import React from "react"
import axios from "../../../api/iskibris/iskibris"
import { Dispatch } from "redux"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"

import useStyles from "./styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import CssBaseline from "@material-ui/core/CssBaseline"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import CircularProgress from "@material-ui/core/CircularProgress"

import { IActionType } from "../../../types"
import NavBar from "../../../components/NavBar/NavBar"
import SideBar from "../../../components/SideBar"
import Copyright from "../../../components/Copyright"
import { companyActions } from "../../../store/actions/company"
import BasicInfo from "../../../components/Companies/CompanyInfo/BasicInfo"
import UserSideMenu from "../../../components/MobileComponents/UserSideMenu"
import SocialMedia from "../../../components/Companies/CompanyInfo/SocialMedia"
import AboutCompany from "../../../components/Companies/CompanyStory/AboutCompany"
import CompanyLeader from "../../../components/Companies/CompanyInfo/CompanyLeader"
import CompanyMission from "../../../components/Companies/CompanyStory/CompanyMission"
import NotificationSnackbar from "../../../components/NotificationSnackbar"
import CompanyInfoFAQ from "../../../components/Companies/CompanyStory/CompanyInfoFaq"
import { useHistory } from "react-router"
import { ICompanySector } from "../../../types/company"

interface Props {
  sectors: ICompanySector
  clearForm: () => void
  fetchSectors: () => void
  fetchIndustries: () => void
  selectSector: (value: any) => void
  editFormFields: (value: any) => void
  companyCreated: (values: any) => void
}

const CreateCompany: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"))

  React.useEffect(() => {
    document.title = t("create_job_title")
  })

  const { sectors, clearForm, fetchSectors, fetchIndustries } = props

  React.useEffect(() => {
    return () => {
      clearForm()
    }
  }, [clearForm])

  React.useEffect(() => {
    if (!sectors) {
      fetchSectors()
      fetchIndustries()
    }
  }, [sectors, fetchIndustries, fetchSectors])

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm()
  const [loading, setLoading] = React.useState(false)
  const [result, setResult] = React.useState("")

  const state = useSelector((state: any) => state.companyReducer)

  const onSubmit = async (fields: any, e: any) => {
    e.preventDefault()
    setLoading(true)
    const data = {
      ...state.form,
      sector_id: state.form?.sector_id?.id,
      industry_id: state.form?.industry_id?.id
    }

    await axios
      .post(`/api/hiring/v2/companies`, data)
      .then(response => {
        if (response.status === 200) {
          setLoading(false)
          setResult("success")
          setTimeout(() => {
            props.companyCreated(response.data)
            history.push("/dashboard")
          }, 2000)
        }
      })
      .catch(error => {
        setLoading(false)
        setResult("fail")
        const errors = error?.response?.data?.errors
        if (errors) {
          const keys = Object.keys(errors)
          keys.map(item => {
            setError(item, { message: errors[item][0], type: "validation" })
            return item
          })
        }
      })
  }

  const handleCancel = () => {
    history.push("/dashboard")
  }

  return (
    <div>
      <CssBaseline />
      <NavBar />
      <SideBar />
      {matchesMobile ? <UserSideMenu /> : null}
      <main className={"content content--has-sidebar content--with-container"} style={{ paddingTop: "62px" }}>
        <Typography variant="h4" className={"page-title"}>
          {t("create_company_header")}
        </Typography>
        <Typography variant="caption" style={{ color: "#797979" }}>
          {t("create_company_subtext")}
        </Typography>
        <Divider className={classes.divider} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item sm={12} md={8} lg={7}>
              <Paper className={classes.paper}>
                <div style={{}}>
                  <BasicInfo
                    formFields={state.form}
                    editFormFields={props.editFormFields}
                    selectSector={props.selectSector}
                    errors={errors}
                    control={control}
                    register={register}
                  />
                </div>
              </Paper>
              <Paper className={classes.paper} style={{ marginTop: "20px" }}>
                <div>
                  <AboutCompany errors={errors} control={control} />
                </div>
              </Paper>
              <Paper className={classes.paper} style={{ marginTop: "20px" }}>
                <div>
                  <CompanyMission errors={errors} control={control} />
                </div>
              </Paper>
              <Paper className={classes.paper} style={{ marginTop: "20px" }}>
                <div style={{}}>
                  <CompanyLeader formFields={state.form} editFormFields={props.editFormFields} errors={errors} register={register} />
                </div>
              </Paper>
              <Paper className={classes.paper} style={{ marginTop: "20px" }}>
                <div style={{}}>
                  <SocialMedia formFields={state.form} editFormFields={props.editFormFields} register={register} />
                </div>
              </Paper>
              <Paper className={classes.paper} style={{ marginTop: "20px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Button variant="outlined" color={"primary"} onClick={handleCancel}>
                    {t("cancel")}
                  </Button>
                  <Button variant="contained" color="primary" type={"submit"}>
                    {loading ? <CircularProgress size={22} style={{ color: "#fff" }} /> : t("submit")}
                  </Button>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={5}>
              <Paper className={classes.sidepaper}>
                <div>
                  <CompanyInfoFAQ />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </form>
        <NotificationSnackbar
          status={result}
          success_msg={t(`company_create_success`)}
          error_msg={t(`company_create_fail`)}
          handleClose={() => {
            setResult("")
          }}
        />
      </main>
      <footer>
        <Copyright />
      </footer>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    companies: state.companyReducer.companies,
    sectors: state.companyReducer.sectors
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    clearForm: () => dispatch(companyActions.clearCompanyForm()),
    fetchSectors: async () => dispatch(await companyActions.fetchSectors()),
    selectSector: (value: any) => dispatch(companyActions.selectSector(value)),
    fetchIndustries: async () => dispatch(await companyActions.fetchIndustries()),
    editFormFields: (value: any) => dispatch(companyActions.editFormContent(value)),
    companyCreated: (values: any) => dispatch(companyActions.companyCreated(values))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCompany)
