import React from "react"
import axios from "../../../../api/iskibris/iskibris"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import Chip from "@material-ui/core/Chip"
import Button from "@material-ui/core/Button"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Autocomplete from "@material-ui/lab/Autocomplete"
// import useTheme from '@material-ui/core/styles/useTheme';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from "@material-ui/core/CircularProgress"

import Templates from "./_Templates"

import { ICompany } from "../../../../types/company"
import { messagesActions } from "../../../../store/actions/messages"
import { IApplicant, IPaginatedApplicants } from "../../../../types/applicants"

import TextEditor from "./TextEditor"
// import InputVariables from "../../../InputVariables";
import NotificationSnackbar from "../../../NotificationSnackbar"
import MobileInputVariables from "../../../MobileComponents/MobileInputVariables"
import useGtm from "../../../../hooks/useGtm"
import { IUser } from "../../../../types/user"

// import useStyles from '../styles';

const variableMap = {
  candidate: [
    { id: 0, value: "cfname" },
    { id: 1, value: "clname" },
    { id: 2, value: "cflname" }
  ],
  job_company: [
    { id: 0, value: "company_name" },
    { id: 1, value: "job_title" },
    { id: 2, value: "city" }
  ],
  recruiter: [
    { id: 0, value: "rfname" },
    { id: 1, value: "rlname" },
    { id: 2, value: "rflname" }
  ]
}

interface MessageInputProps {
  message: any
  template: any
  templateType: any
  recipients: any[]
  submitting: boolean
  applicants: IApplicant[]
  handleCancelClick: () => void
  handleSendClick: () => void
  addRecipients: (values: any) => void
  handleMessageChange: (value: any) => void
  handleSelectTemplate: (values: any) => void
  handleTemplateTypeChange: (value: any) => void
}

interface Props {
  user: IUser
  company: ICompany
  recipients: any[]
  applicant: IApplicant
  closeSendMessage: () => void
  applicants: IPaginatedApplicants
  addRecipients: (data: any) => void
  getMessages: (comId: any, userId: any) => void
}

const MessageInputActions: React.FC<MessageInputProps> = props => {
  const { t } = useTranslation()
  const [openVariables, setOpenVariables] = React.useState(false)
  const [quillRef, setQuill] = React.useState<any>(null)

  // const theme = useTheme();
  // const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const classes = useStyles();

  const onRecipientsChange = (event: any, values: any) => {
    props.addRecipients(values)
  }

  const handleInsert = (value: any) => {
    const range = quillRef.getSelection()
    const position = range ? range.index : 0
    quillRef.insertText(position, value)
  }

  return (
    <div style={{ display: "flex", flexFlow: "column", justifyContent: "space-between", height: "100%" }}>
      <div style={{ overflowY: "auto", overflowX: "hidden" }}>
        <div className="messaging">
          <div>
            <Autocomplete
              multiple
              limitTags={2}
              id="tags-outlined"
              options={props.applicants ?? []}
              onChange={onRecipientsChange}
              defaultValue={props.recipients}
              getOptionLabel={option => option.name}
              style={{ marginBottom: "10px", marginTop: "0px" }}
              getLimitTagsText={more => `+${more} ${t("more_recipients")}`}
              renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => <Chip size="small" label={option.name ?? ""} {...getTagProps({ index })} />)}
              renderInput={params => (
                <TextField {...params} rowsMax={3} rows={2} margin={"dense"} variant="standard" label={`${t("recipients")}`} placeholder={`${t("add_recipients")}`} />
              )}
            />
          </div>
          <Templates handleSelect={props.handleSelectTemplate} template={props.template} />
          <FormControl variant="standard" margin="dense" style={{ marginTop: "0px" }} fullWidth>
            <InputLabel id="template-type-select">{t("template_type")}</InputLabel>
            <Select
              margin="dense"
              fullWidth
              variant="standard"
              labelId="template-type-select"
              id="demo-simple-select-outlined"
              style={{ marginBottom: "25px" }}
              value={props.templateType}
              onChange={props.handleTemplateTypeChange}
              label={t("template_type")}
            >
              {[1, 2, 3, 4, 5, 6, 7].map(item => {
                return (
                  <MenuItem key={item} value={item}>
                    {t(`template_type_id_${item}`)}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <div>
            {openVariables ? ( // && matchesMobile
              <MobileInputVariables variant="standard" variableMap={variableMap} handleVariableClick={handleInsert} />
            ) : null}
          </div>
          <div>
            <TextEditor value={props.message} handleSetRef={setQuill} handleChange={props.handleMessageChange} />
          </div>
        </div>
        <div>
          <Typography variant="caption" style={{ color: "#797979" }}>
            {`${t("character_max")} (${1000 - props.message?.length} ${t("characters")})`}
          </Typography>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <Button variant="outlined" color="secondary" onClick={props.handleCancelClick}>
          {t("cancel")}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: "15px" }}
          onClick={() => {
            setOpenVariables(!openVariables)
          }}
        >
          {`{ }`} {t("variables")}
        </Button>
        <Button color="primary" variant="contained" style={{ marginLeft: "15px" }} onClick={props.handleSendClick}>
          {props.submitting ? <CircularProgress size={24} style={{ color: "#fff" }} /> : t("send")}
        </Button>
      </div>
      {/* {
        openVariables && !matchesMobile
          ?
          (
            <div className={classes.templateVarsContainer}>
              <InputVariables
                handleVariableClick={handleInsert}
                variableMap={variableMap}
                handleClose={() => { setOpenVariables(false) }}
              />
            </div>
          )
          :
          (
            null
          )
      } */}
    </div>
  )
}

const Input: React.FC<Props> = props => {
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)
  const [sendStatus, setSendStatus] = React.useState("")

  const [message, setMessage] = React.useState<any>("")
  const [template, setTemplate] = React.useState<any>("")
  const [templateType, setTemplateType] = React.useState<any>("")

  const { tagEvent } = useGtm()

  const handleMessageChange = (data: any) => {
    setMessage(data)
  }

  const handleSelectTemplate = (value: any) => {
    if (value) {
      setTemplate(value)
      setTemplateType(value.messenger_template_type_id)
      setMessage(value.body)
    }
  }

  const handleTemplateTypeChange = (event: any) => {
    setTemplateType(event.target.value)
  }

  const handleSendClick = async () => {
    setLoading(true)

    tagEvent({
      event: "send_message_to_job_seekers_attempt",
      data: {
        company_name: props.company?.name,
        applicant: props.applicant?.name,
        job_id: props.applicant?.job_id,
        job_title: props.applicant?.job_title,
        user_name: props.user?.full_name
      }
    })

    let info: any = {
      company_id: props.company.id,
      users: [...props.recipients.map(item => item.id)].join(","),
      message: message
    }

    if (!template) {
      if (templateType != null) {
        info = {
          ...info,
          template_type_id: templateType
        }
      }
      await axios
        .post(`/hiring/api/messaging/custom-all/send`, info)
        .then(async response => {
          if (response.status === 200) {
            tagEvent({
              event: "send_message_to_job_seekers",
              data: {
                company_name: props.company?.name,
                applicant: props.applicant?.name,
                job_id: props.applicant?.job_id,
                job_title: props.applicant?.job_title,
                user_name: props.user?.full_name
              }
            })
            await props.getMessages(info.company_id, props.applicant.user_id)
            setSendStatus("success")
            setTimeout(() => {
              setMessage("")
              props.closeSendMessage()
            }, 2000)
          }
        })
        .catch(error => {
          setSendStatus("fail")
          tagEvent({
            event: "send_message_to_job_seekers_failed",
            data: {
              company_name: props.company?.name,
              applicant: props.applicant?.name,
              job_id: props.applicant?.job_id,
              job_title: props.applicant?.job_title,
              user_name: props.user?.full_name,
              error_code: error?.response?.status
            }
          })
        })
    } else {
      await axios
        .post(`/hiring/api/messaging/templates/${template.id}/send`, info)
        .then(async response => {
          if (response.status === 200) {
            await props.getMessages(info.company_id, props.applicant?.user_id)
            tagEvent({
              event: "send_message_to_job_seekers",
              data: {
                company_name: props.company?.name,
                applicant: props.applicant?.name,
                job_id: props.applicant?.job_id,
                job_title: props.applicant?.job_title,
                user_name: props.user?.full_name
              }
            })
            setSendStatus("success")
            setTimeout(() => {
              props.closeSendMessage()
            }, 2000)
          }
        })
        .catch(error => {
          setSendStatus("fail")
          tagEvent({
            event: "send_message_to_job_seekers_failed",
            data: {
              company_name: props.company?.name,
              applicant: props.applicant?.name,
              job_id: props.applicant?.job_id,
              job_title: props.applicant?.job_title,
              user_name: props.user?.full_name,
              error_code: error?.response?.status
            }
          })
        })
    }
    setLoading(false)
  }

  const handleCancelClick = () => {
    setMessage("")
    props.closeSendMessage()
  }

  return (
    <div
      style={{
        width: "100%",
        padding: 10,
        backgroundColor: "#FFFFFF",
        height: "100%"
      }}
    >
      <MessageInputActions
        message={message}
        template={template}
        templateType={templateType}
        handleTemplateTypeChange={handleTemplateTypeChange}
        handleSelectTemplate={handleSelectTemplate}
        handleCancelClick={handleCancelClick}
        handleSendClick={handleSendClick}
        handleMessageChange={handleMessageChange}
        submitting={loading}
        applicants={props.applicants.data}
        recipients={props.recipients}
        addRecipients={props.addRecipients}
      />
      <NotificationSnackbar
        status={sendStatus}
        success_msg={t("message_sent_success")}
        error_msg={t("message_sent_fail")}
        handleClose={() => {
          setSendStatus("")
        }}
      />
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.userReducer.profile,
    company: state.companyReducer.company,
    applicant: state.applicantsReducer.singleApplicant,
    applicants: state.applicantsReducer.applicants,
    recipients: state.messagesReducer.recipients
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    // sendMessage: async info => dispatch(await actionCreators.sendMessage(info)),
    getMessages: async (companyId: any, userId: any) => dispatch(await messagesActions.fetchMessages(companyId, userId)),
    closeSendMessage: () => dispatch(messagesActions.closeSendMessage()),
    addRecipients: (recipients: any) => dispatch(messagesActions.openSendMessage(recipients))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Input)
