import Fuse from "fuse.js"
import { actionTypes } from "./actionTypes"
import { post, fetch, remove } from "../../api/iskibris/redux-api-methods"
import { action } from "typesafe-actions"
import { IJob } from "../../types/jobs"

let options = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0.5,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    {
      name: "title",
      weight: 0.9
    },
    {
      name: "city_name",
      weight: 0.1
    }
  ]
}

export const jobsActions = {
  fetchCompanyJobs: async (id: number) => {
    return await fetch({
      url: `/api/hiring/v2/companies/${id}/jobs`,
      onSuccessDispatch: actionTypes.ADD_COMPANY_JOBS,
      onErrorDispatch: actionTypes.ADD_JOBS_FETCH_ERROR
    })
  },
  fetchArchivedJobs: async (id: number) => {
    return await fetch({
      url: `/api/hiring/v2/companies/${id}/jobs/archived`,
      onSuccessDispatch: actionTypes.ADD_ARCHIVED_JOBS,
      onErrorDispatch: actionTypes.ADD_JOBS_FETCH_ERROR
    })
  },
  searchCompanyJobs: (jobs: IJob[], query: string) => {
    const fuse = new Fuse(jobs, options)
    let searchResults = fuse.search(query)
    searchResults = searchResults.map((data: any) => data.item)
    return action(actionTypes.ADD_JOB_SEARCH_RESULTS, searchResults)
  },
  publishJob: async (id: any) => {
    return await post({
      info: {},
      url: `/api/hiring/v2/jobs/${id}/publish`,
      onErrorDispatch: actionTypes.ADD_JOBS_FETCH_ERROR,
      onSuccessDispatch: actionTypes.UPDATE_SINGLE_JOB
    })
  },
  unPublishJob: async (id: any) => {
    return await post({
      info: {},
      url: `/api/hiring/v2/jobs/${id}/unpublish`,
      onErrorDispatch: actionTypes.ADD_JOBS_FETCH_ERROR,
      onSuccessDispatch: actionTypes.UPDATE_SINGLE_JOB
    })
  },
  deleteJob: async (id: any) => {
    return await remove({
      id: id,
      url: `/api/hiring/v2/jobs/${id}`,
      onErrorDispatch: actionTypes.ADD_JOBS_FETCH_ERROR,
      onSuccessDispatch: actionTypes.DELETE_JOB
    })
  },
  fetchJobToEdit: async (id: number) => {
    return await fetch({
      url: `/api/hiring/v2/jobs/${id}`,
      onSuccessDispatch: actionTypes.FETCH_JOB_TO_EDIT,
      onErrorDispatch: actionTypes.ADD_JOBS_FETCH_NOT_FOUND_ERROR
    })
  },
  editFormContent: (value: any) => {
    return action(actionTypes.EDIT_FORM_CONTENT, value)
  },
  clearJobForm: () => {
    return action(actionTypes.CLEAR_JOB_FORM)
  },
  changeDuplicateState: (value: any) => {
    return action(actionTypes.CHANGE_DUPLICATING_STATE, value)
  },
  duplicateJob: (values: any) => {
    return action(actionTypes.DUPLICATE_JOB, values)
  },
  clearJobErrors: () => {
    return action(actionTypes.CLEAR_JOB_ERRORS)
  },
  clearJobDuplicateState: () => {
    return action(actionTypes.CLEAR_JOB_DUPLICATE_STATE)
  }
}
