import { createStyles, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme =>
  createStyles({
    textbutton: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        display: "none"
      }
    },
    iconbutton: {
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },
    text: {
      [theme.breakpoints.down("xs")]: {
        display: "none"
      }
    },
    icon: {
      marginRight: "10px",
      [theme.breakpoints.down("xs")]: {
        height: "20px",
        width: "20px",
        marginRight: "0px"
      }
    }
  })
)

export default useStyles
