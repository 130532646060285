import React from "react"
import { ICompany, ICompanyPhotos } from "../../../../types/company"

import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"

import useStyles from "./styles"
import PhotoItem from "./PhotoItem"
import AddPhotoTile from "./AddPhotoTile"
import { IActionType } from "../../../../types"
import FilesDropZone from "../../../FilesDropzone"
import { companyActions } from "../../../../store/actions/company"

interface Props {
  company: ICompany
  photos: ICompanyPhotos[]
  addCompanyPhotos: (id: any, photos: any) => void
  deleteCompanyPhotos: (id: any, photoId: any) => void
}

const Photos: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [addPhotoOpen, setAddPhotoOpen] = React.useState(false)

  const { photos, company } = props

  const handleSubmitPhotos = async (files: any) => {
    //submitting the photos
    let values = [...files]
    await props.addCompanyPhotos(company.id, values)
  }

  const handleDeleteClick = (photoId: any) => {
    props.deleteCompanyPhotos(company.id, photoId)
  }

  const handleCloseAddPhoto = () => {
    if (addPhotoOpen) {
      setAddPhotoOpen(false)
    }
  }

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {t("photos")}
      </Typography>
      <Typography variant="subtitle1" gutterBottom className={classes.subtitleText}>
        {t("upload_photos_subtext")}
      </Typography>
      <Typography variant="subtitle2" gutterBottom className={classes.subtitleText}>
        {t("photo_dimensions", {'dimensions': "200px x 200px", 'ratio': "1 : 1"})}
      </Typography> 
      <div style={{ width: "100%" }}>
        <ClickAwayListener onClickAway={handleCloseAddPhoto}>
          <div>
            <FilesDropZone
              open={addPhotoOpen}
              handleSubmitFiles={handleSubmitPhotos}
              handleClose={handleCloseAddPhoto}
              limit={5}
              dropzoneText={t("drag_and_drop_image")}
              acceptedFiles={["image/*"]}
            />
          </div>
        </ClickAwayListener>
      </div>
      <div className={classes.photosContainer}></div>
      <Grid container>
        {photos
          ? photos.map(photo => (
              <Grid key={Math.random()} item xs={6} sm={4} md={4} lg={4}>
                <PhotoItem photo={photo} handleDeletePhoto={handleDeleteClick} />
              </Grid>
            ))
          : null}
        <Grid key={Math.random()} item xs={6} sm={4} md={4} lg={4}>
          <AddPhotoTile handleSubmitPhotos={handleSubmitPhotos} />
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    photos: state.companyReducer.photos,
    company: state.companyReducer.company
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    addCompanyPhotos: async (companyId: any, files: any) => dispatch(await companyActions.addCompanyPhotos(companyId, files)),
    deleteCompanyPhotos: async (companyId: any, photoId: any) => dispatch(await companyActions.deleteCompanyPhotos(companyId, photoId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Photos)
