import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    // paddingLeft: "10px",
    position: "relative",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    // borderLeft: "1px solid #eaeaea",
    // borderRight: "1px solid #eaeaea",
    height: "50px",
    transition: "0.5s"
  },
  button: {
    color: "white",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    transition: "0.5s",
    "&:hover": {}
  },
  paper: {
    position: "absolute",
    top: 36,
    right: 0,
    width: "150px"
  },
  ratingsContainer: {
    backgroundColor: theme.palette.background.paper
  },
  applyButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    float: "right"
  },
  cancelButton: {
    float: "right",
    paddingRight: "10px"
  },
  buttonArea: {
    padding: "10px",
    float: "right",
    width: "100%"
  },
  shortLabel: {
    float: "right"
  },
  rightIcon: {}
}))

export default useStyles
