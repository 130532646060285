import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#ffffff",
    boxShadow: "0 1px 5px 0 rgba(69,77,87,.3)",
    // marginLeft: "-12px",
    // marginRight: "-12px",
    // borderBottom: "1px solid #a5a5a5",
    position: "fixed",
    top: 50,
    width: "100%",
    transition: "0.5s",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
    // bottom: 5,
    // right: 5,
    // zIndex: 100000,
  }
}))

export default useStyles
