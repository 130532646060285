import { actionTypes } from "./actionTypes"
import { fetch } from "../../api/iskibris/redux-api-methods"

export const permissionActions = {
  fetchUserAbilities: async () => {
    return await fetch({
      url: `/api/hiring/v2/abilities`,
      onSuccessDispatch: actionTypes.ADD_ABILITIES,
      onErrorDispatch: actionTypes.ADD_ABILITIES_ERROR
    })
  },
  fetchCompanyBillingAbilities: async () => {
    return await fetch({
      url: `/api/hiring/v2/employer-abilities`,
      onSuccessDispatch: actionTypes.ADD_COMPANY_ABILITIES,
      onErrorDispatch: actionTypes.ADD_ABILITIES_ERROR
    })
  }
}
