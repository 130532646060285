import { makeStyles } from "@material-ui/core"

export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 4),
    maxWidth: "500px",
    width: "100%"
  },
  greetingMessage: {
    marginBottom: "20px"
  },
  googleLoginButton: {
    width: "100%",
    height: "50px",
    textTransform: "none",
    boxShadow: theme.shadows[5],
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Helvetica, sans- serif",
    fontWeight: 700,
    fontSize: "16px",
    cursor: "pointer"
  },
  facebookLoginButton: {
    cursor: "pointer",
    width: "100%",
    height: "50px",
    textTransform: "none",
    backgroundColor: "#3C5A99",
    color: "white",
    boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Helvetica, sans- serif",
    fontWeight: 700,
    fontSize: "16px"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondary.main
  },
  loginError: {
    backgroundColor: "#EB4F63",
    color: "white",
    width: "100%",
    maxWidth: "600px",
    marginBottom: "10px",
    borderRadius: "3px",
    align: "center",
    padding: "15px"
  },
  form: {
    width: "100%"
  },
  extraActionButtonGrid: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "5px"
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      display: "flex",
      marginTop: "15px"
    }
  },
  customDividerContainer: {
    position: "relative",
    marginTop: 10,
    textAlign: "center"
  },
  customDividerLine: {
    width: "100%",
    backgroundColor: "#cecece",
    position: "absolute",
    left: 0,
    top: "50%"
  },
  customDividerContent: {
    position: "relative",
    display: "inline-block",
    margin: "10px 0",
    padding: "0 5px",
    background: "#F2F2F2",
    borderRadius: "4px",
    borderLeft: "1px solid #cecece",
    borderRight: "1px solid #cecece"
  }
}))
