import sort from "fast-sort"
import { actionTypes } from "../actions/actionTypes"
import { IActionType } from "../../types"
import { IMessageModel, IMessageTemplate } from "../../types/messages"

interface IMessagesReducerModel {
  templates?: IMessageTemplate[] | null
  selectedTemplate?: IMessageTemplate | null
  showMessaging?: boolean | null
  sendMessage?: boolean | null
  recipients?: any[] | null
  messages?: IMessageModel | null
  errors?: any
}

const initialState: IMessagesReducerModel = {
  templates: null,
  selectedTemplate: null,
  showMessaging: false,
  sendMessage: false,
  recipients: [],
  messages: null,
  errors: null
}

const reducer = (state: IMessagesReducerModel = initialState, action: IActionType) => {
  switch (action.type) {
    case actionTypes.ADD_MESSAGE_TEMPLATES: {
      const templates = action.payload ? [...action.payload] : []
      return {
        ...state,
        templates: templates
      }
    }
    case actionTypes.ADD_SINGLE_TEMPLATE: {
      let template = { ...action.payload }
      let newTemplates: any = []
      if (state.templates) {
        newTemplates = [...state.templates]
      }
      newTemplates.push(template)
      return {
        ...state,
        templates: newTemplates
      }
    }
    case actionTypes.EDIT_TEMPLATE: {
      let editedTemplate = { ...action.payload }
      let newTemplates = state.templates ? [...state.templates] : []
      let ndx = newTemplates.findIndex(item => item.id === editedTemplate.id)
      newTemplates[ndx] = editedTemplate
      return {
        ...state,
        templates: newTemplates
      }
    }
    case actionTypes.DELETE_SINGLE_TEMPLATE: {
      let oldTemplates = state.templates ? [...state.templates] : []
      let newTemplates = oldTemplates.filter(item => item.id !== action.payload)
      return {
        ...state,
        templates: newTemplates
      }
    }
    case actionTypes.SELECT_TEMPLATE: {
      return {
        ...state,
        selectedTemplate: action.payload ? { ...action.payload } : null
      }
    }
    case actionTypes.OPEN_SEND_MESSAGE: {
      return {
        ...state,
        sendMessage: true,
        recipients: [...action.payload]
      }
    }
    case actionTypes.CLOSE_SEND_MESSAGE: {
      return {
        ...state,
        sendMessage: false,
        recipients: initialState.recipients
      }
    }
    case actionTypes.ADD_MESSAGES: {
      const messages = [...action.payload]
      const sortedMessages = sort(messages).desc(message => message.created_at)
      return {
        ...state,
        messages: sortedMessages
      }
    }
    default:
      break
  }
  return state
}

export default reducer
