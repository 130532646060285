// ratingFilters, stageFilters, locationFilters, jobTitleFilters, fieldsOfStudyFilters, workPermitFilters, educationLevelFilters, genderFilters, keywords, companyId, activeFilter

import { IApplicantSearchParams } from "../types/applicants"
import { applicantActiveStages } from "./data/enums"

export const _createFilterSearchString = (params: IApplicantSearchParams) => {
  let rating = "rating"
  let stage = "stage"
  let stage_enum = "stage_enum"
  let city = "city"
  let place = "place"
  let job = "job"
  let age = "age"
  let driver_licence = "driver_licence"
  let field_of_study = "field_of_study"
  let work_permit = "work_permit"
  let gender = "gender"
  let folder = "folder"
  let education_level = "education_level"
  let kywrd = "keyword"
  let amp = "&"
  let filterString = ""

  filterString = concatString(filterString, params.job, job)
  filterString = concatString(filterString, params.stage, stage)
  filterString = concatString(filterString, params.stage_enum, stage_enum)
  filterString = concatString(filterString, params.city, city)
  filterString = concatString(filterString, params.place, place)
  filterString = concatString(filterString, params.gender, gender)
  filterString = concatString(filterString, params.work_permit, work_permit)
  filterString = concatString(filterString, params.field_of_study, field_of_study)
  filterString = concatString(filterString, params.education_level, education_level)
  filterString = concatString(filterString, params.driver_licence, driver_licence)
  filterString = concatString(filterString, params.folder, folder)
  filterString = concatString(filterString, params.age, age)

  console.log(">>>>>> Params: ", params)

  // if (params.activeFilter && params.rating.length === 0 && params.stage.length === 0) {
  //   filterString = concatString(filterString, [0, 1, 2], rating)
  //   filterString = concatString(filterString, [0, 1, 2, 3, 4, 5], stage)
  // } else if (params.activeFilter && params.rating.length === 0 && params.stage.length > 0) {
  //   filterString = concatString(filterString, [0, 1, 2], rating)
  //   filterString = concatString(filterString, params.stage, stage)
  // } else {
  //   filterString = concatString(filterString, params.rating, rating)
  //   filterString = concatString(filterString, params.stage, stage)
  // }

  if (params.activeFilter && params.rating.length === 0 && params.stage_enum.length === 0) {
    filterString = concatString(filterString, [0, 1, 2], rating)
    filterString = concatString(filterString, applicantActiveStages, stage_enum)
  } else if (params.activeFilter && params.rating.length === 0 && params.stage_enum.length > 0) {
    filterString = concatString(filterString, [0, 1, 2], rating)
    filterString = concatString(filterString, params.stage_enum, stage_enum)
  } else {
    filterString = concatString(filterString, params.rating, rating)
    filterString = concatString(filterString, params.stage_enum, stage_enum)
  }

  if (params.keyword) {
    filterString = filterString.length > 0 ? `${filterString}${amp}${kywrd}=${encodeURI(params.keyword)}` : `${kywrd}=${encodeURI(params.keyword)}`
  }

  filterString = filterString.length > 0 ? `${filterString}${amp}company=${params.companyId}` : `company=${params.companyId}&`

  return filterString
}

const concatString = (oldFilterString: string, filterList: any[], keywrdTitle: any) => {
  if(!filterList || !filterList?.length){
    return oldFilterString
  }
  
  let filterString = oldFilterString
  if (filterList.length > 0) {
    filterString = oldFilterString?.length > 0 ? `${oldFilterString}&${keywrdTitle}=${filterList.join(",")}` : `${keywrdTitle}=${filterList.join(",")}`
  }
  return filterString
}
