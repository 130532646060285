import React, { useState } from "react"

import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { ICompany } from "../../../../types/company"
import imageFile2Base64 from "../../../../utils/imageFile2Base64"

import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"

import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"

import LogoEditor from "./LogoEditor"
import { IActionType } from "../../../../types"
import { companyActions } from "../../../../store/actions/company"

import useStyles from "./styles"

interface Props {
  company: ICompany
  handleLogoUpdate?: (logoUrl: string) => void
  removeCompanyLogo?: (id: any) => void
}

const CompanyLogo: React.FC<Props> = props => {
  const classes = useStyles()

  const company = props.company

  const [onMouseHover, setOnMouseHover] = useState(false)
  const [logoModalOpen, setLogoModalOpen] = useState(false)
  const [newLogo, setNewLogo] = useState<any>(null)

  const { t } = useTranslation()

  const handleMouseHover = () => {
    setOnMouseHover(true)
  }

  const handleMouseLeave = () => {
    setOnMouseHover(false)
  }

  const handleLogoModalOpen = () => {
    setLogoModalOpen(true)
  }

  const handleLogoModalClose = () => {
    setLogoModalOpen(false)
  }

  const handleLogoDelete = () => {
    if (props.removeCompanyLogo) {
      props.removeCompanyLogo(company?.id)
    }
  }

  const handleNewImage = (e: any) => {
    const file = e.target.files[0]
    if (file) {
      imageFile2Base64(file).then(response => {
        setNewLogo(response)
        handleLogoModalOpen()
      })
    }
  }

  return (
    <div>
      {props.company?.logo ? (
        <CardMedia onMouseOver={handleMouseHover} onMouseLeave={handleMouseLeave} image={props.company.logo} title="Logo" className={classes.media}>
          <div className={classes.logoOverlayContainer}>
            {onMouseHover ? (
              <div className={classes.logoOverlay}>
                <IconButton onClick={handleLogoModalOpen} className={classes.logoIconButton} title="Edit Logo">
                  <EditIcon color="primary" />
                </IconButton>
                <IconButton onClick={handleLogoDelete} className={classes.logoIconButton} title="Delete Logo">
                  <DeleteIcon color="error" />
                </IconButton>
              </div>
            ) : null}
          </div>
        </CardMedia>
      ) : (
        <div>
          <input accept="image/*" name="new-logo" style={{ display: "none" }} id="new-logo-input" type="file" onChange={handleNewImage} />
          <label className={classes.addLogoCard} htmlFor="new-logo-input">
            <div style={{ textAlign: "center" }}>
              <Typography>{t("add_logo")}</Typography>
              <AddIcon color="secondary" />
            </div>
          </label>
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={logoModalOpen}
        onClose={handleLogoModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={logoModalOpen}>
          <div className={classes.modalPaper}>
            <LogoEditor company={props.company} handleLogoModalClose={handleLogoModalClose} newLogo={newLogo} handleUpdateCompanyLogo={props.handleLogoUpdate} />
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    handleLogoUpdate: (logo: string) => dispatch(companyActions.updateCompanyLogo(logo)),
    removeCompanyLogo: async (id: number) => dispatch(await companyActions.removeCompanyLogo(id))
  }
}

export default connect(null, mapDispatchToProps)(CompanyLogo)
