import React, { useState } from "react"

import axios from "../../../../api/iskibris/iskibris"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import Button from "@material-ui/core/Button"
import Toolbar from "@material-ui/core/Toolbar"
// import Divider from '@material-ui/core/Divider';
import Checkbox from "@material-ui/core/Checkbox"
import Backdrop from "@material-ui/core/Backdrop"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import CircularProgress from "@material-ui/core/CircularProgress"
import AddIcon from "@material-ui/icons/Add"

import useStyles from "../styles"

// import SelectUsersEmails from './SelectEmails/index.';
import NotificationSnackbar from "../../../NotificationSnackbar"
import { ICompany } from "../../../../types/company"
import { Dispatch } from "redux"
import { IActionType } from "../../../../types"
import { companyActions } from "../../../../store/actions/company"
import { IUser } from "../../../../types/user"
import { TextField, useMediaQuery, useTheme } from "@material-ui/core"

interface Props {
  users: IUser[]
  company: ICompany
  // fetchCompanyUsers: (id: any) => void;
  fetchCompanyUserInvitations: (id: any) => void
}

const AddUser: React.FC<Props> = props => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const [addingStatus, setAddingStatus] = useState("")

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  const [formValues, setValues] = useState<any>({
    user_id: "",
    email: "",
    roles: ["employer"]
  })

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleRolesChange = (event: any) => {
    setValues({
      ...formValues,
      roles: event.target.value
    })
  }

  const handleChange = (event: any) => {
    const ndx = formValues.roles.findIndex((item: any) => item === event.target.value)
    let newRoles = [...formValues.roles]
    if (ndx === -1) {
      newRoles.push(event.target.value)
    } else {
      newRoles = [...formValues.roles].filter(item => item !== event.target.value)
    }
    setValues({
      ...formValues,
      roles: newRoles
    })
  }

  const isChecked = (value: any) => {
    const ndx = [...formValues.roles].findIndex(item => item === value)
    if (ndx === -1) {
      return false
    }
    return true
  }

  // const handleEmailsChange = (values: any) => {
  //   setValues({
  //     ...formValues,
  //     user_id: values
  //   });
  // }

  const handleSubmit = async () => {
    // const info = {
    //   user_id: formValues.user_id.id,
    //   roles: [...formValues.roles].join(',')
    // }

    const info = {
      invited_user_email: formValues.email,
      metadata: {
        roles: formValues.roles?.join(",")
      }
    }
    setLoading(true)
    await axios
      .post(`/api/hiring/v2/companies/${props.company.id}/company-user-invitations`, info)
      .then(response => {
        if (response.status === 200) {
          props.fetchCompanyUserInvitations(props.company.id)
          setAddingStatus("success")
          setLoading(false)
          setTimeout(() => {
            setOpen(false)
          }, 1000)
        }
      })
      .catch(err => {
        setAddingStatus("error")
        setLoading(false)
      })
  }

  const handleCancelClick = () => {
    handleClose()
  }

  return (
    <div
    // style={{
    //   float: "right"
    // }}
    >
      {matches ? (
        <Button variant="outlined" color="secondary" onClick={handleOpen}>
          {t("invite_coworkers")}
        </Button>
      ) : (
        <IconButton color="secondary" onClick={handleOpen} size="small">
          <AddIcon />
        </IconButton>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.modalPaper}>
            <Toolbar variant="dense" className={classes.toolbar}>
              <Typography variant="h6" style={{ textAlign: "center" }} gutterBottom>
                {t("invite_coworkers")}
              </Typography>
            </Toolbar>
            <div className={classes.contents}>
              {/* <SelectUsersEmails
                handleSelect={handleEmailsChange}
                value={formValues.user_id}
              /> */}
              <TextField
                autoFocus
                fullWidth
                margin="dense"
                // value={formValues.email}
                onChange={event => {
                  setValues({ ...formValues, email: event.target.value })
                }}
                placeholder={t("email_addresses")}
              />
              <div style={{ marginTop: "15px" }}>
                <Typography variant="caption" style={{ color: "#797979" }} gutterBottom>
                  {t("access_levels")}
                </Typography>
                <RadioGroup value={formValues.roles} onChange={handleRolesChange}>
                  <div>
                    {/* <FormControlLabel value="employer" control={<Radio />} label="Employer" /> */}
                    <FormControlLabel control={<Checkbox checked={isChecked("employer")} onChange={handleChange} value="employer" />} label={t("employer")} />
                    <Typography variant="body2" style={{ marginLeft: 31, color: "#797979" }} gutterBottom>
                      {t("employer_subtitle")}
                    </Typography>
                  </div>
                  <div>
                    {/* <FormControlLabel value="editor" control={<Radio />} label="Editor" /> */}
                    <FormControlLabel control={<Checkbox checked={isChecked("editor")} onChange={handleChange} value="editor" />} label={t("editor")} />
                    <Typography variant="body2" style={{ marginLeft: 31, color: "#797979" }} gutterBottom>
                      {t("editor_subtitle")}
                    </Typography>
                  </div>
                  <div>
                    {/* <FormControlLabel value="observer" control={<Radio />} label="Observer" /> */}
                    <FormControlLabel control={<Checkbox checked={isChecked("observer")} onChange={handleChange} value="observer" />} label={t("observer")} />
                    <Typography variant="body2" style={{ marginLeft: 31, color: "#797979" }} gutterBottom>
                      {t("observer_subtitle")}
                    </Typography>
                  </div>
                </RadioGroup>
              </div>
              <div className={classes.buttons}>
                <Button style={{ float: "right", backgroundColor: "#221133", color: "white", marginLeft: "10px" }} onClick={handleSubmit}>
                  {loading ? <CircularProgress size={22} style={{ color: "white" }} /> : t("Send Invite")}
                </Button>
                <Button onClick={handleCancelClick} variant="outlined" color="primary">
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <NotificationSnackbar
        status={addingStatus}
        success_msg="Company user was successfully added."
        error_msg="Failed to add company user. Try again!"
        handleClose={() => {
          setAddingStatus("")
        }}
      />
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    users: state.userReducer.systemUsers,
    company: state.companyReducer.company
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchCompanyUserInvitations: async (companyId: any) => dispatch(await companyActions.fetchPendingCompanyUserInvitaitons(companyId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUser)
