import React from "react"
// import moment from 'moment';
import { connect } from "react-redux"

import Grid from "@material-ui/core/Grid"
// import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography"

import { useTranslation } from "react-i18next"
import { IUser } from "../../../../../types/user"

import useStyles from "../../styles"
import dayjs from "dayjs"
import { getPlaceNameRecursively } from "../../../../../utils/places"

interface Props {
  profile: IUser
}

interface DetailProps {
  heading: any
  value: any
}

const DetailItem: React.FC<DetailProps> = props => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <Typography variant="body2" style={{ display: "inline", color: "#797979" }} gutterBottom>
        {props.heading}
      </Typography>
      <span style={{ padding: 5 }}>:</span>
      <Typography variant="body2" style={{ display: "inline" }} gutterBottom>
        {props.value}
      </Typography>
    </div>
  )
}

const AdditionalUserInformation: React.FC<Props> = props => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  return (
    <div className={classes.applicantInfoCard}>
      <div className={classes.infoCardHeader}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          {t("applicant_details")}
        </Typography>
      </div>
      <div>
        <Grid container>
          <Grid xs={12} sm={6} item>
            <DetailItem heading={t("phone_label")} value={props.profile.phone_number} />
          </Grid>
          <Grid xs={12} sm={6} item>
            <DetailItem heading={t("email")} value={props.profile.email} />
          </Grid>
          {props.profile.education_level_id !== null ? (
            <Grid xs={12} sm={6} item>
              <DetailItem heading={t("education_level")} value={props.profile.education_level_id !== null ? t(`education_level_id_${props.profile.education_level_id}`) : "- -"} />
            </Grid>
          ) : null}
          {props.profile.years_of_experience !== null ? (
            <Grid xs={12} sm={6} item>
              <DetailItem heading={t("years_of_experince_label")} value={props.profile.years_of_experience !== null ? props.profile.years_of_experience : "- -"} />
            </Grid>
          ) : null}
          {props.profile.gender !== null && props.profile.gender !== undefined ? (
            <Grid xs={12} sm={6} item>
              <DetailItem heading={t("gender_label")} value={props.profile.gender !== null ? t(`gender_id_${props.profile.gender}`) : "- -"} />
            </Grid>
          ) : null}
          {props.profile.birthdate !== null ? (
            <Grid xs={12} sm={6} item>
              <DetailItem
                heading={t("age_label")}
                value={props.profile !== undefined && props.profile.birthdate !== null ? dayjs().diff(dayjs(props.profile.birthdate), "years") : "- -"}
              />
            </Grid>
          ) : null}
          {props.profile.work_permit_id !== null ? (
            <Grid xs={12} sm={6} item>
              <DetailItem heading={t("work_permit_label")} value={props.profile.work_permit_id !== null ? t(`work_permit_id_${props.profile.work_permit_id}`) : "- -"} />
            </Grid>
          ) : null}
          {props.profile.place  ? (
            <Grid xs={12} sm={6} item>
              <DetailItem 
                heading={t("city_of_residence")}
                value={
                  props.profile?.place?.name ? getPlaceNameRecursively(props.profile?.place, "", i18n.language) : t(`city_id_${props.profile.city_id}`)
                  // props.profile.city_id !== null ? t(`city_id_${props.profile.city_id}`) : "- -"
                } 
              />
            </Grid>
          ) : null}

          {props.profile.military_status !== null ? (
            <Grid xs={12} sm={6} item>
              <DetailItem heading={t("military_status_label")} value={props.profile.military_status !== null ? t(`military_status_id_${props.profile.military_status}`) : "- -"} />
            </Grid>
          ) : null}
          {props.profile.job_seeking_status !== null ? (
            <Grid xs={12} sm={6} item>
              <DetailItem
                heading={t("job_seeking_status_label")}
                value={props.profile.job_seeking_status !== null ? t(`job_seeking_status_${props.profile.job_seeking_status}`) : "- -"}
              />
            </Grid>
          ) : null}
          {props.profile.new_employment_type_id !== null ? (
            <Grid xs={12} sm={6} item>
              <DetailItem
                heading={t("new_employment_type_label")}
                value={props.profile.new_employment_type_id !== null ? t(`employment_type_id_${props.profile.new_employment_type_id}`) : "- -"}
              />
            </Grid>
          ) : null}
          {props.profile.preferred_city_id !== null ? (
            <Grid xs={12} sm={6} item>
              <DetailItem
                heading={t("preferred_city_label")}
                value={
                  // props.profile.preferred_city_id !== null ? t(`city_id_${props.profile.preferred_city_id}`) : "- -"
                  props.profile?.preferred_place?.name ? getPlaceNameRecursively(props.profile?.preferred_place, "", i18n.language) : (props.profile.preferred_city_id !== null ? t(`city_id_${props.profile.preferred_city_id}`) : "-")
                }
              />
            </Grid>
          ) : null}
        </Grid>
        {props.profile.about ? (
          <React.Fragment>
            <Typography variant="body2" style={{ color: "#797979" }}>
              {t("personal_summary")}
            </Typography>
            <Typography variant="body2" style={{ marginBottom: "10px" }}>
              {props.profile.about ? props.profile.about : "- -"}
            </Typography>
          </React.Fragment>
        ) : null}
        {props.profile.about ? (
          <React.Fragment>
            <Typography variant="body2" style={{ color: "#797979" }}>
              {t("qualifications_label")}
            </Typography>
            <Typography variant="body2" style={{}}>
              {props.profile.qualifications ? props.profile.qualifications : "- -"}
            </Typography>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.applicantsReducer.currentUserInformation
  }
}

export default connect(mapStateToProps)(AdditionalUserInformation)
