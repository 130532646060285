import React from "react"
import { useTranslation } from "react-i18next"
// import { IApplicant, IApplicantFilters } from '../../../../types/applicants';

// import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button"
import ListItem from "@material-ui/core/ListItem"
import Checkbox from "@material-ui/core/Checkbox"
import Typography from "@material-ui/core/Typography"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import CircularProgress from "@material-ui/core/CircularProgress"
// import DropdownArrow from '@material-ui/icons/ArrowDropDown';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import useStyles from "./styles"
import { IPaginatedApplicants } from "../../../../../types/applicants"

interface FilterOptionsProps {
  value: any
  icon?: any
  label: string
  quantity?: number
}

interface Props {
  field: any
  filters: any
  options: FilterOptionsProps[]
  applicants: IPaginatedApplicants
  handleGoBack: () => void
  handleApplyClick: (values: any) => void
}

const GenericFilter: React.FC<Props> = props => {
  const { options, field, filters } = props

  const [loading, setLoading] = React.useState(false)
  const [fieldFilters, setFieldFilters] = React.useState(filters[`${field}`] ? [...filters[`${field}`]] : [])
  const classes = useStyles()

  const { t } = useTranslation()

  const checkChecked = (value: any) => {
    let result = false
    fieldFilters.forEach((element: any) => {
      if (value === element) {
        result = true
        return true
      }
    })
    return result
  }

  const handleClose = () => {
    setFieldFilters(filters[`${field}`] ? [...filters[`${field}`]] : [])
    props.handleGoBack()
  }

  const handleOptionClick = (value: any) => {
    const ndx = fieldFilters.findIndex(item => value === item)
    if (ndx === -1) {
      let newArray = [...fieldFilters]
      newArray.push(value)
      setFieldFilters(newArray)
    } else {
      let newArray = [...fieldFilters]
      newArray.splice(ndx, 1)
      setFieldFilters(newArray)
    }
  }

  const handleApplyButtonClick = async () => {
    setLoading(true)
    await props.handleApplyClick({ ...filters, [field]: fieldFilters })
    setLoading(false)
    handleClose()
  }

  return (
    <div className={classes.root + " va-middle"}>
      <div className={classes.listContainer}>
        {options.map(option => {
          const labelId = `checkbox-list-label-${option.value}`
          return (
            <ListItem
              key={option.value}
              role={undefined}
              dense
              button
              onClick={() => {
                handleOptionClick(option.value)
              }}
            >
              <ListItemIcon>
                <Checkbox edge="start" checked={checkChecked(option.value)} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": labelId }} />
              </ListItemIcon>
              {option.icon ? <ListItemIcon>{option.icon}</ListItemIcon> : null}
              <ListItemText id={labelId} primary={`${option.label}`} />
              {option.quantity !== null || option.quantity !== undefined ? (
                <ListItemSecondaryAction>
                  <div className={classes.counterBadge}>
                    <Typography variant="caption" style={{ padding: "7px", color: "#ffffff" }}>
                      {props.applicants ? option.quantity : "-"}
                    </Typography>
                  </div>
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          )
        })}
      </div>
      <div className={classes.buttonArea}>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          {t("go_back")}
        </Button>
        <Button color="primary" variant="contained" disabled={loading} onClick={handleApplyButtonClick}>
          {loading ? <CircularProgress size={22} style={{ color: "#fff" }} /> : t("apply")}
        </Button>
      </div>
    </div>
  )
}

export default GenericFilter
