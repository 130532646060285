import { actionTypes } from "../actions/actionTypes"
import { IActionType } from "../../types"
import { IBillingComponent, IBillingPaymentResult, IInvoice, IMalipoInvoice, IOffer, ISubscription, ITransaction } from "../../types/billing"

interface IBillingReducerModel {
  offers: IOffer[] | null
  invoices: IInvoice[] | null
  unregistered: boolean | null
  transactions: ITransaction[] | null
  subscriptions: ISubscription[] | null
  components: IBillingComponent[] | null
  clientInvoices: IMalipoInvoice[] | null
  billingResult: IBillingPaymentResult | null
  inventory?: any | null
  errors?: any
}

const initialState: IBillingReducerModel = {
  errors: null,
  offers: null,
  invoices: null,
  components: null,
  transactions: null,
  unregistered: null,
  billingResult: null,
  subscriptions: null,
  clientInvoices: null
}

const reducer = (state: IBillingReducerModel = initialState, action: IActionType) => {
  switch (action.type) {
    case actionTypes.ADD_SUBSCRIPTION_HISTORY:
      return {
        ...state,
        subscriptions: [...action.payload],
        unregistered: state.unregistered !== initialState.unregistered ? initialState.unregistered : state.unregistered
      }
    case actionTypes.ADD_PAYMENT_HISTORY:
      return {
        ...state,
        transactions: { ...action.payload }
      }
    case actionTypes.ADD_INVOICES:
      return {
        ...state,
        invoices: { ...action.payload }
      }
    case actionTypes.ADD_CLIENT_INVOICES:
      return {
        ...state,
        clientInvoices: [...action.payload]
      }
    case actionTypes.ADD_BILLING_CLIENT_NOT_REGISTERED_ERROR:
      return {
        ...state,
        unregistered: true
      }
    case actionTypes.ADD_BILLING_ERRORS: {
      return {
        ...state,
        errors: { ...action.payload }
      }
    }
    case actionTypes.ADD_BILLING_OFFERS: {
      return {
        ...state,
        offers: [...action.payload]
      }
    }
    case actionTypes.ADD_BILLING_COMPONENTS: {
      return {
        ...state,
        components: [...action.payload]
      }
    }
    case actionTypes.ADD_BILLING_PAYMENT_RESULT: {
      return {
        ...state,
        billingResult: { ...action.payload }
      }
    }
    case actionTypes.ADD_BILLING_PAYMENT_RESULT_EMPTY: {
      return {
        ...state,
        billingResult: initialState.billingResult //{...action.payload}
      }
    }
    case actionTypes.CLEAR_BILLING_PAYMENT_RESULT: {
      return {
        ...state,
        billingResult: initialState.billingResult
      }
    }
    default:
      break
  }
  return state
}

export default reducer
