import React from "react"

import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import IconButton from "@material-ui/core/IconButton"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import { Link } from "@material-ui/core"

interface Option {
  title: string
  handler: () => void
  link?: string
  download?: boolean
}

interface LongMenuProps {
  options: Array<Option>
}

const ITEM_HEIGHT = 48

const LongMenu: React.FC<LongMenuProps> = props => {
  const options = props.options
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleItemClick = (option: Option) => (event: any) => {
    option.handler()
    setAnchorEl(null)
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} style={{ padding: "3px" }}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200
          }
        }}
      >
        {options.map(option =>
          option.link ? (
            <Link href={option.link} download={option.download} target={option.download ? "_blank" : undefined}>
              <MenuItem key={option.title} onClick={handleItemClick(option)}>
                {option.title}
              </MenuItem>
            </Link>
          ) : (
            <MenuItem key={option.title} onClick={handleItemClick(option)}>
              {option.title}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  )
}

export default LongMenu
