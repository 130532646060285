import React from "react"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"

import useStyles from "./styles"

interface Props {
  icon: any
  footer?: any
  heading: string
  description?: string | null
  clicked: () => void
}

const DashboardCard: React.FC<Props> = props => {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={12} md={4}>
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent className={"dashboard-tile " + classes.cardContent} onClick={props.clicked}>
            <div className="dashboard-tile__icon">{props.icon}</div>
            <div>
              <Typography variant="h5" className="dashboard-tile__heading">
                {props.heading}
              </Typography>
              {props.description ? (
                <Typography variant="body2" className="dashboard-tile__description">
                  {props.description}
                </Typography>
              ) : null}
              {props.footer || null}
            </div>
          </CardContent>
        </Card>
      </div>
    </Grid>
  )
}

export default DashboardCard
