import React from "react"
import { useTranslation } from "react-i18next"

// import Fab from '@material-ui/core/Fab';
import Button from "@material-ui/core/Button"

import ArrowBack from "@material-ui/icons/ArrowBackIos"

import useStyles from "./styles"
import { useHistory } from "react-router"
import { IApplicant, IPaginatedApplicants } from "../../../types/applicants"

interface Props {
  applicants: IPaginatedApplicants
  singleApplicant: IApplicant
}

const MobileNavigation: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const { applicants, singleApplicant } = props

  const handleAllCandidates = () => {
    history.push("/applicants")
  }

  const checkNext = () => {
    if (singleApplicant && applicants) {
      const ndx = applicants.data.findIndex(item => item.id === singleApplicant.id)
      if (ndx !== -1 && ndx < applicants.data.length - 1) {
        return true
      }
    }
    return false
  }

  const handleNext = () => {
    const ndx = applicants.data.findIndex(item => item.id === singleApplicant.id)
    if (ndx !== -1) {
      const nextApplicant = applicants.data[ndx + 1]
      history.push(`/applicants/${nextApplicant.id}`)
    }
  }

  const handlePrevious = () => {
    const ndx = applicants.data.findIndex(item => item.id === singleApplicant.id)
    if (ndx !== -1) {
      const nextApplicant = applicants.data[ndx - 1]
      history.push(`/applicants/${nextApplicant.id}`)
    }
  }

  const checkPrevious = () => {
    if (singleApplicant && applicants) {
      const ndx = applicants.data.findIndex(item => item.id === singleApplicant.id)
      if (applicants.data.length > 1 && ndx > 0) {
        return true
      }
    }
    return false
  }

  return (
    <div className={classes.root}>
      <div>
        <Button size="small" color="secondary" onClick={handleAllCandidates}>
          <ArrowBack fontSize="small" />
          {t("all_candidates")}
        </Button>
      </div>
      <div>
        {checkPrevious() ? (
          <Button size="small" color="secondary" onClick={handlePrevious}>
            {t("previous")}
          </Button>
        ) : null}
        {checkNext() ? (
          <Button size="small" color="secondary" onClick={handleNext}>
            {t("next")}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default MobileNavigation
