import React from "react"
import { useTranslation } from "react-i18next"

import Button from "@material-ui/core/Button"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
// Icons
import InterestedIcon from "@material-ui/icons/CheckCircle"
import NotInterestedIcon from "@material-ui/icons/CancelRounded"
import UnratedIcon from "@material-ui/icons/FiberManualRecord"
import MaybeIcon from "@material-ui/icons/Help"

import useStyles from "./styles"

interface Props {
  rating: number
  readOnly: boolean
  handleUpdate: (value: any) => void
}

const ApplicantRating: React.FC<Props> = props => {
  const classes = useStyles()

  const { t } = useTranslation()

  let ratings = [
    { value: 0, label: "", icon: <UnratedIcon className={"icon icon--unrated"} /> },
    { value: 1, label: "", icon: <InterestedIcon className={"icon icon--interested"} /> },
    { value: 2, label: "", icon: <MaybeIcon className={"icon icon--maybe"} /> },
    { value: 3, label: "", icon: <NotInterestedIcon className={"icon icon--not-interested"} /> }
  ]

  ratings.forEach(item => {
    item.label = t(`rating_id_${item.value}`)
  })

  let isUnrated = props.rating === null || props.rating === 0
  return (
    <div className={(isUnrated ? classes.unrated : classes.rated) + " d-inline-block va-middle mr-8"}>
      <Typography variant="body1" className={classes.label} style={{ marginRight: "5px" }}>
        {t("rating")}:{" "}
      </Typography>
      {!props.readOnly ? (
        isUnrated ? (
          <ToggleButtonGroup size="small" className={"va-middle"}>
            {ratings.map(option => {
              if (option.value !== 0) {
                return (
                  <ToggleButton
                    disabled={props.readOnly === true}
                    key={option.value}
                    value={option.value}
                    onClick={() => {
                      props.handleUpdate(option.value)
                    }}
                  >
                    {option.icon}
                  </ToggleButton>
                )
              } else {
                return null
              }
            })}
          </ToggleButtonGroup>
        ) : (
          <div className={"d-inline-block va-middle"}>
            <FormControl>
              <TextField
                select
                value={props.rating}
                disabled={props.readOnly}
                onChange={event => {
                  props.handleUpdate(event.target.value)
                }}
                variant="outlined"
                margin="dense"
                helperText=""
                style={{ margin: "0px" }}
              >
                {ratings.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    <div className={classes.iconContainer}>
                      <div className={classes.icon}>{option.icon}</div>
                    </div>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
        )
      ) : (
        <Button disabled variant="outlined" style={{ height: "39px" }}>
          {t(`rating_id_${props.rating}`)}
        </Button>
      )}
    </div>
  )
}

export default ApplicantRating
