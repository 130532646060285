import React from "react"

import CardMedia from "@material-ui/core/CardMedia"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/DeleteOutlined"

import useStyles from "../styles"
import { ICompanyPhotos } from "../../../../../types/company"

interface Props {
  photo: ICompanyPhotos
  handleDeletePhoto: (id: any) => void
}

const PhotoItem: React.FC<Props> = props => {
  const classes = useStyles()

  const [onMouseHover, setOnMouseHover] = React.useState(false)

  const handleMouseHover = () => {
    setOnMouseHover(true)
  }

  const handleMouseLeave = () => {
    setOnMouseHover(false)
  }

  const handleClick = () => {
    props.handleDeletePhoto(props.photo.id)
  }

  return (
    <div>
      <CardMedia className={classes.photo} image={props.photo.url || ""}>
        <div className={classes.overlay} onMouseLeave={handleMouseLeave} onMouseOver={handleMouseHover}>
          {onMouseHover ? (
            <IconButton
              onClick={handleClick}
              style={{
                backgroundColor: "#000000",
                transition: "0.5s"
              }}
            >
              <DeleteIcon style={{ color: "#FFFFFF" }} />
            </IconButton>
          ) : null}
        </div>
      </CardMedia>
    </div>
  )
}

export default PhotoItem
