import sort from "fast-sort"
import { IActionType } from "../../types"
import { IApplicant } from "../../types/applicants"
import { actionTypes } from "../actions/actionTypes"
import { IApplicantsReducerModel } from "../../types/reducers"
import { isArray } from "lodash"

const initialState: IApplicantsReducerModel = {
  errors: null,
  exports: null,
  folders: null,
  sortByValue: 0,
  orderBy: "created_at",
  orderDirection: "desc",
  filteredPlaces: null,
  applicants: null,
  studyGroups: null,
  searchOpen: false,
  activeFilter: true,
  fieldsOfStudy: null,
  singleApplicant: null,
  currentUserInformation: null,
  archivedJobsApplicants: null,
  filters: {
    stage: [],
    stage_enum: [],
    rating: [],
    gender: [],
    city: [],
    job: [],
    keyword: null,
    folder: [],
    work_permit: [],
    field_of_study: [],
    education_level: [],
    age: [],
    driver_licence: [],
    place: []
  }
}

const filterNullOut = (info: any[]) => {
  return info.filter(item => item !== null && item !== undefined && item?.user_id !== null && item?.user_id !== undefined)
}

const filterActiveApplicants = (applicants: IApplicant[]) => {
  return applicants.filter(value => value.rating_id !== 3 && value.stage_id < 6)
}

const updateStatusOrRating = (state: IApplicantsReducerModel, action: IActionType) => {
  const newApplicant = {
    ...state.singleApplicant,
    ...action.payload
  }
  let updatedApplicants = state.applicants?.data
  if (state.applicants?.data) {
    const currentApplicantIndex = state.applicants.data.findIndex(val => val.id === action.payload.id)
    updatedApplicants = state.applicants.data.concat()
    updatedApplicants[currentApplicantIndex] = {
      ...updatedApplicants[currentApplicantIndex],
      ...action.payload
    }
    updatedApplicants = state.activeFilter === true ? filterActiveApplicants(updatedApplicants) : updatedApplicants
  }
  return {
    ...state,
    singleApplicant: newApplicant,
    applicants: {
      ...state.applicants,
      data: updatedApplicants
    }
  }
}

const sortApplicants = (applicants: IApplicant[], sortBy: number) => {
  switch (sortBy) {
    case 0: {
      const sortedApps = sort([...applicants]).desc(app => app.created_at)
      return sortedApps
    }
    case 1: {
      const sortedApps = sort([...applicants]).asc(app => app.created_at)
      return sortedApps
    }
    case 2: {
      const sortedApps = sort([...applicants]).asc(app => app.name)
      return sortedApps
    }
    case 3: {
      const sortedApps = sort([...applicants]).desc(app => app.name)
      return sortedApps
    }
    case 4: {
      const sortedApps = sort([...applicants]).asc(app => app.job_title)
      return sortedApps
    }
    case 5: {
      const sortedApps = sort([...applicants]).desc(app => app.job_title)
      return sortedApps
    }
    default: {
      return [...applicants]
    }
  }
}

// const orderApplicants = (applicants: IApplicant[], orderBy: string, orderDirection: string) => {
//   switch (`${orderBy}:${orderDirection}`) {
//     case "created_at:desc":{
//       const sortedApps = sort([...applicants]).desc(app=> app.created_at);
//       return sortedApps;
//     }
//     case "created_at:asc":{
//       const sortedApps = sort([...applicants]).asc(app=> app.created_at);
//       return sortedApps;
//     }
//     case "name:asc":{
//       const sortedApps = sort([...applicants]).asc(app=> app.name);
//       return sortedApps;
//     }
//     case "name:desc":{
//       const sortedApps = sort([...applicants]).desc(app=> app.name);
//       return sortedApps;
//     }
//     case "job_title:asc":{
//       const sortedApps = sort([...applicants]).asc(app=> app.job_title);
//       return sortedApps;
//     }
//     case "job_title:desc":{
//       const sortedApps = sort([...applicants]).desc(app=> app.job_title);
//       return sortedApps;
//     }
//     default:{
//       return [...applicants];
//     }
//   }
// }

const reducer = (state: IApplicantsReducerModel = initialState, action: IActionType) => {
  switch (action.type) {
    case actionTypes.FILTER_ACTIVE_APPLICANTS: {
      const applicants = action.payload
      const newApplicants = filterNullOut(applicants.data)
      return {
        ...state,
        activeFilter: true,
        filters: initialState.filters,
        singleApplicant: initialState.singleApplicant, // newApplicants[0],
        applicants: {
          ...applicants,
          data: newApplicants
        }
      }
    }
    case actionTypes.REMOVE_ACTIVE_FILTER_APPLICANTS: {
      const applicants = action.payload
      const sortedApplicants = filterNullOut(applicants.data)
      return {
        ...state,
        activeFilter: false,
        applicants: {
          ...applicants,
          data: sortedApplicants
        },
        filters: initialState.filters,
        singleApplicant: initialState.singleApplicant //  sortedApplicants[0]
      }
    }
    case actionTypes.CHANGE_SEARCH_OPEN: {
      return {
        ...state,
        searchOpen: !state.searchOpen
      }
    }
    case actionTypes.ADD_APPLICANTS: {
      const applicants = action.payload
      let startNdx = applicants.from - 1
      let newPaginatedApplicants: any
      if (state.applicants) {
        newPaginatedApplicants = state.applicants.data
        filterNullOut(applicants.data).forEach(item => {
          newPaginatedApplicants[startNdx] = item
          startNdx += 1
        })
      } else {
        newPaginatedApplicants = filterNullOut(applicants.data)
      }
      const newApplicants = filterNullOut(newPaginatedApplicants)
      return {
        ...state,
        singleApplicant: initialState.singleApplicant, // newApplicants[0],
        applicants: {
          ...applicants,
          data: newApplicants
        }
      }
    }
    case actionTypes.ADD_EXPORT_APPLICANTS: {
      const applicants = action.payload
      const newApplicants = filterNullOut(applicants.data)
      return {
        ...state,
        exports: {
          ...applicants,
          data: newApplicants
        }
      }
    }
    case actionTypes.CLEAR_OPEN_APPLICANT: {
      return {
        ...state,
        singleApplicant: initialState.singleApplicant
      }
    }
    case actionTypes.FETCH_SINGLE_APPLICANT: {
      const newApplicant = { ...action.payload }
      const newApplicants = state.applicants ? [...state.applicants.data] : []
      const ndx = newApplicants.findIndex(item => item.id === newApplicant.id)
      if (ndx !== null && ndx > -1) {
        newApplicants[ndx] = newApplicant
      } else {
        newApplicants.unshift(newApplicant)
      }
      return {
        ...state,
        singleApplicant: { ...action.payload },
        applicants: {
          ...state.applicants,
          data: newApplicants
        }
      }
    }
    case actionTypes.OPEN_SINGLE_APPLICANT: {
      return {
        ...state,
        singleApplicant: { ...action.payload }
      }
    }
    case actionTypes.ADD_SINGLE_APPLICANT: {
      const newApplicants = state.applicants ? [...state.applicants.data] : []
      newApplicants.unshift({ ...action.payload })
      return {
        ...state,
        applicants: {
          ...state.applicants,
          data: newApplicants
        }
      }
    }
    case actionTypes.ADD_CURRENT_USER_INFORMATION: {
      return {
        ...state,
        currentUserInformation: { ...action.payload }
      }
    }
    case actionTypes.ADD_COMPANY_FOLDERS: {
      return {
        ...state,
        folders: [ ...action.payload ]
      }
    }
    case actionTypes.ADD_FILTERED_PLACE: {
      const result = isArray(action.payload) ? action.payload : (action.payload?.data ?? [])
      return {
        ...state,
        filteredPlaces: [ ...result ]
      }
    }
    case actionTypes.UPDATE_APPLICANT_RATING: {
      return updateStatusOrRating(state, action)
    }
    case actionTypes.UPDATE_APPLICANT_STATUS: {
      return updateStatusOrRating(state, action)
    }
    case actionTypes.UPDATE_APPLICANT_RATING_REALTIME: {
      return updateStatusOrRating(state, action)
    }
    case actionTypes.UPDATE_APPLICANT_STATUS_REALTIME: {
      return updateStatusOrRating(state, action)
    }
    case actionTypes.OPTIMISTIC_UPDATE_APPLICANT_STATUS: {
      return updateStatusOrRating(state, action)
    }
    case actionTypes.APPLY_FILTERS: {
      const applicants = action.payload.applicants
      const filters = action.payload.filters
      let startNdx = applicants.from - 1
      let newPaginatedApplicants: any
      if (state.applicants && applicants.current_page !== 1) {
        newPaginatedApplicants = state.applicants.data
        filterNullOut(applicants.data).forEach(item => {
          newPaginatedApplicants[startNdx] = item
          startNdx += 1
        })
      } else {
        newPaginatedApplicants = filterNullOut(applicants.data)
      }
      const newApplicants = filterNullOut(newPaginatedApplicants)
      return {
        ...state,
        singleApplicant: initialState.singleApplicant, // newApplicants[0],
        filters: {
          ...state.filters,
          ...filters
        },
        applicants: {
          ...applicants,
          data: newApplicants
        },
        searchOpen: filters.keyword ? false : state.searchOpen
      }
    }
    case actionTypes.CLEAR_ALL_FILTERS: {
      const applicants = action.payload
      const updatedApplicants = filterNullOut(applicants.data)
      const newSingleApplicant = { ...updatedApplicants[0] }
      return {
        ...state,
        activeFilter: true,
        applicants: {
          ...applicants,
          data: updatedApplicants
        },
        singleApplicant: newSingleApplicant,
        filters: { ...initialState.filters }
      }
    }
    case actionTypes.CLEAR_ALL_APPLICANTS: {
      return {
        ...state,
        applicants: initialState.applicants,
        singleApplicant: initialState.singleApplicant,
        activeFilter: initialState.activeFilter
      }
    }
    case actionTypes.SORT_APPLICANTS_BY: {
      const newApplicants = sortApplicants(filterNullOut(state.applicants?.data || []), action.payload)
      return {
        ...state,
        applicants: {
          ...state.applicants,
          data: newApplicants
        },
        sortByValue: action.payload
      }
    }
    case actionTypes.CHANGE_APPLICANTS_ORDER_INFORMATION: {
      return {
        ...state,
        orderBy: action.payload?.orderBy,
        orderDirection: action.payload?.orderDirection
      }
    }
    case actionTypes.ADD_FIELDS_OF_STUDY: {
      return {
        ...state,
        fieldsOfStudy: [...action.payload]
      }
    }
    case actionTypes.ADD_FIELDS_OF_STUDY_GROUPS: {
      return {
        ...state,
        studyGroups: [...action.payload]
      }
    }
    case actionTypes.ADD_SINGLE_COMMENT: {
      const newComments = state.singleApplicant?.comments ? [...state.singleApplicant.comments] : []
      const ndx = state.singleApplicant ? [...state.singleApplicant.comments].findIndex(item => item.id === action.payload.id) : -1
      if (ndx > -1) {
        newComments[ndx] = { ...action.payload }
      } else {
        newComments.unshift({ ...action.payload })
      }
      return {
        ...state,
        singleApplicant: {
          ...state.singleApplicant,
          comments: newComments
        }
      }
    }
    case actionTypes.ADD_FOLDER_TO_APPLICANT: {
      const newFolders = state.singleApplicant?.folders ? [...state.singleApplicant.folders] : []
      const ndx = state.singleApplicant?.folders ? [...state.singleApplicant.folders].findIndex(item => item.id === action.payload.id) : -1
      if (ndx > -1) {
        newFolders[ndx] = { ...action.payload }
      } else {
        newFolders.unshift({ ...action.payload })
      }
      return {
        ...state,
        singleApplicant: {
          ...state.singleApplicant,
          folders: newFolders
        }
      }
    }
    case actionTypes.DELETE_COMMENT: {
      const newComments = state.singleApplicant ? state.singleApplicant.comments.filter(item => item.id !== action.payload) : []
      return {
        ...state,
        singleApplicant: {
          ...state.singleApplicant,
          comments: newComments
        }
      }
    }
    case actionTypes.REMOVE_FOLDER_FROM_APPLICANT: {
      const newFolders = state.singleApplicant?.folders ? state.singleApplicant.folders.filter(item => item.id !== action.payload) : []
      return {
        ...state,
        singleApplicant: {
          ...state.singleApplicant,
          folders: newFolders
        }
      }
    }
    case actionTypes.EDIT_COMMENT: {
      const newComments = state.singleApplicant ? [...state.singleApplicant.comments] : []
      const ndx = state.singleApplicant ? [...state.singleApplicant.comments].findIndex(item => item.id === action.payload.id) : -1
      if (ndx > -1) {
        newComments[ndx] = { ...action.payload }
      } else {
        newComments.push({ ...action.payload })
      }
      return {
        ...state,
        singleApplicant: {
          ...state.singleApplicant,
          comments: newComments
        }
      }
    }
    default:
      break
  }
  return state
}

export default reducer
