import React from "react"

import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"

import useStyles from "./styles"
import { CircularProgress } from "@material-ui/core"

interface Props {
  open: boolean
  handleClose: () => void
}

const OverlayLoader: React.FC<Props> = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        disableAutoFocus
        disableEnforceFocus
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          className: classes.backdrop
        }}
      >
        <Fade in={props.open}>
          <div>
            <CircularProgress size={50} />
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default OverlayLoader
