import React from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import Alert from "@material-ui/lab/Alert"
import Typography from "@material-ui/core/Typography"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"

interface Props {
  onClose?: () => void
}

const NewFeatureWarning: React.FC<Props> = ( { onClose } ) => {

  const {t} = useTranslation()

  return (
    <div>
      <Alert 
        severity="info"
        onClose={onClose}
        icon={null}
        style={{ borderRadius: "5px", marginBottom: "10px", alignItems: "center" }}
      >
        <Typography variant="body1" style={{marginBottom: "0.75rem"}}>{t("ats_feature_announcement")}</Typography>
        <div style={{marginBottom: "0.75rem"}}>
          <div style={{display: "flex", alignItems: "center", marginBottom: "0.25rem"}}> <KeyboardArrowRight fontSize="small" /> <Typography variant="body2">{t("ats_feature_announcement_manual_applications")}</Typography></div>
          <div style={{display: "flex", alignItems: "center", marginBottom: "0.25rem"}}> <KeyboardArrowRight fontSize="small" /> <Typography variant="body2">{t("ats_feature_announcement_tag_applications_to_lists")}</Typography></div>
          <div style={{display: "flex", alignItems: "center", marginBottom: "0.25rem"}}> <KeyboardArrowRight fontSize="small" /> <Typography variant="body2">{t("ats_feature_announcement_expanded_applications_stages")}</Typography></div>
        </div>
        <Link to="/features/ats" style={{color: "#221133"}}>{t("learn_more")}</Link>
      </Alert>
    </div>
  )
}

export default NewFeatureWarning