import React from "react"
import { useHistory, useParams } from "react-router"

import Button from "@material-ui/core/Button"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

import useStyles from "./styles"
import { ICompanyUserInvitation } from "../../../types/company"
import { useTranslation } from "react-i18next"

import iKLogo from "../../../assets/images/logo.png"
import { useDispatch, useSelector } from "react-redux"
import api from "../../../api/iskibris/iskibris"
import { CircularProgress } from "@material-ui/core"
import { flashActions } from "../../../store/actions/flash-messaging"
import Cookies from "js-cookie"
import { Alert } from "@material-ui/lab"
import { ErrorOutline } from "@material-ui/icons"
import useSearchParams from "../../../hooks/useSearchParams"
import { companyActions } from "../../../store/actions/company"
// import Signup from "../../../components/Authentication/Signup"
// import ForgotPassword from "../../../components/Authentication/ForgotPassword"
// import Signin from "../../../components/Authentication/Signin"

interface Props {}

const CompanyUserInvitation: React.FC<Props> = props => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const params: { id: any } = useParams()

  const id = parseInt(params.id)

  // const [authTab, setAuthTab] = React.useState("login")

  const isAuthenticated: boolean = useSelector((state: any) => state.authReducer.isAuthenticated)

  const [loading, setLoading] = React.useState<any>(null)

  const [searchAsObject] = useSearchParams()

  const [fetchError, setFetchError] = React.useState<any>(null)
  const [invitation, setInvitation] = React.useState<ICompanyUserInvitation | null>(null)

  React.useEffect(() => {
    if(isAuthenticated){
      api
        .get(`/api/hiring/v2/company-user-invitations/${id}`)
        .then(response => {
          setInvitation(response.data)
        })
        .catch(error => {
          setFetchError(true)
        })
    }
  }, [id, isAuthenticated])

  const handleAccept = async () => {
    setLoading("accept")
    await api
      .post(`/api/hiring/v2/company-user-invitations/${id}/respond`, { status: "accepted" })
      .then(async (response) => {
        dispatch(flashActions.addFlashMessage(t("company_user_invitation_accept_success"), "success"))
        localStorage.setItem("current_company_id", invitation?.company?.id.toString() ?? "")
        Cookies.set("CompanyToken", invitation?.company?.token ?? "", { secure: true, sameSite: "none" })
        dispatch(await companyActions.fetchUserManagedCompanies())
        history.push("/dashboard")
      })
      .catch(error => {
        dispatch(flashActions.addFlashMessage(t("company_user_invitation_accept_error"), "error"))
      })
    setLoading(null)
  }

  const handleReject = async () => {
    setLoading("decline")
    await api
      .post(`/api/hiring/v2/company-user-invitations/${id}/respond`, { status: "rejected" })
      .then(response => {
        dispatch(flashActions.addFlashMessage(t("company_user_invitation_reject_success"), "success"))
        history.push("/")
      })
      .catch(error => {
        dispatch(flashActions.addFlashMessage(t("company_user_invitation_reject_error"), "error"))
      })
    setLoading(null)
  }

  const getRedirectUrl = () => {
    if(searchAsObject?.registered !== "1"){
      return `/join-your-team?email=${searchAsObject?.email}&team=${searchAsObject?.team}`
    }
    return  "/login"
  }

  if(!isAuthenticated){
    history.push(getRedirectUrl(), { from: history.location, team: invitation?.company?.name})
  }

  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
            <div className={classes.logoBar}>
              <img src={iKLogo} alt="Is Kibris" className={classes.logotypeImage} />
              <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                {t("for_employers")}
              </Typography>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <main className="content content--with-container" style={{ paddingTop: "62px" }}>
        {isAuthenticated && <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          {fetchError && (
            <>
              <Alert severity="error" icon={<ErrorOutline />} style={{ borderRadius: "5px", marginBottom: "20px", alignItems: "center", fontSize: "1rem", width: "100%" }}>
                {t("invitation_fetch_error")}
              </Alert>

              <Button
                color="primary"
                variant="text"
                onClick={() => {
                  history.push("/")
                }}
              >
                {t("go_to_home")}
              </Button>
            </>
          )}
          {invitation ? (
            <>
              <div className={classes.innerDiv}>
                <Typography variant="h6" style={{ textAlign: "center", marginBottom: "1rem" }}>
                  {t("company_user_invitation_title", { company_name: invitation.company?.name })}
                </Typography>
                <Typography variant="body1" style={{ textAlign: "center", marginBottom: "1rem" }}>
                  {t("company_user_invitation_body", {
                    inviter_name: invitation.invited_by?.name,
                    company_name: invitation.company?.name,
                    role_name: JSON.parse(invitation.metadata).roles
                  })}
                </Typography>
                <div style={{ margin: "15px", display: "flex", justifyContent: "space-evenly", alignItems: "center", width: "100%" }}>
                  <Button color="primary" variant="outlined" onClick={handleReject}>
                    {loading === "decline" ? <CircularProgress size={22} /> : t("decline")}
                  </Button>
                  <Button color="primary" variant="contained" onClick={handleAccept}>
                    {loading === "accept" ? <CircularProgress size={22} /> : t("accept")}
                  </Button>
                </div>
              </div>
            </>
          ) : (
            !fetchError && (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "60vh" }}>
                <CircularProgress />
              </div>
            )
          )}
        </div>}
        {/* {
          !isAuthenticated && (
            
            <Redirect
              to={{
                pathname: getRedirectUrl(),
                state: { from: history.location, team: invitation?.company?.name}
              }}
            />
          )
        } */}
      </main>
    </div>
  )
}

export default CompanyUserInvitation
