import React from "react"
import { Dispatch } from "redux"
import { connect, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { ICompany } from "../../../types/company"

import SearchIcon from "@material-ui/icons/Search"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import useStyles from "./styles"
import RatingsFilter from "./RatingsFilter"
import { IActionType } from "../../../types"
import { applicantsActions } from "../../../store/actions/applicants"
import { IApplicantFilters, IPaginatedApplicants } from "../../../types/applicants"

import JobsFilter from "./JobsFilter"
import MoreFilters from "./MoreFilters"
// import StageFilter from "./StageFilter"
import ActiveFilter from "./ActiveFilter"
// import LocationFilter from "./LocationFilter"
import WorkPermitFilter from "./WorkPermitFilter"
import EducationLevelFilter from "./EducationLevelFilter"
// import FieldsOfStudyGroupedFilters from './FieldsOfStudyGroupedFilters';
import SearchBar from "./SearchBar"
import FilterTags from "./FilterTags"
// import FieldsOfStudyFilters from './FieldsOfStudyFilters';
import FieldsOfStudyFiltersSelectAll from "./FieldsOfStudyFiltersSelectAll"
import { useHistory } from "react-router"
import { applicationAge, driverLicenceCategories } from "../../../utils/filters"
import StageEnumFilter from "./StageEnumFilter"
import FolderFilter from "./FolderFilter"
import PlacesFilter from "./PlacesFilter"
import SortBy from "../SortBy"

interface Props {
  company: ICompany
  searchOpen: boolean
  activeFilter: boolean
  filters: IApplicantFilters
  exports: IPaginatedApplicants
  applicants: IPaginatedApplicants
  applyFilters: (filters: any, page: number, orderBy: string | null, orderDirection: string | null) => void
  changeSearchOpen: () => void
}

const ApplicantFilters: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const matchesLg = useMediaQuery("(min-width:1500px)")

  const orderBy = useSelector((state: any) => state.applicantsReducer.orderBy)
  const orderDirection = useSelector((state: any) => state.applicantsReducer.orderDirection)

  const handleSearchClick = () => {
    props.changeSearchOpen()
  }

  const checkFiltersAvailable = () => {
    if (
      props.filters.rating?.length > 0 ||
      props.filters.stage?.length > 0 ||
      props.filters.stage_enum?.length > 0 ||
      props.filters.city?.length > 0 ||
      props.filters.place?.length > 0 ||
      props.filters.job?.length > 0 ||
      props.filters.field_of_study?.length > 0 ||
      props.filters.work_permit?.length > 0 ||
      props.filters.education_level?.length > 0 ||
      props.filters.gender?.length > 0 ||
      props.filters.folder?.length > 0 ||
      props.filters.age?.length > 0 ||
      props.filters.driver_licence?.length > 0 ||
      props.filters.keyword
    ) {
      return true
    } else {
      return false
    }
  }

  const applyFilters = async (filters: any, page: number) => {
    if (history.location.pathname !== "/applicants") {
      history.push("/applicants")
    }
    await props.applyFilters(filters, page, orderBy, orderDirection)
  }

  return (
    <div className={classes.root}>
      <div className={classes.filtersRoot}>
        <div>
          <ActiveFilter company={props.company} disabled={false} />
        </div>
        <div className={classes.parentFiltersDiv}>
          <div className={classes.filtersDiv}>
            <Typography variant="body2">{t("filter_by")}:</Typography>
            <RatingsFilter company={props.company} filters={props.filters} applicants={props.exports} activeFilter={props.activeFilter} applyFilters={applyFilters} />
            {/* <StageFilter company={props.company} filters={props.filters} applicants={props.exports} activeFilter={props.activeFilter} applyFilters={applyFilters} /> */}
            <StageEnumFilter  company={props.company} filters={props.filters} applicants={props.exports} activeFilter={props.activeFilter} applyFilters={applyFilters} />
            <PlacesFilter company={props.company} filters={props.filters} activeFilter={props.activeFilter} applyFilters={applyFilters} />
            <JobsFilter company={props.company} filters={props.filters} applicants={props.exports} activeFilter={props.activeFilter} applyFilters={applyFilters} />
            <FieldsOfStudyFiltersSelectAll applyFilters={applyFilters} />
            <FolderFilter applyFilters={applyFilters} />
            {/* <FieldsOfStudyFilters
              applyFilters={applyFilters}
            /> */}
            {/* <FieldsOfStudyGroupedFilters
              applyFilters={applyFilters}
            /> */}
            {matchesLg ? (
              <React.Fragment>
                {/* <LocationFilter company={props.company} filters={props.filters} applicants={props.exports} activeFilter={props.activeFilter} applyFilters={applyFilters} /> */}
                <WorkPermitFilter company={props.company} filters={props.filters} applicants={props.exports} activeFilter={props.activeFilter} applyFilters={applyFilters} />
                <EducationLevelFilter company={props.company} filters={props.filters} applicants={props.exports} activeFilter={props.activeFilter} applyFilters={applyFilters} />
              </React.Fragment>
            ) : null}
            <MoreFilters
              company={props.company}
              filters={props.filters}
              applicants={props.exports}
              activeFilter={props.activeFilter}
              applyFilters={applyFilters}
              options={
                !matchesLg
                  ? [
                      {
                        value: "gender",
                        label: t("gender"),
                        filters: [
                          { value: "male", label: t("male"), quantity: 0, field: "gender" },
                          { value: "female", label: t("female"), quantity: 0, field: "gender" }
                        ]
                      },
                      {
                        value: "age",
                        label: t("application_age_filter_label"),
                        isMultiSelect: false,
                        filters: applicationAge.map(option => ({ value: option, label: t(`application_age_${option}`), quantity: 0, field: "age" }))
                      },
                      {
                        value: "driver_licence",
                        label: t("driver_licence"),
                        filters: driverLicenceCategories.map(option => ({ value: option, label: t(`driver_licence_category_id_${option}`), quantity: 0, field: "driver_licence" }))
                      },
                      // {
                      //   value: "city",
                      //   label: t("city_filter_label"),
                      //   filters: [
                      //     { value: 0, label: t(`city_id_0`), quantity: 0, field: "city_id" },
                      //     { value: 1, label: t(`city_id_1`), quantity: 0, field: "city_id" },
                      //     { value: 2, label: t(`city_id_2`), quantity: 0, field: "city_id" },
                      //     { value: 3, label: t(`city_id_3`), quantity: 0, field: "city_id" },
                      //     { value: 4, label: t(`city_id_4`), quantity: 0, field: "city_id" },
                      //     { value: 5, label: t(`city_id_4`), quantity: 0, field: "city_id" },
                      //     { value: 6, label: t(`city_id_4`), quantity: 0, field: "city_id" }
                      //   ]
                      // },
                      {
                        value: "education_level",
                        label: t("education_level_filter_label"),
                        filters: [
                          { value: 0, label: t(`education_level_id_0`), quantity: 0, field: "education_level_id" },
                          { value: 1, label: t(`education_level_id_1`), quantity: 0, field: "education_level_id" },
                          { value: 2, label: t(`education_level_id_2`), quantity: 0, field: "education_level_id" }
                        ]
                      },
                      {
                        value: "work_permit",
                        label: t("work_permit_filter_label"),
                        filters: [
                          { value: 0, label: t(`work_permit_id_0`), quantity: 0, field: "work_permit" },
                          { value: 1, label: t(`work_permit_id_1`), quantity: 0, field: "work_permit" },
                          { value: 2, label: t(`work_permit_id_2`), quantity: 0, field: "work_permit" },
                          { value: 3, label: t(`work_permit_id_3`), quantity: 0, field: "work_permit" },
                          { value: 4, label: t(`work_permit_id_4`), quantity: 0, field: "work_permit" }
                        ]
                      }
                    ]
                  : [
                      {
                        value: "gender",
                        label: t("gender"),
                        filters: [
                          { value: "male", label: t("male"), quantity: 0, field: "gender" },
                          { value: "female", label: t("female"), quantity: 0, field: "gender" }
                        ],
                      },
                      {
                        value: "driver_licence",
                        label: t("driver_licence"),
                        filters: driverLicenceCategories.map(option => ({ value: option, label: t(`driver_licence_category_id_${option}`), quantity: 0, field: "driver_licence" }))
                      },
                      {
                        value: "age",
                        label: t("application_age_filter_label"),
                        isMultiSelect: false,
                        filters: applicationAge.map(option => ({ value: option, label: t(`application_age_${option}`), quantity: 0, field: "age" }))
                      },
                    ]
              }
            />
          </div>
          <div>
            <IconButton color="primary" onClick={handleSearchClick}>
              <SearchIcon></SearchIcon>
            </IconButton>
            <SortBy variant="dense" />
          </div>
        </div>
      </div>
      {props.searchOpen && (
        <>
          <SearchBar company={props.company} filters={props.filters} activeFilter={props.activeFilter} applyFilters={applyFilters} />
        </>
      )}
      <div>{checkFiltersAvailable() ? <FilterTags /> : null}</div>
      {
        // matchesMobile
        // ?
        // (
        //   <MobileFilters />
        // )
        // :
        // (
        //   null
        // )
      }
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  company: state.companyReducer.company,
  exports: state.applicantsReducer.exports,
  filters: state.applicantsReducer.filters,
  searchOpen: state.applicantsReducer.searchOpen,
  applicants: state.applicantsReducer.applicants,
  activeFilter: state.applicantsReducer.activeFilter
})

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => ({
  applyFilters: async (filters: any, page: number, orderBy: string | null, orderDirection: string | null) =>
    dispatch(await applicantsActions.applyFilters(filters, page, orderBy, orderDirection)),
  changeSearchOpen: () => dispatch(applicantsActions.changeSearchOpen())
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantFilters)
