import React from "react"
import { useTranslation } from "react-i18next"

import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import InputAdornment from "@material-ui/core/InputAdornment"

// Logos
import fbLogo from "../../../../assets/images/fb.png"
import twLogo from "../../../../assets/images/tw.png"
import inLogo from "../../../../assets/images/linkedin.png"
import ytLogo from "../../../../assets/images/yt.png"
import igLogo from "../../../../assets/images/ig.png"

interface Props {
  register: any
  formFields: any
  editFormFields: (value: any) => void
}

const SocialMedia: React.FC<Props> = props => {
  const { t } = useTranslation()

  const inputs = [
    { key: "facebook", domain: "www.facebook.com/profile", label: t("facebook_profile_label"), prefixIcon: fbLogo },
    { key: "twitter", domain: "www.twitter.com/profile", label: t("twitter_profile_label"), prefixIcon: twLogo },
    { key: "linkedin", domain: "www.linkedin.com/profile", label: t("linkedin_profile_label"), prefixIcon: inLogo },
    { key: "instagram", domain: "www.instagram.com/profile", label: t("instagram_profile_label"), prefixIcon: igLogo },
    { key: "youtube", domain: "www.youtube.com/profile", label: t("youtube_profile_label"), prefixIcon: ytLogo }
  ]

  const socialLinks = props.formFields.social_links

  const changeValues = (name: any) => (event: any) => {
    const newSocialsLinks = {
      ...socialLinks,
      [name]: event.target.value
    }
    const info = {
      social_links: newSocialsLinks
    }
    props.editFormFields({ ...info })
  }

  return (
    <React.Fragment>
      <Typography variant="h6">{t("social_media")}</Typography>
      {inputs.map(element => {
        return (
          <FormControl key={element.key} style={{ width: "100%", paddingTop: "10px" }}>
            <TextField
              margin="dense"
              fullWidth
              variant="outlined"
              label={element.label}
              value={socialLinks ? socialLinks[element.key] || "" : ""}
              onChange={changeValues(element.key)}
              placeholder={element.domain}
              name={"social_media"}
              id={`social_media_${element.key}`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ paddingLeft: "-14px", margin: "0px" }}>
                    <img src={element.prefixIcon} alt={element.key} style={{ width: 25, marginRight: 8 }} />
                  </InputAdornment>
                )
              }}
              style={{ padding: "0px" }}
            />
          </FormControl>
        )
      })}
    </React.Fragment>
  )
}

export default SocialMedia
