import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    bottom: 0,
    position: "absolute"
  },
  backdrop: {
    backgroundColor: "rgba(235, 235, 235, 0.5)"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none !important"
  }
  // modalPaper: {
  //   backgroundColor: theme.palette.background.paper,
  //   boxShadow: theme.shadows[5],
  //   bottom: 0,
  //   position: "absolute",
  //   maxHeight: "80%",
  //   // height: "100%",
  //   width: "100%",
  //   overflowY: "auto",
  //   outline: "none",
  //   borderRadius: "10px 10px 0px 0px"
  // },
  // toolbar: {
  //   display: "flex",
  //   justifyContent: "space-between",
  //   alignItems: "center",
  //   borderBottom: "1px solid #979797"
  // },
  // contents: {
  //   height: "calc(80% - 50px)",
  //   overflowY: "auto"
  // },
}))

export default useStyles
