import React from "react"

import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useTheme from "@material-ui/core/styles/useTheme"
import MailIcon from "@material-ui/icons/MailOutline"

import useStyles from "./styles"
import { applicantsActions } from "../../../../store/actions/applicants"
import { Dispatch } from "redux"
import { IActionType } from "../../../../types"
import { messagesActions } from "../../../../store/actions/messages"
import ApplicantRating from "../../ApplicantDetails/ApplicantRating"
import ApplicantStage from "../../ApplicantDetails/ApplicantStage"
import { useTranslation } from "react-i18next"
import { IApplicant } from "../../../../types/applicants"
import { IFieldOfStudy, IUser } from "../../../../types/user"
import { connect } from "react-redux"
import useGtm from "../../../../hooks/useGtm"
import ApplicantStageEnum from "../../ApplicantDetails/ApplicantStageEnum"

interface Props {
  applicant: IApplicant
  currentUserInformation: IUser
  fieldsOfStudy: IFieldOfStudy[]
  closeSendMessage: () => void
  addSingleComment: (info: any) => void
  fetchUserInformation: (id: any) => void
  openSendMessage: (recipients: any) => void
  updateApplicantRating: (id: any, value: any) => void
  updateApplicantStatus: (id: any, value: any) => void
  updateApplicantRatingRealtime: (id: any, info: any) => void
  updateApplicantStageRealtime: (id: any, info: any) => void
}

const ApplicantRatingStatus: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { tagEvent } = useGtm()

  const { applicant } = props

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("xs"))

  const handleUpdateApplicantStatus = async (value: any) => {
    tagEvent({ event: "applicant_status_changed", data: { applicant_old_status: props.applicant.stage_id, applicant_new_status: value } })
    await props.updateApplicantStatus(props.applicant.id, value)
  }

  const handleUpdateApplicantRating = async (value: any) => {
    tagEvent({ event: "applicant_rating_changed", data: { applicant_old_rating: props.applicant.rating_id, applicant_new_rating: value } })
    await props.updateApplicantRating(props.applicant.id, value)
  }

  const handleSendMessage = () => {
    console.log("Send message clicked")
    tagEvent({
      event: "compose_message_click",
      data: {
        applicant_id: applicant?.id,
        applicant_name: applicant?.name,
        applicant_work_permit_id: applicant?.work_permit_id,
        applicant_work_permit: t(`work_permit_id_${applicant?.work_permit_id}`)
      }
    })
    props.openSendMessage([{ ...props.applicant }])
  }

  return (
    <div className={classes.root}>
      <div className={classes.modalPaper}>
        <Button variant="outlined" className={classes.showMessagingButton} onClick={handleSendMessage}>
          <MailIcon style={{}} />
          <Typography variant="button" className={classes.sendMessageText}>
            {t("send_message")}
          </Typography>
        </Button>
        {matchesMobile ? (
          <React.Fragment>
            <ApplicantRating readOnly={false} rating={props.applicant.rating_id} handleUpdate={handleUpdateApplicantRating} />
            <ApplicantStage readOnly={false} stage={props.applicant.stage_id} handleUpdate={handleUpdateApplicantStatus} />
          </React.Fragment>
        ) : (
          <div className={classes.ratingStatusContainer}>
            <ApplicantRating readOnly={false} rating={props.applicant.rating_id} handleUpdate={handleUpdateApplicantRating} />
            <ApplicantStageEnum readOnly={false} stageEnum={props.applicant.stage_id_enum} handleUpdate={handleUpdateApplicantStatus} />
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    applicant: state.applicantsReducer.singleApplicant,
    fieldsOfStudy: state.applicantsReducer.fieldsOfStudy,
    currentUserInformation: state.applicantsReducer.currentUserInformation,
    permissions: state.userReducer.permissions
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => ({
  closeSendMessage: () => dispatch(messagesActions.closeSendMessage()),
  addSingleComment: (info: any) => dispatch(applicantsActions.addSingleComment(info)),
  openSendMessage: (recipients: any[]) => dispatch(messagesActions.openSendMessage(recipients)),
  fetchUserInformation: async (id: any) => dispatch(await applicantsActions.fetchCurrentApplicantUserInformation(id)),
  updateApplicantStageRealtime: (id: any, info: any) => dispatch(applicantsActions.updateApplicantStageRealtime(id, info)),
  updateApplicantRating: async (id: any, value: any) => dispatch(await applicantsActions.updateApplicantRating(id, value)),
  updateApplicantStatus: async (id: any, value: any) => dispatch(await applicantsActions.updateApplicantStatus(id, value)),
  updateApplicantRatingRealtime: (id: any, info: any) => dispatch(applicantsActions.updateApplicantRatingRealtime(id, info))
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantRatingStatus)
