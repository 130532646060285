import React from "react"
import { Dispatch } from "redux"
import { useTranslation } from "react-i18next"
import { connect, useDispatch, useSelector } from "react-redux"

import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"

import Photos from "./Photos"
import CompanyLogo from "./CompanyLogo"
import CompanyCoverPhoto from "./CompanyCoverPhoto"
import OpenGraphTagsEditor from "../../OpenGraphTagsEditor"

import iskibris from "../../../api/iskibris"
import { IActionType } from "../../../types"
import HTTPResponses from "../../../tools/http.responses"
import { companyActions } from "../../../store/actions/company"
import { ICompany, ICompanyPhotos } from "../../../types/company"
import { flashActions } from "../../../store/actions/flash-messaging"
import Can from "../../../utils/AccessControl/Can"
// import { InfoOutlined } from "@material-ui/icons"
// import CustomTooltip from "../../CustomTooltip"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2, 2)
  },
  card: {
    maxWidth: 90,
    transition: "0.5s"
  },
  overlay: {
    "&:hover": {
      backgroundColor: "#000000",
      opacity: "0.4!important"
    },
    transition: "0.5s"
  },
  subtitleText: {
    color: "#797979"
  },
  media: {
    height: 90,
    transition: "0.5s"
  },
  link: {
    textDecoration: "none"
  },
  photo: {
    width: "200px",
    height: "200px"
  }
}))

interface Props {
  company: ICompany
  photos: ICompanyPhotos[]
  getCompanyPhotos: (id: any) => void
}

const CompnayPhotos: React.FC<Props> = props => {
  const classes = useStyles()

  const { t } = useTranslation()

  const { photos, company, getCompanyPhotos } = props

  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.companyReducer)

  const onSubmit = async (fields?: any) => {
    var data = new FormData()

    if (fields?.image) {
      data.append("og_image", fields.image)
    }

    const fieldsInfo = {
      title: fields?.title,
      description: fields?.description
    }

    if (!fieldsInfo.title) {
      delete fieldsInfo.title
    }

    if (!fieldsInfo.description) {
      delete fieldsInfo.description
    }

    data.append("open_graph_tags", JSON.stringify(fieldsInfo))

    await iskibris
      .post(`/api/hiring/v2/companies/${state.company?.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json"
        }
      })
      .then(async response => {
        dispatch(flashActions.addFlashMessage(t("company_og_tags_updated_success"), "success"))
        dispatch(await companyActions.fetchSingleCompany(state.company?.id))
      })
      .catch(error => {
        if (!error?.response) {
          dispatch(flashActions.addFlashMessage(t("network_error"), "error"))
          return
        }
        if (error?.response?.status === HTTPResponses.INTERNAL_SERVER_ERROR) {
          dispatch(flashActions.addFlashMessage(t("server_error_fail"), "error"))
          return
        }
        dispatch(flashActions.addFlashMessage(t("company_og_tags_updated_error"), "error"))
      })
  }

  React.useEffect(() => {
    if (company && !photos) {
      getCompanyPhotos(company.id)
    }
  }, [company, photos, getCompanyPhotos])

  const handleOpenGraphTagsChange = (data: any) => {}

  const handleOpenGraphTagsOnFocusLeave = async (data: any) => {
    await onSubmit(data)
  }

  return (
    <div>
      {props.company ? (
        <React.Fragment>
          <Grid container>
            <Grid item sm={12} md={9} lg={7}>
              <Paper className={classes.paper}>
                <div>
                  <Typography variant="h6" style={{ display: "inline" }} gutterBottom>
                    {t("logo")}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.subtitleText}>
                    {t("upload_logo_subtext")}
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom className={classes.subtitleText}>
                    {t("upload_logo_dimensions")}
                  </Typography>
                  <CompanyLogo company={props.company} />
                </div>
              </Paper>
              <Paper className={classes.paper} style={{ marginTop: "20px" }}>
                <div>
                  <Typography variant="h6" style={{ display: "inline" }} gutterBottom>
                    {t("cover_photo")}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.subtitleText}>
                    {t("upload_cover_photo_subtext")}
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom className={classes.subtitleText}>
                    {t("upload_cover_photo_dimensions")}
                  </Typography> 
                  <CompanyCoverPhoto company={props.company} />
                </div>
              </Paper>
              <Can I="customize-company-open-graph-tags" a="all">
                <Paper className={classes.paper} style={{ marginTop: "20px" }}>
                  <div>
                    <Typography variant="h6" style={{ display: "inline" }} gutterBottom>
                      {t("open_graph_header")}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom className={classes.subtitleText}>
                      {t("open_graph_subtitle")}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom className={classes.subtitleText}>
                      {t("photo_dimensions", {'dimensions': "200px x 200px", 'ratio': "1 : 1"})}
                    </Typography> 
                    <OpenGraphTagsEditor onFocusLeave={handleOpenGraphTagsOnFocusLeave} onChange={handleOpenGraphTagsChange} defaultTags={props.company?.open_graph_tags} />
                  </div>
                </Paper>
              </Can>
              <Paper className={classes.paper} style={{ marginTop: "20px" }}>
                <div>
                  <Photos />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
      ) : (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: "70vh" }}>
          <Grid item xs={3}>
            <CircularProgress size={50} />
          </Grid>
        </Grid>
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    photos: state.companyReducer.photos
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    getCompanyPhotos: async (companyId: any) => dispatch(await companyActions.fetchCompanyPhotos(companyId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompnayPhotos)
