import React from "react"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"

import useStyles from "../../../styles"
import { IUser } from "../../../../../../types/user"
import { IActionType } from "../../../../../../types"
import { IApplicant } from "../../../../../../types/applicants"

import { applicantsActions } from "../../../../../../store/actions/applicants"

interface Props {
  user: IUser
  applicant: IApplicant
  onSuccess?: (note?: any) => void
  closeAddNotes: () => void
  submitNote: (id: any, values: any) => void
}

const AddNote: React.FC<Props> = props => {
  const classes = useStyles()

  const [notes, setNotes] = React.useState("")
  const [loading, setLoading] = React.useState(false)

  const { t } = useTranslation()

  const handleChange = (event: any) => {
    setNotes(event.target.value)
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setLoading(true)
    let note = {
      author_id: props.user.id,
      applicant_id: props.applicant.id,
      body: notes
    }
    await props.submitNote(props.applicant.id, note)
    setLoading(false)
    if(props.onSuccess){
      props.onSuccess(note)
    }
    props.closeAddNotes()
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <TextField
          required
          value={notes}
          onChange={handleChange}
          variant="outlined"
          margin="dense"
          multiline
          fullWidth
          minRows={4}
          autoFocus
          placeholder={t("add_note_placeholder")}
        />
        <div className={classes.buttonArea}>
          <Button
            onClick={() => {
              props.closeAddNotes()
            }}
            className={classes.cancelButton}
            variant="outlined"
            color="primary"
          >
            {t("cancel")}
          </Button>
          <Button className={classes.addButton} variant="contained" color="primary" type="submit">
            {loading ? <CircularProgress size={22} style={{ color: "white" }} /> : t("save")}
          </Button>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.userReducer.profile,
    applicant: state.applicantsReducer.singleApplicant
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => ({
  submitNote: async (id: any, values: any) => dispatch(await applicantsActions.createComment(id, values))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNote)
