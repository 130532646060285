import React from "react"
import { useHistory } from "react-router";

interface Props {
  to?: string;
}

const RouteRedirect: React.FC<Props> = ({ to, ...rest }: any) => {

  const query = window.location.search;

  const history = useHistory()

  history.replace(`${to}${query}`, {...rest})

  // return <Redirect
  //   to={{
  //     pathname: `${to}${query}`,
  //     state: { ...rest }
  //   }}
  // />

  return <></>
}

export default RouteRedirect
