import React from "react"
import { useTranslation } from "react-i18next"

import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import GetAppIcon from "@material-ui/icons/GetApp"

import useStyles from "../../styles"
import CVViewer from "../../../../CVViewer"
import { IApplicant } from "../../../../../types/applicants"
import { fileUrlMiddleware } from "../../../../../utils/fileUrlParser"
import useGtm from "../../../../../hooks/useGtm"
import { ICompany } from "../../../../../types/company"
import { IUser } from "../../../../../types/user"

interface Props {
  user: IUser
  company: ICompany
  applicant: IApplicant
}

const CandidateResume: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { tagEvent } = useGtm()

  const { user, company } = props

  const handleResumeDownloadClick = () => {
    tagEvent({ event: "cv_download_click", data: { company_name: company?.name, user_id: user?.id, user_name: user?.full_name } })
  }

  return (
    <div
      className={classes.applicantInfoCard}
      id="#resume"
      // ref={props.resumeRef}
    >
      <div className={classes.infoCardHeader}>
        <Typography variant="body1" style={{ display: "inline", fontWeight: "bold" }}>
          {t("resume")}
        </Typography>
        {props.applicant.resume_url ? (
          <div onClick={handleResumeDownloadClick}>
            <Button className={classes.downloadResumeButton} href={fileUrlMiddleware(props.applicant.resume_url)} target="_blank" download>
              <GetAppIcon /> {t("download")}
            </Button>
          </div>
        ) : null}
      </div>
      {props.applicant.resume_url ? <CVViewer resume_url={props.applicant.resume_url} /> : <Typography variant="body2">{t("no_cv_body")}</Typography>}
    </div>
  )
}

export default CandidateResume
