import React from "react"

import CustomTooltip from "../CustomTooltip"

import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

interface Props {
  message: string
  link?: string
  linkLabel?: string
  children: React.ReactNode
}

const IKTooltip: React.FC<Props> = props => {
  return (
    <CustomTooltip
      title={
        <div style={{ padding: "8px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="body2">{props.message}</Typography>
          {props.link && props.linkLabel && (
            <Button color="secondary" href={props.link} style={{ marginTop: "10px" }}>
              {props.linkLabel}
            </Button>
          )}
        </div>
      }
      interactive
      arrow
    >
      <div>{props.children}</div>
    </CustomTooltip>
  )
}

export default IKTooltip
