export const applicantStages = [
  'NEW',
  'RESUME_SCREENING',
  'WAITING_FOR_INTERVIEW',
  'WAITING_FOR_JOB_SEEKER',
  'TO_INTERVIEW_IN_PERSON',
  'REFERENCES_AND_BACKGROUND_CHECK',
  'FINAL_DECISION_WAITING_LIST',
  'JOB_OFFERING',
  'REJECTED',
  'CANDIDATE_WITHDREW',
  'HIRED',
]

export const applicantActiveStages = [
  'NEW',
  'RESUME_SCREENING',
  'WAITING_FOR_INTERVIEW',
  'WAITING_FOR_JOB_SEEKER',
  'TO_INTERVIEW_IN_PERSON',
  'REFERENCES_AND_BACKGROUND_CHECK',
  'FINAL_DECISION_WAITING_LIST',
  'JOB_OFFERING',
]