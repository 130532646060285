import React from "react"
import { Draggable } from "@hello-pangea/dnd"
import { Avatar, Divider, IconButton, Typography } from "@material-ui/core"

// Icons
import InterestedIcon from "@material-ui/icons/CheckCircle"
import NotInterestedIcon from "@material-ui/icons/CancelRounded"
import UnratedIcon from "@material-ui/icons/FiberManualRecord"
import MaybeIcon from "@material-ui/icons/Help"

import dayjs from "dayjs"

import {
  AccessTimeOutlined,
  ArrowDropDown,
  BusinessCenterOutlined,
  LocationOnOutlined
} from "@material-ui/icons"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { getPlaceNameRecursively } from "../../../../../utils/places"


interface Props {
  item: any
  index: any
  onClick?: (application: any) => void
}

const CoverLetter = styled.p`
  font-size: 0.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
          line-clamp: 2; 
  -webkit-box-orient: vertical;
`;

const ColumnItem: React.FC<Props> = ({ item, index, onClick }) => {

  const { i18n } = useTranslation()

  const handleClick = () => {
    console.log("handleClick: ", item)
    if(onClick){
      onClick(item)
    }
  }


  const {t} = useTranslation()
  
  const getRatingIcon = (width?: any, height?: any) => {
    let ratingIcon: any
    switch (item.rating_id) {
      case 1:
        ratingIcon = <InterestedIcon style={{width: width ?? "15px", height: height ?? "15px"}} className={"icon icon--interested"} />
        break
      case 2:
        ratingIcon = <MaybeIcon style={{width: width ?? "15px", height: height ?? "15px"}} className={"icon icon--maybe"} />
        break
      case 3:
        ratingIcon = <NotInterestedIcon style={{width: width ?? "15px", height: height ?? "15px"}} className={"icon icon--not-interested"} />
        break
      default:
        ratingIcon = <UnratedIcon style={{width: width ?? "15px", height: height ?? "15px"}} className={"icon icon--unrated"} />
        break
    }

    return ratingIcon
  }

  const ratingColors: any = {
    rating_id_1: "#4dca6c",
    rating_id_2: "#ffdd1b",
    rating_id_3: "#db5050",
    rating_id_0: "#fff",
  }

  const parseDate = (date: any) => {
    if (typeof date === "number") {
      return dayjs.unix(date).toString()
    } else {
      return dayjs(date).toString()
    }
  }

  const infoBadge = (icon: any, label: any) => {
    return (<div style={{display: "flex", alignItems: "center", border: "1px solid #eaeaea", background: "#fff", borderRadius: "3px", padding: "3px 10px", gap: "5px"}}>
      {icon}
      <Typography style={{fontSize: "13px"}}>{label}</Typography>
    </div>)
  }

  const getName = () => {
    return item.name ? item.name[0].toLocaleUpperCase() + item.name.slice(1) : item.name
  }

  return (
    <Draggable draggableId={`${item.id}`} index={index}>
      {
        (provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              onClick={handleClick}
            >
              <div style={{background: "#fff", padding: "8px", margin: "5px", borderRadius: "5px", boxShadow: "rgba(0, 0, 0, 0.04) 0px 0px 3px 2px", borderLeft: `3px solid ${ratingColors[`rating_id_${item.rating_id}`]}`}}>
                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between"}}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "5px", marginBottom: "10px"}}>
                    <Avatar src={item.avatar_url} style={{width: "35px", height: "35px"}} />
                    <div>
                      <Typography variant="body1" style={{fontSize: "0.875rem", fontWeight: "bold"}}>{getName()}</Typography>
                      <Typography variant="body1" style={{fontSize: "0.675rem", fontWeight: "normal"}}>{dayjs(item.created_at).format('DD/MM/YYYY')}</Typography>
                    </div>
                  </div>
                  <div>
                    <div style={{border: "1px solid #eaeaea", borderRadius: "15px"}}>
                      <IconButton style={{padding: "0px"}}>
                        {getRatingIcon("20px", "20px")}
                        <ArrowDropDown />
                      </IconButton>
                    </div>
                  </div>
                </div>
                <Divider />
                {
                  item.cover_letter ? (
                    <>
                      <CoverLetter>{item.cover_letter}</CoverLetter>
                      <Divider />
                    </>
                  ) : null
                }
                <div style={{display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "column", gap: "5px", marginTop: "10px"}}>
                  {
                    infoBadge(<BusinessCenterOutlined style={{width: "15px", height: "15px"}}/>, `${item.job_title} - ${item.job_place_name}`)
                  }
                  {
                    infoBadge(getRatingIcon(), t(`rating_id_${item.rating_id}`))
                  }
                  {
                    item.place ? infoBadge(<LocationOnOutlined style={{width: "15px", height: "15px"}}/>, getPlaceNameRecursively(item.place, "", i18n.language)) : null
                  }
                  {
                    infoBadge(<AccessTimeOutlined style={{width: "15px", height: "15px"}}/>, item.created_at ? dayjs(item.created_at).format('DD/MM/YYYY HH:mm') : parseDate(item.date))
                  }
                </div>
              </div>
            </div>
          )
        }
      }
    </Draggable>
  )
}

export default ColumnItem;