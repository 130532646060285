import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    bottom: 0,
    flexGrow: 1,
    minHeight: 0,
    zIndex: 1301,
    width: "100%",
    display: "flex",
    maxWidth: "500px",
    maxHeight: "90vh",
    overflowY: "auto",
    position: "fixed",
    background: "#bfbfbf",
    flexDirection: "column",
    boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.33)"
  },

  headerSection: {
    // height: "10%",
    display: "flex",
    alignItems: "center",
    width: "100%"
  },

  messageSection: {
    height: "75%"
  },

  messageSectionMinimized: {
    height: "0%"
  },

  inputSection: {
    height: "15%",
    background: "white"
  },

  inputSectionExpanded: {
    height: "90%",
    overflowY: "auto",
    background: "white"
  },

  templateVarsContainer: {
    padding: "0px",
    width: "280px",
    transition: "0.5s",
    height: "450px",
    [theme.breakpoints.down("md")]: {
      height: "100%"
    }
  }
}))

export default useStyles
