import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2, 2),
    marginTop: "20px"
  },
  subheader: {
    borderBottom: "none",
    height: "20px",
    backgroundColor: "#F5F6F7"
  },
  tableRow: {
    borderBottom: "none"
  },
  tableCell: {
    borderBottom: "none",
    paddingTop: "0px",
    paddingBottom: "0px"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  cancelIconButton: {
    color: theme.palette.primary.dark
  },
  saveIconButton: {
    color: theme.palette.secondary.dark
  },
  divider: {
    height: 28,
    margin: 4
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%"
  }
}))

export default useStyles
