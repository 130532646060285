import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxWidth: "620px",
    width: "100%",
    outline: "none",
    position: "relative",
    maxHeight: "100%",
    overflow: "auto",
    padding: theme.spacing(2, 2),
  },
  toolbar: {
    backgroundColor: theme.palette.primary.dark,
    color: "#fff"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none"
  },
  tableCell: {
    borderBottom: "none !important"
  },
  modalCloseContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalTypographyTitle: {
    display: "flex",
    alignItems: "center",
    margin: "0px"
  },
  modalTypographyCaption: {
    color: "#393939"
  },
}))

export default useStyles
