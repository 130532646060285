import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { IoCheckmarkSharp } from "react-icons/io5"

import Link from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import { IOffer } from "../../../types/billing"
import * as Constants from "../../../config/constants"

interface Props {
  icon: any
  title: string
  offer: IOffer
  price: string
  offerId: number
  features: any
}

const SinglePackage: React.FC<Props> = props => {
  const { t } = useTranslation()

  const state = useSelector((state: any) => state.companyReducer)

  const singlePackageItem = (label: string) => (
    <div className="single-feature">
      <IoCheckmarkSharp size={20} className="single-feature--icon" />
      <Typography variant="body2">{label}</Typography>
    </div>
  )

  const getOriginalPrice = () => {
    const discountData = props.offer?.discount ?? props.offer?.package_details?.discount
    return discountData?.original_price;
  }

  // const getDiscountedPrice = () => {
  //   const discountData = props.offer?.discount ?? props.offer?.package_details?.discount
  //   return discountData?.discounted_price;
  // }

  const getDiscountedPercent = () => {
    const discountData = props.offer?.discount ?? props.offer?.package_details?.discount
    return discountData?.discount_percent;
  }

  return (
    <div className="single-package">
      <div>
        <div className="single-package__title-container">
          <Typography variant="h5" className="single-package__title-container--text">
            {props.title}
          </Typography>
          <Link href={`${Constants.BASE_URL}/api/hiring/v2/billing/${props.offer.uuid}/redirect?company=${state.company.id}`}>
            <Button color="primary" variant="contained" className="single-package--link">
              {t("buy_it")}
            </Button>
          </Link>

          {
            props.offer?.discount || props.offer?.package_details?.discount
            ?
            (
              <div className="single-package__discount-container">
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <Typography variant="body2" style={{marginRight: "0.5rem"}} className="single-package__og-price">
                    <s>{`₺${getOriginalPrice()}`}</s>
                  </Typography>
                  <Typography variant="body1" className="single-package__discounted-price">
                    <span>{`₺${props.price}`}</span>
                  </Typography>
                </div>
                <Typography variant="caption" className="single-package__discount-text" style={{marginTop: "0.5rem"}}>
                  {t("discount_deal_text", {discount_percent: `${getDiscountedPercent()}%`})}
                </Typography>
              </div>
            )
            :
            (
              <Typography variant="body1" className="single-package__title-container--text">{`₺${props.price}`}</Typography>
            )
          }
        </div>
        <div>
          {`${props.features.content}`
            .trim()
            .split("\n")
            .map((item: any) => {
              return <React.Fragment key={item}>{singlePackageItem(item)}</React.Fragment>
            })}
        </div>
      </div>
    </div>
  )
}

export default SinglePackage
