/* eslint-disable react/prop-types */
import React from "react"
import { useTranslation } from "react-i18next"

import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import ExpansionPanel from "@material-ui/core/Accordion"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CircularProgress from "@material-ui/core/CircularProgress"
import ExpansionPanelDetails from "@material-ui/core/AccordionDetails"
import ExpansionPanelSummary from "@material-ui/core/AccordionSummary"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

import useStyles from "./styles"
import { ICompany } from "../../../../../types/company"
import { IApplicantFilters, IApplicantSearchParams, IPaginatedApplicants } from "../../../../../types/applicants"
import dayjs from "dayjs"

interface SingleFilterOptionsProps {
  value: any
  icon?: any
  label: string
  field: any
  quantity?: number
}
interface MoreFilterOptionsProps {
  value: any
  label: string
  isMultiSelect?: boolean
  filters: SingleFilterOptionsProps[]
}

interface Props {
  company: ICompany
  activeFilter: boolean
  filters: IApplicantFilters
  applicants: IPaginatedApplicants
  options: MoreFilterOptionsProps[]
  handleGoBack: () => void
  applyFilters: (filters: IApplicantSearchParams, page: number) => void
}

const MoreFilters: React.FC<Props> = props => {
  const [loading, setLoading] = React.useState(false)
  const [expanded, setExpanded] = React.useState<any[]>([])
  const [moreFilters, setMoreFilters] = React.useState<any>({ ...props.filters })
  const classes = useStyles()

  const { t } = useTranslation()

  React.useEffect(() => {
    props.options.forEach(item => {
      item.filters.forEach(filter => {
        if (props.applicants) {
          if(filter.field === "driver_licence"){
            filter.quantity = [...props.applicants.data].filter((applicant: any) => (applicant["driver_licences"] ?? [])?.findIndex((item: any) => item === filter.value) !== -1).length
          } else if(filter.field === "age"){
            filter.quantity = [...props.applicants.data].filter((applicant: any) => dayjs.unix(applicant["created_at"]).isAfter(dayjs().subtract(filter.value, 'days')) ).length
          } else {
            filter.quantity = [...props.applicants.data].filter((applicant: any) => applicant[filter.field] === filter.value).length
          }
        }
      })
    })
  }, [props.applicants, props.options, t])

  const checkChecked = (panel: any, value: any) => {
    let result = false
    const panelFilters = moreFilters[panel]
    if (panelFilters) {
      panelFilters.forEach((element: any) => {
        if (value === element) {
          result = true
          return true
        }
      })
    }
    return result
  }

  const handleClickAway = () => {
    setMoreFilters({})
    setExpanded([])
    props.handleGoBack()
  }

  const handleFilterClick = (panel: any, value: any, isMultiSelect = true) => {
    const panelFilters = moreFilters[panel]
    if (panelFilters) {
      const ndx = panelFilters.findIndex((item: any) => value === item)
      if (ndx === -1) {
        let newArray: any[] = []

        // Select multiple options if isMultiSelect is set.
        if(isMultiSelect){
          newArray = [...panelFilters]
        }

        newArray.push(value)

        setMoreFilters({
          ...moreFilters,
          [panel]: newArray
        })
      } else {
        let newArray = [...panelFilters]
        newArray.splice(ndx, 1)
        setMoreFilters({
          ...moreFilters,
          [panel]: newArray
        })
      }
    } else {
      setMoreFilters({
        ...moreFilters,
        [panel]: [value]
      })
    }
  }

  const handleApplyButtonClick = async () => {
    setLoading(true)
    await props.applyFilters(
      {
        ...props.filters,
        activeFilter: props.activeFilter,
        companyId: props.company.id,
        ...moreFilters
      },
      1
    )
    setLoading(false)
  }

  const handleChangePanel = (panel: any) => (event: any, isExpanded: any) => {
    if (expanded.includes(panel)) {
      setExpanded(expanded.filter(item => item !== panel))
    } else {
      const newExpandedList = [...expanded]
      newExpandedList.push(panel)
      setExpanded(newExpandedList)
    }
  }

  return (
    <div className={classes.root + " va-middle"}>
      <div className={classes.listContainer}>
        {props.options.map(mainFilter => {
          const labelId = `checkbox-list-label-${mainFilter.value}`
          return (
            <ExpansionPanel
              component="div"
              style={{ borderBottom: "1px solid #eaeaea", boxShadow: "none" }}
              expanded={expanded.includes(mainFilter.value) || (Object.keys(moreFilters).includes(mainFilter.value) && moreFilters[mainFilter.value].length > 0)}
              onChange={handleChangePanel(mainFilter.value)}
              key={mainFilter.value}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header" style={{ fontWeight: "bold" }}>
                {mainFilter.label}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ flexDirection: "column", padding: "0px" }}>
                {mainFilter.filters.map(filter => {
                  return (
                    <ListItem
                      key={filter.value}
                      role={undefined}
                      dense
                      button
                      onClick={() => {
                        handleFilterClick(mainFilter.value, filter.value, mainFilter?.isMultiSelect)
                      }}
                    >
                      <ListItemIcon style={{ minWidth: "0px" }}>
                        <Checkbox edge="start" checked={checkChecked(mainFilter.value, filter.value)} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": labelId }} />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={`${filter.label}`} />
                      {filter.quantity !== null || filter.quantity !== undefined ? (
                        <ListItemSecondaryAction>
                          <div className={classes.counterBadge}>
                            <Typography variant="caption" style={{ padding: "7px", color: "#ffffff" }}>
                              {props.applicants ? filter.quantity : "-"}
                            </Typography>
                          </div>
                        </ListItemSecondaryAction>
                      ) : null}
                    </ListItem>
                  )
                })}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        })}
      </div>
      <div className={classes.buttonArea}>
        <Button color="primary" variant="contained" onClick={handleApplyButtonClick}>
          {loading ? <CircularProgress size={22} style={{ color: "#fff" }} /> : t("apply")}
        </Button>
        <Button color="primary" variant="outlined" onClick={handleClickAway}>
          {t("go_back")}
        </Button>
      </div>
    </div>
  )
}

export default MoreFilters
