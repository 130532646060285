import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: "1px solid #eaeaea",
    padding: "0px",
    cursor: "pointer",
    transition: "0.5s",
    listStyle: "none"
  },
  listItemAvatar: {
    paddingRight: "10px",
    margin: "0px",
    minWidth: "0px",
    color: "red",
    fontSize: "15px",
    alignItems: "center",
    display: "flex"
  },
  listItem: {
    padding: "0px",
    paddingTop: "5px",
    paddingBottom: "5px",
    listStyle: "none",
    transition: "0.5s",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#F1F2F3 !important",
      boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)"
    }
  },
  listItemRightSide: {
    // padding: "0px",
    // width: "20%",
    // overflow: "hidden",
  },
  email: {
    // fontSize: 14,
    color: "#5e5e5e"
  },
  roles: {
    // fontSize: 12,
    color: "#5e5e5e"
  }
}))

export default useStyles
