import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import Page from "../../components/layout/Page/Page"

import Link from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import { addAtsSubscriptionToCompany } from "../../api/iskibris/iskibris-methods"
import HTTPResponses from "../../tools/http.responses"
import { flashActions } from "../../store/actions/flash-messaging"
import { Alert } from "@material-ui/lab"
import { CircularProgress } from "@material-ui/core"
import Can from "../../utils/AccessControl/Can"
// import { billingActions } from "../../store/actions/billing"
// import { permissionActions } from "../../store/actions/permissions"

interface Props {}

const AtsSubscriptionLandingPage: React.FC<Props> = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [loading, setLoading] = React.useState(false)

  const company = useSelector((state: any) => state.companyReducer.company)
  const subscriptions = useSelector((state: any) => state.billingReducer.subscriptions)

  const atsSubscriptions = subscriptions?.filter((item: any) => item.offer_handle === "off-ats-beta")

  const handleSubmit = async () => {
    setLoading(true)

    await addAtsSubscriptionToCompany(company?.id)
      .then(async (response) => {
        dispatch(
          flashActions.addFlashMessage(t("ats_subscription_success"), "success")
        )
        window?.location?.reload()
        // dispatch(
        //   await billingActions.fetchCompanySubscriptions(company?.id)
        // )
        // dispatch(
        //   await permissionActions.fetchCompanyBillingAbilities()
        // )
        // dispatch(
        //   await permissionActions.fetchUserAbilities()
        // )
      })
      .catch(err => {
        if (err?.response) {
          if (err?.response?.status === HTTPResponses.INTERNAL_SERVER_ERROR) {
            dispatch(
              flashActions.addFlashMessage(t("server_error_fail"), "error")
            )
          } else {
            dispatch(
              flashActions.addFlashMessage(t("ats_subscription_error"), "error")
            )
          }
        } else {
          dispatch(
            flashActions.addFlashMessage(t("network_error"), "error")
          )
        }
      })

    setLoading(false)
  }

  return (
    <Page>
      
      <div className="content--with-smaller-container" style={{paddingLeft: "0.75rem", paddingRight: "0.75rem"}}>
        {
          atsSubscriptions?.length > 0 && (
            <Alert severity="success" variant="filled" style={{marginBottom: "1rem", marginTop: "1rem"}}>{t("ats_subscription_already_active")}</Alert>
          )
        }
        <Typography variant="h5" className="page-title mb-5" style={{marginBottom: "0.5rem"}}>{t("applicants_tracking_system_add_on")}</Typography>
        <Typography variant="body2" style={{marginBottom: "1rem"}}>{t("applicants_tracking_system_add_on_subtitles")}</Typography>
        
        <Typography variant="h6" className="mb-5" style={{marginBottom: "0.5rem"}}>{t("applicants_tracking_system_add_on_features_title")}</Typography>
        <Typography variant="body2" className="mb-5" style={{marginBottom: "1rem"}}>{t("applicants_tracking_system_add_on_features_subtitle")}</Typography>
        
        <div style={{marginBottom: "1rem"}}>
          <div style={{display: "flex", alignItems: "center", marginBottom: "0.5rem"}}> <KeyboardArrowRight fontSize="small" /> <Typography variant="body2">{t("ats_feature_announcement_manual_applications")}</Typography></div>
          <div style={{display: "flex", alignItems: "center", marginBottom: "0.5rem"}}> <KeyboardArrowRight fontSize="small" /> <Typography variant="body2">{t("ats_feature_announcement_tag_applications_to_lists")}</Typography></div>
          <div style={{display: "flex", alignItems: "center", marginBottom: "0.5rem"}}> <KeyboardArrowRight fontSize="small" /> <Typography variant="body2">{t("ats_feature_announcement_expanded_applications_stages")}</Typography></div>
        </div>

        <Typography variant="body2" className="mb-5" style={{marginBottom: "1rem"}}>{t("applicants_tracking_system_add_on_features_subtitle_more_functionality")}</Typography>

        <div style={{marginBottom: "1rem"}}>
          <Link href="https://www.iskibris.com/blog/ats-basvuru-ekleme?id=15" target="_blank" referrerPolicy="no-referrer" color="secondary" style={{marginBottom: "1rem"}}>{t("applicants_tracking_system_add_on_learn_more")}</Link>
        </div>


        {/* <div style={{marginBottom: "1rem", marginTop: "1rem"}}>
          <iframe width="350" height="197" src="https://www.youtube.com/embed/Q2WBIWUPqqM" title="İş ilanınızı Facebook Hesabınızda Paylaşın" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div> */}

        <div style={{marginBottom: "1rem"}}>
          <Typography variant="h6" gutterBottom>{t("pricing")}</Typography>
          <Typography variant="caption">{t("ats_free_subscription_availability", {charging_start_date: "31/06/2024 23:59"})}<sup>*</sup></Typography>
        </div>

        <Can not I="use-ats-system" a="all">
          <Button variant="contained" color="primary" style={{marginTop: "0.25rem", padding: "0.55rem 2.5rem"}} disabled={loading} onClick={handleSubmit}>{loading ? <CircularProgress /> : t("activate_ats_addon")}</Button>
        </Can>
        {/* {
          (!atsSubscriptions || atsSubscriptions?.length === 0) && (
            <Button variant="contained" color="primary" style={{marginTop: "0.25rem", padding: "0.55rem 2.5rem"}} disabled={loading} onClick={handleSubmit}>{loading ? <CircularProgress /> : t("activate_ats_addon")}</Button>
          )
        } */}

      </div>
    </Page>
  )
}

export default AtsSubscriptionLandingPage
