import React from "react"
import Typography from "@material-ui/core/Typography"
import useStyles from "../styles"
import { useTranslation } from "react-i18next"

const getGreetingMessage = () => {
  let timeOfTheDay = new Date()
  let morningEnd = new Date()
  morningEnd.setHours(12, 0, 0)
  let afternoonEnd = new Date()
  afternoonEnd.setHours(17, 0, 0)

  if (timeOfTheDay <= morningEnd) {
    return 0
  } else if (timeOfTheDay <= afternoonEnd) {
    return 1
  } else {
    return 2
  }
}

const Greetings: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const greetingMessage = t(`greeting_id_${getGreetingMessage()}`)

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Typography component="h4" variant="h4" className={classes.greetingMessage}>
        {greetingMessage}
      </Typography>
    </div>
  )
}

export default Greetings
