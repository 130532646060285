import React from "react"

import clsx from "clsx"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import useTheme from "@material-ui/core/styles/useTheme"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ImportExportIcon from "@material-ui/icons/ImportExport"
import CircularProgress from "@material-ui/core/CircularProgress"

import useStyles from "./styles"
import { IApplicant, IApplicantFilters, IPaginatedApplicants } from "../../../types/applicants"

import SortBy from "../../ApplicantManagement/SortBy"
import ApplicantsList from "../../ApplicantManagement/ApplicantsList"
import ApplicantDetails from "../../ApplicantManagement/ApplicantDetails"
import MobileNavigation from "../../ApplicantManagement/MobileNavigation"
import MobileComponents from "../../ApplicantManagement/MobileComponents"

interface Props {
  filters: IApplicantFilters
  singleApplicant: IApplicant
  applicants: IPaginatedApplicants
}

const ApplicantsListView: React.FC<Props> = props => {

  const params: { id?: any } = useParams()
  const history = useHistory()

  const checkFiltersAvailable = (skipKeyword?: boolean) => {
    if (
      props.filters.rating?.length > 0 ||
      props.filters.stage?.length > 0 ||
      props.filters.city?.length > 0 ||
      props.filters.job?.length > 0 ||
      props.filters.field_of_study?.length > 0 ||
      props.filters.work_permit?.length > 0 ||
      props.filters.education_level?.length > 0 ||
      props.filters.gender?.length > 0 ||
      props.filters.age?.length > 0 ||
      props.filters.driver_licence?.length > 0 ||
      (skipKeyword ? false : !!props.filters.keyword)
    ) {
      return true
    } else {
      return false
    }
  }

  const classes = useStyles({ ...props, checkFiltered: checkFiltersAvailable })

  const { t } = useTranslation()

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const loader = (
    <div className={classes.rootLoader}>
      <CircularProgress />
    </div>
  )

  let noApplications = checkFiltersAvailable() ? (
    <div className={classes.noApplicantsContainer}>
      <Typography variant="body1">{t("no_applicants_match_filter")}</Typography>
    </div>
  ) : (
    <div className={classes.noApplicantsContainer}>
      <Typography variant="body1">{t("no_applicants_for_company")}</Typography>
      <div style={{ width: "100%", marginTop: "10px" }}>
        <Tooltip title={`${t("no_applicants_job_button_tooltip")}`}>
          <Button
            variant="outlined"
            color="secondary"
            style={{ margin: "10px" }}
            onClick={() => {
              history.push("/jobs")
            }}
          >
            {t("add_jobs")}
          </Button>
        </Tooltip>
        <Tooltip title={`${t("no_applicants_employer_button_tooltip")}`}>
          <Button
            variant="outlined"
            color="secondary"
            style={{ margin: "10px" }}
            onClick={() => {
              history.push("/employer/profile")
            }}
          >
            {t("employer_profile")}
          </Button>
        </Tooltip>
      </div>
    </div>
  )

  return (
      <Grid container className={classes.gridContainer}>
        {!props.applicants ? (
          loader
        ) : props.applicants.data.length > 0 ? (
          <React.Fragment>
            <Grid
              item
              className={clsx(classes.leftGridItem, {
                [classes.leftGridItemClosed]: !!params.id // double negative to convert this to boolean
              })}
              xs={12}
              sm={12}
              md={4}
              lg={3}
              xl={3}
            >
              <ListItem className={classes.sortByContainer}>
                <React.Fragment>
                  <ListItemIcon style={{ minWidth: 30 }}>
                    <ImportExportIcon />
                  </ListItemIcon>
                  <ListItemText style={{ fontSize: "12px" }}>{t("sort_by", ": ")}:</ListItemText>
                  <SortBy />
                </React.Fragment>
              </ListItem>
              {matchesMobile ? <MobileComponents /> : null}
              <ApplicantsList applicants={props.applicants} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={9}
              xl={9}
              className={clsx(classes.rightGridItem, {
                [classes.rightGridItemClosed]: !params.id // true when there is no id parameter in the url
              })}
            >
              {props.singleApplicant ? (
                <React.Fragment>
                  {matchesMobile ? <MobileNavigation applicants={props.applicants} singleApplicant={props.singleApplicant} /> : null}
                  <ApplicantDetails />
                </React.Fragment>
              ) : (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <CircularProgress />
                </div>
              )}
            </Grid>
          </React.Fragment>
        ) : (
          noApplications
        )}
      </Grid>
  )
}

export default ApplicantsListView
