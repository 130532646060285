import React from "react"
import Cropper from "react-easy-crop"
import imageCompression from "browser-image-compression"
import { useTranslation } from "react-i18next"

import Button from "@material-ui/core/Button"
import Slider from "@material-ui/core/Slider"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"

import getCroppedImg from "../../utils/cropImage"
import imageFile2Base64 from "../../utils/imageFile2Base64"

interface Props {
  zoom?: number
  defaultImageUrl?: string
  dimensions?: { height: number; width: number }
  onSave?: (file: File) => void
  onCloseModal: () => void
  onLoading?: () => void
}

const ImageEditor: React.FC<Props> = props => {
  const { t } = useTranslation()

  const [image, setImage] = React.useState<any>(props.defaultImageUrl)
  const [crop, setCrop] = React.useState({ x: 0, y: 0 })
  const [zoom, setZoom] = React.useState(props.zoom ?? 1)
  const [rotation, setRotation] = React.useState(0)
  const [aspect] = React.useState(props.dimensions ? props.dimensions.width / props.dimensions.height : 1 / 1)
  const [croppedImage, setCroppedImage] = React.useState<any>(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<any>(null)
  const [loading, setLoading] = React.useState(false)

  const resetValues = {
    crop: { x: 0, y: 0 },
    zoom: 1,
    rotation: 0
  }

  const zoomMarks = [
    {
      value: 0.5,
      label: "0.5x"
    },
    {
      value: 1,
      label: "1x"
    },
    {
      value: 2,
      label: "2x"
    },
    {
      value: 3,
      label: "3x"
    }
  ]

  const valueText = (value: any) => {
    return `${value}°`
  }

  const handleNewImage = (e: any) => {
    const file = e.target.files[0]
    if (file) {
      imageFile2Base64(file).then(response => {
        setImage(response)
      })
    }
  }

  const onCropChange = (crop: any) => {
    setCrop(crop)
  }

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const onZoomChange = (zoom: any) => {
    setZoom(zoom)
  }

  const handleSaveLogo = async () => {
    setLoading(true)

    const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation)

    setCroppedImage(croppedImage)

    let blob = await fetch(croppedImage).then(r => r.blob())

    let compressedFile: any = blob

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }

    try {
      const imageFile = new File([blob], "image.png", { type: "image/png" })
      compressedFile = await imageCompression(imageFile, options)
    } catch (error) {
      console.log("Image Compression Error: ", error)
    }

    if (props.onSave) {
      await props.onSave(compressedFile)
    }
  }

  const resetState = () => {
    console.log("Cropped Image: ", croppedImage)
    setZoom(resetValues.zoom)
    setRotation(resetValues.rotation)
    setCrop(resetValues.crop)
  }

  return (
    <div>
      <div style={{ position: "relative", margin: "16px auto", height: props.dimensions?.height ?? 320, width: props.dimensions?.height ?? 320 }}>
        <Cropper
          crop={crop}
          zoom={zoom}
          image={image}
          aspect={aspect}
          rotation={rotation}
          restrictPosition={false}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropComplete}
        />
      </div>
      <div style={{ textAlign: "center", marginBottom: 8 }}>
        <input accept="image/*" id="upload-new-image-file" multiple type="file" name="newImage" onChange={handleNewImage} style={{ display: "none" }} />
        <label htmlFor="upload-new-image-file" style={{ marginRight: 8 }}>
          <Button variant="contained" color="secondary" component="span">
            {t("select_new_image")}
          </Button>
        </label>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            resetState()
          }}
        >
          {t("reset")}
        </Button>
      </div>
      <Typography id="zoom-slider" gutterBottom>
        {t("zoom")}
      </Typography>
      <Slider
        max={3}
        min={0.5}
        step={0.1}
        value={zoom}
        marks={zoomMarks}
        valueLabelDisplay="auto"
        getAriaValueText={valueText}
        aria-labelledby="zoom-slider"
        onChange={(e, zoom) => onZoomChange(zoom)}
      />
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Button variant="outlined" color="primary" onClick={props.onCloseModal}>
          {t("cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSaveLogo} disabled={loading}>
          {loading ? <CircularProgress size={22} /> : t("save")}
        </Button>
      </div>
    </div>
  )
}

export default ImageEditor
