import React, { useState } from "react"
import Typography from "@material-ui/core/Typography"
import DropdowIcon from "@material-ui/icons/KeyboardArrowDown"
import DropupIcon from "@material-ui/icons/KeyboardArrowUp"
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"

const faqs = [
  {
    id: 0,
    question: "Buraya nasıl içerikler koymalıyım?",
    answer: "Burası işveren marka imajını güçlendireceğiniz yerdir. Adaylara çalışabilecekleri iyi bir firma olduğunuz mesajını verebilrsiniz."
  },
  {
    id: 1,
    question: "Hangi bölümleri önerirsiniz?",
    answer: `Neden Bizim İçin Çalışmalısınız bölümünde en fazla 5 bölüme sahip olabilirsiniz.
      Önerdiklerimizden bazıları şunlardır:
      •	Görüşler
      •	Çeşitlilik
      •	Toplum Katılımı
      Görüşme Sırasında Ne Beklenmeli.  Ayrıcalıklardan ve harika şeylerden bahsettiğiniz eğlenceli bir şey eklemeyi de düşünün.  İşe alım kampanyanızla aynı zamana denk gelecek şekilde sekme sırasını değiştirme olanağına sahipsiniz.`
  },
  {
    id: 2,
    question: "İş İlanlarımı girebilir miyim?",
    answer: "Bu kısım, iş ilanlarını girmek için uygun bir yer değil. İş ilanlarınıza özel bölümümü kullanabiiliirsiniz."
  },
  {
    id: 3,
    question: "Burayı ne sıklıkla güncelemem gerekir?",
    answer: "İşveren markanız ile ilgili herhangi bir gelişme, değişiklik olduğu zamanlarda güncellemeniz yararlı olacaktır."
  },
  {
    id: 4,
    question: "Fotoğraflar için bir sınır var mıdır?",
    answer: "Çalışma ortamınızı yansıtan fotoğraflar ekleyerek, adayları nasıl ortamda çalışacakları konusunda biilgilendirmiş olursunuz."
  },
  {
    id: 5,
    question: "Fotoğraflar için hangi formatları kullanibilirim?",
    answer: "PNG ve JPG formatları"
  }
]

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2, 2),
    marginTop: "20px",
    marginLeft: "30px"
  },
  question: {
    display: "inline",
    cursor: "pointer"
  },
  answer: {
    color: "#797979",
    marginLeft: 25
  },
  icon: {
    display: "inline",
    verticalAlign: "middle"
  }
}))

interface Props {}

interface FaqProps {
  query: any
}

const FAQ: React.FC<FaqProps> = props => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <React.Fragment>
      <div style={{ paddingBottom: "20px" }}>
        {open === true ? <DropupIcon className={classes.icon} /> : <DropdowIcon className={classes.icon} />}

        <Typography onClick={handleClick} variant="body1" className={classes.question}>
          {props.query.question}
        </Typography>

        {open ? (
          <Typography variant="body1" className={classes.answer}>
            {props.query.answer}
          </Typography>
        ) : null}
      </div>
    </React.Fragment>
  )
}

const CompanyInfoFAQ: React.FC<Props> = props => {
  const { t } = useTranslation()

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {t("company_info_faq")}
      </Typography>
      {faqs.map((item: any) => {
        return <FAQ query={item} key={item.id} />
      })}
    </div>
  )
}

export default CompanyInfoFAQ
