import { actionTypes } from "../actions/actionTypes"
import { IActionType } from "../../types"
import Cookies from "js-cookie"

interface IAuthModel {
  isAuthenticated: boolean | null
}

let token = Cookies.get("auth_token")

const initialState: IAuthModel = {
  isAuthenticated: token !== null && token !== undefined
}

const authReducer = (state: IAuthModel = initialState, action: IActionType) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATE:
      return {
        ...state,
        isAuthenticated: true
      }
    case actionTypes.UNAUTHENTICATE: {
      return {
        ...state,
        isAuthenticated: false
      }
    }
    default:
      break
  }
  return state
}

export default authReducer
