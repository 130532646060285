import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { useReactToPrint } from "react-to-print"

import Fade from "@material-ui/core/Fade"
import MLink from "@material-ui/core/Link"
import Modal from "@material-ui/core/Modal"
import Button from "@material-ui/core/Button"
import Backdrop from "@material-ui/core/Backdrop"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"

import useStyles from "./styles"

import logo from "../../../../../assets/images/logo.png"
import { IMalipoInvoice } from "../../../../../types/billing"

interface Props {
  open: boolean
  // invoice: IInvoice;
  clientInvoice: IMalipoInvoice
  handleClose: () => void
}

const SingleInvoice: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const componentRef: any = React.useRef()
  const { company } = useSelector((state: any) => state.companyReducer)

  const { clientInvoice } = props

  const handleClose = () => {
    props.handleClose()
  }

  const handlePrint = useReactToPrint({ content: () => componentRef.current })

  return (
    <div>
      <Modal
        closeAfterTransition
        open={props.open}
        onClose={handleClose}
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Fade in={props.open}>
          <div className={classes.modalPaper}>
            <div className={classes.modalCloseContainer}>
              <IconButton onClick={handleClose} className={classes.iconButton}>
                <CloseRoundedIcon />
              </IconButton>
            </div>
            <div className={classes.mainReceiptContainer} ref={componentRef}>
              <div className={classes.receiptContainerHeader}>
                <div className={classes.logoContainer}>
                  <img src={logo} alt="İş Kıbrıs" className={classes.logo} />
                </div>
                <Typography variant="caption" className={classes.receiptHeaderText}>
                  Şehit Cumhur Yüzlü Sokak Vega Apartment Flat: 12 Ortaköy, Nicosia
                </Typography>
                <Typography variant="body2" style={{ fontWeight: "bold" }} className={classes.receiptHeaderText}>
                  {t("billing_reference")} # {clientInvoice.id}
                </Typography>
              </div>
              <div className={classes.detailsContainer}>
                <Typography variant="body2">{t("product")}</Typography>
                <div className={classes.productInformationContainer}>
                  <Typography variant="body1" className={classes.halfWidth} style={{ fontWeight: "bold" }}>
                    {clientInvoice.offer ? clientInvoice?.offer?.name : clientInvoice.product?.name} - {clientInvoice.id}
                  </Typography>
                  <Typography variant="body1" className={classes.halfWidth} style={{ fontWeight: "bold" }}>
                    {clientInvoice.amount} TRY
                  </Typography>
                </div>
                <div className={classes.paymentInformationContainer}>
                  <div className={classes.halfWidth}>
                    <Typography variant="caption">{t("payment_method")}</Typography>
                    <Typography variant="body2">{clientInvoice?.receipt?.card_no_masked || "- -"}</Typography>
                  </div>
                  <div className={classes.halfWidth}>
                    <Typography variant="caption">{t("payment_date")}</Typography>
                    <Typography variant="body2">{clientInvoice?.receipt?.paid_at || "- -"}</Typography>
                  </div>
                </div>
                {clientInvoice?.receipt ? (
                  <div className={classes.clientInformationContainer}>
                    <Typography variant="body1">{t("client_information")}</Typography>
                    <Typography variant="body2">{clientInvoice.receipt.card_holder}</Typography>
                    <Typography variant="body2">{company.name}</Typography>
                  </div>
                ) : null}
                <div className={classes.contactInformation}>
                  <Typography variant="body2" style={{ color: "#797979" }}>
                    {t("questions_contact_us")}
                    <MLink color="primary" href="mailto:destek@iskibris.com" target="_blank" rel="noreferrer" style={{ marginLeft: "5px" }}>
                      destek@iskibris.com
                    </MLink>
                    <span style={{ marginRight: "5px", marginLeft: "5px" }}>{t("or_sm")}</span>
                    <span>0548 883 7373</span>
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.printReceiptContainer}>
              <Button color="secondary" className={classes.printReceiptButton} onClick={handlePrint}>
                {t("print_receipt")}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default SingleInvoice
