import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    padding: "24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      paddingBottom: "20px"
    }
  },
  card: {
    height: "100%"
  },
  cardContent: {
    margin: "auto",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center"
    }
  }
}))

export default useStyles
