import React from "react"
import { IApplicant } from "../../../../types/applicants"
import { ICompany } from "../../../../types/company"
import { IFieldOfStudy, IUser } from "../../../../types/user"
import AdditionalUserInformation from "./AdditionalUserInformation"
import CandidateResume from "./CandidateResume"
import CoverLetter from "./CoverLetter"
import EducationDetails from "./EducationDetails"
import JobDetails from "./JobDetails"
// import Notes from "./Notes"
import OtherApplications from "./OtherApplications"

interface Props {
  company: ICompany
  currentUser: IUser
  applicant: IApplicant
  showUserInformation: boolean
  fieldsOfStudy: IFieldOfStudy[]
  currentUserInformation: IUser
  fetchUserInformation: (id: any) => void
}

const CandidateDetails: React.FC<Props> = props => {
  const { company, applicant, currentUser, currentUserInformation, fetchUserInformation } = props

  React.useEffect(() => {
    if ((applicant && !currentUserInformation) || currentUserInformation.id !== applicant.user_id) {
      if (applicant.user_id) {
        fetchUserInformation(applicant.user_id)
      }
    }
  }, [applicant, currentUserInformation, fetchUserInformation])

  return (
    <div>
      <JobDetails applicant={props.applicant} readOnly={false} />
      {props.applicant.cover_letter ? <CoverLetter applicant={props.applicant} /> : null}
      {props.applicant.education_details && props.applicant.education_details.length > 0 ? (
        <EducationDetails applicant={props.applicant} fieldsOfStudy={props.fieldsOfStudy} />
      ) : null}
      {props.showUserInformation && currentUserInformation && currentUserInformation.id === applicant.user_id ? <AdditionalUserInformation /> : null}
      {/* {props.applicant.comments && <Notes applicant={props.applicant} />} */}
      <CandidateResume applicant={props.applicant} user={currentUser} company={company} />
      {props.currentUserInformation ? <OtherApplications singleApplicant={props.applicant} /> : null}
    </div>
  )
}

export default CandidateDetails
