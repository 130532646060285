import React from "react"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import Button from "@material-ui/core/Button"
import MenuItem from "@material-ui/core/MenuItem"
import Checkbox from "@material-ui/core/Checkbox"
import Backdrop from "@material-ui/core/Backdrop"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import FormControl from "@material-ui/core/FormControl"
// import useTheme from '@material-ui/core/styles/useTheme';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import CircularProgress from "@material-ui/core/CircularProgress"

import useStyles from "../styles"

import { IActionType } from "../../../../types"
import { ICompany } from "../../../../types/company"
import { IMessageTemplate } from "../../../../types/messages"
import { messagesActions } from "../../../../store/actions/messages"

import Can from "../../../../utils/AccessControl/Can"
// import InputVariables from '../../../InputVariables';
import MobileInputVariables from "../../../MobileComponents/MobileInputVariables"

const variableMap = {
  candidate: [
    { id: 0, value: "cfname" },
    { id: 1, value: "clname" },
    { id: 2, value: "cflname" }
  ],
  job_company: [
    { id: 0, value: "company_name" },
    { id: 1, value: "job_title" },
    { id: 2, value: "city" }
  ],
  recruiter: [
    { id: 0, value: "rfname" },
    { id: 1, value: "rlname" },
    { id: 2, value: "rflname" }
  ]
}

interface Props {
  mode: string
  open: boolean
  company: ICompany
  template?: IMessageTemplate
  changeMode?: (mode: string) => void
  editTemplate: (info: any, id: any) => void
  createTemplate: (info: any) => void
  handleClose: () => void
}

const modules = {
  toolbar: [[{ size: [] }], ["bold", "italic", "underline", "blockquote"], [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }], ["link"]],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
}

const formats = ["size", "bold", "italic", "underline", "blockquote", "list", "bullet", "indent", "link"]

const TemplateForm: React.FC<Props> = props => {
  const { t } = useTranslation()

  const classes = useStyles()

  const [openVariables, setOpenVariables] = React.useState(false)

  const [loading, setLoading] = React.useState(false)
  const [quillRef, setQuill] = React.useState<any>(null)

  // const theme = useTheme();
  // const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const [ckEdtr, setCkEdtr] = React.useState(null);

  const [values, setValues] = React.useState({
    title: props.template ? props.template?.title : "",
    body: props.template ? props.template?.body : "",
    messenger_template_type_id: props.template ? props.template?.messenger_template_type_id : "",
    description: props.template ? props.template?.description : "",
    is_standard: props.mode === "customise" ? 0 : props.template?.is_standard
  })

  const handleInsert = (value: any) => {
    const range = quillRef.getSelection()
    const position = range ? range.index : 0
    quillRef.insertText(position, value)
  }

  const handleTitleChange = (event: any) => {
    setValues({
      ...values,
      title: event.target.value
    })
  }

  const handleTypeChange = (event: any) => {
    if (!values.title) {
    }
    setValues({
      ...values,
      title: !values.title ? t(`template_type_id_${event.target.value}`) : values.title,
      messenger_template_type_id: event.target.value
    })
  }

  const handleDescriptionChange = (event: any) => {
    setValues({
      ...values,
      description: event.target.value
    })
  }

  const handleBodyChange = (value: any) => {
    setValues({
      ...values,
      body: value
    })
  }

  const handleIsStandardChange = (value: any) => {
    if (values.is_standard === 1) {
      setValues({
        ...values,
        is_standard: 0
      })
    } else {
      setValues({
        ...values,
        is_standard: 1
      })
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    let infor = {
      ...values,
      company_id: props.company.id
    }

    if (props.mode === "create") {
      await props.createTemplate(infor)
    } else if (props.mode === "customise") {
      infor.is_standard = 0
      await props.createTemplate(infor)
    } else if (props.mode === "edit") {
      if (props.template?.is_standard === 1) {
        infor["is_standard"] = props.template?.is_standard
      }
      await props.editTemplate(infor, props.template?.id)
    }

    setLoading(false)
    props.handleClose()
  }

  const handleClose = () => {
    props.handleClose()
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={props.open}>
        <div className={classes.modalPaper}>
          <div className={classes.modalCloseContainer}>
            <Typography className={classes.modalTypoghraphyTitle} variant="h6" gutterBottom>
              {t(`messaging_template_title_${props.mode}`)}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon />
            </IconButton>
          </div>
          <div style={{ display: "flex", flexDirection: "row", height: "100%", overflow: "auto" }}>
            <span style={{ padding: "1rem", paddingTop: "0", height: "100%", overflow: "auto" }}>
              <Typography variant="body1" style={{ color: "#979797" }} gutterBottom>
                {t("add_template_subtext")}
              </Typography>
              <form
                onSubmit={event => {
                  event.preventDefault()
                  handleSubmit()
                }}
              >
                <FormControl style={{ width: "100%", marginBottom: "10px" }}>
                  <TextField
                    select
                    required
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    value={values.messenger_template_type_id}
                    onChange={handleTypeChange}
                    placeholder={t("template_type")}
                    label={t("template_type")}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8].map(item => {
                      return (
                        <MenuItem key={item} value={item}>
                          {t(`template_type_id_${item}`)}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </FormControl>
                <FormControl style={{ width: "100%", marginBottom: "10px" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    value={values.title}
                    onChange={handleTitleChange}
                    placeholder={t("template_title")}
                    label={t("template_title")}
                  />
                </FormControl>
                <FormControl style={{ width: "100%", marginBottom: "10px" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    margin="dense"
                    value={values.description}
                    placeholder={t("description")}
                    label={t("description")}
                    onChange={handleDescriptionChange}
                  />
                </FormControl>
                <Can I="create-standard-template" a="all">
                  <FormControl style={{ display: "flex", justifyContent: "end", width: "100%" }}>
                    <FormControlLabel
                      control={<Checkbox checked={values.is_standard === 1} value={1} onChange={handleIsStandardChange} color="primary" />}
                      label={t("is_standard_template")}
                    />
                  </FormControl>
                </Can>
                {openVariables ? ( // && matchesMobile
                  <MobileInputVariables variableMap={variableMap} handleVariableClick={handleInsert} />
                ) : null}
                <div className="app">
                  <ReactQuill
                    ref={el => {
                      setQuill(el?.getEditor())
                    }}
                    theme={"snow"}
                    formats={formats}
                    modules={modules}
                    placeholder={t("enter_information_here")}
                    value={values.body || ""}
                    onChange={handleBodyChange}
                    bounds={".app"}
                    style={{ marginBottom: "10px" }}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  {props.mode === "view" ? (
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        if (props.changeMode) {
                          props.changeMode("customise")
                        }
                      }}
                    >
                      {t("customise")}
                    </Button>
                  ) : (
                    <div>
                      <Button color="primary" variant="contained" style={{ marginRight: "10px" }} type="submit">
                        {loading ? <CircularProgress size={22} style={{ color: "#fff" }} /> : t("save")}
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          handleClose()
                        }}
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        variant="outlined"
                        style={{ float: "right" }}
                        onClick={() => {
                          setOpenVariables(!openVariables)
                        }}
                      >
                        {`{ }`} {t("variables")}
                      </Button>
                    </div>
                  )}
                </div>
              </form>
            </span>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company
    // permissions: state.userReducer.permissions
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    createTemplate: async (info: any) => dispatch(await messagesActions.addTemplate(info)),
    editTemplate: async (info: any, templateId: any) => dispatch(await messagesActions.editTemplate(info, templateId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateForm)
