import React from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"

import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import ItemIcon from "@material-ui/icons/Brightness1"
import CheckedItemIcon from "@material-ui/icons/CheckCircle"
import { useTranslation } from "react-i18next"
import { ICompany, ICompanyBenefit } from "../../types/company"

const checkedStyle = {
  color: "#59AB43",
  display: "inline",
  verticalAlign: "middle"
}
const uncheckStyle = {
  color: "#bfbfbf",
  display: "inline",
  verticalAlign: "middle"
}

interface ChecklistProps {
  label: string
  filled: boolean
  link?: string
}

interface Props {
  company: ICompany
  benefits: ICompanyBenefit[] | null
}

const CheckListItem: React.FC<ChecklistProps> = props => {
  const history = useHistory()

  const handleClick = (event: any) => {
    event.preventDefault()
    if (props.link) {
      history.push(props.link)
    }
  }

  return (
    <div
      style={{
        paddingLeft: "15px",
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
        marginTop: "15px"
      }}
    >
      {props.filled ? <CheckedItemIcon style={checkedStyle} /> : <ItemIcon style={uncheckStyle} />}

      {!props.filled && props.link ? (
        <Link
          // href={props.link}
          color="secondary"
          onClick={handleClick}
          style={{ display: "inline", verticalAlign: "middle", textDecoration: "none", cursor: "pointer" }}
        >
          <Typography variant="body1" style={{ paddingLeft: "10px" }}>
            {props.label}
          </Typography>
        </Link>
      ) : (
        <Typography variant="body1" style={{ paddingLeft: "10px" }}>
          {props.label}
        </Typography>
      )}
    </div>
  )
}

const CandidateAppealChecklist: React.FC<Props> = props => {
  const { t } = useTranslation()

  const checkBenefitsAdded = () => {
    if (props.benefits) {
      let i = 0
      while (i < props.benefits.length) {
        if (props.benefits[i].is_selected === 1) {
          return true
        }
        i += 1
      }
    }
    return false
  }

  return (
    <div>
      <Typography variant="subtitle1" style={{ padding: "5px" }}>
        {t("candidate_appeal_checklist")}
      </Typography>
      <CheckListItem label={t("logo")} filled={props.company.logo ? true : false} link="/employer/profile/photos" />
      <CheckListItem label={t("benefits")} filled={checkBenefitsAdded() ? true : false} link="/employer/profile/info/benefits" />
      <CheckListItem label={t("about_your_co")} filled={props.company.about ? true : false} link="/employer/profile/story" />
      <CheckListItem label={t("basic_info")} filled={props.company.name ? true : false} link="/employer/profile/info" />
      <CheckListItem label={t("why_work_with_us")} filled={props.company.mission ? true : false} link="/employer/profile/story" />
      <CheckListItem label={t("cover_image")} filled={props.company.logo ? true : false} link="/employer/profile/photos" />
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company,
    benefits: state.companyReducer.benefits
  }
}

export default connect(mapStateToProps)(CandidateAppealChecklist)
