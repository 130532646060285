import React from "react"
import { useTranslation } from "react-i18next"
import { Controller } from "react-hook-form"

import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"

import SectorField from "./SectorIndustry/_SectorField"
import IndustryField from "./SectorIndustry/_IndustryField"
import useStyles from "../styles"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { getPlaces } from "../../../../api/iskibris/iskibris-methods"
import { isArray } from "lodash"


import { PlaceSelectorWidget } from "innovia-component-library"

interface Props {
  formFields: any
  errors: any
  register: any
  control: any
  selectSector: (value: any) => void
  editFormFields: (value: any) => void
}

const companySizeOptions = [
  { value: 0, label: "" },
  { value: 1, label: "" },
  { value: 2, label: "" },
  { value: 3, label: "" },
  { value: 4, label: "" }
]

const workingHoursOptions = [
  { value: 0, label: "" },
  { value: 1, label: "" },
  { value: 2, label: "" },
  { value: 3, label: "" },
  { value: 4, label: "" }
]

// let cityOptions = [
//   { value: 0, label: "" },
//   { value: 1, label: "" },
//   { value: 2, label: "" },
//   { value: 3, label: "" },
//   { value: 4, label: "" },
//   { value: 5, label: "" }
// ]

const BasicInfo: React.FC<Props> = props => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()

  // cityOptions.forEach(item => {
  //   item.label = t(`city_id_${item.value}`)
  // })

  companySizeOptions.forEach(item => {
    item.label = t(`company_size_id_${item.value}`)
  })

  workingHoursOptions.forEach(item => {
    item.label = t(`working_hours_id_${item.value}`)
  })

  const editCompanyFormDetails = (name: any) => (event: any) => {
    props.editFormFields({ [name]: event.target.value })
  }

  const editCompanyDatesFormDetails = (name: any) => (event: any) => {
    props.editFormFields({ [name]: event.getFullYear() })
  }

  const setSectorField = (value: any) => {
    props.selectSector(value)
  }

  const setIndustryField = (value: any) => {
    props.editFormFields({ industry_id: value })
  }

  const handleFetchOptions = async (searchKeyword?: string | null, page?: number, perPage?: number) => {
    const response = await getPlaces(searchKeyword, page, perPage)
    const result = isArray(response.data) ? response.data : response?.data?.data
    return result
  }

  const getOptionLabel = (option: any) => {
    return option?.name ? option?.name[`name_${i18n.language}`] : ""
  }

  return (
    <React.Fragment>
      <Typography variant="h6" className={classes.titleText}>
        {t("basic_info")}
      </Typography>
      <FormControl className={classes.formControl}>
        <TextField
          required
          fullWidth
          id="name"
          name="name"
          margin="dense"
          variant="outlined"
          label={t("company_name")}
          error={!!props.errors?.name}
          placeholder={t("company_name")}
          helperText={props.errors?.name ? props.errors?.name.message.toString() : null}
          value={props.formFields.name || ""}
          onChange={editCompanyFormDetails("name")}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          fullWidth
          id="address"
          name="address"
          margin="dense"
          variant="outlined"
          label={t("address")}
          error={!!props.errors?.address}
          placeholder={t("address")}
          helperText={props.errors?.address ? props.errors?.address.message.toString() : null}
          value={props.formFields.address || ""}
          onChange={editCompanyFormDetails("address")}
        />
      </FormControl>
      <FormControl className={classes.halfFormControl}>
        <PlaceSelectorWidget
          fullWidth
          margin="dense"
          name="headquarter_place_id"
          errors={props.errors}
          control={props.control}
          variant="outlined"
          label={t("headquarter_city")}
          getOptionLabel={getOptionLabel}
          fetchOptions={handleFetchOptions}
          placeholder={t("headquarter_city")}
          // defaultValue={props.formFields?.headquarter_place_id} 
          onSelectOption={(option: any) => {props.editFormFields({ "headquarter_place_id": option })}}
        />
        {/* <Controller
          render={data => {
            return (
              <TextField
                select
                fullWidth
                margin="dense"
                variant="outlined"
                id="headquarter_city_id"
                name="headquarter_city_id"
                label={t("headquarter_city")}
                placeholder={t("headquarter_city")}
                error={!!props.errors?.headquarter_city_id}
                value={props.formFields.headquarter_city_id !== null ? props.formFields.headquarter_city_id : ""}
                onChange={event => {
                  editCompanyFormDetails("headquarter_city_id")(event)
                }}
                helperText={props.errors?.headquarter_city_id ? props.errors?.headquarter_city_id.message.toString() : null}
              >
                {cityOptions.map(option => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  )
                })}
              </TextField>
            )
          }}
          name="headquarter_city_id"
          defaultValue={props.formFields.headquarter_city_id}
          rules={{}}
          control={props.control}
        /> */}
      </FormControl>
      <FormControl className={classes.halfFormControl}>
        <TextField
          fullWidth
          id="website"
          name="website"
          margin="dense"
          variant="outlined"
          label={t("company_website_label")}
          error={!!props.errors?.website}
          placeholder={t("company_website_label")}
          helperText={props.errors?.website ? props.errors?.website.message.toString() : null}
          value={props.formFields.website || ""}
          onChange={editCompanyFormDetails("website")}
        />
      </FormControl>
      <FormControl className={classes.halfFormControl}>
        <TextField
          fullWidth
          id="email"
          name="email"
          margin="dense"
          variant="outlined"
          label={t("company_email_label")}
          error={!!props.errors?.email}
          placeholder={t("company_email_label")}
          helperText={props.errors?.email ? props.errors?.email.message.toString() : null}
          value={props.formFields.email || ""}
          onChange={editCompanyFormDetails("email")}
        />
      </FormControl>
      <FormControl className={classes.halfFormControl}>
        <TextField
          fullWidth
          id="phone"
          name="phone"
          margin="dense"
          variant="outlined"
          label={t("company_phone_label")}
          error={!!props.errors?.phone}
          placeholder={t("company_phone_label")}
          helperText={props.errors?.phone ? props.errors?.phone.message.toString() : null}
          value={props.formFields.phone || ""}
          onChange={editCompanyFormDetails("phone")}
        />
      </FormControl>
      <FormControl className={classes.halfFormControl}>
        <TextField
          fullWidth
          id="registration_number"
          name="registration_number"
          margin="dense"
          variant="outlined"
          label={t("company_registration_number_label")}
          error={!!props.errors?.registration_number}
          placeholder={t("company_registration_number_label")}
          helperText={props.errors?.registration_number ? props.errors?.registration_number.message.toString() : null}
          value={props.formFields.registration_number || ""}
          onChange={editCompanyFormDetails("registration_number")}
        />
      </FormControl>
      {/* <FormControl className={classes.halfFormControl}>
        <TextField
          fullWidth
          id="year_of_foundation"
          name="year_of_foundation"
          margin="dense"
          variant="outlined"
          label={t("company_year_of_foundation_label")}
          error={!!props.errors?.year_of_foundation}
          placeholder={t("company_year_of_foundation_label")}
          helperText={props.errors?.year_of_foundation ? props.errors?.year_of_foundation.message : null}
          value={props.formFields.year_of_foundation || ""}
          onChange={editCompanyFormDetails("year_of_foundation")}
        />
      </FormControl> */}
      <FormControl className={classes.halfFormControl}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            fullWidth
            format="yyyy"
            margin="dense"
            views={["year"]}
            inputVariant="outlined"
            id="year_of_foundation"
            name="year_of_foundation"
            error={!!props.errors?.year_of_foundation}
            label={t("company_year_of_foundation_label")}
            placeholder={t("company_year_of_foundation_label")}
            onChange={editCompanyDatesFormDetails("year_of_foundation")}
            helperText={props.errors?.year_of_foundation ? props.errors?.year_of_foundation.message.toString() : null}
            value={props.formFields?.year_of_foundation ? `${props.formFields?.year_of_foundation}-01-01` : null}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
      <FormControl className={classes.halfFormControl}>
        <Controller
          render={data => {
            return (
              <TextField
                select
                fullWidth
                margin="dense"
                variant="outlined"
                id="working_hours_id"
                name="working_hours_id"
                label={t("working_hours_label")}
                placeholder={t("working_hours_label")}
                error={!!props.errors?.working_hours_id}
                value={props.formFields.working_hours_id !== null ? props.formFields.working_hours_id : ""}
                onChange={event => {
                  editCompanyFormDetails("working_hours_id")(event)
                }}
                helperText={props.errors?.working_hours_id ? props.errors?.working_hours_id.message : null}
              >
                {workingHoursOptions.map(option => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  )
                })}
              </TextField>
            )
          }}
          name="working_hours_id"
          defaultValue={props.formFields.working_hours_id}
          rules={{}}
          control={props.control}
        />
      </FormControl>
      <FormControl className={classes.halfFormControl}>
        <Controller
          render={data => {
            return (
              <TextField
                select
                fullWidth
                margin="dense"
                variant="outlined"
                id="company_size_id"
                name="company_size_id"
                label={t("company_size_label")}
                placeholder={t("company_size_label")}
                error={!!props.errors?.company_size_id}
                value={props.formFields.company_size_id !== null ? props.formFields.company_size_id : ""}
                onChange={event => {
                  editCompanyFormDetails("company_size_id")(event)
                }}
                helperText={props.errors?.company_size_id ? props.errors?.company_size_id.message.toString() : null}
              >
                {companySizeOptions.map(option => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  )
                })}
              </TextField>
            )
          }}
          name="company_size_id"
          defaultValue={props.formFields.company_size_id}
          rules={{}}
          control={props.control}
        />
      </FormControl>
      <FormControl className={classes.halfFormControl}>
        <Controller
          render={data => {
            return (
              <SectorField
                value={props.formFields.sector_id}
                handleSelect={(value: any) => {
                  if (value !== null && value !== undefined) {
                    setSectorField(value)
                  }
                }}
              />
            )
          }}
          name="sector_id"
          rules={{}}
          control={props.control}
          defaultValue={props.formFields.sector_id}
        />
        {props.errors?.sector_id ? <FormHelperText style={{ color: "#EF4935" }}>{props.errors?.sector_id?.message}</FormHelperText> : null}
      </FormControl>
      <FormControl className={classes.halfFormControl}>
        <Controller
          render={data => {
            return (
              <IndustryField
                value={props.formFields.industry_id}
                handleSelect={(value: any) => {
                  if (value !== null && value !== undefined) {
                    setIndustryField(value)
                  }
                }}
              />
            )
          }}
          name="industry_id"
          rules={{}}
          control={props.control}
          defaultValue={props.formFields.industry_id}
        />
        {props.errors?.industry_id ? <FormHelperText style={{ color: "#EF4935" }}>{props.errors?.industry_id?.message}</FormHelperText> : null}
      </FormControl>
    </React.Fragment>
  )
}

export default BasicInfo
