import React from "react"
import { useTranslation } from "react-i18next"

import Button from "@material-ui/core/Button"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import useStyles from "../styles"
import { applicantStages } from "../../../../utils/data/enums"
interface Props {
  stageEnum: any
  readOnly: boolean
  handleUpdate: (value: any) => void
}

const ApplicantStageEnum
: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const statusCode = props.stageEnum

  const statuses = applicantStages.map(item => {
    return { value: item, label: t(`stage_id_enum_${item}`) };
  })

  return (
    <div
      className={"d-inline-block va-middle"}
      style={{
        display: "flex",
        alignItems: "center"
        // marginLeft: '10px'
      }}
    >
      <Typography variant="body1" className={classes.label} style={{ marginRight: "5px" }}>
        {t("status")}:{" "}
      </Typography>
      {!props.readOnly ? (
        <FormControl>
          <TextField
            select
            disabled={props.readOnly}
            placeholder={t("status")}
            value={statusCode}
            onChange={event => {
              props.handleUpdate(event.target.value)
            }}
            helperText=""
            margin="dense"
            variant="outlined"
            style={{ margin: "0px" }}
          >
            {statuses.map(option => (
              <MenuItem key={option.value} disabled={option.value === "NEW"} value={option.value} style={{ paddingRight: "10px" }}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      ) : (
        <div style={{ marginTop: "5px" }}>
          <Typography variant="body1" style={{ display: "inline", marginRight: "10px", marginLeft: "10px" }}>
            {t("stage")}:
          </Typography>
          <Button disabled variant="outlined" style={{ height: "39px" }}>
            {t(`stage_id_${statusCode}`)}
          </Button>
        </div>
      )}
    </div>
  )
}

export default ApplicantStageEnum

