import React, { useState } from "react"

import { useTranslation } from "react-i18next"

import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"

import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"

import useStyles from "./styles"
import imageFile2Base64 from "../../utils/imageFile2Base64"
import ImageEditor from "./ImageEditor"

interface Props {
  defaultImageUrl?: string | null
  removeImage?: () => void
  onImagePicked?: (image: File) => void
}

const ImagePicker: React.FC<Props> = props => {
  const classes = useStyles()

  const [onMouseHover, setOnMouseHover] = useState(false)
  const [imageModalOpen, setImageModalOpen] = useState(false)

  const [newImage, setNewImage] = useState<any>(props.defaultImageUrl)
  const [imageSelected, setImageSelected] = useState<any>(props.defaultImageUrl)

  const { t } = useTranslation()

  const handleMouseHover = () => {
    setOnMouseHover(true)
  }

  const handleMouseLeave = () => {
    setOnMouseHover(false)
  }

  const handleImageModalOpen = () => {
    setImageModalOpen(true)
  }

  const handleImageModalClose = () => {
    setImageModalOpen(false)
  }

  const handleImageDelete = () => {
    if (props.removeImage) {
      props.removeImage()
    }
    setNewImage(null)
    setImageSelected(null)
  }

  const handleUpload = async (file: any) => {
    if (props.onImagePicked) {
      await props.onImagePicked(file)
    }
    setImageSelected(URL.createObjectURL(file))
    setNewImage(URL.createObjectURL(file))
    handleImageModalClose()
  }

  const handleNewImage = (e: any) => {
    const file = e.target.files[0]
    if (file) {
      imageFile2Base64(file).then(response => {
        setNewImage(response)
        handleImageModalOpen()
      })
    }
  }

  return (
    <div className={classes.imagePicker}>
      {props.defaultImageUrl || imageSelected ? (
        <CardMedia onMouseOver={handleMouseHover} onMouseLeave={handleMouseLeave} image={imageSelected ?? props.defaultImageUrl} title="Image" className={classes.media}>
          <div className={classes.imageOverlayContainer}>
            {onMouseHover ? (
              <div className={classes.imageOverlay}>
                <IconButton onClick={handleImageModalOpen} className={classes.imageIconButton} title="Edit Image">
                  <EditIcon color="primary" />
                </IconButton>
                <IconButton onClick={handleImageDelete} className={classes.imageIconButton} title="Delete Image">
                  <DeleteIcon color="error" />
                </IconButton>
              </div>
            ) : null}
          </div>
        </CardMedia>
      ) : (
        <div>
          <input accept="image/*" name="new-image" style={{ display: "none" }} id="new-image-input" type="file" onChange={handleNewImage} />
          <label className={classes.addImageCard} htmlFor="new-image-input">
            <div style={{ textAlign: "center" }}>
              <Typography>{t("add_image")}</Typography>
              <AddIcon color="secondary" />
            </div>
          </label>
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={imageModalOpen}
        closeAfterTransition
        className={classes.modal}
        BackdropComponent={Backdrop}
        onClose={handleImageModalClose}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={imageModalOpen}>
          <div className={classes.modalPaper}>
            <ImageEditor onSave={handleUpload} defaultImageUrl={newImage} onCloseModal={handleImageModalClose} />
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default ImagePicker
