import React from "react"

import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { IApplicant } from "../../../../types/applicants"
import { Autocomplete } from "@material-ui/lab"
import { addApplicantsToFolder } from "../../../../api/iskibris/iskibris-methods"
import HTTPResponses from "../../../../tools/http.responses"
import { flashActions } from "../../../../store/actions/flash-messaging"
import { Button, CircularProgress, TextField } from "@material-ui/core"
import { applicantsActions } from "../../../../store/actions/applicants"

import * as yup from "yup"
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"

interface AddProps {
  applicant: IApplicant
  applicantFolders?: any[]
  onSuccess?: (data?: any) => void
  onCancel?: () => void
}

const schema = yup
  .object({
    folder_id: yup.number().required(),
  })
  .required()

type Inputs = {
  folder_id: number;
}

const AddApplicantToFolderForm: React.FC<AddProps> = ({applicant, applicantFolders, onSuccess, onCancel}) => {

  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(false)

  const {
    handleSubmit,
    setError,
    control,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  })

  const folders = useSelector((state: any) => state.applicantsReducer.folders)

  const handleChange = (event: any, newValue: any) => {
    setValue('folder_id', newValue?.id)
  }

  const getOptionLabel = (option: any) => {
    return option.title || ""
  }

  const getOptionSelected = (option: any, value: any) => {
    return option?.id === value?.id
  }

  const fetchSingleApplicant = async (id: any) => {
    dispatch(await applicantsActions.fetchSingleApplicant(id))
  }


  const onSubmit = async (data: any) => {
    setLoading(true)
    addApplicantsToFolder(applicant?.id, data)
      .then(response => {
        if(onSuccess){
          onSuccess(response.data)
        }
        fetchSingleApplicant(applicant?.id)
        dispatch(
          flashActions.addFlashMessage(t(`add_application_folder_success`), "success")
        )
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)

        const resErrors = error?.response?.data?.errors
        const keys = Object.keys(resErrors)
        keys.forEach((item: any) => {
          const itemErr = resErrors[item][0]
          setError(item, { message: itemErr })
        })

        if (error?.response?.status === HTTPResponses.INTERNAL_SERVER_ERROR) {
          dispatch(
            flashActions.addFlashMessage(t("server_error_fail"), "error")
          )
        } else {
          dispatch(
            flashActions.addFlashMessage(t(`add_application_folder_error`), "error")
          )
          return
        }
      })
  }

  return (
    <div style={{marginBottom: "1rem"}}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={'folder_id'}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <>
              <Autocomplete
                autoHighlight
                id="school-select-demo"
                onChange={handleChange}
                getOptionLabel={getOptionLabel}
                getOptionSelected={getOptionSelected}
                placeholder={t("previous_job_placeholder")}
                classes={{ option: "user-education__form-option" }}
                options={folders ?? []}
                renderOption={(option: any) => <React.Fragment>{getOptionLabel(option)}</React.Fragment>}
                renderInput={params => (
                  <TextField
                    {...params}
                    margin="dense"
                    variant="outlined"
                    name="folder_id"
                    error={!!errors?.folder_id}
                    helperText={errors?.folder_id?.message?.toString()}
                    label={t("add_candidate_to_folder")}
                    placeholder={t("add_candidate_to_folder")}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password" // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </>
          )}
        />
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "1rem"}}>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            {t("cancel")}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {loading ? <CircularProgress size={22} style={{color: "#FFFFFF"}} /> : t("submit")}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default AddApplicantToFolderForm