import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2, 2)
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxWidth: "1080px",
    width: "100%",
    outline: "none",
    position: "relative",
    maxHeight: "100%",
    overflow: "auto",
    padding: theme.spacing(2, 2),
    borderRadius: "1rem"
  },
  toolbar: {
    backgroundColor: theme.palette.primary.dark,
    color: "#fff"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none"
  },
  tableCell: {
    borderBottom: "none !important"
  },
  modalCloseContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 1rem"
  },
  modalTypographyTitle: {
    display: "flex",
    alignItems: "center",
    margin: "0"
  },
  modalTypographyCaption: {
    margin: "0px 1rem",
    color: "#393939"
  },
  dropzoneClassImage: {
    display: "none"
  },
  textbutton: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  iconbutton: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  text: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  icon: {
    marginRight: "10px",
    [theme.breakpoints.down("xs")]: {
      height: "20px",
      width: "20px",
      marginRight: "0px"
    }
  }
}))

export default useStyles
