import React from "react"
import { Dispatch } from "redux"
import Moment from "react-moment"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import MuiLink from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"

import useStyles from "../../../styles"
import LongMenu from "../../../../../LongMenu"
import { IActionType } from "../../../../../../types"
import { IComment } from "../../../../../../types/applicants"
import { applicantsActions } from "../../../../../../store/actions/applicants"
import { Link } from "react-router-dom"
import { ICompany } from "../../../../../../types/company"

interface Props {
  comment: IComment
  company?: ICompany,
  readOnly?: boolean
  deleteComment: (id: any) => void
  editComment: (id: any, info: any) => void
}

const SingleNote: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)
  const [hoveredOver, setHoveredOver] = React.useState(false)
  const [editingStatus, setEditingStatus] = React.useState(false)
  const [commentField, setCommentField] = React.useState(props.comment.body)

  const handleMouseOver = () => {
    setHoveredOver(true)
  }

  const handleMouseLeave = () => {
    setHoveredOver(false)
  }

  const handleDelete = async () => {
    await props.deleteComment(props.comment.id)
  }

  const handleEdit = () => {
    setEditingStatus(!editingStatus)
  }

  const handleEditCancel = () => {
    setEditingStatus(false)
  }

  const handleEditSubmit = async () => {
    setLoading(true)
    const comment = {
      body: commentField
    }
    await props.editComment(props.comment.id, comment)
    // setLoading(false);
    setEditingStatus(false)
  }

  const handleCommentChange = (event: any) => {
    setCommentField(event.target.value)
  }

  return (
    <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} className={classes.note}>
      <div className={classes.infoCardHeader}>
        <Typography variant="subtitle2" className={classes.author}>
          {props.comment.author_name} - {<Moment fromNow>{props.comment.created_at}</Moment>}{" "}
          <Link to={`/jobs/${props.comment?.job_id}/edit`} className={classes.noteJobTitle}>
            {props.comment?.job_title}
          </Link>
          {
            props.company?.id !== props.comment?.company_id && (
              <>
                <span>-</span>
                <MuiLink href={`/applicants/${props.comment?.applicant_id}?company=${props.comment?.company_id}`} className={classes.noteCompanyName}>
                  {props.comment?.company_name}
                </MuiLink>
              </>
            )
          }
        </Typography>
        {props.readOnly ? null : hoveredOver ? (
          <LongMenu
            options={[
              { title: t("edit"), handler: handleEdit },
              { title: t("delete"), handler: handleDelete }
            ]}
          />
        ) : null}
      </div>
      {!editingStatus ? (
        <Typography variant="body1" onClick={handleEdit}>
          {props.comment.body}
        </Typography>
      ) : (
        <div>
          <TextField
            value={commentField}
            onChange={handleCommentChange}
            variant="outlined"
            margin="dense"
            multiline
            fullWidth
            rows={4}
            autoFocus
            placeholder={t("edit_your_note")}
          />
          <div className={classes.buttonArea}>
            <Button variant="outlined" color="primary" onClick={handleEditCancel} className={classes.cancelButton}>
              {t("cancel")}
            </Button>
            <Button variant="contained" color="primary" className={classes.addButton} onClick={handleEditSubmit}>
              {loading ? <CircularProgress size={25} style={{ color: "white" }} /> : t("save")}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    deleteComment: async (id: any) => dispatch(await applicantsActions.deleteComment(id)),
    editComment: async (id: any, body: any) => dispatch(await applicantsActions.editComment(id, body))
  }
}

export default connect(null, mapDispatchToProps)(SingleNote)
