import React from "react"
import { FormInputText } from "../../FormInputText";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button,  CircularProgress, FormControl } from "@material-ui/core";
import * as yup from "yup"
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createFolder, updateCompanyFolders } from "../../../api/iskibris/iskibris-methods";
import { flashActions } from "../../../store/actions/flash-messaging";
import HTTPResponses from "../../../tools/http.responses";
import { applicantsActions } from "../../../store/actions/applicants";

interface Props {
  folderId?: number;
  defaultValues?: any;
  type: "edit" | "create";
  onSuccess?: () => void;
  onCancel?: () => void;
}

const schema = yup
  .object({
    title: yup.string().required(),
    description: yup.string().optional(),
  })
  .required()

type Inputs = {
  title: string;
  description: string;
}

const FolderForm: React.FC<Props> = ({ type, folderId, defaultValues, onSuccess, onCancel }) => {

  const {t} = useTranslation()
  const [loading, setLoading] = React.useState(false)

  const dispatch = useDispatch()
 
  const company = useSelector((state: any) => state.companyReducer.company)

  const {
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const submitDataToServer = async (data: any) => {
    setLoading(true)

    if(type === "create")
    {
      await handleCreate(data);
    } else {
      await handleEdit(data)
    }
  }

  const handleCreate = async ( data : any ) => {
    await createFolder(company?.id, {...data})
      .then(async (response) => {
        await handleValidResponse(response)
        setLoading(false)
      })
      .catch((error: any) => {
        handleErrorResponse(error)
        setLoading(false)
      })
  }

  const handleEdit = async ( data : any ) => {
    await updateCompanyFolders(folderId ?? company?.id, {...data})
      .then(async (response) => {
        await handleValidResponse(response)
        setLoading(false)
      })
      .catch((error: any) => {
        handleErrorResponse(error)
        setLoading(false)
      })
  }

  const handleValidResponse = async (response: any) => {
    dispatch(await applicantsActions.clearAllFilters(company?.id))
    dispatch(await applicantsActions.fetchCompanyFolders(company?.id))
    dispatch( flashActions.addFlashMessage(t(`folder_action_success_${type}`), "success") )
    if(onSuccess){ onSuccess() }
  }

  const handleErrorResponse = (error: any) => {
    if (!error?.response) {
      dispatch( flashActions.addFlashMessage(t("network_error"), "error") )
      return
    }

    const resErrors = error?.response?.data?.errors
    const keys = Object.keys(resErrors)
    keys.forEach((item: any) => {
      const itemErr = resErrors[item][0]
      setError(item, { message: itemErr })
    })

    if (error?.response?.status === HTTPResponses.INTERNAL_SERVER_ERROR) {
      dispatch(
        flashActions.addFlashMessage(t("server_error_fail"), "error")
      )
    } else {
      dispatch(
        flashActions.addFlashMessage(t(`folder_action_error_${type}`), "error")
      )
      return
    }
  }

  return (
    <div style={{width: "100%"}}>
      <form onSubmit={handleSubmit(submitDataToServer)}>
        <FormControl fullWidth>
          <FormInputText required name="title" control={control} label={t("title")} errors={errors} />
        </FormControl>
        <FormControl fullWidth>
          <FormInputText rows={4} name="description" multiline control={control} label={t("description")} errors={errors} />
        </FormControl>
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "1rem"}}>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            {t("cancel")}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {loading ? <CircularProgress size={22} style={{color: "#FFFFFF"}} /> : t("submit")}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default FolderForm