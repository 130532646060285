import React from "react"

import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import Toolbar from "@material-ui/core/Toolbar"
import Backdrop from "@material-ui/core/Backdrop"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"

import CloseIcon from "@material-ui/icons/KeyboardArrowDown"

import useStyles from "./styles"

interface Props {
  open: boolean
  children: any
  header: string
  handleClose: () => void
}

const Container: React.FC<Props> = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={props.open}>
          <div className={classes.modalPaper}>
            <Toolbar variant="dense" className={classes.toolbar}>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                {props.header}
              </Typography>
              <IconButton onClick={props.handleClose}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <div className={classes.contents}>{props.children}</div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default Container
