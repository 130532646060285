import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 3)
    // marginRight: "20px"
  },
  table: {
    border: "1px solid #E7E7E7",
    position: "relative",
    marginTop: "20px"
  },
  tableCell: {
    borderBottom: "none"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxWidth: "600px",
    width: "100%",
    outline: "none",
    position: "relative",
    maxHeight: "100%",
    overflow: "auto"
  },
  toolbar: {
    backgroundColor: "#F2F2F2"
  },
  contents: {
    margin: theme.spacing(4)
  },
  buttons: {
    marginTop: "20px"
  }
}))

export default useStyles
