import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2, 2)
  },
  subtitleText: {
    color: "#797979",
    marginBottom: "10px"
  },
  link: {
    textDecoration: "none"
  },
  photo: {
    width: "200px",
    height: "200px"
  },
  companyNameText: {
    fontWeight: "bold"
  },
  companyLogo: {
    width: "40px",
    height: "40px"
  }
}))

export default useStyles
