import { action } from "typesafe-actions"
import { actionTypes } from "./actionTypes"

export const authActions = {
  authenticate: () => {
    return action(actionTypes.AUTHENTICATE, null)
  },
  unauthenticate: () => {
    return action(actionTypes.UNAUTHENTICATE, null)
  }
}
