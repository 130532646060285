import React from "react"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useHistory } from "react-router"
import { useTranslation } from "react-i18next"

import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"

import AddIcon from "@material-ui/icons/Add"
import ClearIcon from "@material-ui/icons/ClearOutlined"

import Link from "@material-ui/core/Link"
import Avatar from "@material-ui/core/Avatar"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"

import useStyles from "./styles"
import { IActionType } from "../../../types"
import ModalContainer from "../ModalContainer"
import { ICompany } from "../../../types/company"
import { companyActions } from "../../../store/actions/company"
import avatarPlaceholder from "../../../assets/images/placeholder-company.png"
import Cookies from "js-cookie"

interface Props {
  company: ICompany
  companies: ICompany[]
  searchResults: ICompany[]
  handleClose: () => void
  searchCompanies: (companies: ICompany[], query: string) => void
}

const MobileCompanyMenu: React.FC<Props> = props => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const [searchQuery, setSearchQuery] = React.useState("")

  const handleChangeSearchQuery = (event: any) => {
    if (event.target.value.length > 0) {
      props.searchCompanies(props.companies, event.target.value)
    }
    setSearchQuery(event.target.value)
  }

  const handleCreateCompany = () => {
    history.push("/employer/create")
    props.handleClose()
  }

  const handleMouseDownClear = (event: any) => {
    event.preventDefault()
  }

  let companies = searchQuery.length > 0 ? props.searchResults : props.companies

  return (
    <ModalContainer open handleClose={props.handleClose}>
      <div className={classes.root}>
        {props.companies ? (
          props.companies.length > 5 ? (
            <div style={{ padding: "5px" }}>
              <TextField
                fullWidth
                autoFocus
                margin="dense"
                variant="outlined"
                value={searchQuery}
                onChange={handleChangeSearchQuery}
                placeholder={t("search_for_more")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle"
                        onClick={() => {
                          setSearchQuery("")
                        }}
                        onMouseDown={handleMouseDownClear}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Divider />
            </div>
          ) : null
        ) : null}
        <div className={classes.ratingsContainer}>
          {companies.slice(0, 5).map((item: any) => {
            const labelId = `checkbox-list-label-${item.id}`
            return (
              <Link
                href="/"
                key={item.id}
                onClick={() => {
                  localStorage.setItem("current_company_id", item.id)
                  Cookies.set("CompanyToken", item.token, { secure: true, sameSite: "none" })
                }}
                style={{ textDecoration: "none" }}
              >
                <ListItem key={item.id} role={undefined} dense button selected={item.id === props.company.id}>
                  <ListItemAvatar>
                    <Avatar alt={item.name} src={item.logo || avatarPlaceholder} className={classes.avatar} />
                  </ListItemAvatar>
                  <ListItemText id={labelId}>
                    {item.name} <span className={classes.shortLabel}>{item.code}</span>
                  </ListItemText>
                </ListItem>
              </Link>
            )
          })}
        </div>
        <Divider />
        <ListItem dense button role={undefined} key={"create_company"} onClick={handleCreateCompany}>
          <ListItemIcon>
            <AddIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText style={{ padding: "5px" }} id={"create_company"}>
            {t("create_company")}
          </ListItemText>
        </ListItem>
      </div>
    </ModalContainer>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company,
    companies: state.companyReducer.companies,
    searchResults: state.companyReducer.searchResults
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => ({
  searchCompanies: (companies: any, query: any) => dispatch(companyActions.searchCompanies(companies, query))
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileCompanyMenu)
