import React from "react"
import { useTranslation } from "react-i18next"

import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"

import useStyles from "../styles"

interface Props {
  formFields: any
  errors: any
  register: any
  editFormFields: (value: any) => void
}

const CompanyLeader: React.FC<Props> = props => {
  const { t } = useTranslation()
  const classes = useStyles()

  const director = props.formFields.directors_json

  const changeValues = (name: any) => (event: any) => {
    const newDirectorsJson = {
      ...director,
      [name]: event.target.value
    }
    const info = {
      directors_json: newDirectorsJson
    }
    props.editFormFields({ ...info })
  }

  return (
    <React.Fragment>
      <Typography variant="h6">{t("company_leader")}</Typography>
      <FormControl className={classes.halfFormControl}>
        <TextField
          fullWidth
          id="name_field"
          name="name"
          margin="dense"
          variant="outlined"
          label={t("leader_name_label")}
          error={!!props.errors?.director_json}
          placeholder={t("leader_name_label")}
          helperText={props.errors?.director_json ? props.errors?.director_json[0] : null}
          value={director ? director.name || "" : ""}
          onChange={changeValues("name")}
        />
      </FormControl>
      <FormControl className={classes.halfFormControl}>
        <TextField
          fullWidth
          id="title_field"
          name="title"
          margin="dense"
          variant="outlined"
          label={t("leader_title_label")}
          error={!!props.errors?.director_json}
          placeholder={t("leader_title_label")}
          helperText={props.errors?.director_json ? props.errors?.director_json[0] : null}
          value={director ? director.title || "" : ""}
          onChange={changeValues("title")}
        />
      </FormControl>
    </React.Fragment>
  )
}

export default CompanyLeader
