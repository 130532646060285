import React from "react"

import { useTranslation } from "react-i18next"
import { Text, StyleSheet } from "@react-pdf/renderer"

import Section from "./Section"
import { IUser } from "../../../types/user"

interface Props {
  user: IUser
}

const PersonalSummary: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation()

  return (
    <>
      {user.about && user.about.length > 0 && (
        <Section title={t("personal_summary")}>
          <Text style={styles.text}>{user.about}</Text>
        </Section>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: "11px"
  }
})

export default PersonalSummary
