import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Dispatch } from "redux"

// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"

import ItemIcon from "@material-ui/icons/Brightness1"
import CheckedItemIcon from "@material-ui/icons/CheckCircle"
import { ICompany, ICompanyBenefit } from "../../../types/company"
import { IActionType } from "../../../types"
import useStyles from "../styles"
import { companyActions } from "../../../store/actions/company"
import CandidateAppeal from "../../CandidateAppealChecklist"

const checkedStyle = {
  color: "#59AB43",
  display: "inline",
  verticalAlign: "middle",
  marginRight: "5px"
}
const uncheckStyle = {
  color: "#bfbfbf",
  display: "inline",
  verticalAlign: "middle",
  marginRight: "5px"
}

interface Props {
  company: ICompany
  benefits: ICompanyBenefit[]
  fetchCompanyBenefits: (id: any) => void
}

const CandidateAppealTab: React.FC<Props> = props => {
  const { t } = useTranslation()

  const classes = useStyles()

  const history = useHistory()

  const { company, benefits, fetchCompanyBenefits } = props

  useEffect(() => {
    if (!benefits) {
      fetchCompanyBenefits(company.id)
    }
  }, [company, benefits, fetchCompanyBenefits])

  const checkBenefitsAdded = () => {
    if (props.benefits) {
      let i = 0
      while (i < props.benefits.length) {
        if (props.benefits[i].is_selected === 1) {
          return true
        }
        i += 1
      }
    }
    return false
  }

  const calculateAppealScore = () => {
    let score = 0
    const company: any = props.company
    const checkAdded = (key: any) => {
      if (company[key]) {
        score += 1
      }
    }

    checkAdded("logo")
    checkAdded("benefits")
    checkAdded("about")
    checkAdded("name")
    checkAdded("mission")
    // checkAdded("photos");
    if (checkBenefitsAdded()) {
      score += 1
    }

    const percentage = (score / 6) * 100
    if (percentage < 25) {
      return [1, 0, 0, 0]
    } else if (percentage < 50) {
      return [1, 1, 0, 0]
    } else if (percentage < 75) {
      return [1, 1, 1, 0]
    } else {
      return [1, 1, 1, 1]
    }
  }

  return (
    <React.Fragment>
      {props.company ? (
        <div>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>
              {t("candidate_appeal")}
            </Typography>
            <Typography variant="body1">{t("candidate_appeal_subtext")}</Typography>
            <div style={{ marginTop: "15px", marginBottom: "10px" }}>
              {calculateAppealScore().map(item => {
                if (item === 1) {
                  return <CheckedItemIcon fontSize="large" style={checkedStyle} key={Math.random()} />
                } else {
                  return <ItemIcon fontSize="large" style={uncheckStyle} key={Math.random()} />
                }
              })}
            </div>
            <Typography variant="body1">
              {t("employer_profile")} - {calculateAppealScore().reduce((a, b) => a + b, 0)} / 4
            </Typography>
          </Paper>
          <Paper className={classes.paper} style={{ marginTop: "20px" }}>
            <Typography variant="h6" gutterBottom>
              {t("employer_profile")}
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={3} direction="column" alignItems="center" justify="center" container>
                <Grid item>
                  <CheckedItemIcon fontSize="large" style={{ margin: "auto", color: calculateAppealScore().reduce((a, b) => a + b, 0) < 4 ? "#bfbfbf" : "#59AB43" }} />
                  <Typography variant="body1" style={{ margin: "auto" }}>
                    {calculateAppealScore().reduce((a, b) => a + b, 0)} {t("of")} 4
                  </Typography>
                </Grid>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => {
                    history.push("/employer/profile")
                  }}
                >
                  {t("go_to_employer_profile")}
                </Button>
              </Grid>
              <Grid item xs={12} sm={8}>
                <CandidateAppeal />
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper} style={{ marginTop: "20px" }}>
            <Typography variant="h6" gutterBottom>
              {t("faqs")}
            </Typography>
            {[1, 2, 3].map(item => {
              return (
                <div key={item} style={{ marginBottom: "20px" }}>
                  <Typography variant="body1" style={{ marginBottom: "10px" }} gutterBottom>
                    {t(`faq_question_${item}`)}
                  </Typography>
                  <Typography variant="body2" style={{ color: "#797979" }} gutterBottom>
                    {t(`faq_answer_${item}`)}
                  </Typography>
                </div>
              )
            })}
          </Paper>
        </div>
      ) : (
        <Grid item xs={12} sm={3} direction="column" alignItems="center" justify="center" container style={{ minHeight: "70vh" }}>
          <Grid item>
            <CircularProgress color="primary" />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company,
    benefits: state.companyReducer.benefits
    // locations: state.companyReducer.locations,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchCompanyBenefits: async (id: any) => dispatch(await companyActions.fetchCompanyBenefits(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateAppealTab)
