import React from "react"

import AddIcon from "@material-ui/icons/AddAPhotoOutlined"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"

import { useTranslation } from "react-i18next"
import FilesDropZone from "../../../../FilesDropzone"

import useStyles from "./styles"

interface Props {
  handleSubmitPhotos: (files: any) => void
}

const AddPhotoTile: React.FC<Props> = props => {
  const classes = useStyles()

  const { t } = useTranslation()

  const [addPhotoOpen, setAddPhotoOpen] = React.useState(false)

  const handleSubmitPhoto = (files: any) => {
    props.handleSubmitPhotos(files)
    setAddPhotoOpen(false)
  }

  const handleClick = () => {
    setAddPhotoOpen(true)
  }

  const handleCloseAddPhoto = () => {
    if (addPhotoOpen) {
      setAddPhotoOpen(false)
    }
  }

  return (
    <div className={classes.root}>
      <div onClick={handleClick} className={classes.buttonDiv}>
        <AddIcon fontSize="large" style={{ fontSize: "40px" }} />
      </div>
      <ClickAwayListener onClickAway={handleCloseAddPhoto}>
        <React.Fragment>
          <FilesDropZone
            open={addPhotoOpen}
            handleSubmitFiles={handleSubmitPhoto}
            handleClose={handleCloseAddPhoto}
            limit={5}
            dropzoneText={t("drag_and_drop_image")}
            acceptedFiles={["image/*"]}
          />
        </React.Fragment>
      </ClickAwayListener>
    </div>
  )
}

export default AddPhotoTile
