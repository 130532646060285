import React from "react"
import { useTranslation } from "react-i18next"

import Link from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import CancelIcon from "@material-ui/icons/Cancel"
import Typography from "@material-ui/core/Typography"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import CircularProgress from "@material-ui/core/CircularProgress"
import DialogContentText from "@material-ui/core/DialogContentText"

interface Props {
  question: string
  warning: string
  link?: string
  link_label?: string
  onConfirm: () => void
  children: React.ReactNode
}

const DeleteConfirmation: React.FC<Props> = props => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const [deleting, setDeletingState] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAgree = async () => {
    setDeletingState(true)
    await props.onConfirm()
    setDeletingState(false)
    setOpen(false)
  }

  // React.useEffect(()=>{
  //   return () => {
  //     setDeletingState(false);
  //   }
  // })

  return (
    <div>
      <div style={{ width: "100%" }} onClick={handleClickOpen}>
        {props.children}
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ width: "100%" }}>
          <div style={{ minHeight: "25px", height: "80px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CancelIcon fontSize="large" style={{ fontSize: "80px", padding: "0px", color: "#D64344" }} />
          </div>
          <span>
            <Typography variant="h6" style={{ textAlign: "center", marginTop: "10px" }}>
              {props.question}
            </Typography>
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ textAlign: "center" }}>
            {props.warning}
            {props.link && props.link_label ? (
              <Link color="primary" href={props.link} target="_blank" style={{ marginLeft: "5px", textDecoration: "none" }}>
                {props.link_label}
              </Link>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Button onClick={handleClose} variant="outlined" color="primary">
            {t("disagree")}
          </Button>
          <Button onClick={handleAgree} style={{ backgroundColor: "#D64344", color: "#FFF", width: "93.23px", height: "35px" }} autoFocus>
            {!deleting ? t("agree") : <CircularProgress size={24} style={{ color: "#ffffff" }} />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DeleteConfirmation
