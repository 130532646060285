/* eslint-disable react/prop-types */
import React, { useEffect } from "react"
import { connect } from "react-redux"

import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import Button from "@material-ui/core/Button"
import TableHead from "@material-ui/core/TableHead"
import Typography from "@material-ui/core/Typography"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
// import CircularProgress from '@material-ui/core/CircularProgress';

import AddUser from "./AddUser"
import TableItem from "./UserTableItem"
import { useTranslation } from "react-i18next"
import useStyles from "./styles"
import { ICompany, ICompanyUser, ICompanyUserInvitation } from "../../../types/company"
import { IUser } from "../../../types/user"
import { Dispatch } from "redux"
import { IActionType } from "../../../types"
import { companyActions } from "../../../store/actions/company"
import { userActions } from "../../../store/actions/user"
import { LinearProgress, useMediaQuery, useTheme } from "@material-ui/core"
import UserCard from "./UserCard"
import Can from "../../../utils/AccessControl/Can"
import AccessDeniedTooltip from "../../AccessDeniedToolTip"
import UserInvitationTableItem from "./UserInvitationTableItem"
import UserInvitationCard from "./UserInvitationCard"

interface Props {
  company: ICompany
  systemUsers: IUser[]
  companyUsers: ICompanyUser[]
  companyUserInvitations: ICompanyUserInvitation[]
  revokeCompanyUserInvitation: (invitationId: any, companyId: any) => void
  deleteCompanyUser: (userId: any, companyId: any) => void
  fetchCompanyUserInvitations: (id: any) => void
  fetchCompanyUsers: (id: any) => void
  fetchSystemUsers: () => void
}

const UsersRoles: React.FC<Props> = props => {
  const classes = useStyles()

  const { t } = useTranslation()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  const { company, companyUserInvitations, companyUsers, fetchCompanyUsers, fetchCompanyUserInvitations } = props

  useEffect(() => {
    if (company && !companyUsers) {
      fetchCompanyUsers(company.id)
    }
  }, [company, companyUsers, fetchCompanyUsers])

  useEffect(() => {
    if (company && !companyUserInvitations) {
      fetchCompanyUserInvitations(company.id)
    }
  }, [company, companyUserInvitations, fetchCompanyUserInvitations])

  const handleDelete = (id: any) => {
    props.deleteCompanyUser(id, company.id)
  }

  const handleRevokeInvite = (id: any) => {
    props.revokeCompanyUserInvitation(id, company.id)
  }

  return (
    <>
      <Paper className={classes.paper}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography style={{ display: "inline" }} variant="h6">
            {t("company_users")}
          </Typography>
          <Can I="add-company-user" a="all">
            <AddUser />
          </Can>
          <Can not I="add-company-user" a="all">
            <AccessDeniedTooltip link={"/billing/subscriptions"} linkLabel={t("go_to_subscriptions")} body={t("billing_cannot_add-company-users")}>
              <Button disabled color="default" variant="outlined">
                {t("invite_coworkers")}
              </Button>
            </AccessDeniedTooltip>
          </Can>
        </div>
        {matches ? (
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("name")}</TableCell>
                  <TableCell>{t("email")}</TableCell>
                  <TableCell>{t("roles")}</TableCell>
                  <TableCell>{t("last_login")}</TableCell>
                  <TableCell style={{ width: "15px" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.companyUsers ? (
                  props.companyUsers.map(item => {
                    return <TableItem key={item.id} item={item} handleDelete={handleDelete} />
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} rowSpan={4}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : props.companyUsers ? (
          props.companyUsers.map(item => {
            return <UserCard key={item.id} user={item} handleDelete={handleDelete} />
          })
        ) : null}
      </Paper>
      {companyUserInvitations && companyUserInvitations.length > 0 ? (
        <Paper className={classes.paper} style={{ marginTop: "1rem" }}>
          <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography style={{ display: "inline" }} variant="h6">
                {t("pending_company_user_invitations")}
              </Typography>
            </div>
            {matches ? (
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("email")}</TableCell>
                      <TableCell>{t("roles")}</TableCell>
                      <TableCell>{t("invited_at")}</TableCell>
                      <TableCell style={{ width: "15px" }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companyUserInvitations ? (
                      companyUserInvitations.map(item => {
                        return <UserInvitationTableItem key={item.id} item={item} onRevoke={handleRevokeInvite} />
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} rowSpan={4}>
                          <LinearProgress />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : companyUserInvitations ? (
              companyUserInvitations.map(item => {
                return <UserInvitationCard key={item.id} item={item} onRevoke={handleRevokeInvite} />
              })
            ) : null}
          </>
        </Paper>
      ) : null}
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    companyUserInvitations: state.companyReducer.companyUserInvitations,
    companyUsers: state.companyReducer.companyUsers,
    systemUsers: state.userReducer.systemUsers,
    company: state.companyReducer.company
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchSystemUsers: async () => dispatch(await userActions.fetchSystemUsers()),
    fetchCompanyUsers: async (companyId: any) => dispatch(await companyActions.fetchCompanyUsers(companyId)),
    deleteCompanyUser: async (userId: any, companyId: any) => dispatch(await companyActions.deleteCompanyUser(userId, companyId)),
    fetchCompanyUserInvitations: async (companyId: any) => dispatch(await companyActions.fetchPendingCompanyUserInvitaitons(companyId)),
    revokeCompanyUserInvitation: async (invitationId: any, companyId: any) => dispatch(await companyActions.revokeCompanyUserInvitation(invitationId, companyId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersRoles)
