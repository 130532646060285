import React from "react"
import { useTranslation } from "react-i18next"

import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { ICompany, ICompanyUpdate } from "../../../types/company"
import useStyles from "./styles"
import AddUpdate from "./AddUpdate"
import { Dispatch } from "redux"
import { IActionType } from "../../../types"
import { companyActions } from "../../../store/actions/company"
import { connect } from "react-redux"
import { Grid } from "@material-ui/core"
import CompanyUpdate from "./CompanyUpdate"

interface Props {
  company: ICompany
  updates: ICompanyUpdate[]
  fetchUpdates: (id: any) => void
}

const CompanyUpdates: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { company, updates, fetchUpdates } = props

  React.useEffect(() => {
    if (company && !updates) {
      fetchUpdates(company.id)
    }
  }, [company, updates, fetchUpdates])

  return (
    <div>
      <Grid container>
        <Grid item sm={12} md={9} lg={7}>
          <Paper className={classes.paper}>
            <div>
              <Typography variant="h6" gutterBottom>
                {t("add_company_update")}
              </Typography>
              <Typography variant="body2" gutterBottom className={classes.subtitleText}>
                {t("add_company_update_text")}
              </Typography>
              <AddUpdate />
            </div>
          </Paper>
          {updates
            ? updates.map(item => {
                return <CompanyUpdate key={item.id} update={item} company={company} />
              })
            : null}
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company,
    updates: state.companyReducer.updates
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchUpdates: async (companyId: any) => dispatch(await companyActions.fetchCompanyUpdates(companyId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyUpdates)
