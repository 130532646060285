import React from "react"

import moment from "moment"
import { useTranslation } from "react-i18next"
import { Text, View, StyleSheet } from "@react-pdf/renderer"

import Section from "./Section"
import { IUser } from "../../../types/user"
import { IApplicant } from "../../../types/applicants"

interface Props {
  user: IUser
}

const ApplicationOtherApplications: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      {user.user_applications && user.user_applications.length > 0 ? (
        <Section title={t("applications_other_applications")}>
          {user.user_applications.map((application: IApplicant) => {
            return (
              <View style={styles.singleNoteContainer} key={application.id} wrap>
                <View style={styles.metaDetailsContainer}>
                  <Text style={styles.primaryText}>
                    {application.job_title} - {application?.job_place_name}
                  </Text>
                </View>
                <View style={styles.bodyDetailsContainer}>
                  <Text style={styles.secondaryText}>{moment(application.created_at ?? application.date).format("DD MMMM YYYY - HH:mm")}</Text>
                </View>
              </View>
            )
          })}
        </Section>
      ) : null}
    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  singleNoteContainer: {
    display: "flex",
    padding: "5px 0px",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  },
  metaDetailsContainer: {
    padding: "2.5px 0px"
  },
  bodyDetailsContainer: {
    padding: "2.5px 0px"
  },
  primaryText: {
    fontWeight: 700,
    fontSize: "13px"
  },
  secondaryText: {
    fontSize: "11px"
  }
})

export default ApplicationOtherApplications
