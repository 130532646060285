import React, { useEffect } from "react"
import { connect } from "react-redux"

import { Paper, Typography, Divider, Grid, CircularProgress, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import useStyles from "../styles"
import NoMessages from "./NoMessages"
import SingleMessage from "./SingleMessage"
import { ICompany } from "../../../../types/company"
import { IMessageModel } from "../../../../types/messages"
import { IApplicant } from "../../../../types/applicants"
import { Dispatch } from "redux"
import { IActionType } from "../../../../types"
import { messagesActions } from "../../../../store/actions/messages"

interface Props {
  company: ICompany
  messages: IMessageModel[]
  applicant: IApplicant
  fetchAllMessages: (companyId: any, userId: any) => void
  openSendMessage: (recipients: any) => void
}

const MessageHistory: React.FC<Props> = props => {
  const { t } = useTranslation()

  const classes = useStyles()

  const { company, messages, applicant, fetchAllMessages } = props

  useEffect(() => {
    let comId = company.id
    let userID = applicant.user_id
    if (comId && userID) {
      fetchAllMessages(comId, userID)
    }
  }, [applicant, company, fetchAllMessages])

  const handleSendMessage = () => {
    props.openSendMessage([{ ...applicant }])
  }

  return (
    <Paper className={classes.messageHistoryPaper}>
      <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }} className={classes.messagingHistoryComponents}>
          <Typography variant="body1">{t("to")}:</Typography>
          <Typography variant="body1" style={{ marginLeft: "5px" }}>
            {applicant.name}
          </Typography>
        </div>
        <div>
          <Button variant="contained" color="primary" style={{ marginRight: "1rem" }} onClick={handleSendMessage}>
            {t("reply")}
          </Button>
        </div>
      </div>
      <Divider />
      {messages ? (
        messages.length > 0 ? (
          messages.map(item => {
            return <SingleMessage message={item} key={Math.random()} />
          })
        ) : (
          <NoMessages handleClick={handleSendMessage} />
        )
      ) : (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ height: "100%" }}>
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
    </Paper>
  )
}

const mapStateToProps = (state: any) => {
  return {
    messages: state.messagesReducer.messages,
    applicant: state.applicantsReducer.singleApplicant,
    company: state.companyReducer.company
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    // changeShowMessaging: () => dispatch(actionCreators.changeShowMessagingV2()),
    fetchAllMessages: async (comId: any, userId: any) => dispatch(await messagesActions.fetchMessages(comId, userId)),
    openSendMessage: async (recipients: any) => dispatch(messagesActions.openSendMessage(recipients))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageHistory)
