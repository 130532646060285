import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxWidth: "900px",
    width: "100%",
    outline: "none",
    position: "relative",
    maxHeight: "100%",
    overflowY: "auto"
  },
  mainContentContainer: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "column",
    padding: "1rem"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none"
  },
  modalCloseContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    paddingBottom: 0
  },
  iconButton: {
    padding: "5px"
  },
  actionButtons: {
    marginTop: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  formGroupContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start"
    }
  },
  rightHalfContainer: {
    width: "50%",
    paddingLeft: "5px",
    marginBottom: "0.675rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: "0px"
    }
  },
  leftHalfContainer: {
    width: "50%",
    paddingRight: "5px",
    marginBottom: "0.675rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingRight: "0px"
    }
  },
  controlContainer: {
    marginBottom: "0.675rem"
  }
}))

export default useStyles
