import { actionTypes } from "../actions/actionTypes"
import { IActionType } from "../../types"

interface IPermissionsReducerModel {
  abilities?: any[] | null
  userAbilities?: any[] | null
  errors?: any
}

const initialState: IPermissionsReducerModel = {
  abilities: null,
  userAbilities: null,
  errors: null
}

const reducer = (state: IPermissionsReducerModel = initialState, action: IActionType) => {
  switch (action.type) {
    case actionTypes.ADD_ABILITIES:
      const abilities = action.payload ? [...action.payload] : []
      return {
        ...state,
        userAbilities: abilities
      }
    case actionTypes.ADD_COMPANY_ABILITIES: {
      const abilities = action.payload ? [...action.payload] : []
      return {
        ...state,
        abilities: abilities
      }
    }
    case actionTypes.ADD_ABILITIES_ERROR: {
      return {
        ...state,
        errors: action.payload
      }
    }
    default:
      break
  }
  return state
}

export default reducer
