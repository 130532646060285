import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { flashActions } from "../../store/actions/flash-messaging"

import Snackbar from "./Snackbar"

interface SingleFlashProps {
  message: any
  handleClose: (id: any) => void
}

const SingleFlash: React.FC<SingleFlashProps> = ({ message, handleClose }) => {
  React.useEffect(() => {
    setTimeout(() => {
      handleClose(message.id)
    }, 5000)
  }, [handleClose, message.id])

  return (
    <div className="paper-snackbar__container">
      <Snackbar
        content={message.message}
        type={message.type}
        onDismiss={() => {
          handleClose(message.id)
        }}
      />
    </div>
  )
}

const FlashMessages: React.FC = () => {
  const dispatch = useDispatch()
  const messages = useSelector((state: any) => state.flashReducer.messages)

  const handleClose = (id: number) => {
    dispatch(flashActions.removeFlashMessage(id))
  }

  return (
    <React.Fragment>
      {messages.map((item: any) => {
        return <SingleFlash key={item.id} message={item} handleClose={handleClose} />
      })}
    </React.Fragment>
  )
}

export default FlashMessages
