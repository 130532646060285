import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import RedirectToEmployer from "../../../components/RedirectToEmployer"

import useStyles from "./styles"
import { ICompanyUserInvitation } from "../../../types/company"

import api from "../../../api/iskibris/iskibris"

import Link from "@material-ui/core/Link"
import Alert from "@material-ui/lab/Alert"
import CssBaseline from "@material-ui/core/CssBaseline"
import InfoOutlined from "@material-ui/icons/InfoOutlined"

interface Props {}

const NoCompanyManaged: React.FC<Props> = props => {
  const classes = useStyles()

  const {t} = useTranslation()
  const profile = useSelector((state: any) => state.userReducer.profile)

  const [companyUserInvitations, setCompanyUserInvitations] = React.useState<ICompanyUserInvitation[] | null>(null)

  React.useEffect(() => {
    if (profile) {
      api
        .get(`/api/hiring/v2/company-user-invitations/user/pending`)
        .then(response => {
          setCompanyUserInvitations(response.data)
        })
        .catch(error => {})
    }
  }, [profile])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.main}>
        <div>
          <div style={{marginTop: "2.5rem"}}>
            {companyUserInvitations &&
              companyUserInvitations.length > 0 &&
              companyUserInvitations.map(item => {
                return (
                  <Alert
                    severity="info"
                    icon={<InfoOutlined />}
                    closeText={t("dismiss")}
                    action={<Link href={`/invitation/${item.id}`}>{t("view_invitation")}</Link>}
                    style={{ borderRadius: "5px", marginBottom: "10px", alignItems: "center", fontSize: "1rem" }}
                  >
                    {t("company_user_invitation_alert", { company_name: item.company?.name })}
                  </Alert>
                )
              })
            }
          </div>
          <RedirectToEmployer />
        </div>
      </main>
    </div>
  )
}

export default NoCompanyManaged
