import thunk from "redux-thunk"
import { IActionType } from "../types"
import companyReducer from "./reducers/company"
import authReducer from "./reducers/authentication"
import impersonationReducer from "./reducers/impersonation"
import permissionsReducer from "./reducers/permissions"
import applicantsReducer from "./reducers/applicants"
import messagesReducer from "./reducers/messages"
import billingReducer from "./reducers/billing"
import systemReducer from "./reducers/system"
import jobsReducer from "./reducers/jobs"
import userReducer from "./reducers/user"
import flashReducer from "./reducers/flash-messaging"
import { createStore, combineReducers, compose, applyMiddleware } from "redux"
import { actionTypes } from "./actions/actionTypes"

const appReducers = combineReducers({
  authReducer,
  companyReducer,
  impersonationReducer,
  permissionsReducer,
  applicantsReducer,
  messagesReducer,
  billingReducer,
  systemReducer,
  flashReducer,
  userReducer,
  jobsReducer,
})

const log = (leading: any, value: any) => {
  if (process.env.REACT_APP_CURRENT_ENV === "development") {
    console.log(leading, value)
  }
}

const rootReducer = (state: any, action: IActionType) => {
  if (action.type === actionTypes.UNAUTHENTICATE) {
    state = undefined
  }
  return appReducers(state, action)
}

const logger = (store: any) => {
  return (next: any) => {
    return (action: any) => {
      log("[MIDDLEWARE] Dispatching", action)
      const result = next(action)
      log("[MIDDLEWARE] Next State", store.getState())
      return result
    }
  }
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)))

export default store
