import React from "react"
import { Typography, Link } from "@material-ui/core"

interface Props {}
const Copyrights: React.FC<Props> = props => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.innoviadigital.com/" target="_blank">
        Innovia Labs
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  )
}

export default Copyrights
