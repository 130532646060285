import React from "react"
import { Dispatch } from "redux"
import { connect, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import Container from "../Container"
import MenuItem from "@material-ui/core/MenuItem"
import SideIcon from "@material-ui/icons/KeyboardArrowRight"

import useStyles from "../styles"
import { IActionType } from "../../../../types"
import { ICompany } from "../../../../types/company"
import { applicantsActions } from "../../../../store/actions/applicants"
import { IApplicantFilters, IPaginatedApplicants } from "../../../../types/applicants"

import JobsFilter from "./JobsFilter"
import MoreFilters from "./MoreFilters"
// import StageFilter from "./StageFilter"
import RatingsFilter from "./RatingsFilter"
// import LocationFilter from "./LocationFilter"
import WorkPermitFilter from "./WorkPermitFilter"
import EducationLevelFilter from "./EducationLevelFilter"

import FieldsOfStudyFiltersSelectAll from "./FieldsOfStudyFiltersSelectAll"
import { applicationAge, driverLicenceCategories } from "../../../../utils/filters"
import StageEnumFilter from "./StageEnumFilter"
import FolderFilter from "./FolderFilter"
import ability from "../../../../utils/AccessControl/ability"
import AccessDeniedTooltip from "../../../AccessDeniedToolTip"
import PlacesFilter from "./PlaceFilter"

interface Props {
  company: ICompany
  searchOpen: boolean
  activeFilter: boolean
  filters: IApplicantFilters
  exports: IPaginatedApplicants
  applicants: IPaginatedApplicants
  handleClose: () => void
  applyFilters: (filters: any, page: number, orderBy: string | null, orderDirection: string | null) => void
}

const MobileFilters: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [openFilters, setOpenFilters] = React.useState<string | null>()

  const orderBy = useSelector((state: any) => state.applicantsReducer.orderBy)
  const orderDirection = useSelector((state: any) => state.applicantsReducer.orderDirection)

  const handleOptionSelect = (option: string) => {
    setOpenFilters(option)
  }

  const rules = ability.rules

  React.useEffect(() => {}, [rules])

  const applyFilters = async (filters: any, page: number) => {
    await props.applyFilters(filters, page, orderBy, orderDirection)
    props.handleClose()
  }

  const handleGoBack = () => {
    setOpenFilters(null)
  }

  const selectFilterGroup = () => {
    switch (openFilters) {
      case "rating": {
        return (
          <RatingsFilter
            company={props.company}
            filters={props.filters}
            applicants={props.exports}
            activeFilter={props.activeFilter}
            applyFilters={applyFilters}
            handleGoBack={handleGoBack}
          />
        )
      }
      // case "stage": {
      //   return (
      //     <StageFilter
      //       company={props.company}
      //       filters={props.filters}
      //       applicants={props.exports}
      //       activeFilter={props.activeFilter}
      //       applyFilters={applyFilters}
      //       handleGoBack={handleGoBack}
      //     />
      //   )
      // }
      case "stage_enum": {
        return (
          <StageEnumFilter
            company={props.company}
            filters={props.filters}
            applicants={props.exports}
            activeFilter={props.activeFilter}
            applyFilters={applyFilters}
            handleGoBack={handleGoBack}
          />
        )
      }
      // case "city": {
      //   return (
      //     <LocationFilter
      //       company={props.company}
      //       filters={props.filters}
      //       applicants={props.exports}
      //       activeFilter={props.activeFilter}
      //       applyFilters={applyFilters}
      //       handleGoBack={handleGoBack}
      //     />
      //   )
      // }
      case "place": {
        return (
          <PlacesFilter
            company={props.company}
            filters={props.filters}
            activeFilter={props.activeFilter}
            applyFilters={applyFilters}
            handleGoBack={handleGoBack}
          />
        )
      }
      case "education_level": {
        return (
          <EducationLevelFilter
            company={props.company}
            filters={props.filters}
            applicants={props.exports}
            activeFilter={props.activeFilter}
            applyFilters={applyFilters}
            handleGoBack={handleGoBack}
          />
        )
      }
      case "work_permit": {
        return (
          <WorkPermitFilter
            company={props.company}
            filters={props.filters}
            applicants={props.exports}
            activeFilter={props.activeFilter}
            applyFilters={applyFilters}
            handleGoBack={handleGoBack}
          />
        )
      }
      case "job": {
        return (
          <JobsFilter
            company={props.company}
            filters={props.filters}
            applicants={props.exports}
            activeFilter={props.activeFilter}
            applyFilters={applyFilters}
            handleGoBack={handleGoBack}
          />
        )
      }
      case "field_of_study": {
        return (
          // <FieldsOfStudyFilters
          //   applyFilters={applyFilters}
          //   handleGoBack={handleGoBack}
          // />
          <FieldsOfStudyFiltersSelectAll applyFilters={applyFilters} handleGoBack={handleGoBack} />
          // <FieldsOfStudyGroupedFilters
          //   applyFilters={applyFilters}
          //   handleGoBack={handleGoBack}
          // />
        )
      }
      case "folder": {
        return (
          // <FieldsOfStudyFilters
          //   applyFilters={applyFilters}
          //   handleGoBack={handleGoBack}
          // />
          <FolderFilter applyFilters={applyFilters} handleGoBack={handleGoBack} />
          // <FieldsOfStudyGroupedFilters
          //   applyFilters={applyFilters}
          //   handleGoBack={handleGoBack}
          // />
        )
      }
      case "more": {
        return (
          <MoreFilters
            company={props.company}
            filters={props.filters}
            applicants={props.exports}
            activeFilter={props.activeFilter}
            applyFilters={applyFilters}
            handleGoBack={handleGoBack}
            options={[
              {
                value: "gender",
                label: t("gender"),
                filters: [
                  { value: "male", label: t("male"), quantity: 0, field: "gender" },
                  { value: "female", label: t("female"), quantity: 0, field: "gender" }
                ]
              },
              {
                value: "driver_licence",
                label: t("driver_licence"),
                filters: driverLicenceCategories.map(option => ({ value: option, label: t(`driver_licence_category_id_${option}`), quantity: 0, field: "driver_licence" }))
              },
              {
                value: "age",
                label: t("application_age_filter_label"),
                isMultiSelect: false,
                filters: applicationAge.map(option => ({ value: option, label: t(`application_age_${option}`), quantity: 0, field: "age" }))
              },
            ]}
          />
        )
      }
      default:
        return (
          <React.Fragment>
            {["rating", "stage_enum", "job", "place", "field_of_study", ability.can("use-ats-system", "all") ? "folder" : "folder_disabled", /* "city", */ "education_level", "work_permit", "more"].map(option => {
              if(option.includes("disabled")){
                return (
                  <AccessDeniedTooltip link={"/billing/subscriptions"} linkLabel={t("go_to_subscriptions")} body={t("billing_cannot_send-message-to-applicants")}>
                    <MenuItem key={option} className={classes.menuItem} disabled>
                      {t(`${option}_filter_label`)}
                      <SideIcon />
                    </MenuItem>
                  </AccessDeniedTooltip>
                )
              }
              return (
                <MenuItem
                  key={option}
                  value={option}
                  className={classes.menuItem}
                  onClick={() => {
                    handleOptionSelect(option)
                  }}
                >
                  {t(`${option}_filter_label`)}
                  <SideIcon />
                </MenuItem>
              )
            })}
          </React.Fragment>
        )
    }
  }

  return (
    <Container open header={t("filters")} handleClose={props.handleClose}>
      <div>{selectFilterGroup()}</div>
    </Container>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company,
    exports: state.applicantsReducer.exports,
    filters: state.applicantsReducer.filters,
    searchOpen: state.applicantsReducer.searchOpen,
    applicants: state.applicantsReducer.applicants,
    activeFilter: state.applicantsReducer.activeFilter
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    changeSearchOpen: () => dispatch(applicantsActions.changeSearchOpen()),
    applyFilters: async (filters: any, page: number, orderBy: string | null, orderDirection: string | null) =>
      dispatch(await applicantsActions.applyFilters(filters, page, orderBy, orderDirection))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileFilters)
