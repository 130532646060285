/* eslint-disable react/prop-types */
import React from "react"
import { connect, useDispatch, useSelector } from "react-redux"

import { useTranslation } from "react-i18next"

import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import DropdownArrow from "@material-ui/icons/ArrowDropDown"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"

import { List as VirtualizedList, AutoSizer, CellMeasurerCache, CellMeasurer } from "react-virtualized"

import { IApplicantFilters } from "../../../../types/applicants"
import { ICompany } from "../../../../types/company"
import { Dispatch } from "redux"
import { IActionType } from "../../../../types"
import { applicantsActions } from "../../../../store/actions/applicants"

import useStyles from "./styles"
import { CircularProgress } from "@material-ui/core"
import AddNewFolder from "../../ApplicantDetails/CandidateFolders/AddNewFolder"
import Can from "../../../../utils/AccessControl/Can"
import AccessDeniedTooltip from "../../../AccessDeniedToolTip"

interface Props {
  company: ICompany
  activeFilter: boolean
  filters: IApplicantFilters
  fetchFieldsOfStudy: () => void
  applyFilters: (values: any, page: any) => void
}

const cache = new CellMeasurerCache({
  defaultHeight: 56,
  defaultWidth: 350,
  fixedWidth: true
})

const FolderFilter: React.FC<Props> = props => {
  const [open, setOpen] = React.useState(false)
  const [folderFilters, setFolderFilters] = React.useState(props.filters.folder ? [...props.filters.folder] : [])
  const classes = useStyles()
  
  const company = useSelector((state: any) => state.companyReducer.company)
  const folders = useSelector((state: any) => state.applicantsReducer.folders)

  const { t } = useTranslation()

  const dispatch = useDispatch()
  
  React.useEffect(() => {
    const fetchFolders = async () => {
      dispatch(await applicantsActions.fetchCompanyFolders(company?.id))
    }
    if (!folders) {
      fetchFolders()
    }
  }, [folders, company?.id, dispatch])

  const handleClick = () => {
    setFolderFilters(props.filters.folder ? [...props.filters.folder] : [])
    setOpen(prev => !prev)
  }

  const handleClickAway = () => {
    setOpen(false)
    setFolderFilters(props.filters.folder ? [...props.filters.folder] : [])
  }

  const handleFolderClick = (value: any) => {
    const ndx = folderFilters.findIndex(item => value === item)
    if (ndx === -1) {
      let newArray = [...folderFilters]
      newArray.push(value)
      setFolderFilters(newArray)
    } else {
      let newArray = [...folderFilters]
      newArray.splice(ndx, 1)
      setFolderFilters(newArray)
      cache.clearAll()
    }
  }

  const checkChecked = (value: any) => {
    let result = false
    folderFilters.forEach(element => {
      if (value === element) {
        result = true
        return true
      }
    })
    return result
  }

  const handleApplyButtonClick = async () => {
    await props.applyFilters(
      {
        ...props.filters,
        activeFilter: props.activeFilter,
        companyId: props.company.id,
        folder: folderFilters,
      },
      1
    )
    setOpen(false)
  }

  const rowRenderer = ({ index, key, style, parent }: { index: any; key: any; style: any; parent: any }) => {
    const folder: any = folders ? folders[index] : {}
    const labelId = `checkbox-list-label-${folder.id}`
    return (
      <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style} key={key}>
          <ListItem
            key={folder.id}
            role={undefined}
            dense
            button
            onClick={() => {
              handleFolderClick(folder.id)
            }}
          >
            <ListItemIcon>
              <Checkbox edge="start" checked={checkChecked(folder.id)} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": labelId }} />
            </ListItemIcon>
            <ListItemText id={labelId} primary={`${folder.title}`} />
          </ListItem>
        </div>
      </CellMeasurer>
    )
  }

  return (
    <div className={classes.root + " va-middle"}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classes.singleFilterDiv}>
          <Can I="use-ats-system" a="all">
            <Button onClick={handleClick} variant="outlined" size="small" color="default">
              {props.filters.folder
                ? props.filters.folder.length > 0
                  ? `${t("folders")}(${props.filters.folder.length})`
                  : `${t("folders")}`
                : `${t("folders")}`}
              <DropdownArrow />
            </Button>
          </Can>
          <Can not I="use-ats-system" a="all">
            <AccessDeniedTooltip link="/features/ats" linkLabel={t("go_to_ats_system_landing_pages")} body={t("billing_cannot_use_ats_system")}>
              <Button disabled variant="outlined" size="small" color="default">{t("folders")} <DropdownArrow /></Button>
            </AccessDeniedTooltip>
          </Can>
          {open ? (
            <Paper className={classes.paper} elevation={3}>
              <div style={{ height: "289px", width: "350px" }}>
                {!!folders ? (
                  folders.length > 0 ? (
                    <AutoSizer>
                      {({ width, height }) => {
                        return (
                          <VirtualizedList
                            width={width}
                            height={height}
                            rowHeight={cache.rowHeight}
                            rowCount={folders.length}
                            rowRenderer={rowRenderer}
                            overscanRowCount={5}
                            deferredMeasurementCache={cache}
                          />
                        )
                      }}
                    </AutoSizer>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center", flexDirection: "column", marginTop: "1rem" }}>
                      <Typography variant="body1" style={{ color: "#979797" }}>
                        {t("no_folders_search_results")}
                      </Typography>
                    </div>
                  )
                ) : (
                  <div style={{display: "flex", justifyContent: "center", width: "100%"}}><CircularProgress /></div>
                )}
              </div>
              <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                <AddNewFolder />
              </div>
              <div className={classes.buttonArea}>
                <Button color="primary" variant="outlined" onClick={handleClickAway}>
                  {t("cancel")}
                </Button>
                <Button color="primary" variant="contained" onClick={handleApplyButtonClick}>
                  {t("apply")}
                </Button>
              </div>
            </Paper>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    activeFilter: state.applicantsReducer.activeFilter,
    filters: state.applicantsReducer.filters,
    company: state.companyReducer.company,
    fieldsOfStudy: state.applicantsReducer.fieldsOfStudy
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchFieldsOfStudyGroups: async () => dispatch(await applicantsActions.fetchStudyGroups()),
    fetchFieldsOfStudy: async () => dispatch(await applicantsActions.fetchFieldsOfStudy())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderFilter)
