import React, { useState } from "react"
import { AxiosError } from "axios"
import axios from "../../../api/iskibris/iskibris"

import Link from "@material-ui/core/Link"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import InputAdornment from "@material-ui/core/InputAdornment"
import CircularProgress from "@material-ui/core/CircularProgress"

import Cookies from "js-cookie"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory, useRouteMatch } from "react-router"

import useStyles from "../styles"
import Greetings from "../Greetings"
import Copyright from "../../Copyright"
import GoogleOauth from "../GoogleAuth"
import FacebookAuth from "../FacebookAuth"
import LanguageSelector from "../LanguageSelector"
import CustomDivider from "../CustomDivider"

interface Props {
  onSuccess: () => void
  onLoginNavigationClick?: () => void
  onForgotPasswordNavigationClick?: () => void
}

const Signup: React.FC<Props> = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue
  } = useForm()

  const match: {
    params: {
      referrer: any
    }
  } = useRouteMatch()

  const [values, setValues] = useState({
    email: "",
    password: ""
  })

  const initErrors = {
    phone_number: [],
    email: [],
    password: [],
    name: []
  }

  const [errorValues, setErrorValues] = useState({
    ...initErrors
  })

  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const setValuesHandler = (name: any) => (event: any) => {
    setValues({ ...values, [name]: event.target.value })
    setValue(name, event.target.value)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  const registerHandler = async () => {
    setErrorValues({ ...initErrors })

    setLoading(true)

    const data = {
      ...values,
      referrer: match.params.referrer ? match.params.referrer : null
    }

    await axios
      .post("/api/register", data)
      .then(async response => {
        Cookies.set("auth_token", response.data["success"]["token"], {
          domain: process.env.REACT_APP_DOMAIN || ".iskibris.com",
          sameSite: "none",
          secure: true
        })
        setLoading(false)
        props.onSuccess()
      })
      .catch((error: AxiosError) => {
        const errors = error?.response?.data?.errors
        const keys = Object.keys(errors)
        keys.map(item => {
          setError(item, { message: errors[item][0], type: "validate" })
          return item
        })
        setErrorValues({
          ...errorValues,
          ...errors
        })
        setLoading(false)
      })
  }

  React.useEffect(() => {
    register("name", {
      required: `${t("fullname_required")}`,
      maxLength: {
        value: 91,
        message: `${t("fullname_maxLength")}`
      },
      minLength: {
        value: 1,
        message: `${t("fullname_minLength")}`
      }
    })
    register("mobile_phone_number", {
      required: `${t("phone_number_required")}`,
      maxLength: {
        value: 15,
        message: `${t("phone_number_maxLength")}`
      },
      minLength: {
        value: 11,
        message: `${t("phone_number_minLength")}`
      },
      pattern: {
        value: /^(\+|\d)[0-9]{7,16}$/,
        message: `${t("phone_number_invalid")}`
      }
    })

    register("email", {
      required: `${t("email_required")}`,
      maxLength: {
        value: 91,
        message: `${t("email_maxLength")}`
      },
      minLength: {
        value: 1,
        message: `${t("email_minLength")}`
      },
      pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: `${t("email_invalid")}`
      }
    })
    register("password", {
      required: `${t("password_required")}`,
      maxLength: {
        value: 91,
        message: `${t("password_maxLength")}`
      },
      minLength: {
        value: 4,
        message: `${t("password_minLength")}`
      }
    })
  }, [register, t])

  return (
    <div className={classes.root}>
      <Greetings />
      <LanguageSelector />
      <div style={{ marginTop: "20px" }}>
        <FacebookAuth
          mode="signup"
          onSuccess={() => {
            console.log("success")
          }}
          onFail={() => {
            console.log("fail")
          }}
        />
        <GoogleOauth
          mode="signup"
          onSuccess={() => {
            console.log("success")
          }}
          onFail={() => {
            console.log("fail")
          }}
        />
      </div>
      <CustomDivider />
      <div>
        <form className={classes.form} onSubmit={handleSubmit(registerHandler)}>
          <TextField
            data-private="lipsum"
            variant="outlined"
            margin="dense"
            required
            fullWidth
            id="name"
            label={t("name")}
            name="name"
            autoFocus
            onChange={setValuesHandler("name")}
            error={!!errors.name}
            helperText={!!errors.name ? errors.name.message?.toString() : ""}
          />
          <TextField
            data-private="lipsum"
            variant="outlined"
            margin="dense"
            required
            fullWidth
            id="email"
            label={t("email_address")}
            name="email"
            onChange={setValuesHandler("email")}
            error={!!errors.email}
            helperText={!!errors.email ? errors.email.message?.toString() : null}
          />
          <TextField
            data-private="lipsum"
            variant="outlined"
            margin="dense"
            required
            fullWidth
            id="mobile_phone_number"
            label={t("phone_number")}
            name="mobile_phone_number"
            onChange={setValuesHandler("mobile_phone_number")}
            error={!!errors.phone_number}
            helperText={!!errors.mobile_phone_number ? errors.mobile_phone_number.message?.toString() : null}
          />
          <TextField
            data-private="lipsum"
            variant="outlined"
            margin="dense"
            required
            fullWidth
            name="password"
            label={t("password")}
            id="password"
            autoComplete="new-password"
            onChange={setValuesHandler("password")}
            value={values.password}
            type={showPassword ? "text" : "password"}
            error={!!errors.password}
            helperText={!!errors.password ? errors.password.message?.toString() : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button type="submit" fullWidth variant="contained" color="secondary" className={classes.submit}>
            {loading ? <CircularProgress size="30px" style={{ color: "white" }} /> : t("sign_up")}
          </Button>
        </form>
        <Grid container>
          <Grid item xs={12} sm={6} className={classes.extraActionButtonGrid}>
            <Link
              href="#"
              onClick={
                props.onForgotPasswordNavigationClick
                  ? props.onForgotPasswordNavigationClick
                  : (event: any) => {
                      event.preventDefault()
                      history.push("/forgot-password", history.location.state)
                    }
              }
            >
              {t("forgot_password")}?
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.extraActionButtonGrid}>
            <Link
              href="#"
              variant="body2"
              onClick={
                props.onLoginNavigationClick
                  ? props.onLoginNavigationClick
                  : (event: any) => {
                      event.preventDefault()
                      history.push("/login", history.location.state)
                    }
              }
            >
              {t("back_to_signin")}
            </Link>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: "30px" }}>
        <Copyright />
      </div>
    </div>
  )
}

export default Signup
