import React from "react"

import UploadIcon from "@material-ui/icons/MergeTypeOutlined"

import { useTranslation } from "react-i18next"
import { Alert } from "@material-ui/lab"

interface Props { }

const ManualApplicationIndidator: React.FC<Props> = props => {
  const { t } = useTranslation()

  return (
    <Alert variant="standard" color="info" icon={<UploadIcon />} style={{padding: "0px 16px"}}>
        {t("manual_application_indidator_text")}
    </Alert>
  )
}

export default ManualApplicationIndidator
