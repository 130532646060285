import React, { useEffect, useState } from "react"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"

import useStyles from "./styles"
import axios from "../../../api/iskibris/iskibris"

import { IActionType } from "../../../types"
import { ICompany } from "../../../types/company"

import { billingActions } from "../../../store/actions/billing"
import { IInvoice, IMalipoInvoice, ISubscription, ITransaction } from "../../../types/billing"

import NotificationSnackbar from "../../NotificationSnackbar"
import BillingSettings from "../Subscription/BillingSettings"

interface Props {
  company: ICompany
  invoices: IInvoice[]
  unregistered: boolean
  transactions: ITransaction[]
  subscriptions: ISubscription[]
  clientInvoices: IMalipoInvoice[]
  fetchCompanyInvoices: (id: any) => void
  fetchCompanyTransactions: (id: any) => void
  fetchCompanySubscriptions: (id: any) => void
  fetchCompanyClientInvoices: (id: any) => void
}

const BillingTab: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false)
  const [activationResult, setActivationResult] = useState("")
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    company,
    unregistered,
    subscriptions,
    clientInvoices,
    fetchCompanySubscriptions,
    fetchCompanyClientInvoices
  } = props

  useEffect(() => {
    if (!subscriptions && company) {
      fetchCompanySubscriptions(company.id)
    }
  }, [company, subscriptions, fetchCompanySubscriptions])

  useEffect(() => {
    if (!clientInvoices && company) {
      fetchCompanyClientInvoices(company.id)
    }
  }, [clientInvoices, company, fetchCompanyClientInvoices])

  const fetchBillingInformation = async () => {
    await fetchCompanySubscriptions(company.id)
    fetchCompanyClientInvoices(company.id)
  }

  const handleActivateBilling = async () => {
    setLoading(true)
    await axios
      .post(`/hiring/api/companies/${company.id}/billing`)
      .then(async response => {
        await fetchBillingInformation()
        setLoading(false)
        setActivationResult("success")
      })
      .catch(err => {
        setLoading(false)
        setActivationResult("fail")
      })
  }

  return (
    <div>
      {clientInvoices ? (
        <Grid container>
          <Grid item lg={9} md={12} sm={12} style={{ width: "100%" }}>
            <BillingSettings />
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>
          {unregistered ? (
            <Paper className={classes.paper}>
              <div
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Typography variant="body1" style={{ textAlign: "center", marginBottom: "15px" }}>
                  {t("no_billing_client")}
                </Typography>
                <Button onClick={handleActivateBilling} color="secondary" variant="outlined">
                  {loading ? <CircularProgress color="secondary" size={22} /> : t("activate_billing")}
                </Button>
              </div>
            </Paper>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                maxHeight: "50vh"
              }}
            >
              <CircularProgress size={35} />
            </div>
          )}
        </React.Fragment>
      )}

      <NotificationSnackbar
        status={activationResult}
        success_msg={t("billing_activation_success")}
        error_msg={t("billing_activation_fail")}
        handleClose={() => {
          setActivationResult("")
        }}
      />
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    clientInvoices: state.billingReducer.clientInvoices,
    subscriptions: state.billingReducer.subscriptions,
    transactions: state.billingReducer.transactions,
    unregistered: state.billingReducer.unregistered,
    invoices: state.billingReducer.invoices,
    company: state.companyReducer.company
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchCompanyClientInvoices: async (id: any) => dispatch(await billingActions.fetchCompanyClientInvoices(id)),
    fetchCompanySubscriptions: async (id: any) => dispatch(await billingActions.fetchCompanySubscriptions(id)),
    fetchCompanyTransactions: async (id: any) => dispatch(await billingActions.fetchCompanyTransactions(id)),
    fetchCompanyInvoices: async (id: any) => dispatch(await billingActions.fetchCompanyInvoices(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingTab)
