import React from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

import useStyles from "../styles"
import LongMenu from "../../../LongMenu"
import { ICompany, ICompanyFAQ } from "../../../../types/company"
import { companyActions } from "../../../../store/actions/company"
import FaqForm from "../FaqForm"

interface Props {
  company: ICompany
  faq: ICompanyFAQ
}

const SingleFaq: React.FC<Props> = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [updateStatus, setUpdateStatus] = React.useState(false)

  const classes = useStyles()

  const handleDelete = async () => {
    await dispatch(await companyActions.deleteCompanyFaq(props.faq.id))
  }

  return (
    <div className={classes.companyListItem}>
      <ListItem style={{ padding: "0px" }}>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography variant="h6">{props.faq.question_tr || props.faq.question_en}</Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography variant="body1" component={"span"} style={{ display: "block" }}>
                {props.faq.answer_tr}
              </Typography>
              <Typography variant="body1" component={"span"} style={{ display: "block" }}>
                {props.faq.answer_en}
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          <LongMenu
            options={[
              {
                title: t("edit"),
                handler: () => {
                  setUpdateStatus(true)
                }
              },
              { title: t("delete"), handler: handleDelete }
            ]}
          />
        </ListItemSecondaryAction>
      </ListItem>
      {updateStatus ? (
        <React.Fragment>
          <Divider />
          <FaqForm
            handleEditCancel={() => {
              setUpdateStatus(false)
            }}
            faq={props.faq}
          />
        </React.Fragment>
      ) : null}
    </div>
  )
}

export default SingleFaq
