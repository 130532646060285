import React, { useState } from "react"
import imageFile2Base64 from "../../../../../utils/imageFile2Base64"
import { ICompany } from "../../../../../types/company"
import { Button, Typography, Slider, CircularProgress } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import Cropper from "react-easy-crop"
import getCroppedImg from "../../../../../utils/cropImage"
import axios from "../../../../../api/iskibris"
import NotificationSnackbar from "../../../../NotificationSnackbar"
import useStyles from "../styles"
import imageCompression from "browser-image-compression"

interface Props {
  newCoverPhoto?: any
  company: ICompany
  handleCoverPhotoModalClose: () => void
  handleUpdateCompanyCoverPhoto?: (coverPhotoUrl: string) => void
}

const CoverPhotoEditor: React.FC<Props> = props => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [image, setImage] = useState<any>(props.newCoverPhoto ? props.newCoverPhoto : props.company?.cover_photo_url)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [aspect, setAspect] = useState(4 / 1)
  const [croppedImage, setCroppedImage] = useState<any>(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState("")

  const resetValues = {
    crop: { x: 0, y: 0 },
    zoom: 1,
    rotation: 0
  }

  const zoomMarks = [
    {
      value: 0.5,
      label: "0.5x"
    },
    {
      value: 1,
      label: "1x"
    },
    {
      value: 2,
      label: "2x"
    },
    {
      value: 3,
      label: "3x"
    }
  ]

  const valueText = (value: any) => {
    return `${value}°`
  }

  const handleNewImage = (e: any) => {
    const file = e.target.files[0]
    if (file) {
      imageFile2Base64(file).then(response => {
        setImage(response)
      })
    }
  }

  const onCropChange = (crop: any) => {
    setCrop(crop)
  }

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const onZoomChange = (zoom: any) => {
    setZoom(zoom)
  }

  const handleSaveLogo = async () => {
    setLoading(true)

    const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation)

    setCroppedImage(croppedImage)

    var formData = new FormData()

    setAspect(4 / 1)

    let blob = await fetch(croppedImage).then(r => r.blob())

    let compressedFile: any = blob // Set the default image to be the cropped blob

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }

    try {
      const imageFile = new File([blob], "cover_photo.png", { type: "image/png" })
      compressedFile = await imageCompression(imageFile, options)
    } catch (error) {
      console.log("Image Compression Error: ", error)
    }

    formData.append("cover_photo_url", compressedFile)

    await axios
      .post(`/api/hiring/v2/companies/${props.company.id}/cover-photo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json"
        }
      })
      .then(response => {
        if (response.status === 200) {
          if (props.handleUpdateCompanyCoverPhoto) {
            props.handleUpdateCompanyCoverPhoto(response.data.cover_photo_url)
          }
          setResult("success")
        }
      })
      .catch(err => {
        setResult("false")
      })

    setLoading(false)

    setTimeout(() => {
      props.handleCoverPhotoModalClose()
    }, 2000)
  }

  const resetState = () => {
    console.log("Cropped Image: ", croppedImage)
    setZoom(resetValues.zoom)
    setRotation(resetValues.rotation)
    setCrop(resetValues.crop)
  }

  return (
    <div>
      <div className={classes.cropperDiv}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={aspect}
          restrictPosition={false}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
          // crossOrigin="anonymous"
        />
      </div>
      <div style={{ textAlign: "center", marginBottom: 8 }}>
        <input accept="image/*" id="upload-new-cover-photo-file" multiple type="file" name="newImage" onChange={handleNewImage} style={{ display: "none" }} />
        <label htmlFor="upload-new-cover-photo-file" style={{ marginRight: 8 }}>
          <Button variant="contained" color="secondary" component="span">
            {t("upload_new_cover_photo")}
          </Button>
        </label>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            resetState()
          }}
        >
          {t("reset")}
        </Button>
      </div>

      <Typography id="zoom-slider" gutterBottom>
        {t("zoom")}
      </Typography>
      <Slider
        aria-labelledby="zoom-slider"
        value={zoom}
        min={0.5}
        max={3}
        step={0.1}
        getAriaValueText={valueText}
        marks={zoomMarks}
        onChange={(e, zoom) => onZoomChange(zoom)}
        valueLabelDisplay="auto"
      />
      <div>
        <Button variant="contained" color="primary" style={{ float: "right" }} onClick={handleSaveLogo}>
          {loading ? <CircularProgress style={{ color: "#ffffff" }} size={22} /> : t("save")}
        </Button>
        <Button variant="outlined" color="primary" style={{ float: "right", marginRight: 8 }} onClick={props.handleCoverPhotoModalClose}>
          {t("cancel")}
        </Button>
      </div>
      <NotificationSnackbar
        status={result}
        success_msg={t("cover_photo_upload_success")}
        error_msg={t("cover_photo_upload_fail")}
        handleClose={() => {
          setResult("")
        }}
      />
    </div>
  )
}

export default CoverPhotoEditor
