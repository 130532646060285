import React from "react"
import { useSelector } from "react-redux"
import CircularProgress from "@material-ui/core/CircularProgress"

import { Route, Redirect } from "react-router-dom"

interface Props {
  path?: string
  exact?: boolean
  component: React.FC
  isAuthenticated: boolean
  redirectUrl?: string
}

const AuthProtectedRoute: React.FC<Props> = ({ component, isAuthenticated, ...rest }: any) => {
  const companies: any = useSelector((state: any) => state.companyReducer.companies)
  const abilities: any = useSelector((state: any) => state.permissionsReducer.abilities)

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (isAuthenticated) {
          if (!companies) {
            return (
              <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
              </div>
            )
          } else {
            if (companies.length === 0) {
              return (
                <Redirect
                  to={{
                    pathname: rest.redirectUrl ?? "/employers/signup",
                    state: { from: props.location }
                  }}
                />
              )
            } else if (!abilities) {
              return (
                <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", color: "pink" }}>
                  <CircularProgress />
                </div>
              )
            }
          }
          return React.createElement(component, props)
        }
        return (
          <Redirect
            to={{
              pathname: rest.redirectUrl ?? "/login",
              state: { from: props.location }
            }}
          />
        )
      }}
      exact
    />
  )
}

export default AuthProtectedRoute
