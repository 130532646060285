const useGtm = () => {
  const ourWindow: any = window

  const tagEvent = ({ event, data }: { event?: string; data?: any }) => {
    ourWindow.dataLayer.push({
      event: event,
      params: data
    })
  }

  return { tagEvent: tagEvent }
}

export default useGtm
