import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import IconButton from "@material-ui/core/IconButton"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"

import useStyles from "./styles"

import { IMalipoInvoice } from "../../../../../types/billing"
import { Typography } from "@material-ui/core"

import BillingSettingsForm from "../../BillingSettings/BillingSettingsForm"

interface Props {
  open: boolean
  clientInvoice: IMalipoInvoice
  handleClose: () => void
}

const InvoiceRequestForm: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const componentRef: any = React.useRef()
  const { company } = useSelector((state: any) => state.companyReducer)

  const { clientInvoice } = props

  const handleClose = () => {
    props.handleClose()
  }

  return (
    <div>
      <Modal
        closeAfterTransition
        open={props.open}
        onClose={handleClose}
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Fade in={props.open}>
          <div className={classes.modalPaper}>
            <div className={classes.modalCloseContainer}>
              <Typography variant="h6">{t("request_invoice")}</Typography>
              <IconButton onClick={handleClose} className={classes.iconButton}>
                <CloseRoundedIcon />
              </IconButton>
            </div>
            <div className={classes.mainContentContainer} ref={componentRef}>
              <BillingSettingsForm
                company={company}
                type="invoice-request"
                onSuccess={handleClose}
                invoice_request_information={{
                  company_id: company?.id,
                  invoice_type: "physical",
                  payment_invoice_uuid: clientInvoice?.uuid,
                  customer_uuid: company?.billing_system_client_id
                }}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default InvoiceRequestForm
