import React from "react"
import { useTranslation } from "react-i18next"

import { IApplicantFilters, IApplicantSearchParams, IPaginatedApplicants } from "../../../../types/applicants"
import { ICompany } from "../../../../types/company"
import GenericFilter from "../GenericFilter"

import useStyles from "../styles"
import { applicantStages } from "../../../../utils/data/enums"

interface Props {
  company: ICompany
  activeFilter: boolean
  filters: IApplicantFilters
  applicants: IPaginatedApplicants
  applyFilters: (filters: IApplicantSearchParams, page: number) => void
}

const options = applicantStages.map(item => (
  { value: item, label: "", quantity: 0 }
))

const StageEnumFilter: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  React.useEffect(() => {
    options.forEach(item => {
      item.label = t(`stage_id_enum_${item.value}`)
      if (props.applicants) {
        item.quantity = [...props.applicants.data].filter(applicant => applicant.stage_id_enum === item.value).length
      }
    })
  }, [props.applicants, t])

  const handleSubmit = async (filters: any) => {
    await props.applyFilters(
      {
        ...filters,
        activeFilter: props.activeFilter,
        companyId: props.company.id,
      },
      1
    )
  }

  return (
    <div className={classes.singleFilterDiv}>
      <GenericFilter field="stage_enum" options={options} filters={props.filters} applicants={props.applicants} handleApplyClick={handleSubmit} />
    </div>
  )
}

export default StageEnumFilter
