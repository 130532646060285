import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    padding: "5px",
    borderBottom: "1px solid #aeaeae",
    backgroundColor: "#e6f8ff"
  },
  textfield: {
    background: "#fff",
    margin: "0px",
    width: "100%"
  },
  inputBase: {
    borderRadius: "25px"
  }
}))

export default useStyles
