import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"

import { NavLink, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { connect, useDispatch, useSelector } from "react-redux"

import List from "@material-ui/core/List"
import Avatar from "@material-ui/core/Avatar"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import OpenSideIcon from "@material-ui/icons/KeyboardArrowLeft"

import IconButton from "@material-ui/core/IconButton"

import axios from "../../../api/iskibris/iskibris"
import Cookies from "js-cookie"
import { ICompany } from "../../../types/company"
import { systemActions } from "../../../store/actions/system"
import { authActions } from "../../../store/actions/authentication"

const useStyles = makeStyles(theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  paper: {
    top: "50px"
  },
  openDrawerButton: {
    color: "#fff",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  small: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(3),
      height: theme.spacing(3)
    }
  }
}))

interface Props {
  company: ICompany
  unauthenticate: () => void
}

type Anchor = "top" | "left" | "bottom" | "right"

const UserSwipeableSideMenu: React.FC<Props> = props => {
  const [state, setState] = React.useState({ right: false })

  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const user = useSelector((state: any) => state.userReducer.profile)

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const handleDrawerItemClick = () => {
    toggleDrawer("right", false)
  }

  const handleCompanyClick = () => {
    dispatch(systemActions.openCompanyMenu())
  }

  const handleLanguageClick = () => {
    dispatch(systemActions.openLanguageMenu())
  }

  const logout = async () => {
    props.unauthenticate()
    await axios.post("/api/logout")
    localStorage.removeItem("auth_token")
    localStorage.removeItem("refresh_token")
    localStorage.removeItem("current_company_id")
    localStorage.removeItem("CompanyToken")
    Cookies.remove("auth_token", { domain: process.env.REACT_APP_DOMAIN ?? ".iskibris.com" })
    Cookies.remove("CompanyToken")
    setTimeout(function () {
      history.push("/index")
    }, 500)
  }

  const NavLinkWithForwardRef = React.forwardRef((props: any, ref: any) => <NavLink innerRef={ref} {...props} exact />)
  NavLinkWithForwardRef.displayName = "NavLinkWithForwardRef"

  return (
    <div>
      <IconButton className={classes.openDrawerButton} onClick={toggleDrawer("right", true)}>
        {user ? <Avatar className={classes.small} src={user.avatar_url} sizes="small" alt="" /> : null}
      </IconButton>
      <SwipeableDrawer anchor={"right"} open={state["right"]} PaperProps={{ className: classes.paper }} onClose={toggleDrawer("right", false)} onOpen={toggleDrawer("right", true)}>
        <div className={clsx(classes.list, {})} role="presentation" onClick={toggleDrawer("right", false)} onKeyDown={toggleDrawer("right", false)}>
          <List>
            <ListItem className={"sidebar__link"} onClick={handleCompanyClick} key="company">
              <ListItemIcon>
                <OpenSideIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" className={"sidebar__link-text"}>
                    {props.company.name}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            <Divider />
            <ListItem className={"sidebar__link"} onClick={handleLanguageClick} key="language">
              <ListItemIcon>
                <OpenSideIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" className={"sidebar__link-text"}>
                    {t("language")}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            <Divider />
            <ListItem className={"sidebar__link"} onClick={handleDrawerItemClick} key="subscriptions" component={NavLinkWithForwardRef} to="/billing/subscriptions">
              <ListItemIcon></ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" className={"sidebar__link-text"}>
                    {t("subscriptions")}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            <Divider />
            <ListItem className={"sidebar__link"} onClick={handleDrawerItemClick} key="packages" component={NavLinkWithForwardRef} to="/billing/pricing">
              <ListItemIcon></ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" className={"sidebar__link-text"}>
                    {t("buy_new_package")}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            <Divider />
            <ListItem className={"sidebar__link"} onClick={logout} key="logout">
              <ListItemIcon></ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" className={"sidebar__link-text"}>
                    {t("logout")}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company
  }
}

const mapDispatchToProps = {
  unauthenticate: authActions.unauthenticate
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSwipeableSideMenu)
