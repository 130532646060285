import React from "react"

import { useTranslation } from "react-i18next"
import { Text, StyleSheet } from "@react-pdf/renderer"
import Section from "./Section"
import { IApplicant } from "../../../types/applicants"

interface Props {
  applicant: IApplicant
}

const CoverLetter: React.FC<Props> = ({ applicant }) => {
  const { t } = useTranslation()

  return (
    <>
      {applicant.cover_letter && applicant.cover_letter.length > 0 && (
        <Section title={t("cover_letter")}>
          <Text style={styles.text}>{applicant.cover_letter}</Text>
        </Section>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: "11px"
  }
})

export default CoverLetter
