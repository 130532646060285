import React from "react"
import i18n from "../../../i18n"
import { I18nextProvider } from "react-i18next"
import { Document as PdfDocument, Page, StyleSheet, Font, View, Image } from "@react-pdf/renderer"

import Header from "./Header"
import CoverLetter from "./CoverLetter"
import PersonalSummary from "./PersonalSummary"
import ApplicationNotes from "./ApplicationNotes"
import CandidateDetails from "./CandidateDetails"
import ApplicationDetails from "./ApplicationDetails"
import ApplicationEducationDetails from "./ApplicationEducationDetails"
import ApplicationOtherApplications from "./ApplicationOtherApplications"

import { IUser } from "../../../types/user"
import { IApplicant } from "../../../types/applicants"

interface DocumentProps {
  user: IUser
  applicant: IApplicant
}

Font.register({
  family: "Roboto",
  src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf"
})

const styles = StyleSheet.create({
  page: {
    paddingTop: 30,
    paddingBottom: 30,
    fontFamily: "Roboto"
  }
})

const CVPdfDocument: React.FC<DocumentProps> = ({ user, applicant }) => {
  return (
    <I18nextProvider i18n={i18n}>
      <PdfDocument>
        {user && (
          <Page size="A4" style={styles.page}>
            <Header user={user} />
            <ApplicationDetails user={user} applicant={applicant} />
            <CoverLetter applicant={applicant} />
            <CandidateDetails user={user} />
            <PersonalSummary user={user} />
            <ApplicationEducationDetails applicant={applicant} />
            <ApplicationNotes applicant={applicant} />
            <ApplicationOtherApplications user={user} />
            <View fixed style={{ bottom: 20, left: 20, position: "absolute" }}>
              <Image source="/assets/images/iskibris-logo-inverse.png" style={{ height: "20px" }} />
            </View>
          </Page>
        )}
      </PdfDocument>
    </I18nextProvider>
  )
}

export default CVPdfDocument
