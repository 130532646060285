import React from "react"
import Cookies from "js-cookie"
import axios from "../../../api/iskibris/iskibris"
import { useTranslation } from "react-i18next"
import { AxiosError, AxiosResponse } from "axios"

import GoogleLogin from "react-google-login"
import Button from "@material-ui/core/Button"
import OverlayLoader from "../../OverlayLoader"
import google from "../../../assets/images/google.png"

import useStyles from "../styles"

interface Props {
  mode: string
  onSuccess: () => void
  onFail: () => void
}

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""

const GoogleOauth: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)

  const googleLoginHandler = (response: any) => {
    setLoading(true)
    let accessToken = { access_token: response.accessToken }
    axios
      .post("/api/login/google", accessToken)
      .then(async (response: AxiosResponse) => {
        Cookies.set("auth_token", response.data["token"], {
          domain: process.env.REACT_APP_DOMAIN ?? ".iskibris.com",
          sameSite: "None",
          secure: true
        })
        setLoading(false)
        props.onSuccess()
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        props.onFail()
      })
  }

  return (
    <div style={{ justifyContent: "center" }}>
      <GoogleLogin
        clientId={googleClientId}
        onAutoLoadFinished={successLogin => {}}
        onSuccess={googleLoginHandler}
        onFailure={error => {
          props.onFail()
        }}
        render={ownProps => (
          <Button size="medium" className={classes.googleLoginButton} onClick={ownProps.onClick}>
            <img
              src={google}
              width={30}
              height={30}
              style={{
                marginRight: 5
              }}
              alt="Google"
            ></img>
            {t(`${props.mode}_with_google`)}
          </Button>
        )}
      />
      {loading ? (
        <OverlayLoader
          handleClose={() => {
            setLoading(false)
          }}
          open
        />
      ) : null}
    </div>
  )
}

export default GoogleOauth
