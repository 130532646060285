import React from "react"
import FloatingWhatsApp from "react-floating-whatsapp"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Cancel"
import Draggable from "react-draggable"

const defaultPosition = { x: 25, y: 25 }

const WhatsappContact = () => {
  const [position, setPosition] = React.useState({ ...defaultPosition })
  const [hideButton, setHideButton] = React.useState(!!sessionStorage.getItem("hide_whatsapp_button"))

  const handleClick = () => {
    setHideButton(true)
    sessionStorage.setItem("hide_whatsapp_button", "true")
  }

  const handleDrag = (e: any, data: any) => {
    setPosition({ ...defaultPosition })
  }

  return (
    <>
      {!hideButton && (
        <Draggable onDrag={handleDrag}>
          <div style={{ position: "fixed", right: position.x, bottom: position.y }}>
            <IconButton color="secondary" style={{ position: "fixed", right: 10, bottom: 75 }} onClick={handleClick}>
              <CloseIcon color="secondary" />
            </IconButton>
            <FloatingWhatsApp
              allowEsc
              allowClickAway
              accountName="Kemal"
              phoneNumber="+905428570360"
              className="whatsapp-button"
              placeholder="Bir mesaj yazın..."
              avatar="/assets/images/w-logo.png"
              chatMessage="Merhaba. Size nasıl yardım edebilirim?"
              statusMessage="Genellikle 5 dakika içinde yanıt verir."
            />
          </div>
        </Draggable>
      )}
    </>
  )
}

export default WhatsappContact
