const parseStringLocales = (body: string) => {
  let trBody, enBody
  if (!body) {
    return {
      tr: "",
      en: ""
    }
  }

  let enMatches = body.match(/<en>(.*?)<\/en>/)
  if (enMatches !== null) {
    enBody = enMatches[1]
  } else {
    trBody = body
  }

  let trMatches = body.match(/<tr>(.*?)<\/tr>/)
  if (trMatches !== null) {
    trBody = trMatches[1]
  }

  if (!trMatches && !enMatches) {
    trBody = `<tr>${body}</tr>`
    enBody = undefined
  }

  return {
    en: enBody,
    tr: trBody
  }
}

export { parseStringLocales }

export default parseStringLocales
