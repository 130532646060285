import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"

import { connect } from "react-redux"

import ClearIcon from "@material-ui/icons/Clear"
import MenuIcon from "@material-ui/icons/Dehaze"

import IconButton from "@material-ui/core/IconButton"

import { ICompany } from "../../../types/company"
import Content from "../../SideBar/Content"

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
    height: "calc(100% - 50px)"
  },
  fullList: {
    width: "auto"
  },
  paper: {
    top: "50px"
  },
  openDrawerButton: {
    color: "#fff",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  small: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(3),
      height: theme.spacing(3)
    }
  }
}))

interface Props {
  company: ICompany
}

type Anchor = "top" | "left" | "bottom" | "right"

const SwipeableMainSideMenu: React.FC<Props> = props => {
  const [state, setState] = React.useState({ left: false })

  const classes = useStyles()

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  const handleDrawerItemClick = () => {
    toggleDrawer("left", false)
  }

  return (
    <div>
      <IconButton className={classes.openDrawerButton} onClick={toggleDrawer("left", true)}>
        {!state["left"] ? <MenuIcon /> : <ClearIcon />}
      </IconButton>
      <SwipeableDrawer anchor={"left"} open={state["left"]} PaperProps={{ className: classes.paper }} onClose={toggleDrawer("left", false)} onOpen={toggleDrawer("left", true)}>
        <div className={clsx(classes.list, {})} role="presentation" onClick={toggleDrawer("left", false)} onKeyDown={toggleDrawer("left", false)}>
          <Content company={props.company} handleItemClick={handleDrawerItemClick} />
        </div>
      </SwipeableDrawer>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company
  }
}

export default connect(mapStateToProps)(SwipeableMainSideMenu)
