import React from "react"
import { useTranslation } from "react-i18next"

import { Backdrop, Button, Fade, IconButton, Link, Modal, Toolbar, makeStyles } from "@material-ui/core"

import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import Typography from "@material-ui/core/Typography"
import TableContainer from "@material-ui/core/TableContainer"
import CircularProgress from "@material-ui/core/CircularProgress"

import SingleInvoice from "./SingleInvoice"
import { IMalipoInvoice } from "../../../../types/billing"
import dayjs from "dayjs"
import LongMenu from "../../../LongMenu"
import InvoiceRequest from "./InvoiceRequest"
import { Close } from "@material-ui/icons"
import DocumentViewer from "../../../DocumentViewer"
import { parseToHttps } from "../../../../utils/urlMiddleware"
import BillingSettingsForm from "../BillingSettings/BillingSettingsForm"
import { useSelector } from "react-redux"
import { ICompany } from "../../../../types/company"
require("dayjs/locale/tr")

const useStyles = makeStyles(theme => ({
  paper: {
    // padding: theme.spacing(3, 3),
    // marginTop: "20px",
    marginRight: "20px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px"
    }
  },
  headerContainer: {
    padding: theme.spacing(2, 2)
  },
  table: {
    border: "none"
  },
  tableCell: {
    borderBottom: "none"
  },
  tableRow: {
    borderBottom: "1px solid #eaeaea"
  }
}))

interface Props {
  // invoices: any;
  // transactions: any;
  unregistered: boolean
  clientInvoices: IMalipoInvoice[]
}

interface InvoiceProps {
  item: IMalipoInvoice
}

const InvoiceTableItem: React.FC<InvoiceProps> = props => {
  const classes = useStyles()

  const company: ICompany = useSelector((state: any) => state.companyReducer.company)

  const [open, setOpen] = React.useState(false)
  const [invoiceRequestOpen, setInvoiceRequestOpen] = React.useState(false)

  const [viewInvoice, setViewInvoice] = React.useState<any>(null);
  const [billingSettingsOpen, setBillingSettingsOpen] = React.useState(false);

  const { t } = useTranslation()

  const handleClose = () => {
    setOpen(!open)
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleInvoiceRequestClose = () => {
    setInvoiceRequestOpen(!invoiceRequestOpen)
  }

  const handleInvoiceView = () => {
    setViewInvoice(!viewInvoice)
  }

  const handleBillingSettingsOpen = () => {
    setBillingSettingsOpen(!billingSettingsOpen)
  }

  const getIsBillingSettingsRequired = () => {
    // return true;
    return !company?.invoice_email ?? !company?.registration_number ?? !company?.invoice_contact_first_name ?? !company?.invoice_contact_first_name
  }

  return (
    <React.Fragment>
      <TableRow className={classes.tableRow}>
        <TableCell className={classes.tableCell}>
          <Typography>
            {t(`payment`)} {`(${props.item?.receipt?.bank_name} ${props.item?.receipt.card_no_masked?.slice(-4)})`}
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography>
            {dayjs(props.item.paid_at).locale("tr").format("D MMMM, YYYY HH:mm")}
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          {/* <Button variant="text" color="secondary" onClick={handleOpen}>
            {t("view_receipt")}
          </Button>
          {open ? <SingleInvoice open={open} clientInvoice={props.item} handleClose={handleClose} /> : null} */}
        </TableCell>
        {/* <TableCell className={classes.tableCell} style={{ color: "#52A148", textAlign: "center" }}>
          {t(props.item.status)}
        </TableCell> */}
        <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>
          <Typography>
            {props.item.amount} TL
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>
          <LongMenu
            options={[
              { title: t("view"), handler: handleOpen },
            ]}
          />
          {invoiceRequestOpen && <InvoiceRequest open={invoiceRequestOpen} handleClose={handleInvoiceRequestClose} clientInvoice={props.item} />}
        </TableCell>
      </TableRow>
      <TableRow className={classes.tableRow}>
        <TableCell className={classes.tableCell}>
          <Typography>
            {t(`invoice_for`, {product: props.item.offer?.name})}
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography>
            {dayjs(props.item.paid_at).locale("tr").format("D MMMM, YYYY HH:mm")}
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          {
            props.item?.e_invoice_url ? (
              <Link href={props.item?.e_invoice_url ?? "#"} download color="secondary" target="_blank">
                <Typography variant="body2">
                  {t("download_invoice")}
                </Typography>
              </Link>
            ) : (
              getIsBillingSettingsRequired()
              ?
              (
                <div onClick={handleBillingSettingsOpen}>
                  <Typography color="secondary" variant="body2" style={{cursor: "pointer"}}>
                    {t("add_billing_settings_information")}
                  </Typography>
                </div>
              )
              :
              (
                <Typography variant="body2">{t("processing")}</Typography>
              )
            )
          }
          {open ? <SingleInvoice open={open} clientInvoice={props.item} handleClose={handleClose} /> : null}
        </TableCell>
        {/* <TableCell className={classes.tableCell} style={{ color: "#52A148", textAlign: "center" }}>
          {t(props.item.status)}
        </TableCell> */}
        <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>
          <Typography>
            {props.item.amount} TL
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>
          <LongMenu
            options={props.item.e_invoice_url 
              ?
              (
                  [
                  { title: t("view"), handler: handleInvoiceView },
                  { title: t("download"), handler: () => {}, link: props.item.e_invoice_url ?? "#", download: true },
                ]
              )
            :
              (
                getIsBillingSettingsRequired()
                ? 
                [ { title: t("add_billing_settings_information"), handler: handleBillingSettingsOpen } ] 
                : 
                []
              )
            }
          />
          {invoiceRequestOpen && <InvoiceRequest open={invoiceRequestOpen} handleClose={handleInvoiceRequestClose} clientInvoice={props.item} />}
        </TableCell>
      </TableRow>
      {viewInvoice && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modal"
          open={viewInvoice != null}
          onClose={() => {
            setViewInvoice(null)
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 5000
          }}
        >
          <Fade in={viewInvoice != null}>
            <div className="modal__paper">
              <Toolbar
                variant="dense"
                className="modal__toolbar"
                style={{ justifyContent: "center" }}
              >
                <IconButton
                  style={{color: "#fff"}}
                  onClick={() => {
                    setViewInvoice(null)
                  }}
                >
                  <Close className="modal__close-button-icon" />
                </IconButton>
              </Toolbar>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
              >
                <div style={{ width: "100%" }}>
                  <DocumentViewer 
                    url={props.item.e_invoice_url}
                  />
                </div>
                <Button href={parseToHttps(props.item.e_invoice_url)} target="_blank" variant="text">
                  {t("download")}
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      )}
      {billingSettingsOpen && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modal"
          open={billingSettingsOpen}
          onClose={() => {
            setBillingSettingsOpen(false)
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 5000
          }}
        >
          <Fade in={billingSettingsOpen}>
            <div className="modal__paper" style={{borderRadius: "5px", paddingTop: "1rem"}}>
              <div
                style={{ justifyContent: "space-between", display: "flex", alignItems: "center", paddingLeft: "2rem", paddingRight: "1rem" }}
              >
                <Typography variant="h6">
                  {t("billing_settings")}
                </Typography>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setBillingSettingsOpen(false)
                  }}
                >
                  <Close className="modal__close-button-icon" />
                </IconButton>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "1rem 2rem"
                }}
              >
                <div style={{ width: "100%" }}>
                  <BillingSettingsForm company={company} onSuccess={() => {setBillingSettingsOpen(false)}} />
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      )}
    </React.Fragment>
  )
}

const PaymentHistory: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { unregistered, clientInvoices } = props

  return (
    <Paper className={classes.paper}>
      <div className={classes.headerContainer}>
        <Typography variant="h6">{t("billing_history")}</Typography>
      </div>
      {clientInvoices ? (
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "#797979" }}>{t("description")}</TableCell>
                <TableCell style={{ color: "#797979" }}>{t("date")}</TableCell>
                <TableCell style={{ color: "#797979" }}>{}</TableCell>
                {/* <TableCell style={{ color: "#797979", textAlign: "center" }}>{t("status")}</TableCell> */}
                <TableCell style={{ color: "#797979", textAlign: "center" }}>{t("amount")}</TableCell>
                <TableCell style={{ color: "#797979", textAlign: "center" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientInvoices?.length > 0 ? (
                clientInvoices?.map((item: any) => {
                  return <InvoiceTableItem key={item.id} item={item} />
                })
              ) : (
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell} style={{ width: "100%", textAlign: "center" }} colSpan={6}>
                    {t("no_invoice_history")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <React.Fragment>
          {unregistered ? (
            <Typography style={{ color: "#797979" }}>{t("no_billing_system_client")}</Typography>
          ) : (
            <div>
              <CircularProgress />
            </div>
          )}
        </React.Fragment>
      )}
    </Paper>
  )
}

export default PaymentHistory
