import React from "react"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { GoMail } from "react-icons/go"
import { useTranslation } from "react-i18next"

interface Props {
  handleClick: () => void
}

const NoMessages: React.FC<Props> = props => {
  const { t } = useTranslation()

  return (
    <div style={{ width: "70%", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -35%)" }}>
      <div style={{ padding: "2rem", border: "0.5px solid #eaeaea", width: "100%", display: "flex", flexFlow: "column", alignItems: "center" }}>
        <GoMail size={50} />
        <Typography variant="body1" style={{ textAlign: "center", marginBottom: "1rem" }}>
          {t("no_messages_yet_title")}
        </Typography>
        <Typography variant="body2" style={{ textAlign: "center", marginBottom: "1rem" }}>
          {t("no_messages_yet_body")}
        </Typography>
        <Button variant="contained" color="secondary" onClick={props.handleClick}>
          <GoMail size={22} />
          <span style={{ marginLeft: "0.5rem" }}>{t("send_message")}</span>
        </Button>
      </div>
    </div>
  )
}

export default NoMessages
