import React from "react"
import axios from "../../../api/iskibris/iskibris"
import { connect, useSelector } from "react-redux"
import { useForm } from "react-hook-form"

import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { useTranslation } from "react-i18next"
import CompanyMission from "./CompanyMission"
import AboutCompany from "./AboutCompany"
import useStyles from "./styles"
import ActionButtons from "../GenericActionButtons"
import NotificationSnackbar from "../../NotificationSnackbar"
import { companyActions } from "../../../store/actions/company"
import { Dispatch } from "redux"
import { IActionType } from "../../../types"
import { Grid } from "@material-ui/core"
import CompanyInfoFAQ from "./CompanyInfoFaq"

interface Props {
  type: string
  editFormFields: (value: any) => void
}

const CompanyStory: React.FC<Props> = props => {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm()
  const [loading, setLoading] = React.useState(false)
  const [result, setResult] = React.useState("")

  const state = useSelector((state: any) => state.companyReducer)

  const onSubmit = async (fields: any, e: any) => {
    e.preventDefault()
    setLoading(true)
    const data = {
      ...state.form,
      sector_id: state.form?.sector_id?.id,
      industry_id: state.form?.industry_id?.id
    }

    await axios
      .put(`/api/hiring/v2/companies/${state.company?.id}`, data)
      .then(response => {
        if (response.status === 200) {
          setLoading(false)
          setResult("success")
        }
      })
      .catch(error => {
        setLoading(false)
        setResult("fail")
        const errors = error?.response?.data?.errors
        if (errors) {
          const keys = Object.keys(errors)
          keys.map(item => {
            setError(item, { message: errors[item][0], type: "validation" })
            return item
          })
        }
      })
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={12} md={7}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper className={classes.paper}>
              <div>
                <div className={classes.titleText}>
                  <Typography variant="h6">{t("about_your_co")}</Typography>
                  <Typography variant="caption" style={{ color: "#797979" }} gutterBottom>
                    {t("about_company_text")}
                  </Typography>
                </div>
                <AboutCompany errors={errors} control={control} />
              </div>
            </Paper>
            <Paper className={classes.paper} style={{ marginTop: "20px" }}>
              <div>
                <CompanyMission errors={errors} control={control} />
              </div>
            </Paper>
            <Paper className={classes.paper} style={{ marginTop: "20px" }}>
              <div>
                <ActionButtons loading={loading} />
              </div>
            </Paper>
          </form>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <Paper className={classes.sidepaper}>
            <div>
              <CompanyInfoFAQ />
            </div>
          </Paper>
        </Grid>
      </Grid>
      <NotificationSnackbar
        status={result}
        success_msg={t(`company_story_${props.type}_success`)}
        error_msg={t(`company_story_${props.type}_fail`)}
        handleClose={() => {
          setResult("")
        }}
      />
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    editFormFields: (value: any) => dispatch(companyActions.editFormContent(value))
  }
}

export default connect(null, mapDispatchToProps)(CompanyStory)
