import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: "1px solid #eaeaea",
    padding: "0px",
    cursor: "pointer",
    transition: "0.5s",
    listStyle: "none"
  },
  listItemIcon: {
    paddingRight: "10px",
    margin: "0px",
    minWidth: "0px",
    color: "red",
    fontSize: "15px",
    alignItems: "center"
  },
  listItem: {
    padding: "5px",
    listStyle: "none",
    transition: "0.5s",
    paddingRight: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#F1F2F3 !important",
      boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)"
    },
    borderLeft: (props: any) => (props.selected ? "2px solid #EA148D" : "none"),
    [theme.breakpoints.down("sm")]: {
      borderLeft: "none !important",
      backgroundColor: "#fff !important"
    }
  },
  listItemRightSide: {
    padding: "0px",
    width: "20%",
    overflow: "hidden",
    float: "right"
  },
  applicationStatus: {
    fontSize: 12,
    textAlign: "end",
    marginBottom: "5px",
    color: theme.palette.grey[800]
  },
  applicationDate: {
    fontSize: 10,
    color: "#5e5e5e",
    textAlign: "end"
  },
  maybeIcon: {
    color: "#F6B500"
  },
  interestedIcon: {
    color: "#10AA61"
  },
  notInterestedIcon: {
    color: "#EB4133"
  },
  unratedIcon: {
    color: "#eaeaea"
  },
  jobTitle: {
    fontSize: 12,
    color: "#5e5e5e",
    marginBottom: "4px",
  },
  jobLocation: {
    fontSize: 10,
    color: "#5e5e5e"
  },
  newTag: {
    paddingRight: "5px",
    paddingLeft: "5px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    marginBottom: "5px",
    textAlign: "center"
  },
  secondarySideContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    flexDirection: "column",
    maxWidth: "25%"
  }
}))

export default useStyles
