import React from "react"
import { useTranslation } from "react-i18next"
import SurveyQuestionWidget from "../SurveyQuestionWidget"
import { submitSurvey } from "../../api/iskibris/iskibris-methods";

interface Props {
  onSubmitted?: (option: any) => void;
}

const NotificationsSurveyModule: React.FC<Props> = ({onSubmitted}) => {
  const { t } = useTranslation()

  const [submitResult, setSubmitResult] = React.useState<"success" | "error" | undefined>(undefined)

  const handleOptionSelected = async (option: number) => {
    const data = {
      slug: "notification-center",
      payload: {
        vote: option
      }
    }

    try {
      const result = await submitSurvey(data)
      if (result.status < 300) {
        setSubmitResult("success")
      } else {
        setSubmitResult("error")
      }
    } catch (err) {
      setSubmitResult("error")
    }


    if(onSubmitted) onSubmitted(option)
  }

  return (
    <div>
      <SurveyQuestionWidget
        tellUsMoreUrl="/contact"
        error={submitResult === "error"}
        success={submitResult === "success"}
        tellUsMoreMessage={t("tell_us_more")}
        title={t("notification_center_survery_question")}
        errorMessage={t("notification_center_survery_error")}
        successMessage={t("notification_center_survery_success")}
        subtitle={t("notification_center_survery_question_subtitle")}
        onSelected={handleOptionSelected}
      />
    </div>
  )
}

export default NotificationsSurveyModule
