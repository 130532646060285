import { action } from "typesafe-actions"
import { actionTypes } from "./actionTypes"

export const systemActions = {
  openDrawer: () => {
    return action(actionTypes.OPEN_DRAWER)
  },
  closeDrawer: () => {
    return action(actionTypes.CLOSE_DRAWER)
  },
  openUserDrawer: () => {
    return action(actionTypes.OPEN_USER_MENU_DRAWER)
  },
  closeUserDrawer: () => {
    return action(actionTypes.CLOSE_USER_MENU_DRAWER)
  },
  openCompanyMenu: () => {
    return action(actionTypes.OPEN_COMPANY_MENU)
  },
  closeCompanyMenu: () => {
    return action(actionTypes.CLOSE_COMPANY_MENU)
  },
  openLanguageMenu: () => {
    return action(actionTypes.OPEN_LANGUAGE_MENU)
  },
  closeLanguageMenu: () => {
    return action(actionTypes.CLOSE_LANGUAGE_MENU)
  }
}
