import React from "react"
import { useTranslation } from "react-i18next"

import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"

import { makeStyles } from "@material-ui/core"
import { ICompany } from "../../../../types/company"
import { useSelector } from "react-redux"
import BillingSettingsForm from "./BillingSettingsForm"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 3),
    // marginTop: "20px",
    marginRight: "20px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px"
    }
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem"
  },
  expiredContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  expiredText: {
    color: "#797979",
    paddingRight: "10px"
  }
}))

interface Props {}

const BillingSettings: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const company: ICompany = useSelector((state: any) => state.companyReducer.company)

  return (
    <Paper className={classes.paper}>
      {!!company ? (
        <React.Fragment>
          <div>
            <div className={classes.headerContainer}>
              <Typography variant="h6">{t("billing_settings")}</Typography>
            </div>
            <BillingSettingsForm company={company} />
          </div>
        </React.Fragment>
      ) : (
        <div>
          <CircularProgress />
        </div>
      )}
    </Paper>
  )
}

export default BillingSettings
