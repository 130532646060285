import { defineAbility } from "@casl/ability"
import store from "../../store"

let ability = defineAbility((can, cannot) => {})

let currentAbilities: any
let currentUserAbilities: any
store.subscribe(() => {
  const prevAbilities = currentAbilities
  currentAbilities = store.getState().permissionsReducer.abilities ?? []
  currentUserAbilities = store.getState().permissionsReducer.userAbilities ?? []
  if (prevAbilities !== currentAbilities) {
    const currentAllAbilities = [...currentAbilities, ...currentUserAbilities]
    ability.update(makeAbilities(currentAbilities))
    ability.update(makeUserAbilities(currentUserAbilities))
    ability.update(makeUserAbilities(currentAllAbilities))
  }
})

const makeAbilities = (abilities: any[]) => {
  if (!!abilities) {
    const newAbs = abilities.map(item => {
      if (item.name === "*") {
        if (!!item.entity_type && (item.entity_type === "*" || item.entity_type === "App\\Company")) {
          return { action: "manage", subject: "all" }
        }
        return { action: "manage", subject: item.entity_type || "all" }
      }
      return { action: item.name, subject: item.entity_type || "all" }
    })
    newAbs.push({ action: "add-company-users", subject: "all" })
    return newAbs
  }
  return [] // This will forbid all rules;
}

const makeUserAbilities = (abilities: any[]) => {
  if (!!abilities) {
    const newAbs = abilities.map(item => {
      if (item.name === "*") {
        if (!!item.entity_type && item.entity_type === "*") {
          // This is for admin users to have full access.
          return { action: "manage", subject: "all" }
        }
      }
      return { action: item.name, subject: item.entity_type || "all" }
    })
    return newAbs
  }
  return [] // This will forbid all rules;
}

export default ability
