import React from "react"
import { useTranslation } from "react-i18next"

import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

import useStyles from "./styles"
import ModalContainer from "../ModalContainer"

let dropdownOptions = [
  { value: "tr", label: "Türkçe", code: "TR" },
  { value: "en", label: "English", code: "EN" }
]

interface Props {
  handleClose: () => void
  changeLanguage: (value: any) => void
}

const MobileLanguageMenu: React.FC<Props> = props => {
  const classes = useStyles()
  const { i18n } = useTranslation()

  const handleLanguageClick = (value: any) => {
    props.changeLanguage(value)
    props.handleClose()
  }

  return (
    <ModalContainer open handleClose={props.handleClose}>
      <div className={classes.root}>
        {dropdownOptions.map(value => {
          const labelId = `checkbox-list-label-${value.value}`
          return (
            <ListItem
              className={classes.listitem}
              selected={i18n.language === value.value}
              key={value.value}
              role={undefined}
              dense
              button
              onClick={() => {
                handleLanguageClick(value.value)
              }}
            >
              <ListItemText id={labelId}>
                {value.label} <span className={classes.shortLabel}>{value.code}</span>
              </ListItemText>
            </ListItem>
          )
        })}
      </div>
    </ModalContainer>
  )
}

export default MobileLanguageMenu
