import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  drawerOpen: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeInOut,
      duration: "400ms"
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeInOut,
      duration: "400ms"
    })
  },
  contentDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%"
  }
}))

export default useStyles
