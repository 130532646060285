import React, { useState } from "react"
import axios from "../../../../api/iskibris/iskibris"
import { connect } from "react-redux"

import { TextField, FormControl, Button, CircularProgress } from "@material-ui/core"

import { useTranslation } from "react-i18next"

import { ICompany, ICompanyFAQ } from "../../../../types/company"
import NotificationSnackbar from "../../../NotificationSnackbar"
import { Dispatch } from "redux"
import { IActionType } from "../../../../types"
import { companyActions } from "../../../../store/actions/company"
import { useForm } from "react-hook-form"

import useStyles from "../styles"

interface Props {
  company: ICompany
  faq?: ICompanyFAQ
  handleEditCancel?: () => void
  fetchCompanyFaqs: (id: any) => void
}

const FaqForm: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError
  } = useForm()

  const initialFormState = {
    question_tr: props.faq ? props.faq.question_tr : "",
    question_en: props.faq ? props.faq.question_en : "",
    answer_tr: props.faq ? props.faq.answer_tr : "",
    answer_en: props.faq ? props.faq.answer_en : "",
    company_id: props.company?.id
  }

  const [submitting, setSubmitting] = useState(false)
  const [requestResult, setRequestResult] = useState<any>("")
  const [values, setValues] = useState({ ...initialFormState })

  const setCompanyFaqDetails = (name: any) => (event: any) => {
    setValues({ ...values, [name]: event.target.value })
  }

  const handleCancel = () => {
    if (props.handleEditCancel) {
      props.handleEditCancel()
    }
  }

  const handleSave = async () => {
    setSubmitting(true)
    clearErrors()
    const data = {
      ...values,
      question_en: values.question_en && values.question_en.length > 0 ? values.question_en : values.question_tr,
      answer_en: values.answer_en && values.answer_en.length > 0 ? values.answer_en : values.answer_tr,
      company_id: props.company?.id
    }

    if (props.faq) {
      await axios
        .put(`/api/hiring/v2/companies/faq/${props.faq.id}`, { ...data })
        .then(response => {
          props.fetchCompanyFaqs(props.company.id)
          setRequestResult("success")
        })
        .catch(err => {
          setRequestResult("fail")
          const errors = err?.response?.data?.errors
          if (errors) {
            const keys = Object.keys(errors)
            keys.map(item => {
              setError(item, { message: errors[item][0], type: "validation" })
              return item
            })
          }
        })
    } else {
      await axios
        .post(`/api/hiring/v2/companies/${props.company.id}/faq`, { ...data })
        .then(responses => {
          props.fetchCompanyFaqs(props.company.id)
          setRequestResult("success")
        })
        .catch(err => {
          setRequestResult("fail")
          const errors = err?.response?.data?.errors
          if (errors) {
            const keys = Object.keys(errors)
            keys.map(item => {
              setError(item, { message: errors[item][0], type: "validation" })
              return item
            })
          }
        })
    }
    setSubmitting(false)
  }

  return (
    <div style={{ width: "100%" }}>
      <form onSubmit={handleSubmit(handleSave)}>
        <FormControl className={classes.formcontrol}>
          <TextField
            margin="dense"
            variant="outlined"
            error={!!errors.question_tr}
            helperText={errors.question_tr ? errors.question_tr?.message?.toString() : null}
            required
            fullWidth
            id="question_tr"
            name="question_tr"
            autoComplete={t("question_tr")}
            placeholder={t("question_tr")}
            label={t("question_tr")}
            value={values.question_tr}
            onChange={setCompanyFaqDetails("question_tr")}
          />
        </FormControl>
        <FormControl className={classes.formcontrol}>
          <TextField
            margin="dense"
            variant="outlined"
            error={!!errors.question_en}
            helperText={errors.question_en ? errors.question_en.message?.toString() : null}
            fullWidth
            id="question_en"
            name="question_en"
            autoComplete={t("question_en")}
            placeholder={t("question_en")}
            label={t("question_en")}
            value={values.question_en}
            onChange={setCompanyFaqDetails("question_en")}
          />
        </FormControl>
        <FormControl className={classes.formcontrol}>
          <TextField
            margin="dense"
            variant="outlined"
            multiline
            rows={3}
            error={!!errors.answer_tr}
            helperText={errors.answer_tr ? errors.answer_tr.message?.toString() : null}
            required
            fullWidth
            id="answer_tr"
            name="answer_tr"
            autoComplete={t("answer_tr")}
            placeholder={t("answer_tr")}
            label={t("answer_tr")}
            value={values.answer_tr}
            onChange={setCompanyFaqDetails("answer_tr")}
          />
        </FormControl>
        <FormControl className={classes.formcontrol}>
          <TextField
            margin="dense"
            variant="outlined"
            multiline
            rows={3}
            error={!!errors.answer_en}
            helperText={errors.answer_en ? errors.answer_en.message?.toString() : null}
            fullWidth
            id="answer_en"
            name="answer_en"
            autoComplete={t("answer_en")}
            placeholder={t("answer_en")}
            label={t("answer_en")}
            value={values.answer_en}
            onChange={setCompanyFaqDetails("answer_en")}
          />
        </FormControl>
        <div style={{ marginTop: "10px" }}>
          <Button color="primary" variant="outlined" onClick={handleCancel}>
            {t("cancel")}
          </Button>
          <Button color="primary" variant="contained" type="submit" style={{ float: "right" }}>
            {submitting ? <CircularProgress size={22} style={{ color: "#fff" }} /> : t("save")}
          </Button>
        </div>
      </form>
      <div style={{}}>
        <NotificationSnackbar
          status={requestResult}
          success_msg={props.faq ? t("edit_faq_success") : t("create_faq_success")}
          error_msg={props.faq ? t("edit_faq_fail") : t("create_faq_fail")}
          handleClose={() => {
            if (props.faq && props.handleEditCancel) {
              setRequestResult("")
              props.handleEditCancel()
            } else {
              setRequestResult("")
              setValues({ ...initialFormState })
            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company,
    faqs: state.companyReducer.faqs
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchCompanyFaqs: async (companyId: any) => dispatch(await companyActions.fetchCompanyFaqs(companyId)),
    addCompanyFaqs: async (companyId: any, information: any) => dispatch(await companyActions.addCompanyFaq(companyId, information)),
    updateCompanyFaq: async (information: any, faqId: any) => dispatch(await companyActions.updateCompanyFaq(information, faqId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqForm)
