import { makeStyles } from "@material-ui/core"

export default makeStyles(theme => ({
  cardIcon: {
    color: theme.palette.secondary.dark
    // fontSize: 45,
    // height: "50px",
    // width: "45px",
  },
  subscribeActionArea: {
    backgroundColor: "#e6f8ff",
    width: "100%",
    margin: "auto",
    textAlign: "left",
    padding: "10px 10px 10px 0px"
  },
  gridContainer: {
    // padding: '24px'
  },
  gridItem: {
    margin: "24px"
  },
  companyLogo: {
    width: "45px"
    // border: '1px solid #C5C5C5'
  },
  progress: {
    margin: theme.spacing(2)
  }
}))
