import React from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import CancelIcon from "@material-ui/icons/Close"
import Container from "@material-ui/core/Container"
import IconButton from "@material-ui/core/IconButton"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import CircularProgress from "@material-ui/core/CircularProgress"

import { Dispatch } from "redux"
import { IActionType } from "../../../../types"
import { ICompany } from "../../../../types/company"
import { IApplicantFilters } from "../../../../types/applicants"
import { applicantsActions } from "../../../../store/actions/applicants"

// Rating Info
import MaybeIcon from "@material-ui/icons/Help"
import InterestedIcon from "@material-ui/icons/CheckCircle"
import UnratedIcon from "@material-ui/icons/FiberManualRecord"
import NotInterestedIcon from "@material-ui/icons/CancelRounded"

import SingleTag from "./SingleTag"
import SearchTag from "./SearchTag"
import { IJob } from "../../../../types/jobs"
import { IFieldOfStudy } from "../../../../types/user"
import OverlayLoader from "../../../OverlayLoader"
// import { filterPlacesByIds } from "../../../../api/iskibris/iskibris-methods"
// import { isArray } from "lodash"
// import { IPlace } from "../../../../types/places"
import { getPlaceNameRecursively } from "../../../../utils/places"

let tagInfoOptions = [
  { value: 0, label: "", icon: <UnratedIcon style={{ color: "#eaeaea" }} className={"icon icon--unrated"} /> },
  { value: 1, label: "", icon: <InterestedIcon style={{ color: "#10AA61" }} className={"icon icon--interested"} /> },
  { value: 2, label: "", icon: <MaybeIcon style={{ color: "#F6B500" }} className={"icon icon--maybe"} /> },
  { value: 3, label: "", icon: <NotInterestedIcon style={{ color: "#EB4133" }} className={"icon icon--not-interested"} /> }
]

interface Props {
  jobs: IJob[]
  company: ICompany
  filters: IApplicantFilters
  fieldsOfStudy: IFieldOfStudy[]
  // places: IPlace[] | null
  folders: any[]
  removeKeywordFilter: (filters: any, orderBy: string | null, orderDirection: string | null) => void
  clearAllFilters: (id: any, page: any, orderBy: string | null, orderDirection: string | null) => void
  removeFilter: (value: any, filters: any, filterType: string, orderBy: string | null, orderDirection: string | null) => void
}

const FilterTags: React.FC<Props> = props => {
  const { t } = useTranslation()

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"))
  const [loading, setLoading] = React.useState(false)
  const [overlayLoading, setOverlayLoading] = React.useState(false)

  const dispatch = useDispatch()

  const orderBy = useSelector((state: any) => state.applicantsReducer.orderBy)
  const orderDirection = useSelector((state: any) => state.applicantsReducer.orderDirection)
  
  const filteredPlaces: any[] | null = useSelector((state: any) => state.applicantsReducer.filteredPlaces)

  const handleDelete = async (value: any, type: any) => {
    await props.removeFilter(value, props.filters, type, orderBy, orderDirection)
  }

  const handleClearAll = async () => {
    setLoading(true)
    await props.clearAllFilters(props.company.id, 1, orderBy, orderDirection)
    setLoading(false)
  }

  const checkFiltersAvailable = (skipKeyword?: boolean) => {
    if (
      props.filters.rating?.length > 0 ||
      props.filters.stage?.length > 0 ||
      props.filters.stage_enum?.length > 0 ||
      props.filters.city?.length > 0 ||
      props.filters.place?.length > 0 ||
      props.filters.job?.length > 0 ||
      props.filters.field_of_study?.length > 0 ||
      props.filters.work_permit?.length > 0 ||
      props.filters.education_level?.length > 0 ||
      props.filters.gender?.length > 0 ||
      props.filters.folder?.length > 0 ||
      props.filters.age?.length > 0 ||
      props.filters.driver_licence?.length > 0 ||
      (skipKeyword ? false : !!props.filters.keyword)
    ) {
      console.log(">> We have filters")
      return true
    } else {
      console.log(">> NO filters")
      return false
    }
  }
  
  const removeKeywordFilter = async () => {
    setOverlayLoading(true)
    await props.removeKeywordFilter(props.filters, orderBy, orderDirection)
    setOverlayLoading(false)
  }

  React.useEffect(() => {
    const fetchPlaces = async () => {
      dispatch(await applicantsActions.filterPlacesByIds(props.filters.place.join(",")))
    }
    if(!filteredPlaces || (filteredPlaces && filteredPlaces?.length !== props.filters.place.length)){
      fetchPlaces()
    }
  }, [props.filters.place, filteredPlaces, dispatch])

  return (
    <div>
      {props.filters.keyword ? (
        <div style={{ borderBottom: checkFiltersAvailable(true) ? "1px solid #eaeaea" : "none" }}>
          <SearchTag keywords={props.filters.keyword} onDelete={removeKeywordFilter} />
        </div>
      ) : null}
      {checkFiltersAvailable(true) ? (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ padding: "5px", overflow: "auto" }}>
            <Box component={Container} style={{ width: "100%", maxWidth: "none", padding: "0px", display: "flex" }}>
              {props.filters.rating
                ? props.filters.rating.map(item => {
                    const ndx = tagInfoOptions.findIndex(tag => item === tag.value)
                    const currentRating = tagInfoOptions[ndx]
                    return <SingleTag value={item} key={Math.random()} field="rating" icon={currentRating.icon} label={t(`rating_id_${item}`)} onDelete={handleDelete} />
                  })
                : null}
              {props.filters.stage
                ? props.filters.stage.map(item => {
                    return <SingleTag value={item} key={Math.random()} field="stage" label={t(`stage_id_${item}`)} onDelete={handleDelete} />
                  })
                : null}
              {props.filters.stage_enum
                ? props.filters.stage_enum.map(item => {
                    return <SingleTag value={item} key={Math.random()} field="stage_enum" label={t(`stage_id_enum_${item}`)} onDelete={handleDelete} />
                  })
                : null}
              {props.filters.city
                ? props.filters.city.map(item => {
                    return <SingleTag value={item} key={Math.random()} field="city" label={t(`city_id_${item}`)} onDelete={handleDelete} />
                  })
                : null}
              {props.filters.place && filteredPlaces
                ? props.filters.place.map(item => {
                    const place = filteredPlaces?.find(place => item === place?.id)
                    if(!place){
                      return null
                    }
                    return <SingleTag value={item} key={Math.random()} field="place" label={getPlaceNameRecursively(place)} onDelete={handleDelete} />
                  })
                : null}
              {props.filters.gender
                ? props.filters.gender.map(item => {
                    return <SingleTag value={item} key={Math.random()} field="gender" label={t(`gender_id_${item}`)} onDelete={handleDelete} />
                  })
                : null}
              {props.filters.age
                ? props.filters.age.map(item => {
                    return <SingleTag value={item} key={Math.random()} field="age" label={t(`application_age_${item}`)} onDelete={handleDelete} />
                  })
                : null}
              {props.filters.driver_licence
                ? props.filters.driver_licence.map(item => {
                    return <SingleTag value={item} key={Math.random()} field="driver_licence" label={t(`driver_licence_category_id_${item}`)} onDelete={handleDelete} />
                  })
                : null}
              {props.filters.work_permit
                ? props.filters.work_permit.map(item => {
                    return <SingleTag value={item} key={Math.random()} field="work_permit" label={t(`work_permit_id_${item}`)} onDelete={handleDelete} />
                  })
                : null}
              {props.filters.education_level
                ? props.filters.education_level.map(item => {
                    return <SingleTag value={item} key={Math.random()} field="education_level" label={t(`education_level_id_${item}`)} onDelete={handleDelete} />
                  })
                : null}
              {props.filters.job
                ? props.filters.job.map(item => {
                    let job = props.jobs.find(job => item === job.id)
                    return <SingleTag value={item} key={Math.random()} field="job" label={job?.title || ""} onDelete={handleDelete} />
                  })
                : null}
              {props.filters.field_of_study
                ? props.filters.field_of_study.map(item => {
                    let fos = props.fieldsOfStudy.find(fos => item === fos.id)
                    return <SingleTag value={item} key={Math.random()} field="field_of_study" label={fos?.name_tr || fos?.name_en || ""} onDelete={handleDelete} />
                  })
                : null}
              {props.filters.folder
                ? props.filters.folder.map(item => {
                    let fos = props.folders.find(fos => item === fos.id)
                    return <SingleTag value={item} key={Math.random()} field="folder" label={fos?.title} onDelete={handleDelete} />
                  })
                : null}
            </Box>
          </div>
          <div>
            {matchesMobile ? (
              <IconButton color="secondary" onClick={handleClearAll}>
                {loading ? <CircularProgress size={22} /> : <CancelIcon />}
              </IconButton>
            ) : (
              <Button variant="text" color="secondary" onClick={handleClearAll}>
                {loading ? <CircularProgress size={22} color="secondary" /> : t("clear_all_filters")}
              </Button>
            )}
          </div>
        </div>
      ) : null}
      {overlayLoading && (
        <OverlayLoader
          open={overlayLoading}
          handleClose={() => {
            setOverlayLoading(false)
          }}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    jobs: state.jobsReducer.jobs,
    company: state.companyReducer.company,
    filters: state.applicantsReducer.filters,
    fieldsOfStudy: state.applicantsReducer.fieldsOfStudy,
    folders: state.applicantsReducer.folders
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    removeFilter: async (value: any, filters: any, filterType: string, orderBy: string | null, orderDirection: string | null) =>
      dispatch(await applicantsActions.removeSingleFilter(value, filters, filterType, orderBy, orderDirection)),
    removeKeywordFilter: async (filters: any, orderBy: string | null, orderDirection: string | null) =>
      dispatch(await applicantsActions.removeKeywordFilter(filters, orderBy, orderDirection)),
    clearAllFilters: async (id: any, page: any, orderBy: string | null, orderDirection: string | null) =>
      dispatch(await applicantsActions.clearAllFilters(id, page, orderBy, orderDirection))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterTags)
