import React from "react"
import { useTranslation } from "react-i18next"
import { IEducationDetailsModel, IFieldOfStudy } from "../../../../../../types/user"

import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import IoIosSchool from "@material-ui/icons/SchoolOutlined"

interface Props {
  fieldsOfStudy: IFieldOfStudy[]
  education: IEducationDetailsModel
}

const SingleEducationDetail: React.FC<Props> = props => {
  const fieldOfStudy = props.fieldsOfStudy?.find(item => item.id === props.education?.field_of_study_id)
  const { t, i18n } = useTranslation()

  return (
    <div>
      <ListItem style={{ paddingLeft: 0, paddingRight: 0, padding: 0 }}>
        <ListItemIcon style={{ minWidth: "45px" }}>
          <IoIosSchool style={{ width: "30px", height: "30px" }} />
        </ListItemIcon>
        <ListItemText
          primary={
            <React.Fragment>
              {i18n.language === "tr" ? props.education.school_name_tr || props.education.school_name_en : props.education.school_name_en || props.education.school_name_tr} -{" "}
              {t("finished_in")} {t(`month_id_${props.education.end_month}`)} {props.education.end_year}
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography variant="body1" component="span" style={{ display: "block" }}>
                {t(`degree_id_${props.education.degree_id}`)} -{" "}
                {i18n.language === "tr" ? fieldOfStudy?.name_tr || fieldOfStudy?.name_en : fieldOfStudy?.name_en || fieldOfStudy?.name_tr}
              </Typography>
              <Typography variant="body2" style={{ display: "block" }} component="span">
                {props.education.achievements}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    </div>
  )
}

export default SingleEducationDetail
