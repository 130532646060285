import React from "react"
import { useTranslation } from "react-i18next"

import { ICompany } from "../../../../../types/company"
import GenericFilter from "../GenericFilter"

import { IApplicantFilters, IApplicantSearchParams, IPaginatedApplicants } from "../../../../../types/applicants"

import useStyles from "../../styles"

interface Props {
  company: ICompany
  activeFilter: boolean
  filters: IApplicantFilters
  applicants: IPaginatedApplicants
  handleGoBack: () => void
  applyFilters: (filters: IApplicantSearchParams, page: number) => void
}

const options = [
  { value: 0, label: "", quantity: 0 },
  { value: 1, label: "", quantity: 0 },
  { value: 2, label: "", quantity: 0 }
]

const EducationLevelFilter: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleSubmit = async (filters: any) => {
    await props.applyFilters(
      {
        ...filters,
        activeFilter: props.activeFilter,
        companyId: props.company.id,
      },
      1
    )
  }

  return (
    <div className={classes.singleFilterDiv}>
      <GenericFilter
        field="education_level"
        options={options.map(item => ({
          value: item.value,
          label: t(`education_level_id_${item.value}`),
          quantity: props.applicants ? props.applicants.data.filter(applicant => applicant.education_level_id === item.value).length : 0
        }))}
        filters={props.filters}
        applicants={props.applicants}
        handleApplyClick={handleSubmit}
        handleGoBack={props.handleGoBack}
      />
    </div>
  )
}

export default EducationLevelFilter
