import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Dispatch } from "redux"

// import * as actionCreators from '../../../store/actions';
import AddTemplate from "./AddTemplate"
import SingleTemplate from "./SingleTemplate"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import Typography from "@material-ui/core/Typography"
import useTheme from "@material-ui/core/styles/useTheme"
import ListItemText from "@material-ui/core/ListItemText"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import CircularProgress from "@material-ui/core/CircularProgress"

import { useTranslation } from "react-i18next"
import { GoMail } from "react-icons/go"

import useStyles from "./styles"
import { IActionType } from "../../../types"
import { ICompany } from "../../../types/company"
import { IMessageTemplate } from "../../../types/messages"

import { messagesActions } from "../../../store/actions/messages"
import { TableContainer } from "@material-ui/core"

interface Props {
  company: ICompany
  templates: IMessageTemplate[]
  fetchTemplates: (comId: any) => void
}

const Templates: React.FC<Props> = props => {
  const classes = useStyles()

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const { company, templates, fetchTemplates } = props

  const standardTemplates = templates?.filter(item => item.is_standard === 1)

  const customTemplates = templates?.filter(item => item.is_standard !== 1)

  const { t } = useTranslation()

  useEffect(() => {
    if (company) {
      fetchTemplates(company.id)
    }
  }, [company, fetchTemplates])

  const loader = () => {
    return (
      <div style={{ height: "100vh" }}>
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: "60vh" }}>
          <Grid item xs={3}>
            <CircularProgress />
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <div>
      {templates ? (
        <React.Fragment>
          <Paper className={classes.paper}>
            <div>
              {/* <Typography variant="h5" style={{ paddingLeft: 10, paddingRight: 10 }} gutterBottom >
                    {t("templates")}
                  </Typography> */}
              <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: 15, paddingRight: 15 }}>
                <div>
                  <Typography variant="h6" style={{ padding: "0px" }} gutterBottom>
                    {t("custom_templates")}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {t("custom_templates_subtext")}
                  </Typography>
                </div>
                <div>{customTemplates?.length > 0 ? <AddTemplate /> : null}</div>
              </div>
            </div>
            <div style={{}}>
              {customTemplates?.length > 0 ? (
                !matchesMobile ? (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("template_type")}</TableCell>
                          <TableCell>{t("template_title")}</TableCell>
                          <TableCell>{t("last_modified")}</TableCell>
                          <TableCell style={{ width: "10%" }}>{""}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {customTemplates?.map(item => {
                          return <SingleTemplate template={item} key={item.id} type="custom" />
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  customTemplates?.map(item => {
                    return <SingleTemplate template={item} key={item.id} type="custom" />
                  })
                )
              ) : (
                <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: 10, paddingRight: 10 }}>
                  <GoMail size={60} style={{ height: "75px", marginRight: "10px" }} />
                  <ListItemText
                    primary={<Typography variant="body1">{t("no_custom_templates_header")}</Typography>}
                    secondary={
                      <Typography variant="body2" style={{ color: "#797979" }}>
                        {t("no_custom_templates_body")}
                      </Typography>
                    }
                  />
                  <span style={{ margin: "10px", marginRight: "0px" }}>
                    <AddTemplate />
                  </span>
                </div>
              )}
            </div>
          </Paper>
          <Paper className={classes.paper} style={{ marginTop: "20px" }}>
            <div>
              <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                <Typography variant="h6" gutterBottom>
                  {t("standard_templates")}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {t("standard_templates_subtext")}
                </Typography>
              </div>
              {standardTemplates?.length > 0 ? (
                !matchesMobile ? (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("template_type")}</TableCell>
                          <TableCell>{t("template_title")}</TableCell>
                          <TableCell style={{ width: "10%" }}>{""}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {standardTemplates?.map(item => {
                          return <SingleTemplate template={item} key={item.id} type="standard" />
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  standardTemplates?.map(item => {
                    return <SingleTemplate template={item} key={item.id} type="standard" />
                  })
                )
              ) : (
                <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: 10, paddingRight: 10 }}>
                  <GoMail size={60} style={{ height: "75px", marginRight: "10px" }} />
                  <ListItemText
                    primary={<Typography variant="body1">{t("no_standard_templates_header")}</Typography>}
                    secondary={
                      <Typography variant="body2" style={{ color: "#797979" }}>
                        {t("no_standard_templates_body")}
                      </Typography>
                    }
                  />
                </div>
              )}
            </div>
          </Paper>
        </React.Fragment>
      ) : (
        loader()
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    templates: state.messagesReducer.templates,
    company: state.companyReducer.company,
    permissions: state.userReducer.permissions
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchTemplates: async (companyId: any) => dispatch(await messagesActions.fetchCompanyTemplates(companyId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Templates)
