import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 1)
  },
  paper: {
    padding: theme.spacing(2, 2),
    borderRadius: "7.5px !important"
  },
  sidepaper: {
    padding: theme.spacing(2, 2),
    borderRadius: "7.5px !important",
    width: "100%",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: "20px"
    }
  },
  toolbar: {
    borderRadius: "7.5px 7.5px 0px 0px !important"
  },
  halfFormControl: {
    width: "50%",
    padding: "5px",
    marginBottom: "5px"
  },
  formControl: {
    width: "100%",
    padding: "5px",
    marginBottom: "5px"
  },
  formcontrolLabel: {
    alignItems: "start",
    width: "100%",
    margin: "0px"
  },
  label: {
    margin: "10px",
    marginBottom: "0px"
  },
  titleText: {
    padding: "5px"
  }
}))

export default useStyles
