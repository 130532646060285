import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2, 2),
    marginTop: "20px"
  },
  card: {
    maxWidth: 90,
    transition: "0.5s"
  },
  // overlay: {
  //   "&:hover": {
  //     backgroundColor: "#000000",
  //     opacity: '0.4!important',
  //   },
  //   transition: '0.5s'
  // },
  subtitleText: {
    color: "#797979"
  },
  media: {
    height: 90,
    transition: "0.5s"
  },
  link: {
    textDecoration: "none"
  },
  photo: {
    width: "160px",
    height: "160px",
    border: "1px solid #B6BBC2",
    marginTop: "20px",
    transition: "0.5s",
    [theme.breakpoints.down("sm")]: {
      width: "135px",
      height: "135px"
    }
  },
  overlay: {
    "&:hover": {
      backgroundColor: "white",
      opacity: "0.8!important"
    },
    width: "100%",
    height: "100%",
    transition: "0.5s"
  },
  photosContainer: {}
}))

export default useStyles
