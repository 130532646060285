import React from "react"

import { useHistory } from "react-router"
import { useTranslation } from "react-i18next"

import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import CssBaseline from "@material-ui/core/CssBaseline"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import NavBar from "../../components/NavBar/NavBar"
import SideBar from "../../components/SideBar"
import TabPanel from "../../components/TabPanel"
import Copyrights from "../../components/Copyright"

import UserSideMenu from "../../components/MobileComponents/UserSideMenu"
import NotificationSettingsManagement from "../../components/NotificationSettings"
import AccountSettingsManagement from "../../components/AccountSettings"

interface Props { }

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  }
}

const UserAccountSettingsManagement: React.FC<Props> = props => {
  const { t } = useTranslation()

  const handleChange = (event: any, value: string) => {
    history.push(value)
  }

  const history = useHistory()
  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"))

  React.useEffect(() => {
    const getPageTitle = () => {
      switch (history.location.pathname) {
        case "/user/account/notifications":
          return t("account_settings_title")
        default:
          return t("account_settings_title")
      }
    }
    document.title = getPageTitle()
  }, [history.location.pathname, t])

  return (
    <div>
      <CssBaseline />
      <NavBar />
      <SideBar />
      {matchesMobile ? <UserSideMenu /> : null}
      <main className="content content--has-sidebar content--with-container" style={{ paddingTop: "62px" }}>
        <Typography variant="h5" className={"page-title"} gutterBottom>
          {t("user_account_settings")}
        </Typography>
        <Tabs
          value={history.location.pathname}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab label={t("user_account_settings")} {...a11yProps("/user/settings/account")} value={"/user/settings/account"} />
          <Tab label={t("notification_settings")} {...a11yProps("/user/settings/notifications")} value={"/user/settings/notifications"} />
        </Tabs>
        <Divider />
        <TabPanel value={history.location.pathname} index={"/user/settings/account"}>
          <AccountSettingsManagement />
        </TabPanel>
        <TabPanel value={history.location.pathname} index={"/user/settings/notifications"}>
          <NotificationSettingsManagement />
        </TabPanel>
      </main>
      <footer>
        <Copyrights />
      </footer>
      {/* <WhatsappContact /> */}
    </div>
  )
}


export default UserAccountSettingsManagement
