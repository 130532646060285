import { IActionType } from "../../types"
import { actionTypes } from "../actions/actionTypes"

interface IFlashMessagingModel {
  messages: any[] | null
}

const initialState: IFlashMessagingModel = {
  messages: []
}

const reducer = (state: IFlashMessagingModel = initialState, action: IActionType) => {
  switch (action.type) {
    case actionTypes.ADD_FLASH_MESSAGE:
      const newMessages = state.messages ? [...state.messages] : []
      newMessages.push(action.payload)
      return {
        ...state,
        messages: newMessages
      }
    case actionTypes.REMOVE_FLASH_MESSAGE: {
      const newMessages = state.messages ? state.messages.filter((item: any) => item.id !== action.payload) : state.messages
      return {
        ...state,
        messages: newMessages
      }
    }
    default:
      break
  }
  return state
}

export default reducer
