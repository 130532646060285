import React, { useState } from "react"
import moment from "moment"

import { makeStyles } from "@material-ui/core"

import Menu from "@material-ui/core/Menu"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import MenuItem from "@material-ui/core/MenuItem"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import IconButton from "@material-ui/core/IconButton"

import OptionsIcon from "@material-ui/icons/MoreHoriz"
import Edituser from "../EditUser"
import { useTranslation } from "react-i18next"
import { ICompanyUser } from "../../../../types/company"

const useStyles = makeStyles(theme => ({
  tableCell: {
    borderBottom: "none"
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#F5F6F7 !important"
    }
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: "5px"
  }
}))

interface Props {
  item: ICompanyUser
  handleDelete: (id: any) => void
}

interface LongMenuProps {
  initialValues: any
  editingStatus: boolean
  handleCancel: () => void
  handleEditClick: () => void
  handleDeleteClick: () => void
}

const LongMenu: React.FC<LongMenuProps> = props => {
  // const classes = useStyles();

  const options = ["Edit", "Delete"]
  const ITEM_HEIGHT = 48
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleItemClicked = (value: any) => {
    setAnchorEl(null)
    if (value === options[0]) {
      props.handleEditClick()
    } else {
      props.handleDeleteClick()
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} style={{ padding: "3px" }}>
        <OptionsIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200
          }
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option}
            onClick={() => {
              handleItemClicked(option)
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      {props.editingStatus ? (
        <Edituser editingStatus={props.editingStatus} initialValues={{ ...props.initialValues }} handleClose={props.handleCancel} user={props.initialValues} />
      ) : null}
    </div>
  )
}

const TableItem: React.FC<Props> = props => {
  const { t } = useTranslation()

  const classes = useStyles()
  let roles = ""

  if (props.item.roles) {
    if (props.item.roles.length > 0) {
      ;[...props.item.roles].forEach(item => {
        if (roles.length === 0) {
          roles = roles + t(item)
        } else {
          roles = roles + ", " + t(item)
        }
      })
      // roles = [...props.item.roles].join(", ")
    }
  }

  // const [hoveredOver, setHoveredOver] = useState(false);
  const [editingStatus, setEditingStatus] = useState(false)

  // const handleMouseOver = () => {
  //   setHoveredOver(true);
  // }

  // const handleMouseLeave = () => {
  //   setHoveredOver(false);
  // }

  const handleDelete = () => {
    props.handleDelete(props.item.id)
  }

  const handleEditClick = () => {
    setEditingStatus(!editingStatus)
  }

  const handleEditCancel = () => {
    setEditingStatus(false)
  }

  const extractInitials = (val: string) => {
    const wordsArr = val.split(" ")
    let initials = ""
    wordsArr.forEach(item => {
      if (item.length > 0) {
        initials = initials + item[0]
      }
    })
    return initials
  }

  return (
    <TableRow
      style={{ borderBottom: "none" }}
      className={classes.tableRow}
      // onMouseOver={handleMouseOver}
      // onMouseLeave={handleMouseLeave}
    >
      <TableCell className={classes.tableCell} style={{ display: "flex", alignItems: "center" }}>
        <Avatar src={props.item.avatar_url || ""} className={classes.avatar} alt="">
          {extractInitials(props.item.name)}
        </Avatar>
        {props.item.name}
      </TableCell>
      {/* <TableCell className={classes.tableCell}>{props.item.title}</TableCell> */}
      <TableCell className={classes.tableCell}>
        <Button variant="text" color="secondary">
          {props.item.email}
        </Button>
      </TableCell>
      <TableCell className={classes.tableCell}>{roles}</TableCell>
      <TableCell className={classes.tableCell}>{moment(props.item.last_login_at).format("DD-MMM-YYYY")}</TableCell>
      <TableCell style={{ width: "15px" }} className={classes.tableCell}>
        <LongMenu handleEditClick={handleEditClick} handleDeleteClick={handleDelete} editingStatus={editingStatus} initialValues={props.item} handleCancel={handleEditCancel} />
      </TableCell>
    </TableRow>
  )
}

export default TableItem
