import React from "react"
import axios from "../../api/iskibris/iskibris"
import Pusher from "pusher-js"
import Cookies from "js-cookie"
import { Dispatch } from "redux"
import { connect, useSelector } from "react-redux"
import { Redirect, useHistory } from "react-router"
import { useTranslation } from "react-i18next"

import { IActionType } from "../../types"
import { ICompany, ICompanyUserInvitation } from "../../types/company"
import { authActions } from "../../store/actions/authentication"

import Grid from "@material-ui/core/Grid"
import Phone from "@material-ui/icons/Phone"
import Button from "@material-ui/core/Button"
import Settings from "@material-ui/icons/Settings"
import InfoOutlined from "@material-ui/icons/InfoOutlined"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import CssBaseline from "@material-ui/core/CssBaseline"
import Typography from "@material-ui/core/Typography"

import useStyles from "./styles"
import DashboardCard from "../../components/DashboardCard"
// import RedirectToEmployer from '../../components/RedirectToEmployer';

import CompanyPlaceholder from "../../assets/images/placeholder-company.png"
import NavBar from "../../components/NavBar/NavBar"

import { IoBriefcaseOutline, IoPeopleOutline } from "react-icons/io5"

import Can from "../../utils/AccessControl/Can"
import UserSideMenu from "../../components/MobileComponents/UserSideMenu"
import { Alert } from "@material-ui/lab"
import Link from "@material-ui/core/Link"
import api from "../../api/iskibris/iskibris"
import NewFeatureWarning from "../../components/NewFeatureWarning"
import BillingSettingsRequiredWarning from "../../components/Settings/BillingSettingsRequiredWarning"

interface Props {
  unauthenticate: () => void
  companies: ICompany[]
  company: ICompany
}

const options = {
  broadcaster: "pusher",
  key: process.env.REACT_APP_PUSHER_KEY || "",
  cluster: "eu",
  forceTLS: true,
  authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: `Bearer ${Cookies.get("auth_token")}`,
      Accept: "application/json"
    }
  }
}

const Dashboard: React.FC<Props> = props => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const [applicantsStats, setApplicantStats] = React.useState({
    new: "",
    unrated: "",
    active: ""
  })

  const profile = useSelector((state: any) => state.userReducer.profile)

  const [companyUserInvitations, setCompanyUserInvitations] = React.useState<ICompanyUserInvitation[] | null>(null)

  const [hideNewFeatureWarning, setHideFeatureWarning] = React.useState<any>(Cookies.get("hide_new_feature_warning"))

  const [hideBillingSettingsWarning, setHideBillingSettingsWarning] = React.useState<any>(Cookies.get("hide_billing_settings_warning"))

  // const subscriptions = useSelector((state: any) => state.billingReducer.subscriptions)

  // const atsSubscriptions = subscriptions?.filter((item: any) => item.offer_handle === "off-ats-beta")

  React.useEffect(() => {
    document.title = t("dashboard_title")
  })

  const { company } = props

  const handleNavigation = (route: string) => {
    history.push(route)
  }

  const authToken = Cookies.get("auth_token")

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"))

  React.useEffect(() => {
    if (profile) {
      api
        .get(`/api/hiring/v2/company-user-invitations/user/pending`)
        .then(response => {
          setCompanyUserInvitations(response.data)
        })
        .catch(error => {})
    }
  }, [profile])

  React.useEffect(() => {
    let pusher: Pusher | null
    let channelStr: any

    if (company && authToken) {
      axios
        .get(`/api/hiring/v2/companies/${company.id}/stats`)
        .then(response => {
          if (response.status === 200) {
            const activeNumber = response.data.active
            const unratedNumber = response.data.pending
            const newAppNumber = response.data.new

            const newApplicantStats = {
              new: newAppNumber,
              unrated: unratedNumber,
              active: activeNumber
            }

            // This if is to prevent multiple calls useEffect calls since state change is async, it keeps calling
            // everytime the dependeny changes (applicantsStats)
            if (JSON.stringify(newApplicantStats) !== JSON.stringify(applicantsStats)) {
              setApplicantStats(newApplicantStats)
            }
          } else {
          }
        })
        .catch(err => {})

      pusher = new Pusher(options.key, {
        cluster: options.cluster,
        forceTLS: options.forceTLS,
        authEndpoint: options.authEndpoint,
        auth: {
          ...options.auth,
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json"
          }
        }
      })

      channelStr = `private-companies.${company.id}.applications`

      let aChannel = pusher.subscribe(channelStr)
      aChannel.bind("new-application-submitted", function (data: any) {
        setApplicantStats({
          active: applicantsStats.active + 1,
          new: applicantsStats.new + 1,
          unrated: applicantsStats.unrated + 1
        })
      })
    }

    return () => {
      if (company && authToken && !!pusher && !!channelStr) {
        pusher.disconnect()
      }
    }
  }, [company, authToken, applicantsStats])

  const handleClose = () => {
    setHideFeatureWarning(true)
    Cookies.set("hide_new_feature_warning", "1")
  }
  const handleBillingSettingsClose = () => {
    setHideBillingSettingsWarning(true)
    Cookies.set("hide_billing_settings_warning", "1")
  }


  const getIsBillingSettingsRequired = () => {
    // return true;
    return !props.company?.invoice_email ?? !props.company?.registration_number ?? !props.company?.invoice_contact_first_name ?? !props.company?.invoice_contact_first_name
  }

  return (
    <div>
      <Can I="use-hiring-panel" a="all">
        <CssBaseline />
        <NavBar />
        {matchesMobile ? <UserSideMenu /> : null}
        <main className="content content--with-container" style={{ paddingTop: "70px" }}>
          {companyUserInvitations &&
            companyUserInvitations.length > 0 &&
            companyUserInvitations.map(item => {
              return (
                <Alert
                  severity="info"
                  icon={<InfoOutlined />}
                  closeText={t("dismiss")}
                  action={<Link href={`/invitation/${item.id}`}>{t("view_invitation")}</Link>}
                  style={{ borderRadius: "5px", marginBottom: "10px", alignItems: "center", fontSize: "1rem" }}
                >
                  {t("company_user_invitation_alert", { company_name: item.company?.name })}
                </Alert>
              )
            })}
          <Can not I="use-ats-system" a="all">
            {
              !hideNewFeatureWarning && (
                <NewFeatureWarning onClose={handleClose} />
              )
            }
          </Can>
          {/* {
            (!atsSubscriptions || atsSubscriptions?.length === 0) && (
              !hideNewFeatureWarning && (
                <NewFeatureWarning onClose={handleClose} />
              )
            )
          } */}
          {
            getIsBillingSettingsRequired() && !hideBillingSettingsWarning ? (
              <BillingSettingsRequiredWarning
                onAddBillingSettingsClick={() => {
                  history.push("/billing/settings")
                }}
                onClose={handleBillingSettingsClose}
              />
            ) : null
          }
          <Grid container alignItems="stretch">
            <DashboardCard
              icon={<IoBriefcaseOutline size={35} className={classes.cardIcon + " dashboard-tile__icon"} />}
              heading={t("jobs")}
              description={t("jobs_card_message")}
              clicked={() => {
                return handleNavigation("/jobs")
              }}
            />
            <DashboardCard
              icon={<IoPeopleOutline size={35} className={classes.cardIcon + " dashboard-tile__icon"} />}
              heading={t("candidates")}
              description={!matchesMobile ? t("candidates_card_message") : t("candidate_card_message_mobile")}
              clicked={() => {
                return handleNavigation("/applicants")
              }}
              footer={
                <Typography variant="button" style={{ marginTop: "10px" }}>
                  <Grid container alignItems="stretch">
                    <Grid item xs={4} style={{ paddingRight: "10px" }}>
                      <div style={{ color: "#EA148D" }} className={"dashboard-tile__counter"}>
                        {applicantsStats.new || 0}
                      </div>
                      <div style={{ color: "#EA148D" }} className={"dashboard-tile__counter-label"}>
                        {t("new")}
                      </div>
                    </Grid>
                    <Grid item xs={4} style={{ paddingRight: "10px" }}>
                      <div className={"dashboard-tile__counter"}>{applicantsStats.active || 0}</div>
                      <div className={"dashboard-tile__counter-label"}>{t("active")}</div>
                    </Grid>
                    <Grid item xs={4} style={{ paddingRight: "10px" }}>
                      <div className={"dashboard-tile__counter"}>{applicantsStats.unrated || 0}</div>
                      <div className={"dashboard-tile__counter-label"}>{t("unrated")}</div>
                    </Grid>
                  </Grid>
                </Typography>
              }
            />
            <DashboardCard
              icon={<img src={props.company.logo || CompanyPlaceholder} alt={props.company.name} className={classes.companyLogo + " dahboard-tile__icon"} />}
              heading={t("employer_profile")}
              description={t("employer_profile_card_message")}
              clicked={() => {
                return handleNavigation("/employer/profile")
              }}
            />
          </Grid>
          <div>
            <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
              <Grid item xs={12} sm={4} md={4} lg={3} style={{ padding: "5px" }}>
                <Button
                  variant="outlined"
                  // target="_blank"
                  onClick={() => {
                    history.push("/billing")
                  }}
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    alignSelf: "center",
                    padding: "10px"
                  }}
                >
                  <KeyboardArrowLeft style={{ marginRight: "10px" }} />
                  {t("billing")}
                </Button>
              </Grid>
              {company.id ? (
                <Grid item xs={12} sm={4} md={4} lg={3} style={{ padding: "5px" }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      history.push("/settings")
                    }}
                    style={{
                      width: "100%",
                      backgroundColor: "white",
                      alignSelf: "center",
                      padding: "10px"
                    }}
                  >
                    <Settings style={{ marginRight: "10px" }} />
                    {t("settings")}
                  </Button>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={4} md={4} lg={3} style={{ padding: "5px" }}>
                <Button
                  variant="outlined"
                  href={`${process.env.REACT_APP_JOB_SEEKER_BASE_URL}/contact`}
                  target="_blank"
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    alignSelf: "center",
                    padding: "10px"
                  }}
                >
                  <Phone style={{ marginRight: "10px" }} />
                  {t("contact_support")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </main>
      </Can>
      <Can not I="use-hiring-panel" a="all">
        <Redirect to="/employers/signup" />
      </Can>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  companies: state.companyReducer.companies,
  company: state.companyReducer.company
})

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => ({
  unauthenticate: () => dispatch(authActions.unauthenticate())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
