import React from "react"
import { useTranslation } from "react-i18next"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

interface Props {
  locale: string
  required?: boolean
  value: string | undefined
  handleChange: (value: string, locale: string) => void
}

const modules = {
  toolbar: [[{ size: [] }], ["bold", "italic", "underline", "blockquote"], [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }], ["link"]],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
}

const formats = ["size", "bold", "italic", "underline", "blockquote", "list", "bullet", "indent", "link"]

const TextEditor: React.FC<Props> = props => {
  const { t } = useTranslation()

  const handleChange = (content: string) => {
    props.handleChange(`<${props.locale}>${content}</${props.locale}>`, props.locale)
  }

  return (
    <div className="app">
      <ReactQuill
        theme={"snow"}
        formats={formats}
        modules={modules}
        placeholder={t("enter_information_here")}
        value={props.value || ""}
        onChange={handleChange}
        bounds={".app"}
        style={{ marginBottom: "10px" }}
      />
    </div>
  )
}

export default TextEditor
