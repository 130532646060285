import { actionTypes } from "../actions/actionTypes"
import { IActionType } from "../../types"
import { IJobsReducerModel } from "../../types/reducers"

const initialState: IJobsReducerModel = {
  searchResults: null,
  archivedJobs: null,
  duplicateState: 0,
  duplicateJob: null,
  singleJob: null,
  jobs: null,
  errors: {},
  form: {
    title: "",
    body: "",
    company_id: null,
    education_id: null,
    startDate: null,
    endDate: null,
    employment_type_id: null,
    is_good_for_new_graduates: 0,
    is_good_for_internationals: 0,
    is_cv_required: 0,
    is_sponsored: 0,
    is_good_for_students: 0,
    is_good_for_remote: 0,
    city_id: "",
    place_id: "",
    job_title_id: "",
    address: "",
    maximum_salary: "",
    minimum_salary: "",
    currency: "TRY",
    show_salary_information: 1,
    bonus: 0
  }
}

const reducer = (state: IJobsReducerModel = initialState, action: IActionType) => {
  switch (action.type) {
    case actionTypes.ADD_COMPANY_JOBS: {
      return {
        ...state,
        jobs: [...action.payload]
      }
    }
    case actionTypes.ADD_ARCHIVED_JOBS: {
      return {
        ...state,
        archivedJobs: [...action.payload]
      }
    }
    case actionTypes.ADD_JOB_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: [...action.payload]
      }
    }
    case actionTypes.UPDATE_SINGLE_JOB: {
      const newComJobs = state.jobs ? [...state.jobs] : state.jobs
      if (newComJobs) {
        const ndx = newComJobs.findIndex(item => item.id === action.payload.id)
        newComJobs[ndx] = { ...action.payload }
      }
      return {
        ...state,
        jobs: newComJobs
      }
    }
    case actionTypes.EDIT_FORM_CONTENT: {
      const newForm = {
        ...state.form,
        ...action.payload
      }
      return {
        ...state,
        form: newForm
      }
    }
    case actionTypes.FETCH_JOB_TO_EDIT: {
      let job = action.payload
      let newForm = state.form
      if (job) {
        newForm = {
          ...job,
          job_title_id: job.job_title,
          company_id: job.company
        }
      }
      return {
        ...state,
        singleJob: { ...job },
        form: newForm
      }
    }
    case actionTypes.CLEAR_JOB_FORM: {
      return {
        ...state,
        form: { ...initialState.form }
      }
    }
    case actionTypes.ADD_JOBS_FETCH_ERROR: {
      return {
        ...state,
        errors: { ...action.payload }
      }
    }
    case actionTypes.CHANGE_DUPLICATING_STATE: {
      return {
        ...state,
        duplicateState: action.payload,
        form: { ...initialState.form }
      }
    }
    case actionTypes.CLEAR_JOB_DUPLICATE_STATE: {
      return {
        ...state,
        duplicateState: initialState.duplicateState,
        duplicateJob: initialState.duplicateJob
      }
    }
    case actionTypes.DUPLICATE_JOB: {
      let job = action.payload
      let newForm = {
        title: job.title,
        body: job.body,
        company_id: job.company_id,
        education_id: job.education_id,
        startDate: job.startDate,
        endDate: job.endDate,
        employment_type_id: job.employment_type_id,
        is_good_for_new_graduates: job.is_good_for_new_graduates,
        is_good_for_internationals: job.is_good_for_internationals,
        is_cv_required: job.is_cv_required,
        is_sponsored: job.is_sponsored,
        is_good_for_remote: job.is_good_for_remote,
        city_id: job.city_id,
        place_id: job.place_id,
        salary_range: job.salary_range,
        is_good_for_students: job.is_good_for_students,
        job_title_id: job.job_title,
        address: job.address,
        maximum_salary: job.maximum_salary,
        minimum_salary: job.minimum_salary,
        currency: job.currency,
        show_salary_information: job.show_salary_information !== null ? job.show_salary_information : 1,
        bonus: job.bonus
      }
      return {
        ...state,
        form: newForm,
        duplicateJob: { ...job }
      }
    }
    case actionTypes.DELETE_JOB: {
      const job = state.jobs?.find(item => item.id === action.payload)
      let newJobs = state.jobs ? [...state.jobs.filter(value => value.id !== action.payload)] : state.jobs
      let newArchives = state.archivedJobs ? [...state.archivedJobs, { ...job, deleted_at: new Date() }] : state.archivedJobs
      return {
        ...state,
        jobs: newJobs,
        archivedJobs: newArchives
      }
    }
    case actionTypes.ADD_JOBS_FETCH_NOT_FOUND_ERROR: {
      return {
        ...state,
        errors: {
          not_found: true
        }
      }
    }
    case actionTypes.CLEAR_JOB_ERRORS: {
      return {
        ...state,
        errors: initialState.errors
      }
    }
    default:
      break
  }
  return state
}

export default reducer
