import React, { useState } from "react"

import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { ICompany } from "../../../../types/company"
import imageFile2Base64 from "../../../../utils/imageFile2Base64"

import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"

import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"

import { IActionType } from "../../../../types"
import { companyActions } from "../../../../store/actions/company"

import useStyles from "./styles"
import CoverPhotoEditor from "./CoverPhotoEditor"

interface Props {
  company: ICompany
  handleCoverPhotoUpdate?: (logoUrl: string) => void
  removeCompanyCoverPhoto?: (id: any) => void
}

const CompanyCoverPhoto: React.FC<Props> = props => {
  const classes = useStyles()

  const company = props.company

  const [onMouseHover, setOnMouseHover] = useState(false)
  const [coverPhotoModalOpen, setCoverPhotoModalOpen] = useState(false)
  const [newCoverPhoto, setNewCoverPhoto] = useState<any>(null)

  const { t } = useTranslation()

  const handleMouseHover = () => {
    setOnMouseHover(true)
  }

  const handleMouseLeave = () => {
    setOnMouseHover(false)
  }

  const handleCoverPhotoModalOpen = () => {
    setCoverPhotoModalOpen(true)
  }

  const handleCoverPhotoModalClose = () => {
    setCoverPhotoModalOpen(false)
  }

  const handleCoverPhotoDelete = () => {
    if (props.removeCompanyCoverPhoto) {
      props.removeCompanyCoverPhoto(company?.id)
    }
  }

  const handleNewImage = (e: any) => {
    const file = e.target.files[0]
    if (file) {
      imageFile2Base64(file).then(response => {
        setNewCoverPhoto(response)
        handleCoverPhotoModalOpen()
      })
    }
  }

  return (
    <div>
      {props.company?.cover_photo_url ? (
        <CardMedia onMouseOver={handleMouseHover} onMouseLeave={handleMouseLeave} image={props.company.cover_photo_url || ""} title="Cover Photo" className={classes.media}>
          <div className={classes.logoOverlayContainer}>
            {onMouseHover ? (
              <div className={classes.logoOverlay}>
                <IconButton onClick={handleCoverPhotoModalOpen} className={classes.logoIconButton} title="Cover Photo">
                  <EditIcon color="primary" />
                </IconButton>
                <IconButton onClick={handleCoverPhotoDelete} className={classes.logoIconButton} title="Delete Cover Photo">
                  <DeleteIcon color="error" />
                </IconButton>
              </div>
            ) : null}
          </div>
        </CardMedia>
      ) : (
        <div>
          <input accept="image/*" name="new-cover-photo" style={{ display: "none" }} id="new-cover-photo-input" type="file" onChange={handleNewImage} />
          <label className={classes.addLogoCard} htmlFor="new-cover-photo-input">
            <div style={{ textAlign: "center" }}>
              <Typography>{t("add_cover_photo")}</Typography>
              <AddIcon color="secondary" />
            </div>
          </label>
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={coverPhotoModalOpen}
        onClose={handleCoverPhotoModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={coverPhotoModalOpen}>
          <div className={classes.modalPaper}>
            <CoverPhotoEditor
              company={props.company}
              handleCoverPhotoModalClose={handleCoverPhotoModalClose}
              newCoverPhoto={newCoverPhoto}
              handleUpdateCompanyCoverPhoto={props.handleCoverPhotoUpdate}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    handleCoverPhotoUpdate: (logo: string) => dispatch(companyActions.updateCompanyCoverPhoto(logo)),
    removeCompanyCoverPhoto: async (id: number) => dispatch(await companyActions.removeCompanyCoverPhoto(id))
  }
}

export default connect(null, mapDispatchToProps)(CompanyCoverPhoto)
