import React from "react"

import { ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core"
import LongMenu from "../../LongMenu"

interface SingleFolderProps {
  folder: any
  menuOptions: any[]
}


const SingleFolder: React.FC<SingleFolderProps> = ({folder, menuOptions}) => {

  return (
    <ListItem>
      <ListItemText id={`checkbox-list-label-select-all`} primary={folder?.title} />
      <ListItemSecondaryAction>
        <LongMenu options={menuOptions}/>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default SingleFolder