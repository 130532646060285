import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "@material-ui/core"
import parseStringWithLocales from "../../utils/parseStringLocales"
import TextEditor from "./TextEditor"

interface Props {
  handleChange: (value: string) => void
  required?: boolean
  value: string
}

const MultiLanguageTextEditor: React.FC<Props> = props => {
  const { t, i18n } = useTranslation()

  const locale = i18n.language

  const [languages, setLanguages] = useState(
    parseStringWithLocales(props.value).tr && parseStringWithLocales(props.value).en ? (i18n.language === "tr" ? ["tr", "en"] : ["en", "tr"]) : [locale]
  )

  const addAnother = (locale: any) => {
    const newLng = [...languages]
    newLng.push(locale)
    setLanguages(newLng)
  }

  const handleChange = (value: any, locale: any) => {
    let internationalizedString
    const handleChangeForLocaleOnly = (value: any, locale: any) => {
      let bodyEnValue = parseStringWithLocales(props.value).en
      let bodyTrValue = parseStringWithLocales(props.value).tr
      if (locale === "en") {
        bodyEnValue = value
        bodyTrValue = `<tr>${bodyTrValue}</tr>`
      } else {
        bodyTrValue = value
        bodyEnValue = `<en>${bodyEnValue}</en>`
      }
      return `${bodyTrValue}${bodyEnValue}`
    }
    internationalizedString = handleChangeForLocaleOnly(value, locale)
    props.handleChange(internationalizedString)
  }

  return (
    <div>
      {languages.map(lang => {
        const val: any = parseStringWithLocales(props.value)

        return <TextEditor key={lang} locale={lang} required={true} value={`${val[lang]}`} handleChange={handleChange} />
      })}
      {languages.length < 2 ? (
        <Button
          variant="text"
          onClick={() => {
            addAnother(locale === "en" ? "tr" : "en")
          }}
          style={{ display: "block", marginTop: "5px" }}
        >
          {t(`add_${locale === "en" ? "tr" : "en"}_translation`)}
        </Button>
      ) : (
        <Button
          variant="text"
          onClick={() => {
            setLanguages([i18n.language])
          }}
          style={{ display: "block", marginTop: "5px" }}
        >
          {t(`hide_${locale === "en" ? "tr" : "en"}_translation`)}
        </Button>
      )}
    </div>
  )
}

export default MultiLanguageTextEditor
