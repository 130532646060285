import React from "react"
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer"
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer"
import useStyles from "./styles"

const fileUrlMiddleware = url => {
  let newUrl = url
  if (url) {
    const strList = url.split("://")
    if (strList.length === 2) {
      newUrl = "https://" + strList[1]
    } else if (strList.length === 1) {
      newUrl = "https://" + strList[0]
    }
  }
  return newUrl
}

const CVViewer = props => {
  const classes = useStyles()

  const fileUrl = fileUrlMiddleware(props.resume_url)

  return (
    <div key={Math.random()} style={{ width: "100%" }}>
      <DocViewer
        key={Math.random()}
        className={classes.docViewer}
        documents={[{ uri: fileUrl }]}
        pluginRenderers={DocViewerRenderers}
        config={{ 
          header: { disableFileName: true },
          pdfVerticalScrollByDefault: true,
        }}
      />
    </div>
  )
}

export default CVViewer
