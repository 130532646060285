import React from "react"
import { ListItemAvatar, Typography, Avatar } from "@material-ui/core"
import MaterialAvatar from "@material-ui/core/Avatar"
// import Avatar from "react-avatar";
import Moment from "react-moment"
import Parser from "html-react-parser"
import { IMessageModel } from "../../../../../types/messages"

interface Props {
  message: IMessageModel
}

const SingleMessage: React.FC<Props> = props => {
  const extractInitials = (name: any) => {
    let result = ""

    const nameLst = `${name}`.trim().split(" ")

    nameLst.forEach(item => {
      if (item?.length > 0) {
        result = result + item[0]
        return item
      }
    })

    return result
  }

  return (
    <div style={{ marginBottom: "1rem", paddingTop: "1rem", width: "100%", display: "flex" }}>
      <div style={{ display: "flex", width: "10%", justifyContent: "center" }}>
        <ListItemAvatar
          style={{
            // width: "25px",
            // height: "25px",
            minWidth: "0"
          }}
        >
          <MaterialAvatar style={{}}>
            <Avatar style={{ backgroundColor: "#221133" }}>{extractInitials(props.message.sender_name)}</Avatar>
          </MaterialAvatar>
        </ListItemAvatar>
      </div>
      <div style={{ width: "85%", display: "flex", flexFlow: "column" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1">{props.message.sender_name}</Typography>
          </div>
          <div>
            <Typography variant="body2">
              <Moment fromNow>{props.message.created_at}</Moment>
            </Typography>
          </div>
        </div>
        <div style={{ border: "1px rgba(0,0,0, 0.2) solid", borderRadius: "0.2rem", background: "#f5f6f7", padding: "1rem", marginTop: "1rem" }}>{Parser(props.message.body)}</div>
      </div>
    </div>
  )
}

export default SingleMessage
