import { actionTypes } from "../actions/actionTypes"
import { IActionType } from "../../types"
import { IUser } from "../../types/user"

interface IPermissionsReducerModel {
  profile?: IUser | null
  errors?: any
}

const initialState: IPermissionsReducerModel = {
  profile: null,
  errors: null
}

const reducer = (state: IPermissionsReducerModel = initialState, action: IActionType) => {
  switch (action.type) {
    case actionTypes.ADD_PROFILE:
      const profile = action.payload ? { ...action.payload } : {}
      return {
        ...state,
        profile: profile
      }
    case actionTypes.ADD_PROFILE_ERROR: {
      return {
        ...state,
        errors: action.payload
      }
    }
    default:
      break
  }
  return state
}

export default reducer
