export const tr = {
  translations: {
    page_not_found: "Sayfa bulunamadı",
    page_not_found_subtext: "Oooops! Verdiğimiz rahatsızlıktan dolayı özür dileriz. Görünüşe göre aradığınız şeyi bulamadık. Bağlantınız kopmuş olabilir veya var olmayabilir.",
    go_to_home: "Ana sayfaya git",
    for_employers: "İşverenler için",
    greeting_id_0: "Günaydın",
    greeting_id_1: "Tünaydın",
    greeting_id_2: "İyi akşamlar",
    language_label_tr: "TR",
    language_label_en: "EN",
    login_with_facebook: "Facebook ile giriş yap",
    login_with_google: "Google ile Giriş yap",
    signup_with_facebook: "Facebook ile kayıt ol",
    signup_with_google: "Google ile kayıt ol",
    email_address: "E-posta",
    password: "Şifre",
    sign_in: "Oturum aç",
    forgot_password: "Şifremi unuttum",
    do_not_have_account: "Hesabım yok. Yeni bir hesap aç",
    back_to_signin: "Zaten hesabınız var mı? Oturum aç",
    reset_password_instructions: "Şifrenizi sıfırlamak için e-posta adresinizi yazın ve şifreyi sıfırla'yı tıklayın. E-posta adresinize bir şifre sıfırlama bağlantısı gönderilecektir.",
    return_to_sign_in: "Oturum açmaya dön",
    submit: "Gönder",
    forgot_password_fail: "Başarısız! E-postanızı onaylayın ve tekrar deneyin.",
    forgot_password_success: "Parola sıfırlama bağlantınız e-postanıza gönderildi. Şifrenizi sıfırlamak için bağlantıya tıklayın.",
    or: "VEYA",
    sign_up: "Kayıt ol",
    name: "İsim",
    phone_number: "Telefon Numarası",
    login_error: "Geçersiz e-posta veya şifre",
    click_here_forgot_password: "Parolanızı mı unuttunuz? Sıfırlamak için burayı tıklayın.",

    fullname_required: "İsim gerekli",
    fullname_maxLength: "İsim çok uzun",
    fullname_minLength: "İsim çok kısa",
    password_required: "Şifre gerekli",
    password_maxLength: "Şifre çok uzun",
    password_minLength: "Şifre çok kısa",
    phone_number_required: "Telefon numarası gerekli",
    phone_number_maxLength: "Telefon numarası çok uzun",
    phone_number_minLength: "Telefon numarası çok kısa",
    phone_number_invalid: "Geçersiz telefon numarası",
    email_required: "E-posta gerekli",
    email_maxLength: "E-posta çok uzun",
    email_minLength: "E-posta çok kısa",
    email_invalid: "Geçersiz E-posta",

    create_company_header: "Yeni şirket ekle",
    create_company_text: "Münhal ekleyip başvuruları yönetebilmek adına, bir şirket oluşturmak için aşağıyı tıklayınız.",

    no_company_managed_text:
      "Şu anda herhangi bir şirketi yönetmiyorsunuz. Deneme hesabı sayfasına gitmek için aşağıyı tıklayınız ve şirketiniz etkinleştirerek, münhalleri ve başvuruları yönetmeye başlayınız.",
    click_here_to_create_trial_account: "İşverenlere git",
    signin_with_another_account: "Başka bir hesapla giriş yapın",

    // Dashboard Screen
    jobs: "Münhaller",
    jobs_card_message: "İş Kıbrıs'taki açık iş ilanlarınızı görüntüleyin, düzenleyin ve yönetin.",
    candidates: "Adaylar",
    candidates_card_message: "Başvuru sahiplerini inceleyin ve işe alma sürecinizde nerede olduklarını takip edin.",
    candidate_card_message_mobile: "Başvuru sahiplerini inceleyin ve takip edin.",
    new: "Yeni",
    active: "Aktif",
    unrated: "Not verilmemiş",
    employer_profile: "İşveren profili",
    employer_profile_card_message: "Adaylara neden sizin için çalışmaları gerektiğini göstermek için şirket bilgilerinizi ve hikayenizi güncelleyin.",
    language: "Dil",
    settings: "Ayarlar",
    contact_support: "Destek ekibiyle iletişime geç",
    view_as_job_seeker: "İş arayan olarak görüntüle",
    logout: "Çıkış yap",
    create_company: "Şirket oluşturun",
    search_for_more: "Arama yapmak için yazın",

    // Sidebar
    home: "Ana Sayfa",
    employer_tools: "Yardımcı araçlar",

    // Jobs
    view_jobs_manage: "İş Kıbrıs'ta yayınladığınız işleri görüntüleyin, düzenleyin ve yönetin.",
    job_slots: "Münhaller",
    manage_your_jobs: "İşlerinizi yönetin",
    all_jobs: "Tüm işler",
    published: "Yayındaki İşler",
    inactive_jobs: "Başvuruya Kapalı",
    job_title: "Başlık",
    job_location: "İş konumu",
    jobs_per_page: "Sayfa başına",
    search: "Arama",
    actions: "Seçenekler",
    edit: "Düzenle",
    duplicate: "Kopya Oluştur",
    archive: "Arşive at",
    create_job: "Yeni  İş Oluştur",
    edit_job: "İşi düzenle",
    create_new_job: "Yeni İş Oluştur",
    duplicate_job: "Münhali çoğalt",
    select_previous_job: "Mevcut münhali seçin",
    job_information: "Temel bilgiler",
    category: "Kategori",
    employment_type: "İstihdam Tipi",
    create_or_duplicate: "Yeni veya yinelenen iş oluştur",
    previous_job_placeholder: "İş ünvanı, yeri",
    increase_candidate_appeal: "İlan edilen iş ilanlarınızın etkinliğini artırarak, aday başvuru sayınızı artırın.",
    job_details: "İş detayları",
    improving_candidate_appeal: "Aday başvurunuzu iyileştirmek kolaydır. Münhalinize bir maaş aralığı ekleyip eklemediği, sunduğunuz avantajları veya diğer herhangi bir katkıyı açıklayan her adım, Aday Temyizinizi ve daha bilgili adayları çekme ihtimalinizi artıracaktır.",
    job_description: "İş tanımı",
    salary_range: "Maaş aralığı",
    resume_required: "Özgeçmiş gerekli",
    job_description_text: "İyi bir iş tanımı, adayların üç soruyu yanıtlamasına yardımcı olur:",
    job_description_text_1: "1. İş nedir? Rol sorumlulukları ve bunların kuruluşunuzun geri kalanına nasıl uyduğu.",
    job_description_text_2: "2. Neden istemeliler? Heyecan verici veya zorlu projeler, şirketiniz için çalışmanın nasıl bir şey olduğu ve mevcut tüm avantajlar.",
    job_description_text_3: "3. Nitelikli mi? Başarı için gerekli beceriler ve deneyimler.",
    employment_type_id_0: "Tam zamanlı",
    employment_type_id_1: "Yarı zamanlı",
    employment_type_id_2: "Serbest çalışan",
    employment_type_id_3: "Staj",
    employment_type_id_4: "Gönüllü",
    education_level: "Eğitim seviyesi",
    education_level_id_: "",
    education_level_id_0: "Üniversite",
    education_level_id_1: "Lise",
    education_level_id_2: "Meslek Yüksekokulu",
    is_good_graduates: "Mezunlar için uygundur",
    is_cv_required: "CV gerekli mi?",
    is_good_students: "Öğrenciler için uygun mudur?",
    is_good_internationals: "Yabancılar için uygun mudur?",
    is_good_remote: "Uzaktan çalışma için uygun mudur?",
    job_location_text_1: "Sokak adresi ve posta kodu eklemek münhallerinize daha fazla başvuru almanıza yardımcı olabilir.",
    job_location_text_2: "İş ilanınızın adaylarla alaka düzeyini artırmak için bir şehir ve eyalet belirtilmesini gerektirir.",
    street_address: "Sokak adresi",
    city_state: "Şehir",
    job_posting_dates: "İş ilanı tarihleri",
    salary_range_text: "Adayların maaş gösteren işlere başvurma olasılığı daha yüksektir.",
    sponsor_job: "İlanı Öne Çıkar",
    sponsored: "Sponsorlu",
    clicks: "Tıklamalar",
    start_date: "Başlangıç tarihi",
    end_date: "Bitiş tarihi",
    add_jobs: "Münhal Ekle / Düzenle",
    job_stats: "İş İstatistikleri",
    date_created: "Oluşturulma tarihi",
    bonus: "Bonus",
    cancel: "İptal",
    add_en_translation: "İngilizce çeviri ekle",
    hide_en_translation: "İngilizce çeviriyi gizle",
    hide_salary_information: "Maaş bilgilerini gizle",
    show_salary_information: "Maaş bilgilerini göster",
    minimum_salary: "Asgari ücret",
    maximum_salary: "Maksimum Maaş",
    city_id_0: "Lefkoşa",
    city_id_1: "Girne",
    city_id_2: "Gazimağusa",
    city_id_3: "İskele",
    city_id_4: "Güzelyurt",
    city_id_5: "Lefke",
    city_id_6: "Diğer",
    city_id_undefined: "-",
    city_id_null: "-",
    candidate_appeal: "Aday başvurusu",
    candidate_appeal_checklist: "Aday itiraz kontrol listesi",
    profile_completeness: "Profil tamamlılığı",
    show_details: "Detayları göster",
    hide_details: "Detayları gizle",
    job_search_query_empty_inactive: "Sorgu, etkin olmayan hiçbir işle eşleşmiyor.",
    job_search_query_empty_all: "Sorgu hiçbir şirket münhaliyle eşleşmiyor.",
    job_search_query_empty_published: "Sorgu, yayınlanan hiçbir münhal ile eşleşmiyor.",
    job_search_query_empty_archived: "Sorgu, arşivlenmiş hiçbir münhal ile eşleşmiyor.",
    inactive_jobs_empty: "İşverenin aktif olmayan münhali yoktur.",
    all_jobs_empty: "İşverenin münhali yoktur.",
    published_jobs_empty: "İşverenin yayınlanmış münhali yoktur.",
    archived_jobs_empty: "İşverenin arşivlenmiş münhali yok.",

    // Employer Tools
    candidate_appeal_subtext: "Yayınlanan iş ilanlarınızın etkinliğini artırmak için aday başvurunuzu artırın.",
    faqs: "Sıkça Sorulan Sorular",
    faq_question_1: "Aday itirazı nedir?",
    faq_question_2: "Neden önemlidir?",
    faq_question_3: "Aday başvurumu nasıl iyileştirebilirim?",
    faq_answer_1:
      "Harika bir işe alım yapmak sadece doğru adayı bulmakla ilgili değildir. Doğru adayların sizi bulmasını kolaylaştırmakla ilgili. Aday Temyizinizi artırmak, İş kıbrıs'ın sizi işletmeniz için doğru seçimle hızla eşleştirmesine yardımcı olur.",
    faq_answer_2:
      "Adayların, şirket ve profesyonel fırsatları tam olarak anlayabildikleri için aday temyizinin yüksek olduğu şirketlerdeki işlere başvurma olasılıkları daha yüksektir. Doğru beklentilerle daha fazla bilgi sahibi olabildikleri için, doğru seçim olma olasılıkları da daha yüksektir!",
    faq_answer_3:
      "Aday itirazınızı iyileştirmek kolaydır. Şirket logonuzu eklemek, sunduğunuz avantajları listelemek, bir çalışan incelemesi almak ve diğer birçok adımı atmak, aday temyizinizi ve daha bilgili adayları çekme yeteneğinizi artıracaktır.",
    go_to_employer_profile: "İşveren Profiline git",
    company_locations: "Şirket Konumları",
    add_company_locations: "Şirket konumu ekle",
    logo: "Logo",
    company_benefits: "Şirket Faydaları",
    about_your_co: "Şirketiniz Hakkında",
    archived_jobs: "Arşivlenen İşler",
    subscriptions: "Abonelikler",
    users_and_roles: "Kullanıcılar ve Roller",
    notification_preferences: "Bildirim Tercihleri",
    templates: "Şablonlar",

    // Subscription
    current_subscription: "Mevcut Abonelik",
    receipts_payment_hist: "Ödeme Geçmişi",
    payment_date: "Ödeme Tarihi",
    "invoice_#": "Fatura #",
    status: "Durum",
    amount_paid: "Ödenen miktar",
    subscription_status: "Durum",
    need_help_employer_center: "Yardıma mı ihtiyacınız var?",
    visit_employer_help: "İşveren Yardım Merkezini ziyaret edin",
    reactivate: "Yeniden etkinleştir",
    no_billing_system_client: "Eklenmiş bir faturalandırma hesabınız yok",
    expires_on: "Bitiş tarihi",
    billing_cycle: "Abonelik Tipi",
    no_billing_client: "Şirketinizin faturalandırma müşterisi yok. Aboneliklerinizi kullanmaya başlamak için Etkinleştir'i tıklayın.",
    activate_billing: "Faturalandırmayı Etkinleştir",
    invoice: "Fatura",
    no_invoice_history: "Faturanız yok.",
    no_subscription_history: "Henüz herhangi bir ödeme yapmadınız.",
    Paid: "Ödenmiş",
    Unpaid: "Ödenmemiş",
    paid: "Ödenmiş",
    unpaid: "Ödenmemiş",
    billing_activation_success: "Faturalandırma hesabı etkinleştirildi.",
    billing_activation_fail: "Faturalandırma hesabı etkinleştirilemedi. Lütfen yöneticilerle iletişime geçin.",

    // Templates
    custom_templates: "İşveren Şablonları",
    custom_templates_subtext: "Şirketinizin mesajlaşma şablonlarını oluşturun ve yönetin.",
    template_type: "Şablon Türü",
    template_title: "Başlık",
    last_modified: "Son düzenleme",
    template_type_id_1: "Red (Görüşme yok)",
    template_type_id_2: "Reddetme (telefon görüşmesi sonrası)",
    template_type_id_3: "Daha fazla bilgi gerekli",
    template_type_id_4: "Telefon görüşmesi daveti",
    template_type_id_5: "Görüşme daveti",
    template_type_id_6: "Teklif mektubu",
    template_type_id_7: "Red (yerinde görüşme sonrası)",
    template_type_id_8: "Diğer",
    standard_templates: "Standart Şablonlar",
    standard_template: "Standart Şablon",
    standard_templates_subtext: "İşKıbrıs tarafından sağlanan bu şablonlara, yeni bir mesaj oluşturduğunuzda istediğiniz zaman erişebilirsiniz.",
    view: "Görünüm",
    customise: "Özelleştirmek",
    delete: "Sil",
    description: "Açıklama",
    is_standard_template: "Standart mı",
    add_template: "Şablon Ekle",
    save: "Kaydet",
    variables: "Değişkenler",
    messaging_template_title_edit: "Şablonu Düzenle",
    messaging_template_title_view: "Şablonu Görüntüle",
    messaging_template_title_create: "Şablon Oluşturun",
    messaging_template_title_customise: "Şablonu Özelleştir",
    no_custom_templates_header: "Özel şablonunuz yok",
    no_custom_templates_body:
      "Özel mesajınızı iletmek ve işe alım görevlinizin sesinin kalabalığın arasından sıyrılmasını sağlamak için İşKıbrıs şablonlarımızı desteklemek için özel bir mesaj şablonu oluşturun.",
    no_standard_templates_header: "Şu anda İş Kıbrıs şablonu yok.",
    no_standard_templates_body:
      "Standart mesaj şablonları, size özel mesajınızı iletmek ve işveren sesinizin kalabalığın arasından sıyrılmasını sağlamak için İşKıbrıs şablonlarımızı tamamlayacak kendi özel şablonlarınızı oluşturabileceğiniz bir başlangıç noktası sağlar.",
    candidate: "Aday",
    job_company: "İş / Şirket",
    cfname: "Aday Adı",
    cflname: "Aday Tam Adı",
    clname: "Aday Soyadı",
    rfname: "İşveren Adı",
    rflname: "İşveren Tam Adı",
    rlname: "İşveren Soyadı",
    messaging_template: "Mesajlaşma Şablonu",
    add_template_subtext: "Bir şablon türü seçin, ona bir ad verin, en çok ihtiyaç duyduğunuz anda kolayca kullanılabilir olmasını sağlayalım.",
    recruiter: "İşveren",

    // Preferences
    preferred_email: "Tercih Edilen E-posta",
    primary_address_subtext: "E-posta almayı seçtiğiniz tüm e-postaların birincil adresi.",
    change: "Değişiklik",
    app_news_header: "İş Kıbrıs haberleri",
    app_news_subtext: "Profilinizden en iyi şekilde yararlanmanıza ve en iyi adayları işe almanıza yardımcı olacak İş Kıbrıs'tan haberler.",

    // Users & Roles
    email: "E-posta adresi",
    roles: "Roller",
    last_login: "Son giriş",
    "role-a": "A rolü",
    employer: "İşveren",
    editor: "Editör",
    observer: "Gözlemci",
    edit_permission_level: "İzin düzeyini düzenleyin",
    edit_user_subtext: "Lütfen yeni erişim düzeyini girin ",
    administrator: "Yönetici",
    employer_subtitle: "Bu rol yalnızca genel derecelendirmeler gibi sınırlı bilgileri görüntüleyebilir. Bu rol, profili düzenleyemez, yorumlara yanıt veremez veya Analiz raporlarını göremez.",
    editor_subtitle: "Sınırlı içerik + analiz görüntüleme raporlama güncellemesi Şirket Profili bilgilerine Yorumlara yanıt verin ve Fotoğraflar ekleyin.",
    observer_subtitle: "Bu rol yalnızca genel derecelendirmeler gibi sınırlı bilgileri görüntüleyebilir. Bu rol, profili düzenleyemez, yorumlara yanıt veremez veya Analiz raporlarını göremez.",

    // Employer Profile
    company_info: "Şirket Bilgileri",
    logo_photos: "Logo ve Fotoğraflar",
    company_story: "Şirket Hikayesi",
    company_updates: "Şirket Güncellemeleri",
    company_faqs: "Şirket SSS'ları",
    create_company_subtext: "İşKıbrıs Profilinizde gösterilen herkese açık bilgileri ekleyin ve düzenleyin",
    basic_info: "Temel Bilgi",
    company_name: "Şirket Adı",
    address: "Adres",
    headquarter_city: "Merkez Şehir",
    company_website_label: "Websitesi",
    company_email_label: "E-posta",
    company_phone_label: "Telefon Numarası",
    company_registration_number_label: "Kayıt Numarası",
    company_year_of_foundation_label: "Kuruluş Tarihi",
    working_hours_label: "Çalışma Saatleri",
    working_hours_id_0: "Hafta içi + Cumartesi (yarım gün)",
    working_hours_id_1: "Hafta içi",
    working_hours_id_2: "Hafta içi + Cumartesi (tam gün)",
    working_hours_id_3: "Pazartesi hariç her gün",
    working_hours_id_4: "Her gün",
    company_size_label: "Çalışan Sayısı",
    company_size_id_0: "0 - 3 çalışan",
    company_size_id_1: "3 - 10 çalışan",
    company_size_id_2: "10 - 30 çalışan",
    company_size_id_3: "30 - 100 çalışan",
    company_size_id_4: "100+ çalışan",
    sector_label: "Sektör",
    industry_label: "Sanayi",
    company_leader: "Şirket Lideri",
    leader_name_label: "Lider Adı",
    leader_title_label: "Ünvan",
    social_media: "Sosyal Medya",
    facebook_profile_label: "Facebook",
    twitter_profile_label: "Twitter",
    linkedin_profile_label: "LinkedIn",
    youtube_profile_label: "Youtube",
    instagram_profile_label: "Instagram",
    delete_company: "Şirketi Sil",
    why_work_with_us: "Neden bizimle çalışmalısın",
    cover_image: "Kapak fotoğrafı",
    delete_company_warning: "Bu şirketi gerçekten silmek istiyor musun? Bu şirketi silerseniz artık şirket profilini veya işleri yönetemezsiniz.",
    confirmation_question: "Emin misiniz?",
    agree: "Katılıyorum",
    disagree: "Katılmıyorum",
    benefits: "Faydaları",
    no_benefits: "Hiçbir fayda eklenmedi. Faydaları eklemek için Fayda ekle'ye tıklayın.",
    add_benefits: "Faydalar Ekleyin",
    benefits_pledges: "Menfaatler ve Taahhütler",
    benefits_summary: "İşveren Yan Faydalar",
    exclusive_feature: "Ayrıcalıklı Özellik",
    add_your_note_here: "Notunuzu ekleyin ...",
    add_description: "Açıklama Ekle",
    employee_reporting: "Çalışan Raporlama",
    benefits_subtext: "Kuruluşunuzun sunduğu farklı avantajları sergileyin.",
    learn_more: "Daha fazla bilgi edin",
    back_to_company_info: "Şirket Bilgisine Dön",
    company_edit_fail: "İşveren profili güncellenemedi.",
    company_edit_success: "İşveren profili başarıyla güncellendi.",
    company_story_edit_fail: "İşveren hikayesi profili güncellenemedi.",

    upload_photos_subtext: "Kuruluşunuzun ekibinin, ofisinin veya konumunun ve etkinliklerin fotoğraflarını eklemek, münhallerinizin adaylar için öne çıkmasına yardımcı olabilir.",
    about_company_text:
      "Kendinizi iş arayanlara tanıtın. Dahil etmek isteyebileceğiniz bilgiler: işinizin ne zaman ve neden kurulduğu, o zamandan beri ne kadar büyüdüğünüz, şirketinizin ne yaptığı ve hizmet verdiğiniz müşteri türleri ve misyon beyanınız.",
    company_info_faq: "Sıkça Sorulan Sorular",
    company_mission: "Şirket Misyonu",
    add_company_update: "Şirket Güncellemesi Ekleyin",
    company_story_edit_success: "İşveren profili başarıyla güncellendi.",
    company_description: "Şirket Tanımı",

    // Photos
    photos: "Fotoğraflar",
    add_logo: "Logo Ekle",
    cover_photo: "Kapak Fotoğrafı",
    add_cover_photo: "Kapak Fotoğrafı Ekleyin",
    upload_logo_subtext: "Şirketinizi iyi temsil ettiğini düşündüğünüz bir logo yükleyin.",
    upload_cover_photo_subtext: "Şirketinizin temsil ettiği değerleri en iyi temsil eden bir kapak fotoğrafı yükleyin.",
    drag_and_drop_image: "Bir resim dosyasını buraya sürükleyip bırakın veya tıklayın",
    drag_and_drop_file: "Dosyaları buraya sürükleyip bırakın veya tıklayın",
    upload_file: "Dosya Yükleme",

    // Updates
    add_company_update_text: "Şirket haberleri ve güncellemeleri yayınlayarak profilinizde takipçilerinize ve ziyaretçilere ulaşın.",
    add_update_placeholder: "Mesajı buraya girin",
    post: "İleti",

    // Locations
    create_location_success: "Şirketinizin yeni şubesini başarıyla oluşturun.",
    edit_location_success: "Şirketinizin şube bilgileri başarıyla düzenlendi.",
    create_location_fail: "Failed to create company's location. Check your information and try again!",
    edit_location_fail: "Şirketin yeri düzenlenemedi. Bilgilerinizi kontrol edin ve tekrar deneyin!",
    branch_name: "Şube Adı - Türkçe",
    branch_name_en: "Şube Adı - İngilizce",
    latitude: "Enlem",
    longitude: "Boylam",
    city: "Şehir",
    address_code: "Adres Kodu",
    directions: "Talimatlar",
    add_company_location: "Şirket Şubesi Ekle",
    add_company_location_text: "Diğer işverenler arasından sıyrılmak ve adayların ilgisini çekmek için şirketinizin şubelerini ekleyin.",
    edit_company_location: "Şirket Şubesini Düzenle",
    edit_company_location_subtext: "Bilgilerinde herhangi bir değişiklik yapmak için şirket şubenizin bilgilerini düzenleyin.",

    // FAQs
    add_company_faq: "Şirket SSS'leri Ekleyin",
    create_faq_success: "Şirketinizin yeni SSS bölümünü başarıyla oluşturun.",
    edit_faq_success: "Şirketinizin SSS bilgileri başarıyla düzenlendi.",
    create_faq_fail: "Şirketin SSS'si oluşturulamadı. Bilgilerinizi kontrol edin ve tekrar deneyin!",
    edit_faq_fail: "Şirketin SSS'si düzenlenemedi. Bilgilerinizi kontrol edin ve tekrar deneyin!",
    add_company_faq_text: "Şirketiniz hakkında sık sorulan sorulardan bazılarını ekleyin.",
    faq_category: "SSS Kategorisi",
    question_tr: "Soru TR",
    question_en: "Soru EN",
    answer_en: "Cevap EN",
    answer_tr: "Cevap TR",
    faq_category_id_1: "",
    question_tr_required_error: "Soru gerekli",
    question_tr_minlength_error: "Soru çok kısa",
    question_en_minlength_error: "Soru çok kısa",
    answer_tr_required_error: "Cevap gerekli",
    answer_tr_minlength_error: "Cevap çok kısa.",
    answer_en_minlength_error: "Cevap çok kısa.",

    // APPLICANTS
    applicants_management: "Adaylar",
    sort_by: "Göre sırala",
    sort_id_0: "Güncellenme (en son)",
    sort_id_1: "Güncellenme (en eski)",
    sort_id_2: "İsim (A-Z)",
    sort_id_3: "İsim (Z-A)",
    sort_id_4: "İş Başlığı (A - Z)",
    sort_id_5: "İş Başlığı (Z - A)",
    mobile_sort_id_0: "En yeni",
    mobile_sort_id_1: "En eski",
    mobile_sort_id_2: "İsim (A-Z)",
    mobile_sort_id_3: "İsim (Z-A)",
    mobile_sort_id_4: "İş Başlığı (A-Z)",
    mobile_sort_id_5: "İş Başlığı (Z-A)",
    all: "Tümü",
    stage: "Aşama",
    rating: "Değerlendirme",
    location: "Yer",
    rating_id_2: "Belki",
    filter_by: "Göre filtre",
    rating_id_0: "Yorumsuz",
    rating_id_1: "Olumlu",
    rating_id_3: "Olumsuz",
    rows_per_page: "Sayfa başına satır",
    export_to_csv: "Adayları dışa aktar",
    other_applications: "Kullanıcı tarafından yapılan diğer başvurular",
    stage_id_0: "Yeni",
    stage_id_1: "Başvurdu",
    stage_id_2: "İncelendi",
    stage_id_3: "Telefon/Ön Mülakatı Yapıldı",
    stage_id_5: "Teklif Yapıldı",
    stage_id_6: "İşe Alındı",
    message_all: "Tümüne Mesaj Gönder",
    export_applicants: "Excel'e Aktar",
    stage_id_4: "Mülâkat",
    stage_id_7: "Reddedildi",
    stage_id_8: "Aday Başvurusunu Geri Çekti",
    company_users: "Şirket Kullanıcıları",
    access_levels: "Erişim Düzeyleri",
    email_addresses: "E-posta adresleri",
    invite_coworkers: "İş Arkadaşlarınızı Davet Edin",
    apply: "Uygula",
    rating_filter_label: "Notuna Göre",
    stage_filter_label: "Aşama",
    city_filter_label: "Yaşadığı Şehir",
    job_filter_label: "Münhal",
    education_level_filter_label: "Eğitim Seviyesi",
    work_permit_filter_label: "Çalışma İzni",
    field_of_study_filter_label: "Üniversite Bölümü",
    job_seeking_status_label: "Müsaitlik durumu",
    more_filter_label: "Daha Fazla Filtre",
    job_seeking_status_0: "Hemen çalışmaya başlayabilirim.",
    job_seeking_status_1: "2 hafta içinde işe başlayabilirim",
    job_seeking_status_2: "1 ay içinde işe başlayabilirim",
    work_permit_label: "İkamet durumu",
    work_permit_id_0: "KKTC Vatandaşıyım",
    work_permit_id_1: "Öğrenci vizem var",
    work_permit_id_2: "Çalışma izni olan yabancı",
    work_permit_id_3: "Çalışma izni olmayan yabancı",
    work_permit_id_4: "KKTC'de yaşamıyorum",
    work_permit_id_null: "-",
    field_of_study: "Üniversite Bölümü",
    more_filters: "Daha Fazla Filtre",
    search_for_more_field_of_study: "Daha fazlasını ara...",
    male: "Erkek",
    gender: "Cinsiyet",
    female: "Kadın",
    gender_id_: "",
    gender_id_male: "Erkek",
    gender_id_female: "Kadın",
    gender_id_unspecified: "Gizli tutmayı tercih ediyorum",
    results_for: "Sonuçlar:",
    send_message: "Mesaj Gönder",
    active_filter_tooltip: "Adaylarınızı farklı aktif durumlarda görmek için Aktif ve Tümü arasında geçiş yapın.",
    clear_all_filters: "Tüm Filtreleri Temizle",
    applied_on: "Başvuruda bulundu",
    view_job: "İşi Görüntüle",
    candidate_details: "Aday Bilgileri",
    message_history: "Mesaj Geçmişi",
    cover_letter: "Ön yazı",
    finished_in: "Sınıfı",
    degree_id_0: "Lisans",
    degree_id_1: "Master Derecesi",
    degree_id_2: "Doktora",
    degree_id_3: "MD",
    course_level_id_0: "Lisans",
    course_level_id_1: "Master Derecesi",
    course_level_id_2: "Doktora",
    course_level_id_3: "MD",
    candidate_education_details: "Eğitim Detayları",
    month_id_1: "Ocak",
    month_id_2: "Şubat",
    month_id_3: "Mart",
    month_id_4: "Nisan",
    month_id_5: "Mayıs",
    month_id_6: "Haziran",
    month_id_7: "Temmuz",
    month_id_8: "Ağustos",
    month_id_9: "Eylül",
    month_id_10: "Ekim",
    month_id_11: "Kasım",
    month_id_12: "Aralık",
    no_comments_body:
      "Diğer şirket kullanıcılarının görmesi için bir aday hakkında kısa notlar ekleyin. Bunlar yalnızca dahili kullanım içindir, bu nedenle notları yalnızca siz ve şirketinizin kullanıcıları görüntüleyebilir.",
    notes: "Notlar",
    internal_only: "Yalnızca dahili",
    add_note_placeholder: "Not ekle...",
    resume: "Özgeçmişler",
    no_cv_body: "Aday CV yüklemedi. CV'lerini eklemelerini istemek için mesajlaşma yoluyla onlarla iletişime geçebilirsiniz.",
    qualifications_label: "Nitelikler",
    years_of_experience_label: "İş Tecrübesi",
    driving_licence_label: "Ehliyet",
    driving_licence_id_0: "Geçerli Ehliyet",
    driving_licence_id_1: "Sürücü belgesi yok",
    languages_label: "Diller",
    languages_id_0: "Türkçe",
    languages_id_1: "İngilizce",
    languages_id_2: "Greek",
    languages_id_3: "Rusça",
    languages_id_4: "Diğer",
    new_employment_type_label: "Tercih Edilen İstihdam Tipi",
    military_status_label: "Askerlik Durumu",
    military_status_id_0: "Muaf",
    military_status_id_1: "Ertelenmiş",
    military_status_id_2: "Tamamlandı",
    preferred_city_label: "Tercih Edilen Şehir",
    phone_label: "Telefon numarası",
    city_of_residence: "İkamet ettiği şehir",
    applicant_details: "Aday Ayrıntıları",
    personal_summary: "Kişisel Özet",
    view_user_details: "Kullanıcı ayrıntılarını görüntüleyin.",
    download: "İndir",
    all_candidates: "Tüm Adaylar",
    previous: "Önceki",
    next: "Sonraki",
    filters: "Filtreler",
    go_back: "Geri dön",
    search_placeholder: "Arama...",
    no_messages_yet_title: "Henüz Mesaj Yok",
    no_messages_yet_body: "Başlamak için bu adaya bir mesaj gönderin. Bu adayla tam mesaj geçmişiniz burada görünecektir.",
    reply: "Cevapla",
    to: "İçin",
    email_label: "E-posta",
    city_id_label: "Şehir",
    name_label: "Ad Soyad",
    stage_id_label: "Evre",
    rating_id_label: "Değerlendirme",
    job_title_label: "İş Başlığı",
    phone_number_label: "Telefon Numarası",
    no_applicants_for_company:
      "Listelenen işlerinize henüz başvuran aday yok. Münhallerinize tıklayan iş arayanların sayısını artırmak için münhallerinizi sponsorlu yayınlayabilirsiniz.",
    no_applicants_match_filter: "Arama kriterlerinizle eşleşen aday yok. Lütfen adaylarınızı görmek için filtrelerinizi ayarlayın.",
    no_applicants_job_button_tooltip: "Daha fazla ayrıntı içeren işler daha fazla tıklama ve başvuru alır.",
    no_applicants_employer_button_tooltip: "Daha fazla tıklama ve başvuru almak için işveren profilinizi ve fotoğraflarınızı düzenleyin",

    // Messaging
    maximum_characters: "Maksimum 1000 Karakter (13 karakter)",
    send: "Gönder",
    preview: "Ön izleme",
    select_template: "Ön izlemek için şablon seçin.",
    hide_messages: "Mesajları Gizle",
    recipients: "Alıcılar",
    show_messages: "Mesajları Göster",
    type_message_here: "Mesajınızı buraya yazın...",
    character_max: "Maksimum 1000 Karakter.",
    characters: "Karakterler",
    template: "Şablon",
    add_recipients: "Alıcı ekle",
    template_origin_0: "Özel Şablonlar",
    template_origin_null: "Özel Şablonlar",
    template_origin_1: "İş Kıbrıs Şablonları",
    optional: "İsteğe bağlı",
    messages_recipient_title: "Alıcılar",
    messages_recipient_placeholder: "Alıcı ekle",
    new_message: "Yeni Mesaj",
    select_template_to_message: "Şablon Seçin",
    template_type_id_null: "- -",

    // Page titles
    applicants_management_title: "İş Kıbrıs İşe Alma Paneli - Başvuru Sahibi Yönetimi",
    create_job_title: "İş Kıbrıs İşe Alma Paneli - İş Oluştur",
    duplicate_job_title: "İş Kıbrıs İşe Alma Paneli - İş Oluştur",
    edit_job_title: "İş Kıbrıs İşe Alma Paneli - İşi Düzenle",
    dashboard_title: "İş Kıbrıs İşe Alma Paneli - Kontrol Paneli",
    employer_profile_title_info: "İş Kıbrıs İşe Alma Paneli - İşveren Bilgileri",
    employer_profile_title_benefits: "İş Kıbrıs İşe Alma Paneli - İşveren Avantajları",
    employer_profile_title_story: "İş Kıbrıs İşe Alma Paneli - İşveren Hikayesi",
    employer_profile_title_photos: "İş Kıbrıs İşe Alma Paneli - İşveren Fotoğrafları",
    employer_profile_title_updates: "İşveren Haberleri | İş Kıbrıs İşe Alma Paneli",
    employer_profile_title_locations: "İş Kıbrıs İşe Alma Paneli - İşveren Konumları",
    employer_profile_title_faqs: "İş Kıbrıs İşe Alma Paneli - İşveren SSS",
    employer_profile_title: "İş Kıbrıs İşe Alma Paneli - İşveren Profili",
    employer_tools_title: "İş Kıbrıs İşe Alma Paneli - İşveren Araçları",
    jobs_management_title: "İş Kıbrıs İşe Alma Paneli - İş Yönetimi",
    register_title: "İş Kıbrıs İşe Alma Paneli - İşveren Hesabını Kaydedin",
    forgot_password_title: "İş Kıbrıs İşe Alma Paneli - Şifremi Unuttum",
    settings_title_subscriptions: "İş Kıbrıs İşe Alma Paneli - Abonelikler",
    settings_title_users_management: "İş Kıbrıs İşe Alma Paneli - Kullanıcı Yönetimi",
    settings_title_preferences: "İş Kıbrıs İşe Alma Paneli - Ayarlar Tercihleri",
    settings_title_templates: "İş Kıbrıs İşe Alma Paneli - Şablon Yönetimi",
    settings_title: "İş Kıbrıs İşe Alma Paneli - Ayarlar",
    login_title: "İş Kıbrıs İşe Alma Paneli - Oturum Aç",

    // Extras
    did_not_receive_email: "E-posta almadım",
    company_create_success: "Şirketiniz başarıyla oluşturuldu.",
    company_create_fail: "Şirketiniz oluşturulamadı, bilgilerinizi kontrol edin ve tekrar deneyin.",
    company_delete_success: "Şirketiniz başarıyla silindi.",
    company_delete_fail: "Şirketiniz silinemedi, bilgilerinizi kontrol edin ve tekrar deneyin.",
    upload_new_cover_photo: "Yeni Kapak Fotoğrafı Yükle",
    upload_new_logo: "Yeni Logo Yükle",
    reset: "Sıfırla",
    zoom: "Yakınlaştır",
    cover_photo_upload_success: "Kapak fotoğrafı başarıyla yüklendi.",
    cover_photo_upload_fail: "Kapak fotoğrafı yüklenemedi.",
    logo_upload_success: "Logo başarıyla yüklendi.",
    logo_upload_fail: "Logo yüklenemedi.",
    job_create_success: "İşiniz başarıyla oluşturuldu.",
    job_create_fail: "İşiniz oluşturulamadı, bilgilerinizi kontrol edin ve tekrar deneyin.",
    enter_message_here: "Mesajınızı buraya giriniz",
    enter_information_here: "Bilgileri buraya giriniz (gerekli)",
    no_fos_search_results: "No fields of study.",
    message_sent_success: "Mesajınız başarıyla gönderilmiştir.",
    message_sent_fail: "Mesajınız gönderilemedi. Daha sonra tekrar deneyin.",

    select_all: "Hepsini seç",
    job_edit_success: "İş bilgileri başarıyla güncellendi.",
    job_edit_fail: "İş bilgileri güncellenemedi.",
    birthdate_label: "Doğum tarihi",
    gender_label: "Cinsiyet",
    field_of_study_label: "Üniversite Bölümü",
    education_level_id_label: "Eğitim Seviyesi",
    school_label: "Okul Adı",
    work_permit_id_label: "Çalışma İzni Durumu",
    more_recipients: "alıcı daha",

    stage_id_null: "",
    city_id_: "Diğer",
    add_tr_translation: "Türkçe Çeviri Ekle",
    add_new_payment_method: "Yeni ödeme yöntemi ekleyin",
    payment_method_subtext: "Aylık aboneliğiniz için varsayılan ödeme yöntemini seçin.",
    payment_methods: "Ödeme metodları",
    jobs_titles_label: "Daha fazla kategori aramak için yazın...",
    job_not_found: "Sağlanan kimliğe sahip iş bulunamadı veya ona erişiminiz yok.",

    // Payment Methods
    add_payment_method: "Ödeme yöntemi ekle",
    billing_address: "Fatura adresi",
    billing_city: "Şehir",
    billing_country: "Ülke",
    card_number: "Kredi kartı numarası",
    credit_card_information: "Kredi kartı bilgisi",
    expiry_date: "Son kullanma tarihi",
    expiry_date_required: "Son kullanma tarihi gerekli.",
    cvv: "CVV",
    cvv_required: "CVV gerekli.",
    billing_address_required: "Fatura adresi gerekli.",
    billing_city_required: "Faturalandırma şehri gerekli.",
    credit_card_information_subtext: "Tüm bilgiler, MasterCard Sertifikalı, son derece iyi sertifikalı PCI/DSS Sunucularında güvence altına alınmıştır..",
    billing_name: "Kart üzerindeki isim",
    billing_name_required: "İsim gerekli.",
    pricing: "Paketler",
    packages: "Paketler",
    show_more: "Daha fazla göster",
    show_more_false: "Daha fazla göster",
    show_more_true: "Daha az göster",
    try_it: "Satın Al",
    subscription_details: "Abonelik ayrıntıları",
    package_details: "Paket ayrıntıları",
    total_allocated_quantity: "Toplam tahsis edilen miktar",
    total_used_quantity: "Toplam kullanılan miktar",
    total_remaining_quantity: "Toplam kalan miktar",
    components: "Bileşenler",
    units: "Birimler",
    billing_reference: "Faturalandırma referansı",
    product: "Ürün",
    contact_information: "İletişim bilgileri",
    client_information: "Müşteri bilgisi",
    questions_contact_us: "Herhangi bir sorunuz varsa, bizimle iletişime geçebilirsiniz.",
    print_receipt: "Makbuzu yazdırın",
    or_sm: "veya",
    payment_method: "Ödeme yöntemi",
    month: "Ay",
    change_plan: "Plan değiştir",
    subscrition_expired_text: "Şu anda aktif bir aboneliğiniz yok.",
    reactivate_subscription: "Aboneliği yeniden etkinleştir",
    cancel_subscription: "Aboneliği iptal et",
    add_addons: "Eklenti ekle",
    add_new_package: "Yeni paket ekle",
    cancel_subscription_question: "Aboneliğinizi iptal etmek istediğinizden emin misiniz?",
    cancel_subscription_warning: "Bu işlemi geri alamazsınız. Herhangi bir sorunuz varsa, ekibimizle iletişime geçiniz.",
    contact_us: "Bize Ulaşın",
    subscription_cancellation_success: "Aboneliğiniz başarıyla iptal edildi.",
    subscription_cancellation_fail: "Aboneliğiniz iptal edilemedi. Yardım için ekibimizle iletişime geçin.",
    interval_month: "Ay(lar)",
    interval_day: "Gün(ler)",
    activate_new_billing: "Faturalandırma planını etkinleştir",
    valid_for: "Şunun için geçerli",
    go_to_subscriptions: "Aboneliklerinizi görüntüleyin",
    "billing_cannot_publish-job":
      "İş Kıbrıs'ta mevcut aboneliğiniz ile iş ilanı yayınlayamazsınız. İş ilanı yayınlamak için paketinizi yükseltin veya tekrardan aktif hale getirin.",
    unpublish_job_confirmation_question: "Bu işi yayından kaldırmak istediğinizden emin misiniz?",
    unpublish_job_confirmation_warning:
      "İşiniz yayından kaldırıldıktan sonra adaylar tarafından yapılan hiçbir aramada görünmez. Bu işi yeniden etkinleştirmek, paketinizden bir iş hakkını kullanmasına sebep olacaktır.",
    "billing_cannot_add-company-users":
      "İş Kıbrıs'ta mevcut aboneliğinizle yeni bir şirket kullanıcısı ekleyemezsiniz. Yeni ekip üyesi eklemek için paketinizi yükseltin veya yeniden etkinleştirin.",
    "billing_cannot_send-message-to-applicants":
      "İş Kıbrıs'ta aktif üyeliği olmayan adaylara mesaj gönderemezsiniz. Başvuru sahiplerine mesaj göndermek için paketinizi yeniden etkinleştirin.",
    "billing_cannot_export-applicants":
      "Mevcut İş Kıbrıs paketinizle başvuranları dışa aktaramazsınız. Başvuru sahiplerini dışa aktarmak için paketinizi yükseltin veya yeniden etkinleştirin.",
    "billing_cannot_create-benefits":
      "Mevcut İş Kıbrıs paketinize avantaj ekleyemezsiniz. İşveren profilinize avantajlar eklemek için paketinizi yükseltin veya yeniden etkinleştirin.",
    "billing_cannot_buy-package": "İş Kıbrıs paketlerini şu anda satın alamazsınız. 1 Kasım 2021 tarihinden itibaren paketlerimizi satın alabileceksiniz.",
    buy_it: "Paket satın al",

    job_description_required: "İş tanımı gerekli.",
    job_title_required: "İş unvanı gerekli.",
    job_city_id_required: "İş yeri şehri gerekli.",
    startDate_required: "İş yayın tarihi gereklidir.",
    endDate_required: "İş bitiş tarihi gerekli.",
    employment_type_required: "İstihdam türü gereklidir.",
    education_level_required: "Eğitim seviyesi gereklidir.",
    active_jobs: "Aktif İşler",
    remove_en_translation: "İngilizce Çeviriyi Kaldır",
    " ": "",
    toggle_component_status_true: "Aktif",
    toggle_component_status_false: "Etkin değil",
    billing_is_coming_text: "Iş Kıbrıs, münhal paketlerimizden birini satın alarak, iş ilanı yayınlayabilirsiniz.",
    billing_is_coming_learn_more: "Paketlerimizi görüntüleyin.",
    filter_apply: "Uygula",
    dismiss: "Yoksay",

    // Job Slots
    job_slots_available_message: "Hesabınızda {{p1}} adet münhal yaratabilirsiniz.",
    job_and_internship_slots_available_message: "Hesabınızda {{p1}} adet münhal yaratabilirsiniz.",
    manage_jobs_below_message: "Aşağıdaki buton ve listeyi kullanarak iş yaratabilirsiniz",
    no_jobs_slots_available: "Tüm münhallerinizi kullandınız.",
    add_a_package: "Yeni Paket Ekle",
    jobs_slots_total: "Toplam",
    jobs_slots_available: "Kalan",
    job_slot_validity_message:
      "This job is valid until {{expiry_date}} and you can publish or unpublish it at your own discretion between this period of time without encountering any extra charges.",
    job_expired_need_to_duplicate:
      "Bu işin ücretsiz deneme süresi dolmuşutur ve tekrardan yayınlanamaz. Benzer içerikte bir iş oluşturabilmek için aşağıdaki “Kopya Oluştur” linkine tıklayabilirsiniz. Oluşturacağınız bu kopya, satın almış olduğunuz iş haklarınızdan düşülecektir.",
    click_here_to_duplicate: "İşi çoğaltmak için buraya tıklayın.",
    age_label: "Yaş",
    applications_other_applications: "Diğer İş Başvuruları",
    gender_id_null: "Belirtilmemiş",
    application_details: "Başvuru Detayları",
    application_notes: "İş Başvuru Notları",
    download_application: "İş Başvurusunu İndir",
    company_user_invitation_title: "{{company_name}} işe alım ekibine davet",
    company_user_invitation_body:
      "{{inviter_name}} sizi {{company_name}} işe alım ekibinin {{role_name}} olarak bir parçası olmaya davet etti. Kabul etmek veya reddetmek için aşağıya tıklayın.",
    accept: "Kabul",
    decline: "Reddetmek",
    company_user_invitation_accept_success: "Şirket kullanıcılarına başarıyla eklendiniz.",
    company_user_invitation_reject_success: "Şirketin davetini başarıyla reddettiniz.",
    company_user_invitation_reject_error: "Şirket daveti reddedilirken hata oluştu. Davet süresi dolmuş olabilir.",
    company_user_invitation_accept_error: "Şirket daveti kabul edilirken hata oluştu. Davet süresi dolmuş olabilir.",
    invitation_fetch_error: "Davetiye alınamadı. Davetiyenin süresi dolmuş veya iptal edilmiş olabilir.",
    pending_company_user_invitations: "Pending invitations",
    invited_at: "Davetli",
    revoke: "Geri çekmek",
    company_user_invitation_alert: "{{ company_name }} işe alım ekibine katılmak için davetiyeniz var",
    view_invitation: "Daveti Görüntüle",
    whatsapp_contact_reply_duration: "Genellikle bir saat içinde yanıt verir.",
    created_at_label: "Oluşturulma Tarihi",
    title: "Başlık",
    og_title: "Facebook Paylaşım Görseli",
    og_description: "Facebook Paylaşım Metni",
    open_graph_header: "Facebook Open Graph Ön izleme",
    open_graph_subtitle: "Facebook'ta paylaşırken bağlantınızın nasıl görüneceğini güncelleyin.",
    add_image: "Görsel Ekle",
    select_new_image: "Yeni resim seç",
    job_open_graph_tags_header: "Facebook Paylaşım Görseli",
    job_open_graph_tags_subtext: "Facebook'ta paylaşım yaparken işinizin bağlantısının nasıl görüneceğini özelleştirin.",
    company_open_graph_tags_subtext: "Facebook'ta paylaşım yaparken şirketinizin bağlantısının nasıl görüneceğini özelleştirin.",

    billing_publish_internship_job:
      "İş Kıbrıs'ta sadece mevcut aboneliğiniz ile staj ilanı verebilirsiniz. Diğer tüm iş türlerini yayınlamak için paketinizi yükseltin veya reaktif hale getirin.",
    create_internship_job: "Staj İş İlanı Oluştur",

    internship_jobs_slots_available: "Staj İş Yuvaları",

    close: "Kapat",
    request_invoice: "Fatura talep et",
    company_email: "Şirket E-postası",
    company_telephone: "Şirket Telefonu",
    contact_person_name: "Temas kurulacak kişinin ismi",
    contact_person_email: "İlgili Kişi E-postası",
    contact_person_phone: "İrtibat Kişisi Telefon",
    invoice_type: "Fatura Türü",
    invoice_type_physical: "Kağıt Fatura",
    invoice_type_digital: "e-Fatura",
    invoice_request_fail: "Fatura isteğiniz oluşturulamadı. Bilgilerinizi kontrol edin ve tekrar deneyin.",
    invoice_request_success: "Faturanız için başarıyla istendi. Kısa bir süre içinde temas kuracağız.",
    years_of_experince_label: "Deneyim Yılları",

    is_profile_required: "Profil gerekli mi",
    active_packages: "Aktif Paketler",
    active_packages_subtitle: "Satın aldığınız ve halen aktif olan paketler.",
    validity: "Geçerlilik süresi",
    no_components: "Bu paketin hiçbir bileşeni yok",
    free_forever: "Bu paket sonsuza kadar ücretsiz",
    company_registration_number_placeholder: "Kayıt Numarası veya Kimlik Numarası ",
    company_registration_number: "Kayıt Numarası veya Kimlik Numarası ",

    billing_settings: "Faturalandırma Ayarları",
    registration_number: "MŞ numarası / Kimlik kart numarası",
    invoice_organization_name: "Fatura Kuruluş Adı",
    invoice_contact_first_name: "İlgili Kişi Adı",
    invoice_contact_last_name: "İlgili Kişi Soyadı",
    invoice_contact_phone: "Fatura İletişim Telefonu",
    invoice_email: "Fatura E-postası",
    invoice_address: "Adres",
    invoice_additional_notes: "Ek Notlar",
    billing_settings_update_success: "Faturalandırma ayarlarınız başarıyla güncellendi.",
    billing_settings_update_fail: "Faturalandırma ayarlarınız güncellenemedi. Bilgilerinizi kontrol edin ve tekrar deneyin.",
    settings_title_billing: "Faturalandırma",
    billing: "Faturalandırma",
    date_charged: "Ödeme Tarihi",
    date_charge_expiry: "Sona Erme Tarihi",
    subscription_charged_reference: "Abonelik Referansı",
    account_settings: "Hesap Ayarları",
    update_password: "Şifre Güncelleme",
    update_password_body: "Şifrenizi güncellemek için önce eski şifrenizi, sonra yeni şifrenizi yazdıktan sonra onaylayıp kaydet butonuna tıklayınız.",
    account_settings_title: "İş Kıbrıs İşe Alma Paneli - Hesap ayarları",
    old_password: "Mevcut Şifreniz",
    new_password: "Yeni Şifreniz",
    confirm_password: "Yeni Şifreni Tekrarla",
    password_change_success: "Şifreniz başarıyla güncellendi.",
    password_change_fail:
      "Şirenizi güncellerken hata oluştu. Bilgilerinizi kontrol edip tekrar deneyiniz.",
    server_fail_error: "Sunucu Hatası. Lütfen destek takım ile iletişime geçiniz.",
    network_error: "Bağlantı Hatası. Lütfen bağlantınızı kontrol edip tekrar deneyiniz.",
    delete_account_question: "İşKıbrıs hesabınızı silmek istediğinizden emin misiniz?",
    delete_account_warning: "Bu işlemi geri alamayacaksınız.",
    account_delete_success: "Hesabınız başarıyla silindi.",
    account_delete_fail:
      "Hesabınızı silerken hata oluştu. Lütfen bağlantınızı kontrol edip tekrar deneyiniz.",
    delete_account: "Hesabımı Sil",
    delete_account_subtext:
      "Hesabınızı sildiğinizde tüm verileriniz ve yüklenen özgeçmiş dosyalarınız silinir.",
    delete_account_company_warning: "Bu işlem, yönettiğiniz şirketlerin hiçbirini silmez. Şirketlerinizden herhangi birini silmek istiyorsanız, Şirketinizi Yönetin seçeneğine tıklayın.",
    manage_companies: "Şirketinizi Yönetin",
    old_password_required: "Mevcut şifrenizi giriniz",
    old_password_maxLength: "Şifreniz 91 karakterden uzun olamaz",
    old_password_minLength: "Şifreniz 4 karakterden kısa olamaz",
    new_password_required: "Yeni şifrenizi giriniz",
    new_password_maxLength: "Şifreniz 91 karakterden uzun olamaz",
    new_password_minLength: "Şifreniz 4 karakterden kısa olamaz",
    confirm_password_required: "You need to confirm your new password",
    confirm_password_maxLength: "Şifreniz 91 karakterden uzun olamaz",
    confirm_password_minLength: "Şifreniz 4 karakterden kısa olamaz",

    notification_settings_header: "Bildirim Ayarları",
    notification_settings_change_success: "Bildirim ayarlarınız başarıyla değişti.",
    notification_settings_change_fail: "Bildirim ayarlarınız değişmedi. Tekrar deneyiniz.",
    notification_settings_subtext:
      "İş ilanlarınıza yapılacak başvurular hakkıda bilgilendirme amaçlı e-posta almak istemiyorsanız, bildirim ayarlarınızı buradan değiştirebilirsiniz.",
    email_notifications: "İş başvuruları hakkında bilgilendirme amaçlı e-posta almak istiyorum.",

    impersonation_title: "Hello {{ name }}!",
    impersonation_body: "User impersonation is active at the moment.",
    end_impersonation_session: "End Impersonation Session",
    impersonation_prompt_title: "Initialize Impersonation",
    impersonation_prompt_body: "You are about to start initializing an impersonation session for user ID: {{ id }}",
    impersonation_error: "Error initiating user impersonation.",

    impersonation_revoked_message: "Impersonation session has been ended successfully. You will be redirected to the home page.",
    impersonation_revoke_error: "Failed to end impersonation session. Contact the developer team.",
    impersonation_starting_message: "Impersonation session has initialized successfully. You will be redirected to the home page to begin your session.",
    
    mark_all_as_read: "Tümu Okundu",
    mark_as_read: "Okundu olarak işaretle",
    notifications: "Bildirimler",
    only_show_unread: "Okunmamışları göster",
    recent_notifications: "Son Eklenen",
    view_jobs_with_count: "Diğer {{jobs}} işi göster",
    mark_as_unread: "Okunmamış olarak işaretle.",
    apply_early: "Önce sen başvur",
    last_week: "Geçtiğimiz Hafta",
    today: "Bugün",
    yesterday: "Dün",
    older: "Daha Eski",
    notification_center_survery_question: "Bu bildirimler sizin için kullanışlı mı?",
    notification_center_survery_question_subtitle:
      "Cevaplarınızı sistemimizi geliştirmek için bize çok faydalı olacak",
    notification_center_survery_success: "Geri bildiriminiz için çok teşekkürler.",
    notification_center_survery_error: "Beklenmedik bir hata oluştu. Daha sonra tekrar deneyin lütfen",
    empty_notifications: "Geçen aya ait tüm bildirimlerinizi okudunuz.",

    driver_licence: "Sürüş Ehliyeti",
    driver_licence_category_id_A: "A Sınıfı - Otobüs",
    driver_licence_category_id_B: "B Sınıfı - Kamyon, Tır",
    driver_licence_category_id_C: "C Sınıfı - Minibüs",
    driver_licence_category_id_D: "D Sınıfı - Manuel ve otomatik vitesli binek araçlar",
    driver_licence_category_id_E: "E Sınıfı - Traktör, ATV",
    driver_licence_category_id_F: "F Sınıfı - Palet üzeri araçlar",
    driver_licence_category_id_G: "G Sınıfı - Yol Silindiri",
    driver_licence_category_id_H: "H Sınıfı - Motorsiklet",
    driver_licence_category_id_K: "K Sınıfı - Greyder ve Jiletli araçlar",
    
    application_age_filter_label: "Başvuru Tarihi",
    application_age_1: "Son Bir Gün İçinde",
    application_age_3: "Son 3 gün İçinde",
    application_age_7: "Geçen Hafta",
    application_age_14: "Geçtiğimiz 2 Hafta İçinde",
    application_age_31: "Geçtiğimiz Ay",
    application_age_60: "Son 60 gün İçinde",
    application_age_90: "Son 90 gün İçinde",
    application_age_180: "Son 6 Ay İçinde",
    application_age_365: "Son 1 Yıl İçinde",
    application_age_730: "Son 2 Yıl İçinde",
    application_age_custom_days: "Son {{ days }} gün İçinde",

    back: "Geri",
    on: "Açık",
    off: "Kapalı",

    notification_settings_title: "Bildirim Ayarları Merkezi",
    notification_settings_center_description: "Bildirim tercihlerinizi buradan yönetebilirsiniz.",

    notification_settings_option_title_id_recommendations: "Önerilenler",
    notification_settings_option_subtitle_id_recommendations: "Anlık Bildirimleri, Uygulama İçi Bildirimler ve E-posta Bildirimleri",
    notification_settings_option_description_id_recommendations: "Bunlar, sizin için en son önerilerimizi içeren bildirimlerdir.",

    notification_settings_option_title_id_message_received: "Mesajlaşma",
    notification_settings_option_subtitle_id_message_received: "Anlık Bildirimleri, Uygulama İçi Bildirimler ve E-posta Bildirimleri",
    notification_settings_option_description_id_message_received: "Bunlar, mesaj aldığınızı bildiren bildirimlerdir.",

    notification_settings_option_title_id_saved_searches: "Kayıtlı aramalar",
    notification_settings_option_subtitle_id_saved_searches: "Anlık Bildirimleri, Uygulama İçi Bildirimler ve E-posta Bildirimleri",
    notification_settings_option_description_id_saved_searches: "Bunlar, kayıtlı arama uyarılarınız için bildirimlerdir.",

    notification_settings_option_title_id_followed_companies: "Takip Edilen Şirketler",
    notification_settings_option_subtitle_id_followed_companies: "Anlık Bildirimleri, Uygulama İçi Bildirimler ve E-posta Bildirimleri",
    notification_settings_option_description_id_followed_companies: "Bunlar, takip ettiğiniz bir şirket iş ilanı yayınladığında sizi bilgilendirmek için yapılan bildirimlerdir.",

    notification_settings_option_title_id_promoted_jobs: "Yüksek Öncelikli İşler",
    notification_settings_option_subtitle_id_promoted_jobs: "Anlık Bildirimleri, Uygulama İçi Bildirimler ve E-posta Bildirimleri",
    notification_settings_option_description_id_promoted_jobs: "Bunlar, sizin için uygun olabilecek yüksek öncelikli işler için bildirimlerdir.",

    notification_settings_option_title_id_application_reviewed: "İş Başvuruları İncelemeleri",
    notification_settings_option_subtitle_id_application_reviewed: "Anlık Bildirimleri, Uygulama İçi Bildirimler ve E-posta Bildirimleri",
    notification_settings_option_description_id_application_reviewed: "Bunlar, işverenler başvurularınızı incelediğinde sizi bilgilendirmek için yapılan bildirimlerdir.",

    notification_settings_option_title_id_subscription_activated: "Abonelik Etkinleştirildi",
    notification_settings_option_subtitle_id_subscription_activated: "Anlık Bildirimleri, Uygulama İçi Bildirimler ve E-posta Bildirimleri",
    notification_settings_option_description_id_subscription_activated: "Bunlar, aboneliğinizin güncellendiğini bildiren bildirimlerdir.",

    notification_settings_option_title_id_subscription_about_to_expire: "Aboneliğin Süresi Dolmak Üzere",
    notification_settings_option_subtitle_id_subscription_about_to_expire: "Anlık Bildirimleri, Uygulama İçi Bildirimler ve E-posta Bildirimleri",
    notification_settings_option_description_id_subscription_about_to_expire: "Bunlar, aboneliğinizin sona ermek üzere olduğunu bildiren bildirimlerdir.",

    notification_settings_option_title_id_subscription_expired: "Abonelik süresi doldu",
    notification_settings_option_subtitle_id_subscription_expired: "Anlık Bildirimleri, Uygulama İçi Bildirimler ve E-posta Bildirimleri",
    notification_settings_option_description_id_subscription_expired: "Bunlar, aboneliğinizin sona erdiğini bildiren bildirimlerdir.",

    notification_settings_option_title_id_new_application_received: "Yeni Başvuru Alındı",
    notification_settings_option_subtitle_id_new_application_received: "Anlık Bildirimleri, Uygulama İçi Bildirimler ve E-posta Bildirimleri",
    notification_settings_option_description_id_new_application_received: "Bunlar, yeni bir başvuru aldığınızı bildiren bildirimlerdir.",

    notification_settings_option_title_id_application_updated: "İş Başvurusu Güncellemeleri",
    notification_settings_option_subtitle_id_application_updated: "Anlık Bildirimleri, Uygulama İçi Bildirimler ve E-posta Bildirimleri",
    notification_settings_option_description_id_application_updated: "Bunlar, bir aday başvurusunu güncellediğinde bunu size bildiren bildirimlerdir.",

    notification_settings_option_title_id_daily_applications_overview: "Günlük Uygulamalara Genel Bakış",
    notification_settings_option_subtitle_id_daily_applications_overview: "Anlık Bildirimleri, Uygulama İçi Bildirimler ve E-posta Bildirimleri",
    notification_settings_option_description_id_daily_applications_overview: "Bunlar, size günlük uygulamalara genel bir bakış sağlayan bildirimlerdir.",

    notification_settings_option_title_id_job_posted: "İş İlanı Bildirimi",
    notification_settings_option_subtitle_id_job_posted: "Anlık Bildirimleri, Uygulama İçi Bildirimler ve E-posta Bildirimleri",
    notification_settings_option_description_id_job_posted: "Bunlar, iş ilanınızın yayınlandığını size bildiren bildirimlerdir.",

    notification_settings_option_title_id_job_about_to_expire: "Süresi dolmak üzere olan İşler",
    notification_settings_option_subtitle_id_job_about_to_expire: "Anlık Bildirimleri, Uygulama İçi Bildirimler ve E-posta Bildirimleri",
    notification_settings_option_description_id_job_about_to_expire: "Bunlar, iş ilanınızın süresinin dolmak üzere olduğunu size bildiren bildirimlerdir.",

    notification_settings_option_title_id_job_expired: "İş Süresi Doldu",
    notification_settings_option_subtitle_id_job_expired: "Anlık Bildirimleri, Uygulama İçi Bildirimler ve E-posta Bildirimleri",
    notification_settings_option_description_id_job_expired: "Bunlar, iş ilanınızın süresi dolduğunda sizi bilgilendirmek için yapılan bildirimlerdir.",

    notification_channel_title_id_email: "E-posta",
    notification_channel_subtitle_id_email: "Birincil E-postanıza gönderildi",
    notification_channel_title_id_in_app: "Uygulama İçi Bildirimler",
    notification_channel_subtitle_id_in_app: "Uygulama içinde teslim edildi",
    notification_channel_title_id_push_notifications: "Anlık Bildirimler",
    notification_channel_subtitle_id_push_notifications: "Hemen cihazınıza iletilir.",

    notification_settings_update_success: "Bildirim tercihleriniz başarıyla ayarlandı.",
    notification_settings_update_fail: "Bildirim tercihleriniz ayarlanamadı. Daha sonra tekrar deneyin.",

    notification_settings: "Bildirim Ayarları",
    manage_notification_settings: "Bildirim Ayarlarınızı Yönetin",

    notification_center_disabled_tooltip: "Bu bildirim kanalı şu anda kullanılamıyor.",

    notification_settings_category_title_id_job_search: "İş arama",
    notification_settings_category_title_id_messaging: "Mesajlaşma",
    notification_settings_category_title_id_billing: "Fatura Bildirimleri",
    notification_settings_category_title_id_applications: "İş başvuruları",
    notification_settings_category_title_id_job_posting: "İş ilanları",

    stage_id_enum_NEW: "Yeni Gelenler",
    stage_id_enum_RESUME_SCREENING: "CV İncelenecekler",
    stage_id_enum_WAITING_FOR_INTERVIEW: "Ön Mülakat Bekleyenler",
    stage_id_enum_WAITING_FOR_JOB_SEEKER: "Ek Bilgi Beklenenler",
    stage_id_enum_TO_INTERVIEW_IN_PERSON: "Mülakat Bekleyenler",
    stage_id_enum_REFERENCES_AND_BACKGROUND_CHECK: "Referans Kontrolu Yapilacaklar",
    stage_id_enum_FINAL_DECISION_WAITING_LIST: "Karar Verilecekler",
    stage_id_enum_JOB_OFFERING: "Teklif Yapılacaklar",
    stage_id_enum_REJECTED: "Reddedildi",
    stage_id_enum_CANDIDATE_WITHDREW: "Aday Başvurusunu Geri Çekti",
    stage_id_enum_HIRED: "Ise Alindi",

    candidate_notes: "Notlar",
    candidates_notes_subtitle: "Diğer şirket kullanıcılarının görmesi için bir aday hakkında kısa notlar ekleyin. Bunlar yalnızca dahili kullanım içindir, bu nedenle notları yalnızca siz ve şirketinizin kullanıcıları görüntüleyebilir.",
    
    full_name: "İsim Soyisim",
    drop_resume_text: "Özgeçmişinizi buraya taşıyıp bırakın",
    add_manual_application: "Elden Başvuru Ekle",
    add_manual_application_title: "Elden Başvuru Ekle",
    select_jobs_for_manual_applicants: "Kullanıcının başvurduğu işleri seçin.",
    submit_to_ats: "Manuel Başvuru Sahibini ATS'ye Aktarın",
    add_manual_application_caption: "E-posta, telefon veya yüz yüze kanallar aracılığıyla gönderilen başvuruları içe aktarabilirsiniz. Bu, tüm adaylarınızı tek bir yerden yönetmenizi sağlayacaktır.",
    manual_application_indidator_text: "Bu başvuru manuel olarak ATS'ye eklendi",

    upload_logo_dimensions: "Boyut: 200 x 200 | Oran: 1 : 1",
    upload_cover_photo_dimensions: "Boyut: 820px X 205px | Oran: 4 : 1",
    photo_dimensions: "Boyut: {{ dimensions }} | Oran: {{ ratio }}",
    add_manual_application_success: "Manuel başvuru başarıyla kaydedildi",
    add_manual_application_fail: "Manuel başvuru oluşturulamadı. Daha sonra tekrar deneyin.",
    
    applicant_not_in_any_folder: "Bu uygulama herhangi bir listeye eklenmemiştir. Bir listeye eklemek veya yeni bir liste oluşturmak için Ekle'ye tıklayın.",
    candidate_folders: "Başvuru Listeleri",
    folders: "Başvuru Listeleri",
    candidates_folders_subtitle: "İşlerinize başvuru eklemek için listeler oluşturabilirsiniz. Ardından, filtreler bölümündeki bu listelere göre başvurularınızı filtreleyebilirsiniz.",
    add_candidate_to_folder: "Listeye başvuru ekle",
    add_new_folder: "Yeni liste oluştur",
    add_new_folder_caption: "İş ilanlarınıza başvuru eklemek için listeler oluşturabilirsiniz, bu listeleri filtreleme yapmak için de kullanabilirsiniz.",
    no_folders_search_results: "Herhangi bir başvuru listeniz yok.",
    folder_filter_label: "Başvuru Listeleri",
    create_folder: "Liste oluştur",
    hide_user_details: "Kullanıcı bilgilerini gizle.",
    add_applicant_to_folder: "Listeye başvuru ekle",
    add_applicant_to_folder_caption: "Bu başvuruyu ekleyebilmek için bir liste seçiniz.",
    manage_folders: "Başvuru listelerini yönetin.",
    add: "Ekle",
    applicant_folders: "Başvuru Listeleri",
    manage_applicant_folder_subtext: "Başvuru listenizi yönetin.",
    folders_empty: "Herhangi bir başvuru listeniz yok.",
    lists: "Listeleri",

    no_jobs_to_assign_applications: "Aktif iş ilanınız yok.",
    remove_applicant_from_folder_fail: "Bir sorun oluştu. İş arayan listeden çıkarılamadı",
    remove_applicant_from_folder_success: "Başvuru sahibi, listeye eklendi",
    folder_action_error_create: "Bir sorun oluştu. Tekrar deneyin",
    folder_action_error_edit: "Bir sorun oluştu. Tekrar deneyin",
    folder_action_success_create: "Yeni başvuru listesi yaratıldı.",
    folder_action_success_edit: "Başvuru Listesi güncellendi",
    add_application_folder_success: "Başvuru sahibi listeden çıkarıldı",
    add_application_folder_error: "Bir sorun oluştu.",

    what_is_this: "Bu nedir?",
    preview_open_graph_tags: "Facebook Bağlantı Önizlemesini Göster",
    hide_preview_open_graph_tags: "Önizlemeyi Sakla",

    ats_feature_announcement: "Yeni Özellik Uyarısı: Başvuru Takip Sistemi",
    ats_feature_announcement_subtitle: "Sistemimize ATS özelliklerini ekledik. ATS eklentisine ücretsiz olarak abone olabilirsiniz. Özellikler şunları içerir: ",
    ats_feature_announcement_manual_applications: "Manuel başvuruları ekleyin ve yönetin.",
    ats_feature_announcement_tag_applications_to_lists: "Başvuruları listelere ekleyin ve bu listeleri kullanarak filtreleyin.",
    ats_feature_announcement_expanded_applications_stages: "Genişletilmiş başvuru aşama listeleri.",

    applicants_tracking_system_add_on: "Başvuru Takip Sistemi (ATS) Eklentisi",
    applicants_tracking_system_add_on_subtitles: "İşverenlerimize, şahsen, e-posta yoluyla veya İş Kıbrıs iş portalı üzerinden gönderilen tüm adaylarını yönetebilmeleri için araçlar sunuyoruz.",
    applicants_tracking_system_add_on_features_title: "ATS Özellikleri",
    applicants_tracking_system_add_on_features_subtitle: "ATS sisteminin bir parçası olarak hayata geçirdiğimiz özelliklerden bazıları şunlardır.",
    applicants_tracking_system_add_on_features_subtitle_more_functionality: "Sisteme sürekli olarak daha fazla işlevsellik eklenecektir.",
    applicants_tracking_system_add_on_learn_more: "Yeni özellikler hakkında daha fazla bilgi edinmek ister misiniz?",
    ats_free_subscription_availability: "ATS işlevselliği {{ charging_date }} tarihine kadar ücretsiz olarak sunulacaktır.",
    activate_ats_addon: "ATS Eklentisini Etkinleştir",
    ats_subscription_success: "ATS abonelik eklentisi başarıyla eklendi.",
    ats_subscription_error: "ATS aboneliği eklenemedi.",
    go_to_ats_system_landing_pages: "ATS abonelik sayfasına git",
    billing_cannot_use_ats_system: "Bu özelliğe şu anda erişemezsiniz çünkü bu bir ATS özelliğidir ve eklentiyi etkinleştirmeniz gerekir. ATS aboneliğini ücretsiz olarak eklemek için aşağıya tıklayın.",
    ats_subscription_already_active: "The ATS addon is already active on this company. You can use the features that are included in this package.",
    folder_disabled_filter_label: "Başvuru Listeleri",

    city_id_placeholder: "Şehir, belediye, ilçe, posta kodu",
    search_and_select_locations: "Başvuru sahiplerini filtrelemek için arama yapın ve konum seçin.",
    loading: "Yükleniyor",
    empty_text: "Seçenek Yok",
    place: "Yer",
    place_filter_label: "Konum",
    job_sponsorship_warning_text: "Eğer işinizi sponsorlu yayınlarsanız, ilanınız Öne Çıkan İşler bölümünde “15 gün” boyunca yayında kalacaktır. Eğer ilanınızınyayın süresinin dolmasına 15 gün’den az kaldıysa,ödeme sonrasında otomatik olarak yayın süresi üzerine 15 gün eklenecektir.",
    discount_deal_text: "{{discount_percent}} İNDİRİMLİ",
    stage_id_enum_label: "Aşama ENUM",
    place_label: "Yer",
    sponsor_job_warning_title: "Sponsor iş ilanı: {{job}}",
    sponsor_job_warning: "Bu paketi satın almanız durumunda, iş ilanınız Öne Çıkan İşler bölümünde 15 gün boyunca yayınlanacaktır.",
    continue: "Devam et",
    buy_new_package: "Yeni paket satın al",
    subscription_completed: "Tüm ilan hakları kullanıldı",
    billing_information: "Fatura bilgileri",
    invoices: "Faturalar",
    user_account_settings: "Kullanıcı ayarları",
    join_your_team_title: "İşKıbrıs'ta Ekibinize Katılın",
    join_your_team_subtitle: "Ekibinize katılmanız için davet edildiniz. Kaydolun ve bugün ekibinizle işbirliğine başlayın.",
    join_your_team_page_title: "İş Kıbrıs İşe Alma Paneli - Ekibinize Katılın",
    are_you_looking_for_billing: "Hesap faturalandırmasını ve paketlerini yönetmek ister misiniz?",
    go_to_billing: "Hesap Faturalandırmasını Yönet",
    billing_settings_required_for_e_fatura: "E-Fatura’nızı oluşturabilmek için fatura bilgileri (örn. şirket kayıt numarası, müşteri kimlik numarası) gereklidir.",
    add_billing_settings: "Fatura bilgilerinizi ekleyin.",
    processing: "İşleme",
    download_invoice: "Faturayı İndir",
    invoice_for: "{{product}} için fatura",
    payment: "Ödeme Makbuzu",
    billing_history: "Faturalandırma Geçmişi",
    date: "Tarih",
    add_billing_settings_information: "Fatura Bilgilerini Ekle",
    amount: "Miktar",
    applications_list_mode: "Liste",
    applications_kanban_mode: "Pano görünümü",
    comments_count_label: "{{count}} notlar"
  }
}
