import React from "react"
import { useTranslation } from "react-i18next"

import Button from "@material-ui/core/Button"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import useStyles from "../styles"
interface Props {
  stage: number
  readOnly: boolean
  handleUpdate: (value: any) => void
}

const ApplicantStage: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const statusCode = props.stage

  const statuses = [
    { value: 0, label: "" },
    { value: 1, label: "" },
    { value: 2, label: "" },
    { value: 3, label: "" },
    { value: 4, label: "" },
    { value: 5, label: "" },
    { value: 6, label: "" },
    { value: 7, label: "" },
    { value: 8, label: "" }
  ]

  statuses.forEach(item => {
    item.label = t(`stage_id_${item.value}`)
  })

  return (
    <div
      className={"d-inline-block va-middle"}
      style={{
        display: "flex",
        alignItems: "center"
        // marginLeft: '10px'
      }}
    >
      <Typography variant="body1" className={classes.label} style={{ marginRight: "5px" }}>
        {t("status")}:{" "}
      </Typography>
      {!props.readOnly ? (
        <FormControl>
          <TextField
            select
            disabled={props.readOnly}
            placeholder={t("status")}
            value={statusCode}
            onChange={event => {
              props.handleUpdate(event.target.value)
            }}
            helperText=""
            margin="dense"
            variant="outlined"
            style={{ margin: "0px" }}
          >
            {statuses.map(option => (
              <MenuItem key={option.value} disabled={option.value === 0} value={option.value} style={{ paddingRight: "10px" }}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      ) : (
        <div style={{ marginTop: "5px" }}>
          <Typography variant="body1" style={{ display: "inline", marginRight: "10px", marginLeft: "10px" }}>
            {t("stage")}:
          </Typography>
          <Button disabled variant="outlined" style={{ height: "39px" }}>
            {t(`stage_id_${statusCode}`)}
          </Button>
        </div>
      )}
    </div>
  )
}

export default ApplicantStage
