import React from "react"
import sort from "fast-sort"
import { useDispatch, useSelector } from "react-redux"
import { jobsActions } from "../../../../store/actions/jobs"

import { IApplicantFilters, IApplicantSearchParams, IPaginatedApplicants } from "../../../../types/applicants"
import { ICompany } from "../../../../types/company"
import { IJob } from "../../../../types/jobs"
import GenericFilter from "../GenericFilter"

import useStyles from "../styles"

interface Props {
  company: ICompany
  activeFilter: boolean
  filters: IApplicantFilters
  applicants: IPaginatedApplicants
  applyFilters: (filters: IApplicantSearchParams, page: number) => void
}

const JobsFilter: React.FC<Props> = props => {
  const classes = useStyles()

  const jobs: IJob[] | null = useSelector((state: any) => state.jobsReducer.jobs)
  const dispatch = useDispatch()

  React.useEffect(() => {
    const fetchJobs = async () => {
      dispatch(await jobsActions.fetchCompanyJobs(props.company.id))
    }
    if (!jobs) {
      fetchJobs()
    }
  }, [props.company, jobs, dispatch])

  const handleSubmit = async (filters: any) => {
    await props.applyFilters(
      {
        ...filters,
        activeFilter: props.activeFilter,
        companyId: props.company.id,
      },
      1
    )
  }

  let rows = jobs

  if (rows) {
    rows = sort(rows ? [...rows] : []).desc([(job: any) => job.is_published, (job: any) => job.updated_at])
  }

  return (
    <div className={classes.singleFilterDiv}>
      <GenericFilter
        field="job"
        options={
          rows
            ? rows.map(item => ({
                label: item.title,
                value: item.id,
                quantity: props.applicants ? [...props.applicants.data].filter(applicant => applicant.job_id === item.id).length : undefined
              }))
            : []
        }
        filters={props.filters}
        applicants={props.applicants}
        handleApplyClick={handleSubmit}
      />
    </div>
  )
}

export default JobsFilter
