import React from "react"
import { makeStyles } from "@material-ui/core"

import Link from "@material-ui/core/Link"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 3)
  },
  textDiv: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center"
    }
  },
  exclusive: {
    display: "inline",
    paddingRight: "20px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center !important",
      paddingRight: "0px"
    }
  },
  caption: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center !important",
      paddingRight: "0px"
    }
  },
  upgradeDiv: {
    width: "100%",
    backgroundColor: "#DFF7E7",
    padding: "8px",
    minHeight: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center"
    }
  },
  learnMore: {
    border: "0.5px solid #eaeaea",
    background: "#fff",
    color: "black",
    borderRadius: "3px",
    padding: "5px",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      padding: "3px"
    }
  }
}))

interface Props {}

const BenefitsSummary: React.FC<Props> = props => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          {t("benefits_summary")}
        </Typography>
        <div className={classes.upgradeDiv}>
          <div className={classes.textDiv}>
            <Typography variant="body1" className={classes.exclusive}>
              {t("exclusive_feature")}
            </Typography>
            <Typography variant="body2" className={classes.caption}>
              {t("benefits_subtext")}
            </Typography>
          </div>
          <Link
            variant="button"
            href={"https://employer.iskibris.com/irketinizin-sunduu-ek-faydalar-profilinize-nasl-ekleyebilirsiniz"}
            target="_blank"
            className={classes.learnMore}
          >
            {t("learn_more")}
          </Link>
        </div>
      </Paper>
    </React.Fragment>
  )
}

export default BenefitsSummary
