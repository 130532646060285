import Fuse from "fuse.js"
import axios from "../../api/iskibris/iskibris"
import { actionTypes } from "./actionTypes"
import { create, fetch, remove, update } from "../../api/iskibris/redux-api-methods"
import { ICompany } from "../../types/company"
import { action } from "typesafe-actions"
import { AxiosResponse } from "axios"

let options = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0.5,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    {
      name: "name",
      weight: 0.9
    },
    {
      name: "headquarter_city_name",
      weight: 0.1
    }
  ]
}

export const companyActions = {
  fetchUserManagedCompanies: async () => {
    return await fetch({
      url: `/api/hiring/v2/companies`,
      onSuccessDispatch: actionTypes.ADD_USER_COMPANIES,
      onErrorDispatch: actionTypes.ADD_USER_COMPANIES_ERROR
    })
  },
  fetchUserAbilities: async () => {
    return await fetch({
      url: `/api/hiring/v2/companies`,
      onSuccessDispatch: actionTypes.ADD_ABILITIES,
      onErrorDispatch: actionTypes.ADD_ABILITIES_ERROR
    })
  },
  fetchSingleCompany: async (id: number) => {
    return await fetch({
      url: `/api/hiring/v2/companies/${id}`,
      onSuccessDispatch: actionTypes.ADD_SINGLE_COMPANY_INFORMATION,
      onErrorDispatch: actionTypes.ADD_USER_COMPANIES_ERROR
    })
  },
  createCompany: async (info: any) => {
    return await create({
      info: info,
      url: `/api/hiring/v2/companies`,
      onSuccessDispatch: actionTypes.COMPANY_CREATED,
      onErrorDispatch: actionTypes.ADD_COMPANY_VALIDATION_ERRORS
    })
  },
  editCompany: async (info: any, id: number) => {
    return await update({
      info: info,
      url: `/api/hiring/v2/companies/${id}`,
      onSuccessDispatch: actionTypes.ADD_SINGLE_COMPANY_INFORMATION,
      onErrorDispatch: actionTypes.ADD_COMPANY_VALIDATION_ERRORS
    })
  },
  searchCompanies: (companies: ICompany[], query: string) => {
    const fuse = new Fuse(companies, options)
    let searchResults = fuse.search(query)
    searchResults = searchResults.map((data: any) => data.item)
    return action(actionTypes.ADD_COMPANY_SEARCH_RESULTS, searchResults)
  },
  fetchCompanyUsers: async (id: any) => {
    return await fetch({
      url: `/api/hiring/v2/companies/${id}/users`,
      onSuccessDispatch: actionTypes.ADD_COMPANY_USERS,
      onErrorDispatch: actionTypes.ADD_COMPANY_USERS_ERROR
    })
  },
  fetchPendingCompanyUserInvitaitons: async (id: any) => {
    return await fetch({
      url: `/api/hiring/v2/companies/${id}/company-user-invitations/pending`,
      onSuccessDispatch: actionTypes.ADD_COMPANY_USER_INVITATIONS,
      onErrorDispatch: actionTypes.ADD_COMPANY_USERS_ERROR
    })
  },
  updateCompanyUser: () => {},
  revokeCompanyUserInvitation: async (invitationId: any, companyId: any) => {
    return await remove({
      id: invitationId,
      url: `/api/hiring/v2/companies/${companyId}/company-user-invitations/${invitationId}/revoke`,
      onErrorDispatch: actionTypes.ADD_COMPANY_USERS_ERROR,
      onSuccessDispatch: actionTypes.REVOKE_COMPANY_USER_INVITATION
    })
  },
  deleteCompanyUser: async (userId: any, companyId: any) => {
    return await remove({
      id: userId,
      url: `/api/hiring/v2/companies/${companyId}/users/${userId}`,
      onErrorDispatch: actionTypes.ADD_COMPANY_USERS_ERROR,
      onSuccessDispatch: actionTypes.DELETE_COMPANY_USER
    })
  },
  fetchSectors: async () => {
    return await fetch({
      url: "/api/hiring/v2/sectors",
      onSuccessDispatch: actionTypes.ADD_SECTORS,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS
    })
  },
  fetchIndustries: async () => {
    return await fetch({
      url: "/api/hiring/v2/industries",
      onSuccessDispatch: actionTypes.ADD_ALL_INDUSTRIES,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS
    })
  },
  fetchSectorIndustries: async (id: number) => {
    return await fetch({
      url: `/api/hiring/v2/sectors/${id}/industries`,
      onSuccessDispatch: actionTypes.ADD_SECTOR_INDUSTRIES,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS
    })
  },
  editFormContent: (value: any) => {
    return action(actionTypes.EDIT_COMPANY_FORM_CONTENT, value)
  },
  selectSector: (value: any) => {
    return action(actionTypes.SELECT_SECTOR, value)
  },
  // addCompanyProfile: (data: any) => {
  //   return action(actionTypes.ADD_COMPANY_PROFILE_FORM), data);
  // },
  clearCompanyForm: () => {
    return action(actionTypes.CLEAR_COMPANY_FORM)
  },
  updateCompanyBenefit: async (information: any, benefitId: any) => {
    return await update({
      info: information,
      url: `/api/hiring/v2/companies/benefits/${benefitId}`,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.EDIT_COMPANY_BENEFIT
    })
  },
  fetchCompanyBenefits: async (id: any) => {
    return await fetch({
      url: `/api/hiring/v2/companies/${id}/benefits`,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.ADD_COMPANY_BENEFITS
    })
  },
  fetchCompanyPhotos: async (id: any) => {
    return await fetch({
      url: `/api/hiring/v2/companies/${id}/photos`,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.ADD_COMPANY_PHOTOS
    })
  },
  fetchCompanyUpdates: async (id: any) => {
    return await fetch({
      url: `/api/hiring/v2/companies/${id}/updates`,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.ADD_COMPANY_UPDATES
    })
  },
  updateCompanyLogo: (logo: string) => {
    return action(actionTypes.UPDATE_COMPANY_LOGO, logo)
  },
  updateCompanyCoverPhoto: (coverPhoto: string) => {
    return action(actionTypes.UPDATE_COMPANY_COVER_PHOTO, coverPhoto)
  },
  removeCompanyLogo: async (id: number) => {
    return await remove({
      id: id,
      url: `/api/hiring/v2/companies/${id}/logo`,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.DELETE_COMPANY_LOGO
    })
  },
  removeCompanyCoverPhoto: async (id: number) => {
    return await remove({
      id: id,
      url: `/api/hiring/v2/companies/${id}/cover-photo`,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.DELETE_COMPANY_COVER_PHOTO
    })
  },
  addCompanyPhotos: async (id: any, files: any) => {
    var formData = new FormData()
    let imageFiles = [...files]
    imageFiles.forEach(image => {
      formData.append("upload[]", image)
    })
    let response: AxiosResponse
    try {
      response = await axios.post(`/api/hiring/v2/companies/${id}/photos`, formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
    } catch (error: any) {
      response = error?.response
    }
    if (response?.status < 400) {
      return await companyActions.fetchCompanyPhotos(id)
    } else {
      return action(actionTypes.ADD_COMPANY_ACTION_ERRORS, response.data)
    }
  },
  deleteCompanyPhotos: async (companyId: any, photoId: any) => {
    let response: AxiosResponse
    try {
      response = await axios.delete(`/api/hiring/v2/companies/${companyId}/photos/${photoId}`)
    } catch (error: any) {
      response = error?.response
    }
    if (response?.status < 400) {
      return await companyActions.fetchCompanyPhotos(companyId)
    } else {
      return action(actionTypes.ADD_COMPANY_ACTION_ERRORS, response.data)
    }
  },
  addCompanyUpdate: async (info: any, id: any) => {
    return await create({
      url: `/api/hiring/v2/companies/${id}/updates`,
      info: info,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.ADD_SINGE_COMPANY_UPDATE
    })
  },
  updateCompanyUpdate: async (information: any, updateId: any) => {
    return await update({
      info: information,
      url: `/api/hiring/v2/companies/updates/${updateId}`,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.EDIT_COMPANY_UPDATE
    })
  },
  deleteCompanyUpdate: async (updateId: any) => {
    return await remove({
      id: updateId,
      url: `/api/hiring/v2/updates/${updateId}`,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.DELETE_COMPANY_UPDATE
    })
  },
  fetchCompanyLocations: async (id: any) => {
    return await fetch({
      url: `/api/hiring/v2/companies/${id}/locations`,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.ADD_COMPANY_LOCATIONS
    })
  },
  addCompanyLocation: async (info: any, id: any) => {
    return await create({
      url: `/api/hiring/v2/companies/${id}/locations`,
      info: info,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.ADD_SINGE_COMPANY_LOCATION
    })
  },
  updateCompanyLocation: async (information: any, id: any) => {
    return await update({
      info: information,
      url: `/api/hiring/v2/companies/locations/${id}`,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.EDIT_COMPANY_LOCATION
    })
  },
  deleteCompanyLocation: async (id: any) => {
    return await remove({
      id: id,
      url: `/api/hiring/v2/locations/${id}`,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.DELETE_COMPANY_LOCATION
    })
  },
  fetchCompanyFaqs: async (id: any) => {
    return await fetch({
      url: `/api/hiring/v2/companies/${id}/faq`,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.ADD_COMPANY_FAQS
    })
  },
  addCompanyFaq: async (info: any, id: any) => {
    return await create({
      url: `/api/hiring/v2/companies/${id}/faq`,
      info: info,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.ADD_SINGE_COMPANY_FAQ
    })
  },
  updateCompanyFaq: async (information: any, id: any) => {
    return await update({
      info: information,
      url: `/api/hiring/v2/companies/faq/${id}`,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.EDIT_COMPANY_FAQ
    })
  },
  deleteCompanyFaq: async (id: any) => {
    return await remove({
      id: id,
      url: `/api/hiring/v2/faq/${id}`,
      onErrorDispatch: actionTypes.ADD_COMPANY_ACTION_ERRORS,
      onSuccessDispatch: actionTypes.DELETE_COMPANY_FAQ
    })
  },
  companyCreated: (company: any) => {
    return action(actionTypes.COMPANY_CREATED, company)
  }
}
