import React from "react"

import { NavLink, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { connect, useDispatch, useSelector } from "react-redux"

import clsx from "clsx"
import List from "@material-ui/core/List"
import Drawer from "@material-ui/core/Drawer"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import OpenSideIcon from "@material-ui/icons/KeyboardArrowLeft"

import useStyles from "./styles"
import { ICompany } from "../../../types/company"
import { systemActions } from "../../../store/actions/system"
import { authActions } from "../../../store/actions/authentication"
// import { companyActions } from '../../../store/actions/company';

import axios from "../../../api/iskibris/iskibris"
import Cookies from "js-cookie"

interface Props {
  company: ICompany
  unauthenticate: () => void
}

const UserSideMenu: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const [open, setOpen] = React.useState(false)

  // const [openCompanyList, setOpenCompanyList] = React.useState(false);

  const openUserDrawer = useSelector((state: any) => state.systemReducer.userDrawerOpen)

  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }

  const dispatch = useDispatch()

  const handleDrawerItemClick = () => {
    if (openUserDrawer) {
      dispatch(systemActions.closeUserDrawer())
    }
  }

  const handleCompanyClick = () => {
    dispatch(systemActions.openCompanyMenu())
    handleDrawerItemClick()
  }

  const handleLanguageClick = () => {
    dispatch(systemActions.openLanguageMenu())
    handleDrawerItemClick()
  }

  const logout = async () => {
    props.unauthenticate()
    // await handleUnsubscribe();
    await axios.post("/api/logout")
    localStorage.removeItem("auth_token")
    localStorage.removeItem("refresh_token")
    localStorage.removeItem("current_company_id")
    localStorage.removeItem("CompanyToken")
    Cookies.remove("auth_token", { domain: process.env.REACT_APP_DOMAIN ?? ".iskibris.com" })
    Cookies.remove("CompanyToken")
    setTimeout(function () {
      history.push("/index")
    }, 500)
  }

  const NavLinkWithForwardRef = React.forwardRef((props: any, ref: any) => <NavLink innerRef={ref} {...props} exact />)
  NavLinkWithForwardRef.displayName = "NavLinkWithForwardRef"

  return (
    <React.Fragment>
      <Drawer
        elevation={28}
        variant="permanent"
        className={"sidebar"}
        anchor={"right"}
        classes={{
          paper:
            clsx({
              [classes.drawerOpen + " sidebar--open"]: open || openUserDrawer,
              [classes.drawerClose + " sidebar--closed"]: !open && !openUserDrawer
            }) + " sidebar__inner"
        }}
        open={open || openUserDrawer}
        onMouseOver={(event: any) => {
          if (event.target.dispatchEvent) {
            handleDrawerOpen()
          }
        }}
        onMouseLeave={event => {
          handleDrawerClose()
        }}
      >
        <div className={classes.contentDiv}>
          <List>
            <ListItem className={"sidebar__link"} onClick={handleCompanyClick} key="company">
              <ListItemIcon>
                <OpenSideIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" className={"sidebar__link-text"}>
                    {props.company.name}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            <Divider />
            <ListItem className={"sidebar__link"} onClick={handleLanguageClick} key="language">
              <ListItemIcon>
                <OpenSideIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" className={"sidebar__link-text"}>
                    {t("language")}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            <Divider />
            <ListItem className={"sidebar__link"} onClick={handleDrawerItemClick} key="subscriptions" component={NavLinkWithForwardRef} to="/billing/subscriptions">
              <ListItemIcon></ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" className={"sidebar__link-text"}>
                    {t("subscriptions")}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            <Divider />
            <ListItem className={"sidebar__link"} onClick={logout} key="logout">
              <ListItemIcon></ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" className={"sidebar__link-text"}>
                    {t("logout")}
                  </Typography>
                }
              ></ListItemText>
            </ListItem>
            {/* <ListItem className={"sidebar__link"} onClick={handleDrawerItemClick} key="jobs" component={NavLinkWithForwardRef} to="/jobs">
              <ListItemIcon className={"sidebar__link-icon"}>
                <JobsIcon />
              </ListItemIcon>
              <ListItemText disableTypography
                primary={<Typography variant="body2" className={'sidebar__link-text'}>{t('jobs')}</Typography>}></ListItemText>
            </ListItem>
            <Divider />
            <ListItem className={"sidebar__link"} onClick={handleDrawerItemClick} key="candidates" component={NavLinkWithForwardRef} to="/applicants">
              <ListItemIcon className={"sidebar__link-icon"}>
                <CandidatesIcon />
              </ListItemIcon>
              <ListItemText disableTypography
                primary={<Typography variant="body2" className={'sidebar__link-text'}>{t('candidates')}</Typography>}></ListItemText>
            </ListItem>
            <Divider />
            <ListItem className={"sidebar__link"} onClick={handleDrawerItemClick} key="employer_profile" component={NavLinkWithForwardRef} to="/employer/profile">
              <ListItemIcon className={"sidebar__link-icon"}>
                <EmployerProfileIcon />
              </ListItemIcon>
              <ListItemText disableTypography
                primary={<Typography variant="body2" className={'sidebar__link-text'}>{t('employer_profile')}</Typography>}></ListItemText>
            </ListItem>
            <Divider />
            <ListItem className={"sidebar__link"} onClick={handleDrawerItemClick} key="employer_tools" component={NavLinkWithForwardRef} to="/employer-tools">
              <ListItemIcon className={"sidebar__link-icon"}>
                <ToolsIcon />
              </ListItemIcon>
              <ListItemText disableTypography
                primary={<Typography variant="body2" className={'sidebar__link-text'}>{t('employer_tools')}</Typography>}></ListItemText>
            </ListItem>
            <Divider /> */}
          </List>
        </div>
      </Drawer>
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company
  }
}

const mapDispatchToProps = {
  unauthenticate: authActions.unauthenticate
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSideMenu)
