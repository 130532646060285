import React from "react"
import { useTranslation } from "react-i18next"

import { ICompany } from "../../../../../types/company"
import GenericFilter from "../GenericFilter"

import { IApplicantFilters, IApplicantSearchParams, IPaginatedApplicants } from "../../../../../types/applicants"

import useStyles from "../../styles"
import { applicantStages } from "../../../../../utils/data/enums"

interface Props {
  company: ICompany
  activeFilter: boolean
  filters: IApplicantFilters
  applicants: IPaginatedApplicants
  handleGoBack: () => void
  applyFilters: (filters: IApplicantSearchParams, page: number) => void
}

const enumOptions = applicantStages.map(item => (
  {
   value: item, label: "", quantity: 0 
  }
))

const StageEnumFilter: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleSubmit = async (filters: any) => {
    await props.applyFilters(
      {
        ...filters,
        activeFilter: props.activeFilter,
        companyId: props.company.id,
      },
      1
    )
  }

  return (
    <div className={classes.singleFilterDiv}>
      <GenericFilter
        field="stage_enum"
        options={enumOptions.map(item => ({
          value: item.value,
          label: t(`stage_id_enum_${item.value}`),
          quantity: props.applicants ? props.applicants.data.filter(applicant => applicant.stage_id_enum === item.value).length : 0
        }))}
        filters={props.filters}
        applicants={props.applicants}
        handleApplyClick={handleSubmit}
        handleGoBack={props.handleGoBack}
      />
    </div>
  )
}

export default StageEnumFilter
