import React, { useState } from "react"
import axios from "../../../../api/iskibris/iskibris"
import { connect } from "react-redux"

import { TextField, FormControl, Button, CircularProgress, MenuItem } from "@material-ui/core"

import { useTranslation } from "react-i18next"

import LocationMap from "./LocationMap"
import { ICompany, ICompanyLocation } from "../../../../types/company"
import NotificationSnackbar from "../../../NotificationSnackbar"
import { Dispatch } from "redux"
import { IActionType } from "../../../../types"
import { companyActions } from "../../../../store/actions/company"

let cityOptions = [
  { value: 0, label: "" },
  { value: 1, label: "" },
  { value: 2, label: "" },
  { value: 3, label: "" },
  { value: 4, label: "" },
  { value: 5, label: "" }
]

interface Props {
  company: ICompany
  location?: ICompanyLocation
  handleEditCancel?: () => void
  fetchCompanyLocations: (id: any) => void
}

const AddLocation: React.FC<Props> = props => {
  const { t } = useTranslation()

  cityOptions.forEach(item => {
    item.label = t(`city_id_${item.value}`)
  })

  const initialErrorState = {
    name_tr: undefined,
    name_en: undefined,
    company_id: undefined,
    city_id: undefined,
    latitude: undefined,
    longitude: undefined,
    directions: undefined,
    address: undefined,
    address_code: undefined
  }

  const initialFormState = {
    name_tr: props.location ? props.location.name_tr || "" : "",
    name_en: props.location ? props.location.name_en || "" : "",
    company_id: props.company?.id,
    city_id: props.location ? props.location.city_id : 0,
    latitude: props.location ? props.location.latitude || "" : "",
    longitude: props.location ? props.location.longitude || "" : "",
    address: props.location ? props.location.address || "" : "",
    directions: props.location ? props.location.directions || "" : "",
    address_code: props.location ? props.location.address_code || "" : ""
  }

  const [submitting, setSubmitting] = useState(false)
  const [requestResult, setRequestResult] = useState<any>("")
  const [values, setValues] = useState({ ...initialFormState })
  const [errors, setErrors] = useState<any>({ ...initialErrorState })

  const setLocationDetails = (name: any) => (event: any) => {
    setValues({ ...values, [name]: event.target.value })
  }

  const fillValuesFromMap = (infor: any) => {
    setValues({
      ...values,
      address: infor?.address,
      latitude: infor?.latitude,
      longitude: infor?.longitude,
      address_code: infor?.address_code
    })
  }

  const handlePost = async () => {
    setSubmitting(true)
    setErrors({ ...initialErrorState })
    const data = {
      ...values,
      latitude: `${values.latitude}`,
      longitude: `${values.longitude}`,
      company_id: props.company.id
    }

    if (props.location) {
      await axios
        .put(`/api/companies/${props.company.id}/locations/${props.location.id}`, { ...data })
        .then(response => {
          props.fetchCompanyLocations(props.company.id)
          setRequestResult("success")
        })
        .catch(err => {
          setErrors({
            ...err?.response?.data?.errors
          })
          setRequestResult("fail")
        })
    } else {
      await axios
        .post(`/api/companies/${props.company.id}/locations`, { ...data })
        .then(response => {
          props.fetchCompanyLocations(props.company.id)
          setRequestResult("success")
        })
        .catch(err => {
          setErrors({
            ...err?.response?.data?.errors
          })
          setRequestResult("fail")
        })
    }
    setSubmitting(false)
  }

  return (
    <div>
      <div style={{ marginBottom: "100px", height: "350px" }}>
        <LocationMap
          handleAddValuesFromMap={fillValuesFromMap}
          center={{ lat: props.location ? parseFloat(props.location.latitude) : 35.18556492572165, lng: props.location ? parseFloat(props.location.longitude) : 33.35895405273436 }}
          height="350px"
          zoom={15}
        />
      </div>
      <FormControl style={{ width: "50%", padding: "5px" }}>
        <TextField
          fullWidth
          variant="standard"
          margin="dense"
          placeholder={t("branch_name")}
          value={values.name_tr}
          onChange={setLocationDetails("name_tr")}
          error={!!errors.name_tr ? true : false}
          helperText={errors?.name_tr ? errors?.name_tr[0] : null}
        />
      </FormControl>
      <FormControl style={{ width: "50%", padding: "5px" }}>
        <TextField
          fullWidth
          variant="standard"
          margin="dense"
          placeholder={t("branch_name_en")}
          value={values.name_en}
          onChange={setLocationDetails("name_en")}
          error={!!errors.name_en ? true : false}
          helperText={errors?.name_en ? errors.name_en[0] : null}
        />
      </FormControl>
      <FormControl style={{ width: "50%", padding: "5px" }}>
        <TextField
          fullWidth
          variant="standard"
          margin="dense"
          placeholder={t("latitude")}
          value={values.latitude}
          onChange={setLocationDetails("latitude")}
          error={!!errors.latitude ? true : false}
          helperText={errors.latitude ? errors.latitude[0] : null}
        />
      </FormControl>
      <FormControl style={{ width: "50%", padding: "5px" }}>
        <TextField
          fullWidth
          variant="standard"
          margin="dense"
          placeholder={t("longitude")}
          value={values.longitude}
          onChange={setLocationDetails("longitude")}
          error={errors.longitude ? true : false}
          helperText={errors?.longitude ? errors?.longitude[0] : null}
        />
      </FormControl>
      <FormControl style={{ width: "50%", padding: "5px" }}>
        <TextField
          fullWidth
          select
          variant="standard"
          margin="dense"
          placeholder={t("city")}
          value={values.city_id}
          onChange={setLocationDetails("city_id")}
          error={errors.city_id ? true : false}
          helperText={errors?.city_id ? errors?.city_id[0] : null}
        >
          {cityOptions.map(option => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )
          })}
        </TextField>
      </FormControl>
      <FormControl style={{ width: "50%", padding: "5px" }}>
        <TextField
          fullWidth
          variant="standard"
          margin="dense"
          placeholder={t("address_code")}
          value={values.address_code}
          onChange={setLocationDetails("address_code")}
          error={errors.address_code ? true : false}
          helperText={errors?.address_code ? errors?.address_code[0] : null}
        />
      </FormControl>
      <FormControl fullWidth style={{ padding: "5px" }}>
        <TextField
          fullWidth
          multiline
          rows={3}
          variant="standard"
          margin="dense"
          placeholder={t("address")}
          value={values.address}
          onChange={setLocationDetails("address")}
          error={errors.address ? true : false}
          helperText={errors?.address ? errors?.address[0] : null}
        />
      </FormControl>
      <FormControl fullWidth style={{ padding: "5px" }}>
        <TextField
          fullWidth
          multiline
          rows={3}
          variant="standard"
          margin="dense"
          placeholder={t("directions")}
          value={values.directions}
          onChange={setLocationDetails("directions")}
        />
      </FormControl>
      <div style={{ marginTop: "20px" }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            props.handleEditCancel ? props.handleEditCancel() : console.log("There is not editing item")
          }}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          style={{
            float: "right"
          }}
          color="primary"
          onClick={handlePost}
        >
          {submitting ? <CircularProgress size="25px" color="secondary" /> : t("submit")}
        </Button>
        <NotificationSnackbar
          status={requestResult}
          success_msg={props.location ? t("edit_location_success") : t("create_location_success")}
          error_msg={props.location ? t("edit_location_fail") : t("create_location_fail")}
          handleClose={() => {
            if (props.location && props.handleEditCancel) {
              setRequestResult("")
              props.handleEditCancel()
            } else {
              setRequestResult("")
              setValues({ ...initialFormState })
            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company,
    locations: state.companyReducer.locations
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchCompanyLocations: async (companyId: any) => dispatch(await companyActions.fetchCompanyLocations(companyId)),
    addCompanyLocations: async (companyId: any, information: any) => dispatch(await companyActions.addCompanyLocation(companyId, information)),
    updateCompanyLocation: async (information: any, locationId: any) => dispatch(await companyActions.updateCompanyLocation(information, locationId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddLocation)
