import React from "react"
import { useTranslation } from "react-i18next"
import { Font, usePDF } from "@react-pdf/renderer"

import Button from "@material-ui/core/Button"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"

import PrintOutDocument from "./PrintOutDocument"

import { IUser } from "../../../types/user"
import { IApplicant } from "../../../types/applicants"
import { Backdrop, CircularProgress, Fade, Modal, Toolbar, Typography, useMediaQuery, useTheme } from "@material-ui/core"

import api from "../../../api/iskibris/iskibris"
import { extractExtension } from "../../../utils/_extractExtension"

import { PDFDocument } from "pdf-lib"
import useStyles from "./styles"

import { parseToHttps } from "../../../utils/urlMiddleware"

interface Props {
  user: IUser
  applicant: IApplicant
  children?: React.ReactNode
}

interface ApplicantPrintOutProps {
  user: IUser
  applicant: IApplicant
  onClose: () => void
}

Font.register({
  family: "Roboto",
  src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf"
})

const DownloadApplicant: React.FC<Props> = ({ user, applicant, children }) => {
  const [open, setOpen] = React.useState<boolean>(false)

  const theme = useTheme()
  const { t } = useTranslation()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("xs"))

  const classes = useStyles()

  const handleClick = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <div onClick={handleClick}>
        {
          children
          ?
          (
            <>{children}</>
          )
          :
          (
            <Button color="secondary" onClick={handleClick}>
              <CloudDownloadIcon color="secondary" style={{ marginRight: !matchesMobile ? 10 : 0 }} />
              <span style={{ display: !matchesMobile ? "inherit" : "none" }}>{t("download_application")}</span>
            </Button>
          )
        }
      </div>
      {open && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.modalPaper}>
              <Toolbar variant="dense" className={classes.toolbar}>
                <Typography variant="h6" style={{ textAlign: "center" }} gutterBottom>
                  {t("download_application")}
                </Typography>
              </Toolbar>
              <div className={classes.contents}>
                <Typography variant="body1" gutterBottom>
                  {t("download_application_body")}
                </Typography>
                <div className={classes.buttons}>
                  <Button onClick={handleClose} variant="outlined" color="primary">
                    {t("cancel")}
                  </Button>
                  <ApplicantPrintOut user={user} applicant={applicant} onClose={handleClose} />
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      )}
    </div>
  )
}

const ApplicantPrintOut: React.FC<ApplicantPrintOutProps> = ({ user, applicant, onClose }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState<boolean>(false)

  const [instance] = usePDF({ document: <PrintOutDocument user={user} applicant={applicant} /> })

  const convertFileOnServer = async (url?: string) => {
    if (url) {
      var formData = new FormData()
      const blob = await fetch(url).then(r => r.blob())
      formData.append("filename", blob)

      const response = await api.post("/api/convert-to-pdf", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json"
        }
      })

      if (response.status < 300) {
        return parseToHttps(response.data["converted_file_url"])
      }
    }
    return null
  }

  const copyPdfToPagesToAnother = async (pdfDoc: PDFDocument, copyUrl: string | null) => {
    if (copyUrl) {
      let copyPdfBytes
      try {
        copyPdfBytes = await fetch(copyUrl).then(res => res.arrayBuffer())
      } catch (error) {
        console.log("Error fetching copyPdfBytes: ", error)
        return pdfDoc
      }

      let copyPdfDocument
      try {
        copyPdfDocument = await PDFDocument.load(copyPdfBytes)
      } catch (error) {
        console.log("Error fetching creating copyPdfDocument: ", error)
        return pdfDoc
      }

      try {
        const copyDocPages = copyPdfDocument.getPages()

        if (copyDocPages) {
          for (let index = 0; index < copyDocPages.length; index++) {
            const [page] = await pdfDoc.copyPages(copyPdfDocument, [index])
            pdfDoc.addPage(page)
          }
          return pdfDoc
        }
      } catch (error) {
        console.log("Error fetching adding pdf pages: ", error)
        return pdfDoc
      }
    }

    return pdfDoc
  }

  const combinePdfs = async (pdfUrls: Array<string | null>) => {
    let pdfDoc = await PDFDocument.create({})

    for (let index = 0; index < pdfUrls.length; index++) {
      const url = pdfUrls[index]
      pdfDoc = await copyPdfToPagesToAnother(pdfDoc, url)
    }

    const pdfBytes = await pdfDoc.save()
    var blob = new Blob([pdfBytes], { type: "application/pdf" })
    const fileURL = URL.createObjectURL(blob)

    return fileURL
  }

  const createResumePdfFromImage = async (imageUrl: string | null) => {
    if (imageUrl) {
      const imageBytes = await fetch(imageUrl).then(res => res.arrayBuffer())
      const pdfDoc = await PDFDocument.create()

      let image

      if (extractExtension(imageUrl) === "png") {
        try {
          image = await pdfDoc.embedPng(imageBytes)
        } catch (error) {
          console.log("Error embedding png: ", error)
          return
        }
      } else {
        try {
          image = await pdfDoc.embedJpg(imageBytes)
        } catch (error) {
          console.log("Error embedding jpg: ", error)
          return
        }
      }

      const jpgDims = image.scale(1.0)

      const page = pdfDoc.addPage()

      page.drawImage(image, {
        x: 0,
        y: 0,
        width: jpgDims.width > page.getWidth() ? page.getWidth() : jpgDims.width,
        height: jpgDims.height
      })

      const pdfBytes = await pdfDoc.save()

      var blob = new Blob([pdfBytes], { type: "application/pdf" })
      const fileURL = URL.createObjectURL(blob)

      return fileURL
    }
  }

  const handleDownload = async () => {
    setLoading(true)

    let docsToBeCombinedUrls: Array<string | null> = []

    let resumeUrl = null
    let coverLetterUrl = instance.url

    if (applicant.resume_url != null && applicant.resume_url !== resumeUrl) {
      const formattedResumeUrl = parseToHttps(applicant.resume_url)
      const lastItem = extractExtension(formattedResumeUrl)
      if (lastItem === "doc" || lastItem === "docx" || lastItem === "odt") {
        resumeUrl = await convertFileOnServer(formattedResumeUrl)
      } else if (lastItem === "png" || lastItem === "jpg" || lastItem === "jpeg") {
        resumeUrl = await createResumePdfFromImage(formattedResumeUrl)
      } else {
        resumeUrl = formattedResumeUrl
      }
    }

    if (coverLetterUrl) {
      docsToBeCombinedUrls = [...docsToBeCombinedUrls, coverLetterUrl]
    }

    if (resumeUrl) {
      docsToBeCombinedUrls = [...docsToBeCombinedUrls, resumeUrl]
    }

    const combinedPdfUrl = await combinePdfs(docsToBeCombinedUrls)
    window.open(combinedPdfUrl, "Download")

    setLoading(false)
    onClose()
  }

  return (
    <div className="user-profile-preview__download-button-container" key={applicant?.id}>
      {user ? (
        <>
          {instance.loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button color="secondary" variant="contained" onClick={handleDownload}>
              {loading ? <CircularProgress size={22} /> : t("download_application")}
            </Button>
          )}
        </>
      ) : null}
    </div>
  )
}

export default DownloadApplicant
