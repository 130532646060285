import React from "react"
// import { BrowserRouter } from "react-router-dom"
// Screens
import { BrowserRouter, Route, Switch } from "react-router-dom"
import NotFound from "../pages/NotFound"
import Dashboard from "../pages/Dashboard"
import LoginRegister from "../pages/Auth/LoginRegister"
import AuthProtectedRoute from "../components/AuthProtectedRoute"
import NoCompanyManaged from "../pages/Employer/NoCompanyManaged"
import JobsManagement from "../pages/Jobs/JobsRouter"
import EmployerTools from "../pages/Employer/EmployerTools"
import Settings from "../pages/Settings"
import EmployerProfile from "../pages/Employer/EmployerProfile"
import ApplicantsManagement from "../pages/Applicants/Applicants"
import ApplicantsManagementV2 from "../pages/ApplicantsV2/ApplicantsV2"
import CreateCompany from "../pages/Employer/EmployerCreate"
// import PricingPage from "../pages/PricingPage"
import SettingsError from "../pages/Settings/SettingsError"
import CompanyUserInvitation from "../pages/Employer/CompanyUserInvitation"
// import AccountSettingsManagement from "../pages/AccountSettings"
import UserAccountSettingsManagement from "../pages/UserAccountSettings"
import AtsSubscriptionLandingPage from "../pages/AtsSubscriptionLandingPage"
import Billing from "../pages/Billing"
import PricingPage from "../pages/PricingPage"
import RouteRedirect from "../components/RouteRedirect"

interface Props {
  isAuthenticated: boolean
}

const HiringRoutes: React.FC<Props> = ({ isAuthenticated }) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={LoginRegister} exact />
        <Route path="/login" component={LoginRegister} exact />
        <Route path="/index" component={LoginRegister} exact />
        <Route path="/register" component={LoginRegister} exact />
        <Route path="/join-your-team" component={LoginRegister} exact />
        <Route path="/index.htm" component={LoginRegister} exact />
        <Route path="/index.html" component={LoginRegister} exact />
        <Route path="/login-register" component={LoginRegister} exact />
        <Route path="/forgot-password" component={LoginRegister} exact />
        <Route path="/register/:referrer" component={LoginRegister} exact />
        <Route path="/password/reset/:token" component={LoginRegister} exact />
        <Route path="/employers/signup" component={NoCompanyManaged} exact />
        <AuthProtectedRoute path="/settings" component={Settings} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/dashboard" component={Dashboard} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/jobs" component={JobsManagement} isAuthenticated={isAuthenticated} />
        <AuthProtectedRoute path="/employer-tools" component={EmployerTools} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/employer/create" component={CreateCompany} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/employer/profile" component={EmployerProfile} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/employer/profile/info" component={EmployerProfile} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/employer/profile/story" component={EmployerProfile} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/employer/profile/photos" component={EmployerProfile} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/employer/profile/updates" component={EmployerProfile} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/employer/profile/locations" component={EmployerProfile} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/employer/profile/info/benefits" component={EmployerProfile} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/employer/profile/faqs" component={EmployerProfile} isAuthenticated={isAuthenticated} exact />
        {/* <AuthProtectedRoute path="/account" component={UserAccountSettingsManagement} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/account/notifications" component={UserAccountSettingsManagement} isAuthenticated={isAuthenticated} exact /> */}

        <AuthProtectedRoute path="/user/settings/account" component={UserAccountSettingsManagement} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/user/settings/notifications" component={UserAccountSettingsManagement} isAuthenticated={isAuthenticated} exact />

        <AuthProtectedRoute path="/settings/templates" component={Settings} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/settings/users/management" component={Settings} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/settings/error" component={SettingsError} isAuthenticated={isAuthenticated} exact />

        <AuthProtectedRoute path="/settings/subscriptions" component={() => <RouteRedirect to="/billing/subscriptions" />} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/settings/billing" component={() => <RouteRedirect to="/billing/invoices" />} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/settings/preferences" component={() => <RouteRedirect to="/settings" />} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/settings/packages" component={() => <RouteRedirect to="/billing/pricing" />} isAuthenticated={isAuthenticated} exact />
        {/* <AuthProtectedRoute path="/settings/billing" component={Settings} isAuthenticated={isAuthenticated} exact /> */}
        {/* <AuthProtectedRoute path="/settings/preferences" component={Settings} isAuthenticated={isAuthenticated} exact /> */}
        {/* <AuthProtectedRoute path="/settings/packages" component={Settings} isAuthenticated={isAuthenticated} exact /> */}
        {/* <AuthProtectedRoute path="/settings/packages/:id/activate" component={Settings} isAuthenticated={isAuthenticated} exact /> */}
        
        <AuthProtectedRoute path="/pricing" component={PricingPage} isAuthenticated={isAuthenticated} exact />

        <AuthProtectedRoute path="/billing" component={Billing} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/billing/subscriptions" component={Billing} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/billing/invoices" component={Billing} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/billing/settings" component={Billing} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/billing/pricing" component={Billing} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/billing/error" component={Billing} isAuthenticated={isAuthenticated} exact />
        
        {/* <AuthProtectedRoute path="/packages" component={PricingPage} isAuthenticated={isAuthenticated} exact /> */}
        <AuthProtectedRoute path="/applicants" component={ApplicantsManagementV2} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/applicants/archived" component={ApplicantsManagement} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/applicants/:id" component={ApplicantsManagementV2} isAuthenticated={isAuthenticated} exact />
        <AuthProtectedRoute path="/features/ats" component={AtsSubscriptionLandingPage} isAuthenticated={isAuthenticated} exact />
        <Route path="/invitation/:id" component={CompanyUserInvitation} /* isAuthenticated={isAuthenticated} */ exact /> 
        <AuthProtectedRoute component={NotFound} isAuthenticated={isAuthenticated} exact />
      </Switch>
    </BrowserRouter>
  )
}

export default HiringRoutes
