import React from "react"

import { useDispatch, useSelector } from "react-redux"
import { NavLink, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

import Link from "@material-ui/core/Link"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import useStyles from "./NavBar.styles"
import iKLogo from "../../assets/images/logo.png"
import LanguageSelector from "./LanguageSelector"
import CompanyMenu from "./CompanyMenu"
import UserMenu from "./UserMenu"

import { systemActions } from "../../store/actions/system"
import MobileCompanyMenu from "../MobileComponents/MobileCompanyMenu"
import MobileLanguageMenu from "../MobileComponents/MobileLanguageMenu"
import UserSwipeableSideMenu from "../MobileComponents/UserSwipeableSideMenu"
import SwipeableMainSideMenu from "../MobileComponents/SwipeableMainSideMenu"
import { userActions } from "../../store/actions/user"
import ImpersonationWarning from "../Impersonation/ImpersonationWarning"
import NotificationCenter from "../NotificationCenter/NotificationCenter"
import { Button } from "@material-ui/core"

interface Props {}

const NavBar: React.FC<Props> = props => {
  const classes = useStyles()
  const history = useHistory()
  const { t, i18n } = useTranslation()

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const companyMenuOpen = useSelector((state: any) => state.systemReducer.companyMenuOpen)
  const languageMenuOpen = useSelector((state: any) => state.systemReducer.languageMenuOpen)
  
  const isImpersonating = useSelector((state: any) => state.impersonationReducer.is_impersonating)
  
  const dispatch = useDispatch()

  const changeLanguage = async (value: string) => {
    i18n.changeLanguage(value)
    dispatch(await userActions.updateProfile({ locale: value }))
  }

  const handleCloseCompanyMenu = () => {
    dispatch(systemActions.closeCompanyMenu())
  }

  const handleCloseLanguageMenu = () => {
    dispatch(systemActions.closeLanguageMenu())
  }

  const NavLinkWithForwardRef = React.forwardRef((props: any, ref: any) => <NavLink innerRef={ref} {...props} exact />)
  NavLinkWithForwardRef.displayName = "NavLinkWithForwardRef"

  return (
    <AppBar className={classes.appBar}>
      {isImpersonating && <ImpersonationWarning />}
      <Toolbar className={classes.toolbar}>
        <div className={classes.left}>
          {history.location.pathname !== "/dashboard" ? <SwipeableMainSideMenu /> : <div style={{ width: "48px" }} />}
          <Link to="/dashboard" component={NavLinkWithForwardRef} className={classes.logoBar}>
            <img src={iKLogo} alt="Is Kibris" className={classes.logotypeImage} />
            <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
              {t("for_employers")}
            </Typography>
          </Link>
          <div className={classes.notificationsAndMenuContainer}>
            <NotificationCenter />
            <UserSwipeableSideMenu />
          </div>
        </div>
        <div className={classes.right}>
          <Button color="secondary" variant="contained" href="/billing/pricing" style={{marginRight: "5px"}}>{t("buy_new_package")}</Button>
          <NotificationCenter />
          <LanguageSelector changeLanguage={changeLanguage} />
          <CompanyMenu />
          <UserMenu />
          {matchesMobile && companyMenuOpen ? <MobileCompanyMenu handleClose={handleCloseCompanyMenu} /> : null}
          {matchesMobile && languageMenuOpen ? <MobileLanguageMenu changeLanguage={changeLanguage} handleClose={handleCloseLanguageMenu} /> : null}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
