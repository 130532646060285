import { TextField } from "@material-ui/core"
import React from "react"
import { Controller } from "react-hook-form"

// import React from "react"

interface Props {
  name: any
  control: any
  errors?: any
  label: any
  required?: boolean
  multiline?: any
  placeholder?: any

  rows?: any
}

export const FormInputText: React.FC<Props> = ({ name, control, label, required, multiline, rows, placeholder, errors }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      render={({ field: { onChange, value } }) => (
        <>
          <TextField
            error={errors?.[name]}
            required={required}
            fullWidth
            multiline={multiline}
            rows={rows}
            margin="dense"
            variant="outlined"
            placeholder={placeholder}
            helperText={errors?.[name]?.message.toString()}
            onChange={onChange}
            value={value || ""}
            label={label}
          />
        </>
      )}
    />
  )
}
