import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.4!important",
      color: "#FFFFFF",
      transition: "0.5s"
    },
    display: "block",
    width: "160px",
    height: "160px",
    border: "1px solid #B6BBC2",
    marginTop: "20px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "135px",
      height: "135px"
    }
  },
  photo: {
    width: "160px",
    height: "160px",
    border: "1px solid #B6BBC2",
    [theme.breakpoints.down("sm")]: {
      width: "135px",
      height: "135px"
    }
  },
  buttonDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "160px",
    minWidth: "160px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "135px",
      minWidth: "135px",
      width: "135px",
      height: "135px"
    }
  }
}))

export default useStyles
