import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  // root: {
  //   display: 'inline',
  //   position: 'relative',
  //   zIndex: 2,
  //   [theme.breakpoints.down("sm")]: {
  //     display: "none"
  //   }
  // },
  button: {
    color: "white",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {}
  },
  // paper: {
  //   position: 'absolute',
  //   top: 36,
  //   width: '150px'
  // },
  // ratingsContainer: {
  //   backgroundColor: theme.palette.background.paper,
  // },
  // applyButton: {
  //   backgroundColor: theme.palette.primary.main,
  //   color: theme.palette.common.white,
  //   float: 'right',
  // },
  // cancelButton: {
  //   float: 'right',
  //   paddingRight: '10px'
  // },
  // buttonArea: {
  //   padding: '10px',
  //   float: 'right',
  //   width: '100%'
  // },
  // shortLabel: {
  //   float: "right",
  // },
  rightIcon: {},
  avatar: {
    margin: 5,
    width: "30px",
    height: "30px"
  },
  icon: {
    marginLeft: "10px"
  },
  root: {
    // display: 'flex',
    // alignItems: 'center',
    // padding: "10px",
    // position: 'relative',
    // zIndex: 2,
    // [theme.breakpoints.up("md")]: {
    //   display: "none"
    // },
    // height: "50px",
  },
  paper: {
    position: "absolute",
    top: 36,
    width: "300px"
  },
  ratingsContainer: {
    overflow: "auto",
    maxHeight: 300,
    backgroundColor: theme.palette.background.paper
  },
  applyButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    float: "right"
  },
  cancelButton: {
    float: "right",
    paddingRight: "10px"
  },
  buttonArea: {
    padding: "10px",
    float: "right",
    width: "100%"
  },
  shortLabel: {
    float: "right"
  }
}))

export default useStyles
