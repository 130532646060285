import React from "react"
import { makeStyles } from "@material-ui/core"
import Snackbar from "@material-ui/core/Snackbar"
import Typography from "@material-ui/core/Typography"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: "#52A148"
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  icon: {
    fontSize: 20,
    marginRight: 10
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}))

interface Props {
  status: string
  success_msg?: string
  error_msg?: string
  handleClose: () => void
}

const NotificationSnackbar: React.FC<Props> = props => {
  const classes = useStyles()

  let snackContent

  if (props.status === "success") {
    snackContent = (
      <SnackbarContent
        className={classes.success}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <CheckCircleIcon className={classes.icon} />
            <Typography>{props.success_msg}</Typography>
          </span>
        }
      />
    )
  } else if (props.status === "fail") {
    snackContent = (
      <SnackbarContent
        className={classes.error}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <ErrorIcon className={classes.icon} />
            <Typography>{props.error_msg}</Typography>
          </span>
        }
      />
    )
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={props.status.length > 0}
        autoHideDuration={3000}
        onClose={props.handleClose}
      >
        {snackContent}
      </Snackbar>
    </div>
  )
}

export default NotificationSnackbar
