import React from "react"
import AsyncSelect from "react-select/async"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import Fuse from "fuse.js"
import { ICompanyIndustry } from "../../../../../types/company"
import { companyActions } from "../../../../../store/actions/company"
import { IActionType } from "../../../../../types"
import { Dispatch } from "redux"

let options = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0.55,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 4,
  keys: [
    {
      name: "name_tr",
      weight: 0.6
    },
    {
      name: "name_en",
      weight: 0.4
    }
  ]
}

const fonts = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"'
].join(",")

const styles = {
  control: (base: any) => ({
    ...base,
    fontFamily: fonts,
    paddingLeft: "10px",
    background: "transparent",
    fontSize: "1rem"
  }),
  menu: (base: any) => ({
    ...base,
    fontFamily: fonts,
    zIndex: 3
  })
}

interface Props {
  industries: ICompanyIndustry[]
  value: any
  fetchIndustries: () => void
  handleSelect: (value: any) => void
}

const IndustryInput: React.FC<Props> = props => {
  const { t, i18n } = useTranslation()

  // const { industries, fetchIndustries} = props;

  // useEffect(()=>{
  //   if(!industries){
  //     fetchIndustries();
  //   }
  // }, [industries, fetchIndustries])

  const getOptions = async (inputValue: any) => {
    let ourHits: any = []
    if (props.industries) {
      if (!inputValue || inputValue?.length === 0) {
        ourHits = props.industries
      } else {
        const fuse = new Fuse(props.industries, options)
        const results = fuse.search(inputValue)
        if (results.length > 15) {
          ourHits = results.slice(0, 15)
        } else {
          ourHits = results
        }
      }
    }
    return ourHits
  }

  const promiseOptions = (inputValue: any) =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(getOptions(inputValue))
      }, 500)
    })

  const handleChange = (event: any) => {
    props.handleSelect(event)
  }

  const fetchOptionLabel = (option: any) => {
    return i18n.language === "en" ? option.name_en : option.name_tr
  }

  const getOptionValue = (option: any) => {
    return option["id"]
  }

  return (
    <AsyncSelect
      required
      isClearable
      cacheOptions
      defaultOptions={props.industries}
      value={props.value}
      onChange={handleChange}
      getOptionLabel={fetchOptionLabel}
      loadOptions={promiseOptions}
      placeholder={t("industry_label")}
      label={t("industry_label")}
      getOptionValue={getOptionValue}
      styles={styles}
    />
  )
}

const mapStateToProps = (state: any) => {
  return {
    industries: state.companyReducer.industries
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchIndustries: async () => dispatch(await companyActions.fetchIndustries())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndustryInput)
