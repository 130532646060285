import React from "react"
import { useTranslation } from "react-i18next"

import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"

import BenefitsSummary from "./BenefitsSummary"
import BenefitsAndPledges from "./BenefitsAndPledgesSection.js"
import { useHistory } from "react-router"

interface Props {}

const Benefits: React.FC<Props> = props => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleSaveClick = () => {
    history.push("/employer/profile/info")
    // props.handleDone()
  }
  return (
    <div>
      <BenefitsSummary />
      <BenefitsAndPledges />
      <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
      <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <Button color="primary" variant="contained" onClick={handleSaveClick}>
          {t("back_to_company_info")}
        </Button>
      </div>
    </div>
  )
}

export default Benefits
