import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 3)
  },
  gridItem: {
    paddingRight: "25px!important"
  },
  gridContainer: {
    marginBottom: "20px",
    marginTop: "20px"
  },
  divider: {
    marginTop: "16px",
    marginBottom: "16px"
  }
}))

export default useStyles
