import React from "react"
import { useTranslation } from "react-i18next"
import TextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"
import useStyles from "./styles"
import { IApplicantFilters } from "../../../../types/applicants"
import { ICompany } from "../../../../types/company"
import OverlayLoader from "../../../OverlayLoader"

interface Props {
  company: ICompany
  activeFilter: boolean
  filters: IApplicantFilters
  applyFilters: (values: any, page: number) => void
}

const SearchBar: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const [keywords, setKeyWords] = React.useState<string | null>(null)

  React.useEffect(() => {
    if (keywords && keywords === "/") {
      setKeyWords("")
    }
  }, [keywords])

  const handleKeywordsChange = (event: any) => {
    setKeyWords(event.target.value)
  }

  const handleSearch = async () => {
    setLoading(true)
    await props.applyFilters(
      {
        ...props.filters,
        activeFilter: props.activeFilter,
        companyId: props.company.id,
        keyword: keywords,
      },
      1
    )
    setLoading(true)
  }

  const handleMouseDown = (event: any) => {
    event.preventDefault()
  }

  return (
    <div className={classes.root}>
      <form onSubmit={handleSearch}>
        <TextField
          autoFocus
          fullWidth
          margin="dense"
          variant="outlined"
          name="keyword"
          type="search"
          id="keyword"
          value={keywords || ""}
          className={classes.textfield}
          placeholder={t("search_placeholder")}
          onChange={handleKeywordsChange}
          onSubmit={handleSearch}
          onKeyPress={event => {
            if (event.key === "Enter") {
              handleSearch()
              event.preventDefault()
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div style={{ borderLeft: "1px solid #eaeaea" }} />
                <IconButton edge="end" aria-label="toggle" onClick={handleSearch} onMouseDown={handleMouseDown}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </form>
      {loading && (
        <OverlayLoader
          open={loading}
          handleClose={() => {
            setLoading(false)
          }}
        />
      )}
    </div>
  )
}

export default SearchBar
