import React from "react"

import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useHistory, useLocation } from "react-router"
import { useTranslation } from "react-i18next"

import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import CssBaseline from "@material-ui/core/CssBaseline"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { IActionType } from "../../types"
import NavBar from "../../components/NavBar/NavBar"
import SideBar from "../../components/SideBar"
import TabPanel from "../../components/TabPanel"
import Copyrights from "../../components/Copyright"

import { ICompany } from "../../types/company"
import Subscription from "../../components/Settings/Subscription"
import UserSideMenu from "../../components/MobileComponents/UserSideMenu"
import Pricing from "../../components/Pricing"
import { billingActions } from "../../store/actions/billing"
import { IBillingPaymentResult } from "../../types/billing"
import { makeStyles } from "@material-ui/core"
import useSearchParams from "../../hooks/useSearchParams"
import PaymentHistory from "../../components/Settings/PaymentHistory"
import BillingSettings from "../../components/Settings/BillingSettings"
import BillingSettingsRequiredWarning from "../../components/Settings/BillingSettingsRequiredWarning"

const useStyles = makeStyles(theme => ({
  error: {
    width: "100%",
    padding: "15px 25px",
    marginBottom: "10px",
    color: theme.palette.common.white,
    background: theme.palette.error.main,
    borderRadius: "5px"
  },
  success: {
    width: "100%",
    padding: "15px 25px",
    marginBottom: "10px",
    color: theme.palette.common.white,
    background: theme.palette.success.main,
    borderRadius: "5px"
  }
}))
interface Props {
  company: ICompany
  billingResult: IBillingPaymentResult | null
  fetchBillingResults: (key: any) => void
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  }
}

const BillingManagement: React.FC<Props> = props => {
  const { t } = useTranslation()

  const handleChange = (event: any, value: string) => {
    history.push(value)
  }

  const history = useHistory()
  const location = useLocation()

  const classes = useStyles()
  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [searchAsObject] = useSearchParams()

  const { fetchBillingResults } = props

  React.useEffect(() => {
    const getPageTitle = () => {
      switch (history.location.pathname) {
        case "/billing/subscriptions":
          return t("settings_title_subscriptions")
        case "/billing/invoices":
          return t("settings_title_subscriptions")
        default:
          return t("settings_title")
      }
    }
    document.title = getPageTitle()
  }, [history.location.pathname, t])

  React.useEffect(() => {
    const containerId = searchAsObject.container_id

    if (containerId) {
      fetchBillingResults(containerId)
    }
  }, [location, fetchBillingResults, searchAsObject.container_id])

  const extractSubscriptionHistoryValue = () => {
    const pathname = history.location.pathname
    switch (pathname) {
      case "/billing/subscriptions":
        return pathname
      default:
        return "/billing"
    }
  }

  const getIsBillingSettingsRequired = () => {
    // return true;
    return !props.company?.invoice_email ?? !props.company?.registration_number ?? !props.company?.invoice_contact_first_name ?? !props.company?.invoice_contact_first_name
  }

  return (
    <div>
      <CssBaseline />
      <NavBar />
      <SideBar />
      {matchesMobile ? <UserSideMenu /> : null}
      <main className="content content--has-sidebar content--with-container" style={{ paddingTop: "62px" }}>
        <Typography variant="h5" className={"page-title"} gutterBottom>
          {t("billing")}
        </Typography>
        <Tabs
          value={history.location.pathname}
          // style={{width: "100%"}}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab label={t("subscriptions")} {...a11yProps("/billing" || "/billing/subscriptions")} value={extractSubscriptionHistoryValue()} />
          <Tab label={t("billing_history")} {...a11yProps("/billing/invoices")} value={"/billing/invoices"} />
          <Tab label={t("billing_settings")} {...a11yProps("/billing/settings")} value={"/billing/settings"} />
          <Tab label={t("pricing")} {...a11yProps("/billing/pricing")} value={"/billing/pricing"} />
        </Tabs>
        <Divider />
        <TabPanel value={history.location.pathname} index={extractSubscriptionHistoryValue()}>
          {props.billingResult ? (
            <React.Fragment>
              {props.billingResult.success ? (
                <div className={classes.success}>{props.billingResult.success}</div>
              ) : (
                <div className={classes.error}>{props.billingResult.error}</div>
              )}
            </React.Fragment>
          ) : null}
          <Subscription />
        </TabPanel>
        <TabPanel value={history.location.pathname} index={"/billing/invoices"}>
          {
            getIsBillingSettingsRequired() ? (
              <BillingSettingsRequiredWarning
                onAddBillingSettingsClick={() => {
                  history.push("/billing/settings")
                }}
              />
            ) : null
          }
          <PaymentHistory />
        </TabPanel>
        <TabPanel value={history.location.pathname} index="/billing/settings">
          <BillingSettings />
        </TabPanel>
        <TabPanel value={history.location.pathname} index="/billing/pricing">
          <Pricing />
        </TabPanel>
      </main>
      <footer>
        <Copyrights />
      </footer>
      {/* <WhatsappContact /> */}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  company: state.companyReducer.company,
  billingResult: state.billingReducer.billingResult
})

const mapDispatchtoProps = (dispatch: Dispatch<IActionType>) => ({
  fetchBillingResults: async (key: string) => dispatch(await billingActions.fetchBillingResults(key))
})

export default connect(mapStateToProps, mapDispatchtoProps)(BillingManagement)
