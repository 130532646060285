/* eslint-disable react/prop-types */
import React from "react"
import Fuse from "fuse.js"
import { connect } from "react-redux"

import { useTranslation } from "react-i18next"

import Chip from "@material-ui/core/Chip"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import ListItem from "@material-ui/core/ListItem"
import TextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import ClearIcon from "@material-ui/icons/ClearOutlined"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import DropdownArrow from "@material-ui/icons/ArrowDropDown"
import InputAdornment from "@material-ui/core/InputAdornment"
import CircularProgress from "@material-ui/core/CircularProgress"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"

import { List as VirtualizedList, AutoSizer, CellMeasurerCache, CellMeasurer } from "react-virtualized"

import { IApplicantFilters } from "../../../../types/applicants"
import { ICompany } from "../../../../types/company"
import { Dispatch } from "redux"
import { IActionType } from "../../../../types"
import { applicantsActions } from "../../../../store/actions/applicants"

import useStyles from "./styles"
import { IFieldOfStudy } from "../../../../types/user"
import SelectAll from "./SelectAll"

let options = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    {
      name: "name_tr",
      weight: 0.6
    },
    {
      name: "name_en",
      weight: 0.4
    },
    {
      name: "field_of_studies",
      weight: 0.6
    }
  ]
}

interface Props {
  company: ICompany
  activeFilter: boolean
  fieldsOfStudy: IFieldOfStudy[]
  filters: IApplicantFilters
  fetchFieldsOfStudy: () => void
  applyFilters: (values: any, page: any) => void
}

const cache = new CellMeasurerCache({
  defaultHeight: 56,
  defaultWidth: 350,
  fixedWidth: true
})

const FieldsOfStudyFilters: React.FC<Props> = props => {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [searchQuery, setSearchQuery] = React.useState("")
  const [fieldsOfStudyFilters, setFieldsOfStudyFilters] = React.useState(props.filters.field_of_study ? [...props.filters.field_of_study] : [])
  const [fieldsOfStudy, setFieldsOfStudy] = React.useState<any>(props.fieldsOfStudy ? [...props.fieldsOfStudy] : null)
  const [selectAll, setSelectAll] = React.useState<string[]>([])
  const classes = useStyles()

  const { t } = useTranslation()

  let decoupledFieldsOfStudy = props.fieldsOfStudy

  const { fetchFieldsOfStudy } = props

  React.useEffect(() => {
    if (!decoupledFieldsOfStudy) {
      fetchFieldsOfStudy()
    }
    if (decoupledFieldsOfStudy && !fieldsOfStudy) {
      setFieldsOfStudy(decoupledFieldsOfStudy)
    }
  }, [fieldsOfStudy, decoupledFieldsOfStudy, fetchFieldsOfStudy])

  const handleChangeSearchQuery = (event: any) => {
    setSearchQuery(event.target.value)
    searchFOS(event.target.value)
  }

  const searchFOS = (query: any) => {
    let searchResults
    if (query.length > 0) {
      if (props.fieldsOfStudy) {
        const fuse = new Fuse(props.fieldsOfStudy, options)
        searchResults = fuse.search(query).map(result => result.item)
      }
    } else {
      searchResults = props.fieldsOfStudy?.filter(item => {
        return fieldsOfStudyFilters.includes(item.id)
      })
      const otherFOS = props.fieldsOfStudy?.filter(item => !fieldsOfStudyFilters.includes(item.id))
      searchResults = searchResults.concat(otherFOS)
    }
    setFieldsOfStudy(searchResults ? searchResults : null)
  }

  const handleMouseDownClear = (event: any) => {
    event.preventDefault()
  }

  const handleClick = () => {
    setFieldsOfStudyFilters(props.filters.field_of_study ? [...props.filters.field_of_study] : [])
    setOpen(prev => !prev)
  }

  const handleClickAway = () => {
    setOpen(false)
    setFieldsOfStudyFilters(props.filters.field_of_study ? [...props.filters.field_of_study] : [])
    setSelectAll([])
  }

  const handleFieldOfStudyClick = (value: any) => {
    const ndx = fieldsOfStudyFilters.findIndex(item => value === item)
    if (ndx === -1) {
      let newArray = [...fieldsOfStudyFilters]
      newArray.push(value)
      setFieldsOfStudyFilters(newArray)
    } else {
      let newArray = [...fieldsOfStudyFilters]
      newArray.splice(ndx, 1)
      setFieldsOfStudyFilters(newArray)
      cache.clearAll()
    }
  }

  const checkChecked = (value: any) => {
    let result = false
    fieldsOfStudyFilters.forEach(element => {
      if (value === element) {
        result = true
        return true
      }
    })
    return result
  }

  const handleApplyButtonClick = async () => {
    setLoading(true)
    await props.applyFilters(
      {
        ...props.filters,
        companyId: props.company.id,
        activeFilter: props.activeFilter,
        field_of_study: fieldsOfStudyFilters,
      },
      1
    )
    setLoading(false)
    setOpen(false)
  }

  const handleSelectAllClick = () => {
    if (selectAll.includes(searchQuery)) {
      handleClearSearch(searchQuery)
    } else {
      handleSelectSearch(searchQuery)
    }
  }

  const handleSelectSearch = (query: any) => {
    if (props.fieldsOfStudy) {
      const fuse = new Fuse(props.fieldsOfStudy, options)
      const searchResults = fuse.search(query).map(result => result.item.id) // We just need the ids to add to the filter
      const newFilters = fieldsOfStudyFilters.concat(searchResults)
      setFieldsOfStudyFilters(newFilters)
    }
    setSelectAll([...selectAll, query])
  }

  const handleClearSearch = (query: any) => {
    if (props.fieldsOfStudy) {
      const fuse = new Fuse(props.fieldsOfStudy, options)
      const searchResults = fuse.search(query).map(result => result.item)
      const newFilters = fieldsOfStudyFilters.filter((item: any) => searchResults.findIndex(fos => fos.id === item) === -1)
      setFieldsOfStudyFilters(newFilters)
    }
    setSelectAll(selectAll.filter(value => value !== query))
  }

  const rowRenderer = ({ index, key, style, parent }: { index: any; key: any; style: any; parent: any }) => {
    const fieldOfStudy: any = fieldsOfStudy ? fieldsOfStudy[index] : {}
    const labelId = `checkbox-list-label-${fieldOfStudy.id}`
    return (
      <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style} key={key}>
          <ListItem
            key={fieldOfStudy.id}
            role={undefined}
            dense
            button
            onClick={() => {
              handleFieldOfStudyClick(fieldOfStudy.id)
            }}
          >
            <ListItemIcon>
              <Checkbox edge="start" checked={checkChecked(fieldOfStudy.id)} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": labelId }} />
            </ListItemIcon>
            <ListItemText id={labelId} primary={`${fieldOfStudy.name_tr || fieldOfStudy.name_en}`} />
          </ListItem>
        </div>
      </CellMeasurer>
    )
  }

  return (
    <div className={classes.root + " va-middle"}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classes.singleFilterDiv}>
          <Button onClick={handleClick} variant="outlined" size="small" color={open ? "primary" : "default"}>
            {props.filters.field_of_study
              ? props.filters.field_of_study.length > 0
                ? `${t("field_of_study")}(${props.filters.field_of_study.length})`
                : `${t("field_of_study")}`
              : `${t("field_of_study")}`}
            <DropdownArrow />
          </Button>
          {open ? (
            <Paper className={classes.paper} elevation={3}>
              <FormControl fullWidth style={{ padding: "10px", paddingBottom: "0px" }}>
                <TextField
                  fullWidth
                  autoFocus
                  margin="dense"
                  variant="standard"
                  value={searchQuery}
                  onChange={handleChangeSearchQuery}
                  placeholder={t("search_for_more_field_of_study")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle"
                          onClick={() => {
                            setSearchQuery("")
                            searchFOS("")
                          }}
                          onMouseDown={handleMouseDownClear}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
              {selectAll.length > 0 ? (
                <div>
                  {selectAll.map(item => {
                    return (
                      <Chip
                        key={Math.random()}
                        variant="default"
                        style={{ margin: "2.5px", marginBottom: "5px" }}
                        label={item}
                        onDelete={event => {
                          handleClearSearch(item)
                        }}
                      />
                    )
                  })}
                </div>
              ) : null}
              {searchQuery.length > 0 && !!fieldsOfStudy && fieldsOfStudy.length > 0 ? (
                <SelectAll query={searchQuery} checked={selectAll.includes(searchQuery)} handleClick={handleSelectAllClick} />
              ) : null}
              <div style={{ height: "289px", width: "350px" }}>
                {!!fieldsOfStudy && fieldsOfStudy.length > 0 ? (
                  <AutoSizer>
                    {({ width, height }) => {
                      return (
                        <VirtualizedList
                          width={width}
                          height={height}
                          rowHeight={cache.rowHeight}
                          rowCount={fieldsOfStudy.length}
                          rowRenderer={rowRenderer}
                          overscanRowCount={5}
                          deferredMeasurementCache={cache}
                        />
                      )
                    }}
                  </AutoSizer>
                ) : (
                  <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Typography variant="body1" style={{ color: "#979797" }}>
                      {t("no_fos_search_results")}
                    </Typography>
                  </div>
                )}
              </div>
              <div className={classes.buttonArea}>
                <Button color="primary" variant="outlined" onClick={handleClickAway}>
                  {t("cancel")}
                </Button>
                <Button color="primary" variant="contained" disabled={loading} onClick={handleApplyButtonClick}>
                  {loading ? <CircularProgress size={22} style={{ color: "#fff" }} /> : t("apply")}
                </Button>
              </div>
            </Paper>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    activeFilter: state.applicantsReducer.activeFilter,
    filters: state.applicantsReducer.filters,
    company: state.companyReducer.company,
    fieldsOfStudy: state.applicantsReducer.fieldsOfStudy
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchFieldsOfStudyGroups: async () => dispatch(await applicantsActions.fetchStudyGroups()),
    fetchFieldsOfStudy: async () => dispatch(await applicantsActions.fetchFieldsOfStudy())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldsOfStudyFilters)
