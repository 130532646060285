import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    // width: "100%",
    backgroundColor: "#e6f8ff",
    padding: "10px"
  },
  iconButton: {
    float: "right",
    padding: "0px",
    display: "inline-block"
  },
  typography: {
    display: "inline"
  }
}))

export default useStyles
