import React from "react"
import { useTranslation } from "react-i18next"
import { NotificationCenterWidget } from "innovia-component-library"

import Menu from "@material-ui/core/Menu"
import Badge from "@material-ui/core/Badge"
import IconButton from "@material-ui/core/IconButton"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"

import { useMediaQuery, useTheme } from "@material-ui/core"

import Remove from "@material-ui/icons/Remove"
import NotificationsNoneOutlined from "@material-ui/icons/NotificationsNoneOutlined"

import NotificationsSurveyModule from "../NotificationsSurveyModule"
import Cookies from "js-cookie"
import { useSelector } from "react-redux"
import dayjs from "dayjs"

import Pusher from 'pusher-js'
import { useHistory } from "react-router"
import useGtm from "../../hooks/useGtm"
import { deleteNotification, getNotificationStats, getNotifications, markAllNotificationsAsRead, markNotificationAsRead, markNotificationAsUnRead } from "../../api/iskibris/iskibris-methods"
import HTTPResponses from "../../tools/http.responses"
// import useGtm from "utils/hooks/useGtm"

interface Props {
  
}

const options = {
  broadcaster: "pusher",
  key: process.env.NEXT_PUBLIC_PUSHER_KEY || "",
  cluster: "eu",
  forceTLS: true,
  authEndpoint: `${process.env.NEXT_PUBLIC_ISKIBRIS_API_URL}/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: `Bearer ${Cookies.get("auth_token")}`,
      Accept: "application/json"
    }
  }
}

const NotificationCenter: React.FC<Props> = props => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = !!anchorEl

  const {t, i18n} = useTranslation()

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("xs"))

  let history = useHistory()

  const { tagEvent } = useGtm()

  const [stats, setStats] = React.useState<any>(null)
  const [filterUnread, setFilterUnread] = React.useState<any>(0)
  const [notifications, setNotifications] = React.useState<any>(null)

  const user = useSelector((state: any) => state.userReducer.user)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = (event: any) => {
    if(anchorEl){
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClickAway = () => {
    if(anchorEl){
      setAnchorEl(null)
    }
  }

  const authToken = Cookies.get("auth_token")

  React.useEffect(() => {
    let pusher: Pusher | null
    let channelStr: any

    if (user && authToken) {
      pusher = new Pusher(options.key, {
        cluster: options.cluster,
        forceTLS: options.forceTLS,
        authEndpoint: options.authEndpoint,
        auth: {
          ...options.auth,
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json"
          }
        }
      })

      channelStr = `private-App.User.${user?.id}.notifications`

      let cChannel = pusher.subscribe(channelStr)
      cChannel.bind_global(function (event: any, data: any) {
        if(event?.includes("BroadcastNotificationCreated")){
          handleFetchNotificationsStats()
          if(open){
            handleFetchNotifications(filterUnread)
          }
        }
      })
    }

    return () => {
      if (user && authToken && !!pusher && !!channelStr) {
        pusher.disconnect()
      }
    }
  }, [user, open, filterUnread, authToken])

  React.useEffect(() => {
    handleFetchNotificationsStats()
  }, [])

  React.useEffect(() => {
    if(open && !notifications){
      handleFetchNotifications(filterUnread)
    }
  }, [open, filterUnread, notifications])

  React.useEffect(() => {
    if (open && notifications) {
      setTimeout(() => {
        tagEvent({
          event: "open_notifications_feed",
          data: {
            list_name: "notifications",
            user_id: user?.id
          }
        })
      }, 2500)
    }
  }, [open, notifications, user?.id, tagEvent])

  const handleFetchNotifications = async (value?: any) => {
    await getNotifications(value)
      .then(response => {
        setNotifications(response.data)
      })
      .catch(error => {
        console.log("Error fetching notifications: ", error)
      })
  }

  const handleFetchNotificationsStats = async () => {
    await getNotificationStats()
      .then(response => {
        setStats(response.data)
      })
      .catch(error => {
        console.log("Error fetching notification stats: ", error)
      })
  }

  const handleTagEventOnNotificationClick = (notification: any) => {
    tagEvent({
      event: "notification_click",
      data: {
        id: notification?.id,
        type: notification?.type,
        category: notification?.category,
        user_id: user?.id
      }
    })
  }

  const handleNotificationClick = (notification: any) => {
    handleMarkAsRead(notification)
    handleTagEventOnNotificationClick(notification)
    const onClickHandler = getOnClick(notification)
    if(onClickHandler) onClickHandler(notification)
    setAnchorEl(null)
  }

  const handleMarkAsRead = async (notification: any) => {
    await markNotificationAsRead(notification?.id)
      .then(async (response) => {
        if(response.status === HTTPResponses.OK){
          await handleFetchNotifications(filterUnread)
          await handleFetchNotificationsStats()
        }
      })
      .catch(error => {
        console.log("Error marking notification as read: ", error)
      })
  }

  const handleMarkAsUnRead = async (notification: any) => {
    await markNotificationAsUnRead(notification?.id)
      .then(async (response) => {
        if(response.status === HTTPResponses.OK){
          await handleFetchNotifications(filterUnread)
          await handleFetchNotificationsStats()
        }
      })
      .catch(error => {
        console.log("Error marking notification as unread: ", error)
      })
  }

  const handleToogleStatusFilter = (value: any) => {
    setFilterUnread(value)
    handleFetchNotifications(value)
  }

  const handleMarkAllAsRead = async () => {
    await markAllNotificationsAsRead()
      .then(async (response) => {
        if(response.status === HTTPResponses.OK){
          await handleFetchNotifications(filterUnread)
          await handleFetchNotificationsStats()
        }
      })
      .catch(error => {
        console.log("Error marking all notification as read: ", error)
      })
  }

  const handleDeleteNotification = async (notification: any) => {
    await deleteNotification(notification?.id)
      .then(async (response) => {
        if(response.status === HTTPResponses.OK){
          await handleFetchNotifications(filterUnread)
        }
      })
      .catch(error => {
        console.log("Error deleting notification: ", error)
      })
  }

  const getActions = (notification: any) => {
    const actions:any = {
      "single-job": [
        {'title': t("view")}
      ],
      "single-application": [
        {'title': t("view")}
      ],
      "company-jobs": [
        {'title': t("view")}
      ],
      "company-users": [
        {'title': t("view")}, {'title': t("edit")} 
      ],
      "applications": [
        {'title': t("view_applications_with_count", {jobs: notification.payload?.jobs_count})}
      ],
      "subscriptions": [
        {'title': t("view")}
      ],
      "settings": [
        {'title': t("update_settings")},
      ],
    }
    return actions[notification.category]
  }

  const getJobRoute = (notification: any) => {
    var payload = notification.payload;
    if (payload == null) {
      return null;
    }
    if (typeof payload == "string") {
      payload = JSON.parse(payload);
    }
    var job = payload['job'];
    if (job == null) {
      return null;
    }
    if (typeof job == "string") {
      job = JSON.parse(job);
    }
    const jobId = job['id'];
    return `/jobs/${jobId}/edit`;
  }

  const getApplicationRoute = (notification: any) => {
    let payload = notification.payload;
    if (payload == null) {
      return null;
    }
    if (typeof payload == "string") {
      payload = JSON.parse(payload);
    }
    let application = payload['application'];
    if (application == null) {
      return null;
    }
    if (typeof application == "string") {
      application = JSON.parse(application);
    }
    const applicationId = application['id'];
    return `/applications/${applicationId}`;
  }

  const navigateToRoute = (route: string | null) => {
    if(route){
      history.push(route)
    }
  }

  const getAnnouncementRoute = (notification: any) => {
    return notification.payload?.navigation_url;
  }

  const getOnClick = (notification: any) => {
    const actions:any = {
      "single-job": (notification: any) => {navigateToRoute(getJobRoute(notification) ?? "/jobs")},
      "single-application": (notification: any) => {navigateToRoute(getApplicationRoute(notification) ?? "/applications")},
      "company-jobs": (notification: any) => {navigateToRoute(getAnnouncementRoute(notification) ?? "/jobs")},
      "company-users": (notification: any) => {navigateToRoute(getAnnouncementRoute(notification) ?? "/settings/users/management")},
      "applications": (notification: any) => {navigateToRoute(getAnnouncementRoute(notification) ?? "/applications")},
      "subscriptions": (notification: any) => {navigateToRoute(getAnnouncementRoute(notification) ?? "/billing")},
      "settings": (notification: any) => {navigateToRoute(getAnnouncementRoute(notification) ?? "/settings")},
      "employer": (notification: any) => {navigateToRoute(getAnnouncementRoute(notification) ?? "/employer/profile")},
    }
    return actions[notification.category]
  }

  const getNotificationMessage = (notification: any) => {
    if(i18n.language === "en"){
      return notification.message_en ?? notification.message 
    } else {
      return notification.message_tr ?? notification.message
    }
  }

  const updatedNotifications = notifications?.map((item: any) => {
    return {
      ...item,
      notificationImageUrl: item.notification_image_url,
      status: !item.read_at ? 'unread' : 'read',
      message: getNotificationMessage(item),
      actions: getActions(item) ?? [],
      onClick: getOnClick(item),
      options: [
        {'title': t(!item.read_at ? "mark_as_read" : "mark_as_unread"), 'onClick': !item.read_at ? handleMarkAsRead : handleMarkAsUnRead},
        {'title': t("delete"), 'onClick': handleDeleteNotification}
      ]
    }
  })

  const handleSurveySubmitted = (option: any) => {
    Cookies.set(
      `notification_center_survey`,
      {"option": option, "user_id": user?.id, "submitted_at": dayjs().toISOString(), 'slug': "notification-center"},
      { secure: true, expires: 14 }
    )
  }

  const checkShowSurvey = () => {
    if(!user){
      return false
    }
    if(notifications?.length === 0){
      return false
    }
    const data = Cookies.getJSON("notification_center_survey")
    if(!data || data.user_id !== user?.id || dayjs().diff(dayjs(data.submitted_at), 'day') > 14){
      return true
    }
    return false
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="notification-center">
        <div className="notification-center__indidator">
          <IconButton onClick={handleOpen} className="notification-center__indicator-icon-button m-2">
            <Badge color="secondary" badgeContent={stats?.unread ?? 0} max={9} overlap="rectangular">
              <NotificationsNoneOutlined className="notification-center__indicator-icon" />
            </Badge>
          </IconButton>
        </div>
        {
          matchesMobile 
          ?
          (
            <SwipeableDrawer anchor="bottom" open={!!anchorEl} onOpen={handleOpen} onClose={handleClickAway} PaperProps={{className: "notification-center__drawer-paper"}}>
              <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}} className="mt-1" onClick={handleClickAway}>
                <Remove className="notification-center__drawer-remove-icon" fontSize="large"/>
              </div>
              <div className="notification-center__drawer-container p-3 pt-0">
                <NotificationCenterWidget 
                  loading={!notifications} 
                  onClick={handleNotificationClick} 
                  notifications={updatedNotifications}
                  onMarkAllAsRead={handleMarkAllAsRead}
                  onFilterNotificationsByStatus={handleToogleStatusFilter}
                />
                {
                  checkShowSurvey() && (
                    <div className="p-3" style={{borderTop: "1px solid #eaeaea"}}>
                      <NotificationsSurveyModule onSubmitted={handleSurveySubmitted} />
                    </div>
                  )
                }
              </div>
            </SwipeableDrawer>
          )
          :
          (
            <Menu
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              id="notification-center"
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              style={{padding: 0}}
              MenuListProps={{style: {padding: 0, width: "100%"}}}
              PaperProps={{
                style: {
                  maxHeight: "calc(100vh - 100px)",
                  width: "540px"
                },
              }}
            >
              <div className="notification-center__container p-3">
                <NotificationCenterWidget 
                  loading={!notifications} 
                  onClick={handleNotificationClick} 
                  notifications={updatedNotifications}
                  onMarkAllAsRead={handleMarkAllAsRead}
                  onFilterNotificationsByStatus={handleToogleStatusFilter}
                />
                {
                  checkShowSurvey() && (
                    <div className="p-3" style={{borderTop: "1px solid #eaeaea"}}>
                      <NotificationsSurveyModule onSubmitted={handleSurveySubmitted} />
                    </div>
                  )
                }
              </div>
            </Menu>
          )
        }
      </div>
    </ClickAwayListener>
  )
}

export default NotificationCenter
