import { actionTypes } from "../actions/actionTypes"
import { IActionType } from "../../types"

interface ISystemReducerModel {
  drawerOpen: boolean
  userDrawerOpen: boolean
  companyMenuOpen: boolean
  languageMenuOpen: boolean
}

const initialState: ISystemReducerModel = {
  drawerOpen: false,
  userDrawerOpen: false,
  companyMenuOpen: false,
  languageMenuOpen: false
}

const reducer = (state: ISystemReducerModel = initialState, action: IActionType) => {
  switch (action.type) {
    case actionTypes.OPEN_DRAWER:
      return {
        ...state,
        drawerOpen: !state.drawerOpen
      }
    case actionTypes.CLOSE_DRAWER: {
      return {
        ...state,
        drawerOpen: false
      }
    }
    case actionTypes.OPEN_USER_MENU_DRAWER:
      return {
        ...state,
        userDrawerOpen: !state.userDrawerOpen
      }
    case actionTypes.CLOSE_USER_MENU_DRAWER: {
      return {
        ...state,
        userDrawerOpen: false
      }
    }
    case actionTypes.OPEN_COMPANY_MENU:
      return {
        ...state,
        companyMenuOpen: !state.companyMenuOpen
      }
    case actionTypes.CLOSE_COMPANY_MENU: {
      return {
        ...state,
        companyMenuOpen: false
      }
    }
    case actionTypes.OPEN_LANGUAGE_MENU:
      return {
        ...state,
        languageMenuOpen: !state.languageMenuOpen
      }
    case actionTypes.CLOSE_LANGUAGE_MENU: {
      return {
        ...state,
        languageMenuOpen: false
      }
    }
    default:
      break
  }
  return state
}

export default reducer
