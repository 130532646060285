import React from "react"
import Cookies from "js-cookie"

import ability from "./utils/AccessControl/ability"

// Styles
import "./assets/styles/App.scss"
import theme from "./assets/jss/theme"

// Material-ui
import { MuiThemeProvider } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { companyActions } from "./store/actions/company"
import { permissionActions } from "./store/actions/permissions"
import { userActions } from "./store/actions/user"

import Pusher from "pusher-js"
import { billingActions } from "./store/actions/billing"
import FlashMessages from "./components/FlashMessages"
import HiringRoutes from "./routes"
import WhatsappContact from "./components/WhatsappContact"
import useSearchParams from "./hooks/useSearchParams"
import ImpersonationPrompt from "./components/Impersonation/ImpersonationPrompt"

const options = {
  broadcaster: "pusher",
  key: process.env.REACT_APP_PUSHER_KEY || "",
  cluster: "eu",
  forceTLS: true,
  authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: `Bearer ${Cookies.get("auth_token")}`,
      Accept: "application/json"
    }
  }
}

function App() {
  const [abilitiesUpdated, setAbilitiesUpdated] = React.useState(false)
  const isAuthenticated: boolean = useSelector((state: any) => state.authReducer.isAuthenticated)
  const companies: any = useSelector((state: any) => state.companyReducer.companies)
  const company: any = useSelector((state: any) => state.companyReducer.company)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (window) {
      const windowProcess: any = {
        ...window.process
      }

      window.process = windowProcess
    }
  }, [])

  const [searchAsObject] = useSearchParams()

  React.useEffect(() => {
    const companyId = searchAsObject.company
    if (companyId) {
      localStorage.setItem("current_company_id", companyId)
    }
  }, [searchAsObject.company])

  React.useEffect(() => {
    const fetchCompanies = async () => {
      dispatch(await companyActions.fetchUserManagedCompanies())
    }
    if (isAuthenticated) {
      if (!companies) {
        fetchCompanies()
      }
    }
  }, [companies, isAuthenticated, dispatch])

  React.useEffect(() => {
    const fetchAbilities = async () => {
      dispatch(await permissionActions.fetchUserAbilities())
      dispatch(await permissionActions.fetchCompanyBillingAbilities())
    }
    const companyToken = Cookies.get("CompanyToken")
    if (isAuthenticated && companies?.length > 0 && !!companyToken) {
      fetchAbilities()
    }
  }, [companies, dispatch, isAuthenticated])

  React.useEffect(() => {
    const fetchSubscriptions = async (company: any) => {
      dispatch(await billingActions.fetchCompanySubscriptions(company.id))
    }

    if (isAuthenticated && company) {
      fetchSubscriptions(company)
    }
  }, [company, dispatch, isAuthenticated])

  React.useEffect(() => {
    const fetchProfile = async () => {
      dispatch(await userActions.fetchProfile())
    }

    if (isAuthenticated) {
      fetchProfile()
    }
  }, [isAuthenticated, dispatch])

  React.useEffect(() => {}, [abilitiesUpdated])

  ability.on("updated", () => {
    setAbilitiesUpdated(true)
  })

  const authToken = Cookies.get("auth_token")

  const isImpersonating = useSelector((state: any) => state.impersonationReducer.is_impersonating)
  const impersonationUserId = useSelector((state: any) => state.impersonationReducer.impersonation_user_id)

  const { impersonate, user } = searchAsObject

  React.useEffect(() => {
    let pusher: Pusher | null
    let abilitiesChannelStr: any
    let subscriptionsChannelStr: any

    const fetchAbilities = async () => {
      dispatch(await permissionActions.fetchUserAbilities())
    }

    const fetchSubscriptions = async () => {
      if (company) {
        dispatch(await billingActions.fetchCompanySubscriptions(company))
      }
    }

    if (company && authToken) {
      pusher = new Pusher(options.key, {
        cluster: options.cluster,
        forceTLS: options.forceTLS,
        authEndpoint: options.authEndpoint,
        auth: {
          ...options.auth,
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json"
          }
        }
      })

      abilitiesChannelStr = `private-companies.${company.id}.abilities`
      subscriptionsChannelStr = `private-companies.${company.id}.subscriptions`

      let aChannel = pusher.subscribe(abilitiesChannelStr)
      aChannel.bind("abilities-updated", function (data: any) {
        console.log("Abilites Updated: ", data)
        fetchAbilities()
      })

      let subscriptionsChannel = pusher.subscribe(subscriptionsChannelStr)
      subscriptionsChannel.bind("subscriptions-updated", function (data: any) {
        console.log("Subscriptions Updated: ", data)
        fetchSubscriptions()
      })
    }

    return () => {
      if (company && authToken && !!pusher && !!abilitiesChannelStr && !!subscriptionsChannelStr) {
        pusher.disconnect()
      }
    }
  }, [company, authToken, dispatch])

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <HiringRoutes isAuthenticated={isAuthenticated} />
      </MuiThemeProvider>
      <FlashMessages />
      <WhatsappContact />
      {
        isAuthenticated && (impersonate && user && (!impersonationUserId && !isImpersonating)) && (
          <ImpersonationPrompt userId={user} />
        )
      }
    </>
  )
}

export default App
