import React from "react"
import { useDispatch, useSelector } from "react-redux"

import packageIcon from "../../assets/images/packages/premium.png"

import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"

import SinglePackage from "./SinglePackage"
import { IOffer } from "../../types/billing"
import { billingActions } from "../../store/actions/billing"

interface Props {}

const Pricing: React.FC<Props> = props => {
  const state = useSelector((state: any) => state.billingReducer)
  const dispatch = useDispatch()

  const { offers, components } = state

  React.useEffect(() => {
    const fetchComponents = async () => {
      dispatch(await billingActions.fetchBillingComponents())
    }

    const fetchOffers = async () => {
      dispatch(await billingActions.fetchBillingOffers())
    }

    if (!components && !offers) {
      fetchComponents()
      fetchOffers()
    }
  }, [components, offers, dispatch])

  return (
    <div>
      {state.offers && state.components ? (
        <React.Fragment>
          <Grid container>
            {state.offers.map((item: IOffer) => {
              return (
                <Grid key={item.id} item xs={12} sm={6} md={4} lg={4} className="single-package--grid-container">
                  <SinglePackage offerId={item.id} features={item.package_details} title={item.name} price={item.price} icon={packageIcon} offer={item} />
                </Grid>
              )
            })}
          </Grid>
        </React.Fragment>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            maxHeight: "50vh"
          }}
        >
          <CircularProgress size={35} />
        </div>
      )}
    </div>
  )
}

export default Pricing
