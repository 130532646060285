import React from "react"

import NavBar from "../../NavBar/NavBar"
import SideBar from "../../SideBar"
import Copyright from "../../Copyright"
import UserSideMenu from "../../MobileComponents/UserSideMenu"

import CssBaseline from "@material-ui/core/CssBaseline"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"

interface Props {
  children: React.ReactNode
}

const Page: React.FC<Props> = props => {
  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <>
      <CssBaseline />
      <NavBar />
      <SideBar />
      {matchesMobile ? <UserSideMenu /> : null}
      <main className={"content content--has-sidebar content--with-container"} style={{ paddingTop: "62px" }}>
        {props.children}
      </main>
      <footer>
        <Copyright />
      </footer>
    </>
  )
}

export default Page
