import React from "react"
import { Dispatch } from "redux"

import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"

import { makeStyles } from "@material-ui/core"
import { IActionType } from "../../../../types"
import { ICompany } from "../../../../types/company"
import { ISubscription } from "../../../../types/billing"
import { billingActions } from "../../../../store/actions/billing"
import { permissionActions } from "../../../../store/actions/permissions"
import SingleSubscription from "./SingleSubscription"
import { Link } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 3),
    marginRight: "20px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px"
    }
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem"
  },
  expiredContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  expiredText: {
    color: "#797979",
    paddingRight: "10px"
  }
}))

interface Props {
  subscriptions: ISubscription[]
  unregistered: boolean
  company: ICompany
  fetchAbilities: () => void
  fetchCompanySubscriptions: (id: any) => void
}

const CurrentSubscription: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { subscriptions } = props

  // const handleCancelSubscription = (subId: any) => {

  // }

  return (
    <Paper className={classes.paper}>
      {!!subscriptions ? (
        <React.Fragment>
          <div>
            <div className={classes.headerContainer}>
              <Typography variant="h6">{t("active_packages")}</Typography>
              <Link to="/billing/pricing">
                <Button color="primary" variant="outlined">
                  {t("add_new_package")}
                </Button>
              </Link>
            </div>
            {subscriptions.length > 0 ? (
              <div>
                {subscriptions.map(item => {
                  return <SingleSubscription subscription={item} key={Math.random()} />
                })}
              </div>
            ) : (
              <div className={classes.expiredContainer}>
                <Typography variant="body2" className={classes.expiredText} gutterBottom>
                  {t("subscrition_expired_text")}{" "}
                </Typography>
              </div>
            )}
          </div>
        </React.Fragment>
      ) : (
        <div>
          <CircularProgress />
        </div>
      )}
    </Paper>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchCompanySubscriptions: async (id: any) => dispatch(await billingActions.fetchCompanySubscriptions(id)),
    fetchAbilities: async () => dispatch(await permissionActions.fetchUserAbilities())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentSubscription)
