import React from "react"
import { useTranslation } from "react-i18next"

import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import CssBaseline from "@material-ui/core/CssBaseline"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import NavBar from "../../../components/NavBar/NavBar"
import SideBar from "../../../components/SideBar"
import TabPanel from "../../../components/TabPanel"
import Copyrights from "../../../components/Copyright"
import UserSideMenu from "../../../components/MobileComponents/UserSideMenu"
import CandidateAppeal from "../../../components/EmployerTools/CandidateAppealTab"

interface Props {}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  }
}

const EmployerTools: React.FC<Props> = props => {
  const { t } = useTranslation()
  const [tabValue, setTabValue] = React.useState(0)

  const handleTabChange = (event: any, value: any) => {
    setTabValue(value)
  }

  React.useEffect(() => {
    document.title = t("employer_tools_title")
  })

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div>
      <CssBaseline />
      <NavBar />
      <SideBar />
      {matchesMobile ? <UserSideMenu /> : null}
      <main className={"content content--has-sidebar content--with-container"} style={{ paddingTop: "62px" }}>
        <Typography variant="h5">{t("employer_tools")}</Typography>
        <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="secondary" textColor="secondary">
          <Tab label={t("candidate_appeal")} {...a11yProps(0)} />
        </Tabs>
        <Divider />
        <TabPanel value={tabValue} index={0}>
          <CandidateAppeal />
        </TabPanel>
      </main>
      <footer>
        <Copyrights />
      </footer>
    </div>
  )
}

export default EmployerTools
