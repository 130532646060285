import React from "react"
import moment from "moment"

import { makeStyles } from "@material-ui/core"

import Menu from "@material-ui/core/Menu"
import Button from "@material-ui/core/Button"
import MenuItem from "@material-ui/core/MenuItem"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import IconButton from "@material-ui/core/IconButton"

import OptionsIcon from "@material-ui/icons/MoreHoriz"
import { ICompanyUserInvitation } from "../../../../types/company"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  tableCell: {
    borderBottom: "none"
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#F5F6F7 !important"
    }
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: "5px"
  }
}))

interface Props {
  item: ICompanyUserInvitation
  onRevoke: (id: any) => void
}

interface LongMenuProps {
  onRevoke: () => void
}

const LongMenu: React.FC<LongMenuProps> = props => {
  const { t } = useTranslation()

  const options = ["revoke"]
  const ITEM_HEIGHT = 48
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleItemClicked = (value: any) => {
    setAnchorEl(null)
    if (value === options[0]) {
      props.onRevoke()
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} style={{ padding: "3px" }}>
        <OptionsIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200
          }
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option}
            onClick={() => {
              handleItemClicked(option)
            }}
          >
            {t(option)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

const UserInvitationTableItem: React.FC<Props> = props => {
  const classes = useStyles()

  let roles = ""

  if (props.item.metadata) {
    const parsedMetadata = JSON.parse(props.item.metadata)
    roles = parsedMetadata.roles
  }

  const handleRevokeInvite = () => {
    props.onRevoke(props.item.id)
  }

  return (
    <TableRow style={{ borderBottom: "none" }} className={classes.tableRow}>
      <TableCell className={classes.tableCell}>
        <Button variant="text" color="secondary">
          {props.item.invited_user_email}
        </Button>
      </TableCell>
      <TableCell className={classes.tableCell}>{roles}</TableCell>
      <TableCell className={classes.tableCell}>{moment(props.item.invited_at).format("DD MMMM YYYY HH:mm:ss")}</TableCell>
      <TableCell style={{ width: "15px" }} className={classes.tableCell}>
        <LongMenu onRevoke={handleRevokeInvite} />
      </TableCell>
    </TableRow>
  )
}

export default UserInvitationTableItem
