import React from "react"

import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"

import useStyles from "./styles"

interface Props {
  open: boolean
  children: any
  handleClose: () => void
}

const ModalContainer: React.FC<Props> = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={props.open}>
          <div className={classes.modalPaper}>
            <div className={classes.contents}>{props.children}</div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default ModalContainer
