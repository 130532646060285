import React from "react"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useHistory } from "react-router"
import { useTranslation } from "react-i18next"

import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import CssBaseline from "@material-ui/core/CssBaseline"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { IActionType } from "../../../types"
import NavBar from "../../../components/NavBar/NavBar"
import SideBar from "../../../components/SideBar"
import TabPanel from "../../../components/TabPanel"
import Benefits from "../../../components/Benefits"
import Copyrights from "../../../components/Copyright"
import { companyActions } from "../../../store/actions/company"
import CompanyInfo from "../../../components/Companies/CompanyInfo"
import CompanyFaqs from "../../../components/Companies/CompanyFaqs"
import CompanyStory from "../../../components/Companies/CompanyStory"
import CompanyPhotos from "../../../components/Companies/CompanyPhotos"
import CompanyUpdates from "../../../components/Companies/CompanyUpdates"
import UserSideMenu from "../../../components/MobileComponents/UserSideMenu"
import CompanyLocations from "../../../components/Companies/CompanyLocations"
import { ICompany, ICompanyIndustry, ICompanySector } from "../../../types/company"

interface Props {
  company: ICompany
  sectors: ICompanySector[]
  industries: ICompanyIndustry[]
  addCompanyProfile: (data: any) => void
  clearEmployerProfileForm: () => void
  fetchIndustries: () => void
  fetchSectors: () => void
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  }
}

const EmployerProfile: React.FC<Props> = props => {
  const { t } = useTranslation()
  const history = useHistory()

  const [formFilled, setFormFilled] = React.useState(false)

  React.useEffect(() => {
    const getPageTitle = () => {
      switch (history.location.pathname) {
        case "/employer/profile/info":
          return t("employer_profile_title_info")
        case "/employer/profile/info/benefits":
          return t("employer_profile_title_benefits")
        case "/employer/profile/story":
          return t("employer_profile_title_story")
        case "/employer/profile/photos":
          return t("employer_profile_title_photos")
        case "/employer/profile/updates":
          return t("employer_profile_title_updates")
        case "/employer/profile/locations":
          return t("employer_profile_title_locations")
        case "/employer/profile/faqs":
          return t("employer_profile_title_faqs")
        default:
          return t("employer_profile_title")
      }
    }
    document.title = getPageTitle()
  }, [history.location.pathname, t])

  const handleChange = (event: any, value: string) => {
    history.push(value)
  }

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const { company, sectors, industries, fetchSectors, fetchIndustries, clearEmployerProfileForm, addCompanyProfile } = props

  React.useEffect(() => {
    if (!sectors) {
      fetchSectors()
      fetchIndustries()
    }
  }, [sectors, fetchIndustries, fetchSectors])

  React.useEffect(() => {
    let data: any = {
      ...company
    }

    if (sectors && industries && company && !formFilled) {
      const sector = [...sectors].find(item => {
        return item.id === company?.sector_id
      })
      const industry = [...industries].find(item => {
        return item.id === company?.industry_id
      })

      data = {
        ...data,
        sector_id: sector,
        industry_id: industry
      }
      addCompanyProfile(data)
      setFormFilled(true)
    }
  }, [company, addCompanyProfile, fetchIndustries, fetchSectors, industries, sectors, formFilled, setFormFilled])

  React.useEffect(() => {
    return () => {
      clearEmployerProfileForm()
    }
  }, [clearEmployerProfileForm])

  const extractCompanyInfoHistoryValue = () => {
    const pathname = history.location.pathname
    switch (pathname) {
      case "/employer/profile/info/benefits":
        return pathname
      case "/employer/profile/info":
        return pathname
      default:
        return "/employer/profile"
    }
  }

  const extractCompanyInfoHistoryIndex = () => {
    const pathname = history.location.pathname
    switch (pathname) {
      case "/employer/profile/info":
        return pathname
      default:
        return "/employer/profile"
    }
  }

  return (
    <div>
      <CssBaseline />
      <NavBar />
      <SideBar />
      {matchesMobile ? <UserSideMenu /> : null}
      <main className={"content content--has-sidebar content--with-container"} style={{ paddingTop: "62px" }}>
        <div style={{ marginBottom: "15px" }}>
          <Typography variant="h5" className={"page-title"}>
            {t("employer_profile")}
          </Typography>
          <Typography variant="caption">{t("create_company_subtext")}</Typography>
        </div>
        <Tabs value={history.location.pathname} indicatorColor="secondary" onChange={handleChange} textColor="secondary" variant="scrollable" scrollButtons="on">
          <Tab label={t("company_info")} {...a11yProps("company-info")} value={extractCompanyInfoHistoryValue()} />
          <Tab label={t("company_story")} {...a11yProps("company-story")} value={"/employer/profile/story"} />
          <Tab label={t("logo_photos")} {...a11yProps("company-photos")} value={"/employer/profile/photos"} />
          <Tab label={t("company_updates")} {...a11yProps("company-updates")} value={"/employer/profile/updates"} />
          <Tab label={t("company_locations")} {...a11yProps("company-locations")} value={"/employer/profile/locations"} />
          <Tab label={t("company_faqs")} {...a11yProps("company-faqs")} value={"/employer/profile/faqs"} />
        </Tabs>
        <Divider />
        <TabPanel value={history.location.pathname} index={extractCompanyInfoHistoryIndex()}>
          <CompanyInfo type="edit" />
        </TabPanel>
        <TabPanel value={history.location.pathname} index={"/employer/profile/info/benefits"}>
          <Benefits />
        </TabPanel>
        <TabPanel value={history.location.pathname} index={"/employer/profile/story"}>
          <CompanyStory type="edit" />
        </TabPanel>
        <TabPanel value={history.location.pathname} index={"/employer/profile/photos"}>
          <CompanyPhotos company={props.company} />
        </TabPanel>
        <TabPanel value={history.location.pathname} index={"/employer/profile/updates"}>
          <CompanyUpdates />
        </TabPanel>
        <TabPanel value={history.location.pathname} index={"/employer/profile/locations"}>
          <CompanyLocations />
        </TabPanel>
        <TabPanel value={history.location.pathname} index={"/employer/profile/faqs"}>
          <CompanyFaqs />
        </TabPanel>
      </main>
      <footer>
        <Copyrights />
      </footer>
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  company: state.companyReducer.company,
  sectors: state.companyReducer.sectors,
  industries: state.companyReducer.industries
})

const mapDispatchtoProps = (dispatch: Dispatch<IActionType>) => ({
  fetchSectors: async () => dispatch(await companyActions.fetchSectors()),
  fetchIndustries: async () => dispatch(await companyActions.fetchIndustries()),
  clearEmployerProfileForm: () => dispatch(companyActions.clearCompanyForm()),
  addCompanyProfile: (data: any) => dispatch(companyActions.editFormContent(data))
})

export default connect(mapStateToProps, mapDispatchtoProps)(EmployerProfile)
