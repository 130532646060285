import React from "react"
import { Controller } from "react-hook-form"
import { connect } from "react-redux"
import { Dispatch } from "redux"

import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControlLabel from "@material-ui/core/FormControlLabel"

import { companyActions } from "../../../../store/actions/company"
import MultiLangTextEditor from "../../../MultiLangTextEditor"
import { IActionType } from "../../../../types"
import { useTranslation } from "react-i18next"

import useStyles from "../styles"

interface Props {
  errors: any
  control: any
  formFields: any
  editFormFields: (value: any) => void
}

const CompanyMission: React.FC<Props> = props => {
  const { t } = useTranslation()

  const classes = useStyles()

  const handleDescriptionChange = (name: string) => (value: any) => {
    props.editFormFields({ [name]: value })
  }

  return (
    <div>
      <FormControlLabel
        className={classes.formcontrolLabel}
        control={
          <FormControl className={classes.formControl}>
            <Controller
              render={data => {
                return (
                  <MultiLangTextEditor
                    value={props.formFields?.mission}
                    required={false}
                    handleChange={(value: string) => {
                      handleDescriptionChange("mission")(value)
                    }}
                  />
                )
              }}
              name="mission"
              rules={{}}
              control={props.control}
              defaultValue={props.formFields.mission || ""}
            />
            {props.errors.mission ? <FormHelperText style={{ color: "#EF4935" }}>{props.errors.mission.message}</FormHelperText> : null}
          </FormControl>
        }
        labelPlacement="top"
        label={
          <Typography variant="body1" className={classes.label}>
            {t("company_mission")}
          </Typography>
        }
      />
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    formFields: state.companyReducer.form
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    editFormFields: (value: any) => dispatch(companyActions.editFormContent(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyMission)
