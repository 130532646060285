import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    borderBottom: "1px solid #eaeaea",
    justifyContent: "space-between",
    alignItems: "center",
    height: "50px"
  },
  button: {
    padding: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    minWidth: "100px",
    marginLeft: "5px",
    marginRight: "10px"
  },
  icon: {
    marginRight: "5px",
    width: "30px",
    height: "25px"
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "10px",
    paddingTop: "20px"
  },
  singleFilterDiv: {
    marginLeft: "2.5px",
    marginRight: "2.5px"
  }
}))

export default useStyles
