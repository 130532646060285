import React, { useState } from "react"
import { connect } from "react-redux"

import AddIcon from "@material-ui/icons/Add"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { useTranslation } from "react-i18next"
import TemplateForm from "../TemplateForm"
import { Dispatch } from "redux"
import { IActionType } from "../../../../types"
import { messagesActions } from "../../../../store/actions/messages"
import { useTheme } from "@material-ui/core"

interface Props {}

const AddTemplates: React.FC<Props> = props => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      {matches ? (
        <Button variant="outlined" color="secondary" onClick={handleOpen}>
          {t("add_template")}
        </Button>
      ) : (
        <IconButton color="secondary" onClick={handleOpen} size="small">
          <AddIcon />
        </IconButton>
      )}
      <TemplateForm open={open} handleClose={handleClose} mode={"create"} />
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    createTemplate: async (info: any) => dispatch(await messagesActions.addTemplate(info))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTemplates)
