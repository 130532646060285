import { FormControl, TextField } from "@material-ui/core"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import ImagePicker from "../ImagePicker"

type OpenGraphData = {
  title?: string
  description?: string
  image?: any // File | string | null
}

interface Props {
  onImageUpload?: (image: File) => void
  onChange: (data: OpenGraphData) => void
  onFocusLeave?: (data: OpenGraphData) => void
  defaultTags?: OpenGraphData
  variant?: "standard" | "dense" | "oneline"
  imageSize?: { height: Number; width: Number }
}

const OpenGraphTagsEditor: React.FC<Props> = ({ onChange, defaultTags, onFocusLeave }) => {
  const { t } = useTranslation()
  const [ogData, setOgData] = useState({ ...defaultTags })
  const [removeImage, setRemoveImage] = useState(false)

  const handleTitleChange = (e: any) => {
    const data = {
      ...ogData,
      title: e.target.value
    }

    setOgData(data)
    onChange(data)
  }

  const handleDescriptionChange = (e: any) => {
    const data = {
      ...ogData,
      description: e.target.value
    }

    setOgData(data)
    onChange(data)
  }

  const handleImageChange = (image: File) => {
    const data = {
      ...ogData,
      image: image
    }

    setOgData(data)
    onChange(data)
    handleSubmitOnFocusLeave("image", image)
  }

  const handleSubmitOnFocusLeave = async (name: string, value?: any) => {
    const currentData = { ...ogData, [name]: value }
    if (onFocusLeave) {
      await onFocusLeave(currentData)
    }
  }

  const handleRemoveImage = () => {
    setRemoveImage(true)
    const newOgData = {
      ...ogData,
      image: undefined
    }
    setOgData(newOgData)
    handleSubmitOnFocusLeave("image")
    onChange(newOgData)
  }

  return (
    <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
      <div>
        <ImagePicker removeImage={handleRemoveImage} defaultImageUrl={!removeImage ? ogData?.image : null} onImagePicked={handleImageChange} />
      </div>
      <div style={{ marginLeft: "1rem", marginTop: "8px" }}>
        <FormControl fullWidth style={{ marginBottom: "1rem" }}>
          <TextField
            fullWidth
            name="title"
            margin="dense"
            variant="outlined"
            label={t("og_title")}
            placeholder={t("og_title")}
            onChange={handleTitleChange}
            defaultValue={defaultTags?.title}
            onBlur={e => {
              handleSubmitOnFocusLeave("title", e.target.value)
            }}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: "1rem" }}>
          <TextField
            multiline
            fullWidth
            minRows={2}
            margin="dense"
            variant="outlined"
            name="description"
            label={t("og_description")}
            placeholder={t("og_description")}
            onChange={handleDescriptionChange}
            defaultValue={defaultTags?.description}
            onBlur={e => {
              handleSubmitOnFocusLeave("description", e.target.value)
            }}
          />
        </FormControl>
      </div>
    </div>
  )
}

export default OpenGraphTagsEditor
