import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    bottom: 0,
    position: "absolute"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none"
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: 0,
    position: "absolute",
    maxHeight: "70%",
    width: "100%",
    overflowY: "auto",
    outline: "none",
    borderRadius: "0px 0px 10px 10px"
  },
  contents: {
    height: "calc(80% - 50px)",
    overflowY: "auto"
  }
}))

export default useStyles
