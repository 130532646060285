import React from "react"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"

interface Props {
  type?: "create" | "edit"
  loading?: boolean
}

const GenericActionButtons: React.FC<Props> = props => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <Button variant="outlined" color={"primary"} onClick={handleCancel}>
        {t("cancel")}
      </Button>
      <Button variant="contained" color="primary" type="submit">
        {props.loading ? <CircularProgress size={22} style={{ color: "#fff" }} /> : t("submit")}
      </Button>
    </div>
  )
}

export default GenericActionButtons
