import React, { useState } from "react"
import axios from "../../../api/iskibris/iskibris"

import Link from "@material-ui/core/Link"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"

import { useHistory } from "react-router"
import { useTranslation } from "react-i18next"

import useStyles from "../styles"
import Copyright from "../../Copyright"

interface Props {
  onSuccess: () => void
}

const ForgotPassword: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const history = useHistory()

  const [values, setValues] = useState({
    email: "",
    password: "",
    remember_me: null,
    forget_password_email: ""
  })

  const [loadingFP, setLoadingFP] = useState(false)
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState<boolean | null>(null)

  const setValuesHandler = (name: string) => (event: any) => setValues({ ...values, [name]: event.target.value })

  const forgetPasswordHandler = async (event: any) => {
    event.preventDefault()
    const params = { email: values.forget_password_email }
    setLoadingFP(true)
    await axios
      .post("/api/hiring/v2/settings/forget-password", params)
      .then(response => {
        setForgotPasswordSuccess(true)
      })
      .catch(err => {
        setForgotPasswordSuccess(false)
      })
    setLoadingFP(false)
  }

  return (
    <div className={classes.root}>
      {/* <Greetings />
      <LanguageSelector /> */}
      <form className={classes.form} onSubmit={forgetPasswordHandler}>
        <Typography component="h4" variant="h4" align="center" gutterBottom>
          {t("forgot_password")}?
        </Typography>
        {forgotPasswordSuccess ? (
          <Typography
            variant="body1"
            style={{
              textAlign: "center",
              width: "100%",
              padding: "15px",
              marginBottom: "15px"
            }}
          >
            {t("forgot_password_success")}
          </Typography>
        ) : (
          <React.Fragment>
            <Typography component="p" variant="body1" style={{ textAlign: "center" }} gutterBottom>
              {t("reset_password_instructions")}
            </Typography>
            <TextField
              variant="outlined"
              margin="dense"
              required
              value={values.forget_password_email}
              fullWidth
              id="forget_password_email"
              label={t("email_address")}
              name="forget_password_email"
              autoComplete="forget_password_email"
              autoFocus
              onChange={setValuesHandler("forget_password_email")}
            />
            {forgotPasswordSuccess === false ? (
              <Typography
                variant="body1"
                style={{
                  backgroundColor: "red",
                  color: "white",
                  width: "100%",
                  maxWidth: "600px",
                  padding: "10px",
                  marginTop: "15px",
                  textAlign: "center",
                  borderRadius: "2px"
                }}
              >
                {t("forgot_password_fail")}
              </Typography>
            ) : null}
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              {loadingFP ? <CircularProgress size={22} style={{ color: "#fff" }} /> : t("submit")}
            </Button>
          </React.Fragment>
        )}
        {forgotPasswordSuccess ? (
          <React.Fragment>
            <Link
              href="#"
              onClick={(event: any) => {
                event.preventDefault()
                setForgotPasswordSuccess(null)
                setValues({ ...values, forget_password_email: "" })
                history.push("/login", history.location.state)
                // props.handleReturnToSignin();
              }}
            >
              {t("return_to_sign_in")}
            </Link>
            <Link
              href="#"
              style={{ float: "right" }}
              color="secondary"
              onClick={(event: any) => {
                event.preventDefault()
                setForgotPasswordSuccess(null)
              }}
            >
              {t("did_not_receive_email")}?
            </Link>
          </React.Fragment>
        ) : (
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.extraActionButtonGrid}>
              <Link
                href="#"
                onClick={(event: any) => {
                  event.preventDefault()
                  setForgotPasswordSuccess(null)
                  setValues({ ...values, forget_password_email: "" })
                  history.push("/login", history.location.state)
                }}
              >
                {t("return_to_sign_in")}?
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.extraActionButtonGrid}>
              <Link
                href="#"
                variant="body2"
                onClick={(event: any) => {
                  event.preventDefault()
                  history.push("/register", history.location.state)
                }}
              >
                {t("do_not_have_account")}
              </Link>
            </Grid>
          </Grid>
        )}
      </form>
      <div style={{ marginTop: "30px" }}>
        <Copyright />
      </div>
    </div>
  )
}

export default ForgotPassword
