import React from "react"
import { Dispatch } from "redux"
import { connect, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import Button from "@material-ui/core/Button"

import { IoOptionsOutline } from "react-icons/io5"
import ImportExportIcon from "@material-ui/icons/ImportExport"

import useStyles from "./styles"
import MobileSortBy from "./SortBy"
import MobileFilters from "./MobileFilters"
import { IActionType } from "../../../types"

interface Props {
  sortBy: number
}

const sortMap: any = {
  "created_at:desc": 0,
  "created_at:asc": 1,
  "name:asc": 2,
  "name:desc": 3,
  "job_title:asc": 4,
  "job_title:desc": 5
}

const MobileComponents: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const orderBy = useSelector((state: any) => state.applicantsReducer.orderBy)
  const orderDirection = useSelector((state: any) => state.applicantsReducer.orderDirection)

  const [open, setOpen] = React.useState<string | boolean>(false)

  const handleSortClick = () => {
    setOpen("sort")
  }

  const handleFilterClick = () => {
    setOpen("filter")
  }

  return (
    <div className={classes.root}>
      <Button variant="outlined" className={classes.button} onClick={handleFilterClick}>
        <IoOptionsOutline className={classes.icon} />
        {t(`filters`)}
      </Button>
      <Button variant="outlined" className={classes.button} onClick={handleSortClick}>
        <ImportExportIcon className={classes.icon} />
        {t(`sort_id_${sortMap[`${orderBy}:${orderDirection}`]}`)}
      </Button>
      {open === "filter" ? (
        <MobileFilters
          handleClose={() => {
            setOpen(false)
          }}
        />
      ) : null}
      {open === "sort" ? (
        <MobileSortBy
          handleClose={() => {
            setOpen(false)
          }}
        />
      ) : null}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    sortBy: state.applicantsReducer.sortByValue
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileComponents)
