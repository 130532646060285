import React from "react"

import Cookies from "js-cookie"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"

import Pusher from "pusher-js"
import Paper from "@material-ui/core/Paper"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import CssBaseline from "@material-ui/core/CssBaseline"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { IActionType } from "../../types"
import NavBar from "../../components/NavBar/NavBar"
import SideBar from "../../components/SideBar"
import Copyrights from "../../components/Copyright"

import useStyles from "./styles"
import { ICompany } from "../../types/company"
import { applicantsActions } from "../../store/actions/applicants"

import { IApplicant, IApplicantFilters, IPaginatedApplicants } from "../../types/applicants"

import Messaging from "../../components/ApplicantManagement/Messaging"
import MessageAll from "../../components/ApplicantManagement/MessageAll"
import UserSideMenu from "../../components/MobileComponents/UserSideMenu"
import ExportApplicants from "../../components/ApplicantManagement/ExportApplicants"
import ApplicantFilters from "../../components/ApplicantManagement/ApplicantFilters"

import useGtm from "../../hooks/useGtm"
import { IUser } from "../../types/user"
import ManualApplication from "../../components/ApplicantManagement/ManualApplication"
import ApplicantsKanbanView from "../../components/ApplicantManagement/ApplicantsKanbanView"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import ApplicantsListView from "../../components/ApplicantManagement/ApplicantsListView"
import { DashboardOutlined, Dashboard, ListOutlined } from "@material-ui/icons"

const options = {
  broadcaster: "pusher",
  key: process.env.REACT_APP_PUSHER_KEY || "",
  cluster: "eu",
  forceTLS: true,
  authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: `Bearer ${Cookies.get("auth_token")}`,
      Accept: "application/json"
    }
  }
}

interface Props {
  company: ICompany
  currentUser: IUser
  searchOpen: boolean
  sendMessage: boolean
  filters: IApplicantFilters
  singleApplicant: IApplicant
  applicants: IPaginatedApplicants
  clearApplicants: () => void
  addSingleApplicant: (info: any) => void
  fetchActiveApplicants: (companyId: any) => void
  fetchSingleApplicant: (applicantId: any) => void
}

const ApplicantsManagementV2: React.FC<Props> = props => {
  const params: { id?: any } = useParams()
  
  const checkFiltersAvailable = (skipKeyword?: boolean) => {
    if (
      props.filters.rating?.length > 0 ||
      props.filters.stage?.length > 0 ||
      props.filters.city?.length > 0 ||
      props.filters.job?.length > 0 ||
      props.filters.field_of_study?.length > 0 ||
      props.filters.work_permit?.length > 0 ||
      props.filters.education_level?.length > 0 ||
      props.filters.gender?.length > 0 ||
      props.filters.age?.length > 0 ||
      props.filters.driver_licence?.length > 0 ||
      (skipKeyword ? false : !!props.filters.keyword)
    ) {
      return true
    } else {
      return false
    }
  }

  const classes = useStyles({ ...props, checkFiltered: checkFiltersAvailable })

  const { t } = useTranslation()

  const { tagEvent } = useGtm()
  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [atsMode, setAtsMode] = React.useState(Cookies.get("ats_mode") ?? "list")

  const { company, applicants, currentUser, singleApplicant, addSingleApplicant, fetchSingleApplicant, fetchActiveApplicants } = props

  const authToken = Cookies.get("auth_token")

  React.useEffect(() => {
    document.title = t("applicants_management_title")
  })

  React.useEffect(() => {
    tagEvent({
      event: "view_applicants_page",
      data: { company_name: company.name, user_name: currentUser?.full_name }
    })
  }, [company, currentUser, tagEvent])

  React.useEffect(() => {
    let pusher: Pusher | null
    let channelStr: any

    if (company && authToken && !applicants) {
      fetchActiveApplicants(company.id)

      pusher = new Pusher(options.key, {
        cluster: options.cluster,
        forceTLS: options.forceTLS,
        authEndpoint: options.authEndpoint,
        auth: {
          ...options.auth,
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json"
          }
        }
      })

      channelStr = `private-companies.${company.id}.applications`

      let aChannel = pusher.subscribe(channelStr)
      aChannel.bind("new-application-submitted", function (data: any) {
        addSingleApplicant(data)
      })
    }

    return () => {
      if (company && authToken && !!pusher && !!channelStr) {
        pusher.disconnect()
      }
    }
  }, [company, applicants, authToken, addSingleApplicant, fetchActiveApplicants])

  React.useEffect(() => {
    const paramId = params.id
    if (applicants && applicants.data.length > 0) {
      let appId: any
      if (paramId) {
        appId = parseInt(paramId)
      } else {
        if(singleApplicant){
          appId = singleApplicant.id
        } else {
          const firstApplicant = applicants.data?.find(item => item.id && item.id > 0)
          if (firstApplicant) {
            appId = firstApplicant.id
          }
        }
      }
      if (appId && singleApplicant?.id !== appId) {
        fetchSingleApplicant(appId)
      }
    }
  }, [applicants, params, singleApplicant, fetchSingleApplicant])

  const handleAtsModeUpdate = (value: "list" | "kanban") => (event: any) => {
    setAtsMode(value)
    Cookies.set('ats_mode', value, {
      expires: 365
    })
    tagEvent( { event: "applications_mode_changed", data: { mode: value, user_id: currentUser?.id, company_id: company?.id } } )
  }

  return (
    <div>
      <CssBaseline />
      <NavBar />
      <SideBar />
      {matchesMobile ? <UserSideMenu /> : null}
      <main className="content content--has-sidebar" style={{ paddingTop: "62px", paddingBottom: "5px" }}>
        <div className={classes.header}>
          <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "15px"}}>
            <Typography variant="h5" className={"page-title"}>{t("applicants_management")}</Typography>
            {
              !matchesMobile ? (
                <ToggleButtonGroup className={classes.toggleButtonGroup} size="small" color="secondary">
                  <ToggleButton selected={atsMode === "list"} size="small" value={true} onClick={handleAtsModeUpdate("list")} className={classes.toggleButton} color={atsMode === "list" ? "secondary" : "primary"}>
                    <ListOutlined className={classes.toggleButtonIcon} style={{width: "20px", height: "20px"}} />
                    {t("applications_list_mode")}
                  </ToggleButton>
                  <ToggleButton selected={atsMode === "kanban"} value={false} onClick={handleAtsModeUpdate("kanban")} className={classes.toggleButton} color={atsMode === "kanban" ? "secondary" : "primary"}>
                    {atsMode === "kanban" ? <Dashboard className={classes.toggleButtonIcon} /> : <DashboardOutlined className={classes.toggleButtonIcon} />}
                    {t("applications_kanban_mode")}
                  </ToggleButton>
                </ToggleButtonGroup>
              ) : null
            }
          </div>
          <div style={{ display: "flex" }}>
            <MessageAll />
            <ExportApplicants />
            <ManualApplication />
          </div>
        </div>
        <Divider />
        {
          atsMode === "kanban" && !matchesMobile
          ?
          (
            <Paper style={{ height: "calc(100vh - 140px)", display: 'flex', flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
              <div style={{width: "100%"}}>
                <ApplicantFilters />
              </div>
              <Divider />
              <ApplicantsKanbanView
                singleApplicant={props.singleApplicant}
                applicants={props.applicants}
                filters={props.filters}
              />
            </Paper>    
          )
          :
          (
            <Paper style={{ height: "calc(100vh - 140px)" }}>
              <ApplicantFilters />
              <Divider />
              <ApplicantsListView
                singleApplicant={props.singleApplicant}
                applicants={props.applicants}
                filters={props.filters}
              />
            </Paper>
          )
        }
        {props.sendMessage ? <Messaging /> : null}
        
      </main>
      <footer>
        <Copyrights />
      </footer>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  singleApplicant: state.applicantsReducer.singleApplicant,
  sendMessage: state.messagesReducer.sendMessage,
  applicants: state.applicantsReducer.applicants,
  searchOpen: state.applicantsReducer.searchOpen,
  filters: state.applicantsReducer.filters,
  currentUser: state.userReducer.profile,
  company: state.companyReducer.company
})

const mapDispatchtoProps = (dispatch: Dispatch<IActionType>) => ({
  fetchActiveApplicants: async (id: any) => dispatch(await applicantsActions.fetchActiveApplicants(id)),
  fetchSingleApplicant: async (id: any) => dispatch(await applicantsActions.fetchSingleApplicant(id)),
  addSingleApplicant: (info: any) => dispatch(applicantsActions.addSingleApplicant(info)),
  clearApplicants: () => dispatch(applicantsActions.clearApplicants())
})

export default connect(mapStateToProps, mapDispatchtoProps)(ApplicantsManagementV2)
