import React from "react"
import Moment from "react-moment"

import Typography from "@material-ui/core/Typography"
import ListItemIcon from "@material-ui/core/ListItemIcon"

// Icons
import InterestedIcon from "@material-ui/icons/CheckCircle"
import NotInterestedIcon from "@material-ui/icons/CancelRounded"
import MaybeIcon from "@material-ui/icons/Help"
import UnratedIcon from "@material-ui/icons/FiberManualRecord"

import { IApplicant } from "../../../types/applicants"

import useStyles from "./styles"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"

import UploadIcon from "@material-ui/icons/MergeTypeOutlined"
import { extractPlaceLabel } from "../../../utils/places"

interface Props {
  selected?: boolean
  applicant: IApplicant
  handleClick: (applicant: any) => void
}

const ApplicantCard: React.FC<Props> = props => {
  const classes = useStyles(props)
  const { t, i18n } = useTranslation()

  const onClick = () => {
    props.handleClick(props.applicant)
  }

  let ratingIcon: any

  switch (props.applicant.rating_id) {
    case 1:
      ratingIcon = <InterestedIcon className={"icon icon--interested"} />
      break
    case 2:
      ratingIcon = <MaybeIcon className={"icon icon--maybe"} />
      break
    case 3:
      ratingIcon = <NotInterestedIcon className={"icon icon--not-interested"} />
      break
    default:
      ratingIcon = <UnratedIcon className={"icon icon--unrated"} />
      break
  }

  const parseDate = (date: any) => {
    if (typeof date === "number") {
      return dayjs.unix(date).toString()
    } else {
      return dayjs(date).toString()
    }
  }

  return (
    <div className={classes.root} onClick={onClick}>
      <div className={classes.listItem}>
        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
          <ListItemIcon className={classes.listItemIcon}>{ratingIcon}</ListItemIcon>
          <div>
            <Typography variant="body1">{props.applicant.name}</Typography>
            <Typography variant="body2" className={classes.jobTitle}>
              {props.applicant.job_title}
            </Typography>
            <Typography variant="body2" className={classes.jobLocation}>
              {
                props.applicant?.place
                ?
                (
                  extractPlaceLabel(props.applicant?.place, i18n.language)
                )
                :
                (
                  t(`city_id_${props.applicant.city_id !== null && props.applicant.city_id !== undefined ? props.applicant.city_id : props.applicant.job_city_id}`)
                )
              }
            </Typography>
          </div>
        </div>
        <div className={classes.secondarySideContainer}>
          {props.applicant?.manually_created_at && props.applicant?.manually_created_by && <UploadIcon color="inherit" fontSize="small"/>}
          {(props.applicant.stage_id_enum === 'NEW') || (props.applicant.status === 0) ? (
            <Typography variant="body1" className={classes.newTag}>
              {t("new")}
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.applicationStatus}>
              {
                props.applicant?.stage_id_enum
                ?
                  t(`stage_id_enum_${props.applicant.stage_id_enum}`)
                :
                  t(`stage_id_${props.applicant.stage_id !== undefined ? props.applicant.stage_id : props.applicant.status}`)
              }
            </Typography>
          )}
          <Typography variant="body2" className={classes.applicationDate}>
            <Moment fromNow>{props.applicant.created_at ? parseDate(props.applicant.created_at) : parseDate(props.applicant.date)}</Moment>
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default ApplicantCard
