import React from 'react';
import { useTranslation } from 'react-i18next';

import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { IApplicant } from '../../../../../types/applicants';

interface Props {
  options?: any[]
  applicant: IApplicant
}

export default function FolderActions( {applicant, options}: Props ) {
  const {t} = useTranslation()
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionClick = (option: any) => (event: any) => {
    if(option?.onClick){
      option.onClick(event)
    }
    handleClose()
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{color: "#000"}}>
        {t("add")} <KeyboardArrowDown />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          options && options?.map(option => {
            return (
              <MenuItem onClick={handleOptionClick(option)}>
                {option.children ? option.children : option.title}
              </MenuItem>
            )
          })
        }
      </Menu>
    </div>
  );
}