import React from "react"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useHistory } from "react-router"
import { useTranslation } from "react-i18next"

import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"

import AddIcon from "@material-ui/icons/Add"
import ClearIcon from "@material-ui/icons/ClearOutlined"
import DropupArrow from "@material-ui/icons/KeyboardArrowUp"
import DropdownArrow from "@material-ui/icons/KeyboardArrowDown"

import Link from "@material-ui/core/Link"
import Paper from "@material-ui/core/Paper"
import Avatar from "@material-ui/core/Avatar"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"

import useStyles from "./styles"
import { IActionType } from "../../../types"
import { ICompany } from "../../../types/company"
import { companyActions } from "../../../store/actions/company"
import avatarPlaceholder from "../../../assets/images/placeholder-company.png"

import { List as VirtualizedList, AutoSizer, CellMeasurerCache, CellMeasurer } from "react-virtualized"
import Cookies from "js-cookie"

interface Props {
  company: ICompany
  searchResults: ICompany[]
  companies: ICompany[]
  searchCompanies: (companies: ICompany[], query: string) => void
}

const cache = new CellMeasurerCache({
  defaultHeight: 48,
  defaultWidth: 350,
  fixedWidth: true
})

const CompanyMenu: React.FC<Props> = props => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const [open, setOpen] = React.useState(false)
  const [searchQuery, setSearchQuery] = React.useState("")

  const handleChangeSearchQuery = (event: any) => {
    if (event.target.value.length > 0) {
      props.searchCompanies(props.companies, event.target.value)
    }
    setSearchQuery(event.target.value)
  }

  const handleCreateCompany = () => {
    history.push("/employer/create")
  }

  const handleMouseDownClear = (event: any) => {
    event.preventDefault()
  }

  const handleClose = () => {
    if (open) {
      setOpen(false)
    }
  }

  const handleCompanyButtonClicked = () => {
    setOpen(!open)
  }

  let companies = searchQuery.length > 0 ? props.searchResults : props.companies

  const rowRenderer = ({ index, key, style, parent }: { index: any; key: any; style: any; parent: any }) => {
    const item: any = companies ? companies[index] : {}
    const labelId = `checkbox-list-label-${item.id}`
    return (
      <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style} key={key}>
          <Link
            href="/"
            key={item.id}
            onClick={() => {
              localStorage.setItem("current_company_id", item.id)
              Cookies.set("CompanyToken", item.token, { secure: true, sameSite: "none" })
            }}
            style={{ textDecoration: "none" }}
          >
            <ListItem dense button key={item.id} role={undefined} selected={item.id === props.company.id}>
              <ListItemAvatar>
                <Avatar alt={item.name} src={item.logo || avatarPlaceholder} className={classes.avatar} />
              </ListItemAvatar>
              <ListItemText id={labelId}>
                {item.name} <span className={classes.shortLabel}>{item.code}</span>
              </ListItemText>
            </ListItem>
          </Link>
        </div>
      </CellMeasurer>
    )
  }

  return (
    <div className={classes.root}>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <div onClick={handleCompanyButtonClicked} className={classes.button}>
            {props.company.name}
            {open ? <DropupArrow className={classes.rightIcon} /> : <DropdownArrow className={classes.rightIcon} />}
          </div>
          {open ? (
            <Paper className={classes.paper} elevation={3}>
              {props.companies ? (
                props.companies.length > 5 ? (
                  <div style={{ padding: "5px" }}>
                    <TextField
                      fullWidth
                      autoFocus
                      margin="dense"
                      variant="outlined"
                      value={searchQuery}
                      onChange={handleChangeSearchQuery}
                      placeholder={t("search_for_more")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="toggle"
                              onClick={() => {
                                setSearchQuery("")
                              }}
                              onMouseDown={handleMouseDownClear}
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    <Divider />
                  </div>
                ) : null
              ) : null}
              <div className={classes.companiesContainer} style={{ height: companies.length > 5 ? 240 : companies.length > 0 ? companies.length * 48 : 48 }}>
                <AutoSizer>
                  {({ width, height }) => {
                    return (
                      <VirtualizedList
                        width={width}
                        height={height}
                        rowHeight={cache.rowHeight}
                        rowCount={companies.length}
                        rowRenderer={rowRenderer}
                        overscanRowCount={5}
                        deferredMeasurementCache={cache}
                      />
                    )
                  }}
                </AutoSizer>
                {/* {companies.slice(0, 5).map((item: any) => {
                  const labelId = `checkbox-list-label-${item.id}`;
                  return (
                    <Link href="/" key={item.id} onClick={() => {localStorage.setItem("current_company_id", item.id);}} style={{textDecoration: "none"}}>
                      <ListItem
                        key={item.id}
                        role={undefined}
                        dense
                        button
                        // onClick={() => {selectCompany(item)}}
                        selected={item.id === props.company.id}
                      >
                        <ListItemAvatar><Avatar alt={item.name} src={item.logo || avatarPlaceholder} className={classes.avatar}/></ListItemAvatar>
                        <ListItemText id={labelId}>{item.name} <span className={classes.shortLabel}>{item.code}</span></ListItemText>
                      </ListItem>
                    </Link>
                  );
                })} */}
              </div>
              <Divider />
              <ListItem key={"create_company"} role={undefined} dense button onClick={handleCreateCompany}>
                <ListItemIcon>
                  <AddIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText style={{ padding: "5px" }} id={"create_company"}>
                  {t("create_company")}
                </ListItemText>
              </ListItem>
            </Paper>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company,
    companies: state.companyReducer.companies,
    searchResults: state.companyReducer.searchResults
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => ({
  searchCompanies: (companies: any, query: any) => dispatch(companyActions.searchCompanies(companies, query))
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyMenu)
