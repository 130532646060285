import React from "react"

import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"

import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"

import { IApplicant, IApplicantFilters, IPaginatedApplicants } from "../../../types/applicants"

import useStyles from "./styles"
import { applicantStages } from "../../../utils/data/enums"
import Kanban from "./Kanban"

import { CloseOutlined } from "@material-ui/icons"
import { AppBar, Backdrop, Fade, IconButton, Modal } from "@material-ui/core"
import ApplicantDetails from "../ApplicantDetails"
import useGtm from "../../../hooks/useGtm"
import { useDispatch, useSelector } from "react-redux"
import { applicantsActions } from "../../../store/actions/applicants"
import InfiniteScroll from "react-infinite-scroll-component"

import _ from "lodash"

interface Props {
  filters: IApplicantFilters
  singleApplicant: IApplicant
  applicants: IPaginatedApplicants
}

const ApplicantsKanbanView: React.FC<Props> = props => {

  const history = useHistory()

  const params: {id?: any} = useParams()

  const dispatch = useDispatch()

  const checkFiltersAvailable = (skipKeyword?: boolean) => {
    if (
      props.filters.rating?.length > 0 ||
      props.filters.stage?.length > 0 ||
      props.filters.city?.length > 0 ||
      props.filters.job?.length > 0 ||
      props.filters.field_of_study?.length > 0 ||
      props.filters.work_permit?.length > 0 ||
      props.filters.education_level?.length > 0 ||
      props.filters.gender?.length > 0 ||
      props.filters.age?.length > 0 ||
      props.filters.driver_licence?.length > 0 ||
      (skipKeyword ? false : !!props.filters.keyword)
    ) {
      return true
    } else {
      return false
    }
  }

  const classes = useStyles({ ...props, checkFiltered: checkFiltersAvailable })

  const { t } = useTranslation()
  const { tagEvent } = useGtm()

  const company = useSelector((state: any) => state.companyReducer.company)
  const exportApplicants = useSelector((state: any) => state.applicantsReducer.exports)

  const stageIdEnumCounters = exportApplicants ? _.countBy(exportApplicants?.data, 'stage_id_enum') : {}

  const loader = (
    <div className={classes.rootLoader}>
      <CircularProgress />
    </div>
  )

  let noApplications = checkFiltersAvailable() ? (
    <div className={classes.noApplicantsContainer}>
      <Typography variant="body1">{t("no_applicants_match_filter")}</Typography>
    </div>
  ) : (
    <div className={classes.noApplicantsContainer}>
      <Typography variant="body1">{t("no_applicants_for_company")}</Typography>
      <div style={{ width: "100%", marginTop: "10px" }}>
        <Tooltip title={`${t("no_applicants_job_button_tooltip")}`}>
          <Button
            variant="outlined"
            color="secondary"
            style={{ margin: "10px" }}
            onClick={() => {
              history.push("/jobs")
            }}
          >
            {t("add_jobs")}
          </Button>
        </Tooltip>
        <Tooltip title={`${t("no_applicants_employer_button_tooltip")}`}>
          <Button
            variant="outlined"
            color="secondary"
            style={{ margin: "10px" }}
            onClick={() => {
              history.push("/employer/profile")
            }}
          >
            {t("employer_profile")}
          </Button>
        </Tooltip>
      </div>
    </div>
  )

  const getKanbanColumns = () => {
    return applicantStages.map(item => (
      {name: item, label: `${t(`stage_id_enum_${item}`).toLocaleUpperCase()} ${stageIdEnumCounters[item] ? `(${stageIdEnumCounters[item]})` : ""}`}
    ))
  }
  const handleColumnChangedCallback = async (applicationId: any, newStatus: any) => {
    const appId = parseInt(applicationId)
    const application = props.applicants.data.find(item => item.id === appId)
    if(application){
      tagEvent({ event: "applicant_status_changed", data: { applicant_old_status: application.stage_id_enum, applicant_new_status: newStatus } })
      dispatch(applicantsActions.optimisticUpdateApplicantStatus(appId, newStatus))
      dispatch(await applicantsActions.updateApplicantStatus(appId, newStatus))
    }
  }

  const handleItemClick = (application: any) => {
    history.push(`/applicants/${application?.id}`)
  }

  const handleCloseApplication = () => {
    history.push(`/applicants`)
  }

  const orderBy = useSelector((state: any) => state.applicantsReducer.orderBy)
  const orderDirection = useSelector((state: any) => state.applicantsReducer.orderDirection)
  const activeFilter = useSelector((state: any) => state.applicantsReducer.activeFilter)
  
  const handleLoadMore = async (page: number) => {
    dispatch(await applicantsActions.applyFilters({
      ...props.filters,
      activeFilter: activeFilter,
      companyId: company.id,
    },
    page,
    orderBy,
    orderDirection))
  }


  return (
    <>
      {!props.applicants ? (
        loader
      ) : props.applicants.data.length > 0 ? (
        <div style={{flex: 1, width: "100%", overflow: "auto"}} id="kanban-scollable-div">
          <InfiniteScroll
            endMessage={<div></div>}
            scrollableTarget="kanban-scollable-div"
            dataLength={props.applicants.data.length}
            hasMore={props.applicants.current_page < props.applicants.last_page}
            next={() => {
              handleLoadMore(props.applicants.current_page + 1)
            }}
            loader={
              <div style={{ width: "100%", display: "flex", justifyContent: "center", padding: "15px", background: "#F8F8F8" }}>
                <CircularProgress size={25} />
              </div>
            }
            style={{overflow: "inherit"}}
          >
            <Kanban
              items={props.applicants.data}
              columns={getKanbanColumns()}
              onColumnChangedCallback={handleColumnChangedCallback}
              onItemClick={handleItemClick}
            />
          </InfiniteScroll>
        </div>
      ) : (
        noApplications
      )}
      {
          params.id
          ?
          (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={!!params.id}
              onClose={handleCloseApplication}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              <Fade in={!!params.id}>
                <div className={classes.modalPaper}>
                  <AppBar variant="outlined" position="sticky" className={classes.modalToolbar}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                      <Typography color="primary" variant="h6">
                        {t("applicant_details")}
                      </Typography>
                      <IconButton onClick={handleCloseApplication}>
                        <CloseOutlined />
                      </IconButton>
                    </div>
                  </AppBar>
                  <div className={classes.modalContents}>
                    {
                      props.singleApplicant && props.singleApplicant.id === parseInt(params.id)
                      ?
                      (
                        <ApplicantDetails centeredActions />
                      )
                      :
                      (
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                          <CircularProgress />
                        </div>
                      )
                    }
                  </div>
                </div>
              </Fade>
            </Modal>
          )
          :
          (
            null
          )
        }
    </>
  )
}

export default ApplicantsKanbanView
