import React, { useState } from "react"
import { connect } from "react-redux"

import Menu from "@material-ui/core/Menu"
import Button from "@material-ui/core/Button"
import MenuItem from "@material-ui/core/MenuItem"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import CircularProgress from "@material-ui/core/CircularProgress"

import { makeStyles } from "@material-ui/core/styles"
import MoreVertIcon from "@material-ui/icons/MoreHoriz"

import { useTranslation } from "react-i18next"
import Moment from "react-moment"

import TemplateForm from "../TemplateForm"
import { IMessageTemplate } from "../../../../types/messages"
import { messagesActions } from "../../../../store/actions/messages"
import SingleTemplateCard from "../../../MobileComponents/SingleTemplateCard"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 3),
    paddingBottom: "0px",
    margin: "20px"
  },
  menu: {
    float: "right"
  },
  template: {
    borderBottom: "none !important",
    padding: "15px",
    paddingTop: "0px",
    cursor: "pointer",
    transition: "0.5s",
    listStyle: "none",
    "&:hover": {
      backgroundColor: "#F1F2F3 !important"
    }
  },
  tablecell: {
    borderBottom: "none !important"
  }
}))

interface Props {
  type: string
  template: IMessageTemplate
  deleteTemplate: (id: any) => void
}

const SingleTemplate: React.FC<Props> = props => {
  const { t } = useTranslation()

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const customOptions = [t("edit"), t("delete")]

  const standardOptions = [
    t("view"),
    t("customise")
    // t('edit'),
    // t('delete')
  ]

  const ITEM_HEIGHT = 48
  const [anchorEl, setAnchorEl] = useState(null)

  const [loading, setLoading] = useState(false)

  const [openTemplate, setOpenTemplate] = useState(false)

  const [mode, setMode] = useState("edit")

  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleTemplateClick = () => {
    setOpenTemplate(true)
  }

  const handleItemClicked = (value: any) => {
    setAnchorEl(null)

    if (props.type === "custom") {
      if (value === customOptions[0]) {
        setOpenTemplate(true)
      } else {
        handleDeleteClick()
      }
    } else {
      if (value === standardOptions[0]) {
        setMode("view")
        setOpenTemplate(true)
      } else if (value === standardOptions[1]) {
        setMode("customise")
        setOpenTemplate(true)
      } else if (value === standardOptions[2]) {
        setMode("edit")
        setOpenTemplate(true)
      } else {
        handleDeleteClick()
      }
    }
  }

  const handleClose = () => {
    setAnchorEl(null)

    setOpenTemplate(false)
  }

  const handleDeleteClick = async () => {
    setLoading(true)
    await props.deleteTemplate(props.template.id)
    setLoading(false)
  }

  const classes = useStyles()

  return (
    <React.Fragment>
      {!matchesMobile ? (
        <TableRow hover style={{ padding: "0px", transition: "0.5s" }}>
          <TableCell className={classes.tablecell} style={{ border: "none" }}>
            <Typography variant="body1">{t(`template_type_id_${props.template.messenger_template_type_id}`)}</Typography>
          </TableCell>
          <TableCell className={classes.tablecell} style={{ border: "none" }}>
            {props.type === "custom" ? (
              <Button variant="text" color="secondary" onClick={handleTemplateClick}>
                <Typography variant="body1">{props.template.title}</Typography>
              </Button>
            ) : (
              <Typography variant="body1">
                {t("standard_template")} : {props.template.title}
              </Typography>
            )}
          </TableCell>
          {props.type === "custom" ? (
            <React.Fragment>
              <TableCell className={classes.tablecell} style={{ border: "none" }}>
                <Typography variant="body1">
                  <Moment fromNow>{props.template.updated_at}</Moment>
                </Typography>
              </TableCell>
            </React.Fragment>
          ) : null}
          <TableCell className={classes.tablecell} style={{ border: "none" }}>
            <div className={classes.menu}>
              {loading ? (
                <CircularProgress size={18} color="primary" />
              ) : (
                <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} style={{ padding: "3px" }}>
                  <MoreVertIcon />
                </IconButton>
              )}
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: 200
                  }
                }}
              >
                {props.type === "custom"
                  ? customOptions.map(option => {
                      return (
                        <div key={option}>
                          <MenuItem
                            key={option}
                            onClick={() => {
                              handleItemClicked(option)
                            }}
                          >
                            {option}
                          </MenuItem>
                        </div>
                      )
                    })
                  : standardOptions.map(option => {
                      return (
                        <div key={option}>
                          <MenuItem
                            key={option}
                            onClick={() => {
                              handleItemClicked(option)
                            }}
                          >
                            {option}
                          </MenuItem>
                        </div>
                      )
                    })}
              </Menu>
            </div>
          </TableCell>
        </TableRow>
      ) : (
        <SingleTemplateCard
          template={props.template}
          type={props.type}
          options={
            props.type === "custom"
              ? customOptions.map(option => {
                  return { title: option, handler: () => handleItemClicked(option) }
                })
              : standardOptions.map(option => {
                  return { title: option, handler: () => handleItemClicked(option) }
                })
          }
        />
      )}
      {openTemplate ? (
        <TemplateForm
          open={openTemplate}
          mode={mode}
          changeMode={mode => {
            setMode(mode)
          }}
          handleClose={handleClose}
          template={props.template}
        />
      ) : null}
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company
    // permissions: state.userReducer.permissions
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteTemplate: async (templateId: any) => dispatch(await messagesActions.deleteTemplate(templateId)),
    editTemplate: async (info: any, templateId: any) => dispatch(await messagesActions.editTemplate(info, templateId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleTemplate)
