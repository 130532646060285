import React from "react"

import { useTranslation } from "react-i18next"

import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"

import useStyles from "../styles"
import { useDispatch, useSelector } from "react-redux"
import { applicantsActions } from "../../../../store/actions/applicants"

import { Button, Fade, Modal, Backdrop } from "@material-ui/core"

import CloseRoundedIcon from "@material-ui/icons/CloseRounded"

import { IApplicant } from "../../../../types/applicants"
import AddApplicantToFolderForm from "./AddApplicantToFolderForm"

interface Props {
  applicant: IApplicant
  applicantFolders?: any[]
  children?: React.ReactNode
}

const AddApplicantToFolder : React.FC<Props> = (props) => {

  const { children } = props
  
  const {t} = useTranslation()

  const [open, setOpen] = React.useState(false)

  const dispatch = useDispatch()
  const company = useSelector((state: any) => state.companyReducer.company)

  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
    fetchFolders()
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const fetchFolders = async () => {
    dispatch(await applicantsActions.fetchCompanyFolders(company?.id))
  }

  return (
    <div>
      <div onClick={handleOpen}>
        {
          children
          ?
          (
            <>{children}</>
          )
          :
          (
            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", marginTop: "1rem"}}>
              <Button color="primary" style={{marginRight: "0.5rem", textDecoration: "underline"}} onClick={handleOpen}>
                {t("add_applicant_to_folder")}
              </Button>
            </div>
          )
        }
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.modalPaper}>
            <div className={classes.modalCloseContainer}>
              <Typography className={classes.modalTypographyTitle} variant="h6" gutterBottom>
                {t(`add_applicant_to_folder`)}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseRoundedIcon />
              </IconButton>
            </div>
            <Typography className={classes.modalTypographyCaption} variant="caption" gutterBottom>
              {t(`add_applicant_to_folder_caption`)}
            </Typography>
            <AddApplicantToFolderForm applicant={props.applicant} applicantFolders={props.applicantFolders} onSuccess={handleClose} onCancel={handleClose}/>
          </div>
        </Fade>
      </Modal>
    </div>
  )

}

export default AddApplicantToFolder