import React from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { ISubscription } from "../../../../types/billing"

import Accordion from "@material-ui/core/Accordion"
import Typography from "@material-ui/core/Typography"
// import ActiveIcon from "@material-ui/icons/VerifiedUser"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  secondaryHeading: {
    color: theme.palette.text.secondary
  },
  accordion: {
    boxShadow: "none",
    border: "none",
    marginBottom: "10px"
  },
  componentItemContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "5px"
  },
  componentItemDetail: {
    flex: 1
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  componentsHeader: {
    marginTop: "10px",
    fontWeight: "bold"
  },
  container: {
    width: "100%"
  }
}))

interface Props {
  canCancel?: boolean
  subscription: ISubscription
  onCancelSubscription?: (uuid: any) => void
}

const SingleSubscription: React.FC<Props> = props => {
  const { subscription } = props

  const { t } = useTranslation()

  const classes = useStyles()

  const checkJobsCompleted = () => {
    let jobsComps = subscription.components?.find(item => item.handle === 'com-jobs')
    if(jobsComps?.subscription_component_usage?.payload?.remaining_quantity_usage
        && jobsComps?.subscription_component_usage?.payload?.remaining_quantity_usage > 0){
      return false
    }
    return true;
  }

  return (
    <React.Fragment>
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <div>
            <Typography variant="h6">{subscription.offer?.name}</Typography>
            <Typography variant="body2" style={{ marginBottom: "7.5px", marginTop: "7.5px" }}>
              <span style={{ color: "#797979" }}>{t("status")} :-</span> <span style={{ color: "#4dca6c" }}> {
              t(checkJobsCompleted() ? "subscription_completed" : "active")}</span>
            </Typography>
            {subscription.expires_at ? (
              <Typography variant="body2">
                <span style={{ color: "#797979" }}>{t("validity")} :-</span> {dayjs(subscription.created_at).locale("tr").format("DD/MM/YYYY")} -{" "}
                {dayjs(subscription.expires_at).locale("tr").format("DD/MM/YYYY")}
              </Typography>
            ) : (
              <Typography variant="body2">{t("free_forever")}</Typography>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.container}>
            <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
              {t("description")}
            </Typography>
            <Typography variant="body2">{subscription.product.description}</Typography>
            <Typography variant="body1" className={classes.componentsHeader} gutterBottom>
              {t("components")}{" "}
            </Typography>
            <>
              {subscription.components.length > 0 ? (
                subscription.components.map(item => {
                  return (
                    <div key={item.id} className={classes.componentItemContainer}>
                      <Typography variant="body2" className={classes.componentItemDetail}>
                        {" "}
                        {item.name}
                      </Typography>
                      <div className={classes.componentItemDetail}>
                        {item.subscription_component_usage?.payload && (
                          <Typography className={classes.secondaryHeading} variant="body2">
                            {item.type === "metered" ? (
                              <>
                                {item.subscription_component_usage.payload?.remaining_quantity_usage} / {item.subscription_component_usage.payload?.allocated_quantity} {t("units")}
                              </>
                            ) : (
                              <>{t(`toggle_component_status_${item.subscription_component_usage.payload?.toggle}`)}</>
                            )}
                          </Typography>
                        )}
                      </div>
                    </div>
                  )
                })
              ) : (
                <Typography variant="body2">{t("no_components")}</Typography>
              )}
            </>
          </div>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  )
}

export default SingleSubscription
