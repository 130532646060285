import i18n from "i18next"
import { InitOptions } from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { en, tr } from "./locales"

const options: InitOptions = {
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  },

  debug: true,

  lng: localStorage.getItem("i18nextLng") || "tr",

  resources: {
    tr: tr,
    en: en
  },

  fallbackLng: "en",

  ns: ["translations"],

  defaultNS: "translations",
  keySeparator: false,

  react: {
    wait: false,
    bindI18n: "languageChanged loaded",
    // bindStore: "added removed",
    nsMode: "default"
  }
}

i18n.use(LanguageDetector).init(options)

export default i18n
