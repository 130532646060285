import axios from "../../api/iskibris/iskibris"
import { AxiosResponse } from "axios"
import { action } from "typesafe-actions"
import { create, fetch, post, remove, update } from "../../api/iskibris/redux-api-methods"
import { IApplicantSearchParams } from "../../types/applicants"
import { _createFilterSearchString } from "../../utils/_filterString"
import { actionTypes } from "./actionTypes"
import { applicantActiveStages } from "../../utils/data/enums"

export const applicantsActions = {
  fetchActiveApplicants: async (id: number, page?: number | null, orderBy: string | null = "created_at", orderDirection: string | null = "desc") => {
    let currPage = page
    if (!page) {
      currPage = 1
    }
    return await post({
      info: {},
      url: `/api/hiring/v2/applicants/search-v3?page=${currPage}&company=${id}&rating=0,1,2&stage_enum=${applicantActiveStages.join(",")}&order_by=${orderBy}&order_direction=${orderDirection}`,
      onErrorDispatch: actionTypes.ADD_APPLICANTS_ERROR,
      onSuccessDispatch: actionTypes.FILTER_ACTIVE_APPLICANTS
    })
  },
  removeActiveApplicantsFilter: async (id: number, page?: number | null, orderBy: string | null = "created_at", orderDirection: string | null = "desc") => {
    let currPage = page
    if (!page) {
      currPage = 1
    }
    return await post({
      info: {},
      url: `/api/hiring/v2/applicants/search-v3?page=${currPage}&company=${id}&order_by=${orderBy}&order_direction=${orderDirection}`,
      onErrorDispatch: actionTypes.ADD_APPLICANTS_ERROR,
      onSuccessDispatch: actionTypes.REMOVE_ACTIVE_FILTER_APPLICANTS
    })
  },
  applyFilters: async (params: IApplicantSearchParams, page: number, orderBy: string | null = "created_at", orderDirection: string | null = "desc") => {
    let currPage = page
    if (!page) {
      currPage = 1
    }
    const filterString = _createFilterSearchString(params)
    let response: AxiosResponse
    try {
      response = await axios.post(`/api/hiring/v2/applicants/search-v3?${filterString}&page=${currPage}&order_by=${orderBy}&order_direction=${orderDirection}`, {})
    } catch (error: any) {
      response = error?.response
    }
    if (response?.status < 400) {
      return action(actionTypes.APPLY_FILTERS, { applicants: response?.data, filters: params })
    } else {
      return action(actionTypes.ADD_APPLICANTS_ERROR, response?.data)
    }
  },
  fetchExportApplicants: async (params: IApplicantSearchParams) => {
    const filterString = _createFilterSearchString(params)
    let response: AxiosResponse
    try {
      response = await axios.post(`/api/hiring/v2/applicants/export?${filterString}&page=1`, {})
    } catch (error: any) {
      response = error?.response
    }
    if (response?.status < 400) {
      return action(actionTypes.ADD_EXPORT_APPLICANTS, response?.data)
    } else {
      return action(actionTypes.ADD_APPLICANTS_ERROR, response?.data)
    }
  },
  updateApplicantStatus: async (id: number, info: any) => {
    const status = { stage_id_enum: info }
    let response: AxiosResponse
    try {
      response = await axios.put(`/api/hiring/v2/applicants/${id}`, status)
    } catch (error: any) {
      response = error?.response
    }
    if (response?.status < 400) {
      return action(actionTypes.UPDATE_APPLICANT_STATUS, { ...status, id: id, stage_id_enum: status?.stage_id_enum })
    } else {
      return action(actionTypes.ADD_APPLICANTS_ERROR, response?.data)
    }
  },
  updateApplicantRating: async (id: number, info: any) => {
    const rating = { rating_id: info }
    let response: AxiosResponse
    try {
      response = await axios.put(`/api/hiring/v2/applicants/${id}`, rating)
    } catch (error: any) {
      response = error?.response
    }
    if (response?.status < 400) {
      return action(actionTypes.UPDATE_APPLICANT_RATING, { ...rating, id: id })
    } else {
      return action(actionTypes.ADD_APPLICANTS_ERROR, response?.data)
    }
  },
  fetchSingleApplicant: async (id: number) => {
    return await fetch({
      url: `/api/hiring/v2/applicants/${id}`,
      onSuccessDispatch: actionTypes.FETCH_SINGLE_APPLICANT,
      onErrorDispatch: actionTypes.ADD_APPLICANTS_ERROR
    })
  },
  fetchCurrentApplicantUserInformation: async (id: number) => {
    return await fetch({
      url: `/api/hiring/v2/users/${id}`,
      onSuccessDispatch: actionTypes.ADD_CURRENT_USER_INFORMATION,
      onErrorDispatch: actionTypes.ADD_APPLICANTS_ERROR
    })
  },
  fetchStudyGroups: async () => {
    return await fetch({
      url: `/api/hiring/v2/study-groups`,
      onSuccessDispatch: actionTypes.ADD_FIELDS_OF_STUDY_GROUPS,
      onErrorDispatch: actionTypes.ADD_APPLICANTS_ERROR
    })
  },
  fetchFieldsOfStudy: async () => {
    return await fetch({
      url: `/api/hiring/v2/fields-of-studies`,
      onSuccessDispatch: actionTypes.ADD_FIELDS_OF_STUDY,
      onErrorDispatch: actionTypes.ADD_APPLICANTS_ERROR
    })
  },
  fetchCandidateNotes: async (id: number, page?: number | null, orderBy: string | null = "created_at", orderDirection: string | null = "desc") => {
    let currPage = page
    if (!page) {
      currPage = 1
    }
    return await post({
      info: {},
      url: `/api/hiring/v2/users/${id}/comments?page=${currPage}&order_by=${orderBy}&order_direction=${orderDirection}`,
      onErrorDispatch: actionTypes.ADD_APPLICANTS_ERROR,
      onSuccessDispatch: actionTypes.ADD_CANDIDATE_NOTES
    })
  },
  removeSingleFilter: async (value: any, params: any, filterType: any, orderBy: string | null, orderDirection: string | null) => {
    let newParams = { ...params }
    newParams[filterType] = newParams[filterType].filter((item: any) => item !== value)
    return await applicantsActions.applyFilters(newParams, 1, orderBy, orderDirection)
  },
  removeKeywordFilter: async (params: any, orderBy: string | null, orderDirection: string | null) => {
    let newParams = {
      ...params,
      keyword: null
    }
    return await applicantsActions.applyFilters(newParams, 1, orderBy, orderDirection)
  },
  clearAllFilters: async (id: number, page?: number | null, orderBy: string | null = "created_at", orderDirection: string | null = "desc") => {
    let currPage = page
    if (!page) {
      currPage = 1
    }
    return await post({
      info: {},
      url: `/api/hiring/v2/applicants/search-v3?page=${currPage}&company=${id}&rating=0,1,2&stage_enum=${applicantActiveStages.join(",")}&order_by=${orderBy}&order_direction=${orderDirection}`,
      onErrorDispatch: actionTypes.ADD_APPLICANTS_ERROR,
      onSuccessDispatch: actionTypes.CLEAR_ALL_FILTERS
    })
  },
  createComment: async (id: any, info: any) => {
    return await create({
      info: info,
      url: `/api/hiring/v2/applicants/${id}/comments`,
      onErrorDispatch: actionTypes.ADD_APPLICANTS_ERROR,
      onSuccessDispatch: actionTypes.ADD_SINGLE_COMMENT
    })
  },
  editComment: async (id: any, info: any) => {
    return await update({
      info: info,
      url: `/api/hiring/v2/comments/${id}`,
      onErrorDispatch: actionTypes.ADD_APPLICANTS_ERROR,
      onSuccessDispatch: actionTypes.EDIT_COMMENT
    })
  },
  deleteComment: async (id: any) => {
    return await remove({
      id: id,
      url: `/api/hiring/v2/comments/${id}`,
      onErrorDispatch: actionTypes.ADD_APPLICANTS_ERROR,
      onSuccessDispatch: actionTypes.DELETE_COMMENT
    })
  },
  fetchCompanyFolders: async (id: number) => {
    return await fetch({
      url: `/api/hiring/v2/companies/${id}/folders`,
      onSuccessDispatch: actionTypes.ADD_COMPANY_FOLDERS,
      onErrorDispatch: actionTypes.ADD_APPLICANTS_ERROR
    })
  },
  filterPlacesByIds: async (ids?: string | null, page = 1, perPage = 24) => {
    return await fetch({
      url: `/api/places/filter-by-ids?id=${ids}&page=${page ?? 1}&per_page=${perPage ?? 12}`,
      onSuccessDispatch: actionTypes.ADD_FILTERED_PLACE,
      onErrorDispatch: actionTypes.ADD_APPLICANTS_ERROR
    })
  },
  optimisticUpdateApplicantStatus: (id: number, info: any) => {
    const status = { stage_id_enum: info }
    return action(actionTypes.OPTIMISTIC_UPDATE_APPLICANT_STATUS, { ...status, id: id })
  },
  updateApplicantRatingRealtime: (id: any, info: any) => {
    return action(actionTypes.UPDATE_APPLICANT_RATING_REALTIME, { id: id, ...info })
  },
  updateApplicantStageRealtime: (id: any, info: any) => {
    return action(actionTypes.UPDATE_APPLICANT_STATUS_REALTIME, { id: id, ...info })
  },
  addSingleApplicant: (info: any) => {
    return action(actionTypes.ADD_SINGLE_APPLICANT, info)
  },
  addSingleComment: (info: any) => {
    return action(actionTypes.ADD_SINGLE_COMMENT, info)
  },
  openSingleApplicant: (info: any) => {
    return action(actionTypes.OPEN_SINGLE_APPLICANT, info)
  },
  changeSortByValue: (value: any) => {
    return action(actionTypes.SORT_APPLICANTS_BY, value)
  },
  changeOrderByValues: (orderBy: string, orderDirection: string) => {
    return action(actionTypes.CHANGE_APPLICANTS_ORDER_INFORMATION, { orderBy, orderDirection })
  },
  changeSearchOpen: () => {
    return action(actionTypes.CHANGE_SEARCH_OPEN)
  },
  clearOpenApplicant: () => {
    return action(actionTypes.CLEAR_OPEN_APPLICANT)
  },
  clearApplicants: () => {
    return action(actionTypes.CLEAR_ALL_APPLICANTS)
  }
}
