import { makeStyles } from "@material-ui/core"

export default makeStyles(theme => ({
  root: {
    height: "100vh",
    width: "100vw"
  },
  logotypeContainer: {
    backgroundColor: theme.palette.primary.dark,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  logotypeImage: {
    width: 165
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 64
  }
}))
