import React from "react"
import { DropzoneDialog } from "material-ui-dropzone"
import { useTranslation } from "react-i18next"

interface Props {
  open: boolean
  limit: number
  dropzoneText: string
  acceptedFiles: string[]
  handleClose: () => void
  handleSubmitFiles: (files: any) => void
}

const FilesDropZone: React.FC<Props> = props => {
  const { t } = useTranslation()

  const dropzoneText = props.dropzoneText

  const handleSave = async (files: any) => {
    await props.handleSubmitFiles(files)
  }

  return (
    <div>
      <DropzoneDialog
        open={props.open}
        onSave={handleSave}
        showPreviews
        maxFileSize={2000000}
        filesLimit={props.limit}
        onClose={props.handleClose}
        cancelButtonText={t("cancel")}
        submitButtonText={t("submit")}
        dialogTitle={t("upload_file")}
        dropzoneText={dropzoneText}
        acceptedFiles={props.acceptedFiles}
      />
    </div>
  )
}

export default FilesDropZone
