import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import { makeStyles } from "@material-ui/core"

import Chip from "@material-ui/core/Chip"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import LinearProgress from "@material-ui/core/LinearProgress"

import Select from "react-select/creatable"

import { useTranslation } from "react-i18next"
import { ICompany, ICompanyBenefit } from "../../../../types/company"
import { Dispatch } from "redux"
import { IActionType } from "../../../../types"
import { companyActions } from "../../../../store/actions/company"
import { useHistory } from "react-router"
import Can from "../../../../utils/AccessControl/Can"
import AccessDeniedTooltip from "../../../AccessDeniedToolTip"

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#eaeaea"
    },
    transition: "0.5s"
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5)
    }
  }
}))

interface SingleBenefitProps {
  benefit: ICompanyBenefit
  editBenefit: (values: any, id: any) => void
}

interface Props {
  company: ICompany
  benefits: ICompanyBenefit[]
  // handleOpen: () => void;
  fetchBenefits: (id: any) => void
  // editFormField: (value: any) => void;
  editBenefit: (values: any, id: any) => void
}

const SingleBenefit: React.FC<SingleBenefitProps> = props => {
  const classes = useStyles()

  const benefit = props.benefit

  const handleCheck = (event: any) => {
    let newVal = 0
    props.editBenefit({ is_selected: newVal }, benefit.id)
  }

  return (
    <div>
      <Tooltip
        title={
          <Typography variant="body2" style={{ textAlign: "center" }}>
            {benefit.description_tr || benefit.description_en}
          </Typography>
        }
        placement="bottom"
      >
        <Chip label={benefit.name_tr} variant="outlined" onDelete={handleCheck} className={classes.chip} color="primary" />
      </Tooltip>
    </div>
  )
}

const Benefits: React.FC<Props> = props => {
  const [addBenefitOpen] = useState(false)

  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const company = props.company
  const fetchBenefits = props.fetchBenefits

  let benefits: any[] = []

  if (props.benefits) {
    benefits = props.benefits.filter(item => item.is_selected === 1)
  }

  const handleAddBenefitsChange = (value: any) => {
    let benefits = [...props.benefits]
    const duplicity = checkDuplicity(benefits, value)
    if (duplicity === false) {
      benefits.push(value)
    }
    // props.editFormField({'benefits': benefits});
  }

  const checkDuplicity = (arr: any[], val: any) => {
    let res = false
    arr.forEach((element: any) => {
      if (parseInt(element.id) === parseInt(val.id)) {
        res = true
      }
    })
    return res
  }

  const handleOpen = () => {
    history.push("/employer/profile/info/benefits")
  }

  useEffect(() => {
    if (company && !props.benefits) {
      fetchBenefits(company.id)
    }
  }, [company, props.benefits, fetchBenefits])

  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h6">{t("benefits")}</Typography>
      <div style={{ marginTop: "10px", marginBottom: "15px" }} className={classes.root}>
        {props.benefits ? (
          benefits.length > 0 ? (
            benefits.map(item => {
              return <SingleBenefit benefit={item} key={item.id} editBenefit={props.editBenefit}></SingleBenefit>
            })
          ) : (
            <Typography variant="body1" style={{ color: "#797979" }}>
              {t("no_benefits")}
            </Typography>
          )
        ) : (
          <div style={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </div>
        )}
      </div>
      {addBenefitOpen ? (
        <FormControl style={{ width: "100%" }}>
          <Select options={benefits} onChange={handleAddBenefitsChange} />
        </FormControl>
      ) : null}
      <Can I="create-benefits" a="all">
        <Button style={{ width: "100%", color: "#1D67B9" }} onClick={handleOpen}>
          {t("add_benefits")} {addBenefitOpen ? "-" : "+"}
        </Button>
      </Can>
      <Can not I="create-benefits" a="all">
        <AccessDeniedTooltip link={"/billing/subscriptions"} linkLabel={t("go_to_subscriptions")} body={t("billing_cannot_create-benefits")}>
          <Button disabled style={{ width: "100%" }}>
            {t("add_benefits")} {addBenefitOpen ? "-" : "+"}
          </Button>
        </AccessDeniedTooltip>
      </Can>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    benefits: state.companyReducer.benefits,
    company: state.companyReducer.company
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    // editFormField: ({value: }),
    editBenefit: async (information: any, benefitId: any) => dispatch(await companyActions.updateCompanyBenefit(information, benefitId)),
    fetchBenefits: async (companyId: any) => dispatch(await companyActions.fetchCompanyBenefits(companyId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Benefits)
