import React from "react"

const useSearchParams: any = () => {
  const [search, setSearch] = React.useState(window.location.search)
  const searchAsObject = Object.fromEntries(new URLSearchParams(search))

  return [searchAsObject, setSearch]
}

export default useSearchParams
