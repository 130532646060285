import React from "react"
import { connect } from "react-redux"

import Typography from "@material-ui/core/Typography"
import ItemIcon from "@material-ui/icons/Brightness1"
import CheckedItemIcon from "@material-ui/icons/CheckCircle"
import { useTranslation } from "react-i18next"
import { ICompany, ICompanyBenefit } from "../../../../types/company"
import CandidateAppealChecklist from "../../../CandidateAppealChecklist"

const checkedStyle = {
  color: "#59AB43",
  // color: 'secondary',
  display: "inline",
  verticalAlign: "middle"
}
const uncheckStyle = {
  color: "#bfbfbf",
  display: "inline",
  verticalAlign: "middle"
}

interface Props {
  company: ICompany
  benefits: ICompanyBenefit[] | null
}

const CandidateAppeal: React.FC<Props> = props => {
  const { t } = useTranslation()

  const checkBenefitsAdded = () => {
    if (props.benefits) {
      let i = 0
      while (i < props.benefits.length) {
        if (props.benefits[i].is_selected === 1) {
          return true
        }
        i += 1
      }
    }
    return false
  }

  const calculateAppealScore = () => {
    let score = 0
    const company: any = props.company
    const checkAdded = (key: any) => {
      if (company[key]) {
        score += 1
      }
    }

    checkAdded("logo")
    checkAdded("benefits")
    checkAdded("about")
    checkAdded("name")
    checkAdded("mission")
    checkAdded("photos")
    if (checkBenefitsAdded()) {
      score += 1
    }

    const percentage = (score / 6) * 100
    if (percentage < 25) {
      return [1, 0, 0, 0]
    } else if (percentage < 50) {
      return [1, 1, 0, 0]
    } else if (percentage < 75) {
      return [1, 1, 1, 0]
    } else {
      return [1, 1, 1, 1]
    }
  }

  return (
    <div>
      <Typography variant="h6" style={{ padding: "5px" }} gutterBottom>
        {t("candidate_appeal")}
      </Typography>
      <div style={{ marginBottom: "15px" }}>
        {calculateAppealScore().map(item => {
          if (item === 1) {
            return <CheckedItemIcon style={checkedStyle} key={Math.random()} />
          } else {
            return <ItemIcon style={uncheckStyle} key={Math.random()} />
          }
        })}
      </div>
      <CandidateAppealChecklist />
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company,
    benefits: state.companyReducer.benefits
  }
}

export default connect(mapStateToProps)(CandidateAppeal)
