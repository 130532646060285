import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

import store from "./store"
import { Provider } from "react-redux"

import i18n from "./i18n/index"
import { I18nextProvider } from "react-i18next"

import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import TagManager from "react-gtm-module"

Sentry.init({
  dsn: "https://199996c3ac4d423bafdf693d0d013b1a@o279177.ingest.sentry.io/5598716",
  integrations: [new Integrations.BrowserTracing()],
  environment: `${process.env.REACT_APP_CURRENT_ENV}`,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
})

const tagManagerArgs = {
  gtmId: "GTM-N9DN29V",
  events: {}
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
