import React from "react"

import moment from "moment"
import { useTranslation } from "react-i18next"
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer"
import { parseToHttps } from "../../../utils/urlMiddleware"
import { getPlaceNameRecursively } from "../../../utils/places"

interface Props {
  user: any
}

const Header: React.FC<Props> = ({ user }) => {
  const { t, i18n } = useTranslation()

  return (
    <View style={styles.container}>
      {user.avatar_url && (
        <Image source={{ uri: parseToHttps(user.avatar_url), method: "GET", headers: {}, body: "" }} style={{ height: "75px", width: "75px", borderRadius: "50%" }} />
      )}
      <Text style={styles.title}>{user.full_name}</Text>
      <View style={styles.informationContainer}>
        {user.email ? (
          <View style={styles.singleInformationContainer}>
            <Image source="/assets/mui-icons/mail-outline-white.png" style={styles.icon} />
            <Text style={styles.textBody2}>{user.email}</Text>
          </View>
        ) : null}
        {user.phone_number && typeof user.phone_number === "string" && user.phone_number.length > 0 ? (
          <View style={styles.singleInformationContainer}>
            <Image source="/assets/mui-icons/phone-outline-white.png" style={styles.icon} />
            <Text style={styles.textBody2}>{user.phone_number}</Text>
          </View>
        ) : null}
        {(user.city_id !== undefined && user.city_id !== null) || ( user.place !== null ) ? (
          <View style={styles.singleInformationContainer}>
            <Image source="/assets/mui-icons/location-outline-white.png" style={styles.icon} />
            <Text style={styles.textBody2}>
              {
                // t(`city_id_${user.city_id}`)
                user?.place?.name ? getPlaceNameRecursively(user?.place, "", i18n.language) : t(`city_id_${user?.city_id}`)
              }
            </Text>
          </View>
        ) : null}
        {user.birthdate ? (
          <View style={styles.singleInformationContainer}>
            <Image source="/assets/mui-icons/date-outline-white.png" style={styles.icon} />
            <Text style={styles.textBody2}>{user.birthdate !== null && user.birthdate !== undefined ? moment(user.birthdate).format("LL") : "-"}</Text>
          </View>
        ) : null}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#221133",
    color: "#FFFFFF",
    marginTop: "-30px",
    marginBottom: "30px",
    paddingBottom: "30px",
    paddingTop: "30px"
  },
  informationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    flexDirection: "row",
    marginTop: "8px"
  },
  singleInformationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px"
  },
  textBody2: {
    fontSize: "12px",
    fontWeight: 400
  },
  title: {
    fontSize: "32px",
    fontWeight: "bold"
  },
  icon: {
    width: "16px",
    // height: "16px",
    paddingRight: "2.5px"
  }
})

export default Header
