import React from "react"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { IActionType } from "../../../types"
import { useTranslation } from "react-i18next"

import CssBaseline from "@material-ui/core/CssBaseline"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

import useStyles from "./styles"

// import logo from "../../assets/images/logo.png"
import logo from "../../../assets/images/logo.png"
// import Signin from '../../components/Authentication/Signin';
import { authActions } from "../../../store/actions/authentication"
import { Redirect, useHistory } from "react-router"
import Signup from "../../../components/Authentication/Signup"
import Signin from "../../../components/Authentication/Signin"
import ForgotPassword from "../../../components/Authentication/ForgotPassword"
import JoinTeamSignup from "../../../components/Authentication/JoinTeamSignup"
// import WhatsappContact from "../../../components/WhatsappContact"

interface Props {
  isAuthenticated: boolean
  authenticate: () => void
}

const LoginRegister: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const onLoginSuccess = () => {
    props.authenticate()
  }

  const onSignupSuccess = () => {
    props.authenticate()
  }

  const onForgotPasswordSuccess = () => {}

  React.useEffect(() => {
    const getPageTitle = () => {
      switch (history.location.pathname) {
        case "/register":
          return t("register_title")
        case "/forgot-password":
          return t("forgot_password_title")
        case "/join-your-team":
          return t("join_your_team_page_title")
        default:
          return t("login_title")
      }
    }
    document.title = getPageTitle()
  }, [history.location.pathname, t])

  if (props.isAuthenticated === true) {
    const { from } = (history.location.state as any) || { from: { pathname: "/dashboard" } }
    return <Redirect to={from.pathname} />
  }

  const inPageRouting = () => {
    const path = history.location.pathname
    if (path === "/register") {
      return <Signup onSuccess={onSignupSuccess} />
    } else if (path === "/forgot-password") {
      return <ForgotPassword onSuccess={onForgotPasswordSuccess} />
    } else if (path === "/join-your-team") {
      return <JoinTeamSignup onSuccess={onSignupSuccess} />
    } else {
      return <Signin onSuccess={onLoginSuccess} />
    }
  }

  return (
    <div>
      <CssBaseline />
      <main>
        <Grid container className={classes.root}>
          <Grid item xs={false} sm={false} md={6} lg={8}>
            <div className={classes.logotypeContainer}>
              <img src={logo} alt="logo" className={classes.logotypeImage} />
              <Typography className={classes.logotypeText}>{t("for_employers")}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>{inPageRouting()}</div>
          </Grid>
        </Grid>
      </main>
      {/* <WhatsappContact /> */}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: state.authReducer.isAuthenticated
  }
}

const mapDisptachToProps = (dispatch: Dispatch<IActionType>) => ({
  authenticate: () => dispatch(authActions.authenticate())
})

export default connect(mapStateToProps, mapDisptachToProps)(LoginRegister)
