import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {},
  filtersRoot: {
    display: "flex",
    borderBottom: "1px solid #eaeaea",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between"
    }
  },
  parentFiltersDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "20px",
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end"
    }
  },
  filtersDiv: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  singleFilterDiv: {
    marginLeft: "2.5px",
    marginRight: "2.5px"
  },
  toggleButtonGroup: {
    marginTop: "5px",
    marginBottom: "5px",
    marginLeft: "5px"
  },
  toggleButton: {
    minWidth: "100px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      minWidth: "65px"
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "100px"
    }
  },
  searchButton: {
    marginLeft: "auto",
    marginTop: 8,
    marginRight: 8
  },
  toolTipIcon: {
    fontSize: "1.2rem",
    color: "#797979",
    marginLeft: "5px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.0rem"
    }
  },
  activeFilters: {
    display: "flex",
    alignItems: "center",
    marginLeft: "0px"
  }
}))

export default useStyles
