import { action } from "typesafe-actions"
import axios from "./iskibris"
import { AxiosResponse } from "axios"

export const fetch = async (params: { url: string; onSuccessDispatch: string; onErrorDispatch: string }) => {
  let response: AxiosResponse
  try {
    response = await axios.get(params.url)
  } catch (error: any) {
    response = error?.response
  }
  if (response?.status < 400) {
    return action(params.onSuccessDispatch, response?.data)
  } else {
    return action(params.onErrorDispatch, response?.data)
  }
}

export const remove = async (params: { id: any; url: string; onSuccessDispatch: string; onErrorDispatch: string }) => {
  let response: AxiosResponse
  try {
    response = await axios.delete(params.url)
  } catch (error: any) {
    response = error?.response
  }
  if (response?.status < 400) {
    return action(params.onSuccessDispatch, params.id)
  } else {
    return action(params.onErrorDispatch, response?.data)
  }
}

export const update = async (params: { info: any; url: string; onSuccessDispatch: string; onErrorDispatch: string }) => {
  let response: AxiosResponse
  try {
    response = await axios.put(params.url, params.info)
  } catch (error: any) {
    response = error?.response
  }
  if (response?.status < 400) {
    return action(params.onSuccessDispatch, response?.data)
  } else {
    return action(params.onErrorDispatch, response?.data)
  }
}

export const create = async (params: { info: any; url: string; onSuccessDispatch: string; onErrorDispatch: string }) => {
  let response: AxiosResponse
  try {
    response = await axios.post(params.url, params.info)
  } catch (error: any) {
    response = error?.response
  }
  if (response?.status < 400) {
    return action(params.onSuccessDispatch, response?.data)
  } else {
    return action(params.onErrorDispatch, response?.data)
  }
}

export const post = async (params: { info: any; url: string; onSuccessDispatch: string; onErrorDispatch: string }) => {
  let response: AxiosResponse
  try {
    response = await axios.post(params.url, params.info)
  } catch (error: any) {
    response = error?.response
  }
  if (response?.status < 400) {
    return action(params.onSuccessDispatch, response?.data)
  } else {
    return action(params.onErrorDispatch, response?.data)
  }
}
