import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    width: "100%"
    // minHeight: "450px"
  },
  paper: {
    padding: theme.spacing(2, 0)
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxWidth: "600px",
    width: "100%",
    outline: "none",
    position: "relative",
    maxHeight: "100%",
    overflow: "auto"
  },
  toolbar: {
    backgroundColor: theme.palette.primary.dark,
    color: "#fff"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none"
  },
  tableCell: {
    borderBottom: "none !important"
  },
  modalCloseContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 1rem"
  },

  modalTypoghraphyTitle: {
    display: "flex",
    alignItems: "center",
    margin: "0"
  },

  templateVarsContainer: {
    transition: "0.5s",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}))

export default useStyles
