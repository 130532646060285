import React from "react"
import axios from "../../../api/iskibris/iskibris"
import ReactFacebookLogin from "react-facebook-login"
import facebook from "../../../assets/images/facebook.png"
import useStyles from "../styles"
import { AxiosError, AxiosResponse } from "axios"
import Cookies from "js-cookie"
import { useTranslation } from "react-i18next"
import OverlayLoader from "../../OverlayLoader"

interface Props {
  mode: string
  onSuccess: () => void
  onFail: () => void
}

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID ?? ""

const FacebookAuth: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)

  const facebookLoginHandler = async (response: any) => {
    setLoading(true)
    let accessToken = { access_token: response.accessToken }
    await axios
      .post("/api/login/facebook", accessToken)
      .then(async (response: AxiosResponse) => {
        Cookies.set("auth_token", response.data["token"], {
          domain: process.env.REACT_APP_DOMAIN ?? ".iskibris.com",
          sameSite: "None",
          secure: true
        })
        setLoading(false)
        props.onSuccess()
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        props.onFail()
      })
  }

  return (
    <div style={{ justifyContent: "center", height: "86px", width: "100%" }}>
      <ReactFacebookLogin
        appId={facebookAppId}
        fields="email"
        cssClass={classes.facebookLoginButton}
        buttonStyle={{
          width: "100%",
          height: "50px",
          fontSize: "16px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          borderRadius: "5px",
          boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)",
          // fontWeight: "normal",
          textTransform: "none",
          border: "none"
        }}
        textButton={t(`${props.mode}_with_facebook`)}
        typeButton="contained"
        size="small"
        // isMobile={true}
        disableMobileRedirect={true}
        // autoLoad={false}
        callback={facebookLoginHandler}
        icon={<img alt="fb" src={facebook} width={30} height={30} style={{ marginRight: 5 }} />}
      />
      {loading ? (
        <OverlayLoader
          handleClose={() => {
            setLoading(false)
          }}
          open
        />
      ) : null}
    </div>
  )
}

export default FacebookAuth
