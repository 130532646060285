import React from "react"

import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { useTranslation } from "react-i18next"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import { Button, CircularProgress, Paper, makeStyles } from "@material-ui/core"

import { UpdatePasswordWidget } from "innovia-component-library"

import api from "../../api/iskibris"
import { AxiosError, AxiosResponse } from "axios"
import { flashActions } from "../../store/actions/flash-messaging"
import HTTPResponses from "../../tools/http.responses"
import DeleteConfirmation from "../DeleteConfirmation"
import Cookies from "js-cookie"
import { authActions } from "../../store/actions/authentication"
import { Link } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 2),
    marginBottom: "2rem"
  },
  
}))

interface Props {
}

const AccountSettingsManagement: React.FC<Props> = () => {
  const { t } = useTranslation()

  const [errors, setErrors] = React.useState({})
  const [loading, setLoading] = React.useState(false)

  const user = useSelector((state: any) => state.userReducer.profile)

  const history = useHistory()

  const classes = useStyles()

  const dispatch = useDispatch();

  React.useEffect(() => {
    document.title = t("account_settings_title")
  }, [history.location.pathname, t])

  const onSubmit = async (values: any) => {
    const info = { ...values }

    await api
      .post("/api/user/settings/password", info)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          dispatch(
            flashActions.addFlashMessage(t("password_reset_success"), "success")
          )
        }
      })
      .catch((error: AxiosError) => {
        if (!error?.response) {
          dispatch(
            flashActions.addFlashMessage(t("network_error"), "error")
          )
          return
        }

        const resErrors = error?.response?.data?.errors
        const keys = Object.keys(resErrors)
        keys.forEach((item: any) => {
          const itemErr = resErrors[item][0]
          setErrors({...errors, item: itemErr })
        })

        if (error?.response?.status === HTTPResponses.INTERNAL_SERVER_ERROR) {
          dispatch(
            flashActions.addFlashMessage(t("server_error_fail"), "error")
          )
          return
        } else {
          dispatch(
            flashActions.addFlashMessage(t("password_reset_fail"), "error")
          )
          return
        }
      })
  }


  const handleSubmit = async () => {
    setLoading(true)
    await api
      .delete(`/api/users/${user.id}`)
      .then((response: AxiosResponse) => {
        localStorage.removeItem("auth_token")
        localStorage.removeItem("refresh_token")
        localStorage.removeItem("current_company_id")
        Cookies.remove("auth_token", { domain: process.env.REACT_APP_DOMAIN ?? "iskibris.com" })
        dispatch(
          flashActions.addFlashMessage(t("password_change_success"), "success")
        )
        dispatch(authActions.unauthenticate())
      })
      .catch(err => {
        if (err?.response) {
          if (err?.response?.status === HTTPResponses.INTERNAL_SERVER_ERROR) {
            dispatch(
              flashActions.addFlashMessage(t("server_error_fail"), "error")
            )
          } else {
            dispatch(
              flashActions.addFlashMessage(t("account_delete_fail"), "error")
            )
          }
        } else {
          dispatch(
            flashActions.addFlashMessage(t("network_error"), "error")
          )
        }
      })
    setLoading(false)
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={12} lg={9}>
          <Paper className={classes.paper}>
            <Typography variant="h6" className="account-settings__title">{t("update_password")}</Typography>
            <Typography variant="body1" className="account-settings__body">{t("update_password_body")}</Typography>
            <UpdatePasswordWidget onSubmit={onSubmit} errors={errors} />
          </Paper>
          <Paper className={classes.paper}>
            <Typography variant="h6" className="account-settings__title">{t("delete_account")}</Typography>
            <Typography variant="body1" className="account-settings__body" gutterBottom>{t("delete_account_subtext")}</Typography>
            <Typography variant="body1" className="account-settings__subtext" gutterBottom>{t("delete_account_company_warning")} <Link to={"/employer/profile"} color="secondary" className="account-settings__link">{t("manage_companies")}</Link></Typography>
            <DeleteConfirmation
              onConfirm={handleSubmit}
              question={t("delete_account_question")}
              warning={t("delete_account_warning")}
            >
              <Button className="account-settings__delete-account-button" fullWidth>
                {loading ? (
                  <CircularProgress size={22} style={{ color: "#fff" }} />
                ) : (
                  t("delete_account")
                )}
              </Button>
            </DeleteConfirmation>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default AccountSettingsManagement
