import React from "react"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"

import Typography from "@material-ui/core/Typography"

import useStyles from "../../styles"
import useGtm from "../../../../../hooks/useGtm"
import { applicantsActions } from "../../../../../store/actions/applicants"

// import ApplicantSummary from "../../../ApplicantSummary"

import { IUser } from "../../../../../types/user"
import { IActionType } from "../../../../../types"
import { IApplicant } from "../../../../../types/applicants"
import ApplicantCard from "../../../ApplicantCard"

interface Props {
  profile: IUser
  singleApplicant: IApplicant
  clearOpenApplicant: () => void
  updateApplicationStatus: (id: any, status: any) => void
}

const OtherApplications: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const history = useHistory()

  const otherApplications = props.profile.user_applications

  const { tagEvent } = useGtm()
  const params: { id?: string } = useParams()

  const onClick = (applicant: any) => {
    // props.fetchCurrentUserInfo(applicant.user_id);
    if (applicant.id !== props.singleApplicant?.id && !!applicant.id) {
      props.clearOpenApplicant()
      // props.openSingleApplicant(applicant)
    }
    if (params.id !== applicant.id) {
      let url = `/applicants/${applicant.id}`
      if (applicant.company_id !== props.singleApplicant.company_id) {
        url = `${url}?company=${applicant.company_id}`
      }
      history.push(url)
    }
    if (!!applicant && applicant.stage_id_enum === "NEW" && applicant.applicant_id) {
      tagEvent({ event: "applicant_status_changed", data: { applicant_old_status: applicant?.stage_id_enum, applicant_new_status: 'RESUME_SCREENING' } })
      props.updateApplicationStatus(applicant.applicant_id, "RESUME_SCREENING")
    }
  }

  return (
    <div className={classes.applicantInfoCard}>
      <div className={classes.infoCardHeader}>
        <Typography variant="body1" style={{ fontWeight: "bold", marginBottom: "10px" }}>
          {t("other_applications")}
        </Typography>
      </div>
      {props.profile
        ? Object.keys(otherApplications).map(key => {
            const applicant = otherApplications[key]
            return (
              <ApplicantCard
                applicant={applicant}
                handleClick={onClick}
                key={key}
                // selected={applicant.id === props.singleApplication.id}
              />
            )
          })
        : null}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.applicantsReducer.currentUserInformation
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => ({
  // openSingleApplicant: (applicant: any) => dispatch(applicantsActions.openSingleApplicant(applicant)),
  clearOpenApplicant: () => dispatch(applicantsActions.clearOpenApplicant()),
  updateApplicationStatus: async (id: any, status: any) => dispatch(await applicantsActions.updateApplicantStatus(id, status))
})

export default connect(mapStateToProps, mapDispatchToProps)(OtherApplications)
