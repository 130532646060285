import React from "react"
import { useTranslation } from "react-i18next"

import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Typography from "@material-ui/core/Typography"

interface Props {
  variableMap: any
  variant?: "filled" | "outlined" | "standard"
  // handleClose: () => void;
  handleVariableClick: (value: any) => void
}

const MobileInputVariables: React.FC<Props> = props => {
  const { t } = useTranslation()

  const {
    variableMap,
    // handleClose,
    handleVariableClick
  } = props

  const handleChange = (value: any) => {
    handleVariableClick(`{{${value}}}`)
  }

  return (
    <React.Fragment>
      <InputLabel htmlFor="grouped-select" style={{ marginBottom: "5px" }}>{`{ } ${t("variables")} (${t("optional")})`}</InputLabel>
      <FormControl fullWidth style={{ marginBottom: "10px" }}>
        <Select
          fullWidth
          id={"grouped-select"}
          defaultValue=""
          margin="dense"
          variant={props.variant ? props.variant : "outlined"}
          value={""}
          label={`{ } ${t("variables")} (${t("optional")})`}
          placeholder={`{ } ${t("variables")} (${t("optional")})`}
          // onChange={handleChange}
        >
          {Object.keys(variableMap).map((item: any) => {
            return (
              <div key={Math.random()}>
                <Typography variant="body2" style={{ padding: "5px" }} gutterBottom>
                  {t(item)}
                </Typography>
                {variableMap[item].map((val: any) => {
                  return (
                    <MenuItem
                      value={val.value}
                      onClick={() => {
                        handleChange(val.value)
                      }}
                      key={Math.random()}
                    >
                      {t(val.value)}
                    </MenuItem>
                  )
                })}
              </div>
            )
          })}
        </Select>
      </FormControl>
    </React.Fragment>
  )
}

export default MobileInputVariables
