import { createContext, useReducer } from "react"
import { IJob } from "../../types/jobs"
import { jobsReducer } from "./JobsManagementReducer"

// Reference: https://dev.to/elisealcala/react-context-with-usereducer-and-typescript-4obm
interface Props {
  children: React.ReactNode
}

export interface ISlotsInfo {
  total_allocated_quantity: number
  total_remaining_quantity: number
  total_used_quantity: number
}

export type JobsState = {
  jobs: IJob[] | null
  fetched: boolean
}

export type ContextStateType = {
  state: any
  dispatch: any
}

const initialState: JobsState = {
  jobs: null,
  fetched: false
}

const JobsManagementContext = createContext<{
  state: JobsState
  dispatch: React.Dispatch<any>
}>({
  state: initialState,
  dispatch: () => {}
})

const JobsManagementContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(jobsReducer, initialState)

  return <JobsManagementContext.Provider value={{ state, dispatch }}>{children}</JobsManagementContext.Provider>
}

export { JobsManagementContext, JobsManagementContextProvider }
