import React from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

import useStyles from "../styles"
import LongMenu from "../../../LongMenu"
import { ICompanyLocation, ICompany } from "../../../../types/company"
import { companyActions } from "../../../../store/actions/company"
import LocationForm from "../LocationForm"

interface Props {
  company: ICompany
  location: ICompanyLocation
}

const SingleLocation: React.FC<Props> = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [updateStatus, setUpdateStatus] = React.useState(false)

  const classes = useStyles()

  const handleDelete = async () => {
    await dispatch(await companyActions.deleteCompanyLocation(props.location.id))
  }

  return (
    <div className={classes.companyListItem}>
      <ListItem style={{ padding: "0px" }}>
        <ListItemText
          primary={<Typography variant="h6">{props.location.name_tr || props.location.name_en}</Typography>}
          secondary={
            <React.Fragment>
              <Typography variant="body1" component={"span"} style={{ display: "block" }}>
                {props.location.address} - {props.location.address_code}
              </Typography>
              <Typography variant="body2" component={"span"} style={{ display: "block" }}>
                {props.location?.city_id !== null ? t(`city_id_${props.location?.city_id}`) : "-"}
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          <LongMenu
            options={[
              {
                title: t("edit"),
                handler: () => {
                  setUpdateStatus(true)
                }
              },
              { title: t("delete"), handler: handleDelete }
            ]}
          />
        </ListItemSecondaryAction>
      </ListItem>
      {
        <div>
          <Typography variant="body1">{props.location.directions}</Typography>
        </div>
      }
      {updateStatus ? (
        <React.Fragment>
          <Divider />
          <LocationForm
            handleEditCancel={() => {
              setUpdateStatus(false)
            }}
            location={props.location}
          />
        </React.Fragment>
      ) : null}
    </div>
  )
}

export default SingleLocation
