import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    zIndex: 2
  },
  paper: {
    position: "absolute",
    top: 36,
    width: "100px"
  },
  listContainer: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: "calc(100% - 105px)",
    overflowY: "auto",
    borderBottom: "1px solid: #eaeaea"
  },
  buttonArea: {
    padding: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  counterBadge: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "25px"
  }
}))

export default useStyles
