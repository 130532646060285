import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // display: 'flex',
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "10px"
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(10, 0, 2, 10)
    },
    gridContainer: {
      height: (props: any) => {
        if (props.searchOpen && props.checkFiltered(true) && props.filters.keyword) {
          return "calc(100% - 196px)"
        } else if (props.checkFiltered(true) && props.filters.keyword) {
          return "calc(100% - 145px)"
        } else if (props.searchOpen && props.checkFiltered(true)) {
          return "calc(100% - 153px)"
        } else if (props.searchOpen) {
          return "calc(100% - 101px)"
        } else if (props.filters.keyword) {
          return "calc(100% - 105px)"
        } else if (props.checkFiltered(true)) {
          return "calc(100% - 100px)"
        }
        return "calc(100% - 50px)"
      }
    },
    leftGridItem: {
      height: "100%",
      overflowY: "auto",
      [theme.breakpoints.up("md")]: {
        borderRight: "1px solid #eaeaea"
      }
    },
    rightGridItem: {
      height: "100%",
      overflowY: "auto",
      backgroundColor: "#eaeaea"
    },
    rightGridItemClosed: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    leftGridItemClosed: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    sortByContainer: {
      height: "50px",
      borderBottom: "1px solid #797979",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: "8px",
        paddingRight: "8px"
      }
    },
    rootLoader: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    noApplicantsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      textAlign: "center",
      flexDirection: "column"
    }
  })
)

export default useStyles
