import React from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { ICompany } from "../../types/company"
import clsx from "clsx"

import Drawer from "@material-ui/core/Drawer"

import useStyles from "./styles"
import { systemActions } from "../../store/actions/system"
import Content from "./Content"

interface Props {
  company: ICompany
}

const SideBar: React.FC<Props> = props => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const openDrawer = useSelector((state: any) => state.systemReducer.drawerOpen)

  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }

  const dispatch = useDispatch()

  const handleDrawerItemClick = () => {
    if (openDrawer) {
      dispatch(systemActions.closeDrawer())
    }
  }

  return (
    <Drawer
      elevation={28}
      variant="permanent"
      className={"sidebar"}
      classes={{
        paper:
          clsx({
            [classes.drawerOpen + " sidebar--open"]: open || openDrawer,
            [classes.drawerClose + " sidebar--closed"]: !open && !openDrawer
          }) + " sidebar__inner"
      }}
      open={open || openDrawer}
      onMouseOver={(event: any) => {
        if (event.target.dispatchEvent) {
          handleDrawerOpen()
        }
      }}
      onMouseLeave={event => {
        handleDrawerClose()
      }}
    >
      <Content company={props.company} handleItemClick={handleDrawerItemClick} />
    </Drawer>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company
  }
}

export default connect(mapStateToProps)(SideBar)
