import React from "react"
import { IApplicant } from "../../../../types/applicants"

import { DragDropContext, DropResult, ResponderProvided } from "@hello-pangea/dnd"
import Column from "./Column"

interface Props {
  items: any[]
  columns: any[]
  onColumnChangedCallback?: (applicationId: any, newStatus: string) => Promise<void>
  onItemClick?: (application: IApplicant) => void;
}

const Kanban: React.FC<Props> = (props) => {

  const handleDropEnd = (result: DropResult, provided: ResponderProvided) => {
    if(result.reason === "DROP"){
      const newFieldStatus = result.destination?.droppableId
      const applicationId = result.draggableId
      if(newFieldStatus && props.onColumnChangedCallback){
        props.onColumnChangedCallback(applicationId, newFieldStatus)
      }
    }
  }

  const getColumnItems = (columnName: any) => {
    return props.items.filter(item => item.stage_id_enum === columnName)
  }

  return (
    <DragDropContext onDragEnd={handleDropEnd}>
      <div style={{display: "flex", gap: "3px", padding: "3px", flexShrink: 0}}>
        {
          props.columns.map(item => {
            return (
              <Column
                name={item.name}
                title={item.label}
                key={item.name}
                columnsCount={props.columns.length}
                items={getColumnItems(item.name)}
                onItemClick={props.onItemClick}
              />
            )
          })
        }
      </div>
    </DragDropContext>
  )
}

export default Kanban