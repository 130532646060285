import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"

import Paper from "@material-ui/core/Paper"
import AddIcon from "@material-ui/icons/Add"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"

import AddNote from "../CandidateDetails/Notes/AddNote"

import useStyles from "../styles"
import { getCandidateNotes } from "../../../../api/iskibris/iskibris-methods"
import { useSelector } from "react-redux"
import { IPaginatedComments } from "../../../../types/applicants"
import SingleNote from "../CandidateDetails/Notes/SingleNote"
import { CircularProgress } from "@material-ui/core"

interface Props { readOnly?: boolean }

const CandidateNotes: React.FC<Props> = ({ readOnly }) => {

  const classes = useStyles()

  const { t } = useTranslation()

  const applicant = useSelector((state: any) => state.applicantsReducer.singleApplicant)
  const company = useSelector((state: any) => state.companyReducer.company)

  const [comments, setComments] = React.useState<IPaginatedComments | null>(null)
  const [addNoteOpen, setAddNoteOpen] = React.useState(false)

  const handleAddNoteClicked = () => {
    setAddNoteOpen(!addNoteOpen)
  }

  const fetchComments = (id: any, page = 1) => {
    getCandidateNotes(id, page)
      .then(response => {
        setComments({...response.data})
      })
      .catch(error => {
        console.log(">>>> Error: ", error)
      })
  }

  const handleOnSuccess = (note: any) => {
    fetchComments(applicant?.user_id)
  }

  useEffect(() => {
    getCandidateNotes(applicant?.user_id, 1)
      .then(response => {
        setComments(response.data)
      })
      .catch(error => {
        console.log(">>>> Error: ", error)
      })
  }, [applicant?.user_id])


  return (
    <Paper className={classes.notesSectionPaper}>
      <Divider />
      <div className={classes.notesSectionHeaderContainer}>
        <div className={classes.infoCardHeader}>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {t("notes")}
            <span style={{ color: "#a5a5a6" }}>({t("internal_only")})</span>
          </Typography>
          {readOnly ? null : (
            <IconButton onClick={handleAddNoteClicked} style={{ padding: 0 }}>
              <AddIcon />
            </IconButton>
          )}
        </div>
        <Typography variant="caption">
          {t("candidates_notes_subtitle")}
        </Typography>
      </div>
      <div className={classes.candidateNotesComponents}>
        {addNoteOpen ? (
          <AddNote
            onSuccess={handleOnSuccess}
            closeAddNotes={() => {
              setAddNoteOpen(false)
            }}
          />
        ) : null}
      </div>
      
      {
        comments
        ?
        (
          <div className={classes.candidateNotesComponents}>
            {
              comments && comments?.data && comments?.data?.map(comment => {
                return (
                  <SingleNote comment={comment} company={company} key={Math.random()} />
                )
              })
            }
          </div>
        )
        :
        (
          <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <CircularProgress />
          </div>
        )
      }
    </Paper>
  )
}

export default CandidateNotes
