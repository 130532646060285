import Tooltip from "@material-ui/core/Tooltip"
import { withStyles, Theme } from "@material-ui/core/styles"

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 320,
    transition: "0.5s",
    width: "100%",
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip)

export default CustomTooltip
