import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    marginTop: "60px"
    // maxWidth: "800px",
  },
  innerDiv: {
    maxWidth: "800px",
    width: "100%",
    border: "1px solid #eaeaea",
    borderRadius: "5px"
  },
  logotypeImage: {
    width: 165,
    maxHeight: 40,
    [theme.breakpoints.down("xs")]: {
      // margin: "auto",
      width: "100px !important"
    }
  },
  appBar: {
    position: "fixed",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
    minHeight: "50px !important",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0px !important"
    }
  },
  toolbarTitle: {
    marginLeft: "5px",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px"
    }
  },
  logoBar: {
    marginLeft: "5px",
    color: theme.palette.secondary.main,
    textDecoration: "none !important",
    display: "flex",
    alignItems: "flex-end",
    margin: theme.spacing(1, 1.5),
    [theme.breakpoints.down("sm")]: {
      alignContent: "center",
      margin: "auto",
      justifyContent: "center",
      flexGrow: 1
    }
  },
  left: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      width: "100%"
    }
  },
  small: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(3),
      height: theme.spacing(3)
    }
  }
}))

export default useStyles
