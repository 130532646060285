import React from "react"

import moment from "moment"
import { useTranslation } from "react-i18next"
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import Section from "./Section"
import { IApplicant } from "../../../types/applicants"

interface Props {
  applicant: IApplicant
}

const ApplicationEducationDetails: React.FC<Props> = ({ applicant }) => {
  const { t, i18n } = useTranslation()

  return (
    <React.Fragment>
      {applicant.education_details && applicant.education_details.length > 0 ? (
        <Section title={t("candidate_education_details")}>
          {applicant.education_details.map((education: any) => {
            const endDate = `${education?.end_year}-${education?.end_month}-01`
            return (
              <View style={styles.singleEduContainer} key={education.id} wrap>
                <View style={styles.datesDetailsContainer}>
                  <Text style={styles.dateText}>{moment(endDate).format("MMMM YYYY")}</Text>
                </View>
                <View style={styles.degreeDetailsContainer}>
                  <Text style={styles.primaryText}>
                    {t(`course_level_id_${education.degree_id}`)},{" "}
                    {i18n.language === "en" ? education.field_of_study_name_en || education.field_of_study_name_tr : education.field_of_study_name_tr}
                  </Text>
                  <Text style={styles.secondaryText}>{i18n.language === "en" ? education.school_name_en || education.school_name_tr : education.school_name_tr}</Text>
                  <Text style={styles.secondaryText}>{education.achievements}</Text>
                </View>
              </View>
            )
          })}
        </Section>
      ) : null}
    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  singleEduContainer: {
    display: "flex",
    padding: "5px 0px",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  },
  datesDetailsContainer: {
    flex: 2.5,
    padding: "2.5px 0px"
  },
  dateText: {
    fontSize: "11px"
  },
  degreeDetailsContainer: {
    flex: 5
  },
  primaryText: {
    fontWeight: 700,
    fontSize: "13px"
  },
  secondaryText: {
    fontSize: "11px"
  }
})

export default ApplicationEducationDetails
