import React, { Suspense } from "react"
import { Switch, useRouteMatch } from "react-router"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { JobsManagementContextProvider } from "../../context/JobsManagementContext/JobsManagementProvider"
import AuthProtectedRoute from "../../components/AuthProtectedRoute"
import { CircularProgress } from "@material-ui/core"
import { ICompany } from "../../types/company"
import Cookies from "js-cookie"

const Jobs = React.lazy(() => import("./Jobs"))
const JobEdit = React.lazy(() => import("./JobEdit"))
const CreateJobs = React.lazy(() => import("./JobCreate"))
const JobDuplicate = React.lazy(() => import("./JobDuplicate/JobDuplicate"))

const JobsManagement: React.FC = () => {
  let { path } = useRouteMatch()
  const isAuthenticated: boolean = useSelector((state: any) => state.authReducer.isAuthenticated)
  const company: ICompany = useSelector((state: any) => state.companyReducer.company)

  const { t } = useTranslation()

  React.useEffect(() => {
    document.title = t("jobs_management_title")
  }, [t])

  React.useEffect(() => {
    if (company && company?.token !== Cookies.get("CompanyToken")) {
      Cookies.set("CompanyToken", company.token, { secure: true, sameSite: "none" })
    }
  }, [company])

  const loader = () => (
    <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress />
    </div>
  )

  return (
    <JobsManagementContextProvider>
      <Suspense fallback={loader()}>
        <Switch>
          <AuthProtectedRoute path={`${path}/`} component={Jobs} isAuthenticated={isAuthenticated} exact />
          <AuthProtectedRoute path={`${path}/create`} component={CreateJobs} isAuthenticated={isAuthenticated} exact />
          <AuthProtectedRoute path={`${path}/:id/duplicate`} component={JobDuplicate} isAuthenticated={isAuthenticated} exact />
          <AuthProtectedRoute path={`${path}/:id/edit`} component={JobEdit} isAuthenticated={isAuthenticated} exact />
        </Switch>
      </Suspense>
    </JobsManagementContextProvider>
  )
}

export default JobsManagement
