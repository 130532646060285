import React from "react"

import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import CancelIcon from "@material-ui/icons/Close"

import useStyles from "./styles"
import { useTranslation } from "react-i18next"

// Icons
// import InterestedIcon from '@material-ui/icons/CheckCircle';
// import NotInterestedIcon from '@material-ui/icons/CancelRounded';
// import MaybeIcon from '@material-ui/icons/Help';
// import UnratedIcon from '@material-ui/icons/FiberManualRecord';
// import { useTranslation } from 'react-i18next';

// let tagInfoOptions = [
//   {value: 0, label: "", icon:<UnratedIcon style={{color: '#eaeaea'}} className={"icon icon--unrated"}/>},
//   {value: 1, label: "", icon:<InterestedIcon style={{color: '#10AA61'}} className={"icon icon--interested"}/>},
//   {value: 2, label: "", icon:<MaybeIcon style={{color: '#F6B500'}} className={"icon icon--maybe"}/>},
//   {value: 3, label: "", icon:<NotInterestedIcon style={{color: "#EB4133"}} className={"icon icon--not-interested"}/>},
// ];

interface Props {
  keywords: string
  onDelete: () => void
}

const SearchTag: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.typography}>
        {t("results_for")} {`"${props.keywords}"`}
      </Typography>
      <IconButton onClick={props.onDelete} className={classes.iconButton}>
        <CancelIcon />
      </IconButton>
    </div>
  )
}

export default SearchTag
