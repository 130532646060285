import React from "react"
import { Droppable } from "@hello-pangea/dnd";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";

import ColumnItem from "../ColumnItem";

interface Props {
  title: string;
  name: string;
  items: any[]
  columnsCount?: number;
  onItemClick?: (item: any) => void;
}

const Column: React.FC<Props> = ({title, name, items, columnsCount, onItemClick}) => {

  const theme = useTheme()
  const isXLargeScreen = useMediaQuery(theme.breakpoints?.up('xl'))

  return (
    <>
      <div
        style={{width: isXLargeScreen && columnsCount ? `calc(150% / ${columnsCount})` : "250px", background: "#f8f8f8", borderRadius: "5px", flexShrink: 0, display: "flex", flexDirection: "column"}}
      >
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "40px", borderBottom: "2px solid #fff", position: "sticky", top: "0", background: "#f8f8f8", zIndex: "1"}}>
          <Typography style={{textAlign: "center", textTransform: "capitalize", color: "#595959", fontWeight: "bold", fontSize: "13px"}}>{title}</Typography>
        </div>
        <div>
          <Droppable droppableId={name}>
            {
              (provided, snapshot) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div style={{minHeight: "500px"}}>
                      {
                        items.map((item, index) => {
                          return <ColumnItem item={item} key={item.id} index={index} onClick={onItemClick} />
                        })
                      }
                    </div>
                    {provided.placeholder}
                  </div>
                )
              }
            }
          </Droppable>
        </div>
      </div>
    </>
  )
}

export default Column