import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2, 2),
    marginTop: "20px"
  },
  overlay: {
    "&:hover": {
      backgroundColor: "#000000",
      opacity: "0.4!important"
    },
    transition: "0.5s"
  },
  subtitleText: {
    color: "#797979"
  },
  media: {
    height: 160,
    width: 160,
    border: "1px solid #B6BBC2",
    transition: "background-color .3s",
    [theme.breakpoints.down("sm")]: {
      height: 120,
      width: 120
    }
  },
  link: {
    textDecoration: "none"
  },
  photo: {
    width: "200px",
    height: "200px"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "5%",
    maxHeight: "90%",
    overflow: "auto"
  },
  imageOverlayContainer: {
    backgroundColor: "transparent",
    height: "100%",
    width: "100%",
    transition: "all .3s",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,.9)"
    }
  },
  imageOverlay: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all .3s"
  },
  imageIconButton: {
    height: 50,
    width: 50,
    margin: 2
  },
  addImageCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 180,
    height: 180,
    border: "1px solid #ccc",
    cursor: "pointer",
    transition: "background-color .3s",
    "&:hover": {
      backgroundColor: "#cccccc"
    }
  },
  imagePicker: {
    marginBottom: "1rem",
    marginTop: "1rem"
  }
}))

export default useStyles
