import React from "react"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"

import { IoHomeOutline, IoBuildOutline, IoPeopleOutline, IoSettingsOutline, IoBusinessOutline, IoBriefcaseOutline, IoChevronBackOutline } from "react-icons/io5"
import { MdOutlineManageAccounts } from "react-icons/md"
import { IoReceiptOutline } from "react-icons/io5"

import companyPlaceholder from "../../../assets/images/placeholder-company.png"
import { ICompany } from "../../../types/company"

import useStyles from "../styles"

interface Props {
  company: ICompany
  handleItemClick: () => void
}

const Content: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleDrawerItemClick = () => {
    props.handleItemClick()
  }

  const NavLinkWithForwardRef = React.forwardRef((props: any, ref: any) => <NavLink innerRef={ref} {...props} exact />)
  NavLinkWithForwardRef.displayName = "NavLinkWithForwardRef"

  return (
    <div className={classes.contentDiv}>
      <List disablePadding>
        {props.company ? (
          <a
            className="MuiListItem-root sidebar__link MuiListItem-gutters"
            href={`${process.env.REACT_APP_JOB_SEEKER_BASE_URL}/companies/${props.company.id}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleDrawerItemClick}
          >
            <ListItemIcon>
              <img src={props.company.logo || companyPlaceholder} alt="innovia digital" className={"sidebar__innovia-logo"} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" className={"sidebar__link-text"}>
                  {props.company.name}
                </Typography>
              }
            ></ListItemText>
          </a>
        ) : null}
        <Divider />
        <ListItem className={"sidebar__link"} onClick={handleDrawerItemClick} key="home" component={NavLinkWithForwardRef} to="/dashboard">
          <ListItemIcon className={"sidebar__link-icon"}>
            <IoHomeOutline size={24} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" className={"sidebar__link-text"}>
                {t("home")}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        <Divider />
        <ListItem className={"sidebar__link"} onClick={handleDrawerItemClick} key="jobs" component={NavLinkWithForwardRef} to="/jobs">
          <ListItemIcon className={"sidebar__link-icon"}>
            <IoBriefcaseOutline size={24} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" className={"sidebar__link-text"}>
                {t("jobs")}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        <Divider />
        <ListItem className={"sidebar__link"} onClick={handleDrawerItemClick} key="candidates" component={NavLinkWithForwardRef} to="/applicants">
          <ListItemIcon className={"sidebar__link-icon"}>
            <IoPeopleOutline size={24} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" className={"sidebar__link-text"}>
                {t("candidates")}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        <Divider />
        <ListItem className={"sidebar__link"} onClick={handleDrawerItemClick} key="employer_profile" component={NavLinkWithForwardRef} to="/employer/profile">
          <ListItemIcon className={"sidebar__link-icon"}>
            <IoBusinessOutline size={24} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" className={"sidebar__link-text"}>
                {t("employer_profile")}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        <Divider />
        <ListItem className={"sidebar__link"} onClick={handleDrawerItemClick} key="employer_tools" component={NavLinkWithForwardRef} to="/employer-tools">
          <ListItemIcon className={"sidebar__link-icon"}>
            <IoBuildOutline size={24} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" className={"sidebar__link-text"}>
                {t("employer_tools")}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        <Divider />
      </List>
      <List disablePadding>
        <Divider />
        <a
          className="MuiListItem-root sidebar__link MuiListItem-gutters"
          href={`${process.env.REACT_APP_JOB_SEEKER_BASE_URL}/companies/${props.company?.id}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleDrawerItemClick}
        >
          <ListItemIcon className={"sidebar__link-icon"}>
            <IoChevronBackOutline size={24} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" className={"sidebar__link-text"}>
                {t("view_as_job_seeker")}
              </Typography>
            }
          ></ListItemText>
        </a>
        <Divider />
        <ListItem className={"sidebar__link"} onClick={handleDrawerItemClick} key="billing" component={NavLinkWithForwardRef} to="/billing">
          <ListItemIcon className={"sidebar__link-icon"}>
            <IoReceiptOutline size={24} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" className={"sidebar__link-text"}>
                {t("billing")}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        <Divider />
        <ListItem className={"sidebar__link"} onClick={handleDrawerItemClick} key="account_settings" component={NavLinkWithForwardRef} to="/user/settings/account">
          <ListItemIcon className={"sidebar__link-icon"}>
            <MdOutlineManageAccounts size={24} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" className={"sidebar__link-text"}>
                {t("user_account_settings")}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        <Divider />
        <ListItem className={"sidebar__link"} onClick={handleDrawerItemClick} key="settings" component={NavLinkWithForwardRef} to="/settings">
          <ListItemIcon className={"sidebar__link-icon"}>
            <IoSettingsOutline size={24} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" className={"sidebar__link-text"}>
                {t("settings")}
              </Typography>
            }
          ></ListItemText>
        </ListItem>
        <Divider />
      </List>
    </div>
  )
}

export default Content
