import React from "react"

import Chip from "@material-ui/core/Chip"

import useStyles from "./styles"

interface Props {
  icon?: any
  field: any
  value: any
  label: string
  onDelete: (filterValue: any, filterType: any) => Promise<void>
}

const SingleTag: React.FC<Props> = props => {
  const classes = useStyles()

  return (
    <Chip
      color="primary"
      variant="default"
      icon={props.icon}
      key={props.value}
      label={props.label}
      className={classes.chip}
      onDelete={() => {
        props.onDelete(props.value, props.field)
      }}
    />
  )
}

export default SingleTag
