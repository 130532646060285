import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  docViewer: {
    width: "100%",
    // padding: "20px",
    overflow: "auto",
    maxHeight: "800px",
    minHeight: "800px"
  }
}))

export default useStyles
