import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    // bottom: 0,
    // position: "absolute",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none"
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    bottom: 0,
    position: "fixed",
    width: "100%",
    overflowY: "auto",
    outline: "none",
    borderRadius: "10px 10px 0px 0px",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      padding: "7px"
    }
  },
  showMessagingButton: {
    // paddingTop: "6px",
    // paddingBottom: "6px",
    // marginRight: "10px",
    // marginLeft: "10px"
  },
  sendMessageText: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  ratingStatusContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  }
}))

export default useStyles
