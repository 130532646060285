import React from "react"
import sort from "fast-sort"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import XLSX from "xlsx"

import { IApplicantFilters, IPaginatedApplicants } from "../../../types/applicants"

import { BsUpload } from "react-icons/bs"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"

import useStyles from "../styles"
import { connect } from "react-redux"
import { IFieldOfStudy, IUser } from "../../../types/user"
import OverlayLoader from "../../OverlayLoader"
import { IActionType } from "../../../types"
import { applicantsActions } from "../../../store/actions/applicants"
import { ICompany } from "../../../types/company"
import Can from "../../../utils/AccessControl/Can"
// import AccessDeniedTooltip from '../../AccessDeniedToolTip';
import useGtm from "../../../hooks/useGtm"
import AccessDeniedTooltip from "../../AccessDeniedToolTip"
import IKTooltip from "../../IKToolTip"

interface Props {
  company: ICompany
  currentUser: IUser
  filters: IApplicantFilters
  exports: IPaginatedApplicants
  applicants: IPaginatedApplicants
  fieldsOfStudy: IFieldOfStudy[]
  fetchExportApplicants: (filters: any) => void
}

const ExportApplicants: React.FC<Props> = props => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { tagEvent } = useGtm()
  const [loading, setLoading] = React.useState(false)

  const { filters, company, fetchExportApplicants } = props

  const getFileName = () => {
    return dayjs().format("DD_MM_YYYY_hh_mm_ss").toString() + "_iskibris_applicants_export"
  }

  React.useEffect(() => {
    fetchExportApplicants({
      ...filters,
      companyId: company.id
    })
  }, [filters, company, fetchExportApplicants])

  const handleClick = async () => {
    tagEvent({ event: "export_click", data: { company_name: company?.name, user_id: props.currentUser?.id, user_name: props.currentUser?.full_name } })
    setLoading(true)
    const keys = [
      "name",
      "email",
      "birthdate",
      "phone_number",
      "gender",
      "education_level_id",
      "school",
      "field_of_study",
      "work_permit_id",
      "military_status",
      "job_title",
      "place",
      "stage_id",
      "stage_id_enum",
      "rating_id",
      "created_at"
    ]

    const info = {
      data: [
        keys.map(item => t(`${item}_label`)),
        ...props.exports?.data.map((applicant: any) => {
          return keys.map((key: any) => {
            if (key === "stage_id" || key === "stage_id_enum" || key === "rating_id" || key === "work_permit_id" || key === "education_level_id") {
              if (applicant[key] !== null && applicant[key] !== undefined) {
                return t(`${key}_${applicant[key]}`)
              }
              return ""
            }
            if (key === "military_status" || key === "gender") {
              if (applicant[key] !== null && applicant[key] !== undefined) {
                return t(`${key}_id_${applicant[key]}`)
              }
              return ""
            }
            if (key === "school") {
              if (applicant["education_details"] && applicant["education_details"].length > 0) {
                let educationDetails = applicant["education_details"]
                educationDetails = sort(educationDetails ? [...educationDetails] : []).desc([(app: any) => app.end_year, (app: any) => app.end_month])
                const latestEdu = educationDetails[0] // First item;
                return latestEdu.school_name_tr
              }
              return ""
            }
            if (key === "field_of_study") {
              if (applicant["fields_of_study"] && applicant["fields_of_study"].length > 0 && props.fieldsOfStudy) {
                const firstFos = applicant["fields_of_study"][0]
                const fos = props.fieldsOfStudy.find(item => item.id === firstFos)
                if (fos) {
                  return i18n.language === "en" ? fos.name_en || fos.name_tr : fos.name_tr || fos.name_en
                }
                return ""
              }
              return ""
            }
            if (key === "birthdate") {
              if (applicant["birthdate"]) {
                return dayjs(applicant["birthdate"]).format("DD/MM/YYYY")
              }
              return ""
            }
            if (key === "place") {
              if (applicant["place"]) {
                if(applicant['place'].name){
                  return applicant['place'].name[`name_${i18n.language}`] ?? applicant['place'].title
                }
                return applicant['place'].title
              }
              return ""
            }
            if (key === "created_at") {
              if (applicant["created_at"]) {
                return dayjs.unix(applicant["created_at"]).format("DD/MM/YYYY")
                // return moment(applicant["created_at"]).format("dd/MM/yyyy")
              }
              return ""
            }
            return applicant[key]
          })
        })
      ]
    }
    const workSheet = XLSX.utils.aoa_to_sheet(info.data)
    const newWorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(newWorkBook, workSheet, "candidates")
    await XLSX.writeFile(newWorkBook, `${getFileName()}.xlsx`, { sheet: "Candidates", Props: { Author: props.currentUser?.full_name } })
    setLoading(false)
  }

  return (

    <div>
      <Can I="export-applicants" a="all">
        {
          props.exports
          ?
          (
            <div>
              <Button className={classes.textbutton} onClick={handleClick}>
                <BsUpload className={classes.icon} />
                <Typography className={classes.text}>{t("export_applicants")}</Typography>
              </Button>
              <IconButton className={classes.iconbutton} onClick={handleClick}>
                <BsUpload className={classes.icon} />
              </IconButton>
              {loading ? (
                <OverlayLoader
                  open
                  handleClose={() => {
                    setLoading(false)
                  }}
                />
              ) : null}
            </div>
          )
          :
          (
            <IKTooltip
              message={t("loading")}
            >
              <Button className={classes.textbutton} disabled>
                <BsUpload className={classes.icon} />
                <Typography className={classes.text}>{t("export_applicants")}</Typography>
              </Button>
              <IconButton className={classes.iconbutton} disabled>
                <BsUpload className={classes.icon} />
              </IconButton>
            </IKTooltip>
          )
        }
        
      </Can>
      <Can not I="export-applicants" a="all">
        <AccessDeniedTooltip link={"/billing/subscriptions"} linkLabel={t("go_to_subscriptions")} body={t("billing_cannot_export-applicants")}>
          <Button disabled onClick={handleClick} className={classes.textbutton}>
            <BsUpload className={classes.icon} />
            <Typography className={classes.text}>{t("export_applicants")}</Typography>
          </Button>
          <IconButton disabled onClick={handleClick} className={classes.iconbutton}>
            <BsUpload className={classes.icon} />
          </IconButton>
        </AccessDeniedTooltip>
      </Can>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company,
    exports: state.applicantsReducer.exports,
    fieldsOfStudy: state.applicantsReducer.fieldsOfStudy,
    applicants: state.applicantsReducer.applicants,
    filters: state.applicantsReducer.filters,
    currentUser: state.userReducer.profile
  }
}

const mapDispatchToProps = (dispatch: React.Dispatch<IActionType>) => ({
  fetchExportApplicants: async (filters: any) => dispatch(await applicantsActions.fetchExportApplicants(filters))
})

export default connect(mapStateToProps, mapDispatchToProps)(ExportApplicants)
