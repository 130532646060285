import { action } from "typesafe-actions"
import { actionTypes } from "./actionTypes"

export const impersonationActions = {
  changeImpersonationState: (state = true) => {
    return action(actionTypes.CHANGE_IMPERSONATION_STATE, {is_impersonating: state})
  },
  changeIsImpersonationTokenSet: (state = true) => {
    return action(actionTypes.CHANGE_IMPERSONATION_TOKEN_SET_STATE, {is_impersonation_token_set: state})
  },
  changeIsImpersonatingUserId: (userId: any) => {
    return action(actionTypes.CHANGE_IMPERSONATION_USER_ID, {impersonation_user_id: userId})
  },
  addImpersonator: (data: any) => {
    return action(actionTypes.ADD_IMPERSONATOR, data)
  },
  addImpersonationData: (data: any) => {
    return action(actionTypes.ADD_IMPERSONATION_DATA, data)
  },
  clearImpersonationData: () => {
    return action(actionTypes.CLEAR_IMPERSONATION_DATA, null)
  },
}
