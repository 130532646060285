import React from "react"
import Cookies from "js-cookie"
import { Dispatch } from "redux"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector, connect } from "react-redux"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"

import { IActionType } from "../../../types/"
import { companyActions } from "../../../store/actions/company"
import ActionButtons from "../ActionButtons"

import axios from "../../../api/iskibris/iskibris"
import BasicInfo from "./BasicInfo"
import SocialMedia from "./SocialMedia"
import CompanyLeader from "./CompanyLeader"
import NotificationSnackbar from "../../NotificationSnackbar"

import useStyles from "./styles"
import CandidateAppeal from "./CandidateAppeal"
import BenefitsSection from "./BenefitsSection"

interface Props {
  type: string
  selectSector: (value: any) => void
  editFormFields: (value: any) => void
  fetchUserManagedCompanies: () => void
}

const CompanyInfomationForm: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const state = useSelector((state: any) => state.companyReducer)

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors }
  } = useForm<any>({
    defaultValues: {
      ...state.form,
      sector_id: state.form?.sector_id?.id,
      industry_id: state.form?.industry_id?.id,
      headquarter_place_id: state.form?.headquarter_place_id,
    }
  })
  const [loading, setLoading] = React.useState(false)
  const [result, setResult] = React.useState("")
  const [deleteResult, setDeleteResult] = React.useState("")

  const onSubmit = async (fields: any, e: any) => {
    e.preventDefault()
    setLoading(true)


    let placeId = state.form?.headquarter_place_id

    if(state.form?.headquarter_place_id && typeof state.form?.headquarter_place_id !== "number"){
      placeId = state.form?.headquarter_place_id?.id
    }

    const data = {
      ...state.form,
      sector_id: state.form?.sector_id?.id,
      industry_id: state.form?.industry_id?.id,
      headquarter_place_id: placeId,
    }

    await axios
      .put(`/api/hiring/v2/companies/${state.company?.id}`, data)
      .then(response => {
        if (response.status === 200) {
          setLoading(false)
          setResult("success")
        }
      })
      .catch(error => {
        setLoading(false)
        setResult("fail")
        const errors = error?.response?.data?.errors
        if (errors) {
          const keys = Object.keys(errors)
          keys.map(item => {
            setError(item, { message: errors[item][0], type: "validation" })
            return item
          })
        }
      })
  }

  const onDelete = async (id: any) => {
    setLoading(true)

    await axios
      .delete(`/api/hiring/v2/companies/${state.company?.id}`)
      .then(response => {
        localStorage.removeItem("current_company_id")
        Cookies.remove("CompanyToken", { secure: true, sameSite: "none" })
        props.fetchUserManagedCompanies()
      })
      .catch(error => {
        setLoading(false)
        setDeleteResult("fail")
        const errors = error?.response?.data?.errors
        if (errors) {
          const keys = Object.keys(errors)
          keys.map(item => {
            setError(item, { message: errors[item][0], type: "validation" })
            return item
          })
        }
      })
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item sm={12} md={8} lg={7}>
            <Paper className={classes.paper}>
              <div style={{}}>
                <BasicInfo formFields={state.form} editFormFields={props.editFormFields} selectSector={props.selectSector} errors={errors} control={control} register={register} />
              </div>
            </Paper>
            <Paper className={classes.paper} style={{ marginTop: "20px" }}>
              <div style={{}}>
                <CompanyLeader formFields={state.form} editFormFields={props.editFormFields} errors={errors} register={register} />
              </div>
            </Paper>
            <Paper className={classes.paper} style={{ marginTop: "20px" }}>
              <div style={{}}>
                <BenefitsSection />
              </div>
            </Paper>
            <Paper className={classes.paper} style={{ marginTop: "20px" }}>
              <div style={{}}>
                <SocialMedia formFields={state.form} editFormFields={props.editFormFields} register={register} />
              </div>
            </Paper>
            {/* <div style={{ marginBottom: "20px",  }}>
              <Typography variant="h6">{t("company_story")}</Typography>
              <CompanyStory
                formFields={state.form}
                editFormFields={props.editFormFields}
                errors={errors}
                control={control}
              />
            </div> */}
            <Paper className={classes.paper} style={{ marginTop: "20px" }}>
              <div style={{}}>
                <ActionButtons company={state.company} deleteCompany={onDelete} loading={loading} />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={5}>
            <Paper className={classes.sidepaper}>
              <div style={{}}>
                <CandidateAppeal />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </form>
      <NotificationSnackbar
        status={result}
        success_msg={t(`company_${props.type}_success`)}
        error_msg={t(`company_${props.type}_fail`)}
        handleClose={() => {
          setResult("")
        }}
      />
      <NotificationSnackbar
        status={deleteResult}
        success_msg={t(`company_delete_success`)}
        error_msg={t(`company_delete_fail`)}
        handleClose={() => {
          setDeleteResult("")
        }}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    editFormFields: (value: any) => dispatch(companyActions.editFormContent(value)),
    selectSector: (value: any) => dispatch(companyActions.selectSector(value)),
    fetchUserManagedCompanies: async () => dispatch(await companyActions.fetchUserManagedCompanies())
  }
}

export default connect(null, mapDispatchToProps)(CompanyInfomationForm)
