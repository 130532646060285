import { actionTypes } from "../actions/actionTypes"
import { IActionType } from "../../types"
import Cookies from "js-cookie"
import { ICompanyReducerModel } from "../../types/reducers"

const initialState: ICompanyReducerModel = {
  companyUserInvitations: null,
  searchResults: null,
  companyUsers: null,
  industries: null,
  companies: null,
  locations: null,
  benefits: null,
  sectors: null,
  company: null,
  updates: null,
  photos: null,
  faqs: null,
  errors: [],
  form: {
    name: "",
    phone: "",
    email: "",
    website: "",
    year_of_foundation: "",
    company_size_id: "",
    registration_number: "",
    address: "",
    headquarter_city_id: "",
    headquarter_place_id: "",
    working_hours_id: "",
    industry_id: "",
    sector_id: "",
    about: "",
    mission: "",
    directors_json: {
      title: "",
      name: ""
    },
    social_links: {
      facebook: "",
      twitter: "",
      linkedin: "",
      instagram: "",
      youtube: ""
    }
  }
}

const reducer = (state: ICompanyReducerModel = initialState, action: IActionType) => {
  switch (action.type) {
    case actionTypes.ADD_USER_COMPANIES: {
      const companies = [...action.payload]
      let singleCompany = null
      if (companies.length > 0) {
        const currCompanyId = localStorage.getItem("current_company_id")
        if (!currCompanyId) {
          singleCompany = companies[0]
        } else {
          let currCom = companies.find(item => {
            const itemId = parseInt(item.id)
            const comId = parseInt(currCompanyId)
            return itemId === comId
          })
          if (currCom) {
            singleCompany = currCom
          } else {
            singleCompany = companies[0]
          }
        }
        localStorage.setItem("current_company_id", singleCompany.id)
        Cookies.set("CompanyToken", singleCompany.token, { secure: true, sameSite: "none" })
      }
      return {
        ...state,
        companies: companies,
        company: singleCompany
      }
    }
    case actionTypes.COMPANY_CREATED: {
      const company = { ...action.payload }
      localStorage.setItem("current_company_id", company.id)
      Cookies.set("CompanyToken", company.token, { secure: true, sameSite: "none" })
      return {
        ...initialState
      }
    }
    case actionTypes.ADD_SINGLE_COMPANY_INFORMATION: {
      return {
        ...state,
        company: { ...action.payload }
      }
    }
    case actionTypes.ADD_COMPANY_SEARCH_RESULTS: {
      const newSearchRes = [...action.payload]
      return {
        ...state,
        searchResults: newSearchRes
      }
    }
    case actionTypes.ADD_COMPANY_USERS: {
      const companyUsers = action.payload ? [...action.payload] : action.payload
      return {
        ...state,
        companyUsers: companyUsers
      }
    }
    case actionTypes.DELETE_COMPANY_USER: {
      const id = action.payload
      let newCompanyUsers = state.companyUsers
      if (newCompanyUsers) {
        newCompanyUsers = newCompanyUsers.filter(item => item.id !== id)
      }
      return {
        ...state,
        companyUsers: newCompanyUsers
      }
    }
    case actionTypes.ADD_COMPANY_USER_INVITATIONS: {
      const companyUserInvitations = action.payload ? [...action.payload] : action.payload
      return {
        ...state,
        companyUserInvitations: companyUserInvitations
      }
    }
    case actionTypes.REVOKE_COMPANY_USER_INVITATION: {
      const id = action.payload
      let newCompanyUserInvitations = state.companyUserInvitations
      if (newCompanyUserInvitations) {
        newCompanyUserInvitations = newCompanyUserInvitations.filter(item => item.id !== id)
      }

      return {
        ...state,
        companyUserInvitations: newCompanyUserInvitations
      }
    }
    case actionTypes.ADD_SECTORS: {
      const sectors = [...action.payload]
      return {
        ...state,
        sectors: sectors
      }
    }
    case actionTypes.ADD_SECTOR_INDUSTRIES: {
      const industries = [...action.payload]
      return {
        ...state,
        industries: industries
      }
    }
    case actionTypes.ADD_ALL_INDUSTRIES: {
      const industries = [...action.payload]
      return {
        ...state,
        industries: industries,
        allIndustries: industries
      }
    }
    case actionTypes.EDIT_COMPANY_FORM_CONTENT: {
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload
        }
      }
    }
    case actionTypes.SELECT_SECTOR: {
      const sector = { ...action.payload }
      const industries = state.allIndustries ? [...state.allIndustries].filter(item => item.sector_id === sector.id) : state.allIndustries
      return {
        ...state,
        industries: industries,
        form: {
          ...state.form,
          industry_id: initialState?.form?.industry_id,
          sector_id: sector
        }
      }
    }
    case actionTypes.CLEAR_COMPANY_FORM: {
      return {
        ...state,
        form: { ...initialState.form }
      }
    }
    case actionTypes.ADD_COMPANY_BENEFITS: {
      return {
        ...state,
        benefits: [...action.payload]
      }
    }
    case actionTypes.EDIT_COMPANY_BENEFIT: {
      const newComBenefits = state.benefits ? [...state.benefits] : state.benefits
      if (newComBenefits) {
        const ndx = newComBenefits.findIndex(item => item.id === action.payload.id)
        newComBenefits[ndx] = { ...action.payload }
      }
      return {
        ...state,
        benefits: newComBenefits
      }
    }
    case actionTypes.DELETE_COMPANY_LOGO: {
      return {
        ...state,
        company: {
          ...state.company,
          logo: null
        }
      }
    }
    case actionTypes.UPDATE_COMPANY_LOGO: {
      return {
        ...state,
        company: {
          ...state.company,
          logo: action.payload
        }
      }
    }
    case actionTypes.DELETE_COMPANY_COVER_PHOTO: {
      return {
        ...state,
        company: {
          ...state.company,
          cover_photo_url: null
        }
      }
    }
    case actionTypes.UPDATE_COMPANY_COVER_PHOTO: {
      return {
        ...state,
        company: {
          ...state.company,
          cover_photo_url: action.payload
        }
      }
    }
    case actionTypes.ADD_COMPANY_PHOTOS: {
      return {
        ...state,
        photos: [...action.payload]
      }
    }
    case actionTypes.ADD_COMPANY_UPDATES: {
      return {
        ...state,
        updates: [...action.payload]
      }
    }
    case actionTypes.ADD_SINGE_COMPANY_UPDATE: {
      const singleUpdate = { ...action.payload }
      let newUpdates: any = []
      if (state.updates) {
        newUpdates = [...state.updates]
      }
      newUpdates.unshift(singleUpdate)
      return {
        ...state,
        updates: newUpdates
      }
    }
    case actionTypes.EDIT_COMPANY_UPDATE: {
      const newComUpdates = state.updates ? [...state.updates] : state.updates
      if (newComUpdates) {
        const ndx = newComUpdates.findIndex(item => item.id === action.payload.id)
        newComUpdates[ndx] = { ...action.payload }
      }
      return {
        ...state,
        updates: newComUpdates
      }
    }
    case actionTypes.DELETE_COMPANY_UPDATE: {
      let oldUpdates = state.updates ? [...state.updates] : []
      let newUpdates = oldUpdates.filter(item => item.id !== action.payload)
      return {
        ...state,
        updates: newUpdates
      }
    }
    case actionTypes.ADD_COMPANY_LOCATIONS: {
      return {
        ...state,
        locations: [...action.payload]
      }
    }
    case actionTypes.ADD_SINGE_COMPANY_LOCATION: {
      const singleLocation = { ...action.payload }
      let newLocation: any = []
      if (state.locations) {
        newLocation = [...state.locations]
      }
      newLocation.unshift(singleLocation)
      return {
        ...state,
        locations: newLocation
      }
    }
    case actionTypes.EDIT_COMPANY_LOCATION: {
      const newComLocations = state.locations ? [...state.locations] : state.locations
      if (newComLocations) {
        const ndx = newComLocations.findIndex(item => item.id === action.payload.id)
        newComLocations[ndx] = { ...action.payload }
      }
      return {
        ...state,
        locations: newComLocations
      }
    }
    case actionTypes.DELETE_COMPANY_LOCATION: {
      let oldLocations = state.locations ? [...state.locations] : []
      let newLocations = oldLocations.filter(item => item.id !== action.payload)
      return {
        ...state,
        locations: newLocations
      }
    }
    case actionTypes.ADD_COMPANY_FAQS: {
      return {
        ...state,
        faqs: [...action.payload]
      }
    }
    case actionTypes.ADD_SINGE_COMPANY_FAQ: {
      const singleFaq = { ...action.payload }
      let newFaq: any = []
      if (state.faqs) {
        newFaq = [...state.faqs]
      }
      newFaq.unshift(singleFaq)
      return {
        ...state,
        faqs: newFaq
      }
    }
    case actionTypes.EDIT_COMPANY_FAQ: {
      const newComFaqs = state.faqs ? [...state.faqs] : state.faqs
      if (newComFaqs) {
        const ndx = newComFaqs.findIndex(item => item.id === action.payload.id)
        newComFaqs[ndx] = { ...action.payload }
      }
      return {
        ...state,
        faqs: newComFaqs
      }
    }
    case actionTypes.DELETE_COMPANY_FAQ: {
      let oldFaq = state.faqs ? [...state.faqs] : []
      let newFaq = oldFaq.filter(item => item.id !== action.payload)
      return {
        ...state,
        faqs: newFaq
      }
    }
    default:
      break
  }
  return state
}

export default reducer
