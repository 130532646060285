import React from "react"
import { useTranslation } from "react-i18next"

import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { ICompany, ICompanyLocation } from "../../../types/company"
import useStyles from "./styles"
import { Dispatch } from "redux"
import { IActionType } from "../../../types"
import { companyActions } from "../../../store/actions/company"
import { connect } from "react-redux"
import { Grid } from "@material-ui/core"
import SingleLocation from "./SingleLocation"
import LocationForm from "./LocationForm"

interface Props {
  company: ICompany
  locations: ICompanyLocation[]
  fetchLocations: (id: any) => void
}

const CompanyLocations: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { company, locations, fetchLocations } = props

  React.useEffect(() => {
    if (company && !locations) {
      fetchLocations(company.id)
    }
  }, [company, locations, fetchLocations])

  return (
    <div>
      <Grid container>
        <Grid item sm={12} md={9} lg={7}>
          <Paper className={classes.paper}>
            <div>
              <Typography variant="h6" gutterBottom>
                {t("add_company_location")}
              </Typography>
              <Typography variant="body2" gutterBottom className={classes.subtitleText}>
                {t("add_company_location_text")}
              </Typography>
              <LocationForm />
            </div>
          </Paper>
          {locations
            ? locations.map(item => {
                return (
                  <Paper key={item.id} className={classes.paper} style={{ marginTop: "20px" }}>
                    <div>
                      <SingleLocation location={item} company={company} />
                    </div>
                  </Paper>
                )
              })
            : null}
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company,
    locations: state.companyReducer.locations
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchLocations: async (companyId: any) => dispatch(await companyActions.fetchCompanyLocations(companyId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyLocations)
