import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  scrollableDiv: {
    height: "calc(100% - 50px)",
    overflowY: "auto"
  }
}))

export default useStyles
