import React from "react"
import { FormInputText } from "../../FormInputText";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Typography } from "@material-ui/core";
import * as yup from "yup"
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DropzoneArea } from "material-ui-dropzone";
import { IJob } from "../../../types/jobs";
import { useDispatch, useSelector } from "react-redux";
import { createManualApplication } from "../../../api/iskibris/iskibris-methods";
import { flashActions } from "../../../store/actions/flash-messaging";
import HTTPResponses from "../../../tools/http.responses";
import { applicantsActions } from "../../../store/actions/applicants";
import { Link } from "react-router-dom";

interface Props {
  onSuccess?: () => void;
}

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().optional(),
    phone: yup.string().required(),
    job_id: yup.array().required(),
    filename: yup.mixed().required()
  })
  .required()

type Inputs = {
  name: string;
  phone: string;
  email?: string;
  job_id: number[];
  filename: any;
}

const ManualApplicationForm: React.FC<Props> = (props) => {

  const {t} = useTranslation()
  const [jobIds, setJobIds] = React.useState<number[]>([])
  // const [filename, setFilename] = React.useState<any>(null)
  const [loading, setLoading] = React.useState(false)

  const dispatch = useDispatch()

  const jobs: IJob[] | null = useSelector((state: any) => state.jobsReducer.jobs)
  const company = useSelector((state: any) => state.companyReducer.company)
  const activeJobs = jobs ? jobs?.filter((item) => item.active) : null

  const {
    handleSubmit,
    setValue,
    setError,
    control,
    resetField,
    formState: { errors }
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  })


  const submitDataToServer = async (data: any) => {
    setLoading(true)

    const formData = new FormData()
    formData.append("job_id", [data?.job_id].join(","))
    formData.append("name", data?.name)
    formData.append("phone", data?.phone)
    if(data.email){
      formData.append("email", data?.email)
    }

    formData.append("filename", new File(data?.filename, "manual_file.pdf"))

    await createManualApplication(formData)
      .then(async (response) => {
        setTimeout(async () => {
          dispatch(await applicantsActions.clearAllFilters(company?.id))
          dispatch(
            flashActions.addFlashMessage(t("add_manual_application_success"), "success")
          )
          if(props.onSuccess){
            props.onSuccess()
          }
          setLoading(false)
        }, 3000);
      })
      .catch((error: any) => {
        if (!error?.response) {
          dispatch(
            flashActions.addFlashMessage(t("network_error"), "error")
          )
          return
        }

        setLoading(false)
        
        const resErrors = error?.response?.data?.errors
        const keys = Object.keys(resErrors)
        keys.forEach((item: any) => {
          const itemErr = resErrors[item][0]
          setError(item, { message: itemErr })
        })

        if (error?.response?.status === HTTPResponses.INTERNAL_SERVER_ERROR) {
          dispatch(
            flashActions.addFlashMessage(t("server_error_fail"), "error")
          )
        } else {
          dispatch(
            flashActions.addFlashMessage(t("add_manual_application_fail"), "error")
          )
          return
        }

      })
  }

  const handleDrop = (data: any) => {
    setValue('filename', data)
  }

  const handleFileDelete = (data: any) => {
    resetField("filename")
  }

  const handleJobChange = (data: any) => (event: any, checked: any) => {
    let newIds = []
    if(checked){
      newIds = [...jobIds, data]
    } else {
      newIds = jobIds.filter(item => item !== data)
    }
    setJobIds(newIds)
    setValue("job_id", newIds)
  }

  return (
    <div style={{width: "100%", padding: "0rem 1rem 1rem 1rem"}}>
      <form onSubmit={handleSubmit(submitDataToServer)}>
        <Grid container>
          <Grid xs={12} sm={6} md={6} style={{paddingRight: "0.5rem"}}>
            <FormControl fullWidth>
              <FormInputText name="name" control={control} label={t("full_name")} errors={errors} />
            </FormControl>
            <FormControl fullWidth>
              <FormInputText name="email" control={control} label={t("email")} errors={errors} />
            </FormControl>
            <FormControl fullWidth>
              <FormInputText name="phone" control={control} label={t("phone_number")} errors={errors} />
            </FormControl>
            <div style={{
              marginTop: "0.5rem",
              borderRadius: "4px",
              padding: "1rem",
              border: "1px solid #c2c2c2"
            }}>
              <FormControl error={!!errors?.job_id} component="fieldset" style={{maxHeight: "250px", overflow: "auto"}}>
                <FormLabel component="legend" style={{padding: "0.25"}}>
                  <Typography variant="h6">{t("jobs")}</Typography>
                  <Typography variant="caption">{t("select_jobs_for_manual_applicants")}</Typography>
                </FormLabel>
                <FormGroup>
                  {
                    activeJobs ? (
                      activeJobs.length > 0 ? (
                        activeJobs?.map(item => (
                          <FormControlLabel
                            control={<Checkbox checked={[...jobIds].includes(item.id)} onChange={handleJobChange(item.id)} name="job_id" />}
                            label={item.title}
                            key={Math.random()}
                          />
                        ))
                      ) : (
                        <div>
                          <Typography variant="body2">{t("no_jobs_to_assign_applications")} <Link to={'/jobs'} style={{textDecoration: "none", color: "#EA148D"}}>{t('create_new_job')}</Link></Typography>
                        </div>
                      )
                    ) : (
                      <div><CircularProgress /></div>
                    )
                  }
                </FormGroup>
                {
                  errors?.job_id && (
                    <FormHelperText>{errors?.job_id?.message?.toString()}</FormHelperText>
                  )
                }
              </FormControl>
            </div>
          </Grid>
          <Grid xs={12} sm={6} md={6} style={{padding: "0.5rem"}}>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%"
            }}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name={"filename"}
                  rules={{required: {value: true, message: t("filename_required")}}}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <DropzoneArea
                        showFileNames
                        filesLimit={1}
                        onDrop={handleDrop}
                        maxFileSize={2000000}
                        onDelete={handleFileDelete}
                        dropzoneText={t("drop_resume_text")}
                        acceptedFiles={['.pdf', '.doc', '.docx', '.txt']}
                      />
                      {
                        errors?.filename && (
                          <FormHelperText style={{marginTop: ".5rem", color: "red"}}>{errors?.filename?.message?.toString()}</FormHelperText>
                        )
                      }
                    </>
                  )}
                />
              </FormControl>
              <Button variant="contained" color="primary" fullWidth type="submit">
                {loading ? <CircularProgress size={22} style={{color: "#FFFFFF"}} /> : t("submit_to_ats")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default ManualApplicationForm