import { IPlace } from "../types/places"

export const extractPlaceLabel: any = (place: IPlace, locale="tr") => {
  const nameKey = `name_${locale}`
  if(place?.name){
    return place?.name[nameKey]
  }
}

export const getPlaceNameRecursively: any = (place: IPlace, childrenNames: string, locale="tr") => {
  const name = childrenNames && childrenNames.length > 0 ? `${childrenNames}, ${extractPlaceLabel(place, locale)}` : extractPlaceLabel(place, locale)
  if(place.parent_recursive){
    return getPlaceNameRecursively(place.parent_recursive, name, locale)
  }
  return name
}