import React from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import Container from "../Container"
import MenuItem from "@material-ui/core/MenuItem"
import { applicantsActions } from "../../../../store/actions/applicants"
import { IActionType } from "../../../../types"
import { Dispatch } from "redux"
import { ICompany } from "../../../../types/company"
import { IApplicantFilters } from "../../../../types/applicants"
import { useHistory } from "react-router"
import OverlayLoader from "../../../OverlayLoader"

interface Props {
  company: ICompany
  activeFilter: boolean
  filters: IApplicantFilters
  handleClose: () => void
  applyFilters: (filters: any, page: number, orderBy?: string | null, orderDirection?: string | null) => void
}

const options = [
  { id: 0, orderBy: "created_at", orderDirection: "desc" },
  { id: 1, orderBy: "created_at", orderDirection: "asc" },
  { id: 2, orderBy: "name", orderDirection: "asc" },
  { id: 3, orderBy: "name", orderDirection: "desc" },
  { id: 4, orderBy: "job_title", orderDirection: "asc" },
  { id: 5, orderBy: "job_title", orderDirection: "desc" }
]

const MobileSortBy: React.FC<Props> = props => {
  const { t } = useTranslation()

  const history = useHistory()
  const [loading, setLoading] = React.useState(false)

  const dispatch = useDispatch()
  const orderBy = useSelector((state: any) => state.applicantsReducer.orderBy)
  const orderDirection = useSelector((state: any) => state.applicantsReducer.orderDirection)

  const handleOptionSelect = async (option: any) => {
    setLoading(true)
    dispatch(applicantsActions.changeOrderByValues(option.orderBy, option.orderDirection))
    await applyFilters(props.filters, 1, option.orderBy, option.orderDirection)
    setLoading(false)
    props.handleClose()
  }

  const applyFilters = async (filters: any, page: number, orderBy: string | null = "created_at", orderDirection: string | null = "desc") => {
    if (history.location.pathname !== "/applicants") {
      history.push("/applicants")
    }
    await props.applyFilters(
      {
        rating: filters.rating || [],
        stage: filters.stage || [],
        stage_enum: filters.stage_enum || [],
        activeFilter: props.activeFilter,
        city: filters.city || [],
        companyId: props.company.id,
        gender: filters.gender || [],
        job: filters.job || [],
        education_level: filters.education_level || [],
        field_of_study: filters.field_of_study || [],
        keyword: filters.keyword,
        work_permit: filters.work_permit || [],
        age: filters.age || [],
        driver_licence: filters.driver_licence || []
      },
      1,
      orderBy,
      orderDirection
    )
  }

  return (
    <Container open header={t("sort_by")} handleClose={props.handleClose}>
      <div>
        {options.map(option => (
          <MenuItem
            key={option.id}
            selected={option.orderBy === orderBy && option.orderDirection === orderDirection}
            value={option.id}
            onClick={() => {
              handleOptionSelect(option)
            }}
          >
            {t(`sort_id_${option.id}`)}
          </MenuItem>
        ))}
      </div>
      {loading && (
        <OverlayLoader
          open={loading}
          handleClose={() => {
            setLoading(false)
          }}
        />
      )}
    </Container>
  )
}

const mapStateToProps = (state: any) => ({
  company: state.companyReducer.company,
  filters: state.applicantsReducer.filters,
  applicants: state.applicantsReducer.applicants,
  activeFilter: state.applicantsReducer.activeFilter
})

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => ({
  applyFilters: async (filters: any, page: number, orderBy: string | null = "created_at", orderDirection: string | null = "desc") =>
    dispatch(await applicantsActions.applyFilters(filters, page, orderBy, orderDirection))
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileSortBy)
