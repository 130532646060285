export const parseToHttps = (url: any) => {
  let newUrl = url
  if (url) {
    const strList = url.split("://")
    if (strList.length === 2) {
      newUrl = "https://" + strList[1]
    } else if (strList.length === 1) {
      newUrl = "https://" + strList[0]
    }
  }
  return newUrl
}
