export enum actionTypes {
  // AUTH ACTION TYPES
  AUTHENTICATE = "AUTHENTICATE",
  UNAUTHENTICATE = "UNAUTHENTICATE",

  // FLASH ACTIONTYPES
  ADD_FLASH_MESSAGE = "ADD_FLASH_MESSAGE",
  REMOVE_FLASH_MESSAGE = "REMOVE_FLASH_MESSAGE",

  // SYSTEM ACTION TYPES
  CLOSE_DRAWER = "CLOSE_DRAWER",
  OPEN_DRAWER = "CHANGE_OPEN_DRAWER_STATE",
  CLOSE_COMPANY_MENU = "CLOSE_COMPANY_MENU",
  OPEN_COMPANY_MENU = "CHANGE_OPEN_COMPANY_MENU_STATE",
  CLOSE_LANGUAGE_MENU = "CLOSE_LANGUAGE_MENU",
  OPEN_LANGUAGE_MENU = "CHANGE_OPEN_LANGUAGE_MENU_STATE",
  CLOSE_USER_MENU_DRAWER = "CLOSE_USER_MENU_DRAWER",
  OPEN_USER_MENU_DRAWER = "CHANGE_OPEN_USER_MENU_DRAWER_STATE",

  // COMPANIES ACTION TYPES
  ADD_SECTORS = "ADD_SECTORS",
  SELECT_SECTOR = "SELECT_SECTOR",
  ADD_ABILITIES = "ADD_ABILITIES",
  COMPANY_CREATED = "COMPANY_CREATED",
  ADD_COMPANY_FAQS = "ADD_COMPANY_FAQS",
  ADD_SYSTEM_USERS = "ADD_SYSTEM_USERS",
  EDIT_COMPANY_FAQ = "EDIT_COMPANY_FAQ",
  ADD_COMPANY_USERS = "ADD_COMPANY_USERS",
  CLEAR_COMPANY_FORM = "CLEAR_COMPANY_FORM",
  ADD_USER_COMPANIES = "ADD_USER_COMPANIES",
  ADD_ALL_INDUSTRIES = "ADD_ALL_INDUSTRIES",
  ADD_COMPANY_PHOTOS = "ADD_COMPANY_PHOTOS",
  DELETE_COMPANY_FAQ = "DELETE_COMPANY_FAQ",
  DELETE_COMPANY_USER = "DELETE_COMPANY_USER",
  ADD_ABILITIES_ERROR = "ADD_ABILITIES_ERROR",
  UPDATE_COMPANY_USER = "UPDATE_COMPANY_USER",
  DELETE_COMPANY_LOGO = "DELETE_COMPANY_LOGO",
  ADD_COMPANY_UPDATES = "ADD_COMPANY_UPDATES",
  UPDATE_COMPANY_LOGO = "UPDATE_COMPANY_LOGO",
  EDIT_COMPANY_UPDATE = "EDIT_COMPANY_UPDATE",
  ADD_COMPANY_BENEFITS = "ADD_COMPANY_BENEFITS",
  EDIT_COMPANY_BENEFIT = "EDIT_COMPANY_BENEFIT",
  ADD_SECTOR_INDUSTRIES = "ADD_SECTOR_INDUSTRIES",
  DELETE_COMPANY_UPDATE = "DELETE_COMPANY_UPDATE",
  ADD_COMPANY_LOCATIONS = "ADD_COMPANY_LOCATIONS",
  EDIT_COMPANY_LOCATION = "EDIT_COMPANY_LOCATION",
  ADD_SINGE_COMPANY_FAQ = "ADD_SINGE_COMPANY_FAQ",
  ADD_COMPANY_USERS_ERROR = "ADD_COMPANY_USERS_ERROR",
  DELETE_COMPANY_LOCATION = "DELETE_COMPANY_LOCATION",
  ADD_USER_COMPANIES_ERROR = "ADD_USER_COMPANIES_ERROR",
  ADD_SINGE_COMPANY_UPDATE = "ADD_SINGE_COMPANY_UPDATE",
  ADD_COMPANY_ACTION_ERRORS = "ADD_COMPANY_ACTION_ERRORS",
  EDIT_COMPANY_FORM_CONTENT = "EDIT_COMPANY_FORM_CONTENT",
  DELETE_COMPANY_COVER_PHOTO = "DELETE_COMPANY_COVER_PHOTO",
  UPDATE_COMPANY_COVER_PHOTO = "UPDATE_COMPANY_COVER_PHOTO",
  ADD_SINGE_COMPANY_LOCATION = "ADD_SINGE_COMPANY_LOCATION",
  ADD_COMPANY_SEARCH_RESULTS = "ADD_COMPANY_SEARCH_RESULTS",
  ADD_COMPANY_USER_INVITATIONS = "ADD_COMPANY_USER_INVITATIONS",
  ADD_COMPANY_VALIDATION_ERRORS = "ADD_COMPANY_VALIDATION_ERRORS",
  REVOKE_COMPANY_USER_INVITATION = "REVOKE_COMPANY_USER_INVITATION",
  ADD_SINGLE_COMPANY_INFORMATION = "ADD_SINGLE_COMPANY_INFORMATION",

  // USER ACTION TYPES
  ADD_PROFILE = "ADD_PROFILE",
  ADD_PROFILE_ERROR = "ADD_PROFILE_ERROR",

  // JOB ACTION TYPES
  DELETE_JOB = "DELETE_JOB",
  DUPLICATE_JOB = "DUPLICATE_JOB",
  ADD_SINGLE_JOB = "ADD_SINGLE_JOB",
  CLEAR_JOB_FORM = "CLEAR_JOB_FORM",
  CLEAR_JOB_ERRORS = "CLEAR_JOB_ERRORS",
  ADD_COMPANY_JOBS = "ADD_COMPANY_JOBS",
  ADD_ARCHIVED_JOBS = "ADD_ARCHIVED_JOBS",
  UPDATE_SINGLE_JOB = "UPDATE_SINGLE_JOB",
  EDIT_FORM_CONTENT = "EDIT_FORM_CONTENT",
  FETCH_JOB_TO_EDIT = "FETCH_JOB_TO_EDIT",
  ADD_JOB_SEARCH_RESULTS = "ADD_SEARCH_RESULTS",
  ADD_JOBS_FETCH_ERROR = "ADD_JOBS_FETCH_ERROR",
  CHANGE_DUPLICATING_STATE = "CHANGE_DUPLICATING_STATE",
  ADD_JOBS_FETCH_NOT_FOUND_ERROR = "ADD_JOBS_FETCH_NOT_FOUND_ERROR",
  CLEAR_JOB_DUPLICATE_STATE = "CLEAR_JOB_DUPLICATE_STATE",

  // BILLING ACTION TYPES
  ADD_INVOICES = "ADD_INVOICES",
  ADD_JOB_SLOTS = "ADD_JOB_SLOTS",
  ADD_BILLING_ERRORS = "ADD_BILLING_ERRORS",
  ADD_BILLING_OFFERS = "ADD_BILLING_OFFERS",
  ADD_CLIENT_INVOICES = "ADD_CLIENT_INVOICES",
  ADD_PAYMENT_HISTORY = "ADD_PAYMENT_HISTORY",
  ADD_COMPANY_ABILITIES = "ADD_COMPANY_ABILITIES",
  ADD_BILLING_COMPONENTS = "ADD_BILLING_COMPONENTS",
  ADD_SINGLE_BILLING_OFFER = "ADD_SINGLE_BILLING_OFFER",
  ADD_SUBSCRIPTION_HISTORY = "ADD_SUBSCRIPTION_HISTORY",
  ADD_BILLING_PAYMENT_RESULT = "ADD_BILLING_PAYMENT_RESULT",
  CLEAR_BILLING_PAYMENT_RESULT = "CLEAR_BILLING_PAYMENT_RESULT",
  ADD_SINGLE_BILLING_COMPONENT = "ADD_SINGLE_BILLING_COMPONENT",
  ADD_BILLING_PAYMENT_RESULT_EMPTY = "ADD_BILLING_PAYMENT_RESULT_EMPTY",
  ADD_BILLING_CLIENT_NOT_REGISTERED_ERROR = "ADD_BILLING_CLIENT_NOT_REGISTERED_ERROR",

  // MESSAGES ACTION TYPES
  ADD_MESSAGES = "ADD_MESSAGES",
  EDIT_TEMPLATE = "EDIT_TEMPLATE",
  SELECT_TEMPLATE = "SELECT_TEMPLATE",
  ADD_MESSAGING_ERROR = "ADD_MESSAGING_ERROR",
  ADD_SINGLE_TEMPLATE = "ADD_SINGLE_TEMPLATE",
  ADD_MESSAGE_TEMPLATES = "ADD_MESSAGE_TEMPLATES",
  DELETE_SINGLE_TEMPLATE = "DELETE_SINGLE_TEMPLATE",

  // APPLICANTS ACTION TYPES
  ADD_COMMENT = "ADD_COMMENT",
  RESET_STATE = "RESET_STATE",
  EDIT_COMMENT = "EDIT_COMMENT",
  APPLY_FILTERS = "APPLY_FILTERS",
  DELETE_COMMENT = "DELETE_COMMENT",
  ADD_APPLICANTS = "ADD_APPLICANTS",
  CHANGE_SORT_BY = "CHANGE_SORT_BY",
  OPEN_SEND_MESSAGE = "OPEN_SEND_MESSAGE",
  CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS",
  CLEAR_FILTER_TAGS = "CLEAR_FILTER_TAGS",
  ADD_SINGLE_COMMENT = "ADD_SINGLE_COMMENT",
  SORT_APPLICANTS_BY = "SORT_APPLICANTS_BY",
  CHANGE_SEARCH_OPEN = "CHANGE_SEARCH_OPEN",
  CLOSE_SEND_MESSAGE = "CLOSE_SEND_MESSAGE",
  ADD_CANDIDATE_NOTES = "ADD_CANDIDATE_NOTES",
  ADD_FIELDS_OF_STUDY = "ADD_FIELDS_OF_STUDY",
  ADD_SINGLE_APPLICANT = "ADD_SINGLE_APPLICANT",
  CLEAR_OPEN_APPLICANT = "CLEAR_OPEN_APPLICANT",
  CLEAR_ALL_APPLICANTS = "CLEAR_ALL_APPLICANTS",
  ADD_APPLICANTS_ERROR = "ADD_APPLICANTS_ERROR",
  ADD_EXPORT_APPLICANTS = "ADD_EXPORT_APPLICANTS",
  OPEN_SINGLE_APPLICANT = "OPEN_SINGLE_APPLICANT",
  CLEAR_CANDIDATE_NOTES = "CLEAR_CANDIDATE_NOTES",
  FETCH_SINGLE_APPLICANT = "FETCH_SINGLE_APPLICANT",
  UPDATE_APPLICANT_STATUS = "UPDATE_APPLICANT_STATUS",
  UPDATE_APPLICANT_RATING = "UPDATE_APPLICANT_RATING",
  FILTER_ACTIVE_APPLICANTS = "FILTER_ACTIVE_APPLICANTS",
  ADD_FIELDS_OF_STUDY_GROUPS = "ADD_FIELDS_OF_STUDY_GROUPS",
  CHANGE_SHOW_MESSAGING_STATUS = "CHANGE_SHOW_MESSAGING_STATUS",
  ADD_CURRENT_USER_INFORMATION = "ADD_CURRENT_USER_INFORMATION",
  ADD_ARCHIVED_JOBS_APPLICANTS = "ADD_ARCHIVED_JOBS_APPLICANTS",
  CLEAR_ARCHIVED_JOBS_APPLICANTS = "CLEAR_ARCHIVED_JOBS_APPLICANTS",
  REMOVE_ACTIVE_FILTER_APPLICANTS = "REMOVE_ACTIVE_FILTER_APPLICANTS",
  UPDATE_APPLICANT_RATING_REALTIME = "UPDATE_APPLICANT_RATING_REALTIME",
  UPDATE_APPLICANT_STATUS_REALTIME = "UPDATE_APPLICANT_STATUS_REALTIME",
  OPTIMISTIC_UPDATE_APPLICANT_STATUS = "OPTIMISTIC_UPDATE_APPLICANT_STATUS",
  CHANGE_APPLICANTS_ORDER_INFORMATION = "CHANGE_APPLICANTS_ORDER_INFORMATION",
  ADD_COMPANY_FOLDERS = "ADD_COMPANY_FOLDERS",
  ADD_FOLDER_TO_APPLICANT = "ADD_FOLDER_TO_APPLICANT",
  REMOVE_FOLDER_FROM_APPLICANT = "REMOVE_FOLDER_FROM_APPLICANT",
  ADD_FILTERED_PLACE = "ADD_FILTERED_PLACE",

  // Impersonation Action Types
  ADD_IMPERSONATOR = "ADD_IMPERSONATOR",
  ADD_IMPERSONATION_DATA = "ADD_IMPERSONATION_DATA",
  CLEAR_IMPERSONATION_DATA = "CLEAR_IMPERSONATION_DATA",
  CHANGE_IMPERSONATION_STATE = "CHANGE_IMPERSONATION_STATE",
  CHANGE_IMPERSONATION_USER_ID = "CHANGE_IMPERSONATION_USER_ID",
  CHANGE_IMPERSONATION_TOKEN_SET_STATE = "CHANGE_IMPERSONATION_TOKEN_SET_STATE",
}
