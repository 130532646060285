import React from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import ListSubheader from "@material-ui/core/ListSubheader"
import { IMessageTemplate } from "../../../../../types/messages"
import { ICompany } from "../../../../../types/company"
import { IActionType } from "../../../../../types"
import { messagesActions } from "../../../../../store/actions/messages"
import { Dispatch } from "redux"

interface Props {
  company: ICompany
  template: IMessageTemplate
  templates: IMessageTemplate[]
  fetchTemplates: (id: any) => void
  handleSelect: (value: any) => void
}

const _TemplatesInput: React.FC<Props> = props => {
  const { t } = useTranslation()

  const { templates, company, fetchTemplates } = props

  React.useEffect(() => {
    if (!templates) {
      fetchTemplates(company.id)
    }
  }, [company, templates, fetchTemplates])

  const handleChange = (event: any, value: any) => {
    props.handleSelect(event.target.value)
  }

  const standard = props.templates?.filter(item => item.is_standard === 1)

  const custom = props.templates?.filter(item => item.is_standard !== 1)

  return (
    // <Autocomplete
    //   options={props.templates ?? []}
    //   groupBy={(option) => option.is_standard}
    //   getOptionLabel={fetchOptionLabel}
    //   value={props.template}
    //   style={{marginBottom: "10px", marginTop: "0px"}}
    //   onChange={handleChange}
    //   renderInput={
    //     (params) => <TextField
    //       {...params}
    //       label={`${t("select_template")} (${t('optional')})`}
    //       placeholder={`${t("select_template")} (${t('optional')})`}
    //       variant="standard"
    //       margin="dense"
    //     />
    //   }
    // />
    <FormControl fullWidth style={{ marginBottom: "10px" }}>
      <InputLabel htmlFor="grouped-select">{`${t("select_template")} (${t("optional")})`}</InputLabel>
      <Select
        fullWidth
        id={"grouped-select"}
        defaultValue=""
        value={props.template || ""}
        label={`${t("select_template")} (${t("optional")})`}
        placeholder={`${t("select_template")} (${t("optional")})`}
        onChange={handleChange}
      >
        {custom?.length > 0 ? <ListSubheader key={Math.random()}>{t("template_origin_0")}</ListSubheader> : null}
        {custom?.map((item: any) => {
          return (
            <MenuItem value={item} key={item.id}>
              {item.title}
            </MenuItem>
          )
        })}
        {standard?.length > 0 ? <ListSubheader key={Math.random()}>{t("template_origin_1")}</ListSubheader> : null}
        {standard?.map((item: any) => {
          return (
            <MenuItem value={item} key={item.id}>
              {item.title}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

const mapStateToProps = (state: any) => {
  return {
    templates: state.messagesReducer.templates,
    company: state.companyReducer.company
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchTemplates: async (companyId: any) => dispatch(await messagesActions.fetchCompanyTemplates(companyId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(_TemplatesInput)
