import React from "react"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import IconButton from "@material-ui/core/IconButton"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"

import Input from "./Input"
import { IActionType } from "../../../types"
import { messagesActions } from "../../../store/actions/messages"

import useStyles from "./styles"

interface Props {
  closeSendMessage: () => void
}

const Messaging: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <div
        style={{
          backgroundColor: "#221133"
        }}
        className={classes.headerSection}
      >
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
          <div style={{ color: "white" }}>{t("new_message")}</div>
          <IconButton onClick={props.closeSendMessage}>
            <CloseRoundedIcon style={{ color: "white" }} />
          </IconButton>
        </div>
      </div>

      <div className={classes.inputSectionExpanded}>
        <Input />
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company,
    messages: state.messagesReducer.messages,
    recipients: state.messagesReducer.recipients,
    applicant: state.applicantsReducer.singleApplicant
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    closeSendMessage: () => dispatch(messagesActions.closeSendMessage())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Messaging)
