import React from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"

// import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
// import WorkOutlineIcon from "@material-ui/icons/WorkOutline";

import useStyles from "../../styles"
import { IApplicant } from "../../../../../types/applicants"

interface Props {
  readOnly?: boolean
  applicant: IApplicant
}

const JobDetails: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.applicantInfoCard}>
      <div className={classes.infoCardHeader}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          {t("job_details")}
        </Typography>
        {/* {props.readOnly ? null : (
          <Button href={`/jobs/${props.applicant.job_id}/edit`} target="_blank" rel="noopener noreferrer" color="primary" style={{ padding: 0 }}>
            {t("view_job")}
          </Button>
        )} */}
      </div>
      <Typography variant="subtitle1">
        {props.applicant.job_title} - {props.applicant?.job_place_name ? props.applicant?.job_place_name : t(`city_id_${props.applicant.job_city_id}`)}
      </Typography>
      <Typography variant="body2">
        {t("applied_on")} {moment(props.applicant.created_at).format("DD MMMM YYYY - HH:mm")}
      </Typography>
    </div>
  )
}

export default JobDetails
