import React from "react"
import { useTranslation } from "react-i18next"

import Checkbox from "@material-ui/core/Checkbox"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"

// import { IStudyGroup } from '../../../../../types/user';

interface Props {
  query?: string
  checked: boolean
  handleClick: () => void
}

const SelectAll: React.FC<Props> = props => {
  const { t } = useTranslation()

  const { query, checked, handleClick } = props

  return (
    <div style={{ borderBottom: "1px solid #eaeaea", backgroundColor: "#f2f2f2" }}>
      <ListItem role={undefined} dense button onClick={handleClick}>
        <ListItemIcon>
          <Checkbox edge="start" checked={checked} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": `checkbox-list-label-select-all` }} />
        </ListItemIcon>
        <ListItemText id={`checkbox-list-label-select-all`} primary={`${t("select_all")} (${query ? query : ""})`} />
      </ListItem>
    </div>
  )
}

export default SelectAll
