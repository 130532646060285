import React from "react"
import axios from "../../api/iskibris/iskibris"

import Cookies from "js-cookie"
import { Redirect, useHistory } from "react-router"
import { useTranslation } from "react-i18next"

import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

import iKLogo from "../../assets/images/logo.png"

import useStyles from "./styles"
import { useDispatch } from "react-redux"
import { authActions } from "../../store/actions/authentication"
import Can from "../../utils/AccessControl/Can"
import * as Constants from "../../config/constants"

interface Props {}

const RedirectToEmployer: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const handleLogout = async () => {
    await axios.post("/api/logout")
    localStorage.removeItem("auth_token")
    localStorage.removeItem("refresh_token")
    localStorage.removeItem("current_company_id")
    localStorage.removeItem("CompanyToken")
    Cookies.remove("auth_token", { domain: Constants.APP_DOMAIN })
    Cookies.remove("CompanyToken")
    dispatch(authActions.unauthenticate())
    setTimeout(function () {
      history.push("/")
    }, 500)
  }

  return (
    <div className={classes.root}>
      <Can not I="use-hiring-panel" a="all">
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.left}>
              <div className={classes.logoBar}>
                <img src={iKLogo} alt="Is Kibris" className={classes.logotypeImage} />
                <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                  {t("for_employers")}
                </Typography>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div className={classes.innerDiv}>
          <Typography variant="body1" style={{ textAlign: "center" }}>
            {t("no_company_managed_text")}
          </Typography>
          <div style={{ margin: "15px", display: "flex", flexDirection: "column", width: "100%" }}>
            <Button color="secondary" href={process.env.REACT_APP_EMPLOYER_APP_URL}>
              {t("click_here_to_create_trial_account")}
            </Button>
            <Button color="secondary" onClick={handleLogout}>
              {t("signin_with_another_account")}
            </Button>
          </div>
        </div>
      </Can>
      <Can I="use-hiring-panel" a="all">
        <Redirect to="/dashboard" />
      </Can>
    </div>
  )
}

export default RedirectToEmployer
