import { Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React from 'react'
import { useTranslation } from 'react-i18next';

interface Props {
  onAddBillingSettingsClick?: () => void;
  onClose?: () => void;
}

const BillingSettingsRequiredWarning: React.FC<Props> = (props) => {

  const {t} = useTranslation()

  return (
    <div>
      <Alert severity="info" variant="standard" icon={<ErrorOutline />} style={{ borderRadius: "5px", marginBottom: "20px", display: "flex", alignItems: "center", fontSize: "1rem", width: "100%" }} onClose={props.onClose}>
        {t("billing_settings_required_for_e_fatura")}
        {
          props.onAddBillingSettingsClick ? (
            <span onClick={props.onAddBillingSettingsClick} style={{textDecoration: "underline", fontWeight: "bold", cursor: "pointer"}}>
              <Typography style={{textDecoration: "underline", fontWeight: "bold"}}>{t("add_billing_settings")}</Typography>
            </span>
          ) : null
        }
      </Alert>
    </div>
  )
}

export default BillingSettingsRequiredWarning;