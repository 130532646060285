import React from "react"

import { useTranslation } from "react-i18next"
import { Text, View, StyleSheet } from "@react-pdf/renderer"

import Section from "./Section"
import { IUser } from "../../../types/user"
import { getPlaceNameRecursively } from "../../../utils/places"

interface Props {
  user: IUser
}

const CvPdfUserLanguages: React.FC<Props> = ({ user }) => {
  const { t, i18n } = useTranslation()

  return (
    <React.Fragment>
      <Section title={t("candidate_details")}>
        <View style={styles.container}>
          <View style={styles.layout}>
            <View style={styles.singleDetailsContainer} key={Math.random()} wrap>
              <Text style={styles.detailLabel}>{t("education_level")}</Text>
              <Text style={styles.detailValue}>{t(`education_level_id_${user.education_level_id}`)}</Text>
            </View>
            <View style={styles.singleDetailsContainer} key={Math.random()} wrap>
              <Text style={styles.detailLabel}>{t("years_of_experience_label")}</Text>
              <Text style={styles.detailValue}>{user.years_of_experience}</Text>
            </View>
            <View style={styles.singleDetailsContainer} key={Math.random()} wrap>
              <Text style={styles.detailLabel}>{t("gender")}</Text>
              <Text style={styles.detailValue}>{t(`gender_id_${user.gender}`)}</Text>
            </View> 
            <View style={styles.singleDetailsContainer} key={Math.random()} wrap>
              <Text style={styles.detailLabel}>{t("city_of_residence")}</Text>
              <Text style={styles.detailValue}>
                {
                  // t(`city_id_${user.city_id}`)
                  user?.place?.name ? getPlaceNameRecursively(user?.place, "", i18n.language) : t(`city_id_${user?.city_id}`)
                }
              </Text>
            </View>
            <View style={styles.singleDetailsContainer} key={Math.random()} wrap>
              <Text style={styles.detailLabel}>{t("work_permit_label")}</Text>
              <Text style={styles.detailValue}>{t(`work_permit_id_${user.work_permit_id}`)}</Text>
            </View>
            <View style={styles.singleDetailsContainer} key={Math.random()} wrap>
              <Text style={styles.detailLabel}>{t("new_employment_type_label")}</Text>
              <Text style={styles.detailValue}>{t(`employment_type_id_${user.job_seeking_status}`)}</Text>
            </View>
          </View>
        </View>
      </Section>
    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  layout: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  singleDetailsContainer: {
    width: "33%",
    display: "flex",
    flexWrap: "wrap",
    padding: "10px 0px"
  },
  detailLabel: {
    fontSize: "11px"
  },
  detailValue: {
    fontWeight: "bold",
    fontSize: "12px"
  }
})

export default CvPdfUserLanguages
