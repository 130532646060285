import React from "react"
import { useTranslation } from "react-i18next"

import Typography from "@material-ui/core/Typography"

import useStyles from "../../styles"
import { IApplicant } from "../../../../../types/applicants"

interface Props {
  readOnly?: boolean
  applicant: IApplicant
}

const CoverLetter: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.applicantInfoCard}>
      <div className={classes.infoCardHeader}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          {t("cover_letter")}
        </Typography>
      </div>
      <Typography variant="body2">{props.applicant.cover_letter}</Typography>
    </div>
  )
}

export default CoverLetter
