import React from "react"
import { useTranslation } from "react-i18next"

import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"

import { ICompany } from "../../../../types/company"
import MultiLangTextEditor from "../../../MultiLangTextEditor"
import { Dispatch } from "redux"
import { IActionType } from "../../../../types"
import { companyActions } from "../../../../store/actions/company"
import { connect } from "react-redux"

interface Props {
  company: ICompany
  submitUpdate: (info: any, id: any) => void
}

const AddUpdate: React.FC<Props> = props => {
  const { t } = useTranslation()

  const [updateBody, setUpdateBody] = React.useState("")
  const [submitting, setSubmitting] = React.useState(false)

  const handlePost = async () => {
    setSubmitting(true)
    let update = {
      body: updateBody
    }
    await props.submitUpdate(update, props.company.id)
    setSubmitting(false)
    setUpdateBody("")
  }

  const handleCancel = () => {}

  return (
    <div>
      <MultiLangTextEditor value={updateBody} handleChange={setUpdateBody} />
      <div style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
        <Button color="primary" variant="contained" onClick={handlePost}>
          {submitting ? <CircularProgress size={22} style={{ color: "#fff" }} /> : t("post")}
        </Button>
        <Button disabled color="primary" variant="outlined" onClick={handleCancel}>
          {t("cancel")}
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    submitUpdate: async (value: any, companyId: any) => dispatch(await companyActions.addCompanyUpdate(value, companyId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdate)
