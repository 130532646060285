import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Typography from "@material-ui/core/Typography"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import { useDispatch } from "react-redux"
import Cookies from "js-cookie"
import { CircularProgress } from "@material-ui/core"
import { getImpersonationAuthToken } from "../../api/iskibris/iskibris-methods"
import { impersonationActions } from "../../store/actions/impersonation"
// import { useHistory } from "react-router"
import { flashActions } from "../../store/actions/flash-messaging"

interface Props {
  userId: any
}

const ImpersonationPrompt: React.FC<Props> = ({userId}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const [loading, setLoading] = useState(false)

  // const router = useHistory()

  const dispatch = useDispatch()

  const handleClose = () => {
    setOpen(false)
  }

  const handleAgree = async () => {
    setLoading(true)
    await getImpersonationAuthToken(userId)
      .then(response => {
        const token = response.data?.token
        const expiresAt = response.data?.expires_at

        Cookies.set('impersonation', "1")
        Cookies.set('impersonation_user_id', userId)
        Cookies.set('impersonation_auth_token', token)
        Cookies.set('impersonation_auth_token_expires_at', expiresAt)

        Cookies.remove('CompanyToken')
        Cookies.remove('current_company_id')

        const data = {
          is_impersonating: true,
          is_impersonation_token_set: true,
          impersonation_user_id: userId,
        }

        dispatch(impersonationActions.addImpersonationData(data))

        dispatch( flashActions.addFlashMessage(t("impersonation_starting_message"), "success") )

        window?.location?.reload()
      })
      .catch(error => {
        dispatch( flashActions.addFlashMessage(t("impersonation_error"), "error") )
      })
    setLoading(false)
    setOpen(false)
  }

  React.useEffect(() => {
    return () => { }
  }, [])

  return (
    <div className="impersonation-confirmation">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="impersonation-confirmation__dialog-title">
          <span>
            <Typography variant="h6" className="impersonation-confirmation__dialog-question">
              {t("impersonation_prompt_title")}
            </Typography>
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="impersonation-confirmation__dialog-warning"
          >
            {t("impersonation_prompt_body", {id: userId})}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="impersonation-confirmation__dialog-actions-container">
          <Button onClick={handleClose} color="primary" variant="outlined">
            {t("cancel")}
          </Button>
          <Button onClick={handleAgree} className="impersonation-confirmation__delete-button" autoFocus>
            {loading ? <CircularProgress size={22} style={{color: "white"}} /> : t("continue")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ImpersonationPrompt
