import React from "react"

import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useHistory, useLocation } from "react-router"
import { useTranslation } from "react-i18next"

import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import CssBaseline from "@material-ui/core/CssBaseline"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { IActionType } from "../../types"
import NavBar from "../../components/NavBar/NavBar"
import SideBar from "../../components/SideBar"
import TabPanel from "../../components/TabPanel"
import Copyrights from "../../components/Copyright"

import { ICompany } from "../../types/company"
import Templates from "../../components/Settings/Templates"
// import Subscription from "../../components/Settings/Subscription"
import UserManagement from "../../components/Settings/UserManagement"
import UserSideMenu from "../../components/MobileComponents/UserSideMenu"
import { billingActions } from "../../store/actions/billing"
import { IBillingPaymentResult } from "../../types/billing"
import useSearchParams from "../../hooks/useSearchParams"
import { Alert } from "@material-ui/lab"
import { Link } from "react-router-dom"
// import { makeStyles } from "@material-ui/core"

// const useStyles = makeStyles(theme => ({
//   error: {
//     width: "100%",
//     padding: "15px 25px",
//     marginBottom: "10px",
//     color: theme.palette.common.white,
//     background: theme.palette.error.main,
//     borderRadius: "5px"
//   },
//   success: {
//     width: "100%",
//     padding: "15px 25px",
//     marginBottom: "10px",
//     color: theme.palette.common.white,
//     background: theme.palette.success.main,
//     borderRadius: "5px"
//   }
// }))
interface Props {
  company: ICompany
  billingResult: IBillingPaymentResult | null
  fetchBillingResults: (key: any) => void
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  }
}

const SettingsManagement: React.FC<Props> = props => {
  const { t } = useTranslation()

  const handleChange = (event: any, value: string) => {
    history.push(value)
  }

  const history = useHistory()
  const location = useLocation()

  // const classes = useStyles()
  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [searchAsObject] = useSearchParams()

  const { fetchBillingResults } = props

  React.useEffect(() => {
    const getPageTitle = () => {
      switch (history.location.pathname) {
        case "/settings/users/management":
          return t("settings_title_users_management")
        case "/settings/templates":
          return t("settings_title_templates")
        default:
          return t("settings_title")
      }
    }
    document.title = getPageTitle()
  }, [history.location.pathname, t])

  React.useEffect(() => {
    const containerId = searchAsObject.container_id

    if (containerId) {
      fetchBillingResults(containerId)
    }
  }, [location, fetchBillingResults, searchAsObject.container_id])

  const extractSubscriptionHistoryValue = () => {
    const pathname = history.location.pathname
    switch (pathname) {
      case "/settings/users/management":
        return pathname
      default:
        return "/settings"
    }
  }

  return (
    <div>
      <CssBaseline />
      <NavBar />
      <SideBar />
      {matchesMobile ? <UserSideMenu /> : null}
      <main className="content content--has-sidebar content--with-container" style={{ paddingTop: "62px" }}>
        <Typography variant="h5" className={"page-title"} gutterBottom>
          {t("settings")}
        </Typography>
        <Alert
          severity="info"
          variant="outlined"
          style={{ borderRadius: "5px", marginBottom: "10px", alignItems: "center", width: "100%" }}
          // title={t("are_you_looking_for_billing")}
          // closeText={t("go_to_billing")}
          // onClose={() => {history.push("/billing")}}
        >
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            <Typography variant="body1">{t("are_you_looking_for_billing")} <Link to="/billing" style={{color: "#ea148d"}}>{t("go_to_billing")}</Link></Typography>
            
          </div>
        </Alert>
        <Tabs
          value={history.location.pathname}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab label={t("users_and_roles")} {...a11yProps("/settings" || "/settings/users/management")} value={extractSubscriptionHistoryValue()} />
          <Tab label={t("templates")} {...a11yProps("/settings/templates")} value={"/settings/templates"} />
        </Tabs>
        <Divider />
        {/* <TabPanel value={history.location.pathname} index={extractSubscriptionHistoryValue()}>
          {props.billingResult ? (
            <React.Fragment>
              {props.billingResult.success ? (
                <div className={classes.success}>{props.billingResult.success}</div>
              ) : (
                <div className={classes.error}>{props.billingResult.error}</div>
              )}
            </React.Fragment>
          ) : null}
          <Subscription />
        </TabPanel> */}
        <TabPanel value={history.location.pathname} index={extractSubscriptionHistoryValue()}>
          <UserManagement />
        </TabPanel>
        <TabPanel value={history.location.pathname} index={"/settings/templates"}>
          <Templates />
        </TabPanel>
      </main>
      <footer>
        <Copyrights />
      </footer>
      {/* <WhatsappContact /> */}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  company: state.companyReducer.company,
  billingResult: state.billingReducer.billingResult
})

const mapDispatchtoProps = (dispatch: Dispatch<IActionType>) => ({
  fetchBillingResults: async (key: string) => dispatch(await billingActions.fetchBillingResults(key))
})

export default connect(mapStateToProps, mapDispatchtoProps)(SettingsManagement)
