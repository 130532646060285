import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2, 2),
    marginTop: "20px"
  },
  overlay: {
    "&:hover": {
      backgroundColor: "#000000",
      opacity: "0.4!important"
    },
    transition: "0.5s"
  },
  subtitleText: {
    color: "#797979"
  },
  media: {
    height: 205,
    width: "100%",
    paddingBottom: "17%",
    position: "relative",
    border: "1px solid #B6BBC2",
    transition: "background-color .3s",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      height: 100
    }
  },
  link: {
    textDecoration: "none"
  },
  photo: {
    width: "820px",
    height: "205px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "820px",
      width: "100%"
    }
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "5%",
    maxHeight: "90%",
    overflow: "auto"
  },
  logoOverlayContainer: {
    backgroundColor: "transparent",
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    transition: "all .3s",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,.9)"
    }
  },
  logoOverlay: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all .3s"
  },
  logoIconButton: {
    height: 50,
    width: 50,
    margin: 2
  },
  addLogoCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // width: 1280,
    height: 142,
    border: "1px solid #000",
    cursor: "pointer",
    transition: "background-color .3s",
    "&:hover": {
      backgroundColor: "#cccccc"
    }
  },
  cropperDiv: {
    position: "relative",
    margin: "16px auto",
    height: 280,
    width: 720,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 720,
      width: "100%"
    }
  }
}))

export default useStyles
