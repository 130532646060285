import React from "react"
import { Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import useStyles from "../styles"
import { Link } from "react-router-dom"

interface Props {}

const AuthError: React.FC<Props> = props => {
  const { t } = useTranslation()

  const classes = useStyles()

  return (
    <React.Fragment>
      <Typography variant="body1" className={classes.loginError} style={{ textAlign: "center" }}>
        {t("login_error")}{" "}
        <Link style={{ color: "#fff" }} to="/forgot-password">
          {t("click_here_forgot_password")}
        </Link>
      </Typography>
    </React.Fragment>
  )
}

export default AuthError
