import React from "react"

import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import DropdownArrow from "@material-ui/icons/KeyboardArrowDown"
import DropupArrow from "@material-ui/icons/KeyboardArrowUp"
// import Button from "@material-ui/core/Button";

import Paper from "@material-ui/core/Paper"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

import { useTranslation } from "react-i18next"

import useStyles from "./styles"

interface Props {
  changeLanguage: (value: any) => void
}

let dropdownOptions = [
  { value: "tr", label: "Türkçe", code: "TR" },
  { value: "en", label: "English", code: "EN" }
]

const LanguageSelector: React.FC<Props> = props => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const [open, setOpen] = React.useState(false)

  const handleLanguageClick = (value: any) => {
    props.changeLanguage(value)
    setOpen(false)
  }

  const handleClose = () => {
    if (open) {
      setOpen(false)
    }
  }

  const handleLangButtonClicked = () => {
    setOpen(!open)
  }

  return (
    <div className={classes.root}>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <div onClick={handleLangButtonClicked} className={classes.button}>
            {t("language")}
            {open ? <DropupArrow className={classes.rightIcon} /> : <DropdownArrow className={classes.rightIcon} />}
          </div>
          {open ? (
            <Paper className={classes.paper} elevation={3}>
              <List className={classes.ratingsContainer}>
                {dropdownOptions.map(value => {
                  const labelId = `checkbox-list-label-${value.value}`
                  return (
                    <ListItem
                      selected={i18n.language === value.value}
                      key={value.value}
                      role={undefined}
                      dense
                      button
                      onClick={() => {
                        handleLanguageClick(value.value)
                      }}
                    >
                      <ListItemText id={labelId}>
                        {value.label} <span className={classes.shortLabel}>{value.code}</span>
                      </ListItemText>
                    </ListItem>
                  )
                })}
              </List>
            </Paper>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  )
}

export default LanguageSelector
