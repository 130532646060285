import React from "react"
import { IMessageTemplate } from "../../../types/messages"

import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

import useStyles from "./styles"
import { useTranslation } from "react-i18next"
import LongMenu from "../../LongMenu"
import Moment from "react-moment"

interface Props {
  type: string
  template: IMessageTemplate
  options: Array<{ title: string; handler: () => void }>
}

const SingleTemplateCard: React.FC<Props> = props => {
  const { t } = useTranslation()

  const classes = useStyles()

  return (
    <div>
      <ListItem className={classes.listItem}>
        <ListItemText
          primary={<Typography variant="body1">{props.template.title}</Typography>}
          secondary={
            <Typography variant="body2" className={classes.jobTitle}>
              {t(`template_type_id_${props.template.messenger_template_type_id}`)}
              {props.type === "custom" ? (
                <React.Fragment>
                  {" "}
                  - <Moment fromNow>{props.template.updated_at}</Moment>
                </React.Fragment>
              ) : (
                ""
              )}
            </Typography>
          }
        />
        <ListItemSecondaryAction>{<LongMenu options={[...props.options]} />}</ListItemSecondaryAction>
      </ListItem>
    </div>
  )
}

export default SingleTemplateCard
