import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxWidth: "600px",
    width: "100%",
    outline: "none",
    position: "relative",
    maxHeight: "100%",
    overflow: "auto"
  },
  toolbar: {
    backgroundColor: "#F2F2F2"
  },
  contents: {
    margin: theme.spacing(2, 2)
  },
  buttons: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "space-between"
  }
}))

export default useStyles
