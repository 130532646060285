import React, { useState } from "react"
import { AxiosError, AxiosResponse } from "axios"
import axios from "../../../api/iskibris/iskibris"

import Link from "@material-ui/core/Link"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import InputAdornment from "@material-ui/core/InputAdornment"
import CircularProgress from "@material-ui/core/CircularProgress"

import Cookies from "js-cookie"
import { useHistory } from "react-router"
import { useTranslation } from "react-i18next"

import useStyles from "../styles"
import AuthError from "../AuthError"
import Greetings from "../Greetings"
import GoogleOauth from "../GoogleAuth"
import Copyright from "../../Copyright"
import FacebookAuth from "../FacebookAuth"
import LanguageSelector from "../LanguageSelector"
import HTTPResponses from "../../../tools/http.responses"
import CustomDivider from "../CustomDivider"

interface Props {
  onSuccess: () => void
}

const Signin: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const [values, setValues] = useState({
    email: "",
    password: ""
  })
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [wrongCredentials, setWrongCredentials] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  const setValuesHandler = (name: any) => (event: any) => setValues({ ...values, [name]: event.target.value })

  const serverLoginHandler = async (event: any) => {
    event.preventDefault()

    setLoading(true)
    setWrongCredentials(false)

    const data = {
      username: values.email,
      password: values.password,
      grant_type: process.env.REACT_APP_GRANT_TYPE,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET
    }

    await axios
      .post("/oauth/token", data)
      .then(async (response: AxiosResponse) => {
        if (response.status === 200) {
          await Cookies.set("auth_token", response.data["access_token"], {
            domain: process.env.REACT_APP_DOMAIN || ".iskibris.com",
            sameSite: "none",
            secure: true,
            expires: 364
          })
          setLoading(false)
          props.onSuccess()
        }
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === HTTPResponses.UNAUTHORIZED || error.response?.status === HTTPResponses.BAD_REQUEST) {
          setWrongCredentials(true)
          setLoading(false)
        }
      })
  }

  let loginFailureMessage = null

  if (wrongCredentials) {
    loginFailureMessage = <AuthError></AuthError>
  }

  return (
    <div className={classes.root}>
      <Greetings />
      <LanguageSelector />
      <div style={{ marginTop: "20px" }}>
        <FacebookAuth
          mode="login"
          onSuccess={props.onSuccess}
          onFail={() => {
            console.log("Facebook Login Failed")
          }}
        />
        <GoogleOauth
          mode="login"
          onSuccess={props.onSuccess}
          onFail={() => {
            console.log("Facebook Login Failed")
          }}
        />
      </div>
      <CustomDivider />
      {loginFailureMessage}
      <div>
        <form onSubmit={serverLoginHandler}>
          <TextField
            data-private="lipsum"
            variant="outlined"
            margin="dense"
            required
            fullWidth
            id="email"
            label={t("email_address")}
            name="email"
            autoComplete="email"
            autoFocus
            onChange={setValuesHandler("email")}
          />
          <TextField
            data-private="lipsum"
            variant="outlined"
            margin="dense"
            required
            fullWidth
            name="password"
            label={t("password")}
            id="password"
            autoComplete="current-password"
            onChange={setValuesHandler("password")}
            value={values.password}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} style={{ marginTop: "15px" }}>
            {loading ? <CircularProgress size="30px" style={{ color: "white" }} /> : t("sign_in")}
          </Button>
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.extraActionButtonGrid}>
              <Link
                href="#"
                onClick={(event: any) => {
                  event.preventDefault()
                  history.push("/forgot-password", history.location.state)
                }}
              >
                {t("forgot_password")}?
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.extraActionButtonGrid}>
              <Link
                variant="body2"
                target="_blank"
                referrerPolicy="no-referrer"
                href={process.env.REACT_APP_EMPLOYER_APP_URL}
                // onClick={(event: any) => {
                //   event.preventDefault()
                //   history.push("/register")
                // }}
              >
                {t("do_not_have_account")}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ marginTop: "30px" }}>
        <Copyright />
      </div>
    </div>
  )
}

export default Signin
