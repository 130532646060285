import React from "react"
import { useTranslation } from "react-i18next"
import CustomTooltip from "../../CustomTooltip"
import { InfoOutlined } from "@material-ui/icons"
import { Button, Fade, IconButton, Modal, Typography, Backdrop } from "@material-ui/core"

import CloseRoundedIcon from "@material-ui/icons/CloseRounded"

import useStyles from "./styles"
import ManualApplicationForm from "./ManualApplicationForm"

import UploadIcon from "@material-ui/icons/MergeTypeOutlined"
import Can from "../../../utils/AccessControl/Can"
import AccessDeniedTooltip from "../../AccessDeniedToolTip"

interface Props {

}

const ManualApplication: React.FC<Props> = (props) => {
  
  const {t} = useTranslation()

  const [open, setOpen] = React.useState(false)

  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <div>
      <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end",}}>
        <Can I="use-ats-system" a="all">
          <Button variant="contained" color="secondary" style={{marginRight: "0.5rem"}} onClick={handleOpen} className={classes.textbutton}>
            <UploadIcon className={classes.icon} />
            <Typography className={classes.text}>{t("add_manual_application")}</Typography>
          </Button>
          <IconButton style={{marginRight: "0.5rem"}} color="secondary" onClick={handleOpen} className={classes.iconbutton}>
            <UploadIcon className={classes.icon} />
          </IconButton>
        </Can>
        <Can not I="use-ats-system" a="all">
          <AccessDeniedTooltip link={"/features/ats"} linkLabel={t("go_to_ats_system_landing_pages")} body={t("billing_cannot_use_ats_system")}>
            <Button variant="outlined" color="secondary" disabled style={{marginRight: "0.5rem"}} className={classes.textbutton}>
              <UploadIcon className={classes.icon} />
              <Typography className={classes.text}>{t("add_manual_application")}</Typography>
            </Button>
            <IconButton style={{marginRight: "0.5rem"}} disabled className={classes.iconbutton}>
              <UploadIcon className={classes.icon} />
            </IconButton>
          </AccessDeniedTooltip>
        </Can>
        {/* <IconButton style={{marginRight: "0.5rem"}} onClick={handleOpen} className={classes.iconbutton}>
          <UploadIcon className={classes.icon} />
        </IconButton> */}
        <CustomTooltip
          title={
            <div style={{ padding: "8px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <Typography variant="body2">{t("add_manual_application_caption")}</Typography>
              <Button color="secondary" href="/employer-tools" style={{ marginTop: "10px" }}>
                {t("learn_more")}
              </Button>
            </div>
          }
          interactive
          arrow
        >
          <InfoOutlined fontSize="small" />
        </CustomTooltip>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.modalPaper}>
            <div className={classes.modalCloseContainer}>
              <Typography className={classes.modalTypographyTitle} variant="h6" gutterBottom>
                {t(`add_manual_application_title`)}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseRoundedIcon />
              </IconButton>
            </div>
            <Typography className={classes.modalTypographyCaption} variant="caption" gutterBottom>
              {t(`add_manual_application_caption`)}
            </Typography>
            <ManualApplicationForm onSuccess={handleClose}/>
          </div>
        </Fade>
      </Modal>
    </div>
  )

}

export default ManualApplication