import React, { useEffect } from "react"
import { connect } from "react-redux"

import { makeStyles } from "@material-ui/core"

import Table from "@material-ui/core/Table"
import Paper from "@material-ui/core/Paper"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import Typography from "@material-ui/core/Typography"

import SingleBenefit from "./SingleBenefit"
import { useTranslation } from "react-i18next"
import { ICompany, ICompanyBenefit } from "../../../types/company"
import { Dispatch } from "redux"
import { IActionType } from "../../../types"
import { companyActions } from "../../../store/actions/company"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2, 2),
    marginTop: "20px"
  },
  subheader: {
    borderBottom: "none",
    height: "20px",
    backgroundColor: "#F5F6F7"
  },
  tableRow: {
    borderBottom: "none"
  },
  tableCell: {
    borderBottom: "none",
    paddingTop: "0px",
    paddingBottom: "0px"
  }
}))

interface Props {
  company: ICompany
  benefits: ICompanyBenefit[]
  fetchBenefits: (id: any) => void
}

const BenefitsAndPledges: React.FC<Props> = props => {
  const classes = useStyles()

  const { t } = useTranslation()

  const benefits = props.benefits
  const company = props.company
  const fetchBenefits = props.fetchBenefits

  useEffect(() => {
    if (company && !benefits) {
      fetchBenefits(company.id)
    }
  }, [company, benefits, fetchBenefits])

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          {t("benefits_pledges")}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("benefits")}</TableCell>
              <TableCell align="left">{t("description")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {benefits
              ? benefits.map(item => {
                  return <SingleBenefit key={item.id} benefit={item} />
                })
              : null}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => {
  return {
    benefits: state.companyReducer.benefits,
    company: state.companyReducer.company
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchBenefits: async (companyId: any) => dispatch(await companyActions.fetchCompanyBenefits(companyId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsAndPledges)
