import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  unrated: {
    // marginTop: "8px",
    // marginBottom: "4px",
    display: "flex",
    alignItems: "center"
  },
  rated: {
    display: "flex",
    alignItems: "center"
  },
  iconContainer: {
    position: "relative",
    height: "1px",
    width: "30px",
    display: "inline-flex",
    verticalAlign: "middle"
  },
  icon: {
    position: "absolute",
    left: "0",
    top: "-12px"
  },
  label: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  }
}))

export default useStyles
