import React from "react"

import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"

import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import { FormInputText } from "../../../FormInputText"

import api from "../../../../api/iskibris"
import HTTPResponses from "../../../../tools/http.responses"

import { ICompany } from "../../../../types/company"
import { flashActions } from "../../../../store/actions/flash-messaging"
import { companyActions } from "../../../../store/actions/company"

interface Props {
  company: ICompany
  type?: "billing-settings" | "invoice-request"
  invoice_request_information?: {
    company_id: number
    payment_invoice_uuid: string
    customer_uuid: string
    invoice_type: "physical" | "digital"
  }
  onSuccess?: () => void
}

const generateDefaultValues = (company: ICompany) => {
  return {
    invoice_organization_name: company?.invoice_organization_name ?? company?.name,
    registration_number: company?.registration_number,
    invoice_contact_first_name: company?.invoice_contact_first_name,
    invoice_contact_last_name: company?.invoice_contact_last_name,
    invoice_contact_phone: company?.invoice_contact_phone ?? company?.phone,
    invoice_email: company?.invoice_email ?? company?.email,
    invoice_address: company?.invoice_address ?? company?.address,
    invoice_additional_notes: company?.invoice_additional_notes
  }
}

const BillingSettingsForm: React.FC<Props> = props => {
  const { t } = useTranslation()
  const formRef = React.useRef<any>()

  const [loading, setLoading] = React.useState(false)

  const dispatch = useDispatch()

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({ defaultValues: generateDefaultValues(props.company) })

  const onSubmit = async (values: any) => {
    setLoading(true)

    if (props.type === "invoice-request") {
      const info = processInvoiceRequestValues(values)
      await submitInvoiceRequestToServer(info)
    } else {
      await submitBillingSettingsToServer({ ...values })
    }

    setLoading(false)
  }

  const processInvoiceRequestValues = (values: any) => {
    return {
      company_id: props.invoice_request_information?.company_id,
      company_name: values?.invoice_organization_name,
      company_email: values?.invoice_email,
      contact_person_name: `${values?.invoice_contact_first_name} ${values?.invoice_contact_last_name}`,
      contact_person_email: values?.invoice_email,
      company_telephone: values?.invoice_contact_phone,
      contact_person_phone: values?.invoice_contact_phone,
      registration_number: values?.registration_number,
      notes: values?.invoice_additional_notes,
      address: values?.invoice_address,
      payment_invoice_uuid: props.invoice_request_information?.payment_invoice_uuid,
      customer_uuid: props.invoice_request_information?.customer_uuid,
      invoice_type: props.invoice_request_information?.invoice_type ?? "physical",
      invoice_contact_first_name: values?.invoice_contact_first_name,
      invoice_contact_last_name: values?.invoice_contact_last_name,
      permissions: null
    }
  }

  const submitBillingSettingsToServer = async (values: any) => {
    await api
      .put(`/api/hiring/v2/companies/${props.company?.id}`, values)
      .then(async (response) => {
        handleValidResponse("billing_settings_update_success")
        dispatch(await companyActions.fetchSingleCompany(props.company?.id))
      })
      .catch((error: any) => {
        handleErrorResponse(error, "billing_settings_update_fail")
      })
  }

  const submitInvoiceRequestToServer = async (values: any) => {
    setLoading(true)
    const data = { ...values }

    await api
      .post(`/api/hiring/v2/companies/${props.company.id}/invoices/request`, data)
      .then((response) => {
        handleValidResponse("invoice_request_success")
      })
      .catch((error: any) => {
        handleErrorResponse(error, "invoice_request_fail")
      })
    setLoading(false)
  }

  const handleValidResponse = (messageKey: any) => {
    dispatch(flashActions.addFlashMessage(t(messageKey), "success"))
    if (props.onSuccess) {
      props.onSuccess()
    }
  }

  const handleErrorResponse = (error: any, messageKey: any) => {
    const errors = error?.response?.data?.errors

    if (errors) {
      const keys = Object.keys(errors)
      keys.map((item: any) => {
        setError(item, { message: errors[item][0], type: "validation" })
        return item
      })
    }

    if (error?.response?.status === HTTPResponses.FORBIDDEN) {
      const err = error.response.data
      setError("permissions", { message: err["error"], type: "billing" })
    }

    if (!error?.response) {
      dispatch(flashActions.addFlashMessage(t("network_error"), "error"))
      return
    }
    if (error?.response?.status === HTTPResponses.INTERNAL_SERVER_ERROR) {
      dispatch(flashActions.addFlashMessage(t("server_error_fail"), "error"))
      return
    }

    dispatch(flashActions.addFlashMessage(t(messageKey), "error"))
    return
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <div style={{ display: "flex" }}>
          <FormInputText errors={errors} control={control} name="invoice_organization_name" label={t("invoice_organization_name")} placeholder={t("invoice_organization_name")} />
          <FormInputText errors={errors} control={control} name="registration_number" label={t("registration_number")} placeholder={t("registration_number")} />
        </div>
        <div style={{ display: "flex" }}>
          <FormInputText
            errors={errors}
            control={control}
            name="invoice_contact_first_name"
            label={t("invoice_contact_first_name")}
            placeholder={t("invoice_contact_first_name")}
          />
          <FormInputText errors={errors} control={control} name="invoice_contact_last_name" label={t("invoice_contact_last_name")} placeholder={t("invoice_contact_last_name")} />
        </div>
        <div style={{ display: "flex" }}>
          <FormInputText errors={errors} control={control} name="invoice_contact_phone" label={t("invoice_contact_phone")} placeholder={t("invoice_contact_phone")} />
          <FormInputText errors={errors} control={control} name="invoice_email" label={t("invoice_email")} placeholder={t("invoice_email")} />
        </div>
        <FormInputText multiline rows={2} errors={errors} control={control} name="invoice_address" label={t("invoice_address")} placeholder={t("invoice_address")} />
        <FormInputText
          multiline
          rows={3}
          errors={errors}
          control={control}
          name="invoice_additional_notes"
          label={t("invoice_additional_notes")}
          placeholder={t("invoice_additional_notes")}
        />
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "15px" }}>
          <Button variant="outlined" color="primary" disabled>
            {t("cancel")}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {loading ? <CircularProgress size={22} style={{ color: "#FFFFFF" }} /> : t("submit")}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default BillingSettingsForm
