import React from "react"
import moment from "moment"

import { useTranslation } from "react-i18next"
import { Text, View, StyleSheet } from "@react-pdf/renderer"

import Section from "./Section"
import { IApplicant } from "../../../types/applicants"

interface Props {
  user: any
  applicant: IApplicant
}

const ApplicationDetails: React.FC<Props> = ({ user, applicant }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Section title={t("application_details")}>
        <View style={styles.container}>
          <Text style={styles.primaryText}>
            {applicant.job_title} - {t(`city_id_${applicant.job_city_id}`)}
          </Text>
          <Text style={styles.primaryText}>{moment(applicant.created_at ?? applicant.date).format("LL")}</Text>
          <Text style={styles.primaryText}>
            {t(`rating_id_${applicant.rating_id}`)} - {t(`stage_id_${applicant.stage_id}`)}
          </Text>
        </View>
      </Section>
    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  container: {
    width: "100%"
  },
  layout: {
    width: "100%"
  },
  singleSkillContainer: {
    width: "50%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    padding: "10px 0px",
    paddingRight: "5px"
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  primaryText: {
    fontSize: "12px"
  },
  secondaryText: {
    fontSize: "11px"
  }
})

export default ApplicationDetails
