import { makeStyles } from "@material-ui/core"

export default makeStyles(theme => ({
  root: {
    height: "100vh",
    width: "100vw"
  },
  main: {
    height: "100%",
    width: "100%",
    padding: theme.spacing(4, 4),
    display: "flex",
    justifyContent: "center"
  }
}))
