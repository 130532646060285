import React from "react"
import { makeStyles } from "@material-ui/core"

import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import HelpIcon from "@material-ui/icons/Help"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 3),
    // marginRight: "20px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px"
    }
  }
}))

interface Props {}

const NeedHelp: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Paper className={classes.paper} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <HelpIcon style={{ height: "35px", width: "35px" }} />
      <Typography variant="h6" style={{ textAlign: "center" }} gutterBottom>
        {t("need_help_employer_center")}
      </Typography>
      <Button variant="outlined" href={`${process.env.REACT_APP_JOB_SEEKER_BASE_URL}/contact`} target="_blank">
        {t("visit_employer_help")}
      </Button>
    </Paper>
  )
}

export default NeedHelp
