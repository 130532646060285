import React from "react"
import { useTranslation } from "react-i18next"

import { ICompany } from "../../../../../types/company"
import GenericFilter from "../GenericFilter"

import MaybeIcon from "@material-ui/icons/Help"
import InterestedIcon from "@material-ui/icons/CheckCircle"
import UnratedIcon from "@material-ui/icons/FiberManualRecord"
import NotInterestedIcon from "@material-ui/icons/CancelRounded"
import { IApplicantFilters, IApplicantSearchParams, IPaginatedApplicants } from "../../../../../types/applicants"

import useStyles from "../../styles"

interface Props {
  company: ICompany
  activeFilter: boolean
  filters: IApplicantFilters
  applicants: IPaginatedApplicants
  handleGoBack: () => void
  applyFilters: (filters: IApplicantSearchParams, page: number) => void
}

const options = [
  { value: 0, label: "", icon: <UnratedIcon className={"icon icon--unrated"} />, quantity: 0 },
  { value: 1, label: "", icon: <InterestedIcon className={"icon icon--interested"} />, quantity: 0 },
  { value: 2, label: "", icon: <MaybeIcon className={"icon icon--maybe"} />, quantity: 0 },
  { value: 3, label: "", icon: <NotInterestedIcon className={"icon icon--not-interested"} />, quantity: 0 }
]

const RatingsFilter: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  // React.useEffect(()=>{
  //   options.forEach(item => {
  //     item.label = t(`rating_id_${item.value}`);
  //     if(props.applicants){
  //       item.quantity = [...props.applicants.data].filter(applicant => applicant.rating_id === item.value).length;
  //     }
  //   })
  // }, [props.applicants, t])

  const handleSubmit = async (filters: any) => {
    await props.applyFilters(
      {
        ...filters,
        activeFilter: props.activeFilter,
        companyId: props.company.id,
      },
      1
    )
  }

  return (
    <div className={classes.singleFilterDiv}>
      <GenericFilter
        field="rating"
        options={options.map(item => ({
          value: item.value,
          label: t(`rating_id_${item.value}`),
          icon: item.icon,
          quantity: props.applicants ? props.applicants.data.filter(applicant => applicant.rating_id === item.value).length : 0
        }))}
        filters={props.filters}
        applicants={props.applicants}
        handleApplyClick={handleSubmit}
        handleGoBack={props.handleGoBack}
      />
    </div>
  )
}

export default RatingsFilter
