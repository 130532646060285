/* eslint-disable react/prop-types */
import React from "react"

import { isArray } from "lodash"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import DropdownArrow from "@material-ui/icons/ArrowDropDown"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"

import { IApplicantFilters } from "../../../../types/applicants"
import { ICompany } from "../../../../types/company"

import useStyles from "./styles"

import { PlaceSelectorWidget } from "innovia-component-library"
import { getPlaces } from "../../../../api/iskibris/iskibris-methods"
import { CircularProgress, Typography } from "@material-ui/core"

interface Props {
  company: ICompany
  activeFilter: boolean
  filters: IApplicantFilters
  applyFilters: (values: any, page: any) => Promise<void>
}

const PlaceFilter: React.FC<Props> = props => {

  const [loading, setLoading] = React.useState(false)

  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const {
    watch,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      place: props.filters.place
    }
  })

  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    watch("place")
  }, [watch])

  const handleClick = () => {
    setOpen(prev => !prev)
  }

  const handleClickAway = () => {
    setOpen(false)
    setValue('place', props.filters.place)
  }

  const handleApplyButtonClick = async () => {
    setLoading(true)
    let placeFilters = getValues().place

    placeFilters = placeFilters.map(item => typeof item === "number" ? item : item?.id)

    await props.applyFilters(
      {
        ...props.filters,
        activeFilter: props.activeFilter,
        companyId: props.company.id,
        place: placeFilters
      },
      1
    )
    setLoading(false)
    setOpen(false)
  }

  const handleFetchOptions = async (searchKeyword?: string | null, page?: number, perPage?: number) => {
    const response = await getPlaces(searchKeyword, page, perPage)
    const result = isArray(response.data) ? response.data : response?.data?.data
    return result
  }

  const getOptionLabel = (option: any) => {
    return option?.name ? option?.name[`name_${i18n.language}`] : ""
  }

  return (
    <div className={classes.root + " va-middle"}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classes.singleFilterDiv}>
          <Button onClick={handleClick} variant="outlined" size="small" color="default">
            {props.filters.place
              ? props.filters.place.length > 0
                ? `${t("location")}(${props.filters.place.length})`
                : `${t("location")}`
              : `${t("location")}`}
            <DropdownArrow />
          </Button>
          {open ? (
            <Paper className={classes.paper} elevation={3}>
              <form>
                <FormControl fullWidth style={{ padding: "10px" }}>
                  <PlaceSelectorWidget
                    multiple
                    required
                    fullWidth
                    margin="dense"
                    name="place"
                    errors={errors}
                    control={control}
                    placeholder={t("city_id_placeholder")}
                    getOptionLabel={getOptionLabel}
                    fetchOptions={handleFetchOptions}
                    onSelectOption={(option: any) => {setValue("place", option)}}
                  />
                </FormControl>
                <div style={{ width: "350px", display: "flex", justifyContent: "center" }}>
                  <Typography variant="caption" style={{textAlign: "center"}}>{t("search_and_select_locations")}</Typography>
                </div>
                <div className={classes.buttonArea}>
                  <Button color="primary" variant="outlined" onClick={handleClickAway}>
                    {t("cancel")}
                  </Button>
                  <Button color="primary" variant="contained" onClick={handleApplyButtonClick}>
                    {loading ? <CircularProgress size={22} /> : t("apply")}
                  </Button>
                </div>
              </form>
            </Paper>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  )
}

export default PlaceFilter
