import React from "react"
import { useHistory } from "react-router"
import { useTranslation } from "react-i18next"
import { Paper, Typography, Grid, Button, CardActions } from "@material-ui/core"

const NotFound = () => {
  const { t } = useTranslation()

  const history = useHistory()

  const handleClick = () => {
    history.push("/")
  }

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: "100vh" }}>
      <Grid item md={12} lg={8}>
        <Paper style={{ paddingBottom: "15px", margin: "10px" }}>
          <Typography variant="h4" color="primary" style={{ textAlign: "center", fontSize: "175px" }}>
            404
          </Typography>
          <Typography variant="h6" style={{ textAlign: "center", fontSize: "40px" }}>
            {t("page_not_found")}
          </Typography>
          <Typography variant="body1" style={{ textAlign: "center", width: "60%", margin: "auto" }} gutterBottom>
            {t("page_not_found_subtext")}
          </Typography>
          <CardActions style={{ justifyContent: "center", marginTop: "10px" }}>
            <Button variant="contained" color="secondary" style={{ borderRadius: 25 }} onClick={handleClick}>
              <Typography>{t("go_to_home")}</Typography>
            </Button>
          </CardActions>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default NotFound
