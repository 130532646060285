import React from "react"

import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import {  Paper, Grid } from "@material-ui/core"

import { flashActions } from "../../store/actions/flash-messaging"
import HTTPResponses from "../../tools/http.responses"
import { userActions } from "../../store/actions/user"


import { NotificationSettingsCenterWidget } from "innovia-component-library"
import { getNotificationSettings, updateNotificationSettings } from "../../api/iskibris/iskibris-methods"
import { notificationSettings } from "../../utils/data/notification_settings"


interface Props { }


const NotificationSettingsManagement: React.FC<Props> = () => {
  const { t } = useTranslation()

  const user = useSelector((state: any) => state.userReducer.profile)
  const [userNotificationSettings, setUserNotificationSettings] = React.useState<any>(null)

  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchUser = async () => {
      dispatch(await userActions.fetchProfile())
    }

    if (!user) {
      fetchUser()
    }
  }, [dispatch, user])

  const fetchUserNotificationSettings = () => {
    getNotificationSettings()
      .then(response => {
        setUserNotificationSettings(response.data)
      })
      .catch(error => {
        console.log("Error fetching user notification settings: ", error)
      })
  }

  React.useEffect(() => {
    if(!userNotificationSettings){
      fetchUserNotificationSettings()
    }
  }, [userNotificationSettings])

  const getNotificationOptionChannelsData = (channels: any) => {
    let result = {}
    for (let index = 0; index < channels.length; index++) {
      const element = channels[index];
      result = { ...result, [element.name]: element.value }
    }
    return result
  }

  const getNotificationCategoryOptionsSubmissionData = (options: any) => {
    let result = {}
    for (let index = 0; index < options.length; index++) {
      const element = options[index]
      result = { ...result, [element.name]: getNotificationOptionChannelsData(element?.channels) }
    }
    return result
  }


  const handleSubmit = async (data: any) => {
    let settings = userNotificationSettings?.settings ? {...userNotificationSettings.settings} : {}

    for (let index = 0; index < notificationSettings.length; index++) {
      const element = notificationSettings[index]

      if(settings[element.name]){
        continue
      }

      settings = {
        ...settings,
        [element.name]: getNotificationCategoryOptionsSubmissionData(element.options)
      }
    }

    settings = {
      ...settings,
      [data.category]: {
        ...settings[data.category],
        [data.setting]: {
          ...settings[data.category][data.setting],
          [data.channel]: data.value
        }
      }
    }

    await updateNotificationSettings({settings: settings})
      .then(response => {
        fetchUserNotificationSettings()
        dispatch(
          flashActions.addFlashMessage(t("notification_settings_update_success"), "success")
        )
      })
      .catch(err => {
        console.log("Error updating notification settings: ", err)
        if (err?.response?.status === HTTPResponses.INTERNAL_SERVER_ERROR) {
          dispatch(
            flashActions.addFlashMessage(t("server_fail_error"), "error")
          )
        } else {
          dispatch(
            flashActions.addFlashMessage(t("notification_settings_update_fail"), "error")
          )
        }
      })
  }

  const checkIsValueSet = (value: any) => {
    return value !== null && value !== undefined
  }

  const generateOptions = () => {
    return notificationSettings.map(item => {
      const settings = userNotificationSettings?.settings

      let currentCategorySettings: any;

      if(settings){
        currentCategorySettings = settings[item.name]
      }
      
      const populatedCategoryOptions = item.options.map(itemOption => {
        const option = {
          ...itemOption,
          title: t(`notification_settings_option_title_id_${itemOption.name}`),
          subtitle: t(`notification_settings_option_subtitle_id_${itemOption.name}`),
          description: t(`notification_settings_option_description_id_${itemOption.name}`),
        }

        let currentOptionSettings: any;

        
        if(currentCategorySettings){
          currentOptionSettings = currentCategorySettings[itemOption.name]
        }
        
        const currentItemOptionChannels = itemOption.channels.map(itemOptionChannel => {
          return {
            ...itemOptionChannel,
            title: t(`notification_channel_title_id_${itemOptionChannel.name}`),
            subtitle: t(`notification_channel_subtitle_id_${itemOptionChannel.name}`),
            value: currentOptionSettings && checkIsValueSet(currentOptionSettings[itemOptionChannel.name]) ? currentOptionSettings[itemOptionChannel.name] : itemOptionChannel.value
          }
        })

        return {
          ...option,
          channels: currentItemOptionChannels
        }
      })

      return {
        ...item,
        title: t(`notification_settings_category_title_id_${item.name}`),
        options: populatedCategoryOptions
      }
    })
  }



  return (
    <div>

      <Grid container>
        <Grid item xs={12} md={12} lg={9}>
          <Paper className="pt-3 pb-3" style={{padding: "1.5rem 0rem"}}>
            <NotificationSettingsCenterWidget
              title={t("notification_settings_title")}
              description={t("notification_settings_center_description")}
              onSubmit={handleSubmit}
              options={generateOptions()}
            />
          </Paper>
          </Grid>
      </Grid>
    </div>
  )
}

export default NotificationSettingsManagement