import React from "react"
import Button from "@material-ui/core/Button"
import { useTranslation } from "react-i18next"

const LanguageSelector: React.FC = () => {
  const { t, i18n } = useTranslation()

  const handleChange = (lng: string) => {
    i18n.changeLanguage(lng)
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Button
        color={i18n.language === "tr" ? "secondary" : "primary"}
        onClick={() => {
          handleChange("tr")
        }}
      >
        {t("language_label_tr")}
      </Button>
      <span>|</span>
      <Button
        color={i18n.language === "en" ? "secondary" : "primary"}
        onClick={() => {
          handleChange("en")
        }}
      >
        {t("language_label_en")}
      </Button>
    </div>
  )
}

export default LanguageSelector
