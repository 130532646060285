import React from "react"

import clsx from "clsx"
import Cookies from "js-cookie"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"

import Pusher from "pusher-js"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Tooltip from "@material-ui/core/Tooltip"
import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import CssBaseline from "@material-ui/core/CssBaseline"
import useTheme from "@material-ui/core/styles/useTheme"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ImportExportIcon from "@material-ui/icons/ImportExport"
import CircularProgress from "@material-ui/core/CircularProgress"

import { IActionType } from "../../types"
import NavBar from "../../components/NavBar/NavBar"
import SideBar from "../../components/SideBar"
import Copyrights from "../../components/Copyright"

import useStyles from "./styles"
import { ICompany } from "../../types/company"
import { applicantsActions } from "../../store/actions/applicants"

import { IApplicant, IApplicantFilters, IPaginatedApplicants } from "../../types/applicants"

import SortBy from "../../components/ApplicantManagement/SortBy"
import Messaging from "../../components/ApplicantManagement/Messaging"
import MessageAll from "../../components/ApplicantManagement/MessageAll"
import UserSideMenu from "../../components/MobileComponents/UserSideMenu"
import ApplicantsList from "../../components/ApplicantManagement/ApplicantsList"
import ExportApplicants from "../../components/ApplicantManagement/ExportApplicants"
import ApplicantFilters from "../../components/ApplicantManagement/ApplicantFilters"
import ApplicantDetails from "../../components/ApplicantManagement/ApplicantDetails"
import MobileNavigation from "../../components/ApplicantManagement/MobileNavigation"
import MobileComponents from "../../components/ApplicantManagement/MobileComponents"

import useGtm from "../../hooks/useGtm"
import { IUser } from "../../types/user"
import ManualApplication from "../../components/ApplicantManagement/ManualApplication"

const options = {
  broadcaster: "pusher",
  key: process.env.REACT_APP_PUSHER_KEY || "",
  cluster: "eu",
  forceTLS: true,
  authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: `Bearer ${Cookies.get("auth_token")}`,
      Accept: "application/json"
    }
  }
}

interface Props {
  company: ICompany
  currentUser: IUser
  searchOpen: boolean
  sendMessage: boolean
  filters: IApplicantFilters
  singleApplicant: IApplicant
  applicants: IPaginatedApplicants
  clearApplicants: () => void
  addSingleApplicant: (info: any) => void
  // openSingleApplicant: (info: any) => void
  fetchActiveApplicants: (companyId: any) => void
  fetchSingleApplicant: (applicantId: any) => void
}

const ApplicantsManagement: React.FC<Props> = props => {
  const params: { id?: any } = useParams()
  const history = useHistory()

  const checkFiltersAvailable = (skipKeyword?: boolean) => {
    if (
      props.filters.rating?.length > 0 ||
      props.filters.stage?.length > 0 ||
      props.filters.city?.length > 0 ||
      props.filters.job?.length > 0 ||
      props.filters.field_of_study?.length > 0 ||
      props.filters.work_permit?.length > 0 ||
      props.filters.education_level?.length > 0 ||
      props.filters.gender?.length > 0 ||
      props.filters.age?.length > 0 ||
      props.filters.driver_licence?.length > 0 ||
      (skipKeyword ? false : !!props.filters.keyword)
    ) {
      return true
    } else {
      return false
    }
  }

  const classes = useStyles({ ...props, checkFiltered: checkFiltersAvailable })

  const { t } = useTranslation()

  const { tagEvent } = useGtm()
  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const { company, applicants, currentUser, singleApplicant, addSingleApplicant, fetchSingleApplicant, fetchActiveApplicants } = props

  const authToken = Cookies.get("auth_token")

  React.useEffect(() => {
    document.title = t("applicants_management_title")
  })

  React.useEffect(() => {
    tagEvent({
      event: "view_applicants_page",
      data: { company_name: company.name, user_name: currentUser?.full_name }
    })
  }, [company, currentUser, tagEvent])

  React.useEffect(() => {
    let pusher: Pusher | null
    let channelStr: any

    if (company && authToken && !applicants) {
      fetchActiveApplicants(company.id)

      pusher = new Pusher(options.key, {
        cluster: options.cluster,
        forceTLS: options.forceTLS,
        authEndpoint: options.authEndpoint,
        auth: {
          ...options.auth,
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json"
          }
        }
      })

      channelStr = `private-companies.${company.id}.applications`

      let aChannel = pusher.subscribe(channelStr)
      aChannel.bind("new-application-submitted", function (data: any) {
        addSingleApplicant(data)
      })
    }

    return () => {
      if (company && authToken && !!pusher && !!channelStr) {
        pusher.disconnect()
      }
    }
  }, [company, applicants, authToken, addSingleApplicant, fetchActiveApplicants])

  React.useEffect(() => {
    const paramId = params.id
    if (applicants && applicants.data.length > 0) {
      let appId: any
      if (paramId) {
        appId = parseInt(paramId)
      } else {
        const firstApplicant = applicants.data?.find(item => item.id && item.id > 0)
        if (firstApplicant) {
          appId = firstApplicant.id
        }
      }
      if (appId && singleApplicant?.id !== appId) {
        fetchSingleApplicant(appId)
      }
    }
  }, [applicants, params, singleApplicant, fetchSingleApplicant])

  const loader = (
    <div className={classes.rootLoader}>
      <CircularProgress />
    </div>
  )

  let noApplications = checkFiltersAvailable() ? (
    <div className={classes.noApplicantsContainer}>
      <Typography variant="body1">{t("no_applicants_match_filter")}</Typography>
    </div>
  ) : (
    <div className={classes.noApplicantsContainer}>
      <Typography variant="body1">{t("no_applicants_for_company")}</Typography>
      <div style={{ width: "100%", marginTop: "10px" }}>
        <Tooltip title={`${t("no_applicants_job_button_tooltip")}`}>
          <Button
            variant="outlined"
            color="secondary"
            style={{ margin: "10px" }}
            onClick={() => {
              history.push("/jobs")
            }}
          >
            {t("add_jobs")}
          </Button>
        </Tooltip>
        <Tooltip title={`${t("no_applicants_employer_button_tooltip")}`}>
          <Button
            variant="outlined"
            color="secondary"
            style={{ margin: "10px" }}
            onClick={() => {
              history.push("/employer/profile")
            }}
          >
            {t("employer_profile")}
          </Button>
        </Tooltip>
      </div>
    </div>
  )

  return (
    <div>
      <CssBaseline />
      <NavBar />
      <SideBar />
      {matchesMobile ? <UserSideMenu /> : null}
      <main className="content content--has-sidebar" style={{ paddingTop: "62px", paddingBottom: "5px" }}>
        <div className={classes.header}>
          <Typography variant="h5" className={"page-title"}>
            {t("applicants_management")}
          </Typography>
          <div style={{ display: "flex" }}>
            <MessageAll />
            <ExportApplicants />
            <ManualApplication />
          </div>
        </div>
        <Divider />
        <Paper style={{ height: "calc(100vh - 140px)" }}>
          <ApplicantFilters />
          <Divider />
          <Grid container className={classes.gridContainer}>
            {!props.applicants ? (
              loader
            ) : props.applicants.data.length > 0 ? (
              <React.Fragment>
                <Grid
                  item
                  className={clsx(classes.leftGridItem, {
                    [classes.leftGridItemClosed]: !!params.id // double negative to convert this to boolean
                  })}
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  xl={3}
                >
                  <ListItem className={classes.sortByContainer}>
                    <React.Fragment>
                      <ListItemIcon style={{ minWidth: 30 }}>
                        <ImportExportIcon />
                      </ListItemIcon>
                      <ListItemText style={{ fontSize: "12px" }}>{t("sort_by", ": ")}:</ListItemText>
                      <SortBy />
                    </React.Fragment>
                  </ListItem>
                  {matchesMobile ? <MobileComponents /> : null}
                  <ApplicantsList applicants={props.applicants} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={9}
                  xl={9}
                  className={clsx(classes.rightGridItem, {
                    [classes.rightGridItemClosed]: !params.id // true when there is no id parameter in the url
                  })}
                >
                  {props.singleApplicant ? (
                    <React.Fragment>
                      {matchesMobile ? <MobileNavigation applicants={props.applicants} singleApplicant={props.singleApplicant} /> : null}
                      <ApplicantDetails />
                    </React.Fragment>
                  ) : (
                    <div style={{ justifyContent: "center", display: "flex" }}>
                      <CircularProgress />
                    </div>
                  )}
                </Grid>
              </React.Fragment>
            ) : (
              noApplications
            )}
          </Grid>
        </Paper>
        {props.sendMessage ? <Messaging /> : null}
      </main>
      <footer>
        <Copyrights />
      </footer>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  singleApplicant: state.applicantsReducer.singleApplicant,
  sendMessage: state.messagesReducer.sendMessage,
  applicants: state.applicantsReducer.applicants,
  searchOpen: state.applicantsReducer.searchOpen,
  filters: state.applicantsReducer.filters,
  currentUser: state.userReducer.profile,
  company: state.companyReducer.company
})

const mapDispatchtoProps = (dispatch: Dispatch<IActionType>) => ({
  fetchActiveApplicants: async (id: any) => dispatch(await applicantsActions.fetchActiveApplicants(id)),
  fetchSingleApplicant: async (id: any) => dispatch(await applicantsActions.fetchSingleApplicant(id)),
  // openSingleApplicant: (info: any) => dispatch(applicantsActions.openSingleApplicant(info)),
  addSingleApplicant: (info: any) => dispatch(applicantsActions.addSingleApplicant(info)),
  clearApplicants: () => dispatch(applicantsActions.clearApplicants())
})

export default connect(mapStateToProps, mapDispatchtoProps)(ApplicantsManagement)
