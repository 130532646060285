import React from "react"

import { useTranslation } from "react-i18next"

import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"

import useStyles from "./styles"
import { useDispatch, useSelector } from "react-redux"

import { Button, Fade, Modal, Backdrop, CircularProgress } from "@material-ui/core"

import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import { applicantsActions } from "../../../store/actions/applicants"
import SingleFolder from "./SingleApplicantFolder"
import FolderForm from "../FolderForm"
import { deleteCompanyFolders } from "../../../api/iskibris/iskibris-methods"
import { flashActions } from "../../../store/actions/flash-messaging"

interface Props {
  children?: React.ReactNode
}

const ManageApplicantFolders : React.FC<Props> = ({ children }) => {
  
  const {t} = useTranslation()

  const [open, setOpen] = React.useState(false)

  const [editingFolderId, setEditingFolderId] = React.useState<any>(null)

  const dispatch = useDispatch()
  const company = useSelector((state: any) => state.companyReducer.company)

  const folders = useSelector((state: any) => state.applicantsReducer.folders)

  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
    fetchFolders()
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const fetchFolders = async () => {
    dispatch(await applicantsActions.fetchCompanyFolders(company?.id))
  }

  const handleOptionDelete = async (id: any) => {
    await deleteCompanyFolders(id)
      .then(async (response) => {
        dispatch(await applicantsActions.fetchCompanyFolders(company?.id))
        dispatch( flashActions.addFlashMessage(t(`folder_delete_success`), "success") )
      })
      .catch((error: any) => {
        dispatch( flashActions.addFlashMessage(t(`folder_delete_fail`), "error") )
      })
  }

  const handleOptionEdit = (id: any) => {
    setEditingFolderId(id)
  }

  const handleCloseEdit = () => {
    setEditingFolderId(null)
  }

  const getOptions = (option: any) => [
    {
      title: t("edit"),
      handler: () => {handleOptionEdit(option?.id)}
    },
    { 
      title: t("delete"),
      handler: () => {handleOptionDelete(option?.id)}
    }
  ]

  return (
    <div>
      <div onClick={handleOpen}>
        {
          children
          ?
          (
            <>{children}</>
          )
          :
          (
            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", marginTop: "1rem"}}>
              <Button color="primary" style={{marginRight: "0.5rem", textDecoration: "underline"}} onClick={handleOpen}>
                {t("manage_folders")}
              </Button>
            </div>
          )
        }
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.modalPaper}>
            <div className={classes.modalCloseContainer}>
              <Typography className={classes.modalTypographyTitle} variant="h6" gutterBottom>
                {t(`applicant_folders`)}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseRoundedIcon />
              </IconButton>
            </div>
            <Typography className={classes.modalTypographyCaption} variant="body2" style={{marginBottom: "1rem"}} gutterBottom>
              {t(`manage_applicant_folder_subtext`)}
            </Typography>
            <div>
              {
                folders ? (
                  folders?.length > 0 ? (
                    folders.map((item: any) => {
                      if(item?.id === editingFolderId){
                        return <FolderForm type="edit" folderId={item?.id} defaultValues={{...item}} onCancel={handleCloseEdit} onSuccess={handleCloseEdit} />
                      }
                      return <SingleFolder folder={item} menuOptions={getOptions(item)}/>
                    })
                  ) : (
                    <div>
                      <Typography variant="body2" style={{marginTop: "1rem"}}>{t("folders_empty")}</Typography>
                    </div>
                  )
                ) : (
                  <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <CircularProgress />
                  </div>
                )
              }
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )

}

export default ManageApplicantFolders