import React from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import Menu from "@material-ui/core/Menu"
import Button from "@material-ui/core/Button"
import MenuItem from "@material-ui/core/MenuItem"
// import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from "@material-ui/core/ListItemIcon"
// import ListItemText from '@material-ui/core/ListItemText';

// import ImportExportIcon from "@material-ui/icons/ImportExport";
import KeyBoardDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { Dispatch } from "redux"
import { IActionType } from "../../../types"
import { applicantsActions } from "../../../store/actions/applicants"
import { useHistory } from "react-router"
import { IApplicantFilters } from "../../../types/applicants"
import { ICompany } from "../../../types/company"

import OverlayLoader from "../../OverlayLoader"
import { Sort } from "@material-ui/icons"
import { IconButton } from "@material-ui/core"

const options = [
  { id: 0, orderBy: "created_at", orderDirection: "desc" },
  { id: 1, orderBy: "created_at", orderDirection: "asc" },
  { id: 2, orderBy: "name", orderDirection: "asc" },
  { id: 3, orderBy: "name", orderDirection: "desc" },
  { id: 4, orderBy: "job_title", orderDirection: "asc" },
  { id: 5, orderBy: "job_title", orderDirection: "desc" }
]

interface Props {
  company: ICompany
  activeFilter: boolean
  filters: IApplicantFilters
  variant?: "detailed" | "dense"
  applyFilters: (filters: any, page: number, orderBy?: string | null, orderDirection?: string | null) => void
}

const SortBy: React.FC<Props> = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const history = useHistory()

  const [loading, setLoading] = React.useState(false)
  const [selectedSort, setSelectedSort] = React.useState(0)

  const orderBy = useSelector((state: any) => state.applicantsReducer.orderBy)
  const orderDirection = useSelector((state: any) => state.applicantsReducer.orderDirection)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOptionSelect = async (option: any) => {
    setSelectedSort(option.id)
    setLoading(true)
    dispatch(applicantsActions.changeOrderByValues(option.orderBy, option.orderDirection))
    await applyFilters(props.filters, 1, option.orderBy, option.orderDirection)
    setLoading(false)
    handleClose()
  }

  const applyFilters = async (filters: any, page: number, orderBy: string | null = "created_at", orderDirection: string | null = "desc") => {
    if (history.location.pathname !== "/applicants") {
      history.push("/applicants")
    }
    await props.applyFilters(
      {
        rating: filters.rating || [],
        stage: filters.stage || [],
        stage_enum: filters.stage_enum || [],
        activeFilter: props.activeFilter,
        city: filters.city || [],
        companyId: props.company.id,
        gender: filters.gender || [],
        job: filters.job || [],
        education_level: filters.education_level || [],
        field_of_study: filters.field_of_study || [],
        keyword: filters.keyword,
        work_permit: filters.work_permit || [],
        age: filters.age || [],
        driver_licence: filters.driver_licence || []
      },
      1,
      orderBy,
      orderDirection
    )
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      {
        props.variant === "dense"
        ?
        (
          <IconButton onClick={handleClick} color="primary">
            <Sort />
          </IconButton>
        )
        :
        (
          <Button variant="outlined" color="primary" style={{ padding: "3px", paddingTop: "5px", paddingBottom: "5px" }} onClick={handleClick}>
            {t(`sort_id_${selectedSort}`)}
            <ListItemIcon style={{ minWidth: "0" }}>
              <KeyBoardDownIcon />
            </ListItemIcon>
          </Button>
        )
      }
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200
          }
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option.id}
            value={option.id}
            onClick={() => {
              handleOptionSelect(option)
            }}
            selected={option.orderBy === orderBy && option.orderDirection === orderDirection}
          >
            {t(`sort_id_${option.id}`)}
          </MenuItem>
        ))}
      </Menu>
      {loading && (
        <OverlayLoader
          open={loading}
          handleClose={() => {
            setLoading(false)
          }}
        />
      )}
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => ({
  company: state.companyReducer.company,
  filters: state.applicantsReducer.filters,
  applicants: state.applicantsReducer.applicants,
  activeFilter: state.applicantsReducer.activeFilter
})

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => ({
  applyFilters: async (filters: any, page: number, orderBy: string | null = "created_at", orderDirection: string | null = "desc") =>
    dispatch(await applicantsActions.applyFilters(filters, page, orderBy, orderDirection))
})

export default connect(mapStateToProps, mapDispatchToProps)(SortBy)
