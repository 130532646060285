import React from "react"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import FaqForm from "./FaqForm"
import useStyles from "./styles"
import { IActionType } from "../../../types"
import { ICompany, ICompanyFAQ } from "../../../types/company"
import { companyActions } from "../../../store/actions/company"
import SingleFaq from "./SingleFaq"

interface Props {
  company: ICompany
  faqs: ICompanyFAQ[]
  fetchFaqs: (id: any) => void
}

const CompanyFaqs: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { faqs, company, fetchFaqs } = props

  React.useEffect(() => {
    if (company && !faqs) {
      fetchFaqs(company.id)
    }
  }, [company, faqs, fetchFaqs])

  return (
    <div>
      <Grid container>
        <Grid item sm={12} md={9} lg={7}>
          <Paper className={classes.paper}>
            <div>
              <Typography variant="h6" gutterBottom>
                {t("add_company_faq")}
              </Typography>
              <Typography variant="body2" gutterBottom className={classes.subtitleText}>
                {t("add_company_faq_text")}
              </Typography>
              <FaqForm />
            </div>
          </Paper>
          {faqs
            ? faqs.map(item => {
                return (
                  <Paper key={item.id} className={classes.paper} style={{ marginTop: "20px" }}>
                    <div>
                      <SingleFaq faq={item} company={company} />
                    </div>
                  </Paper>
                )
              })
            : null}
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company,
    faqs: state.companyReducer.faqs
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    fetchFaqs: async (companyId: any) => dispatch(await companyActions.fetchCompanyFaqs(companyId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyFaqs)
