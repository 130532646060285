import React from "react"
import { CircularProgress, IconButton, Link, Typography } from "@material-ui/core"
import {
  CancelRounded,
  CheckCircleOutline,
  ThumbDownAltOutlined,
  ThumbUpOutlined
} from "@material-ui/icons"

import { NavLink } from "react-router-dom"

interface ItemProps {
  title: string
  error?: boolean
  subtitle: string
  success?: boolean
  errorMessage?: string
  successMessage?: string
  tellUsMoreUrl?: string
  tellUsMoreMessage?: string
  errorBody?: React.ReactChild
  successBody?: React.ReactChild
  onSelected?: (value: number) => void | Promise<void>
}

const SurveyQuestionWidget: React.FC<ItemProps> = ({
  title,
  subtitle,
  success,
  error,
  successMessage,
  errorMessage,
  tellUsMoreUrl,
  tellUsMoreMessage,
  errorBody,
  successBody,
  onSelected
}) => {
  const [loading, setLoading] = React.useState<any>(null)
  const [submitted, setSubmitted] = React.useState<any>(null)

  const handleSubmit = (value: any) => async (event: any) => {
    setLoading(value)
    if (onSelected) {
      await onSelected(value)
    }
    setLoading(null)
    setSubmitted(true)
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
      }}
    >
      {submitted ? (
        <React.Fragment>
          {success &&
            (successBody ?? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h6">{successMessage}</Typography>
                  <NavLink to={tellUsMoreUrl ?? "#"}>
                    <Link color="secondary" style={{ marginLeft: "1rem", marginTop: "0.25rem" }}>
                      {tellUsMoreMessage}
                    </Link>
                  </NavLink>
                </div>
                <CheckCircleOutline fontSize="large" color="secondary" />
              </div>
            ))}
          {error &&
            (errorBody ?? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h6">{errorMessage}</Typography>
                </div>
                <CancelRounded fontSize="large" style={{ color: "red" }} />
              </div>
            ))}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body2" color="textSecondary">
              {subtitle}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            {[0, 1].map(item => (
              <React.Fragment>
                {loading === item ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <IconButton
                    size="medium"
                    key={item}
                    color="secondary"
                    style={{
                      margin: "5px",
                      border: "1px solid"
                    }}
                    onClick={handleSubmit(item)}
                  >
                    {item === 1 ? <ThumbUpOutlined /> : <ThumbDownAltOutlined />}
                  </IconButton>
                )}
              </React.Fragment>
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default SurveyQuestionWidget
