import axios from "../../api/iskibris/iskibris"
import { AxiosResponse } from "axios"
import { action } from "typesafe-actions"
import { actionTypes } from "./actionTypes"
import { fetch } from "../../api/iskibris/redux-api-methods"

export const userActions = {
  fetchProfile: async () => {
    return await fetch({
      url: `/api/user/profile`,
      onSuccessDispatch: actionTypes.ADD_PROFILE,
      onErrorDispatch: actionTypes.ADD_PROFILE_ERROR
    })
  },
  fetchSystemUsers: async () => {
    return await fetch({
      url: `/api/hiring/v2/users`,
      onSuccessDispatch: actionTypes.ADD_SYSTEM_USERS,
      onErrorDispatch: actionTypes.ADD_COMPANY_USERS_ERROR
    })
  },
  updateProfile: async (infor: {}) => {
    let information = { ...infor }
    let response: AxiosResponse
    try {
      response = await axios.post("/api/user/profile/update", information)
    } catch (error: any) {
      response = error?.response
    }
    if (response?.status < 400) {
      return userActions.fetchProfile()
    } else {
      return action(actionTypes.ADD_COMPANY_ACTION_ERRORS, response?.data?.errors)
    }
  }
}
