import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxWidth: "900px",
    width: "100%",
    outline: "none",
    position: "relative",
    maxHeight: "100%",
    overflowY: "auto"
  },
  mainReceiptContainer: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "column"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none"
  },
  modalCloseContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: 0
  },
  receiptContainerHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  receiptHeaderText: {
    marginTop: "2.5px",
    marginBottom: "2.5px"
  },
  logoContainer: {
    background: theme.palette.primary.main,
    padding: "5px 20px 0px 20px",
    borderRadius: "5px",
    marginBottom: "5px"
  },
  logo: {
    height: "30px"
  },
  iconButton: {
    padding: "5px"
  },
  detailsContainer: {
    padding: "35px",
    paddingBottom: "0px"
  },
  productInformationContainer: {
    display: "flex",
    marginTop: "5px",
    marginBottom: "10px"
  },
  paymentInformationContainer: {
    display: "flex",
    marginBottom: "10px"
  },
  clientInformationContainer: {
    marginBottom: "15px"
  },
  contactInformation: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  printReceiptContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "15px"
  },
  printReceiptButton: {
    marginTop: "10px"
  },
  halfWidth: {
    width: "50%"
  },
  displayFlex: {
    display: "flex"
  }
}))

export default useStyles
