import React from "react"
import Moment from "react-moment"
import Parser from "html-react-parser"

import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import CircularProgress from "@material-ui/core/CircularProgress"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import CompanyPlaceholder from "../../../../assets/images/placeholder-company.png"

import { ICompany, ICompanyUpdate } from "../../../../types/company"

import useStyles from "../styles"
import { Avatar } from "@material-ui/core"
import LongMenu from "../../../LongMenu"
import { useTranslation } from "react-i18next"
import MultiLangTextEditor from "../../../MultiLangTextEditor"
import { useDispatch } from "react-redux"
import { companyActions } from "../../../../store/actions/company"
import parseStringLocales from "../../../../utils/parseStringLocales"

interface Props {
  company: ICompany
  update: ICompanyUpdate
}

const CompanyUpdate: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [updateStatus, setUpdateStatus] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [updateBody, setUpdateBody] = React.useState("")

  const handleClose = () => {
    setUpdateStatus(false)
  }

  const handlePost = async () => {
    setSubmitting(true)
    await dispatch(await companyActions.updateCompanyUpdate({ body: updateBody }, props.update.id))
    setSubmitting(false)
    handleClose()
  }

  const handleDelete = async () => {
    setSubmitting(true)
    await dispatch(await companyActions.deleteCompanyUpdate(props.update.id))
    setSubmitting(false)
    handleClose()
  }

  const handleEdit = () => {
    setUpdateBody(props.update.body)
    setUpdateStatus(true)
  }

  return (
    <Paper className={classes.paper} style={{ marginTop: "20px" }}>
      <div>
        <ListItem>
          <ListItemAvatar>
            <Avatar src={props.company.logo || CompanyPlaceholder} alt={props.company.name} className={classes.companyLogo} />
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="body1" className={classes.companyNameText}>
              {props.company.name}
            </Typography>
            <Typography variant="body2">
              <Moment fromNow>{props.update.updated_at}</Moment>
            </Typography>
          </ListItemText>
          <ListItemSecondaryAction>
            <LongMenu
              options={[
                { title: t("edit"), handler: handleEdit },
                { title: t("delete"), handler: handleDelete }
              ]}
            />
          </ListItemSecondaryAction>
        </ListItem>
        {!updateStatus ? (
          <Typography variant="body1" component="span">
            {Object.keys(parseStringLocales(props.update.body)).map(item => {
              const body: any = parseStringLocales(props.update.body)
              const singleLocaleTranslation = body[item]
              if (singleLocaleTranslation) {
                return <React.Fragment key={Math.random()}>{Parser(singleLocaleTranslation)}</React.Fragment>
              }
              return <React.Fragment key={Math.random()} />
            })}
          </Typography>
        ) : (
          <div>
            <MultiLangTextEditor value={updateBody} handleChange={setUpdateBody} />
            <div style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
              <Button color="primary" variant="contained" onClick={handlePost}>
                {submitting ? <CircularProgress size={22} style={{ color: "#fff" }} /> : t("submit")}
              </Button>
              <Button color="primary" variant="outlined" onClick={handleClose}>
                {t("cancel")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Paper>
  )
}

export default CompanyUpdate
