import React from "react"
import { useTranslation } from "react-i18next"

import Page from "../../components/layout/Page/Page"
import Pricing from "../../components/Pricing"

import Typography from "@material-ui/core/Typography"

interface Props {}

const PricingPage: React.FC<Props> = props => {
  const { t } = useTranslation()

  return (
    <Page>
      <Typography variant="h6">{t("packages")}</Typography>
      <Pricing />
    </Page>
  )
}

export default PricingPage
