/* eslint-disable react/prop-types */
import React from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import avatarPlaceholder from "../../../assets/images/avatar.jpg"
import { useTranslation } from "react-i18next"
import axios from "../../../api/iskibris/iskibris"
import Cookies from "js-cookie"

import { authActions } from "../../../store/actions/authentication"
import { ClickAwayListener } from "@material-ui/core"
import { IUser } from "../../../types/user"
import * as Constants from "../../../config/constants"

interface Props {
  user: IUser
  unauthenticate: () => void
}

const UserMenu: React.FC<Props> = props => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mouseOverButton, setMouseOverButton] = React.useState(false)
  const [mouseOverMenu, setMouseOverMenu] = React.useState(false)

  let open = mouseOverButton || mouseOverMenu

  let history = useHistory()

  // const handleUnsubscribe = async () => {
  //   if(localEndpoint){
  //     await axios.post("/api/hiring/v2/notifications/subscriptions/delete", {endpoint: localEndpoint})
  //       .then(response => {
  //         localStorage.removeItem("subscription_endpoint");
  //       });
  //   }
  // }

  const logout = async () => {
    props.unauthenticate()
    // await handleUnsubscribe();
    await axios.post("/api/logout")
    localStorage.removeItem("auth_token")
    localStorage.removeItem("refresh_token")
    localStorage.removeItem("current_company_id")
    localStorage.removeItem("CompanyToken")
    Cookies.remove("auth_token", { domain: Constants.APP_DOMAIN })
    Cookies.remove("CompanyToken")
    setTimeout(function () {
      history.push("/login")
    }, 500)
  }

  const handleClose = () => {
    setMouseOverButton(false)
    setMouseOverMenu(false)
  }

  const enterButton = (event: any) => {
    setMouseOverButton(true)
    setAnchorEl(event.currentTarget)
  }

  const enterMenu = () => {
    setMouseOverMenu(true)
  }

  const leaveMenu = () => {
    setMouseOverMenu(false)
    setMouseOverButton(false)
  }

  const toggleUserMenu = () => {
    if (mouseOverMenu) {
      setMouseOverMenu(false)
    } else {
      setMouseOverMenu(true)
    }
  }

  const handleBillingClick = () => {
    history.push("/billing")
  }

  const handleAccountSettingsClick = () => {
    history.push("/settings")
  }

  const handleUserAccountSettingsClick = () => {
    history.push("/user/settings/account")
  }

  const handleUserNotificationsSettingsClick = () => {
    history.push("/user/settings/notifications")
  }

  const { t } = useTranslation()

  return (
    <ClickAwayListener
      onClickAway={() => {
        setMouseOverButton(false)
        setMouseOverMenu(false)
      }}
    >
      <div className={"user-menu"}>
        <Button
          className={"user-menu__avatar-button"}
          // aria-owns={open ? "user-menu" : null}
          aria-haspopup="true"
          onMouseEnter={enterButton}
          // onMouseLeave={leaveButton}
          onClick={toggleUserMenu}
        >
          <Avatar alt="Remy Sharp" src={props.user?.avatar_url || avatarPlaceholder} className={"user-menu__avatar"} />
          <Typography style={{ color: "#fff" }}>{props.user?.full_name}</Typography>
        </Button>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          MenuListProps={{
            onMouseEnter: enterMenu,
            onMouseLeave: leaveMenu
          }}
        >
          {/* <MenuItem onClick={handleProfileClick}>{t('profile')}</MenuItem> */}
          <MenuItem onClick={handleBillingClick}>{t("billing")}</MenuItem>
          <MenuItem onClick={handleUserAccountSettingsClick}>{t("user_account_settings")}</MenuItem>
          <MenuItem onClick={handleUserNotificationsSettingsClick}>{t("notification_settings")}</MenuItem>
          <MenuItem onClick={handleAccountSettingsClick}>{t("account_settings")}</MenuItem>
          <MenuItem
            onClick={() => {
              handleClose()
              logout()
            }}
          >
            {t("logout")}
          </MenuItem>
        </Menu>
      </div>
    </ClickAwayListener>
  )
}

const mapStateToProps = (state: any) => ({
  user: state.userReducer.profile
})

const mapDispatchToProps = {
  unauthenticate: authActions.unauthenticate
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
