import { actionTypes } from "./actionTypes"
import { create, fetch, remove, update } from "../../api/iskibris/redux-api-methods"
import { IMessageTemplate } from "../../types/messages"
import { action } from "typesafe-actions"

export const messagesActions = {
  fetchCompanyTemplates: async (id: any) => {
    return await fetch({
      url: `/api/hiring/v2/messaging/templates/companies/${id}`,
      onSuccessDispatch: actionTypes.ADD_MESSAGE_TEMPLATES,
      onErrorDispatch: actionTypes.ADD_MESSAGING_ERROR
    })
  },
  selectTemplate: (template: IMessageTemplate) => {
    return action(actionTypes.SELECT_TEMPLATE, template)
  },
  addTemplate: async (info: any) => {
    return await create({
      info: info,
      url: `/api/hiring/v2/messaging/templates`,
      onErrorDispatch: actionTypes.ADD_MESSAGING_ERROR,
      onSuccessDispatch: actionTypes.ADD_SINGLE_TEMPLATE
    })
  },
  editTemplate: async (info: any, templateId: any) => {
    return await update({
      info: info,
      url: `/api/hiring/v2/messaging/templates/${templateId}`,
      onErrorDispatch: actionTypes.ADD_MESSAGING_ERROR,
      onSuccessDispatch: actionTypes.EDIT_TEMPLATE
    })
  },
  deleteTemplate: async (id: any) => {
    return await remove({
      id: id,
      url: `/api/hiring/v2/messaging/templates/${id}`,
      onErrorDispatch: actionTypes.ADD_MESSAGING_ERROR,
      onSuccessDispatch: actionTypes.DELETE_SINGLE_TEMPLATE
    })
  },
  fetchMessages: async (comId: any, userId: any) => {
    return await fetch({
      url: `/api/hiring/v2/messaging/${comId}:${userId}`,
      onErrorDispatch: actionTypes.ADD_MESSAGING_ERROR,
      onSuccessDispatch: actionTypes.ADD_MESSAGES
    })
  },
  openSendMessage: (recipients: any[]) => {
    return action(actionTypes.OPEN_SEND_MESSAGE, recipients)
  },
  closeSendMessage: () => {
    return action(actionTypes.CLOSE_SEND_MESSAGE)
  }
}
