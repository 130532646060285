import React from "react"

import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

interface Props {
  children: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: Props) {
  const { children, value, index, ...other } = props

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index ? (
        <Box style={{ padding: "0px", paddingTop: "24px" }} p={3}>
          {children}
        </Box>
      ) : null}
    </Typography>
  )
}

export default TabPanel
