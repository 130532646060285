import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2, 2),
    borderRadius: "7.5px !important"
  },
  sidepaper: {
    padding: theme.spacing(2, 2),
    borderRadius: "7.5px !important",
    width: "100%",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: "20px"
    }
  },
  halfFormControl: {
    width: "50%",
    padding: "5px",
    marginBottom: "5px",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  formControl: {
    width: "100%",
    padding: "5px",
    marginBottom: "5px"
  },
  formcontrolLabel: {
    alignItems: "start",
    width: "100%",
    margin: "0px"
  },
  titleText: {
    padding: "5px"
  },
  divider: {
    marginTop: "10px",
    marginBottom: "15px"
  }
}))

export default useStyles
