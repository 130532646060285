/* eslint-disable react/prop-types */
import React from "react"

import { isArray } from "lodash"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import CircularProgress from "@material-ui/core/CircularProgress"

import { ICompany } from "../../../../../types/company"
import { IApplicantFilters } from "../../../../../types/applicants"

import useStyles from "./styles"
import { getPlaces } from "../../../../../api/iskibris/iskibris-methods"

import { PlaceSelectorWidget } from "innovia-component-library"


interface Props {
  company: ICompany
  activeFilter: boolean
  filters: IApplicantFilters
  handleGoBack: () => void
  applyFilters: (values: any, page: any) => Promise<void>
}

const PlaceFilter: React.FC<Props> = props => {

  const [loading, setLoading] = React.useState(false)

  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const {
    watch,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      place: props.filters.place
    }
  })

  React.useEffect(() => {
    watch("place")
  }, [watch])

  const handleApplyButtonClick = async () => {
    setLoading(true)
    let placeFilters = getValues().place

    placeFilters = placeFilters.map(item => typeof item === "number" ? item : item?.id)

    await props.applyFilters(
      {
        ...props.filters,
        activeFilter: props.activeFilter,
        companyId: props.company.id,
        place: placeFilters
      },
      1
    )
    setLoading(false)
  }

  const handleClose = () => {
    setValue('place', props.filters.place)
    props.handleGoBack()
  }

  const handleFetchOptions = async (searchKeyword?: string | null, page?: number, perPage?: number) => {
    const response = await getPlaces(searchKeyword, page, perPage)
    const result = isArray(response.data) ? response.data : response?.data?.data
    return result
  }

  const getOptionLabel = (option: any) => {
    return option?.name ? option?.name[`name_${i18n.language}`] : ""
  }

  return (
    <div className={classes.root + " va-middle"}>
      <form>
        <FormControl fullWidth style={{ padding: "10px" }}>
          <PlaceSelectorWidget
            multiple
            required
            fullWidth
            margin="dense"
            name="place"
            errors={errors}
            control={control}
            placeholder={t("city_id_placeholder")}
            getOptionLabel={getOptionLabel}
            fetchOptions={handleFetchOptions}
            onSelectOption={(option: any) => {setValue("place", option)}}
          />
        </FormControl>
        <div style={{ width: "350px", display: "flex", justifyContent: "center" }}>
          <Typography variant="caption" style={{textAlign: "center"}}>{t("search_and_select_locations")}</Typography>
        </div>
        <div className={classes.buttonArea}>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            {t("go_back")}
          </Button>
          <Button color="primary" variant="contained" onClick={handleApplyButtonClick}>
            {loading ? <CircularProgress size={22} /> : t("apply")}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default PlaceFilter