import React from "react"
import { useTranslation } from "react-i18next"

import Typography from "@material-ui/core/Typography"

import { IApplicant } from "../../../../../types/applicants"
import SingleEducationDetail from "./SingleEducationDetail"

import useStyles from "../../styles"
import { IFieldOfStudy } from "../../../../../types/user"

interface Props {
  applicant: IApplicant
  fieldsOfStudy: IFieldOfStudy[]
}

const EducationDetails: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const educationDetails = props.applicant?.education_details ?? []

  return (
    <div className={classes.applicantInfoCard}>
      {educationDetails && educationDetails.length > 0 ? (
        <React.Fragment>
          <div className={classes.infoCardHeader}>
            <Typography variant="body1" style={{ fontWeight: "bold" }} gutterBottom>
              {t("candidate_education_details")}
            </Typography>
          </div>
          {educationDetails.map(item => {
            return <SingleEducationDetail education={item} key={item.id} fieldsOfStudy={props.fieldsOfStudy} />
          })}
        </React.Fragment>
      ) : null}
    </div>
  )
}

export default EducationDetails
