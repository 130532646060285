import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    zIndex: 2
  },
  paper: {
    position: "absolute",
    top: 36,
    width: "350px"
  },
  listContainer: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: "296px",
    overflowY: "auto",
    borderBottom: "1px solid: #eaeaea"
  },
  buttonArea: {
    padding: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #eaeaea"
  },
  singleFilterDiv: {
    marginLeft: "2.5px",
    marginRight: "2.5px"
  }
}))

export default useStyles
