import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button, Typography } from "@material-ui/core"
import Cookies from "js-cookie"
import { useHistory } from "react-router"
import { endImpersonation, getImpersonatorProfile } from "../../api/iskibris/iskibris-methods"
import { impersonationActions } from "../../store/actions/impersonation"
import { flashActions } from "../../store/actions/flash-messaging"

interface Props {

}

const ImpersonationWarning: React.FC<Props> = () => {

  const router = useHistory()
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const isAuthenticated = useSelector((state: any) => state.authReducer?.isAuthenticated)

  const impersonator = useSelector((state: any) => state.impersonationReducer?.impersonator)

  React.useEffect(() => {
    if(isAuthenticated && !impersonator){
      getImpersonatorProfile()
        .then(response => {
          dispatch(impersonationActions.addImpersonator(response.data))
        })
        .catch(error => {
          console.log("Error fetching impersonator: ", impersonator)
        })
    }
  }, [isAuthenticated, dispatch, impersonator])

  const handleEndImpersonation = async () => {
    await endImpersonation()
      .then(response => {
        Cookies.remove('impersonation')
        Cookies.remove('impersonation_user_id')
        Cookies.remove('impersonation_auth_token')
        Cookies.remove('impersonation_auth_token_expires_at')

        Cookies.remove('CompanyToken')
        Cookies.remove('current_company_id')

        dispatch(
          flashActions.addFlashMessage(
            t("impersonation_revoked_message"),"success", 
          )
        )
        
        dispatch(impersonationActions.clearImpersonationData())
        
        router.replace("/dashboard")

        window?.location?.reload()
      })
      .catch(error => {
        dispatch(
          flashActions.addFlashMessage(t("impersonation_revoke_error"), "error")
        )
      })

    
  }

  return (
    <div className="impersonation-warning__container">
      <Typography variant="body2">{t("impersonation_title", {name: impersonator?.full_name ?? ""})} {t("impersonation_body")}</Typography>
      <Button className="impersonation-warning__button" onClick={handleEndImpersonation}>{t("end_impersonation_session")}</Button>
    </div>
  )
}

export default ImpersonationWarning