import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {},
  shortLabel: {
    float: "right"
  },
  listitem: {
    paddingTop: "10px",
    paddingBottom: "10px"
  }
}))

export default useStyles
