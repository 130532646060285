export const en = {
  translations: {
    page_not_found: "page not found",
    page_not_found_subtext: "Oooops! We are sorry for the inconveniece. It looks like we couldn't find what you are looking for. Your link may be broken or may not exist.",
    go_to_home: "Go to Homepage",
    for_employers: "for Employers",
    greeting_id_0: "Good Morning",
    greeting_id_1: "Good Afternoon",
    greeting_id_2: "Good Evening",
    language_label_tr: "TR",
    language_label_en: "EN",
    login_with_facebook: "Login with Facebook",
    login_with_google: "Login with Google",
    signup_with_facebook: "Sign up with Facebook",
    signup_with_google: "Sign up with Google",
    email_address: "Email",
    password: "Password",
    sign_in: "Sign in",
    forgot_password: "Forgot Password",
    do_not_have_account: "Don't have an account? Sign Up",
    back_to_signin: "Already have an account? Sign In",
    reset_password_instructions: "To reset your password, type in your email address and click reset password. A password reset link will be sent to your email address.",
    return_to_sign_in: "Return to Sign In",
    submit: "Submit",
    forgot_password_fail: "Failed! Confirm your email and try again.",
    forgot_password_success: "Your password reset link sent to your email. Click on the link to reset your password.",
    or: "OR",
    sign_up: "Register",
    name: "Name",
    phone_number: "Phone Number",
    login_error: "Invalid email or password",
    click_here_forgot_password: "Forgot password? Click here to reset.",

    fullname_required: "Name is required",
    fullname_maxLength: "Name is too long",
    fullname_minLength: "Name is too short",
    password_required: "Password is required",
    password_maxLength: "Password is too long",
    password_minLength: "Password is too short",
    phone_number_required: "Phone number is required",
    phone_number_maxLength: "Phone number is too long",
    phone_number_minLength: "Phone number is too short",
    phone_number_invalid: "Phone number is invalid",
    email_required: "Email is required",
    email_maxLength: "Email is too long",
    email_minLength: "Email is too short",
    email_invalid: "Email is invalid",

    create_company_header: "Add New Company",
    create_company_text: "Click below to create a company to add and manage jobs and applications.",

    no_company_managed_text:
      "You are not managing any companies at the moment. Click below to go the trial account page and activated your companies for you to start managing jobs and applications.",
    click_here_to_create_trial_account: "Go to Employers",
    signin_with_another_account: "Signin with another Account",

    // Dashboard Screen
    jobs: "Jobs",
    jobs_card_message: "View, edit, and manage your open jobs in İş Kıbrıs.",
    candidates: "Candidates",
    candidates_card_message: "Review applicants and track where they are in your hiring process.",
    candidate_card_message_mobile: "Review and track your applicants.",
    new: "New",
    active: "Active",
    unrated: "Unrated",
    employer_profile: "Employer Profile",
    employer_profile_card_message: "Update your company information and story to show candidate why they should work for you.",
    language: "Language",
    settings: "Settings",
    contact_support: "Contact Support",
    view_as_job_seeker: "View as Job Seeker",
    logout: "Logout",
    create_company: "Create Company",
    search_for_more: "Type to search",

    // Sidebar
    home: "Dashboard",
    employer_tools: "Employer Tools",

    // Jobs
    view_jobs_manage: "View, edit and manage the jobs that you post onto İş Kıbrıs.",
    job_slots: "Job Slots",
    manage_your_jobs: "Manage Your Jobs",
    all_jobs: "All",
    published: "Published",
    inactive_jobs: "Inactive",
    job_title: "Title",
    job_location: "Job Location",
    jobs_per_page: "Per Page",
    search: "Search",
    actions: "Actions",
    edit: "Edit",
    duplicate: "Duplicate",
    archive: "Archive",
    create_job: "Create Job",
    edit_job: "Edit Job",
    create_new_job: "Create New Job",
    duplicate_job: "Duplicate Job",
    select_previous_job: "Select Existing Job",
    job_information: "Basic Information",
    category: "Category",
    employment_type: "Employment Type",
    create_or_duplicate: "Create New or Duplicate Job",
    previous_job_placeholder: "Job Title, Location",
    increase_candidate_appeal: "Increase your Candidate Appeal to improve the effectiveness of your posted jobs.",
    job_details: "Job Details",
    improving_candidate_appeal: `Improving your Candidate Appeal is easy. Whether it's adding a salary range to your job, describing the benefits you offer, or any other contribution, each step will increase your Candidate Appeal and your ability to attract more informed candidates.`,
    job_description: "Job Description",
    salary_range: "Salary Range",
    resume_required: "Resume required",
    job_description_text: "A good job description helps candidates answer three questions:",
    job_description_text_1: "1. What is the job? The roles responsibilities and how it fits into the rest of your organization.",
    job_description_text_2: "2. Why should they want it? Exciting or challenging projects, what its like to work for your company, and any benefits or perks available.",
    job_description_text_3: "3. Are they qualified? Skills and experience needed for success.",
    employment_type_id_0: "Full Time",
    employment_type_id_1: "Part Time",
    employment_type_id_2: "Freelancer",
    employment_type_id_3: "Internship",
    employment_type_id_4: "Voluntary",
    education_level: "Education Level",
    education_level_id_: "",
    education_level_id_0: "University",
    education_level_id_1: "High School",
    education_level_id_2: "Vocational School",
    is_good_graduates: "Is good for graduates",
    is_cv_required: "Is CV required",
    is_good_students: "Is good for students",
    is_good_internationals: "Is good for foreigners",
    is_good_remote: "Is good for remote work",
    job_location_text_1: "Adding street address and zip code can help get your jobs more applicants.",
    job_location_text_2: "Jobs require a city & state be specified in order to improve the relevancy of your job listing to candidates.",
    street_address: "Street Address",
    city_state: "City",
    job_posting_dates: "Job posting dates",
    salary_range_text: "Candidates are more likely to apply to jobs that show a salary.",
    sponsor_job: "Sponsor Job",
    sponsored: "Sponsored",
    clicks: "Clicks",
    start_date: "Start Date",
    end_date: "End Date",
    add_jobs: "Add / Edit Jobs",
    job_stats: "Job Stats",
    date_created: "Created At",
    bonus: "Bonus",
    cancel: "Cancel",
    add_en_translation: "Add English Translation",
    hide_en_translation: "Hide English Translation",
    hide_salary_information: "Hide Salary Information",
    show_salary_information: "Show Salary Information",
    minimum_salary: "Minimum Salary",
    maximum_salary: "Maximum Salary",
    city_id_0: "Nicosia",
    city_id_1: "Kyrenia",
    city_id_2: "Famagusta",
    city_id_3: "Iskele",
    city_id_4: "Morphou",
    city_id_5: "Lefke",
    city_id_6: "Other",
    city_id_undefined: "-",
    city_id_null: "-",
    candidate_appeal: "Candidate Appeal",
    candidate_appeal_checklist: "Candidate Appeal Checklist",
    profile_completeness: "Profile Completeness",
    show_details: "Show Details",
    hide_details: "Hide Details",
    job_search_query_empty_inactive: "Query does not match any inactive jobs.",
    job_search_query_empty_all: "Query does not match any company jobs.",
    job_search_query_empty_published: "Query does not match any published jobs.",
    job_search_query_empty_archived: "Query does not match any archived jobs.",
    inactive_jobs_empty: "Employer has no inactive jobs.",
    all_jobs_empty: "Employer has no jobs.",
    published_jobs_empty: "Employer has no published jobs.",
    archived_jobs_empty: "Employer has no archived jobs.",

    // Employer Tools
    candidate_appeal_subtext: "Increase your Candidate Appeal to improve the effectiveness of your posted jobs.",
    faqs: "Frequently Asked Questions",
    faq_question_1: "What is Candidate Appeal?",
    faq_question_2: "Why does it matter?",
    faq_question_3: "How do I improve my Candidate Appeal?",
    faq_answer_1:
      "Making a great hire isn't just about finding the right candidate. It's about making it easy for the right candidates to find you. Increasing your Candidate Appeal helps İş Kıbrıs quickly match you with the right fit for your business.",
    faq_answer_2:
      "Candidates are more likely to apply to jobs at companies with high Candidate Appeal because they can fully understand company and professional opportunities. Because they're able to become more informed with the right expectations, they're also more likely to be the right fit!",
    faq_answer_3:
      "Improving your Candidate Appeal is easy. Adding your company logo, listing benefits you offer, getting an employee review and taking many other steps will increase your Candidate Appeal and your ability to attract more informed candidates.",
    go_to_employer_profile: "Go to Employer Profile",
    company_locations: "Company Locations",
    add_company_locations: "Add Company Locations",
    logo: "Logo",
    company_benefits: "Company Benefits",
    about_your_co: "About Your Company",
    archived_jobs: "Archived Jobs",
    subscriptions: "Subscriptions",
    users_and_roles: "Users & Roles",
    notification_preferences: "Notification Preferences",
    templates: "Templates",

    // Subscription
    current_subscription: "Current Subscription",
    receipts_payment_hist: "Payment History",
    payment_date: "Payment Date",
    "invoice_#": "Invoice #",
    status: "Status",
    amount_paid: "Amount Paid",
    subscription_status: "Status",
    need_help_employer_center: "Need Help?",
    visit_employer_help: "Visit Employer Help Center",
    reactivate: "Reactivate",
    no_billing_system_client: "You do not have a billing account added",
    expires_on: "Expires on",
    billing_cycle: "Subscription Type",
    no_billing_client: "Your company doesn't have a billing client. Click Activate to get started with your subscriptions.",
    activate_billing: "Activate Billing",
    invoice: "Invoice",
    no_invoice_history: "You do not have any invoices.",
    no_subscription_history: "You have not made any payments yet.",
    Paid: "Paid",
    Unpaid: "Unpaid",
    paid: "Paid",
    unpaid: "Unpaid",
    billing_activation_success: "Billing account has been activated.",
    billing_activation_fail: "Failed to activated billing account. Please contact the admins.",

    // Templates
    custom_templates: "Employer Templates",
    custom_templates_subtext: "Create and manage your company's messaging templates.",
    template_type: "Template Type",
    template_title: "Title",
    last_modified: "Last Modified",
    template_type_id_1: "Rejection (no interview)",
    template_type_id_2: "Rejection (post-phone-interview)",
    template_type_id_3: "More infomation needed",
    template_type_id_4: "Phone interview invitation",
    template_type_id_5: "Interview invitation",
    template_type_id_6: "Offer Letter",
    template_type_id_7: "Rejection (post-onsite-interview)",
    template_type_id_8: "Other",
    standard_templates: "Standard Templates",
    standard_template: "Standard Template",
    standard_templates_subtext: "You can access these İşKıbrıs-provided templates anytime you compose a new message.",
    view: "View",
    customise: "Customise",
    delete: "Delete",
    description: "Description",
    is_standard_template: "Is Standard",
    add_template: "Add Template",
    save: "Save",
    variables: "Variables",
    messaging_template_title_edit: "Edit Template",
    messaging_template_title_view: "View Template",
    messaging_template_title_create: "Create Template",
    messaging_template_title_customise: "Customise Template",
    no_custom_templates_header: "You have no custom templates",
    no_custom_templates_body:
      "Create a custom messaging template to supplement our İşKıbrıs templates to convey your specific message and allow your recruiter voice to stand out from the crowd.",
    no_standard_templates_header: "There are currently no İş Kıbrıs templates.",
    no_standard_templates_body:
      "Standard messaging templates provide you with a starting point to create your own custom templates to supplement our İşKıbrıs templates to convey your specific message and allow your recruiter voice to stand out from the crowd.",
    candidate: "Candidate",
    job_company: "Job/Company",
    cfname: "Candidate First Name",
    cflname: "Candidate Fullname",
    clname: "Candidate Last Name",
    rfname: "Recruiter First Name",
    rflname: "Recruiter Fullname",
    rlname: "Recruiter Last Name",
    messaging_template: "Messaging Template",
    add_template_subtext: "Select a template type, give it a name, and we will make sure it’s easily available when you need it the most.",
    recruiter: "Recruiter",

    // Preferences
    preferred_email: "Preferred Email",
    primary_address_subtext: "The primary address for any emails you've elected to receive emails on.",
    change: "Change",
    app_news_header: "İş Kıbrıs news",
    app_news_subtext: "News from İş Kıbrıs to help you make the most of your profile and hire the best candidates.",

    // Users & Roles
    email: "Email",
    roles: "Roles",
    last_login: "Last Login",
    "role-a": "A",
    employer: "Employer",
    editor: "Editor",
    observer: "Observer",
    edit_permission_level: "Edit permission level",
    edit_user_subtext: "Please enter the new level of access for ",
    adminstrator: "Adminstrator",
    employer_subtitle: "This role can only view limited information like general ratings. This role cannot edit the profile, respond to reviews or see Insights reporting.",
    editor_subtitle: "Content Limited + view Insights reporting update Company Profile information respond to Reviews and add Photos.",
    observer_subtitle: "This role can only view limited information like general ratings. This role cannot edit the profile, respond to reviews or see Insights reporting.",

    // Employer Profile
    company_info: "Company Info",
    logo_photos: "Logo and Photos",
    company_story: "Company Story",
    company_updates: "Company Updates",
    company_faqs: "Company FAQs",
    create_company_subtext: "Add and edit public information that's shown on your İşKıbrıs Profile",
    basic_info: "Basic Info",
    company_name: "Company Name",
    address: "Address",
    headquarter_city: "Headquarters City",
    company_website_label: "Website",
    company_email_label: "Email",
    company_phone_label: "Telephone Number",
    company_registration_number_label: "Registration Number",
    company_year_of_foundation_label: "Year of Foundation",
    working_hours_label: "Working Hours",
    working_hours_id_0: "Weekdays + Saturday (half day)",
    working_hours_id_1: "Weekdays",
    working_hours_id_2: "Weekdays + Saturday (full day)",
    working_hours_id_3: "Everyday except Monday",
    working_hours_id_4: "Everyday",
    company_size_label: "Number of Employees",
    company_size_id_0: "0 - 3 employees",
    company_size_id_1: "3 - 10 employees",
    company_size_id_2: "10 - 30 employees",
    company_size_id_3: "30 - 100 employees",
    company_size_id_4: "100+ employees",
    sector_label: "Sector",
    industry_label: "Industry",
    company_leader: "Company Leader",
    leader_name_label: "Leader Name",
    leader_title_label: "Title",
    social_media: "Social Media",
    facebook_profile_label: "Facebook",
    twitter_profile_label: "Twitter",
    linkedin_profile_label: "LinkedIn",
    youtube_profile_label: "Youtube",
    instagram_profile_label: "Instagram",
    delete_company: "Delete Company",
    why_work_with_us: "Why work with us",
    cover_image: "Cover Photo",
    delete_company_warning: "Do you really want to delete this company? You cannot manage company profile or jobs anymore if you delete this company.",
    confirmation_question: "Are you sure?",
    agree: "Agree",
    disagree: "Disagree",
    benefits: "Benefits",
    no_benefits: "No benefits added. Click on add benefits to add benefits",
    add_benefits: "Add Benefits",
    benefits_pledges: "Benefits And Pledges",
    benefits_summary: "Benefits Summary",
    exclusive_feature: "Exclusive Feature",
    add_your_note_here: "Add your note...",
    add_description: "Add Description",
    employee_reporting: "Employee Reporting",
    benefits_subtext: "Showcase distinct benefits offered by your organization.",
    learn_more: "Learn More",
    back_to_company_info: "Back to Company Info",
    company_edit_fail: "Failed to update employer profile.",
    company_edit_success: "Successfully updated employer profile.",
    company_story_edit_fail: "Failed to update employer profile.",

    upload_photos_subtext: "Adding photos of your organization's team, office or location, and events can help your jobs stand out to candidates.",
    about_company_text:
      "Introduce yourself to job seekers. Information you might want to include: when and why your business was founded, how much you have grown since, what your company does and the types of customers you serve, and your mission statement.",
    company_info_faq: "FAQ",
    company_mission: "Company Mission",
    add_company_update: "Add a Company Update",
    company_story_edit_success: "Employer story updated successfully.",
    company_description: "Company Description",

    // Photos
    photos: "Photos",
    add_logo: "Add Logo",
    cover_photo: "Cover Photo",
    add_cover_photo: "Add Cover Photo",
    upload_logo_subtext: "Upload a logo you think represents your company well.",
    upload_cover_photo_subtext: "Upload a cover photo that best represents the values your company stands for.",
    drag_and_drop_image: "Drag and drop an image file here or click",
    drag_and_drop_file: "Drag and drop files here or click",
    upload_file: "Upload File",

    // Updates
    add_company_update_text: "Reach followers and visitors to your profile by publishing company news and updates.",
    add_update_placeholder: "Enter message here",
    post: "Post",

    // Locations
    create_location_success: "Successfully create your company's new branch.",
    edit_location_success: "Successfully edited your company's branch information.",
    create_location_fail: "Failed to create company's location. Check your information and try again!",
    edit_location_fail: "Failed to edit company's location. Check your information and try again!",
    branch_name: "Branch Name - Turkish",
    branch_name_en: "Branch Name - English",
    latitude: "Latitude",
    longitude: "Longitude",
    city: "City",
    address_code: "Address Code",
    directions: "Directions",
    add_company_location: "Add Company Branch",
    add_company_location_text: "Add your company's branches to stand out to candidates among other employers.",
    edit_company_location: "Edit Company Branch",
    edit_company_location_subtext: "Edit your company branch's information to make any modifications to it's information.",

    // FAQs
    add_company_faq: "Add Company FAQs",
    create_faq_success: "Successfully create your company's new FAQ.",
    edit_faq_success: "Successfully edited your company's FAQ information.",
    create_faq_fail: "Failed to create company's FAQ. Check your information and try again!",
    edit_faq_fail: "Failed to edit company's FAQ. Check your information and try again!",
    add_company_faq_text: "Add some of the frequently asked questions about your company.",
    faq_category: "FAQ Category",
    question_tr: "Question TR",
    question_en: "Question EN",
    answer_en: "Answer EN",
    answer_tr: "Answer TR",
    faq_category_id_1: "",
    question_tr_required_error: "Question is required",
    question_tr_minlength_error: "Quesiton is too short",
    question_en_minlength_error: "Quesiton is too short",
    answer_tr_required_error: "Answer is required",
    answer_tr_minlength_error: "Answer is too short.",
    answer_en_minlength_error: "Answer is too short",

    // APPLICANTS
    applicants_management: "Candidates",
    sort_by: "Sort by",
    sort_id_0: "Activity (most recent)",
    sort_id_1: "Activity (oldest)",
    sort_id_2: "Name (A-Z)",
    sort_id_3: "Name (Z-A)",
    sort_id_4: "Job  Title (A - Z)",
    sort_id_5: "Job  Title (Z - A)",
    mobile_sort_id_0: "Most recent",
    mobile_sort_id_1: "Oldest",
    mobile_sort_id_2: "Name (A-Z)",
    mobile_sort_id_3: "Name (Z-A)",
    mobile_sort_id_4: "Job (A-Z)",
    mobile_sort_id_5: "Job (Z-A)",
    all: "All",
    stage: "Stage",
    rating: "Rating",
    location: "Location",
    rating_id_2: "Maybe",
    filter_by: "Filter by",
    rating_id_0: "Unrated",
    rating_id_1: "Interested",
    rating_id_3: "Not interested",
    rows_per_page: "Rows per page",
    export_to_csv: "Export candidates",
    other_applications: "Other applications by the user",
    stage_id_0: "New",
    stage_id_1: "Applied",
    stage_id_2: "Reviewed",
    stage_id_3: "Phone Screen",
    stage_id_5: "Offer",
    stage_id_6: "Hired",
    message_all: "Message All",
    export_applicants: "Export",
    stage_id_4: "Interview",
    stage_id_7: "Rejected",
    stage_id_8: "Candidate Withdrew",
    company_users: "Company Users",
    access_levels: "Access Levels",
    email_addresses: "Email Addresses",
    invite_coworkers: "Invite Coworkers",
    apply: "Apply",
    rating_filter_label: "Rating",
    stage_filter_label: "Stage",
    city_filter_label: "Location",
    job_filter_label: "Job Title",
    education_level_filter_label: "Education Level",
    work_permit_filter_label: "Residence Status",
    field_of_study_filter_label: "Field of Study",
    job_seeking_status_label: "Availability",
    more_filter_label: "More Filters",
    job_seeking_status_0: "I can start working immediately.",
    job_seeking_status_1: "I can start to work within 2 weeks",
    job_seeking_status_2: "I can start to work within 1 month",
    work_permit_label: "Residence status",
    work_permit_id_0: "I'm a TRNC Citizen",
    work_permit_id_1: "I have a student visa",
    work_permit_id_2: "Foreigner with a work permit",
    work_permit_id_3: "Foreigner without a work permit",
    work_permit_id_4: "I'm not living in TRNC",
    work_permit_id_null: "-",
    field_of_study: "Field of Study",
    more_filters: "More Filters",
    search_for_more_field_of_study: "Search for more...",
    male: "Male",
    gender: "Gender",
    female: "Female",
    gender_id_: "",
    gender_id_male: "Male",
    gender_id_female: "Female",
    gender_id_unspecified: "Prefer not to say",
    results_for: "Results for:",
    send_message: "Send Message",
    active_filter_tooltip: "Toogle between Active and All to see your candidates in different active states.",
    clear_all_filters: "Clear All Filters",
    applied_on: "Applied On",
    view_job: "View Job",
    candidate_details: "Candidate Details",
    message_history: "Message History",
    cover_letter: "Cover Letter",
    finished_in: "Class of",
    degree_id_0: "Bachelor's Degree",
    degree_id_1: "Masters",
    degree_id_2: "PHD",
    degree_id_3: "MD",
    course_level_id_0: "Bachelor's Degree",
    course_level_id_1: "Masters",
    course_level_id_2: "PHD",
    course_level_id_3: "MD",
    candidate_education_details: "Education Details",
    month_id_1: "January",
    month_id_2: "February",
    month_id_3: "March",
    month_id_4: "April",
    month_id_5: "May",
    month_id_6: "June",
    month_id_7: "July",
    month_id_8: "August",
    month_id_9: "September",
    month_id_10: "October",
    month_id_11: "November",
    month_id_12: "December",
    no_comments_body:
      "Add short notes about a candidate for other company users to see. These are for internal use only so only you and your company users will be able to view the notes.",
    notes: "Notes",
    internal_only: "internal only",
    add_note_placeholder: "Add note...",
    resume: "Résumé",
    no_cv_body: "Candidate has not uploaded a CV. You can contact them via in-app messaging to request that they add their CV.",
    qualifications_label: "Qualifications",
    years_of_experience_label: "Years of Experience",
    driving_licence_label: "Driver's licence",
    driving_licence_id_0: "Valid Driver's Licence",
    driving_licence_id_1: "No driver's licence",
    languages_label: "Languages",
    languages_id_0: "Türkçe",
    languages_id_1: "English",
    languages_id_2: "Ελληνικά",
    languages_id_3: "Русский",
    languages_id_4: "Other",
    new_employment_type_label: "Preferred Employment Type",
    military_status_label: "Military Status",
    military_status_id_0: "Exempted",
    military_status_id_1: "Postponed",
    military_status_id_2: "Completed",
    preferred_city_label: "Preferred City",
    phone_label: "Phone Number",
    city_of_residence: "City of Residence",
    applicant_details: "Candidate Details",
    personal_summary: "Personal Summary",
    view_user_details: "View user details.",
    download: "Download",
    all_candidates: "All Candidates",
    previous: "Previous",
    next: "Next",
    filters: "Filters",
    go_back: "Go Back",
    search_placeholder: "Search...",
    no_messages_yet_title: "No Messages Yet",
    no_messages_yet_body: "Send a message to this candidate to get started. Your complete message history with this candidate will appear here.",
    reply: "Reply",
    to: "To",
    email_label: "Email",
    city_id_label: "City",
    name_label: "Full Name",
    stage_id_label: "Stage",
    rating_id_label: "Rating",
    job_title_label: "Job Title",
    phone_number_label: "Phone Number",
    no_applicants_for_company:
      "There are no candidates who have applied to your listed jobs yet. You can sponsor your jobs to boost the number of job seekers who click on your jobs.",
    no_applicants_match_filter: "There are no candidates matching your search criteria. Please adjust your filters to view your candidates.",
    no_applicants_job_button_tooltip: "Jobs with more details get more clicks and applications.",
    no_applicants_employer_button_tooltip: "Edit your employer profile and photos to get more clicks and applications",

    // Messaging
    maximum_characters: "1000 Character Maximum (13 characters)",
    send: "Send",
    preview: "Preview",
    select_template: "Select template to preview.",
    hide_messages: "Hide Messages",
    recipients: "Recipients",
    show_messages: "Show Messages",
    type_message_here: "Type your message here...",
    character_max: "1000 Character Maximum.",
    characters: "characters",
    template: "Template",
    add_recipients: "Add Recipients",
    template_origin_0: "Custom Templates",
    template_origin_null: "Custom Templates",
    template_origin_1: "İş Kıbrıs Templates",
    optional: "Optional",
    messages_recipient_title: "Recipients",
    messages_recipient_placeholder: "Add Recipients",
    new_message: "New Message",
    select_template_to_message: "Select Template",
    template_type_id_null: "- -",

    // Page titles
    applicants_management_title: "İş Kıbrıs Hiring Panel - Applicant Management",
    create_job_title: "İş Kıbrıs Hiring Panel - Create Job",
    duplicate_job_title: "İş Kıbrıs Hiring Panel - Create Job",
    edit_job_title: "İş Kıbrıs Hiring Panel - Edit Job",
    dashboard_title: "İş Kıbrıs Hiring Panel - Dashboard",
    employer_profile_title_info: "İş Kıbrıs Hiring Panel - Employer Info",
    employer_profile_title_benefits: "İş Kıbrıs Hiring Panel - Employer Benefits",
    employer_profile_title_story: "İş Kıbrıs Hiring Panel - Employer Story",
    employer_profile_title_photos: "İş Kıbrıs Hiring Panel - Employer Photos",
    employer_profile_title_updates: "İş Kıbrıs Hiring Panel - Employer Updates",
    employer_profile_title_locations: "İş Kıbrıs Hiring Panel - Employer Locations",
    employer_profile_title_faqs: "İş Kıbrıs Hiring Panel - Employer Faqs",
    employer_profile_title: "İş Kıbrıs Hiring Panel - Employer Profile",
    employer_tools_title: "İş Kıbrıs Hiring Panel - Employer Tools",
    jobs_management_title: "İş Kıbrıs Hiring Panel - Jobs Management",
    register_title: "İş Kıbrıs Hiring Panel - Register Employer Account",
    forgot_password_title: "İş Kıbrıs Hiring Panel - Forgot Password",
    settings_title_subscriptions: "İş Kıbrıs Hiring Panel - Subscriptions",
    settings_title_users_management: "İş Kıbrıs Hiring Panel - User Management",
    settings_title_preferences: "İş Kıbrıs Hiring Panel - Settings Preferences",
    settings_title_templates: "İş Kıbrıs Hiring Panel - Template Management",
    settings_title: "İş Kıbrıs Hiring Panel - Settings",
    login_title: "İş Kıbrıs Hiring Panel - Sign In",

    // Extras
    did_not_receive_email: "Did not receive email",
    company_create_success: "Your company has been created successfully.",
    company_create_fail: "Failed to create your company, check your information and try again.",
    job_create_success: "Your job has been created successfully.",
    job_create_fail: "Failed to create your job, check your information and try again.",
    company_delete_success: "Your company has been deleted successfully.",
    company_delete_fail: "Failed to delete your company, check your information and try again.",
    upload_new_cover_photo: "Upload New Cover Photo",
    upload_new_logo: "Upload New Logo",
    reset: "Reset",
    zoom: "Zoom",
    cover_photo_upload_success: "Cover photo successfully uploaded.",
    cover_photo_upload_fail: "Failed to upload cover photo.",
    logo_upload_success: "Logo successfully uploaded.",
    logo_upload_fail: "Failed to upload logo.",
    enter_message_here: "Enter message here",
    enter_information_here: "Enter information here",
    no_fos_search_results: "No fields of study.",
    message_sent_success: "Your message has been sent successfully.",
    message_sent_fail: "Failed to send your message. Try again later.",

    select_all: "Select all",
    job_edit_success: "Job information successfully updated.",
    job_edit_fail: "Failed to update job information.",
    birthdate_label: "Date of Birth",
    gender_label: "Gender",
    field_of_study_label: "Field of Study",
    education_level_id_label: "Education Level",
    school_label: "School",
    work_permit_id_label: "Work Permit Status",
    more_recipients: "more recipients",

    stage_id_null: "",
    city_id_: "Other",
    add_tr_translation: "Add Turkish Translation",
    add_new_payment_method: "Add new payment method",
    payment_method_subtext: "Select the default payment method for your monthly subscription.",
    payment_methods: "Payment Methods",
    jobs_titles_label: "Type to search for more categories...",
    job_not_found: "Job with the provided id could not be found or you do not have access to it.",

    // Payment Methods
    add_payment_method: "Add Payment Method",
    billing_address: "Billing Address",
    billing_city: "City",
    billing_country: "Country",
    card_number: "Credit card number",
    credit_card_information: "Credit card information",
    expiry_date: "Expiry Date",
    expiry_date_required: "Expiry date is required.",
    cvv: "CVV",
    cvv_required: "CVV is required.",
    billing_address_required: "Billing address required.",
    billing_city_required: "Billing city required.",
    credit_card_information_subtext: "All information are secured on highly good certified PCI/DSS Servers which are MasterCard Certified.",
    billing_name: "Name on card",
    billing_name_required: "Name is required.",
    pricing: "Pricing",
    packages: "Packages",
    show_more: "Show More",
    show_more_false: "Show More",
    show_more_true: "Show Less",
    try_it: "Try it",
    subscription_details: "Subscription Details",
    package_details: "Package Details",
    total_allocated_quantity: "Total allocated quantity",
    total_used_quantity: "Total used quantity",
    total_remaining_quantity: "Total remaining quantity",
    components: "Components",
    units: "Units",
    billing_reference: "Billing Reference",
    product: "Product",
    contact_information: "Contact Information",
    client_information: "Client Information",
    questions_contact_us: "If you have any questions, you can contact us at",
    print_receipt: "Print Receipt",
    or_sm: "or",
    payment_method: "Payment Method",
    month: "Month",
    change_plan: "Change Plan",
    subscrition_expired_text: "You do not have an active subscription at the moment.",
    reactivate_subscription: "Reactivate subscription",
    cancel_subscription: "Cancel Subscription",
    add_addons: "Add Addons",
    add_new_package: "Add New Package",
    cancel_subscription_question: "Are you sure you want to cancel your subscription?",
    cancel_subscription_warning: "You cannot undo this action. If you have any questions, contact our team.",
    contact_us: "Contact us.",
    subscription_cancellation_success: "Successfully cancelled your subscription.",
    subscription_cancellation_fail: "Failed to cancel your subscription. Contact our team for assistance.",
    interval_month: "month(s)",
    interval_day: "day(s)",
    activate_new_billing: "Activate Billing Plan",
    valid_for: "Valid for",
    go_to_subscriptions: "View your subscriptions",
    "billing_cannot_publish-job": "You cannot publish a job with your current subscription on İş Kıbrıs. Upgrade or reactive your package in order to publish a job.",
    unpublish_job_confirmation_question: "Are you sure you want to unpublish this job?",
    unpublish_job_confirmation_warning: 
      "After unpublishing this job, your job won't appear in any searches by candidates. Reactivating this job will charge a job slot from your package.",
    "billing_cannot_add-company-users":
      "You cannot add a new company user with your current subscription on İş Kıbrıs. Upgrade or reactivate your package in order to add new team member.",
    "billing_cannot_send-message-to-applicants":
      "You cannot send messages to applicants without an active subscription on İş Kıbrıs. Reactivate your package in order to send a message to applicants.",
    "billing_cannot_export-applicants": 
      "You cannot export applicants with your current İş Kıbrıs package. Upgrade or reactivate your package in order to export your applicants.",
    "billing_cannot_create-benefits":
      "You cannot add benefits with your current İş Kıbrıs package. Upgrade or reactivate your package in order to add benefits to your employer profile.",
    "billing_cannot_buy-package": 
      "You cannot buy package İş Kıbrıs packages at the moment. You will be able to buy our packages from 1 November 2021.",
    buy_it: "Buy Package",

    job_description_required: "Job description is required.",
    job_title_required: "Job title is required.",
    job_city_id_required: "Job location city is required.",
    startDate_required: "Job publication date is required.",
    endDate_required: "Job end date is required.",
    employment_type_required: "Employment type is required.",
    education_level_required: "Education level is required.",
    active_jobs: "Active Jobs",
    remove_en_translation: "Remove English Translation",
    " ": "",
    toggle_component_status_true: "Active",
    toggle_component_status_false: "Inactive",
    billing_is_coming_text: "Iş Kıbrıs is removing free plans on the 1st of November. New plans start at 77 TL.",
    billing_is_coming_learn_more: "Learn more about the change.",
    dismiss: "Dismiss",
    filter_apply: "Apply Filters",

    // Job Slots
    job_slots_available_message: "You have {{p1}} job slots available to post your jobs",
    job_and_internship_slots_available_message: "You have {{p1}} job slots and {{p2}} internship slots available to post your jobs",
    manage_jobs_below_message: "Use the dashboard below to add a job",
    no_jobs_slots_available: "There is no job slots available now.",
    add_a_package: "Add a Package",
    jobs_slots_total: "Total",
    jobs_slots_available: "Available",
    end_date_min_date_message: "End date cannot be lower than start date.",
    end_date_max_date_message: "You cannot exceed this publication date limit on this job because of your package.",
    job_slot_validity_message:
      "This job is valid until {{expiry_date}} and you can publish or unpublish it at your own discretion between this period of time without encountering any extra charges.",
    job_expired_need_to_duplicate:
      "The free trial period of this work has expired and cannot be republished. You can click “Duplicate” link below to create  a job with similar content. Note that a job slot will be charged for the new duplicated job.",
    click_here_to_duplicate: "Click here to duplicate.",
    age_label: "Age",
    applications_other_applications: "Other Applications",
    gender_id_null: "Unspecified",
    application_details: "Application Details",
    application_notes: "Application Notes",
    download_application_body: "In order to download the summary of this job application, click on the download button below.",
    download_application: "Download Application",
    company_user_invitation_title: "Invitation to joing the {{ company_name }} recruitment team",
    company_user_invitation_body:
      "{{inviter_name}} has invited you to be part of the {{company_name}} recruitment team with the role of {{role_name}}. Click below to either accept or reject.",
    accept: "Accept",
    decline: "Decline",
    company_user_invitation_accept_success: "You have been successfully added to the company users.",
    company_user_invitation_reject_success: "You have successfully declined the company's invitation.",
    company_user_invitation_reject_error: "Error while rejecting company invitation. Invitation may have expired.",
    company_user_invitation_accept_error: "Error while accepting company invitation. Invitation may have expired.",
    invitation_fetch_error: "Failed to fetch invitation. Invitation may have expired or revoked.",
    pending_company_user_invitations: "Pending Invitations",
    invited_at: "Invited At",
    revoke: "Revoke",
    company_user_invitation_alert: "You have an invitation to joing the {{ company_name }} recruitment team",
    view_invitation: "View Invitation",
    whatsapp_contact_reply_duration: "Typically replies within an hour.",
    created_at_label: "Created At",
    title: "Title",
    og_title: "Open Graph Title",
    og_description: "Open Graph Description",
    open_graph_header: "Facebook Open Graph Preview",
    open_graph_subtitle: "Update what your link will look like when sharing on Facebook.",
    add_image: "Add Image",
    select_new_image: "Select New Image",
    company_og_tags_updated_success: "Company open graph tags updated",
    company_og_tags_updated_error: "Failed to update the company open graph tags.",
    job_open_graph_tags_header: "Open Graph Tags",
    job_open_graph_tags_subtext: "Customize what your job's link will look like when sharing on Facebook.",
    company_open_graph_tags_subtext: "Customize what your company's link will look like when sharing on Facebook.",

    billing_publish_internship_job:
      "You can only publish an internship job with your current subscription on İş Kıbrıs. Upgrade or reactive your package in order to publish all the other job types.",
    create_internship_job: "Create Internship Job",

    internship_jobs_slots_available: "Internship Slots",

    close: "Close",
    request_invoice: "Request Invoice",
    company_email: "Company Email",
    company_telephone: "Company Telephone",
    contact_person_name: "Contact Person Name",
    contact_person_email: "Contact Person Email",
    contact_person_phone: "Contact Person Phone",
    invoice_type: "Invoice Type",
    invoice_type_physical: "Physical Invoice",
    invoice_type_digital: "Digital Invoice",
    invoice_request_fail: "Failed to create your invoice request. Check your information and try again.",
    invoice_request_success: "Successfully requested for your invoice. We will be in touch shortly.",
    years_of_experince_label: "Years of Experience",

    is_profile_required: "Is profile required",
    active_packages: "Active Packages",
    active_packages_subtitle: "Packages you bought which are still active.",
    validity: "Validity Period",
    no_components: "This package has no components",
    free_forever: "Free forever",
    company_registration_number_placeholder: "Company registration number or ID number",
    company_registration_number: "Company registration number or ID number",

    billing_settings: "Billing Settings",
    registration_number: "Company registration number or ID number",
    invoice_organization_name: "Invoice Organization Name",
    invoice_contact_first_name: "Contact Person First Name",
    invoice_contact_last_name: "Contact Person Last Name",
    invoice_contact_phone: "Invoice Contact Phone",
    invoice_email: "Invoice Email",
    invoice_address: "Address",
    invoice_additional_notes: "Additional Notes / Description",
    billing_settings_update_success: "Successfully updated your billing settings.",
    billing_settings_update_fail: "Failed to update your billing settings. Check your details and try again.",
    settings_title_billing: "Billing",
    billing: "Billing",
    date_charged: "Charged At",
    date_charge_expiry: "Expires At",
    subscription_charged_reference: "Subscription Reference",
    account_settings: "Account Settings",
    update_password: "Update Password",
    update_password_body: "In order to update your password you need to enter the new password, reenter the password to confirm and then click save to submit.",
    account_settings_title: "İş Kıbrıs Hiring Panel - Account Settings",
    old_password: "Current Password",
    new_password: "New Password",
    confirm_password: "Confirm Password",
    password_change_success: "Successfully updated your password.",
    password_change_fail:
      "Failed to update your password. Please check your information and try again.",
    server_fail_error: "Server error. Please contact support team for assistance.",
    network_error: "Network error. Please check your network connection and try again.",
    delete_account_question: "Are you sure you want to delete your İş Kıbrıs account?",
    delete_account_warning: "Your will not be able to undo this action.",
    account_delete_success: "Successfully deleted your account.",
    account_delete_fail:
      "Failed to delete your account. Please check your connection and try again.",
    delete_account: "Delete Account",
    delete_account_subtext:
      "Deleting your account will clear all your data, and delete your résumé files uploaded. This information will not be recovered if you re-register with this account.",
    delete_account_company_warning: "This action will not delete any of the companies you manage. If you need to delete any of your companies click manage your companies.",
    manage_companies: "Manage Companies",
    old_password_required: "Your current password is required",
    old_password_maxLength: "Password cannot be longer than 91 characters",
    old_password_minLength: "Password cannot be shorter than 4 characters",
    new_password_required: "Your new password is required",
    new_password_maxLength: "Password cannot be longer than 91 characters",
    new_password_minLength: "Password cannot be shorter than 4 characters",
    confirm_password_required: "You need to confirm your new password",
    confirm_password_maxLength: "Password cannot be longer than 91 characters",
    confirm_password_minLength: "Password cannot be shorter than 4 characters",

    notification_settings_header: "Nofitication Settings",
    notification_settings_change_success: "Your nofitication settings were changed successfully.",
    notification_settings_change_fail: "Failed to change your nofitication settings. Try again later.",
    notification_settings_subtext:
      "If you do not want to receive emails about applications to your job posts, you can change your notification settings here.",
    email_notifications: "I want to receive email notifications about job applications",

    impersonation_title: "Hello {{ name }}!",
    impersonation_body: "User impersonation is active at the moment.",
    end_impersonation_session: "End Impersonation Session",
    impersonation_prompt_title: "Initialize Impersonation",
    impersonation_prompt_body: "You are about to start initializing an impersonation session for user ID: {{ id }}",
    impersonation_error: "Error initiating user impersonation.",

    impersonation_revoked_message: "Impersonation session has been ended successfully. You will be redirected to the home page.",
    impersonation_revoke_error: "Failed to end impersonation session. Contact the developer team.",
    impersonation_starting_message: "Impersonation session has initialized successfully. You will be redirected to the home page to begin your session.",

    mark_all_as_read: "Mark all as read",
    mark_as_read: "Mark as read",
    notifications: "Notifications",
    only_show_unread: "Only Show Unread",
    recent_notifications: "Latest",
    view_jobs_with_count: "View {{jobs}}+ Jobs",
    apply_early: "Apply Early",
    mark_as_unread: "Mark as unread",
    last_week: "Last Week",
    today: "Today",
    yesterday: "Yesterday",
    older: "Older",
    notification_center_survery_question: "Are you finding these notifications useful?",
    notification_center_survery_question_subtitle:
      "We'll use your feedback to improve your job search experience.",
    notification_center_survery_success: "Thank you for your feedback.",
    notification_center_survery_error: "An unexpected error occurred. Please try again later.",
    empty_notifications: "You have read all your notifications from the past month.",
  
    driver_licence: "Driver's Licence",
    driver_licence_category_id_A: "Class A - Bus",
    driver_licence_category_id_B: "Class B - Truck, Trailer",
    driver_licence_category_id_C: "Class C - Minibus",
    driver_licence_category_id_D: "Class D - Passenger cars with manual and automatic transmission",
    driver_licence_category_id_E: "Class E - Tractor, ATV",
    driver_licence_category_id_F: "Class F - Tracked vehicles",
    driver_licence_category_id_G: "Class G - Road Roller",
    driver_licence_category_id_H: "Class H - Motorcycle",
    driver_licence_category_id_K: "Class K - Grader and Razor vehicles",

    application_age_filter_label: "Applied At",
    application_age_1: "Past 24 Hours",
    application_age_3: "Last 3 Days",
    application_age_7: "Last Week",
    application_age_14: "Last 2 Weeks",
    application_age_31: "Last Month",
    application_age_60: "Last 60 Days",
    application_age_90: "Last 90 Days",
    application_age_180: "Last 6 Months",
    application_age_365: "Last 1 Year",
    application_age_730: "Last 2 Years",
    application_age_custom_days: "Last {{ days }} Days",

    back: "Back",
    on: "On",
    off: "Off",

    notification_settings_title: "Notifications Settings Center",
    notification_settings_center_description: "You can manage your notifications preferences here.",

    notification_settings_option_title_id_recommendations: "Recommendations",
    notification_settings_option_subtitle_id_recommendations: "Push Notifications, In-app Notifications and Email Notifications",
    notification_settings_option_description_id_recommendations: "These are notifications with our latest recommendations for you.",

    notification_settings_option_title_id_message_received: "Messaging",
    notification_settings_option_subtitle_id_message_received: "Push, In-app and Email",
    notification_settings_option_description_id_message_received: "These are notifications letting you know you've received messages.",

    notification_settings_option_title_id_saved_searches: "Saved Searches",
    notification_settings_option_subtitle_id_saved_searches: "Push, In-app and Email",
    notification_settings_option_description_id_saved_searches: "These are notifications for your saved searches alerts.",

    notification_settings_option_title_id_followed_companies: "Followed Companies",
    notification_settings_option_subtitle_id_followed_companies: "Push, In-app and Email",
    notification_settings_option_description_id_followed_companies: "These are notifications to notify you when a company your follow posts a job.",

    notification_settings_option_title_id_promoted_jobs: "High Priority Jobs",
    notification_settings_option_subtitle_id_promoted_jobs: "Push, In-app and Email",
    notification_settings_option_description_id_promoted_jobs: "These are notifications for high priority jobs that might be suitable for you.",

    notification_settings_option_title_id_application_reviewed: "Job Applications Reviews",
    notification_settings_option_subtitle_id_application_reviewed: "Push, In-app and Email",
    notification_settings_option_description_id_application_reviewed: "These are notifications to inform you when employers review your applications.",

    notification_settings_option_title_id_subscription_activated: "Subscription Activated",
    notification_settings_option_subtitle_id_subscription_activated: "Push, In-app and Email",
    notification_settings_option_description_id_subscription_activated: "These are notifications letting you know your subscription has been updated.",

    notification_settings_option_title_id_new_application_received: "New Application Received",
    notification_settings_option_subtitle_id_new_application_received: "Push, In-app and Email",
    notification_settings_option_description_id_new_application_received: "These are notifications letting you know you've received a new application.",

    notification_settings_option_title_id_application_updated: "Job Application Updates",
    notification_settings_option_subtitle_id_application_updated: "Push, In-app and Email",
    notification_settings_option_description_id_application_updated: "These are notifications letting you know when a candidate updates their application.",

    notification_settings_option_title_id_daily_applications_overview: "Daily Applications Overview",
    notification_settings_option_subtitle_id_daily_applications_overview: "Push, In-app and Email",
    notification_settings_option_description_id_daily_applications_overview: "These are notifications giving you a daily applications overview.",

    notification_settings_option_title_id_job_posted: "Job Posted",
    notification_settings_option_subtitle_id_job_posted: "Push, In-app and Email",
    notification_settings_option_description_id_job_posted: "These are notifications letting you know that your job has been posted.",

    notification_settings_option_title_id_job_about_to_expire: "Job About To Expire",
    notification_settings_option_subtitle_id_job_about_to_expire: "Push, In-app and Email",
    notification_settings_option_description_id_job_about_to_expire: "These are notifications to inform you that your job post is about to expire.",

    notification_settings_option_title_id_job_expired: "Job Expired",
    notification_settings_option_subtitle_id_job_expired: "Push, In-app and Email",
    notification_settings_option_description_id_job_expired: "These are notifications to notify you when your job post expired.",

    notification_settings_option_title_id_subscription_about_to_expire: "Subscription About to Expire",
    notification_settings_option_subtitle_id_subscription_about_to_expire: "Push, In-app and Email",
    notification_settings_option_description_id_subscription_about_to_expire: "These are notifications letting you know your subscription is about to expire.",

    notification_settings_option_title_id_subscription_expired: "Subscription Expired",
    notification_settings_option_subtitle_id_subscription_expired: "Push, In-app and Email",
    notification_settings_option_description_id_subscription_expired: "These are notifications letting you know your subscription has expired.",

    notification_channel_title_id_email: "Email",
    notification_channel_subtitle_id_email: "Sent on your primary email",
    notification_channel_title_id_in_app: "In-app Notifications",
    notification_channel_subtitle_id_in_app: "Delivered inside the app",
    notification_channel_title_id_push_notifications: "Push Notifications",
    notification_channel_subtitle_id_push_notifications: "Pushed to your device immediately.",

    notification_settings_update_success: "Your notification preferences have been set successfully.",
    notification_settings_update_fail: "Failed to set your notification preferences. Try again later.",

    notification_settings: "Notifications Settings",
    manage_notification_settings: "Manage Your Notification Settings",

    notification_center_disabled_tooltip: "This notification channel is not available at the moment.",

    notification_settings_category_title_id_job_search: "Job Search",
    notification_settings_category_title_id_messaging: "Messaging",
    notification_settings_category_title_id_billing: "Billing",
    notification_settings_category_title_id_applications: "Job Applications",
    notification_settings_category_title_id_job_posting: "Job Posts",

    stage_id_enum_NEW: "New",
    stage_id_enum_RESUME_SCREENING: "Resume Screening",
    stage_id_enum_WAITING_FOR_INTERVIEW: "Waiting for Interview",
    stage_id_enum_WAITING_FOR_JOB_SEEKER: "Waiting for Job Seeker",
    stage_id_enum_TO_INTERVIEW_IN_PERSON: "To interview In-Person",
    stage_id_enum_REFERENCES_AND_BACKGROUND_CHECK: "References and Background Check",
    stage_id_enum_FINAL_DECISION_WAITING_LIST: "Final Decision Waiting List",
    stage_id_enum_JOB_OFFERING: "Job Offering",
    stage_id_enum_REJECTED: "Rejected",
    stage_id_enum_CANDIDATE_WITHDREW: "Candidate Withdrew",
    stage_id_enum_HIRED: "Hired",
    stage_enum_filter_label: "Stage",

    candidate_notes: "Notes",
    candidates_notes_subtitle: "Add short notes about this candidate for other company users to see. These are for internal use only so only you and your company users will be able to view the notes.",
    
    full_name: "Full Name",
    drop_resume_text: "Drop CV/Resume here.",
    add_manual_application: "Add Manual Application",
    add_manual_application_title: "Add Manual Application",
    select_jobs_for_manual_applicants: "Select the job(s) to which the user applied.",
    submit_to_ats: "Add Manual Applicant",
    add_manual_application_caption: "You can import applications submitted via email, phone or in-person channels. This will allow you to manage all your candidates in one place.",
    manual_application_indidator_text: "This application was manually added to the ATS",

    upload_logo_dimensions: "Dimensions: 200px X 200px | Ratio: 1 : 1",
    upload_cover_photo_dimensions: "Dimensions: 820px X 205px | Ratio: 4 : 1",
    photo_dimensions: "Dimensions: {{ dimensions }} | Ratio: {{ ratio }}",
    add_manual_application_success: "Manual application was saved successfully.",
    add_manual_application_fail: "Failed to create a manual application. Try again later.",
    
    applicant_not_in_any_folder: "This application is not assigned to any lists. Click on add to assign it to a list or to create a new list.",
    candidate_folders: "Application Lists",
    folders: "Application Lists",
    candidates_folders_subtitle: "You can create lists to assign applications to your jobs. You can then filter applications by these lists in the filters section.",
    add_candidate_to_folder: "Add candidate to list",
    add_new_folder: "Create new list",
    add_new_folder_caption: "You can create lists to assign applications to your jobs that you can use to filter your applications.",
    no_folders_search_results: "You don't have any application lists currently.",
    folder_filter_label: "Application Lists",
    create_folder: "Create List",
    hide_user_details: "Hide user details",
    add_applicant_to_folder: "Add application to a list",
    add_applicant_to_folder_caption: "Select the list to add this application.",
    manage_folders: "Manage Application Lists",
    add: "Add",
    applicant_folders: "Application Lists",
    manage_applicant_folder_subtext: "Manage your application lists.",
    folders_empty: "You currently don't have any application lists.",
    lists: "Lists",

    no_jobs_to_assign_applications: "You don't have active jobs to assign this manual application.",
    remove_applicant_from_folder_fail: "Failed to remove applicant from list",
    remove_applicant_from_folder_success: "Successfully removed the application to the list.",
    folder_action_error_create: "Failed to create a new list. Try again later.",
    folder_action_error_edit: "Failed to update this list. Try again later.",
    folder_action_success_create: "Successfully created a new applications list.",
    folder_action_success_edit: "Successfully updated the applications list.",
    add_application_folder_success: "Successfully added the application to a list.",
    add_application_folder_error: "Failed to add this application to a list.",
    
    what_is_this: "What is this?",
    preview_open_graph_tags: "Show Facebook Link Share Preview",
    hide_preview_open_graph_tags: "Hide Preview",

    ats_feature_announcement: "New Feature Alert: Applicant Tracking System",
    ats_feature_announcement_subtitle: "We have added ATS features to our system. You can subscribe to the ATS add on for free. Features include: ",
    ats_feature_announcement_manual_applications: "Add and manage manual applications.",
    ats_feature_announcement_tag_applications_to_lists: "Add applications to lists and filter using these lists.",
    ats_feature_announcement_expanded_applications_stages: "Expanded list of applications stages.",

    applicants_tracking_system_add_on: "Applicant Tracking System (ATS) Add On",
    applicants_tracking_system_add_on_subtitles: "We are introducing tools for our employers to be able to manage all their candidates, submitted either in person, via email or through the İş Kıbrıs jobs portal.",
    applicants_tracking_system_add_on_features_title: "ATS Features",
    applicants_tracking_system_add_on_features_subtitle: "Here are some of the features that we have implemented as part of the ATS system.",
    applicants_tracking_system_add_on_features_subtitle_more_functionality: "More functionality will continuously be added to the system.",
    applicants_tracking_system_add_on_learn_more: "Want to learn more about the new features?",
    ats_free_subscription_availability: "The ATS functionality will be available for free until {{ charging_start_date }}",
    activate_ats_addon: "Activate ATS Add On",
    ats_subscription_success: "Successfully added the ATS subscription addon.",
    ats_subscription_error: "Failed to add the ATS subscription.",
    go_to_ats_system_landing_pages: "Go to ATS subscription page",
    billing_cannot_use_ats_system: "You cannot access this feature at the moment because it's an ATS feature and you need to activate the addon. Click below to add the ATS subscription for free.",
    ats_subscription_already_active: "The ATS addon is already active on this company. You can use the features that are included in this package.",
    folder_disabled_filter_label: "Application Lists",

    city_id_placeholder: "City, municipality, district, zip code",
    search_and_select_locations: "Search and select locations to filter applicants.",
    loading: "Loading",
    empty_text: "No Options",
    place: "Place",
    place_filter_label: "Location",
    job_sponsorship_warning_text: "If you sponsor, your job it will appear in the Featured Jobs section for “15 days”. If there are less than 15 days left before your advertisement expires, 15 days will be automatically added to the publication period after payment.",
    discount_deal_text: "save up to {{discount_percent}} with our black friday deal",
    stage_id_enum_label: "Stage ENUM",
    place_label: "Place",
    sponsor_job_warning_title: "Sponsor Job: {{job}}",
    sponsor_job_warning: "If you purchase this package, your job will be published in the Featured Jobs section for 15 days.",
    continue: "Continue",
    buy_new_package: "Buy new package",
    subscription_completed: "Complete",
    billing_information: "Billing Information",
    invoices: "Invoices",
    user_account_settings: "My Settings",
    join_your_team_title: "Join Your Team on İşKıbrıs",
    join_your_team_subtitle: "You have been invited to join your team. Sign up and start collaborating with your team today.",
    join_your_team_page_title: "İş Kıbrıs Hiring Panel - Join Your Team",
    are_you_looking_for_billing: "Do you want to manage your account billing and packages?",
    go_to_billing: "Manage Billing Account",
    billing_settings_required_for_e_fatura: "Billing information (e.g company registration number, customer id number) is required in order to create your E-Fatura.",
    add_billing_settings: "Add your billing information.",
    processing: "Processing",
    download_invoice: "Download",
    invoice_for: "Invoice for {{product}}",
    payment: "Payment",
    billing_history: "Billing History",
    date: "Date",
    add_billing_settings_information: "Add Invoicing Information",
    amount: "Amount",
    applications_list_mode: "List",
    applications_kanban_mode: "Board",
    comments_count_label: "{{count}} notes"
  }
}