import React from "react"

import { Text, View, StyleSheet } from "@react-pdf/renderer"

interface Props {
  wrapped?: boolean
  title: string
  children: any
}

const Section: React.FC<Props> = ({ wrapped, title, children }) => {
  return (
    <View wrap={!!wrapped}>
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View style={styles.body}>{children}</View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    padding: "0cm 2cm"
  },
  titleContainer: {
    borderBottom: "1px solid #a2a2a2"
  },
  title: {
    fontSize: "18px",
    margin: "8px 0px",
    fontWeight: 700
  },
  body: {
    margin: "8px 0px"
  }
})

export default Section
