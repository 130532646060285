const channels = [
  {
    value: 1,
    name: "email",
    title: "Email",
    subtitle: "Sent on your primary email",
  },
  {
    value: 1,
    name: "in_app",
    title: "In-app notifications",
    subtitle: "Delivered inside the app",
  },
  {
    value: 1,
    name: "push_notifications",
    title: "Push notifications",
    subtitle: "Pushed to your device immediately",
  }
]

const notificationSettings = [
  {
    name: "applications",
    title: "Hiring Someone",
    options: [
      {
        name: "new_application_received",
        title: "New Application Received",
        subtitle: "Push, In-app and Email",
        description: "These are notifications letting you know you've received a new application.",
        channels: channels
      },
      {
        name: "application_updated",
        title: "Job Application Updates",
        subtitle: "Push, In-app and Email",
        description: "These are notifications letting you know when a candidate updates their application.",
        channels: channels
      },
      {
        name: "daily_applications_overview",
        title: "Daily Applications Overview",
        subtitle: "Push, In-app and Email",
        disabled: 1,
        description: "These are notifications giving you a daily applications overview.",
        channels: channels.map(item => {
          return {
            ...item,
            disabled: 1
          }
        })
      }
    ],
  },
  {
    name: "job_posting",
    title: "Job Posts",
    options: [
      {
        name: "job_posted",
        title: "Job Posted",
        subtitle: "Push, In-app and Email",
        description: "These are notifications letting you know that your job has been posted.", 
        channels: channels
      },
      {
        name: "job_about_to_expire",
        title: "Job About To Expire",
        subtitle: "Push, In-app and Email",
        description: "These are notifications to inform you that your job post is about to expire.",
        channels: channels
      },
      {
        name: "job_expired",
        title: "Job Expired",
        subtitle: "Push, In-app and Email",
        description: "These are notifications to notify you when your job post expires.",
        channels: channels
      },
    ],
  },
  {
    name: "billing",
    title: "Billing",
    options: [
      {
        name: "subscription_activated",
        title: "Subscription Activated",
        subtitle: "Push, In-app and Email",
        description: "These are notifications letting you know your subscription has been updated.",
        channels: channels
      },
      {
        name: "subscription_about_to_expire",
        title: "Subscription About to Expire",
        subtitle: "Push, In-app and Email",
        description: "These are notifications letting you know your subscription is about to expire.",
        channels: channels
      },
      {
        name: "subscription_expired",
        title: "Subscription Expired",
        subtitle: "Push, In-app and Email",
        description: "These are notifications letting you know your subscription has expired.",
        channels: channels
      }
    ],
  },
  {
    name: "messaging",
    title: "Messaging",
    options: [
      {
        name: "message_received",
        title: "Messages",
        subtitle: "Push, In-app and Email",
        description: "These are notifications letting you know you've received messages.",
        channels: channels
      }
    ],
  },
]

export { notificationSettings }