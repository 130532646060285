import React from "react"

import { Dispatch } from "redux"
import { connect } from "react-redux"

// import CircularProgress from '@material-ui/core/CircularProgress';

import Menu from "@material-ui/core/Menu"
import Avatar from "@material-ui/core/Avatar"
import MenuItem from "@material-ui/core/MenuItem"
import ListItem from "@material-ui/core/ListItem"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

import OptionsIcon from "@material-ui/icons/MoreHoriz"
import Edituser from "../EditUser"
import useStyles from "./styles"
import { IActionType } from "../../../../types"
import { ICompanyUser } from "../../../../types/company"
import { useTranslation } from "react-i18next"

interface Props {
  user: ICompanyUser
  handleDelete: (id: any) => void
}

interface LongMenuProps {
  initialValues: any
  editingStatus: boolean
  handleCancel: () => void
  handleEditClick: () => void
  handleDeleteClick: () => void
}

const LongMenu: React.FC<LongMenuProps> = props => {
  // const classes = useStyles();

  const options = ["Edit", "Delete"]
  const ITEM_HEIGHT = 48
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleItemClicked = (value: any) => {
    setAnchorEl(null)
    if (value === options[0]) {
      props.handleEditClick()
    } else {
      props.handleDeleteClick()
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} style={{ padding: "3px" }}>
        <OptionsIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200
          }
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option}
            onClick={() => {
              handleItemClicked(option)
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      {props.editingStatus ? (
        <Edituser editingStatus={props.editingStatus} initialValues={{ ...props.initialValues }} handleClose={props.handleCancel} user={props.initialValues} />
      ) : null}
    </div>
  )
}

const JobCard: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  // const [loading, setLoading] = React.useState(false);

  let roles = ""

  if (props.user.roles) {
    if (props.user.roles.length > 0) {
      ;[...props.user.roles].forEach(item => {
        if (roles.length === 0) {
          roles = roles + t(item)
        } else {
          roles = roles + ", " + t(item)
        }
      })
    }
  }

  const [editingStatus, setEditingStatus] = React.useState(false)

  const handleDelete = () => {
    props.handleDelete(props.user.id)
  }

  const handleEditClick = () => {
    setEditingStatus(!editingStatus)
  }

  const handleEditCancel = () => {
    setEditingStatus(false)
  }

  const extractInitials = (val: string) => {
    const wordsArr = val.split(" ")
    let initials = ""
    wordsArr.forEach(item => {
      if (item.length > 0) {
        initials = initials + item[0]
      }
    })
    return initials
  }

  return (
    <div className={classes.root}>
      <ListItem className={classes.listItem}>
        <ListItemAvatar>
          <Avatar src={props.user.avatar_url || ""} alt="">
            {extractInitials(props.user.name)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<Typography variant="body1">{props.user.name}</Typography>}
          secondary={
            <React.Fragment>
              <Typography variant="body2" component="span" style={{ display: "block" }} className={classes.email}>
                {props.user.email}
              </Typography>
              <Typography variant="caption" component="span" style={{ display: "block" }} className={classes.roles}>
                {roles}
              </Typography>
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction style={{ right: "0px" }}>
          <LongMenu handleEditClick={handleEditClick} handleDeleteClick={handleDelete} editingStatus={editingStatus} initialValues={props.user} handleCancel={handleEditCancel} />
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => ({})

export default connect(null, mapDispatchToProps)(JobCard)
