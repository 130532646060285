import React from "react"

import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"

import { useHistory } from "react-router"
import { useTranslation } from "react-i18next"
import DeleteConfirmation from "../../DeleteConfirmation"
import { ICompany } from "../../../types/company"

interface Props {
  loading?: boolean
  company: ICompany
  deleteCompany: (id: any) => Promise<void>
}

const ActionButtons: React.FC<Props> = props => {
  const { t } = useTranslation()
  const history = useHistory()

  // const handleCancel = () => {
  //   history.goBack();
  // }
  const handleDeleteClick = async () => {
    await props.deleteCompany(props.company.id)
    history.push("/dashboard")
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <DeleteConfirmation onConfirm={handleDeleteClick} question={t("confirmation_question")} warning={t("delete_company_warning")}>
        <Button variant="outlined" color={"primary"}>
          {t("delete_company")}
        </Button>
      </DeleteConfirmation>
      <Button variant="contained" color="primary" type={"submit"} disabled={props.loading ?? false}>
        {props.loading ? <CircularProgress size={22} style={{ color: "#fff" }} /> : t("submit")}
      </Button>
    </div>
  )
}

export default ActionButtons
