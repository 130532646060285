import { action } from "typesafe-actions"
import { actionTypes } from "./actionTypes"

export const flashActions = {
  addFlashMessage: (body: any, type: "success" | "error" | "info" | "warning") => {
    const message = { id: Math.random(), type: type, message: body }
    return action(actionTypes.ADD_FLASH_MESSAGE, message)
  },
  removeFlashMessage: (id: any) => {
    return action(actionTypes.REMOVE_FLASH_MESSAGE, id)
  }
}
