import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    display: "inline"
  },
  chip: {
    margin: "2.5px"
    // marginRight: "2.5px",
  }
}))

export default useStyles
