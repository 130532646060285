import React from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import TableRow from "@material-ui/core/TableRow"
import Checkbox from "@material-ui/core/Checkbox"
import TableCell from "@material-ui/core/TableCell"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControlLabel from "@material-ui/core/FormControlLabel"

import EditIcon from "@material-ui/icons/EditOutlined"
import SaveIcon from "@material-ui/icons/SaveAltOutlined"
import CancelIcon from "@material-ui/icons/CancelOutlined"
import useStyles from "../styles"
import { ICompanyBenefit } from "../../../../types/company"
import { Dispatch } from "redux"
import { IActionType } from "../../../../types"
import { companyActions } from "../../../../store/actions/company"
import Can from "../../../../utils/AccessControl/Can"
import AccessDeniedTooltip from "../../../AccessDeniedToolTip"
// import AccessDeniedTooltip from '../../../AccessDeniedToolTip';

interface EditProps {
  description: any
  handleSubmit: () => void
  handleCancel: () => void
  handleChange: (event: any) => void
}

interface Props {
  benefit: ICompanyBenefit
  editBenefit: (value: any, id: any) => void
}

const AddEditDescription: React.FC<EditProps> = props => {
  const classes = useStyles()

  const { t } = useTranslation()

  const [savingState, setSavingState] = React.useState(false)

  const handleMouseDownBenefit = (event: any) => {
    event.preventDefault()
  }

  const handleFormSubmit = async (event: any) => {
    event.preventDefault()
    setSavingState(true)
    await props.handleSubmit()
    setSavingState(false)
  }

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <TextField
          value={props.description}
          onChange={props.handleChange}
          variant="outlined"
          margin="dense"
          // multiline
          fullWidth
          rows={1}
          rowsMax={4}
          autoFocus
          placeholder={t("add_your_note_here")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!savingState ? (
                  <React.Fragment>
                    <IconButton
                      edge="end"
                      style={{ marginRight: 0 }}
                      className={classes.saveIconButton}
                      aria-label="toggle password visibility"
                      onClick={props.handleSubmit}
                      onMouseDown={handleMouseDownBenefit}
                    >
                      <SaveIcon />
                    </IconButton>
                    <Divider className={classes.divider} orientation="vertical" />
                    <IconButton color="primary" className={classes.cancelIconButton} style={{ marginRight: "-12px" }} aria-label="directions" onClick={props.handleCancel}>
                      <CancelIcon />
                    </IconButton>
                  </React.Fragment>
                ) : (
                  <CircularProgress size={20} />
                )}
              </InputAdornment>
            )
          }}
        />
      </form>
    </div>
  )
}

const SingleBenefit: React.FC<Props> = props => {
  const { i18n, t } = useTranslation()

  const classes = useStyles()

  const [editingState, setEditingState] = React.useState(false)
  const [selectingState, setSelectingState] = React.useState(false)
  const [description, setDesctiption] = React.useState(props.benefit.description_tr || props.benefit.description_en)

  const handleEditClick = () => {
    setEditingState(!editingState)
  }

  const handleCancelClick = () => {
    setEditingState(false)
  }

  const handleBenefitDescChange = (event: any) => {
    setDesctiption(event.target.value)
  }

  const handleUpdateSubmit = async () => {
    let newDesc = {
      description_tr: description
    }
    await props.editBenefit(newDesc, props.benefit.id)
    setEditingState(false)
  }

  const handleCheck = async (event: any) => {
    let newVal = 1
    if (props.benefit.is_selected === 1) {
      newVal = 0
    }
    setSelectingState(true)
    await props.editBenefit({ is_selected: newVal }, props.benefit.id)
    setSelectingState(false)
  }

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableCell} style={{ width: "30%" }}>
        {props.benefit.is_selected === 1 ? (
          <React.Fragment>
            <FormControlLabel
              control={
                !selectingState ? (
                  <Checkbox checked={props.benefit.is_selected === 1} onChange={handleCheck} value={1} color="primary" />
                ) : (
                  <CircularProgress size={18} style={{ margin: "12px" }} />
                )
              }
              label={i18n.language === "tr" ? props.benefit.name_tr : props.benefit.name_en}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Can I="create-benefits" a="all">
              <FormControlLabel
                control={
                  !selectingState ? (
                    <Checkbox checked={props.benefit.is_selected === 1} onChange={handleCheck} value={1} color="primary" />
                  ) : (
                    <CircularProgress size={18} style={{ margin: "12px" }} />
                  )
                }
                label={i18n.language === "tr" ? props.benefit.name_tr : props.benefit.name_en}
              />
            </Can>
            <Can not I="create-benefits" a="all">
              <AccessDeniedTooltip link={"/billing/subscriptions"} linkLabel={t("go_to_subscriptions")} body={t("billing_cannot_create-benefits")}>
                <FormControlLabel
                  control={<Checkbox value={1} color="primary" checked={props.benefit.is_selected === 1} disabled={props.benefit.is_selected === 0} />}
                  label={i18n.language === "tr" ? props.benefit.name_tr : props.benefit.name_en}
                />
              </AccessDeniedTooltip>
            </Can>
          </React.Fragment>
        )}
      </TableCell>
      <TableCell className={classes.tableCell} style={{ width: "55%" }}>
        {editingState ? (
          <AddEditDescription description={description} handleCancel={handleCancelClick} handleSubmit={handleUpdateSubmit} handleChange={handleBenefitDescChange} />
        ) : props.benefit.description_tr || props.benefit.description_en ? (
          <div>
            <Typography variant="body1" style={{ display: "inline", textAlign: "justify", paddingTop: "5px" }} onClick={handleEditClick}>
              {props.benefit.description_tr || props.benefit.description_en}
            </Typography>
            <IconButton style={{ float: "right", padding: "2px" }} onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
          </div>
        ) : (
          <Button variant="text" color="secondary" style={{ paddingLeft: "0px" }} onClick={handleEditClick}>
            {t("add_description")}
          </Button>
        )}
      </TableCell>
    </TableRow>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    editBenefit: async (information: any, benefitId: any) => dispatch(await companyActions.updateCompanyBenefit(information, benefitId))
  }
}

export default connect(null, mapDispatchToProps)(SingleBenefit)
