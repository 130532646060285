import React from "react"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import Button from "@material-ui/core/Button"
import { IoMailOutline } from "react-icons/io5"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"

import useStyles from "../styles"
import { IActionType } from "../../../types"
import { messagesActions } from "../../../store/actions/messages"
import Can from "../../../utils/AccessControl/Can"
import AccessDeniedTooltip from "../../AccessDeniedToolTip"
// import AccessDeniedTooltip from '../../AccessDeniedToolTip';

interface Props {
  applicants: any
  openSendMessage: (recipients: any) => void
}

const MessageAll: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleClick = () => {
    if (props.applicants) {
      props.openSendMessage(props.applicants.data)
    }
  }

  return (
    <div>
      <Can I="send-message-to-applicants" a="all">
        <Button onClick={handleClick} className={classes.textbutton}>
          <IoMailOutline className={classes.icon} />
          <Typography className={classes.text}>{t("message_all")}</Typography>
        </Button>
        <IconButton onClick={handleClick} className={classes.iconbutton}>
          <IoMailOutline className={classes.icon} />
        </IconButton>
      </Can>
      <Can not I="send-message-to-applicants" a="all">
        <AccessDeniedTooltip link={"/billing/subscriptions"} linkLabel={t("go_to_subscriptions")} body={t("billing_cannot_send-message-to-applicants")}>
          <Button disabled onClick={handleClick} className={classes.textbutton}>
            <IoMailOutline className={classes.icon} />
            <Typography className={classes.text}>{t("message_all")}</Typography>
          </Button>
          <IconButton disabled onClick={handleClick} className={classes.iconbutton}>
            <IoMailOutline className={classes.icon} />
          </IconButton>
        </AccessDeniedTooltip>
      </Can>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    applicants: state.applicantsReducer.applicants
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    openSendMessage: (recipients: any) => dispatch(messagesActions.openSendMessage(recipients))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageAll)
