import Cookies from "js-cookie"
import { IActionType } from "../../types"
import { actionTypes } from "../actions/actionTypes"

interface IImpersonationModel {
  impersonator: any | null
  is_impersonating: boolean | null
  impersonation_user_id: any | null
  is_impersonation_token_set: boolean | null
}

const impersonation = Cookies.get("impersonation") 
const impersonationToken = Cookies.get("impersonation_auth_token")
const impersonationUserId = Cookies.get("impersonation_user_id") ?? ""

const initialState: IImpersonationModel = {
  impersonator: null,
  is_impersonating: impersonation === "1",
  is_impersonation_token_set: impersonationToken !== null && impersonationToken !== undefined,
  impersonation_user_id: impersonationUserId
}

const reducer = (state: IImpersonationModel = initialState, action: IActionType) => {
  switch (action.type) {
    case actionTypes.CHANGE_IMPERSONATION_STATE:
    return {
      ...state,
      is_impersonating: action.payload?.is_impersonating
    }
    case actionTypes.CHANGE_IMPERSONATION_TOKEN_SET_STATE: {
      return {
        ...state,
        is_impersonation_token_set: action.payload?.is_impersonation_token_set
      }
    }
    case actionTypes.CHANGE_IMPERSONATION_USER_ID: {
      return {
        ...state,
        impersonation_user_id: action.payload?.impersonation_user_id
      }
    }
    case actionTypes.ADD_IMPERSONATOR: {
      return {
        ...state,
        impersonator: {...action.payload}
      }
    }
    case actionTypes.ADD_IMPERSONATION_DATA: {
      return {
        ...state,
        ...action.payload
      }
    }
    case actionTypes.CLEAR_IMPERSONATION_DATA: {
      return {
        ...initialState
      }
    }
    default:
      break
  }
  return state
}

export default reducer
