/* eslint-disable react/prop-types */
import React, { useState } from "react"
import axios from "../../../../api/iskibris/iskibris"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import Button from "@material-ui/core/Button"
import Toolbar from "@material-ui/core/Toolbar"
import Divider from "@material-ui/core/Divider"
import Checkbox from "@material-ui/core/Checkbox"
import Backdrop from "@material-ui/core/Backdrop"
import Typography from "@material-ui/core/Typography"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import CircularProgress from "@material-ui/core/CircularProgress"

import useStyles from "../styles"
import NotificationSnackbar from "../../../NotificationSnackbar"
import { ICompany, ICompanyUser } from "../../../../types/company"
import { Dispatch } from "redux"
import { IActionType } from "../../../../types"
import { companyActions } from "../../../../store/actions/company"

interface Props {
  company: ICompany
  initialValues: any
  user: ICompanyUser
  editingStatus: boolean
  fetchCompanyUsers: (id: any) => void
  handleClose: () => void
}

const EditUser: React.FC<Props> = props => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [formValues, setValues] = useState({
    user_id: props.initialValues.user_id,
    roles: [...props.user.roles]
  })

  const [loading, setLoading] = useState(false)
  const [editStatus, setEditStatus] = useState("")

  const handleChange = (event: any) => {
    const ndx = formValues.roles.findIndex(item => item === event.target.value)
    let newRoles = [...formValues.roles]
    if (ndx === -1) {
      newRoles.push(event.target.value)
    } else {
      newRoles = [...formValues.roles].filter(item => item !== event.target.value)
    }
    setValues({
      ...formValues,
      roles: newRoles
    })
  }

  const isChecked = (value: any) => {
    const ndx = [...formValues.roles].findIndex(item => item === value)
    if (ndx === -1) {
      return false
    }
    return true
  }

  const handleSaveClick = async () => {
    const vals = [...formValues.roles].filter(item => item.length > 0)
    const infor = { roles: vals.join(",") }
    setLoading(true)
    await axios
      .put(`/hiring/api/companies/${props.company.id}/users/${props.user.id}`, infor)
      .then(response => {
        if (response.status === 200) {
          setEditStatus("success")
          props.fetchCompanyUsers(props.company.id)
        }
        setTimeout(() => {
          props.handleClose()
        }, 1000)
      })
      .catch(err => {
        setEditStatus("fail")
      })
    setLoading(false)
  }

  const handleCancelClick = () => {
    props.handleClose()
  }

  return (
    <div
      style={
        {
          // float: "right"
        }
      }
    >
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.editingStatus}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={props.editingStatus}>
          <div className={classes.modalPaper}>
            <Toolbar variant="dense" className={classes.toolbar}>
              <Typography variant="h6" style={{ textAlign: "center" }} gutterBottom>
                {t("edit_permission_level")}
              </Typography>
            </Toolbar>
            <div className={classes.contents}>
              <Typography variant="body1">
                {t("edit_user_subtext")}
                {props.user.name}
              </Typography>
              <Divider style={{ marginTop: "15px" }} />
              <div style={{ marginTop: "15px" }}>
                <RadioGroup value={formValues.roles} onChange={handleChange}>
                  <div>
                    <FormControlLabel control={<Checkbox checked={isChecked("employer")} onChange={handleChange} value="employer" />} label={t("employer")} />
                    {/* <FormControlLabel value="employer" control={<Radio />} label={t('employer')} /> */}
                    <Typography variant="body2" style={{ marginLeft: 31, color: "#797979" }} gutterBottom>
                      {t("employer_subtitle")}
                    </Typography>
                  </div>
                  <div>
                    <FormControlLabel control={<Checkbox checked={isChecked("editor")} onChange={handleChange} value="editor" />} label={t("editor")} />
                    {/* <FormControlLabel value="editor" control={<Radio />} label={t("editor")} /> */}
                    <Typography variant="body2" style={{ marginLeft: 31, color: "#797979" }} gutterBottom>
                      {t("editor_subtitle")}
                    </Typography>
                  </div>
                  <div>
                    <FormControlLabel control={<Checkbox checked={isChecked("observer")} onChange={handleChange} value="observer" />} label={t("observer")} />
                    {/* <FormControlLabel value="observer" control={<Radio />} label={t('observer')} /> */}
                    <Typography variant="body2" style={{ marginLeft: 31, color: "#797979" }} gutterBottom>
                      {t("observer_subtitle")}
                    </Typography>
                  </div>
                </RadioGroup>
              </div>
              <div className={classes.buttons}>
                <Button style={{ float: "right", backgroundColor: "#221133", color: "white", marginLeft: "10px" }} onClick={handleSaveClick}>
                  {loading ? <CircularProgress size={22} style={{ color: "white" }} /> : t("save")}
                </Button>
                <Button variant="outlined" color="primary" onClick={handleCancelClick}>
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <NotificationSnackbar
        status={editStatus}
        success_msg="User credentials were successfully edited."
        error_msg="Failed to edit user's credentials. Try again!"
        handleClose={() => {
          setEditStatus("")
        }}
      />
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    company: state.companyReducer.company
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => {
  return {
    // editUser: async (info, userId,  companyId) => dispatch(await actionCreators.updateCompanyUsers(info, userId, companyId)),
    fetchCompanyUsers: async (companyId: any) => dispatch(await companyActions.fetchCompanyUsers(companyId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser)
