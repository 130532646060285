import React from "react"
import { useTranslation } from "react-i18next"

interface Props {
  type?: "success" | "error" | "info" | "warning"
  content: string
  onDismiss?: () => void
}

const Snackbar: React.FC<Props> = ({ content, type, onDismiss }) => {
  const { t } = useTranslation()

  const handleDismiss = () => {
    if (onDismiss) {
      onDismiss()
    }
  }

  return (
    <div className={`paper-snackbar paper-snackbar--appear ${type ? "paper-snackbar--" + type : ""}`}>
      {content}
      <button onClick={handleDismiss} className={`action ${type ? "action--" + type : ""}`}>
        {t("dismiss")}
      </button>
    </div>
  )
}

export default Snackbar
