import axios from "../../api/iskibris/iskibris"
import { AxiosResponse } from "axios"
import { action } from "typesafe-actions"
import { actionTypes } from "./actionTypes"
import HTTPResponses from "../../tools/http.responses"
import { fetch } from "../../api/iskibris/redux-api-methods"

export const billingActions = {
  fetchCompanySubscriptions: async (id: any) => {
    let response: AxiosResponse
    try {
      response = await axios.get(`/api/hiring/v2/companies/${id}/subscriptions`)
    } catch (error: any) {
      response = error?.response
    }
    if (response?.status === HTTPResponses.OK) {
      return action(actionTypes.ADD_SUBSCRIPTION_HISTORY, response?.data)
    } else if (response?.status === HTTPResponses.NO_CONTENT) {
      return action(actionTypes.ADD_BILLING_CLIENT_NOT_REGISTERED_ERROR, response?.data)
    } else {
      return action(actionTypes.ADD_BILLING_ERRORS, response.data)
    }
  },
  cancelCompanySubscriptions: async (companyId: any, subscriptionId: any) => {
    let response: AxiosResponse
    try {
      response = await axios.get(`/api/hiring/v2/companies/${companyId}/subscriptions/${subscriptionId}/cancel`)
    } catch (error: any) {
      response = error?.response
    }
    if (response?.status === HTTPResponses.OK) {
      return action(actionTypes.ADD_SUBSCRIPTION_HISTORY, response?.data)
    } else if (response?.status === HTTPResponses.NO_CONTENT) {
      return action(actionTypes.ADD_BILLING_CLIENT_NOT_REGISTERED_ERROR, response?.data)
    } else {
      return action(actionTypes.ADD_BILLING_ERRORS, response.data)
    }
  },
  fetchCompanyTransactions: async (id: any) => {
    let response: AxiosResponse
    try {
      response = await axios.get(`/api/hiring/v2/companies/${id}/transactions`)
    } catch (error: any) {
      response = error?.response
    }
    if (response?.status === HTTPResponses.OK) {
      return action(actionTypes.ADD_PAYMENT_HISTORY, response?.data)
    } else if (response?.status === HTTPResponses.NO_CONTENT) {
      return action(actionTypes.ADD_BILLING_CLIENT_NOT_REGISTERED_ERROR, response?.data)
    } else {
      return action(actionTypes.ADD_BILLING_ERRORS, response.data)
    }
  },
  fetchCompanyInvoices: async (id: any) => {
    let response: AxiosResponse
    try {
      response = await axios.get(`/api/hiring/v2/companies/${id}/invoices`)
    } catch (error: any) {
      response = error?.response
    }
    if (response?.status === HTTPResponses.OK) {
      return action(actionTypes.ADD_INVOICES, response?.data)
    } else if (response?.status === HTTPResponses.NO_CONTENT) {
      return action(actionTypes.ADD_BILLING_CLIENT_NOT_REGISTERED_ERROR, response?.data)
    } else {
      return action(actionTypes.ADD_BILLING_ERRORS, response.data)
    }
  },
  fetchCompanyClientInvoices: async (id: any) => {
    let response: AxiosResponse
    try {
      response = await axios.get(`/api/hiring/v2/companies/${id}/client-invoices`)
    } catch (error: any) {
      response = error?.response
    }
    if (response?.status === HTTPResponses.OK) {
      return action(actionTypes.ADD_CLIENT_INVOICES, response?.data)
    } else if (response?.status === HTTPResponses.NO_CONTENT) {
      return action(actionTypes.ADD_BILLING_CLIENT_NOT_REGISTERED_ERROR, response?.data)
    } else {
      return action(actionTypes.ADD_BILLING_ERRORS, response.data)
    }
  },
  fetchCompanyJobSlots: async () => {
    let response: AxiosResponse
    try {
      response = await axios.get(`/api/hiring/v2/job-slots`)
    } catch (error: any) {
      response = error?.response
    }
    if (response?.status === HTTPResponses.OK) {
      return action(actionTypes.ADD_JOB_SLOTS, response?.data)
    } else if (response?.status === HTTPResponses.NO_CONTENT) {
      return action(actionTypes.ADD_BILLING_CLIENT_NOT_REGISTERED_ERROR, response?.data)
    } else {
      return action(actionTypes.ADD_BILLING_ERRORS, response.data)
    }
  },
  fetchBillingResults: async (key: string) => {
    let response: AxiosResponse
    try {
      response = await axios.get(`/api/hiring/v2/billing/notifications/${key}`)
    } catch (error: any) {
      response = error?.response
    }
    if (response?.status === HTTPResponses.OK) {
      return action(actionTypes.ADD_BILLING_PAYMENT_RESULT, response?.data)
    } else {
      return action(actionTypes.ADD_BILLING_PAYMENT_RESULT_EMPTY, response.data)
    }
  },
  clearBillingResults: () => {},
  fetchBillingOffers: async () => {
    return fetch({
      url: "/api/hiring/v2/packages",
      onSuccessDispatch: actionTypes.ADD_BILLING_OFFERS,
      onErrorDispatch: actionTypes.ADD_BILLING_ERRORS
    })
  },
  fetchBillingComponents: async () => {
    return fetch({
      url: "/api/hiring/v2/billing/components",
      onSuccessDispatch: actionTypes.ADD_BILLING_COMPONENTS,
      onErrorDispatch: actionTypes.ADD_BILLING_ERRORS
    })
  },
  fetchSingleBillingOffers: async (id: number) => {
    return fetch({
      url: `/api/hiring/v2/billing/offers/${id}`,
      onSuccessDispatch: actionTypes.ADD_SINGLE_BILLING_OFFER,
      onErrorDispatch: actionTypes.ADD_BILLING_ERRORS
    })
  },
  fetchSingleBillingComponents: async (id: any) => {
    return fetch({
      url: `/api/hiring/v2/billing/components/${id}`,
      onSuccessDispatch: actionTypes.ADD_SINGLE_BILLING_COMPONENT,
      onErrorDispatch: actionTypes.ADD_BILLING_ERRORS
    })
  }
}
