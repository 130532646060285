import React from "react"

import InfiniteScroll from "react-infinite-scroll-component"
import CircularProgress from "@material-ui/core/CircularProgress"

import { IApplicant, IApplicantFilters, IApplicantSearchParams, IPaginatedApplicants } from "../../../types/applicants"
// import ApplicantSummary from "../ApplicantSummary"
import useStyles from "./styles"
import { Dispatch } from "redux"
import { IActionType } from "../../../types"
import { applicantsActions } from "../../../store/actions/applicants"
import { connect, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { ICompany } from "../../../types/company"
import useGtm from "../../../hooks/useGtm"
import ApplicantCard from "../ApplicantCard"

interface Props {
  company: ICompany
  activeFilter: boolean
  filters: IApplicantFilters
  applicants: IPaginatedApplicants
  singleApplicant: IApplicant | null
  fetchCurrentUserInfo: (id: any) => void
  // openSingleApplicant: (applicant: any) => void;
  applyFilters: (filters: IApplicantSearchParams, page: number, orderBy: string | null, orderDirection: string | null) => void
  updateApplicationStatus: (id: any, info: any) => void
  clearOpenApplicant: () => void
}

const ApplicantsList: React.FC<Props> = props => {
  const classes = useStyles()
  const history = useHistory()
  const { tagEvent } = useGtm()
  const params: { id?: string } = useParams()

  const orderBy = useSelector((state: any) => state.applicantsReducer.orderBy)
  const orderDirection = useSelector((state: any) => state.applicantsReducer.orderDirection)

  const handleLoadMore = async (page: number) => {
    await props.applyFilters(
      {
        ...props.filters,
        activeFilter: props.activeFilter,
        companyId: props.company.id,
      },
      page,
      orderBy,
      orderDirection
    )
  }

  // React.useEffect(() => {
  //   if(!!params.id){

  //   }
  // });

  const onClick = (applicant: any) => {
    // props.fetchCurrentUserInfo(applicant.user_id);
    if (applicant.id !== props.singleApplicant?.id && !!applicant.id) {
      props.clearOpenApplicant()
      // props.openSingleApplicant(applicant)
    }
    if (params.id !== applicant.id) {
      history.push(`/applicants/${applicant.id}`)
    }
    if (!!applicant && applicant.stage_id_enum === "NEW" && applicant.id) {
      tagEvent({ event: "applicant_status_changed", data: { applicant_old_status: applicant?.stage_id_enum, applicant_new_status: 'RESUME_SCREENING' } })
      props.updateApplicationStatus(applicant.id, "RESUME_SCREENING")
    }
  }

  return (
    <div id="scrollableDiv" className={classes.scrollableDiv}>
      {props.applicants ? (
        <InfiniteScroll
          endMessage={<div></div>}
          scrollableTarget="scrollableDiv"
          dataLength={props.applicants.data.length}
          hasMore={props.applicants.data.length < props.applicants.total}
          next={() => {
            handleLoadMore(props.applicants.current_page + 1)
          }}
          loader={
            <div style={{ width: "100%", display: "flex", justifyContent: "center", padding: "15px" }}>
              <CircularProgress size={25} />
            </div>
          }
        >
          {props.applicants.data.map(applicant => {
            if(applicant?.company_id !== props.company?.id){
              return null
            }
            return (
              <div key={applicant?.id || Math.random()} className="applicant-list-item">
                <ApplicantCard applicant={applicant} handleClick={onClick} selected={applicant?.id === props.singleApplicant?.id} />
                {/* <ApplicantSummary applicant={applicant} handleClick={onClick} selected={applicant?.id === props.singleApplicant?.id} /> */}
              </div>
            )
          })}
        </InfiniteScroll>
      ) : null}
    </div>
  )
}

const mapStateToprops = (state: any) => ({
  company: state.companyReducer.company,
  singleApplicant: state.applicantsReducer.singleApplicant,
  filters: state.applicantsReducer.filters,
  activeFilter: state.applicantsReducer.activeFilter
})

const mapDispatchToProps = (dispatch: Dispatch<IActionType>) => ({
  // openSingleApplicant: (applicant: any) => dispatch(applicantsActions.openSingleApplicant(applicant)),
  clearOpenApplicant: () => dispatch(applicantsActions.clearOpenApplicant()),
  fetchCurrentUserInfo: async (id: any) => dispatch(await applicantsActions.fetchCurrentApplicantUserInformation(id)),
  updateApplicationStatus: async (id: any, status: any) => dispatch(await applicantsActions.updateApplicantStatus(id, status)),
  applyFilters: async (filters: any, page: number, orderBy: string | null, orderDirection: string | null) =>
    dispatch(await applicantsActions.applyFilters(filters, page, orderBy, orderDirection))
})

export default connect(mapStateToprops, mapDispatchToProps)(ApplicantsList)
