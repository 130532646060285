import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import Chip from "@material-ui/core/Chip"
import Paper from "@material-ui/core/Paper"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"

import useStyles from "../styles"
import { applicantsActions } from "../../../../store/actions/applicants"
import { removeApplicantsFromFolder } from "../../../../api/iskibris/iskibris-methods"

import AddNewFolder from "./AddNewFolder"
import FolderActions from "./FolderActions"
import AddApplicantToFolderModal from "./AddApplicantToFolder"

import HTTPResponses from "../../../../tools/http.responses"
import { flashActions } from "../../../../store/actions/flash-messaging"
import ManageApplicantFolders from "../../ManageApplicantFolders"

interface Props { }

interface SingleFolderProps {
  folder: any
  onRemove: (id?: any) => Promise<void>
}


const SingleFolder: React.FC<SingleFolderProps> = ({folder, onRemove}) => {

  const handleDelete = async () => {
    await onRemove(folder?.id)
  }

  return (
    <Chip
      label={folder?.title}
      onDelete={handleDelete}
      color="secondary"
      variant="outlined"
    />
  )
}

const CandidateFolders: React.FC<Props> = (props) => {

  const classes = useStyles()

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const company = useSelector((state: any) => state.companyReducer.company)
  const applicant = useSelector((state: any) => state.applicantsReducer.singleApplicant)

  useEffect(() => {
    const fetchFolders = async () => {
      dispatch(await applicantsActions.fetchCompanyFolders(company?.id))
    }

    fetchFolders()
  }, [company?.id, dispatch])

  const fetchSingleApplicant = async (id: any) => {
    dispatch(await applicantsActions.fetchSingleApplicant(id))
  }

  const handleRemoveApplicantFromFolder = async (id: any) => {
    await removeApplicantsFromFolder(id, applicant?.id)
      .then(response => {
        fetchSingleApplicant(applicant?.id)
      })
      .catch(error => {
        if (error?.response?.status === HTTPResponses.INTERNAL_SERVER_ERROR) {
          dispatch(
            flashActions.addFlashMessage(t("server_error_fail"), "error")
          )
        } else {
          dispatch(
            flashActions.addFlashMessage(t(`remove_applicant_from_folder_fail`), "error")
          )
          return
        }
      })
  }

  const folderActionsOptions = [
    {
      'name': "add_new_folder",
      'title': t("add_new_folder"),
      'onClick': () => {},
      'children': <AddNewFolder key={Math.random()}><div>{t("add_new_folder")}</div></AddNewFolder>
    },
    {
      'name': "add_applicant_to_folder",
      'title': t("add_applicant_to_folder"),
      'onClick': () => {},
      'children': <AddApplicantToFolderModal applicant={applicant} applicantFolders={applicant?.folders} key={Math.random()}><div>{t("add_applicant_to_folder")}</div></AddApplicantToFolderModal>
    }
  ]

  return (
    <Paper className={classes.notesSectionPaper}>
      <Divider />
      <div>
        <div className={classes.notesSectionHeaderContainer}>
          <div className={classes.infoCardHeader}>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {t("folders")}
            </Typography>
            <FolderActions applicant={applicant} options={folderActionsOptions} />
          </div>
        </div>
        {
          applicant?.folders && applicant?.folders?.length > 0
          ?
          (
            <div className={classes.candidateFoldersContainer}>
              {
                applicant?.folders?.map((folder: any) => {
                  return (
                    <SingleFolder folder={folder} onRemove={handleRemoveApplicantFromFolder} key={Math.random()} />
                  )
                })
              }
            </div>
          )
          :
          (
            <div style={{ padding: "0px 1rem" }}>
              <Typography variant="body2">
                {t("applicant_not_in_any_folder")}
              </Typography>
            </div>
          )
        }
      </div>
      <div style={{padding: "1rem", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <ManageApplicantFolders />
      </div>
    </Paper>
  )
}

export default CandidateFolders




