import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  logotypeImage: {
    width: 165,
    maxHeight: 40,
    [theme.breakpoints.down("xs")]: {
      // margin: "auto",
      width: "100px !important"
    }
  },
  appBar: {
    // position: "fixed",
    marginBottom: "30px",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "50px !important",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0px !important"
    }
  },
  toolbarTitle: {
    marginLeft: "5px",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px"
    }
  },
  logoBar: {
    marginLeft: "5px",
    color: theme.palette.secondary.main,
    textDecoration: "none !important",
    display: "flex",
    alignItems: "flex-end",
    margin: theme.spacing(1, 1.5),
    [theme.breakpoints.down("sm")]: {
      alignContent: "center",
      margin: "auto",
      justifyContent: "center",
      flexGrow: 1
    }
  },
  link: {
    margin: theme.spacing(1, 1.5),
    color: theme.palette.common.white,
    [theme.breakpoints.down("xs")]: {
      display: "none",
      width: "50%"
    }
  },
  createJobButton: {
    margin: theme.spacing(1, 1.5),
    color: theme.palette.secondary.main,
    outlineColor: theme.palette.secondary.main,
    outline: 0.5,
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  right: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    height: "50px",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  openDrawerButton: {
    color: "#fff",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  left: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      width: "100%"
    }
  },
  small: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(3),
      height: theme.spacing(3)
    }
  },
  notificationsAndMenuContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    }
  }
}))

export default useStyles
