import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import InfoToolTipIcon from "@material-ui/icons/InfoOutlined"
import { ICompany } from "../../../../types/company"

import useStyles from "../styles"
import { applicantsActions } from "../../../../store/actions/applicants"

interface Props {
  company: ICompany
  disabled: boolean
}

const ActiveFilter: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.applicantsReducer)

  const orderBy = useSelector((state: any) => state.applicantsReducer.orderBy)
  const orderDirection = useSelector((state: any) => state.applicantsReducer.orderDirection)

  const handleActive = async () => {
    dispatch(await applicantsActions.fetchActiveApplicants(props.company.id, 1, orderBy, orderDirection))
  }

  const handleAll = async () => {
    dispatch(await applicantsActions.removeActiveApplicantsFilter(props.company.id, 1, orderBy, orderDirection))
  }

  return (
    <div className={classes.activeFilters}>
      <ToggleButtonGroup className={classes.toggleButtonGroup} size="small">
        <ToggleButton disabled={props.disabled} selected={state.activeFilter === true} value={true} onClick={handleActive} className={classes.toggleButton}>
          {t("active")}
        </ToggleButton>
        <ToggleButton disabled={props.disabled} selected={state.activeFilter === false} value={false} onClick={handleAll} className={classes.toggleButton}>
          {t("all")}
        </ToggleButton>
      </ToggleButtonGroup>
      <Tooltip arrow title={<Typography style={{ textAlign: "center" }}>{t("active_filter_tooltip")}</Typography>}>
        <InfoToolTipIcon className={classes.toolTipIcon} />
      </Tooltip>
    </div>
  )
}

export default ActiveFilter
