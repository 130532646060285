import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 3)
  },
  button: {
    color: "#fff",
    border: "1px solid #fff"
  }
}))

export default useStyles
