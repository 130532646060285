import { JobsState } from "./JobsManagementProvider"
import sort from "fast-sort"

export enum JobsActionType {
  API_RESPONSE = "API_RESPONSE",
  NEW_JOB = "NEW_JOB",
  UPDATE_JOB = "UPDATE_JOB",
  JOB_SLOTS_INFO_FETCHED = "JOB_SLOTS_INFO_FETCHED",
  JOBS_SORTED = "JOBS_SORTED",
  ARCHIVE_JOB = "ARCHIVE_JOB"
}

export interface JobsAction {
  type: JobsActionType
  payload: any
}

export function jobsReducer(state: JobsState, action: JobsAction): JobsState {
  const { type, payload } = action
  switch (type) {
    case JobsActionType.API_RESPONSE:
      let jobsToDisplay = payload
      jobsToDisplay = sort(jobsToDisplay ? [...jobsToDisplay] : []).desc([(job: any) => job.is_published, (job: any) => job.updated_at])

      return {
        ...state,
        jobs: jobsToDisplay,
        fetched: true
      }
    case JobsActionType.NEW_JOB:
      return {
        ...state,
        jobs: [action.payload].concat(state.jobs),

        fetched: false
      }

    case JobsActionType.UPDATE_JOB:
      const index = state.jobs?.findIndex(e => e.id === payload.id)
      let updatedJobs = state.jobs ?? []
      if (index) {
        updatedJobs[index] = payload
      }
      const updateState = {
        ...state,
        jobs: updatedJobs,
        fetched: false
      }
      return updateState
    case JobsActionType.JOBS_SORTED:
      const sortedState = {
        ...state,
        jobs: payload
      }
      return sortedState
    case JobsActionType.JOB_SLOTS_INFO_FETCHED:
      // TODO: What should i do
      return { ...state, fetched: false }

    case JobsActionType.ARCHIVE_JOB:
      const jobsDeletedOne = state.jobs?.filter(e => e.id !== payload) ?? []

      return {
        ...state,
        jobs: jobsDeletedOne,
        fetched: false
      }
  }
  return {
    jobs: null,
    fetched: false
  }
}
