import React from "react"

import moment from "moment"
import { useTranslation } from "react-i18next"
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import Section from "./Section"
import { IApplicant } from "../../../types/applicants"

interface Props {
  applicant: IApplicant
}

const ApplicationNotes: React.FC<Props> = ({ applicant }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      {applicant.comments && applicant.comments.length > 0 ? (
        <Section title={t("application_notes")}>
          {applicant.comments.map(comment => {
            return (
              <View style={styles.singleNoteContainer} key={comment.id} wrap>
                <View style={styles.metaDetailsContainer}>
                  <Text>{comment.author_name}</Text>
                  <Text>{moment(comment.created_at).format("dd MMMM YYYY - HH:mm")}</Text>
                </View>
                <View style={styles.bodyDetailsContainer}>
                  <Text style={styles.primaryText}>{comment.body}</Text>
                </View>
              </View>
            )
          })}
        </Section>
      ) : null}
    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  singleNoteContainer: {
    display: "flex",
    padding: "5px 0px",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  },
  metaDetailsContainer: {
    flex: 2.5,
    padding: "2.5px 0px"
  },
  bodyDetailsContainer: {
    flex: 5
  },
  primaryText: {
    fontWeight: 700,
    fontSize: "12px"
  },
  secondaryText: {
    fontWeight: 700,
    fontSize: "12px"
  }
})

export default ApplicationNotes
