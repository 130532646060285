import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    borderLeft: "1px solid black",
    padding: "0px"
  },
  applicationDetails: {
    transition: "0.5s"
  },
  applicantChoices: {
    display: "flex",
    height: "50px",
    alignItems: "center",
    borderBottom: "1px solid #797979"
  },
  detailsRoot: {
    height: "calc(100% - 50px)",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 30px)"
    }
  },
  showMessagingButton: {
    paddingTop: "6px",
    paddingBottom: "6px",
    marginRight: "10px",
    marginLeft: "10px"
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: 16,
    paddingBottom: 32
  },
  headerInner: {
    maxWidth: 752,
    margin: "0 auto",
    width: "100%"
  },
  viewResumeButton: {
    color: theme.palette.common.white
  },
  headerEmailContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerNameContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  overlappingContainer: {
    margin: "-24px auto 0 auto",
    maxWidth: 752,
    borderRadius: 2
  },
  applicantInfoCard: {
    padding: 16,
    background: "#ffffff",
    boxShadow: "0 1px 5px 0 rgba(69,77,87,.3)",
    borderBottom: "1px solid #a5a5a5"
  },
  infoCardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "30px"
  },
  applicantDetailsTop: {
    height: "50px",
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  addButton: {},
  cancelButton: {},
  userIcon: {
    // [theme.breakpoints.down("xs")]: {
    //   display: "none"
    // }
  },
  showUserProfile: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  note: {
    "&:hover": {
      backgroundColor: "#F5F6F7 !important",
      // boxShadow: "0px 4px 14px 0px rgb(0 0 0 / 11%) !important",
      transition: "0.5s"
    },
    transition: "0.5s",
    padding: "10px 0px",
    borderBottom: "1px solid #eaeaea",
    "&:last-child": {
      borderBottom: "none"
    }
  },
  noteJobTitle: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
    marginLeft: ".5rem",
    marginRight: ".5rem",
  },
  author: {
    display: "inline",
    color: "#797979"
  },
  buttonArea: {
    paddingTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  label: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  downloadResumeButton: {
    padding: "0px",
    color: theme.palette.primary.main
  },
  messageHistoryPaper: {
    height: "100%",
    minHeight: "60vh",
    position: "relative"
  },
  messagingHistoryComponents: {
    padding: theme.spacing(2, 2)
  },
  notesSectionPaper: {
    height: "100%",
    minHeight: "60vh",
    position: "relative"
  },
  notesSectionHeaderContainer: {
    padding: theme.spacing(2, 2),
  },
  candidateNotesComponents: {
    padding: theme.spacing(0, 2)
  },
  noteCompanyName: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
    marginLeft: ".5rem",
    marginRight: ".5rem",
  },
  candidateFoldersContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    margin: "0px 1rem"
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxWidth: "620px",
    width: "100%",
    outline: "none",
    position: "relative",
    maxHeight: "100%",
    overflow: "auto",
    padding: theme.spacing(2, 2),
  },
  toolbar: {
    backgroundColor: theme.palette.primary.dark,
    color: "#fff"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none"
  },
  tableCell: {
    borderBottom: "none !important"
  },
  modalCloseContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalTypographyTitle: {
    display: "flex",
    alignItems: "center",
    margin: "0"
  },
  modalTypographyCaption: {
    color: "#393939"
  },
}))

export default useStyles
